import { DatePipe } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  Output,
  ChangeDetectorRef,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { CustomWindowConfig } from 'src/app/event.model';
import { EventService } from 'src/app/event.service';
import { Subscription } from 'rxjs';
import { PrintConfig } from 'src/app/core/print-settings/print-config-storage-data.model';
import { PrintService } from '../../../../print.service';
import { PrintSettingsService } from 'src/app/core/print-settings/print-settings.service';

@Component({
    selector: 'app-interest-note',
    templateUrl: './interest-note.component.html',
    styleUrls: ['./interest-note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InterestNoteComponent {
  @ViewChild('paymentTerm') paymentTermChild;
  @ViewChild('itemGrid') itemGrid;

  @Input() isVisible: boolean;
  @Input() readOnly: boolean;
  @Input() mode: string;
  @Input() settlementName: string;
  @Input() paymentDateSettlement: string;
  @Input() settlementType: string;
  @Input() payerId: number | null = null;
  @Input() settlementId: number | null = null;
  @Input() settlementAmount: string = '';
  @Input() isSelectedRows: boolean = false;

  @Output() onClosing = new EventEmitter();

  settlementNumer = '';
  title: string;
  submitted: boolean = false;
  form: FormGroup;
  emptyStateHeight;
  minWidth = null;
  maxHeight = null;
  loadChildComponent: boolean = false;
  isMobileBtnHeader: boolean = false;
  unicalGuid: number;
  showHeader: boolean = true;
  tooltipAdd: boolean = false;
  scrollPosition = 0;
  tooltipCancel: boolean = false;
  shortcuts: ShortcutInput[] = [];
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: '',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 0,
    },
    mainPanelWidth: '',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 0,
    },
    windowSize: {
      isFullscreen: false,
      width: '90%',
      height: '90%',
    },
    params: [],
  };
  daysForDue: number | null = 0;
  paymentTerm: number | null = 0;
  worker;
  tableInterestName: string = '';
  dataSource = [];
  selectedInterestNoteRowKeys = [];
  focusedSelected = [];
  heightGrid = 249;
  employee = '';
  payerName = '';
  noteSummary = 0;
  dayOfPaymentSettlement = '';
  printInterestNoteSub: Subscription;

  constructor(
    public print: PrintService,
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private printSettings: PrintSettingsService
  ) {
    this.createForm();

    this.title = this.translate.instant('addingInterestNote');
  }

  ngOnInit() {
    // this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.payerId?.currentValue != changes?.payerId?.previousValue) {
      this.appService
        .getAuth(`customers?ObjectId=${this.payerId}`)
        .subscribe((res) => {
          this.form.controls.PayerId.setValue(res.data[0].CustomerId);
          this.payerName = res.data[0].ShortName;
          this.form.controls.PaymentType.setValue(res.data[0].PaymentForm);
          this.form.controls.CompanyId.setValue(res.data[0].CompanyId);
          this.form.controls.EmployeeId.setValue(res.data[0].TraderId);
          this.daysForDue = res.data[0].DaysForDue;

          this.cd.detectChanges();
        });
    }
    if (
      changes?.settlementId?.currentValue !=
      changes?.settlementId?.previousValue
    ) {
      this.form.controls.SettlementId.setValue(this.settlementId);
    }

    if (
      changes?.settlementAmount?.currentValue !=
      changes?.settlementAmount?.previousValue
    ) {
      this.settlementAmount = changes?.settlementAmount?.currentValue;
    }

    if (
      changes?.settlementAmount?.currentValue !=
      changes?.settlementAmount?.previousValue
    ) {
      this.form.controls.SettlementType.setValue(this.settlementType);
    }

    if (this.isSelectedRows) {
      this.form.controls.SettlementId.setValue(0);
      this.translate.get('checked').subscribe((text) => {
        this.settlementNumer = text;
      });
      this.dayOfPaymentSettlement = '..';
      this.cd.detectChanges();
    } else {
      this.settlementNumer = this.settlementName;
      this.dayOfPaymentSettlement = new DatePipe('en-US').transform(
        this.paymentDateSettlement,
        'yyyy-MM-dd'
      );
      this.cd.detectChanges();
    }

    if (changes?.isVisible?.currentValue) {
      this.printSettings.currentComponentName.set('interestNote');
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isVisible) {
            this.isVisible = false;
            this.onClosing.emit(true);
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.isVisible) {
            this.onSaveNote();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      }
    );

    setTimeout(() => {
      if (this.localStorageData.windowSize.height == '90%') {
        this.emptyStateHeight = window.innerHeight * 0.9;

        this.showHeader
          ? (this.heightGrid = 503 - 157 + 24)
          : (this.heightGrid = 301 - 157);
      } else {
        this.emptyStateHeight = this.localStorageData.windowSize.height;
        this.showHeader
          ? (this.heightGrid = 503 - 157 + 24)
          : (this.heightGrid = 301 - 157);
      }

      if (this.localStorageData.windowSize.isFullscreen) {
        this.emptyStateHeight = window.innerHeight;
      }
    }, 0);
  }

  visibleChange() {
    this.submitted = false;

    if (!this.isVisible) {
      this.onClosing.emit(true);
      this.showHeader = true;
      this.submitted = false;
      this.selectedInterestNoteRowKeys = [];
      this.createForm();
    }

    this.cd.detectChanges();
  }

  onShown() {}

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  createForm() {
    this.form = this.formBuilder.group({
      CompanyId: 0,
      WarehouseId: 0,
      PayerId: 0,
      PaymentType: '',
      Amount: 0,
      Date: new Date(),
      UserId: 0,
      SettlementId: this.settlementId,
      InterestTableId: 0,
      SettlementType: this.settlementType,
      PaymentDate: new Date(),
      Number: '<auto>',
      EmployeeId: 0,
    });
  }

  onResizeEnd = () => {
    this.localStorageData.windowSize.isFullscreen = false;
  };

  onResizeStart = (e) => {
    this.emptyStateHeight = e.height;
    this.isMobileBtnHeader = e.width < 1160;
  };

  fullScreenChange = (e) => {
    this.localStorageData.windowSize.isFullscreen = e;

    if (e) {
      this.emptyStateHeight = window.innerHeight;
      this.showHeader
        ? (this.heightGrid = 503 - 157 + 24)
        : (this.heightGrid = 301 - 157 + 88);
    } else {
      if (this.localStorageData.windowSize.height == '90%') {
        this.localStorageData.windowSize.height = window.innerHeight * 0.9;
      }
      this.emptyStateHeight = this.localStorageData.windowSize.height;
      this.showHeader
        ? (this.heightGrid = 503 - 157 + 24)
        : (this.heightGrid = 301 - 157 + 88);
    }
  };

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };

  changeHeader = () => {
    this.showHeader = !this.showHeader;
    this.showHeader
      ? (this.heightGrid = 503 - 157 + 24)
      : (this.heightGrid = 301 - 157 + 88);

    this.cd.detectChanges();
  };

  cancel() {
    this.isVisible = false;
  }

  setFocus() {
    setTimeout(() => {
      // this.Number.instance.focus();f
    }, 0);
  }

  onSaveNote() {
    if (this.form.controls.Amount.value) {
      this.appService
        .postAuth(`finances/interestNote`, this.form.value)
        .subscribe(
          (res) => {
            const printConfig = this.print?.currentComponentConfig?.printConfig;
            if (printConfig) {
              this.event.printInterestNote.next(printConfig.interestNote);
            }
            if (res != null && res != 0) {
              this.event.interestNoteSettlementId.next(res);
            }
            this.isVisible = false;
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    } else {
      this.event.showNotification(
        'warning',
        this.translate.instant('interestNote.confirmErrorAmount')
      );
    }
  }

  onCancelImportInvoice() {
    this.isVisible = false;
  }

  onChoosedFormPayment(e: any) {
    this.form.controls.PaymentType.setValue(e?.PaymentFormName || null);
    this.paymentTermChild.instance.focus();
    this.cd.detectChanges();
  }

  setPaymentDate() {
    this.form.controls.PaymentDate.setValue(
      new DatePipe('en-US').transform(
        new Date(this.form.get('Date').value).getTime() +
          1000 * 60 * 60 * 24 * this.daysForDue,
        'yyyy-MM-dd'
      )
    );
  }

  DateOfPaymentChanged(e) {
    let amountDay =
      new Date(this.event.dataFormatted(e)).getTime() -
      new Date(
        this.event.dataFormatted({
          value: this.form.value.Date,
        })
      ).getTime();
    if (Math.ceil(amountDay / (1000 * 3600 * 24)) < 0) {
      amountDay = 0;
      this.form.controls.PaymentDate.setValue(
        new DatePipe('en-US').transform(
          new Date(this.form.value.Date).getTime(),
          'yyyy-MM-dd'
        )
      );
    }

    this.daysForDue = Math.ceil(amountDay / (1000 * 3600 * 24));
    this.cd.detectChanges();
  }

  onChoosedWorker(e) {
    if (!e) {
      return;
    }
    this.worker = e;
    this.employee = e.LastName;
    this.form.controls.EmployeeId.setValue(this.worker?.EmployeeId || null);
  }

  onChoosedInterest(e) {
    if (e) {
      this.form.controls.InterestTableId.setValue(e.InterestTableId);
      this.tableInterestName = e.Name;
      if (this.form.controls.InterestTableId.value) {
        this.getInterestNotePositions();
      }
    }
    if (e == null) {
      this.form.controls.InterestTableId.setValue(null);
      this.tableInterestName = '';
    }
  }

  getInterestNotePositions() {
    this.appService
      .getAuth(
        `finances/interestNotePositions?InterestTableId=${this.form.controls.InterestTableId.value}&settlementId=${this.form.controls.SettlementId.value}`
      )
      .subscribe(
        (res) => {
          this.dataSource = res;
          this.cd.detectChanges();
          this.appService
            .getAuth(
              `finances/interestNotePositionsSum?InterestTableId=${this.form.controls.InterestTableId.value}&settlementId=${this.form.controls.SettlementId.value}`
            )
            .subscribe((res) => {
              this.noteSummary = res[0].AmountRateSum;
              this.cd.detectChanges();
              this.form.controls.Amount.setValue(res[0].AmountRateSum);
            });
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  onDateChanged() {
    this.form.controls.PaymentDate.setValue(
      new DatePipe('en-US').transform(
        new Date(this.form.get('Date').value).getTime() +
          1000 * 60 * 60 * 24 * this.daysForDue,
        'yyyy-MM-dd'
      )
    );
  }

  onKeyDown() {}

  onFocusedRowChanged(e) {
    this.focusedSelected = [e.row.data];
  }

  onRowDblClick() {}

  calculateNote() {}

  ngOnDestroy() {}

  printConfig: PrintConfig;

  getPrintConfig(e) {
    this.printConfig = e;
  }
}

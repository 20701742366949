import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
@Injectable({
  providedIn: 'root',
})
export class SettlementsService {
  constructor(private http: AppServices, private event: EventService) {}

  prepareForModify(settlementId: number) {
    return this.http
      .putAuth(`finances/settlements/${settlementId}/prepareForModify`, null)
      .pipe(
        catchError((error) => {
          this.event.httpErrorNotification(error);
          throw error;
        })
      );
  }
}

import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  Output,
} from '@angular/core';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlobalDate } from '../../../core/date-range/GlobalDate';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-suma',
    templateUrl: './suma.component.html',
    styleUrls: ['./suma.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title', 'forSelected'],
    standalone: false
})
export class SumaComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('gridJobCost') gridJobCost;

  @ViewChild('focusFirst') focusFirst;
  @ViewChild('isValidUserExtensions') isValidUserExtensions;
  @ViewChild('appContractors') appContractors;
  @ViewChild('gridSuma') gridSuma: DxDataGridComponent;
  @ViewChild('gridSumaAccount') gridSumaAccount: DxDataGridComponent;
  @ViewChild('gridCurrency') gridCurrency: DxDataGridComponent;

  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;

  widthWindow = '90%';
  heightWindow = '90%';

  shortcuts: ShortcutInput[] = [];

  submitted: boolean = false;

  unicalGuid;

  dataSourceSuma = [];
  dataSourceCurrency = [];
  dataSourceSumaAccount = [];
  selectedtab = 0;

  radioButtonList = [
    { value: false, label: 'Tylko rozrachunki filtrowane' },
    { value: true, label: 'Tylko rozrachunki zaznaczone' },
  ];

  forSelected;
  isDisabledForSelected: boolean = true;
  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('form-financial-document.onlyFilteredAccounts')
      .subscribe((text) => (this.radioButtonList[0].label = text));
    this.translate
      .get('form-financial-document.onlySettlementsSelected')
      .subscribe((text) => (this.radioButtonList[1].label = text));
  }

  ngOnInit(): void {
    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    if (!this.readOnly) this.readOnly = this.event.readOnly;
  }

  ngOnDestroy() {
    this.currentDateSub?.unsubscribe();
  }

  onValueChanged = () => {
    this.getData();
  };

  getData = () => {
    let DateFrom =
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '';
    let DateTo =
      this.event.dataFormatted({ value: this.currentDate.dateTo }) || '';

    this.appService
      .getAuth(
        `finances/settlements/sums?payerType=${this.selectedId[0].PayerType}&forSelected=${this.forSelected}&dateStart=${DateFrom}&dateEnd=${DateTo}`
      )
      .subscribe((res) => {
        this.dataSourceSuma = [];
        this.dataSourceCurrency = [];
        this.dataSourceSumaAccount = [];

        this.dataSourceSuma.push({
          was: 'was',
          Claims: res['Claims'].Amount,
          Liabilities:
            res['Liabilities'].Amount != null ? res['Liabilities'].Amount : 0.0,
          PaymentsOut:
            res['Liabilities'].Amount != null ? res['PaymentsOut'].Amount : 0.0,
          PaymentsIn:
            res['Liabilities'].Amount != null ? res['PaymentsIn'].Amount : 0.0,
        });

        this.dataSourceSuma.push({
          was: 'left',
          Claims:
            res['Liabilities'].Remaining != null
              ? res['Claims'].Remaining
              : 0.0,
          Liabilities:
            res['Liabilities'].Remaining != null
              ? res['Liabilities'].Remaining
              : 0.0,
          PaymentsOut:
            res['Liabilities'].Remaining != null
              ? res['PaymentsOut'].Remaining
              : 0.0,
          PaymentsIn:
            res['Liabilities'].Remaining != null
              ? res['PaymentsIn'].Remaining
              : 0.0,
        });

        try {
          if (Array.isArray(res['ClaimsCurrency'])) {
            res['ClaimsCurrency'].forEach((field, index) => {
              this.dataSourceCurrency.push({
                was: 'was',
                CurrencyCode: field.CurrencyCode,
                ClaimsCurrency: field.Amount != null ? field.Amount : 0.0,
                LiabilitiesCurrency:
                  res['LiabilitiesCurrency'][index].Amount != null
                    ? res['LiabilitiesCurrency'][index].Amount
                    : 0.0,
              });

              this.dataSourceCurrency.push({
                was: 'left',
                CurrencyCode: field.CurrencyCode,
                ClaimsCurrency: field.Remaining != null ? field.Remaining : 0.0,
                LiabilitiesCurrency:
                  res['LiabilitiesCurrency'][index].Remaining != null
                    ? res['LiabilitiesCurrency'].Remaining
                    : 0.0,
              });
            });
          } else {
            this.dataSourceCurrency.push({
              was: 'was',
              CurrencyCode:
                res['ClaimsCurrency'] != null
                  ? res['ClaimsCurrency'].CurrencyCode
                  : '',
              ClaimsCurrency:
                res['ClaimsCurrency']?.Amount != null
                  ? res['ClaimsCurrency'].Amount
                  : 0.0,
              LiabilitiesCurrency:
                res['LiabilitiesCurrency']?.Amount != null
                  ? res['LiabilitiesCurrency'].Amount
                  : 0.0,
            });

            this.dataSourceCurrency.push({
              was: 'left',
              CurrencyCode:
                res['ClaimsCurrency'] != null
                  ? res['ClaimsCurrency'].CurrencyCode
                  : '',
              ClaimsCurrency:
                res['ClaimsCurrency']?.Remaining != null
                  ? res['ClaimsCurrency'].Remaining
                  : 0.0,
              LiabilitiesCurrency:
                res['LiabilitiesCurrency']?.Remaining != null
                  ? res['LiabilitiesCurrency'].Remaining
                  : 0.0,
            });

            if (
              res['ClaimsCurrency'] == null &&
              res['LiabilitiesCurrency'] != null
            ) {
              this.dataSourceCurrency[0].CurrencyCode =
                res['LiabilitiesCurrency'].CurrencyCode;
              this.dataSourceCurrency[1].CurrencyCode =
                res['LiabilitiesCurrency'].CurrencyCode;
            }

            if (
              res['ClaimsCurrency'] != null &&
              res['LiabilitiesCurrency'] == null
            ) {
              this.dataSourceCurrency[0].CurrencyCode =
                res['ClaimsCurrency'].CurrencyCode;
              this.dataSourceCurrency[1].CurrencyCode =
                res['ClaimsCurrency'].CurrencyCode;
            }
          }
        } catch {}

        this.dataSourceSumaAccount.push({
          was: 'was',
          AccountSumsIn:
            res['AccountSumsIn'].Remaining != null
              ? res['AccountSumsIn'].Amount
              : 0.0,
          AccountSumsOut:
            res['AccountSumsOut'].Remaining != null
              ? res['AccountSumsOut'].Amount
              : 0.0,
        });

        this.dataSourceSumaAccount.push({
          was: 'left',
          AccountSumsIn:
            res['AccountSumsIn'].Remaining != null
              ? res['AccountSumsIn'].Remaining
              : 0.0,
          AccountSumsOut:
            res['AccountSumsOut'].Remaining != null
              ? res['AccountSumsOut'].Remaining
              : 0.0,
        });

        this.cd.detectChanges();
      });
  };

  ngOnChanges() {
    if (this.selectedId) {
      if (this.selectedId.length != 0 && this.isVisible) {
        if (this.forSelected) {
          this.isDisabledForSelected = false;
        }
        this.getData();
      }
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 0;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 1;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 2;
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };
}

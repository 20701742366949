<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{
    'form-commercial-operation.determiningJPKclassificationForThePurchaseDocument'
      | translate
  }}"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'close' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="row">
        <div class="col-md-12">
          <div class="c-frame-row">
            <h5>
              {{
                "form-commercial-operation.JPKclassificationForThePurchaseDocument"
                  | translate
              }}
            </h5>
          </div>

          <dx-radio-group
            [items]="radioList"
            layout="horizontal"
            displayExpr="label"
            valueExpr="value"
            [readOnly]="readOnly"
            (onValueChanged)="onValueChanged($event)"
            [(value)]="radioListId"
          >
          </dx-radio-group>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-type-of-commercial-transaction
  [popUpMode]="true"
  [isVisible]="isCustomJPk"
  type="document"
  (onClosed)="isCustomJPk = false; cd.detectChanges()"
  (onChoosed)="onChoosedJPK($event)"
></app-type-of-commercial-transaction>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible || isCustomJPk"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  *ngIf="openNestFinished"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <!-- <dx-button text="{{'buttons.choose' |translate}}" type="success" style="margin-right: 6px;"
        [id]="'choosed'+unicalGuid" *ngIf="!readOnly" [disabled]="creadOnly"></dx-button> -->

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px">
        <p class="p-header-info">
          {{ "workers.documentNumber" | translate }}:
          <strong>{{ headerDocument.InvoiceDocumentNumber }}</strong> |
          {{ "form-commercial-operation.documentDate" | translate }}:
          <strong>{{
            headerDocument.DateOfInvoiceDocument | date : "yyyy-MM-dd"
          }}</strong>
          | {{ "warehouseDocument.customer" | translate }}:
          <strong>{{ headerDocument.CustomerName }}</strong>
        </p>
      </div>

      <div style="margin-bottom: 12px; margin-top: 12px">
        <!-- <dx-button class="btn-dx-top-menu-folder" icon="icon absui-icon--add-circle" [id]="'btn-add'+unicalGuid"
          [text]="'buttons.addCorrection' | translate" [disabled]="readOnly" (onClick)="addCorection()">
        </dx-button>

        <dx-button class="btn-dx-top-menu" icon="icon circle-add-correct" [id]="'btn-add-customer'+unicalGuid"
          [text]="'form-commercial-operation.addCustomerCorrection' | translate"
          [disabled]="readOnly || !KorDokHandlKontr" (onClick)="addCorection(true)">
        </dx-button>

        <dx-button class="btn-dx-top-menu" icon="icon circle-add-correct" [id]="'btn-add-unregister'+unicalGuid"
          [text]="'form-commercial-operation.addRevisionToUnregisteredDocument' | translate" [disabled]="readOnly"
          (onClick)="addCorectionUnregister()">
        </dx-button>

        <dx-button class="btn-dx-top-menu" icon="icon circle-add-correct"
          [text]="'form-commercial-operation.addBulkRevision' | translate" [id]="'btn-add-bulk'+unicalGuid"
          [disabled]="readOnly || !KorDokHandlDZB" (onClick)="addBulkRevision()">
        </dx-button> -->

        <dx-drop-down-button
          class="correction-drop-down"
          text="{{ 'buttons.add' | translate }}"
          width="auto"
          icon="icon circle-add-correct"
          [items]="exportList"
          stylingMode="text"
          type="default"
          [useItemTextAsTitle]="false"
          (onItemClick)="onItemClickExport($event)"
          itemTemplate="fontItem"
          data-cy="add-correction-invoice-ducument"
        >
          <dxo-drop-down-options [wrapperAttr]="wrapperAttr">
          </dxo-drop-down-options>
          <div *dxTemplate="let data of 'fontItem'">
            <span>{{ data.text }}</span>
          </div>
        </dx-drop-down-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--mode-edit"
          [text]="'buttons.edit' | translate"
          [id]="'btn-edit' + unicalGuid"
          (onClick)="editCorrectionDocument()"
          [disabled]="
            readOnly || focusedSelected.length == 0 || !perABD.editBtn
          "
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--form-items"
          [text]="'buttons.show' | translate"
          [id]="'show' + unicalGuid"
          (onClick)="showCorrectionDocument()"
          [disabled]="
            readOnly || focusedSelected.length == 0 || !perABD.showBtn
          "
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu"
          icon="icon absui-icon--highlight-off"
          [disabled]="
            (readOnly || focusedSelected.length == 0) && !isActiveButtonDelete
          "
          [text]="'warehouseDocument.deleteLastCorrection' | translate"
          [id]="'delete' + unicalGuid"
          (onClick)="isDelete()"
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu"
          [disabled]="readOnly || focusedSelected.length == 0"
          [text]="'form-commercial-operation.correctVat' | translate"
          [id]="'correctVat' + unicalGuid"
          (onClick)="correctVat()"
          *ngIf="IsOutcomeInvoice"
        >
        </dx-button>

        <app-reports
          #reports
          [code]="'Dokument_handlowy_korekta'"
          [currencyCode]="parentCurrencyCode"
          [menuName]="this.focusedSelected[0]?.MenuName"
          type="FRA"
          [objectId]="
            focusedSelected?.length
              ? focusedSelected[0]?.InvoiceDocumentId
              : null
          "
          (onForceRefresh)="onForceRefresh()"
          [commercialDocument]="focusedSelected[0]"
        >
        </app-reports>

        <dx-tooltip
          [target]="'#btn-add-customer' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "form-commercial-operation.addCustomerCorrection" | translate }}
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-add-bulk' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "form-commercial-operation.addBulkRevision" | translate }} (Ctrl
            + Insert)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-add-unregister' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{
              "form-commercial-operation.addRevisionToUnregisteredDocument"
                | translate
            }}
            (Shift + Insert)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-add' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.add" | translate }} (Insert)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-edit' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.edit" | translate }} (F2)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#show' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.show" | translate }} (Shift + F2)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#delete' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.delete" | translate }} (Del)
          </div>
        </dx-tooltip>
      </div>

      <div class="row">
        <div class="col-md-12">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            shortcut
            [height]="heightGrid"
            [(selectedRowKeys)]="selectedRows"
            [columnResizingMode]="'widget'"
            (onInitialized)="onInitialized($event)"
            [remoteOperations]="true"
            (onRowDblClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            (onContentReady)="onContentReady($event)"
            (onKeyDown)="onKeyDown($event)"
            [(focusedRowIndex)]="focusedRowIndex"
            [focusedRowEnabled]="true"
            #gridBank
            (onOptionChanged)="event.onOptionChanged($event)"
            data-cy="correction-invoice-ducument-grid"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              [allowSorting]="false"
              cellTemplate="mainColumn"
              [width]="150"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'mainColumn'">
              <div class="hov-underline" style="text-decoration: none">
                {{ data.data.InvoiceDocumentNumber }}
              </div>
            </div>

            <dxi-column
              caption="{{
                'form-financial-document.payer-type.contractor' | translate
              }}"
              [allowSorting]="false"
              dataField="Customer"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.form.dateOfIssue' | translate
              }}"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
              [allowSorting]="false"
              dataField="DateOfDocumentSys"
            ></dxi-column>

            <dxi-column
              caption="{{ 'warehouseDocument.netCorrection' | translate }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="TotalNetAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalNetAmount'">
              {{ cell.data.TotalNetAmount | amountFormatter : cell }}
            </div>

            <dxi-column
              caption="{{ 'warehouseDocument.vatCorrection' | translate }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="VATAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'VATAmount'">
              {{
                cell.data.TotalGrossAmount - cell.data.TotalNetAmount
                  | amountFormatter : cell
              }}
            </div>

            <dxi-column
              caption="{{ 'warehouseDocument.grossCorrection' | translate }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="grossCorrection"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'grossCorrection'">
              {{ cell.data.TotalGrossAmount | amountFormatter : cell }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.TotalNetAmountCurrency' | translate
              }}"
              [allowSorting]="false"
              width="150"
              cellTemplate="TotalNetAmountCurrency"
              *ngIf="selected[0]?.IsCurrencyDocument"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalNetAmountCurrency'">
              {{ cell.data.TotalNetAmountCurrency | amountFormatter : cell }}
            </div>

            <dxi-column
              caption="{{ 'buffor.vatCurrency' | translate }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="VATCurrencyAmount"
              *ngIf="selected[0]?.IsCurrencyDocument"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'VATCurrencyAmount'">
              {{
                cell.data.TotalGrossAmountCurrency -
                  cell.data.TotalNetAmountCurrency | amountFormatter : cell
              }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.TotalGrossAmountCurrency' | translate
              }}"
              [allowSorting]="false"
              width="150"
              cellTemplate="TotalGrossAmountCurrency"
              *ngIf="selected[0]?.IsCurrencyDocument"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalGrossAmountCurrency'">
              {{ cell.data.TotalGrossAmountCurrency | amountFormatter : cell }}
            </div>
          </dx-data-grid>
        </div>
      </div>

      <div class="count-container" style="margin-top: 12px; height: auto">
        <div class="row">
          <div class="col-md-2">
            <!-- <p *ngIf="isCorrect" class="summary-count-container">
              {{ "inventory.summarizedInventory" | translate }}
            </p> -->
          </div>

          <div class="col-md-10 text-right">
            <div class="contener-pay">
              <p class="correction-label">
                {{
                  sumPosition.TotalGrossAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label" data-cy="correction-label">
                {{
                  sumPosition.TotalGrossAmountAfterCorrection.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="summary-amount-correction">
                <strong>
                  {{
                    sumPosition.TotalGrossAmountCorrection.toFixed(2)
                      | currency : " "
                      | pointReplacer
                  }}
                </strong>
              </p>

              <p class="des-toPay">
                {{ "form-commercial-operation.gross" | translate }}
                {{
                  selected[0]?.IsCurrencyDocument
                    ? selected[0]?.CurrencyCode
                    : ""
                }}
              </p>
            </div>

            <div class="contener-pay" style="padding-right: 15px">
              <p class="correction-label">
                {{
                  sumPosition.TotalTaxAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                {{
                  sumPosition.TotalTaxAmountAfterCorrection.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                <strong>{{
                  sumPosition.TotalTaxAmountCorrection.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}</strong>
              </p>
              <p class="des-toPay">VAT</p>
            </div>

            <div
              class="contener-pay"
              style="padding-right: 15px; border-left: none"
            >
              <p class="correction-label">
                {{
                  sumPosition.TotalNetAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>
              <p class="correction-label">
                {{
                  sumPosition.TotalNetAmountAfterCorrection.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                <strong>{{
                  sumPosition.TotalNetAmountCorrection.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}</strong>
              </p>
              <p class="des-toPay">
                {{ "form-commercial-operation.net" | translate }}
                {{
                  selected[0]?.IsCurrencyDocument
                    ? selected[0]?.CurrencyCode
                    : ""
                }}
              </p>
            </div>

            <div class="contener-pay" style="border-left: none">
              <p class="correction-label">
                {{ "warehouseDocument.beforeCorrection" | translate }}
                :
              </p>
              <p class="correction-label">
                {{ "warehouseDocument.afterCorrection" | translate }}:
              </p>
              <p class="correction-label">
                <strong
                  >{{
                    "warehouseDocument.correctionValue" | translate
                  }}:</strong
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<ng-container *ngIf="loadChildComponent">
  <app-new-comercial-operation
    [isVisible]="addRow"
    (onClosing)="addRow = false; isVatVerification = false; cd.detectChanges()"
    title="{{ 'form-commercial-operation.title' | translate }}"
    (onInserted)="onInsertedDocument($event)"
    [mode]="mode"
    [selectedId]="correctionDocumetn"
    [readOnly]="mode == 'show' ? true : readOnly"
    [isCorrect]="true"
    [componentNests]="componentNests"
    [sumCorrection]="correctionSummary"
    [isEditCustomer]="isEditCustomer"
    [unregisterSum]="unregisterSum"
    [isVatVerificationCorrect]="isVatVerification"
    [isCorrectionFZUE]="isCorrectionFZUE"
  >
  </app-new-comercial-operation>

  <app-new-comercial-operation
    [isVisible]="isUnregist"
    (onClosing)="isUnregist = false"
    [componentNests]="componentNests"
    title="{{ 'form-commercial-operation.title' | translate }}"
    (onInserted)="onInsertedDocumentUnregister($event)"
    mode="add"
    [readOnly]="mode == 'show' ? true : readOnly"
    [isCorrect]="false"
    [unregisterDocument]="true"
  >
  </app-new-comercial-operation>

  <app-confirm-dialog
    [isVisible]="isDeleteRow"
    (onRemoving)="delete()"
    (onClosing)="closeConfirm()"
    confirmText="form-commercial-operation.doYouWantToDeleteLastCorrection"
  ></app-confirm-dialog>
</ng-container>

<app-confirm-dialog
  [isVisible]="isInfoCorrectionUnregister"
  (onClosing)="isInfoCorrectionUnregister = false; cd.detectChanges()"
  btnConfig="continuegiveup"
  (onRemoving)="onAddUnregisterCorretion(); cd.detectChanges()"
  kindDialog="information"
  [groupMsg]="groupMsg"
  width="520"
  title="form-commercial-operation.correctionRegistration"
  confirmText=""
>
</app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible || isExpansionListVisible"
>
</wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGHKL'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGHKL'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<!-- <app-print-settings
  componentName="comercialOperations"
  [refreshPrintSettings]="refreshPrintSettings"
  (onSettingsRefreshed)="refreshPrintSettings = false"
>
</app-print-settings> -->

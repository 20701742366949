import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-change-jpk',
    templateUrl: './change-jpk.component.html',
    styleUrls: ['./change-jpk.component.scss'],
    inputs: ['isVisible', 'readOnly', 'selectedRows'],
    standalone: false
})
export class ChangeJPKComponent implements OnInit {
  @Output() onClosing = new EventEmitter();

  unicalGuid;
  readOnly;
  isVisible;
  widthWindow: any = '590';
  heightWindow: any = 200;
  selectedRows;

  shortcuts: ShortcutInput[] = [];
  isCustomJPk: boolean = false;

  radioList = [
    {
      label: 'proponuj automatycznie na podstawie właściwości dokumentu',
      value: 0,
    },
    {
      label: 'wybierz i ustaw po weryfikacji z właściwościami dokumentu',
      value: 1,
    },
  ];

  radioListId: Number = 0;
  selectedJpk: string = '';

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get(
        'form-commercial-operation.suggestAutomaticallyBasedOnDocumentProperties'
      )
      .subscribe((text) => (this.radioList[0].label = text));
    this.translate
      .get(
        'form-commercial-operation.selectAndSetAfterVerificationWithDocumentProperties'
      )
      .subscribe((text) => (this.radioList[1].label = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.onClosing.emit(true);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(true);
    }
  };

  closeWindow = () => {
    this.isVisible = false;
    this.onClosing.emit(true);
  };

  onChoosedJPK = (e) => {
    if (e) {
      e.forEach((element, index) => {
        this.selectedJpk += element.TransactionCode;
        if (index < e.length - 1) this.selectedJpk += ' ';
      });
    }
  };

  onValueChanged = (e) => {
    if (e.value == 1) this.isCustomJPk = true;
    this.cd.detectChanges();
  };

  onSave = () => {
    if (this.radioListId == 0) {
      let arrayForkjoin: any = [];

      this.selectedRows.forEach((field) => {
        arrayForkjoin.push(
          this.appService.putAuth(
            `invoices/additionalOperations/${field}/setJpkClassification`,
            `""`,
            true
          )
        );
      });

      forkJoin(arrayForkjoin).subscribe(
        () => {
          this.isVisible = false;
          this.onClosing.emit(true);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
      return;
    }

    let arrayForkjoin: any = [];
    this.selectedRows.forEach((field) => {
      arrayForkjoin.push(
        this.appService.putAuth(
          `invoices/additionalOperations/${field}/setJpkClassification`,
          `"${this.selectedJpk}"`,
          true
        )
      );
    });

    forkJoin(arrayForkjoin).subscribe(
      () => {
        this.isVisible = false;
        this.onClosing.emit(true);
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  };
}

<dx-popup
  width="760"
  height="340"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShowing)="event.onShownPopUp(unicalGuid)"
  (onHiding)="event.onHiddenPopUp(unicalGuid); onClosing.emit(false)"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        type="danger"
        text="{{ 'buttons.close' | translate }}"
        style="margin-right: 6px"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <dx-tab-panel
        #tabPanel
        [(selectedIndex)]="selectedtab"
        [items]="[
          'form-financial-document.sumOfSettlements' | translate,
          'form-financial-document.totalByCurrency' | translate,
          'form-financial-document.bookSum' | translate
        ]"
        [loop]="false"
        [animationEnabled]="false"
        [swipeEnabled]="false"
      >
        <div *dxTemplate="let name of 'item'">
          <ng-container [ngSwitch]="name">
            <div
              *ngSwitchCase="
                'form-financial-document.sumOfSettlements' | translate
              "
            >
              <ng-container *ngTemplateOutlet="tab1"></ng-container>
            </div>
            <div
              *ngSwitchCase="
                'form-financial-document.totalByCurrency' | translate
              "
            >
              <ng-container *ngTemplateOutlet="tab2"></ng-container>
            </div>
            <div *ngSwitchCase="'form-financial-document.bookSum' | translate">
              <ng-container *ngTemplateOutlet="tab3"></ng-container>
            </div>
          </ng-container>

          <dx-radio-group
            [items]="radioButtonList"
            layout="horizontal"
            displayExpr="label"
            valueExpr="value"
            [readOnly]="readOnly || isDisabledForSelected"
            [(value)]="forSelected"
            (onValueChanged)="onValueChanged()"
          >
          </dx-radio-group>
        </div>
      </dx-tab-panel>

      <ng-template #tab1>
        <dx-data-grid
          #gridSuma
          [dataSource]="dataSourceSuma"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [height]="160"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onOptionChanged)="event.onOptionChanged($event)"
        >
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxi-column
            caption=""
            alignment="right"
            [allowSorting]="false"
            cellTemplate="firstColumn"
          >
          </dxi-column>

          <div *dxTemplate="let data of 'firstColumn'">
            {{ data.data.was | translate }}
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.receivable' | translate }}"
            [width]="150"
            [allowSorting]="false"
            dataField="Claims"
            cellTemplate="Claims"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'Claims'">
            {{ data.data.Claims | amountFormatter : data }}
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.commitment' | translate }}"
            [width]="150"
            [allowSorting]="false"
            dataField="Liabilities"
            cellTemplate="Liabilities"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'Liabilities'">
            {{ data.data.Liabilities | amountFormatter : data }}
          </div>

          <dxi-column
            caption="{{
              'form-financial-document.outflowPayments' | translate
            }}"
            [width]="150"
            [allowSorting]="false"
            dataField="PaymentsOut"
            cellTemplate="PaymentsOut"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'PaymentsOut'">
            {{ data.data.PaymentsOut | amountFormatter : data }}
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.outflowImpact' | translate }}"
            [width]="150"
            [allowSorting]="false"
            dataField="PaymentsIn"
            cellTemplate="PaymentsIn"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'PaymentsIn'">
            {{ data.data.PaymentsIn | amountFormatter : data }}
          </div>
        </dx-data-grid>
      </ng-template>

      <ng-template #tab2>
        <dx-data-grid
          #gridCurrency
          [dataSource]="dataSourceCurrency"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [height]="160"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onOptionChanged)="event.onOptionChanged($event)"
        >
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxi-column
            caption=""
            alignment="right"
            [width]="100"
            [allowSorting]="false"
            cellTemplate="firstColumn"
          >
          </dxi-column>

          <div *dxTemplate="let data of 'firstColumn'">
            {{ data.data.was | translate }} {{ data.data.CurrencyCode }}
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.claimsCurrency' | translate }}"
            [width]="300"
            [allowSorting]="false"
            dataField="ClaimsCurrency"
            cellTemplate="ClaimsCurrency"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'ClaimsCurrency'">
            {{ data.data.ClaimsCurrency | amountFormatter : data : 4 }}
          </div>

          <dxi-column
            caption="{{
              'form-financial-document.liabilitiesCurrency' | translate
            }}"
            [width]="300"
            [allowSorting]="false"
            dataField="LiabilitiesCurrency"
            cellTemplate="LiabilitiesCurrency"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'LiabilitiesCurrency'">
            {{ data.data.LiabilitiesCurrency | amountFormatter : data : 4 }}
          </div>
        </dx-data-grid>
      </ng-template>

      <ng-template #tab3>
        <dx-data-grid
          #gridSumaAccount
          [dataSource]="dataSourceSumaAccount"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [height]="160"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onOptionChanged)="event.onOptionChanged($event)"
        >
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxi-column
            caption=""
            alignment="right"
            [width]="100"
            [allowSorting]="false"
            cellTemplate="firstColumn"
          >
          </dxi-column>

          <div *dxTemplate="let data of 'firstColumn'">
            {{ data.data.was | translate }}
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.sideDt' | translate }}"
            [width]="300"
            [allowSorting]="false"
            dataField="AccountSumsIn"
            cellTemplate="AccountSumsIn"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'AccountSumsIn'">
            {{ data.data.AccountSumsIn | amountFormatter : data }}
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.sideCt' | translate }}"
            [width]="300"
            [allowSorting]="false"
            dataField="AccountSumsOut"
            cellTemplate="AccountSumsOut"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'AccountSumsOut'">
            {{ data.data.AccountSumsOut | amountFormatter : data }}
          </div>
        </dx-data-grid>
      </ng-template>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

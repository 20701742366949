import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent, DxPopupComponent } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { LoadOptions } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { map, Subscription } from 'rxjs';
import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import { BaseGridDataResponse, RefreshCardResult } from 'src/app/event.model';
import { ExportFakirService } from 'src/app/other/export-fakir.service';
import { environment } from '../../../environments/environment';
import { AppServices } from '../../app-services.service';
import { GlobalDate } from '../../core/date-range/GlobalDate';
import { EventService } from '../../event.service';
import { ICompanyConfigurationParameter } from '../../administrator/company-configuration/ICompanyConfiguration';
import { ReportData } from 'src/app/core/reports/IReportParameters';
import { PrintService } from 'src/app/print.service';
import { PrintSettingsService } from 'src/app/core/print-settings/print-settings.service';

@Component({
    selector: 'app-financial-raports',
    templateUrl: './financial-raports.component.html',
    styleUrls: ['./financial-raports.component.scss'],
    inputs: ['isVisible', 'title', 'readOnly', 'popUpMode'],
    standalone: false
})
export class FinancialRaportsComponent {
  @Output() onClosing = new EventEmitter();
  @ViewChild('popup') popup: DxPopupComponent;
  @ViewChild('headerDocument') headerDocument: ElementRef;
  @ViewChild('gridButtons') gridButtons: ElementRef;
  @ViewChild('kindOfRaportBox') kindOfRaportBox;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  @ViewChild('gridBank') gridBank: DxDataGridComponent;
  exportFakirService = inject(ExportFakirService);
  router = inject(Router);
  isVisible;
  pageSize = 100;
  title;
  readOnly;
  unicalGuid;
  widthWindow = '700';
  heightWindow = '90%';
  maxHeight;
  maxWidth;
  popUpMode;

  kindsOfRaportList = [
    { id: 'k', name: 'Kasowy' },
    { id: 'b', name: 'Bankowy' },
  ];

  selectedKindOfRaport: string = 'k';

  filterCriteria: any[] = [
    { value: 'number', label: 'Numer' },
    { value: 'date', label: 'Data', type: 'data' },
  ];
  valueCriteria = 'number';
  filterValue = '';

  refreshCardResult: RefreshCardResult = 'Tak';

  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();

  statusList = [
    { value: null, label: 'Wszystkie' },
    { value: true, label: 'Zaksięgowane' },
    { value: false, label: 'Niezaksięgowane' },
  ];

  kindCashRaportList = [
    { value: 1, label: 'Wszystko' },
    { value: 2, label: 'Wpływy' },
    { value: 3, label: 'Wypływy' },
  ];

  dataSource;
  dataSourceRaport;
  selectedRows: any = [];
  heightGrid = 0.6 * window.innerHeight - 28;

  selectedtabIndex = 0;
  mode = 'add';

  bottomPanelTabs = ['Dokumenty raportu', 'Rozbicie na waluty'];
  fullScreen: boolean = false;
  shortcuts: ShortcutInput[] = [];
  statusValue = null;
  bankAccounts;
  isDeleteRow: boolean = false;
  menuName: string = 'cashBankReports';
  selectedDocumentsData: any = [];

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices,
    private print: PrintService,
    private printSettings: PrintSettingsService
  ) {
    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    this.widthWindow = this.event.setWidthPopUp();
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;

    this.translate
      .get('form-financial-document.checkout')
      .subscribe((text: string) => {
        this.kindsOfRaportList[0].name = text;
      });

    this.translate
      .get('form-financial-document.bankingk')
      .subscribe((text: string) => {
        this.kindsOfRaportList[1].name = text;
      });

    this.translate
      .get('form-financial-document.number')
      .subscribe((text: string) => {
        this.filterCriteria[0].label = text;
      });

    this.translate
      .get('form-financial-document.date')
      .subscribe((text: string) => {
        this.filterCriteria[1].label = text;
      });

    this.translate.get('all').subscribe((text) => {
      this.statusList[0].label = text;
    });

    this.translate.get('form-financial-document.posted').subscribe((text) => {
      this.statusList[1].label = text;
    });

    this.translate
      .get('form-financial-document.notAccountedFor')
      .subscribe((text) => {
        this.statusList[2].label = text;
      });

    this.translate.get('constractors.filter.everything').subscribe((text) => {
      this.kindCashRaportList[0].label = text;
    });

    this.translate.get('form-financial-document.income').subscribe((text) => {
      this.kindCashRaportList[1].label = text;
    });

    this.translate.get('form-financial-document.outflows').subscribe((text) => {
      this.kindCashRaportList[2].label = text;
    });

    this.translate
      .get('form-financial-document.raportDocuments')
      .subscribe((text) => {
        this.bottomPanelTabs[0] = text;
      });

    this.translate
      .get('form-financial-document.currencyBreakdown')
      .subscribe((text) => {
        this.bottomPanelTabs[1] = text;
      });
  }

  ngOnInit() {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.bankAccounts = this.event.decryptString(
      localStorage.getItem('staticData')
    ).companyBankAccounts;
  }

  @ViewChild('cashBox') cashBox;
  CashId = 0;
  BankAccountId = 0;

  contentHeight: number;
  onShown() {
    this.event.onShownPopUp();
    this.event.setFocus(this.kindOfRaportBox);
    this.getCurrentCashBox();

    if (this.bankAccounts?.length > 0) {
      this.BankAccountId = this.bankAccounts[0].BankAccountId;
    }

    this.getCashBankRaports();
    setTimeout(() => {
      this.getGridHeight();
    }, 0);
  }

  onResizeEnd(e) {
    const content = e.component.content() as HTMLElement;
    this.contentHeight = content.getBoundingClientRect().height;
    setTimeout(() => {
      this.getGridHeight();
    }, 0);
  }

  readonly FieldCode = 'DomKasa';
  getCurrentCashBox() {
    this.appService
      .getAuth(`configurations/users/currentUser/config`, {
        FieldCode: this.FieldCode,
        ShowWeb: true,
      })
      .pipe(
        map((response: BaseGridDataResponse<ICompanyConfigurationParameter>) =>
          response?.data?.length
            ? response?.data[0]
            : { Value: this.cashBox?.dataSource?._items[0].CashId }
        ),
        map((obj) => obj.Value)
      )
      .subscribe({
        next: (data) => {
          if (data) {
            this.CashId = data;
            this.getCashBankRaports();
            this.cd.detectChanges();
          }
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isVisible?.currentValue) {
      this.deselectAllRecords();
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRows.length &&
            this.exportFakirService.isSelectMode()
          ) {
            this.cancelChoosed();
          } else {
            this.onCancel();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onAddNew();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onEdit();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isDelete();
        },
        preventDefault: true,
      },
      {
        key: 'enter',
        allowIn: [AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRows.length &&
            this.exportFakirService.isSelectMode()
          ) {
            this.selectChoosed();
          }
        },
        preventDefault: true,
      }
    );
  }

  onCancel() {
    this.isVisible = false;
    this.cd.detectChanges();
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  isDelete() {
    this.isDeleteRow = true;
    this.cd.detectChanges();
  }

  closeConfirm() {
    this.isDeleteRow = false;
    this.cd.detectChanges();
  }

  onEdit() {
    this.mode = 'edit';
    this.isVisibleNewRaport = true;
    this.cd.detectChanges();
  }

  delete() {
    this.isDeleteRow = false;
    this.cd.detectChanges();
    this.appService
      .deleteAuth(
        `finances/cashBankReports/${this.focusedSelected[0].ReportId}`
      )
      .subscribe(
        () => {
          this.dataSourceRaport = [];
          this.getCashBankRaports();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  onChoosedCashBox(e) {
    if (e) {
      this.CashId = e.CashId;
    } else {
      this.CashId = 0;
    }
    this.getCashBankRaports();
  }

  onChoosedCompanyBank(e) {
    if (e) {
      this.BankAccountId = e.BankAccountId;
    } else {
      this.BankAccountId = 0;
    }
    this.getCashBankRaports();
  }

  CurrencySymbol = 'PLN';
  onChoosedCurrency(e) {
    if (e) {
      this.CurrencySymbol = e.CurrencyCode;
      this.getCashBankRaports();
    }
  }

  onEditorPreparing(e) {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.valueCriteria = e.selectedItem.value;
    }
    this.filterValue = e.filterValue;
    this.getCashBankRaports();
  }

  onValueChangedState(e) {
    this.statusValue = e;
    this.getCashBankRaports();
  }

  ngOnDestroy() {
    this.exportFakirService.isSelectMode.set(false);
  }

  onDateRangeChoosed() {
    if (this.refreshCardResult === 'Tak') {
      this.getCashBankRaports();
    }
  }

  focusedRowIndex;
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onRowDblClick = () => {
    if (!this.readOnly) {
      this.onEdit();
    }
  };

  onRowClick = (e) => {
    this.focusedSelected = [e.data];
  };

  focusedSelected = [];
  myTimer;
  onFocusedRowChanged = (e) => {
    if (!e.row?.data) {
      return;
    }
    this.focusedSelected = [e.row.data];
    this.menuName = e.row.data?.MenuName || null;
    if (this.myTimer) {
      clearTimeout(this.myTimer);
    }

    this.myTimer = setTimeout(() => {
      try {
        this.getDocumentRaports(this.focusedSelected[0].ReportId);
        this.cashbankreportsSums(this.focusedSelected[0].ReportId);
      } catch {}
    }, 300);
  };

  sortingIcons: any = {
    asc: [],
    desc: [],
  };
  onGridContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }

    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  onKeyDown(e) {
    if (e.event.key === 'Enter') {
      if (this.selectedRows.length && this.exportFakirService.isSelectMode()) {
        this.selectChoosed();
      }
    } else if (e.event.key === 'Escape') {
      if (this.exportFakirService.isSelectMode()) {
        this.cancelChoosed();
      }
    } else if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    } else if (e.event.key === 'F9' && !e.event.ctrlKey && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.dateRange.openDateRange();
    }
    // if (e.event.keyCode === 32) {
    //   e.event.preventDefault();
    //   const grid = e.component;
    //   const focusedRowKey = grid.option('focusedRowKey');
    //   const isRowSelected = grid.isRowSelected(focusedRowKey);
    //   if (isRowSelected) {
    //     grid.deselectRows([focusedRowKey]);
    //     this.toDeselect.push(focusedRowKey);
    //   } else {
    //     grid.selectRows([focusedRowKey], true);
    //     this.toSelect.push(focusedRowKey);
    //   }
    //   this.focusedRowIndex = this.focusedRowIndex + 1;
    //   grid.repaint();
    //   this.isQuickPress = false;
    // }
  }

  sendRequestTimer;
  onSelectionChanged = (e) => {
    this.selectedDocumentsData = e.selectedRowsData;
    if (this.skipSendSelectionEvent) {
      this.toSelect = [];
      this.toDeselect = [];
      this.skipSendSelectionEvent = false;
      return;
    }

    if (
      e.currentDeselectedRowKeys.length &&
      e.currentDeselectedRowKeys.length === this.dataSource._totalCount
    ) {
      this.deselectAllRecords();
      return;
    }

    this.toSelect = this.toSelect.concat(e.selectedRowKeys);
    this.toDeselect = this.toDeselect.concat(e.currentDeselectedRowKeys);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (
      this.selectedRows.length === 0 ||
      (this.selectedRows.length &&
        this.selectedRows.length !== this.dataSource._items.length)
    ) {
      this.isAllSelected = false;
    }

    if (this.dataSource._items.length) {
      this.setSelectionTimeout();
    }
  };

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        let data = [];
        for (let item of this.toSelect) {
          if (item?.ReportId) {
            data.push(item.ReportId);
          } else {
            data.push(item);
          }
        }
        data = this.event.removeDuplicates(data);

        this.event
          .selectRecords(`finances/selection/cashBankReports/select`, data)
          .then((val) => {
            if (val) {
              this.toSelect = [];
            }
          });
      }
      if (this.toDeselect.length) {
        let data = [];
        for (let item of this.toDeselect) {
          if (item?.ReportId) {
            data.push(item.ReportId);
          } else {
            data.push(item);
          }
        }
        data = this.event.removeDuplicates(data);
        const items = this.dataSource.items();
        const toRemove = [];
        for (let toDes of data) {
          const item = items.find((el) => el.ReportId === toDes);
          if (!item) {
            toRemove.push(data.indexOf(toDes));
          }
        }
        for (let i = toRemove.length - 1; i >= 0; i--) {
          data.splice(toRemove[i], 1);
        }
        if (data.length) {
          this.event
            .selectRecords(`finances/selection/cashBankReports/unselect`, data)
            .then((val) => {
              if (val) {
                this.toDeselect = [];
              }
            });
        } else {
          this.toDeselect = [];
        }
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  Direction;
  onValueChangedType(e) {
    this.Direction = e;
  }

  isVisibleNewRaport: boolean = false;
  onAddNew() {
    if (this.readOnly) return;

    if (this.CashId == 0 && this.BankAccountId == 0) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.select-cash-bank-account'
        )
      );
      return;
    }
    this.mode = 'add';
    this.isVisibleNewRaport = true;
    this.cd.detectChanges();
  }

  onClosingNewRaport() {
    this.isVisibleNewRaport = false;
    this.printSettings.currentComponentName.set('financialRaports');
    this.cd.detectChanges();
  }

  fullScreenChange(e) {
    if (e) {
      this.heightGrid = window.innerHeight - 228;
    } else {
      this.heightGrid = 0.6 * window.innerHeight - 28;
    }

    setTimeout(() => {
      this.getGridHeight();
    }, 0);
    // this.heightGrid = parseInt(this.heightWindow);
  }

  getGridHeight() {
    const popupContent: HTMLElement = this.popup.instance.content();
    const contentHeight: number = popupContent.getBoundingClientRect().height;
    const contentPaddingY: number = 28;
    const contentScroll: number = 23;
    const headerDocument: number =
      this.headerDocument.nativeElement.getBoundingClientRect().height;
    const gridButtons: number =
      this.gridButtons.nativeElement.getBoundingClientRect().height;

    this.heightGrid =
      contentHeight -
      contentScroll -
      contentPaddingY -
      headerDocument -
      gridButtons -
      10;
  }

  getCashBankRaports() {
    if (this.CashId == 0 && this.BankAccountId == 0) return;

    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ReportId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}finances/cashBankReports`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          if (data.length > 0) {
            this.focusedRowIndex = 0;
            this.focusedSelected = [data[0]];
          } else {
            this.focusedSelected = [];
            this.dataSourceSum = [];
            this.dataSourceRaport = [];
          }
          this.onFocusedRowChanged({
            row: { data: data[this.focusedRowIndex] },
          });

          // unselect from api
          const toAdd = data.filter(
            (el) =>
              el.IsSelected && this.selectedRows.indexOf(el.ReportId) === -1
          );
          for (let item of toAdd) {
            this.selectedRows.push(item.ReportId);
            this.skipSendSelectionEvent = true;
          }
          const toDelete = [];
          for (let id of this.selectedRows) {
            const item = data.find((el) => el.ReportId === id);
            if (item && !item.IsSelected) {
              toDelete.push(id);
            }
          }
          for (let id of toDelete) {
            this.selectedRows.splice(
              this.selectedRows.findIndex((el) => el === id),
              1
            );
            this.skipSendSelectionEvent = true;
          }
        },
        deleteUrl: `${environment.domain}finances/cashBankReports`,
      }),
      reshapeOnPush: true,
      requireTotalCount: true,
      paginate: true,
    });
  }

  getDocumentRaports(id) {
    this.dataSourceRaport = new DataSource({
      store: AspNetData.createStore({
        key: 'FinanceDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}finances/documents`,
        loadParams: this.getLoadParamsDocuments(id),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
      }),
      reshapeOnPush: true,
      requireTotalCount: true,
      paginate: true,
    });
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.orderBy) {
      this.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.getCashBankRaports();
    this.cd.detectChanges();
  };

  switchOrder() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
      return;
    }

    this.order = 'ASC';
  }

  editDocument() {}

  order: string = 'ASC'; // asc/desc
  orderBy: string = 'number';
  paramsObj: any = {};
  getLoadParams() {
    let obj: any = {};

    obj.ReportType = this.selectedKindOfRaport;
    obj.DateFrom =
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '';
    obj.DateTo =
      this.event.dataFormatted({ value: this.currentDate.dateTo }) || '';

    if (this.statusValue != null) obj.IsBooked = this.statusValue;

    if (this.selectedKindOfRaport == 'k') {
      obj.SourceId = this.CashId;
    }

    if (this.selectedKindOfRaport == 'b') {
      obj.SourceId = this.BankAccountId;
    }

    switch (this.valueCriteria) {
      case 'number':
        obj.Number = this.filterValue;
        break;
      case 'date':
        obj.DateFrom = this.filterValue;
        obj.DateTo = this.filterValue;
        break;
    }

    this.paramsObj = obj;
    return obj;
  }

  getLoadParamsDocuments(id) {
    let obj: any = {};
    obj.ReportId = id;
    obj.Direction = this.Direction;
    return obj;
  }

  onRefresh() {
    this.getCashBankRaports();
    this.isVisibleNewRaport = false;
    this.cd.detectChanges();
  }

  dataSourceSum;
  cashbankreportsSums(reportId) {
    this.dataSourceSum = new DataSource({
      store: AspNetData.createStore({
        key: 'ReportId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}finances/cashBankReports/${reportId}/sums`,
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
      }),
      reshapeOnPush: true,
      requireTotalCount: true,
      paginate: true,
    });
  }

  isAllSelected = false;
  selectChoosed() {
    const value = this.exportFakirService.selections.getValue();
    if (this.isAllSelected) {
      this.exportFakirService.selections.next({
        ...value,
        RKWB: this.dataSource._totalCount,
      });
    } else if (this.selectedRows) {
      this.exportFakirService.selections.next({
        ...value,
        RKWB: this.selectedRows.length,
      });
    }
    this.event.isExportFKVisible.set(true);
  }

  cancelChoosed() {
    this.deselectAllRecords();
    const value = this.exportFakirService.selections.getValue();
    this.exportFakirService.selections.next({
      ...value,
      RKWB: 0,
    });
    this.event.isExportFKVisible.set(true);
  }

  skipSendSelectionEvent = false;

  selectAll() {
    this.isAllSelected = true;
    this.appService
      .postAuth(`finances/selection/cashBankReports`, this.paramsObj)
      .subscribe(() => {});
  }

  deselectAllRecords() {
    this.isAllSelected = false;
    return this.event.deselectAllRecords('finances/selection/cashBankReports');
  }

  async printAfterSave(_e) {
    let financialRaport = await new Promise<FinancialRaport>((resolve) => {
      const params = {
        ReportId: _e.document.ReportId,
        SourceId: _e.document.SourceId,
        ReportType: _e.document.ReportType,
      };
      let queryStr = '?';
      for (let key in params) {
        queryStr += `${key}=${params[key]}&`;
      }
      this.appService.getAuth(`finances/cashBankReports${queryStr}`).subscribe({
        next: (res) => {
          if (res.data.length > 0) {
            resolve(res.data[0]);
          } else {
            resolve(null);
          }
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
          resolve(null);
        },
      });
    });
    let printData = {
      code: 'Raport_finansowy',
      currencyCode: financialRaport.CurrencySymbol,
      documentNumber: financialRaport.Number,
      // fiscalPrinterEnabled: e.fiscalPrinterEnabled,
      menuName: financialRaport.MenuName,
      objectId: financialRaport.ReportId,
      type: financialRaport.ReportType,
      documentsToPrint: _e.documentsToPrint,
      reportId: financialRaport.ReportType === 'k' ? 115 : 116,
    };
    let reportData: ReportData = this.print.getReportData(
      printData,
      financialRaport.ReportType
    );
    const reportTypes = await this.print.getReportTypes(
      financialRaport.MenuName
    );
    reportData.reportTypeId = reportTypes[0].ReportId;
    reportData.report = this.print.getReportTemplate(
      reportData.menuName,
      reportData.type,
      reportTypes
    );
    await this.print.printReport(reportData);
  }
}

interface FinancialRaport {
  Amount: number;
  AutoNumber: number;
  CloseBalance: number;
  CurrencySymbol: string;
  Date: string;
  DateFrom: string;
  DateTo: string;
  Income: number;
  MenuName: string;
  Number: string;
  OpenBalance: number;
  Outflow: number;
  ReportId: number;
  ReportType: 'k' | 'b';
  ReportTypeId: number;
  SourceId: number;
  SplitPaymentId: number;
}

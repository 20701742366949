import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  signal,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';

import { EventService } from '../../../../event.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SelectedListComponent } from '../../../../libraries/articles/selected-articles-list/selected-list.component';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { AppServices } from '../../../../app-services.service';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

interface RequestObject {
  InvoiceDocumentTemplateId: number;
  TemplateDictionaryId: number;
}

@Component({
    selector: 'app-add-list-customer-template',
    imports: [
        CommonModule,
        DxPopupModule,
        DxTemplateModule,
        DxButtonModule,
        DxScrollViewModule,
        TranslateModule,
        SelectedListComponent,
        FormsModule,
        ReactiveFormsModule,
        DxTextBoxModule,
        DxTooltipModule,
        NgShortcutsComponent,
    ],
    templateUrl: './add-list-customer-template.component.html',
    styleUrl: './add-list-customer-template.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddListCustomerTemplateComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() isVisible: boolean = false;
  @Input() InvoiceDocumentTemplateId: number = 0;
  @Input() selected;
  @Output() onClosing = new EventEmitter();
  @Output() onInsert = new EventEmitter();

  widthWindow: number | string | Function = 555;
  heightWindow: number | string | Function = 200;
  title: string = '';
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  isShowList = signal<boolean>(false);
  form;
  submitted = signal<boolean>(false);
  shortcuts: ShortcutInput[] = [];

  constructor(
    public event: EventService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private appService: AppServices
  ) {
    this.initForm();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.isVisible.currentValue &&
      changes.selected &&
      changes.selected.currentValue.length > 0
    ) {
      this.form.patchValue({
        InvoiceDocumentTemplateId: this.selected[0].InvoiceDocumentTemplateId,
        TemplateDictionaryId: this.selected[0].TemplateDictionaryId,
      });
    }
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }

  ngAfterViewInit() {
    this.initTranslate();
    this.initShortcuts();
  }

  visibleChange(e): void {
    if (!e) this.onClosing.emit(false);
  }

  closeWindow(): void {
    this.onClosing.emit(false);
    this.isVisible = false;
  }

  private initTranslate() {
    this.title = this.translate.instant('form-commercial-operation.addList');
  }

  onSelectedListChoosed(e) {
    this.isShowList.set(false);
    if (e) {
      this.form.controls['TemplateDictionaryId'].setValue(e.SelectedListId);
    }
  }

  onSave() {
    this.submitted.set(true);

    if (this.form.invalid) return;

    const object: RequestObject = this.form.value;

    this.appService
      .putAuth(`invoices/additionalOperations/templateDictionary`, object)
      .subscribe({
        next: () => {
          this.handleSuccessfulResponse();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }

  private handleSuccessfulResponse() {
    this.onInsert.emit(true);
    this.isVisible = false;
  }

  private initForm() {
    this.form = this.fb.group({
      InvoiceDocumentTemplateId: [this.InvoiceDocumentTemplateId],
      TemplateDictionaryId: [0, Validators.required],
    });
  }

  private initShortcuts() {
    this.shortcuts = [
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      },
    ];
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

@Component({
    selector: 'app-add-currency-sum',
    templateUrl: './add-currency-sum.component.html',
    styleUrls: ['./add-currency-sum.component.scss'],
    inputs: [
        'isVisible',
        'title',
        'readOnly',
        'mode',
        'ReportType',
        'SourceId',
        'CurrencySymbol',
        'selectedId',
        'ReportId',
    ],
    standalone: false
})
export class AddCurrencySumComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @ViewChild('currencyBox') currencyBox;

  isVisible;
  title;
  readOnly;
  unicalGuid;
  widthWindow = '570';
  heightWindow = '360';
  maxHeight;
  maxWidth;
  mode;
  dataSource;
  form;
  SourceId;
  ReportType;
  CurrencySymbol;
  selectedId;
  ReportId;
  editStatePermission = false;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
    this.editStatePermission = this.event.checkPermissions('RKWBDokFinStan');
  }

  ngOnInit() {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.onCreateForm();
  }

  ngOnChanges() {
    if (this.isVisible && this.mode == 'edit') {
      this.form.patchValue(this.selectedId[0]);
      this.cd.detectChanges();
    }
  }

  shortcuts: ShortcutInput[] = [];
  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onCancel();
        },
        preventDefault: true,
      },
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  onCreateForm() {
    this.form = this.formBuilder.group({
      ReportId: [0],
      CurrencyReportId: [0],
      CurrencySymbol: ['', Validators.required],
      OpenBalance: [0, Validators.required],
      OpenBalanceCurrency: [0, Validators.required],
      Income: [0],
      Outflows: [0],
      ClosingBalance: [0],
      ClosingBalanceCurrency: [0],
    });
  }

  myTimerWarehouse;
  onValueChanged() {
    if (this.myTimerWarehouse) {
      clearTimeout(this.myTimerWarehouse);
    }
    this.myTimerWarehouse = setTimeout(() => {
      this.form.controls['ClosingBalance'].setValue(
        this.form.value.OpenBalance
      );
      if (this.form.controls['CurrencySymbol'].value === 'PLN') {
        this.form.controls['OpenBalanceCurrency'].setValue(
          this.form.value.OpenBalance
        );
        this.form.controls['ClosingBalanceCurrency'].setValue(
          this.form.value.OpenBalance
        );
      }
    }, 100);
  }

  onChoosedCurrency(e) {
    if (e) {
      this.form.controls['CurrencySymbol'].setValue(e.CurrencyCode);
    }
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.onCreateForm();
    }
  };

  onCancel() {
    this.isVisible = false;
    this.cd.detectChanges();
  }

  submitted: boolean = false;
  onSave() {
    this.form.controls['ReportId'].setValue(this.ReportId);
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.value.CurrencyReportId == 0) {
      this.appService
        .postAuth(
          `finances/cashBankReports/${this.form.value.ReportId}/sums`,
          this.form.value
        )
        .subscribe(
          () => {
            this.isVisible = false;
            this.onRefresh.emit(true);
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
      return;
    }

    this.appService
      .putAuth(
        `finances/cashBankReports/${this.form.value.ReportId}/sums/${this.form.value.CurrencyReportId}`,
        this.form.value
      )
      .subscribe(
        () => {
          this.isVisible = false;
          this.onRefresh.emit(true);
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }
}

import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { forkJoin } from 'rxjs';
import { NestService } from 'src/app/nest-service.service';
import {
  DefaultObject,
  NestFinishedEvent,
  NestOperation,
} from 'src/app/nest/nest.model';
import { Socket } from 'src/app/socket.model';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { HelpService } from '../../../help-service.service';

@Component({
    selector: 'app-new-item',
    templateUrl: './new-item.component.html',
    styleUrls: ['./new-item.component.scss'],
    inputs: [
        'isVisible',
        'IsBaseCurrency',
        'FinanceDocumentId',
        'position',
        'PayerName',
        'PayerId',
        'DateDocument',
        'CurrencyFactor',
        'IsRestBaseCurrency',
        'amountInBasyCurrency',
        'totalAmount',
        'componentNests',
        'mode',
        'Signature',
        'CurrencyCode',
    ],
    standalone: false
})
export class NewItemComponent implements OnInit, AfterViewInit {
  @Input() parentObjectParameters: DefaultObject = {};
  @Output() nestParentUpdated: EventEmitter<DefaultObject> = new EventEmitter();
  nestObjectParameters: DefaultObject = null;
  @Output() onClosing = new EventEmitter();
  @Output() onAdding = new EventEmitter();
  @ViewChild('firsFocus') firsFocus;
  @ViewChild('secondFocus') secondFocus;
  @ViewChild('btnSave') btnSave;

  componentNests: Socket;
  isVisible;
  remarksFault: boolean = false;
  FinanceDocumentId;
  position;
  PayerName;
  DateDocument;
  CurrencyFactor;
  IsRestBaseCurrency;
  PayerId;
  amountInBasyCurrency;
  totalAmount;
  mode;
  Signature;
  CurrencyCode;

  item: itemComercialOperation = {
    r: false,
    itemDescription: '',
    amount: 0,
    currencyAmount: 0,
    payerName: '',
    impact: '',
    outflow: '',
    datePayer: '',
  };

  saveGenerateList = [
    { value: 0, label: 'data dokumentu finansowego' },
    { value: 1, label: 'data ustalona' },
    { value: 2, label: 'termin nieskończony' },
  ];

  widthWindow = 650;
  submitted: boolean = false;
  format = '#0.00';
  formatCurrency = '#0.0000';
  IsBaseCurrency;
  positionTypeValue: boolean = false;

  shortcuts: ShortcutInput[] = [];

  howToAddItems = [
    { value: 2, label: 'rozlicz rozrachunki' },
    { value: 0, label: 'brak zapisu w rozrachunkach' },
    { value: 1, label: 'generuj zapis w rozrachunkach (zalizka)' },
  ];

  paymentMethod;
  addDescription: any;
  itemForm;
  isVisibleSettlements: boolean = false;

  @HostListener('window:keydown.F10', ['$event'])
  addCompany(event: KeyboardEvent) {
    event.preventDefault();
  }
  unicalGuid;
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public event: EventService,
    public appService: AppServices,
    public cd: ChangeDetectorRef,
    private helpService: HelpService,
    private nestService: NestService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    this.createForm();
    if (window.innerWidth < 570) this.widthWindow = 360;

    this.translate
      .get('form-financial-document.settlements')
      .subscribe((text) => (this.howToAddItems[0].label = text));
    this.translate
      .get('form-financial-document.noEntryInTheSettlements')
      .subscribe((text) => (this.howToAddItems[1].label = text));
    this.translate
      .get('form-financial-document.generateAnEntryInSettlements')
      .subscribe((text) => (this.howToAddItems[2].label = text));

    this.translate
      .get('form-financial-document.financialDocumentDate')
      .subscribe((text) => {
        this.saveGenerateList[0].label = text;
        this.paymentMethod = this.saveGenerateList[0].value;
      });
    this.translate
      .get('form-financial-document.dateSet')
      .subscribe((text) => (this.saveGenerateList[1].label = text));
    this.translate
      .get('form-financial-document.infinitePeriod')
      .subscribe((text) => (this.saveGenerateList[2].label = text));

    // setTimeout(() => {
    //   this.howToAddItems.push(this.translate.instant('form-financial-document.settlements'))
    //   this.howToAddItems.push(this.translate.instant('form-financial-document.noEntryInTheSettlements'))
    //   this.howToAddItems.push(this.translate.instant('form-financial-document.generateAnEntryInSettlements'))
    // }, 0)
  }

  createForm = () => {
    this.itemForm = this.formBuilder.group({
      PositionId: 0,
      FinanceDocumentId: 0,
      Description: ['', Validators.required],
      Amount: [0, [Validators.required, Validators.min(0.01)]],
      SettlementGeneratedType: [0],
      PositionType: '',
      IsBaseCurrency: this.IsRestBaseCurrency,
      AmountCurrency: [0],
      AdvancePayment: [null],
      SettlementId: [0],
      DataInsert: [0],
      PayerName: [this.PayerName],
      Number: [''],
      SettlementDate: [''],
      TotalToPay: [null],
      PaymentDate: [null],
      TotalAmountGross: [null],
      SettlementType: [''],
    });
  };

  setFocus(): void {
    if (!this.IsBaseCurrency) {
      this.event.setFocusWithSelect(this.firsFocus);
    } else {
      this.event.setFocusWithSelect(this.secondFocus);
    }
  }

  checkAmount = () => {
    if (this.totalAmount === 0) return;
    this.itemForm.controls.Amount.setValue(
      this.amountInBasyCurrency - this.totalAmount
    );
    this.cd.detectChanges();
  };

  //I count Amount when CurrencyFactor is not 0
  countAmount = () => {
    if (this.CurrencyFactor != 0)
      this.itemForm.controls.Amount.setValue(
        Number(
          (
            this.itemForm.get('AmountCurrency').value * this.CurrencyFactor
          ).toFixed(2)
        )
      );
  };

  onValueSettlementTypeChange = (e) => {
    this.itemForm.controls.PaymentDate.setValue(null);
    this.itemForm.controls.AdvancePayment.setValue(null);
    this.paymentMethod = this.saveGenerateList[0].value;

    if (e.value == 2 && this.mode == 'add') {
      this.itemForm.controls.Amount.setValue(0);
      this.itemForm.controls.AmountCurrency.setValue(0);
      this.isVisibleSettlements = true;
      this.cd.detectChanges();
    }

    if (e.value == 1) {
      if (this.paymentMethod == 0) {
        this.itemForm.controls.PaymentDate.setValue(this.DateDocument);
        this.itemForm.controls.AdvancePayment.setValue(1);
      }
    }
  };

  signature;
  isAbs: boolean = false;
  ngOnChanges(changes: SimpleChanges) {
    if (this.isVisible) this.checkAmount();
    if (this.position && this.isVisible) {
      if (this.position.length > 0) {
        this.appService
          .getAuth(
            `finances/documents/${this.FinanceDocumentId}/positions?positionId=${this.position[0]}`
          )
          .subscribe((res) => {
            setTimeout(() => {
              this.isAbs = false;
              this.itemForm.patchValue(res.data[0]);
              if (this.itemForm.value.Amount < 0) {
                this.isAbs = true;
                this.itemForm.controls.Amount.setValue(
                  this.itemForm.value.Amount * -1
                );
                this.itemForm.controls.AmountCurrency.setValue(
                  this.itemForm.value.AmountCurrency * -1
                );
              }
            }, 0);

            this.itemForm.controls.IsBaseCurrency.setValue(
              this.IsRestBaseCurrency
            );
          });
      }
    }
    try {
      this.itemForm.controls.IsBaseCurrency.setValue(this.IsRestBaseCurrency);
    } catch {}

    if (changes?.isVisible?.currentValue) {
      if (this.position) {
        this.findSocketAndRun('XGFPPO1');
      } else {
        this.findSocketAndRun('XGFPDO1');
      }
    }
  }

  onChoosedSettlements = (e) => {
    let arrayForkjoin: any = [];

    e.forEach((field) => {
      let AutoTytulRozrach =
        this.event.getConfigurationParameter('AutoTytulRozrach')?.Value;

      let SettlementDate = new DatePipe('en-US').transform(
        new Date(field.SettlementDate),
        'yyyy-MM-dd'
      );

      // let PaymentDate = new DatePipe('en-US').transform(
      //   new Date(field.PaymentDate),
      //   'yyyy-MM-dd'
      // );
      this.itemForm.controls.SettlementId.setValue(field.SettlementId);

      if (AutoTytulRozrach == 'Numer i data')
        this.itemForm.controls.Description.setValue(
          `${field.Number} z dn. ${SettlementDate}`
        );
      if (AutoTytulRozrach == 'Numer')
        this.itemForm.controls.Description.setValue(`${field.Number}`);
      this.itemForm.controls.Amount.setValue(field.TotalAmountGross);
      this.itemForm.controls.AmountCurrency.setValue(
        field.TotalGrossAmountCurrency
      );

      this.itemForm.controls.FinanceDocumentId.setValue(this.FinanceDocumentId);
      this.itemForm.controls.SettlementGeneratedType.setValue(2);
      this.itemForm.controls.PayerName.setValue(field.PayerName);
      this.itemForm.controls.Number.setValue(field.Number);
      this.itemForm.controls.SettlementDate.setValue(field.SettlementDate);
      this.itemForm.controls.TotalToPay.setValue(field.TotalToPay);
      this.itemForm.controls.TotalAmountGross.setValue(field.TotalAmountGross);
      if (this.itemForm.value.DataInsert == 0)
        this.itemForm.controls.DataInsert.setValue(new Date().getTime());

      if (e[0].isNoSelect) {
        arrayForkjoin.push(
          this.appService.postAuth(`finances/selection/settlements/select`, {
            ObjectIds: [e[0].SettlementId],
          })
        );
      }
    });

    arrayForkjoin.push(
      this.appService.postAuth(
        `finances/documents/${this.FinanceDocumentId}/positions`,
        this.itemForm.value
      )
    );

    forkJoin(arrayForkjoin).subscribe(
      () => {
        this.onAdding.emit(this.itemForm.value);
        this.createForm();
        this.submitted = false;
        this.isVisibleSettlements = false;
        setTimeout(() => {
          this.openNestFinished = false;
        }, 500);
        this.onClosing.emit(true);
        this.event.deselectAllRecords(`finances/selection/settlements`);
      },
      (error) => {
        this.event.deselectAllRecords(`finances/selection/settlements`);
        this.event.httpErrorNotification(error);
      }
    );
    return;
  };

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.refreshKeyPressed.next();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp('sprzedaz-i-magazyn/dokumenty/finansowe');
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          if (this.isVisible) {
            document.getElementById('btn-save-item').focus();
            this.addItem();
          }
        },
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.closeWindow();
        },
      }
    );
  }

  visibleChange = () => {
    if (!this.isVisible) {
      this.createForm();
      this.positionTypeValue = false;
    }
  };

  onValueChangedDate = (e) => {
    if (this.paymentMethod == 1) {
      localStorage.setItem('TermPlatDlaZal', e.value);
    }
  };

  onSelectValueChanged = (e) => {
    this.itemForm.controls.AdvancePayment.setValue(1);
    if (e.value == 2) {
      this.itemForm.controls.PaymentDate.setValue('9999-12-31');
      this.itemForm.controls.AdvancePayment.setValue(2);
    }
    if (e.value == 1 && localStorage.getItem('TermPlatDlaZal')) {
      this.itemForm.controls.PaymentDate.setValue(
        localStorage.getItem('TermPlatDlaZal')
      );
    }

    if (e.value == 0)
      this.itemForm.controls.PaymentDate.setValue(this.DateDocument);
  };

  onValueChanged = (e) => {
    this.item.datePayer = new DatePipe('en-US').transform(
      e.value,
      'yyyy-MM-dd'
    );
  };

  onChoosedDescription = (e) => {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });

      this.itemForm.controls.Description.setValue(text);
      this.remarksFault = false;
    }
  };

  closeWindow() {
    this.submitted = false;
    if (this.position) {
      this.findSocketAndRun('XGFPPA1');
    } else {
      this.findSocketAndRun('XGFPDA1');
    }
  }

  afterClosingWindow() {
    this.onClosing.emit(true);
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
  }

  positionTypeChanged = (e) => {
    if (e.value) {
      this.itemForm.controls.PositionType.setValue('P');
    } else this.itemForm.controls.PositionType.setValue('');
  };

  addItem = () => {
    this.itemForm.controls.IsBaseCurrency.setValue(this.IsBaseCurrency);
    this.submitted = true;
    this.itemForm.controls.PayerName.setValue(this.PayerName);

    if (this.itemForm.valid) {
      if (this.FinanceDocumentId != 0)
        this.itemForm.controls.FinanceDocumentId.setValue(
          this.FinanceDocumentId
        );
      if (this.itemForm.value.DataInsert == 0)
        this.itemForm.controls.DataInsert.setValue(new Date().getTime());

      if (this.position) {
        this.findSocketAndRun('XGFPPZ1');
      } else {
        this.findSocketAndRun('XGFPDZ1');
      }
    }
  };

  resAddItemObject = null;
  addItemApi() {
    if (this.itemForm.value.PositionId == 0) {
      if (this.isAbs) {
        this.itemForm.controls.Amount.setValue(this.itemForm.value.Amount * -1);
        this.itemForm.controls.AmountCurrency.setValue(
          this.itemForm.value.AmountCurrency * -1
        );
      }
      this.appService
        .postAuth(
          `finances/documents/${this.FinanceDocumentId}/positions`,
          this.itemForm.value
        )
        .subscribe(
          (res) => {
            this.resAddItemObject = res;
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            if (this.position) {
              this.findSocketAndRun('XGFPPZ2');
            } else {
              this.findSocketAndRun('XGFPDZ2');
            }
          }
        );
    } else if (this.itemForm.value.PositionId == 0) {
      this.resAddItemObject = this.itemForm.value;
      if (this.position) {
        this.findSocketAndRun('XGFPPZ2');
      } else {
        this.findSocketAndRun('XGFPDZ2');
      }
    } else {
      if (this.isAbs) {
        this.itemForm.controls.Amount.setValue(this.itemForm.value.Amount * -1);
        this.itemForm.controls.AmountCurrency.setValue(
          this.itemForm.value.AmountCurrency * -1
        );
      }
      this.appService
        .putAuth(
          `finances/documents/${this.FinanceDocumentId}/positions/${this.itemForm.value.PositionId}`,
          this.itemForm.value
        )
        .subscribe(
          (res) => {
            res.PositionId = this.itemForm.value.PositionId;
            res.AmountCurrency = this.itemForm.value.AmountCurrency;
            res.Amount = this.itemForm.value.Amount;
            res.Description = this.itemForm.value.Description;
            this.resAddItemObject = res;
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            if (this.position) {
              this.findSocketAndRun('XGFPPZ2');
            } else {
              this.findSocketAndRun('XGFPDZ2');
            }
          }
        );
    }
  }

  afterAddItemApi() {
    this.onAdding.emit(this.resAddItemObject);
    this.createForm();
    this.submitted = false;
    this.onClosing.emit(true);
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
  }

  openNestFinished = false;
  isNestRunning = false;
  nestOperation: NestOperation = null;
  nest: Socket;
  findSocketAndRun(SocketCode: string, nestOperation: NestOperation = null) {
    this.nestObjectParameters = Object.assign({}, this.itemForm.value);
    this.nestOperation = nestOperation;
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) return;
    if (e.updatedParameters?.ParentObject) {
      e.updatedParameters.ParentObject =
        this.nestService.removeNonEditableVariables(
          e.updatedParameters.ParentObject,
          e.SocketCode,
          true
        );
      this.nestParentUpdated.emit(e.updatedParameters?.ParentObject);
    }
    if (e.updatedParameters?.Object) {
      e.updatedParameters.Object = this.nestService.removeNonEditableVariables(
        e.updatedParameters.Object,
        e.SocketCode
      );
      this.itemForm.patchValue(e.updatedParameters?.Object, {
        emitEvent: false,
      });
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGFPPO1':
        this.findSocketAndRun('XGFPAO1', 'edit');
        break;
      case 'XGFPDO1':
        this.findSocketAndRun('XGFPAO1', 'add');
        break;
      case 'XGFPAO1':
        this.openNestFinished = true;
        if (this.nestOperation === 'edit') {
          this.findSocketAndRun('XGFPPO2');
        } else if (this.nestOperation === 'add') {
          this.findSocketAndRun('XGFPDO2');
        }
        break;
      case 'XGFPPO2':
        this.findSocketAndRun('XGFPAO2');
        break;
      case 'XGFPDO2':
        this.findSocketAndRun('XGFPAO2');
        break;
      case 'XGFPDA1':
        this.findSocketAndRun('XGFPAA1', 'add');
        break;
      case 'XGFPPA1':
        this.findSocketAndRun('XGFPAA1', 'edit');
        break;
      case 'XGFPAA1':
        if (this.nestOperation === 'add') {
          this.findSocketAndRun('XGFPDA2');
        } else if (this.nestOperation === 'edit') {
          this.findSocketAndRun('XGFPPA2');
        }
        break;
      case 'XGFPPA2':
        this.findSocketAndRun('XGFPAA2');
        break;
      case 'XGFPDA2':
        this.findSocketAndRun('XGFPAA2');
        break;
      case 'XGFPAA2':
        this.afterClosingWindow();
        break;

      case 'XGFPDZ1':
        this.findSocketAndRun('XGFPAZ1');
        break;
      case 'XGFPPZ1':
        this.findSocketAndRun('XGFPAZ1');
        break;
      case 'XGFPAZ1':
        this.addItemApi();
        break;

      case 'XGFPPZ2':
        this.findSocketAndRun('XGFPAZ2');
        break;
      case 'XGFPDZ2':
        this.findSocketAndRun('XGFPAZ2');
        break;
      case 'XGFPAZ2':
        this.afterAddItemApi();
        break;
    }
  }

  isExpansionListVisible = false;
  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGF');
    }
  }
}

export interface itemComercialOperation {
  r: boolean;
  itemDescription: string;
  amount: number;
  currencyAmount: number;
  payerName: string;
  impact: string;
  outflow: string;
  datePayer: string;
}

import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { HelpService } from 'src/app/help-service.service';
import { NestFinishedEvent, NestOperation } from 'src/app/nest/nest.model';
import { Socket } from 'src/app/socket.model';
import { environment } from '../../../../environments/environment';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { NestService } from './../../../nest-service.service';
import { OnInsertedData } from '../../new-comercial-operation/new-comercial-operation.component';

@Component({
  selector: 'app-correction-invoice',
  templateUrl: './correction.component.html',
  styleUrls: ['./correction.component.scss'],
  inputs: [
    'isVisible',
    'parentCurrencyCode',
    'selectedId',
    'readOnly',
    'title',
    'selected',
    'typeOfDocument',
    'componentNests',
    'selectedDocument',
    'componentNests',
  ],
  standalone: false,
})
export class CorrectionComponentInvoice implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onPrintCorrectionAfterSave = new EventEmitter();
  @ViewChild('reports') reports: ReportsComponent;
  @Output() onRefreshCard = new EventEmitter();

  helpService = inject(HelpService);
  componentNests: Socket;
  isVisible;
  selectedId;
  readOnly = false;
  title;
  selected;
  widthWindow: any = '75%';
  heightWindow: any = '620px';
  selectedDocument;
  typeOfDocument;
  isEditCustomer: boolean = false;
  headerDocument = {
    InvoiceDocumentNumber: '',
    DateOfInvoiceDocument: '',
    CustomerName: '',
  };

  sumPosition = {
    TotalNetAmount: 0,
    TotalGrossAmount: 0,
    TotalTaxAmount: 0,
    TotalNetAmountAfterCorrection: 0,
    TotalGrossAmountAfterCorrection: 0,
    TotalTaxAmountAfterCorrection: 0,
    TotalNetAmountCorrection: 0,
    TotalGrossAmountCorrection: 0,
    TotalTaxAmountCorrection: 0,
  };

  unicalGuid;

  dataSource;
  selectedRows = [];

  heightGrid = 345;
  shortcuts: ShortcutInput[] = [];
  addRow: boolean = false;
  editRow: boolean = false;
  sumPositionIncome;
  showCorrection: boolean = false;
  editCorrection: boolean = false;
  myEventDelete;
  myEventEdit;
  myEditShow;

  isNestRunning = false;
  openNestFinished: boolean = false;
  mode;

  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  focusedRowIndex;
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;

  focusedSelected = [];

  loadChildComponent: boolean = false;
  correctionDocumetn;
  InvoiceDocumentOriginalId;
  isDeleteRow: boolean = false;

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  groupMsg = {
    textInfo: 'form-commercial-operation.inOrderCorrection',
    list: [
      {
        textMsg: 'form-commercial-operation.registerTheOriginalDocument',
        readMore: null,
      },
      {
        textMsg: 'form-commercial-operation.enterTheCorrectionData',
        readMore: null,
      },
    ],
  };

  event = inject(EventService);

  KorDokHandlD;
  KorDokHandlDZB;
  KorDokHandlKontr;

  exportList = [
    { id: 0, text: 'korektę', disabled: true },
    { id: 1, text: 'korektę kontrahenta', disabled: true },
    {
      id: 2,
      text: 'korektę do nierejestrowanego dokumentu',
      disabled: true,
      visible: !this.event.footerInfo.VariantName.includes('FAKTUROWANIE'),
    },
    { id: 3, text: 'korektę zbiorczą', disabled: true },
  ];

  wrapperAttr = {
    class: 'correctionInvoices',
  };

  IsOutcomeInvoice: boolean = false;
  isVatVerification: boolean = false;
  sumPositionVat: any;
  refreshPrintSettings: boolean = false;
  sendToEDocumentsAfterSave: any;
  parentCurrencyCode: string;
  isCorrectionFZUE: boolean = false;
  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private nestService: NestService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.translate
      .get('form-commercial-operation.addCorrection')
      .subscribe((text) => (this.exportList[0].text = text));
    this.translate
      .get('form-commercial-operation.addCustomerCorrection')
      .subscribe((text) => (this.exportList[1].text = text));
    this.translate
      .get('form-commercial-operation.addRevisionToUnregisteredDocument')
      .subscribe((text) => (this.exportList[2].text = text));
    this.translate
      .get('form-commercial-operation.addBulkRevision')
      .subscribe((text) => (this.exportList[3].text = text));
  }

  ngOnInit(): void {
    this.perABD = this.event.checkPermissionsBtn(
      'KorDokHandlD',
      'KorDokHandlEOryg',
      'KorDokHandlU',
      'ODokHandl'
    );

    this.KorDokHandlD = this.event.checkPermissions('KorDokHandlD');
    this.KorDokHandlKontr = this.event.checkPermissions('KorDokHandlKontr');
    this.KorDokHandlDZB = this.event.checkPermissions('KorDokHandlDZB');

    this.exportList[0].disabled = !this.KorDokHandlD;
    this.exportList[1].disabled = !this.KorDokHandlKontr;
    this.exportList[2].disabled = !this.KorDokHandlD;
    this.exportList[3].disabled = !this.KorDokHandlDZB;
  }

  ngOnDestroy() {
    this.IsOutcomeInvoice = false;
    this.onClosing.emit(true);
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  onItemClickExport = (e) => {
    switch (e.itemData.id) {
      case 0:
        this.addCorection();
        break;
      case 1:
        this.addCorection(true);
        break;
      case 2:
        this.addCorectionUnregister();
        break;
      case 3:
        if (!this.readOnly && this.KorDokHandlDZB) this.addBulkRevision();
        break;
    }
  };

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/dokumenty/handlowe/korekta'
          );
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.findSocketAndRun('XGHKLA1');
        },
        preventDefault: true,
      },
      {
        key: 'Shift + Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.KorDokHandlD)
            this.addCorectionUnregister();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.KorDokHandlDZB) this.addBulkRevision();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.KorDokHandlD) this.addCorection();
        },
        preventDefault: true,
      }
    );
    setTimeout(() => {
      this.loadChildComponent = true;
    }, 500);
  }

  chechSignature() {
    return new Promise((resolve) => {
      let data: any = localStorage.getItem('typesInvoice');
      if (data) {
        data = this.event.decryptString(data);
        const el = data.find(
          (el) => el.TypeId == this.selected[0].DocumentTypeId
        );
        resolve(el || null);
      } else {
        this.appService
          .getAuth(
            `document/sales/types/invoice?typeId=${this.selected[0].DocumentTypeId}`
          )
          .subscribe((res) => {
            resolve(res.data[0]);
          });
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.isVisible && this.selected) {
      let IsOutcome: any = await this.chechSignature();
      if (
        IsOutcome.DocumentType.trim() == 'z' ||
        IsOutcome.DocumentType.trim() == 'kz'
      ) {
        this.IsOutcomeInvoice = true;
      }

      this.headerDocument.InvoiceDocumentNumber =
        this.selected[0].InvoiceDocumentNumber;
      this.headerDocument.DateOfInvoiceDocument =
        this.selected[0].DateOfInvoiceDocument;
      this.headerDocument.CustomerName = this.selected[0].CustomerName;

      this.getDocuments();
    }

    if (changes?.isVisible?.currentValue) {
      this.findSocketAndRun('XGHKLO1');
    }
    this.cd.detectChanges();
  }

  showCorrectionDocument = () => {
    if (this.perABD.showBtn && this.focusedSelected.length > 0) {
      this.mode = 'show';
      this.correctionDocumetn = this.focusedSelected;
      this.correctionDocumetn[0].isNew = false;
      this.addRow = true;
      this.cd.detectChanges();
    }
  };

  editCorrectionDocument = () => {
    if (this.perABD.editBtn && this.focusedSelected.length > 0) {
      this.findSocketAndRun('XGHKLYP1');
    }
  };

  beforeEditCorrection() {
    this.appService
      .putAuth(
        `invoices/documents/${this.focusedSelected[0].InvoiceDocumentId}/prepareForModify`,
        {
          invoiceDocumentId: this.focusedSelected[0].InvoiceDocumentId,
        }
      )
      .subscribe(
        () => {
          this.event.loadingVisible = false;
          this.mode = 'edit';
          this.correctionDocumetn = this.focusedSelected;
          this.correctionDocumetn[0].isNew = false;
          this.addRow = true;
          this.cd.detectChanges();
        },
        (error) => {
          localStorage.removeItem('editInvoicesDocumentId');
          this.event.loadingVisible = false;
          this.cd.detectChanges();
          this.event.httpErrorNotification(error);
        }
      );
  }

  getCorrectionSums = () => {
    let idDocument = this.selected[0].InvoiceDocumentOrginalId
      ? this.selected[0].InvoiceDocumentOrginalId
      : this.selected[0].InvoiceDocumentId;
    this.appService
      .getAuth(`invoices/documents/${idDocument}/corrections/sums`)
      .subscribe(
        (res) => {
          this.sumPosition = res;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  indexInsert: number = 0;
  onInsertedDocument = (_e: OnInsertedData) => {
    this.indexInsert++;
    if (this.indexInsert > 1) {
      this.indexInsert = 0;
      return;
    }
    this.unregisterSum = null;
    this.isVatVerification = false;
    // this.dataSource.reload();
    // this.getCorrectionSums();

    if (this.mode === 'edit') {
      this.findSocketAndRun('XGHKLYP2');
    } else if (this.mode === 'add') {
      this.findSocketAndRun('XGHKLYD2');
    }
    this.onRefreshCard.emit(true);
    this.cd.detectChanges();
  };

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }

  onKeyDown(e) {
    if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  correctionSummary;
  isActiveButtonDelete: boolean = false;
  getDocuments = () => {
    let idDocument = this.selected[0].InvoiceDocumentOrginalId
      ? this.selected[0].InvoiceDocumentOrginalId
      : this.selected[0].InvoiceDocumentId;
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${idDocument}/corrections?isShowOnlyCorrections=true`,
        // loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          if (data.length > 0) {
            this.focusedRowIndex = 0;
            this.isActiveButtonDelete = true;
            this.focusedSelected = [data[this.focusedRowIndex]];
          }

          if (data.length == 0) {
            this.focusedSelected = [];
            this.isActiveButtonDelete = false;
          }

          if (this.selected[0].IsCorrectionDocument && data.length == 0) {
            this.isActiveButtonDelete = true;
          }
          this.getCorrectionSums();
        },
        deleteUrl: `${environment.domain}invoices/documents/${this.selected[0].InvoiceDocumentId}/corrections`,
      }),
      reshapeOnPush: true,
    });
  };

  closeWindow() {
    this.findSocketAndRun('XGHKLA1');
  }

  afterCloseWindow() {
    // this.isVisible = false;
    this.onClosing.emit(false);
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
  }

  visibleChange = (e) => {
    if (!e) {
      this.IsOutcomeInvoice = false;
      this.onClosing.emit(false);
      setTimeout(() => {
        this.openNestFinished = false;
      }, 500);
    }
    //this.createForm(); //reset form with initial value

    //this.selectedtab = 0;
    this.cd.detectChanges();
  };

  onRowDblClick = () => {
    this.editCorrectionDocument();
  };
  menuName: string = null;
  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
    this.menuName = e.row.data?.MenuName || null;

    if (this.focusedSelected.length > 0) {
      this.focusedSelected[0].TotalTaxAmountCurrency =
        this.focusedSelected[0].TotalGrossAmountCurrency -
        this.focusedSelected[0].TotalNetAmountCurrency;
      this.focusedSelected[0].TotalTaxAmount =
        this.focusedSelected[0].TotalGrossAmount -
        this.focusedSelected[0].TotalNetAmount;
    }
  };

  isCustomerCorrection = false;
  addCorection = (IsCustomerCorrection?: boolean) => {
    this.isCustomerCorrection = IsCustomerCorrection;
    if (!this.readOnly && this.perABD.addBtn) {
      this.findSocketAndRun('XGHKLYD1');
    }
  };

  addCorrectionApi() {
    this.event.onShown();
    let TypeId;
    let Signature;

    TypeId = this.returnSignatureAndType().TypeId;
    Signature = this.returnSignatureAndType().Signature;
    let object = {
      InvoiceDocumentId: this.selected[0].InvoiceDocumentOrginalId,
      InvoiceDocumentCorrectedId: this.selected[0].InvoiceDocumentOrginalId,
      InvoiceDocumentOriginalId: this.selected[0].InvoiceDocumentOrginalId,
      InvoiceDocumentLastCorrectionId:
        this.selected[0].InvoiceDocumentOrginalId,
      DateOfInvoiceDocument: new Date(),
      CustomerId: this.selected[0].CustomerId,
      PayerId: this.selected[0].PayerId,
      TypeId: TypeId,
      DateOfRecived: this.selected[0].DateOfReceived,
      IsCustomerCorrection: !this.isCustomerCorrection ? false : true,
      DateOfAgreedCorrection: new Date(),
      IsCorrectionAgreed: false,
    };

    let idDocument = this.selected[0].InvoiceDocumentOrginalId
      ? this.selected[0].InvoiceDocumentOrginalId
      : this.selected[0].InvoiceDocumentId;
    this.appService
      .getAuth(
        `invoices/documents/${idDocument}/corrections?isShowOnlyCorrections=false`
      )
      .subscribe(
        (res) => {
          if (res.length > 0) {
            object.InvoiceDocumentId = res[0].InvoiceDocumentOriginalId;
            object.InvoiceDocumentLastCorrectionId =
              res[0].InvoiceDocumentLastCorrectionId;
            object.InvoiceDocumentOriginalId = res[0].InvoiceDocumentOriginalId;
            object.InvoiceDocumentCorrectedId =
              res[0].InvoiceDocumentLastCorrectionId;
            this.getHeaderCorrection(object, Signature);
          } else {
            object.InvoiceDocumentId =
              this.selected[0].InvoiceDocumentOrginalId;

            object.InvoiceDocumentLastCorrectionId =
              this.selected[0].LastInvoiceDocumentCorrectionId;

            object.InvoiceDocumentOriginalId =
              this.selected[0].InvoiceDocumentOrginalId;

            object.InvoiceDocumentCorrectedId =
              this.selected[0].InvoiceDocumentCorrectionId;

            this.getHeaderCorrection(object, Signature);
          }
        },
        (error) => {
          this.event.httpErrorNotification(error);
          this.event.onHidden();
        }
      );
  }

  getHeaderCorrection = (object, Signature) => {
    object.IsCustomerCorrection
      ? (this.isEditCustomer = true)
      : (this.isEditCustomer = false);
    this.appService
      .postAuth(
        `invoices/documents/${object.InvoiceDocumentId}/corrections/header`,
        object
      )
      .subscribe(
        (response) => {
          this.appService
            .getAuth(
              `invoices/documents/${response.InvoiceDocumentId}/corrections/sums`
            )
            .subscribe((sum) => {
              this.correctionSummary = {
                TotalGrossAmountPrevious: sum.TotalGrossAmount,
                TotalNetAmountPrevious: sum.TotalNetAmount,
                TotalTaxAmountPrevious: sum.TotalTaxAmount,

                TotalNetAmount: sum.TotalNetAmountAfterCorrection,
                TotalGrossAmount: sum.TotalGrossAmountAfterCorrection,
                TotalTaxAmount: sum.TotalTaxAmountAfterCorrection,

                TotalGrossAmountCorrection: sum.TotalGrossAmountCorrection,
                TotalNetAmountCorrection: sum.TotalNetAmountCorrection,
                TotalTaxAmountCorrection: sum.TotalTaxAmountCorrection,
                TotalToPay: response.TotalToPay,
              };
              this.appService
                .getAuth(
                  `invoices/documents/${object.InvoiceDocumentCorrectedId}`
                )
                .subscribe((res) => {
                  let isAutoNumeration = this.event.checkAutoNumeration(
                    'InvoiceDocuments',
                    object.TypeId
                  );

                  if (res.IsFinalDocument) {
                    res.CurrentPayment = 0;
                    res.CurrentPaymentCurrency = 0;
                  }

                  this.correctionDocumetn = [res];
                  this.correctionDocumetn[0].InvoiceDocumentId =
                    response.InvoiceDocumentId;
                  this.correctionDocumetn[0].DateOfInvoiceDocument =
                    new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd');
                  this.correctionDocumetn[0].DocumentTypeId = object.TypeId;
                  this.correctionDocumetn[0].IsCorrectionDocument = true;
                  this.correctionDocumetn[0].Remarks = '';
                  this.correctionDocumetn[0].DocumentStatus = 'N';
                  this.correctionDocumetn[0].StatusComment = '';
                  this.correctionDocumetn[0].InvoiceDocumentNumber =
                    isAutoNumeration ? '<auto>' : '';
                  this.correctionDocumetn[0].DateOfPayment =
                    this.correctionDocumetn[0].DateOfInvoiceDocument;
                  this.correctionDocumetn[0].Signature = Signature;
                  this.correctionDocumetn[0].InvoiceDocumentOrginalId =
                    object.InvoiceDocumentOriginalId;
                  this.correctionDocumetn[0].InvoiceDocumentLastCorrectionId =
                    object.InvoiceDocumentLastCorrectionId;
                  this.correctionDocumetn[0].isNew = true;
                  this.mode = 'edit';
                  this.addRow = true;
                  this.event.setTitle.next(
                    this.translate.instant(
                      'form-commercial-operation.titleCorrect'
                    )
                  );
                  this.cd.detectChanges();
                  this.event.onHidden();
                });
            });
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  };

  isDelete = () => {
    if (
      !this.readOnly &&
      (this.dataSource._items.length > 0 || this.isActiveButtonDelete) &&
      this.perABD.deleteBtn
    ) {
      this.findSocketAndRun('XGHKLYU1');
    }
  };

  delete = () => {
    let InvoiceDocumentOrginalId = this.selected[0].InvoiceDocumentOrginalId;
    let LastInvoiceDocumentCorrectionId =
      this.selected[0].LastInvoiceDocumentCorrectionId;
    if (this.dataSource._items.length > 0) {
      InvoiceDocumentOrginalId =
        this.dataSource._items[0].InvoiceDocumentOriginalId;
      LastInvoiceDocumentCorrectionId =
        this.dataSource._items[0].InvoiceDocumentLastCorrectionId;
    }

    this.isDeleteRow = false;
    this.appService
      .deleteAuth(
        `invoices/documents/${InvoiceDocumentOrginalId}/corrections/${LastInvoiceDocumentCorrectionId}?isCancel=false`
      )
      .subscribe(
        () => {
          if (this.dataSource._items.length !== 0) {
            this.dataSource.reload();
          } else {
            this.isVisible = false;
          }

          this.onRefreshCard.emit(true);
        },
        (error) => {
          this.event.loadingVisible = false;
          this.event.httpErrorNotification(error);
        }
      );
  };

  addBulkRevision = () => {
    this.event.onShown();
    if (this.selectedDocument.length == 0) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.pleaseSelectTheDocumentsToWhichTheCollectiveCorrectionWillBeIssued'
        )
      );
      this.event.onHidden();
      return;
    }

    let object = {
      InvoiceDocumentTamplateId: this.selectedDocument[0],
      Date: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd'),
      SumBy: '',
    };

    this.appService
      .getAuth(`invoices/documents/${this.selectedDocument[0]}`)
      .subscribe((response) => {
        let TypeId;
        let Signature;

        TypeId = this.returnSignatureAndType().TypeId;
        Signature = this.returnSignatureAndType().Signature;

        let isAutoNumeration = this.event.checkAutoNumeration(
          'InvoiceDocuments',
          TypeId
        );

        this.correctionDocumetn = [response];

        this.correctionDocumetn[0].DateOfInvoiceDocument = new DatePipe(
          'en-US'
        ).transform(new Date(), 'yyyy-MM-dd');
        this.correctionDocumetn[0].DocumentTypeId = TypeId;
        this.correctionDocumetn[0].IsCorrectionDocument = true;
        this.correctionDocumetn[0].InvoiceDocumentNumber = isAutoNumeration
          ? '<auto>'
          : '';
        this.correctionDocumetn[0].DateOfPayment =
          this.correctionDocumetn[0].DateOfInvoiceDocument;
        this.correctionDocumetn[0].Signature = Signature;
        this.correctionDocumetn[0].InvoiceDocumentOrginalId =
          response.InvoiceDocumentOrginalId;
        this.correctionDocumetn[0].InvoiceDocumentLastCorrectionId =
          response.InvoiceDocumentOrginalId;
        this.correctionDocumetn[0].isNew = true;

        try {
          object.SumBy = response.SumBy.replace(' ', '');
        } catch {
          object.SumBy = response.SumBy;
        }
        this.appService
          .postAuth(`invoices/documents/headerBulkCorrection`, object)
          .subscribe(
            (res) => {
              this.appService
                .getAuth(
                  `invoices/documents/${res.InvoiceDocumentId}/corrections/sums`
                )
                .subscribe((sum) => {
                  this.correctionSummary = {
                    TotalGrossAmountPrevious: sum.TotalGrossAmount,
                    TotalNetAmountPrevious: sum.TotalNetAmount,
                    TotalTaxAmountPrevious: sum.TotalTaxAmount,

                    TotalNetAmount: sum.TotalNetAmountAfterCorrection,
                    TotalGrossAmount: sum.TotalGrossAmountAfterCorrection,
                    TotalTaxAmount: sum.TotalTaxAmountAfterCorrection,

                    TotalGrossAmountCorrection: sum.TotalGrossAmountCorrection,
                    TotalNetAmountCorrection: sum.TotalNetAmountCorrection,
                    TotalTaxAmountCorrection: sum.TotalTaxAmountCorrection,
                    TotalToPay: response.TotalToPay,
                  };

                  this.correctionDocumetn[0].InvoiceDocumentId =
                    res.InvoiceDocumentId;
                  this.correctionDocumetn[0].Remarks = '';
                  this.correctionDocumetn[0].DocumentStatus = 'N';
                  this.correctionDocumetn[0].StatusComment = '';
                  this.mode = 'edit';
                  this.addRow = true;
                  this.event.setTitle.next(
                    this.translate.instant(
                      'form-commercial-operation.titleCorrect'
                    )
                  );
                  this.cd.detectChanges();
                  this.event.onHidden();
                });
            },
            (error) => {
              this.event.loadingVisible = false;
              this.event.httpErrorNotification(error);
            }
          );
      });
  };

  isInfoCorrectionUnregister: boolean = false;
  addCorectionUnregister = () => {
    if (!this.readOnly && this.KorDokHandlD)
      this.isInfoCorrectionUnregister = true;
  };

  isUnregist: boolean = false;
  onAddUnregisterCorretion = () => {
    this.event.onShown();
    this.isInfoCorrectionUnregister = false;
    this.isUnregist = true;
  };

  returnSignatureAndType = (doc?) => {
    this.isCorrectionFZUE = false;
    let typeDocument = this.event.decryptString(
      localStorage.getItem('typesInvoice')
    );

    let correctionDocumentType = typeDocument
      .find((field) => field.TypeId == this.selected[0].DocumentTypeId)
      .Signature.replace('k', '');

    if (doc) {
      correctionDocumentType = typeDocument
        .find((field) => field.TypeId == doc.DocumentTypeId)
        .Signature.replace('k', '');
    }

    let TypeId;
    let Signature;

    correctionDocumentType = correctionDocumentType.replace('k', '');

    switch (correctionDocumentType) {
      case 'PR':
        TypeId = typeDocument.find((field) => field.Signature == 'PRk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'PRk'
        ).Signature;
        break;
      case 'DW':
        TypeId = typeDocument.find((field) => field.Signature == 'DWk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'DWk'
        ).Signature;
        break;
      case 'FV':
        TypeId = typeDocument.find((field) => field.Signature == 'FVk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'FVk'
        ).Signature;
        break;
      case 'FW':
        TypeId = typeDocument.find((field) => field.Signature == 'FVk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'FVk'
        ).Signature;
        break;
      case 'FN':
        TypeId = typeDocument.find((field) => field.Signature == 'FVk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'FVk'
        ).Signature;
        break;
      case 'FUE':
        TypeId = typeDocument.find((field) => field.Signature == 'FVk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'FVk'
        ).Signature;
        break;
      case 'FM':
        TypeId = typeDocument.find((field) => field.Signature == 'FMk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'FMk'
        ).Signature;
        break;
      case 'FR':
        TypeId = typeDocument.find((field) => field.Signature == 'FRk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'FRk'
        ).Signature;
        break;
      case 'FZ':
        TypeId = typeDocument.find((field) => field.Signature == 'FZk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'FZk'
        ).Signature;
        break;

      case 'FZUE':
        TypeId = typeDocument.find((field) => field.Signature == 'FZk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'FZk'
        ).Signature;
        this.isCorrectionFZUE = true;
        break;
      case 'PM':
        TypeId = typeDocument.find((field) => field.Signature == 'PMk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'PMk'
        ).Signature;
        break;
      case 'RZ':
        TypeId = typeDocument.find((field) => field.Signature == 'RZk').TypeId;
        Signature = typeDocument.find(
          (field) => field.Signature == 'RZk'
        ).Signature;
        break;
    }

    return { TypeId: TypeId, Signature: Signature };
  };

  unregisterSum;
  onInsertedDocumentUnregister = (e: OnInsertedData) => {
    this.event.onShown();
    this.isUnregist = false;
    this.correctionDocumetn = [e.document];

    let TypeId = this.returnSignatureAndType(e.document).TypeId;
    let Signature = this.returnSignatureAndType(e.document).Signature;

    let object = {
      InvoiceDocumentId: e.document.InvoiceDocumentId,
      InvoiceDocumentCorrectedId: e.document.InvoiceDocumentId,
      DateOfInvoiceDocument: new DatePipe('en-US').transform(
        new Date(),
        'yyyy-MM-dd'
      ),
      CustomerId: e.document.CustomerId,
      PayerId: e.document.PayerId,
      TypeId: TypeId,
      DateOfRecived: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd'),
      IsCustomerCorrection: false,
      DateOfAgreed: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd'),
      IsCorrectionAgreed: false,
    };

    this.appService
      .getAuth(
        `invoices/documents/${e.document.InvoiceDocumentId}/corrections/sums`
      )
      .subscribe((res) => {
        this.unregisterSum = res;
        this.appService
          .postAuth(
            `invoices/documents/${e.document.InvoiceDocumentId}/corrections/header`,
            object
          )
          .subscribe(
            (res) => {
              let isAutoNumeration = this.event.checkAutoNumeration(
                'InvoiceDocuments',
                object.TypeId
              );
              this.correctionDocumetn[0].DateOfInvoiceDocument = new DatePipe(
                'en-US'
              ).transform(
                new Date(this.correctionDocumetn[0].DateOfInvoiceDocument),
                'yyyy-MM-dd'
              );
              this.correctionDocumetn[0].DateOfRegistration = new DatePipe(
                'en-US'
              ).transform(
                new Date(this.correctionDocumetn[0].DateOfRegistration),
                'yyyy-MM-dd'
              );
              this.correctionDocumetn[0].DateOfSell = new DatePipe(
                'en-US'
              ).transform(
                new Date(this.correctionDocumetn[0].DateOfSell),
                'yyyy-MM-dd'
              );
              this.correctionDocumetn[0].DateOfReceived = new DatePipe(
                'en-US'
              ).transform(
                new Date(this.correctionDocumetn[0].DateOfReceived),
                'yyyy-MM-dd'
              );

              this.correctionDocumetn[0].InvoiceDocumentId =
                res.InvoiceDocumentId;
              this.correctionDocumetn[0].DocumentTypeId = object.TypeId;
              this.correctionDocumetn[0].IsCorrectionDocument = true;
              this.correctionDocumetn[0].Remarks = '';
              this.correctionDocumetn[0].DocumentStatus = 'N';
              this.correctionDocumetn[0].StatusComment = '';
              this.correctionDocumetn[0].InvoiceDocumentNumber =
                isAutoNumeration ? '<auto>' : '';
              this.correctionDocumetn[0].DateOfPayment =
                this.correctionDocumetn[0].DateOfInvoiceDocument;
              this.correctionDocumetn[0].Signature = Signature;
              this.correctionDocumetn[0].InvoiceDocumentOrginalId =
                object.InvoiceDocumentId;
              this.correctionDocumetn[0].InvoiceDocumentLastCorrectionId =
                object.InvoiceDocumentId;
              this.correctionDocumetn[0].isNew = true;
              this.mode = 'edit';
              this.addRow = true;
              this.cd.detectChanges();
              this.event.onHidden();
            },
            (error) => {
              this.event.onHidden();
              this.event.httpErrorNotification(error);
            },
            () => {
              // this.findSocketAndRun('XGHKLYD2');
            }
          );
      });
  };

  closeConfirm = () => {
    this.isDeleteRow = false;
    this.cd.detectChanges();
  };

  nest: Socket;
  nestOperation: NestOperation;

  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) return;
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGHKLO1':
        this.openNestFinished = true;
        this.findSocketAndRun('XGHKLO2');
        break;
      case 'XGHKLA1':
        this.findSocketAndRun('XGHKLA2');
        break;
      case 'XGHKLA2':
        this.afterCloseWindow();
        break;
      case 'XGHKLYD1':
        this.findSocketAndRun('XGHKLYA1', 'add');
        break;
      case 'XGHKLYP1':
        this.findSocketAndRun('XGHKLYA1', 'edit');
        break;
      case 'XGHKLYU1':
        this.findSocketAndRun('XGHKLYA1', 'delete');
        break;
      case 'XGHKLYA1':
        if (this.nestOperation === 'add') {
          this.addCorrectionApi();
        } else if (this.nestOperation === 'edit') {
          this.beforeEditCorrection();
        } else if (this.nestOperation === 'delete') {
          this.isDeleteRow = true;
        }
        break;
      case 'XGHKLYU2':
        // this.findSocketAndRun('XGHKLA2');
        this.dataSource.reload();
        break;
      case 'XGHKLYP2':
        // this.findSocketAndRun('XGHKLA2');
        this.dataSource.reload();
        break;
      case 'XGHKLYD2':
        // this.findSocketAndRun('XGHKLA2');
        this.dataSource.reload();
        break;
    }
  }

  isExpansionListVisible = false;
  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGA');
    }
  }

  correctVat() {
    this.isVatVerification = true;
    this.mode = 'edit';
    this.correctionDocumetn = this.focusedSelected;
    this.correctionDocumetn[0].isNew = false;
    this.addRow = true;
    this.cd.detectChanges();
  }

  onForceRefresh() {
    this.dataSource.reload();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  signal,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTooltipModule,
} from 'devextreme-angular';

import { EventService } from '../../../event.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppServices } from '../../../app-services.service';
import { InvoiceDocument } from '../../comercial-operation/invoice-document.model';
import { AddListCustomerTemplateComponent } from './add-list-customer-template/add-list-customer-template.component';
import { ConfirmDialogComponent } from '../../../core/confirm-dialog/confirm-dialog.component';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

interface TemplateDictionary {
  InvoiceDocumentTemplateId: number;
  TemplateDictionaryId: number;
  TemplateDictionaryName: string;
  TemplateName: string;
  Use: number;
}

@Component({
    selector: 'app-list-customer-template',
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        DxScrollViewModule,
        TranslateModule,
        DxDataGridModule,
        AddListCustomerTemplateComponent,
        ConfirmDialogComponent,
        NgShortcutsComponent,
        DxTooltipModule,
    ],
    templateUrl: './list-customer-template.component.html',
    styleUrl: './list-customer-template.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCustomerTemplateComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() isVisible: boolean = false;
  @Input() selectedTemplate: number[] = [];
  @Output() onClosing = new EventEmitter();

  widthWindow: number | string | Function = 500;
  heightWindow: number | string | Function = 560;
  title: string = '';
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  dataSource: TemplateDictionary[] = [];
  heightGrid: number = 300;
  selectedRows: TemplateDictionary[] = [];
  isHold = false;
  toDeselect = [];
  toSelect = [];
  timerSel: any;
  isQuickPress: boolean = true;
  focusedSelectedRow: TemplateDictionary[] = [];
  focusedRowIndex: number = 0;
  isEditRow = signal<boolean>(false);
  isDeleteRow = signal<boolean>(false);
  shortcuts: ShortcutInput[] = [];

  constructor(
    public event: EventService,
    private translate: TranslateService,
    private appService: AppServices,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.getData();
  }

  async onInsert() {
    this.isEditRow.set(false);
    this.getData();
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp();
  }

  ngAfterViewInit(): void {
    this.initTranslate();
    this.initShortcuts();
  }

  visibleChange(e): void {
    if (!e) this.onClosing.emit(false);
  }

  closeWindow(): void {
    this.onClosing.emit(false);
    this.isVisible = false;
  }

  private initTranslate() {
    this.title = this.translate.instant(
      'form-commercial-operation.listOfContractorsOfSelectedTemplates'
    );
  }

  delete() {
    this.isDeleteRow.set(false);
    this.appService
      .deleteAuth(
        `invoices/additionalOperations/templateDictionary?invoiceDocumentTemplateId=${this.focusedSelectedRow[0].InvoiceDocumentTemplateId}&templateDictionaryId=${this.focusedSelectedRow[0].TemplateDictionaryId}`
      )
      .subscribe({
        next: () => {
          this.getData();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }

  private async getData() {
    this.dataSource = [];
    for (const item of this.selectedTemplate) {
      await this.getTemplateDictionary(item);
    }
    this.focusedRowIndex = 0;
    if (this.dataSource.length > 0)
      this.focusedSelectedRow = [this.dataSource[0]];
  }

  onRowDblClick() {
    this.isEditRow.set(true);
  }

  onFocusedRowChanged = (e) => {
    this.focusedSelectedRow = [e.row.data];
  };

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }

  onKeyDown(e): void {
    if ([13, 27, 113, 114].includes(e.event.keyCode)) {
      e.event.preventDefault();
    }
  }

  editRow() {
    if (this.focusedSelectedRow.length === 0) return;
    this.isEditRow.set(true);
  }

  private getDataInvoice(id) {
    return new Promise((resolve) => {
      this.appService.getAuth(`invoices/documents/${id}`).subscribe({
        next: (data: InvoiceDocument) => {
          resolve(data.InvoiceDocumentNumber);
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
    });
  }

  private async getTemplateDictionary(id) {
    const InvoiceDocumentNumber = await this.getDataInvoice(id);
    return new Promise((resolve) => {
      this.appService
        .getAuth(
          `invoices/additionalOperations/templateDictionary?templateName=${InvoiceDocumentNumber}`
        )
        .subscribe({
          next: (data: TemplateDictionary[]) => {
            this.dataSource = this.dataSource.concat(data);
            this.cdr.detectChanges();
            resolve(true);
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
          },
        });
    });
  }

  private initShortcuts() {
    this.shortcuts = [
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.editRow();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isDeleteRow.set(true);
        },
        preventDefault: true,
      },
    ];
  }
}

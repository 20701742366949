import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { FinancialOperationSum } from './financial-operation-sum.model';

@Component({
    selector: 'app-financial-operation-sum',
    templateUrl: './financial-operation-sum.component.html',
    styleUrls: ['./financial-operation-sum.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FinancialOperationSumComponent {
  @Input() fromSelectionAvailable = false;
  @Input() readOnly = false;
  @Input() paramsObj: any = null;
  @Output() onClosing = new EventEmitter<void>();

  selectionDataSource = [
    {
      label: 'form-financial-document.sumFilteredDocs',
      value: false,
      disabled: false,
    },
    {
      label: 'form-financial-document.sumSelectedDocs',
      value: true,
      disabled: false,
    },
  ];
  tabs = [
    { title: '', translation: 'form-financial-document.sumDocs', value: 's' },
    { title: '', translation: 'form-financial-document.cashState', value: 'k' },
    { title: '', translation: 'form-financial-document.withMPP', value: 'v' },
    {
      title: '',
      translation: 'form-financial-document.sumCurrencies',
      value: 'w',
    },
  ];
  selectedTabIndex = 0;
  shortcuts: ShortcutInput[] = [];
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  vatMpp = false;
  useForSelection = false;
  dataSource = [];
  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    private cd: ChangeDetectorRef
  ) {
    this.getTranslations();
  }

  getTranslations() {
    for (let t of this.tabs) {
      t.title = this.translate.instant(t.translation);
    }
    for (let s of this.selectionDataSource) {
      s.label = this.translate.instant(s.label);
    }
  }

  ngOnInit() {
    this.selectionDataSource[1].disabled = !this.fromSelectionAvailable;
    if (this.fromSelectionAvailable) {
      this.useForSelection = true;
    }

    this.getData();
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTabIndex = 0;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTabIndex = 1;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTabIndex = 2;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 4',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTabIndex = 3;
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit();
    }
  };

  onMPPValueChanged(e) {
    if (!e.event) return;
    this.getData();
  }

  onRadioValueChanged(e) {
    if (!e.event) return;
    this.getData();
  }

  getTabType() {
    return this.tabs[this.selectedTabIndex].value;
  }

  getLoadParams() {
    const params = {
      tabType: this.getTabType(),
      vatMPP: this.vatMpp,
      OnlySelected: this.useForSelection,
    };
    if (this.paramsObj) {
      const extraParams = { ...this.paramsObj };
      delete extraParams['orderBy'];
      delete extraParams['order'];
      return { ...params, ...extraParams };
    }
    return params;
  }

  getData() {
    this.appService
      .getAuth(`finances/SummaryReports`, this.getLoadParams())
      .subscribe({
        next: (res: FinancialOperationSum[]) => {
          this.dataSource = this.parseTabData(res);
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  onSelectionTabChanged() {
    this.getData();
  }

  parseTabData(data: FinancialOperationSum[]) {
    if (this.selectedTabIndex === 0) {
      const record = data[0];
      return [
        {
          Title: 'Suma',
          Income: record.Income,
          Outcome: record.Outcome,
        },
        {
          Title: 'Saldo',
          Income: record.OpenBalance,
          Outcome: record.CloseBalance,
        },
      ];
    }

    return data;
  }
}

<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  (onShown)="event.onShownPopUp(); getDocument(); advanceDocumentSummary()"
  (onHidden)="
    event.onHiddenPopUp();
    dataSource = null;
    resetAdvaneDocumentSummary();
    IsFinalDocument = false
  "
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <!-- <dx-button text="{{'buttons.choose' |translate}}" type="success" style="margin-right: 6px;"
        [id]="'choosed'+unicalGuid" *ngIf="!readOnly" [disabled]="creadOnly"></dx-button> -->

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div style="margin-bottom: 12px; margin-top: 12px">
        <dx-drop-down-button
          class="correction-drop-down"
          text="{{ 'buttons.add' | translate }}"
          width="auto"
          icon="icon circle-add-correct"
          [items]="exportList"
          stylingMode="text"
          type="default"
          (onItemClick)="onItemClickExport($event)"
          itemTemplate="fontItem"
          [id]="'btn-add' + unicalGuid"
          data-cy="addAdvanceInvoice-btn"
        >
          <div *dxTemplate="let data of 'fontItem'">
            <span>{{ data.text }}</span>
          </div>
        </dx-drop-down-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--mode-edit"
          [text]="'buttons.edit' | translate"
          [id]="'btn-edit' + unicalGuid"
          (onClick)="editDocument()"
          [disabled]="
            readOnly || focusedSelectedRow.length == 0 || !perABD.editBtn
          "
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--form-items"
          [text]="'buttons.show' | translate"
          [id]="'show' + unicalGuid"
          (onClick)="showDocument()"
          [disabled]="
            readOnly || focusedSelectedRow.length == 0 || !perABD.showBtn
          "
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu"
          icon="icon absui-icon--highlight-off"
          [disabled]="readOnly"
          [text]="'warehouseDocument.deleteLastCorrection' | translate"
          [id]="'delete' + unicalGuid"
          (onClick)="isDelete()"
        >
        </dx-button>

        <app-reports
          #reports
          [code]="reportCode"
          [currencyCode]="
            focusedSelectedRow?.length
              ? focusedSelectedRow[0]?.CurrencyCode
              : null
          "
          [menuName]="
            focusedSelectedRow?.length ? focusedSelectedRow[0]?.MenuName : null
          "
          [objectId]="
            focusedSelectedRow?.length
              ? focusedSelectedRow[0]?.InvoiceDocumentId
              : null
          "
          [type]="type"
          [commercialDocument]="focusedSelectedRow[0]"
          (onForceRefresh)="onForceRefresh()"
          [isChild]="true"
          [params]="getLoadParams()"
        >
        </app-reports>

        <div
          style="display: inline-block"
          *ngIf="
            !IsFinalDocument &&
            advanceDocumentSummaryData.LeftTotalGrossAmount == 0 &&
            advanceDocumentSummaryData.FirstTotalGrossAmount != 0
          "
        >
          <dx-button
            class="btn-dx-top-menu"
            [disabled]="readOnly"
            [text]="
              'form-commercial-operation.generateWZ/SUforAdvanceInvoice'
                | translate
            "
            [id]="'generateWZSUforAdvanceInvoice' + unicalGuid"
            (onClick)="isGenerateWarehouseDocument()"
            data-cy="generateWarehouseDocument-btn"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu"
            [disabled]="readOnly"
            [text]="
              'form-commercial-operation.connectTheWZToTheAdvanceInvoice'
                | translate
            "
            [id]="'connectTheWZToTheAdvanceInvoice' + unicalGuid"
            (onClick)="connectTheWZToTheAdvanceInvoice()"
            data-cy="connectTheWZToTheAdvanceInvoice-btn"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu"
            [disabled]="readOnly"
            [text]="
              'form-commercial-operation.detachTheWZfromTheAdvanceInvoice'
                | translate
            "
            [id]="'detachTheWZfromTheAdvanceInvoice' + unicalGuid"
            (onClick)="isSelected = true; disconnectWarehouseDocument()"
            data-cy="detachTheWZfromTheAdvanceInvoice-btn"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu"
            [disabled]="readOnly"
            [text]="
              'form-commercial-operation.showTheWZtoTheAdvanceInvoice'
                | translate
            "
            [id]="'showTheWZtoTheAdvanceInvoice' + unicalGuid"
            (onClick)="isSelected = true; selectGeneratedWarehouseDocument()"
            data-cy="showTheWZtoTheAdvanceInvoice-btn"
          >
          </dx-button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            shortcut
            [height]="heightGrid"
            [(selectedRowKeys)]="selectedRows"
            [columnResizingMode]="'widget'"
            (onInitialized)="onInitialized($event)"
            [remoteOperations]="true"
            (onRowDblClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            (onContentReady)="onContentReady($event)"
            (onKeyDown)="onKeyDown($event)"
            [(focusedRowIndex)]="focusedRowIndex"
            [focusedRowEnabled]="true"
            #gridBank
            (onOptionChanged)="event.onOptionChanged($event)"
            data-cy="advanceInvoice-grid"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              [allowSorting]="false"
              cellTemplate="mainColumn"
              [width]="150"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'mainColumn'">
              <div class="hov-underline" style="text-decoration: none">
                {{ data.data.InvoiceDocumentNumber }}
              </div>
            </div>

            <dxi-column
              caption="{{
                'form-financial-document.payer-type.contractor' | translate
              }}"
              [allowSorting]="false"
              dataField="CustomerName"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.form.dateOfIssue' | translate
              }}"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="180"
              alignment="left"
              [allowSorting]="false"
              dataField="DateOfInvoiceDocument"
            ></dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="TotalNetAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalNetAmount'">
              {{
                cell.data.IsCurrencyDocument
                  ? (cell.data.TotalNetAmountCurrency | amountFormatter : cell)
                  : (cell.data.TotalNetAmount | amountFormatter : cell)
              }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.vatAmount' | translate
              }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="VATAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'VATAmount'">
              {{
                cell.data.IsCurrencyDocument
                  ? (cell.data.TotalTaxAmountCurrency | amountFormatter : cell)
                  : (cell.data.TotalTaxAmount | amountFormatter : cell)
              }}
              <!-- {{
                data.data.TotalGrossAmount - data.data.TotalNetAmount
                  | amountFormatter
              }} -->
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="grossCorrection"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'grossCorrection'">
              {{
                cell.data.IsCurrencyDocument
                  ? (cell.data.TotalGrossAmountCurrency
                    | amountFormatter : cell)
                  : (cell.data.TotalGrossAmount | amountFormatter : cell)
              }}
              <!-- {{ data.data.TotalGrossAmount | amountFormatter }} -->
            </div>
          </dx-data-grid>
        </div>
      </div>

      <div class="count-container" style="margin-top: 12px; height: auto">
        <div class="row">
          <div class="col-md-2">
            <!-- <p *ngIf="isCorrect" class="summary-count-container">
              {{ "inventory.summarizedInventory" | translate }}
            </p> -->
          </div>

          <div class="col-md-10 text-right">
            <div class="contener-pay">
              <p class="correction-label">
                {{
                  advanceDocumentSummaryData.FirstTotalGrossAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                {{
                  advanceDocumentSummaryData.TotalGrossAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="summary-amount-correction">
                <strong>
                  {{
                    advanceDocumentSummaryData.LeftTotalGrossAmount.toFixed(2)
                      | currency : " "
                      | pointReplacer
                  }}</strong
                >
              </p>

              <p class="des-toPay">
                {{ "form-commercial-operation.gross" | translate }}
                {{ CurrencyCode }}
              </p>
            </div>

            <div class="contener-pay" style="padding-right: 15px">
              <p class="correction-label">
                {{
                  advanceDocumentSummaryData.FirstTotalTaxAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                {{
                  advanceDocumentSummaryData.TotalTaxAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                <strong>{{
                  advanceDocumentSummaryData.LeftTotalTaxAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}</strong>
              </p>
              <p class="des-toPay">VAT</p>
            </div>

            <div
              class="contener-pay"
              style="padding-right: 15px; border-left: none"
            >
              <p class="correction-label">
                {{
                  advanceDocumentSummaryData.FirstTotalNetAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>
              <p class="correction-label">
                {{
                  advanceDocumentSummaryData.TotalNetAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                <strong>{{
                  advanceDocumentSummaryData.LeftTotalNetAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}</strong>
              </p>
              <p class="des-toPay">
                {{ "form-commercial-operation.net" | translate }}
                {{ CurrencyCode }}
              </p>
            </div>

            <div class="contener-pay" style="border-left: none">
              <p class="correction-label">
                {{
                  "form-commercial-operation.theValueOfTheItemsOnTheInitialAdvanceInvoice"
                    | translate
                }}:
              </p>
              <p class="correction-label">
                {{
                  "form-commercial-operation.sumOfAdvanceInvoices" | translate
                }}:
              </p>
              <p class="correction-label">
                <strong
                  >{{
                    "form-commercial-operation.itRemainsToBeSettledWithTheFinalInvoice"
                      | translate
                  }}:</strong
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<ng-container *ngIf="loadChildComponent">
  <app-warehouse-documents
    [popUpMode]="true"
    [isVisible]="isShowWarehouseDocuments"
    [readOnly]="readOnly"
    title="{{ 'menu.warehouseDocuments' | translate }}"
    (onClosed)="onClosingWarehouse()"
    (onChoosedDocument)="onChoosedDocument($event)"
    [fromAdvance]="true"
    [customerName]="selected[0]?.CustomerName"
    [customerIdAdvance]="selected[0]?.CustomerId"
    [isSelected]="isSelected"
    [isDisconnectWarehouseDocument]="isDisconnectWarehouseDocument"
  >
  </app-warehouse-documents>

  <app-confirm-dialog
    [isVisible]="isDeleteRow"
    (onRemoving)="delete($event)"
    (onClosing)="closeConfirm()"
    [checkBoxList]="checkBoxListConfirmDelete"
    confirmText="warehouseDocument.doYouWantDeleteLastAdvanceInvoice"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isCorrectionConfirm"
    (onRemoving)="yesOpenCorection()"
    (onClosing)="closeConfirm()"
    confirmText="form-commercial-operation.thereAreCorrectionsToTheLastAdvanceInvoice"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isLastInvoice"
    (onRemoving)="yesLastAdvance()"
    (onClosing)="noLastAdvance()"
    confirmText="form-commercial-operation.whetherToAutomaticallyCreateWarehouseDocumentsForAdvance"
  >
  </app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isShowWarning"
    (onClosing)="isShowWarning = false; cd.detectChanges()"
    btnConfig="close"
    confirmText="form-commercial-operation.warningAdvance"
  >
  </app-confirm-dialog>

  <app-new-comercial-operation
    [isVisible]="addRow"
    (onClosing)="onClosingNewComercialOperation()"
    title="{{ 'form-commercial-operation.title' | translate }}"
    (onInserted)="onInserted($event)"
    [mode]="mode"
    [selectedId]="focusedSelectedRow"
    [readOnly]="mode == 'show' ? true : readOnly"
    [isAdvance]="true"
    [nextAdcance]="nextAdcance"
    [isLastAdvance]="isLastAdvance"
    [sumAdvancedPayment]="abs(advanceDocumentSummaryData.TotalGrossAmount)"
    [leftToTotalPay]="abs(advanceDocumentSummaryData.LeftTotalGrossAmount)"
  >
  </app-new-comercial-operation>
</ng-container>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="
    !isVisible ||
    addRow ||
    showQuestionConnect ||
    showQuestionDisconect ||
    isShowCreateDocument ||
    isAllSettlement ||
    isConfirmVisible ||
    isShowWarning ||
    isLastInvoice ||
    isDeleteRow ||
    isShowWarehouseDocuments
  "
>
</wapro-keyboard-shortcuts>

<app-confirm-dialog
  *ngIf="showQuestionConnect"
  [isVisible]="true"
  (onRemoving)="startConnectWarehouseDocument()"
  (onClosing)="showQuestionConnect = false; cd.detectChanges()"
  confirmText="form-commercial-operation.shouldYouAssociateSelectedWarehouseDocumentsWithAnAdvanceInvoiceDocumentingAdvancePaymentsFor100"
></app-confirm-dialog>

<app-confirm-dialog
  *ngIf="showQuestionDisconect"
  [isVisible]="true"
  (onRemoving)="postDisconnectWarehouseDocument()"
  (onClosing)="showQuestionDisconect = false; cd.detectChanges()"
  confirmText="form-commercial-operation.shouldSelectedWarehouseDocumentsBeSeparatedFromTheAdvanceInvoiceDocumentingTheAcceptanceOfTheAdvancePaymentFor100OfTheValue"
></app-confirm-dialog>

<app-confirm-dialog
  *ngIf="isConfirmVisible"
  [isVisible]="true"
  (onRemoving)="continueConnectWarehouseDocument()"
  (onClosing)="isConfirmVisible = false; cd.detectChanges()"
  [confirmText]="confirmText"
></app-confirm-dialog>

<app-confirm-dialog
  *ngIf="isAllSettlement"
  [isVisible]="true"
  (onRemoving)="generateWarehouseDocument()"
  (onClosing)="isAllSettlement = false; cd.detectChanges()"
  confirmText="form-commercial-operation.theValueOfGoodsSservicesHasAlreadyBeenFullySettledWithAdvanceInvoicesInThisCaseItIsNotPossibleToIssueAfinalInvoiceButOnlyAWZDocument!ShouldIissueAwarehouseDocument"
></app-confirm-dialog>

<app-confirm-dialog
  *ngIf="isShowCreateDocument"
  [isVisible]="true"
  (onRemoving)="selectGeneratedWarehouseDocument()"
  (onClosing)="isShowCreateDocument = false; cd.detectChanges()"
  confirmText="form-commercial-operation.warehouseDocumentsHaveBeenCreatedWouldYouLikeToSeeThemNow"
></app-confirm-dialog>

<dx-tooltip
  [target]="'#btn-add' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.add" | translate }}
    (Insert)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#btn-edit' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.edit" | translate }}
    (F2)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#show' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.show" | translate }}
    (Shift + F2)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#delete' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "warehouseDocument.deleteLastCorrection" | translate }}
    (Del)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#connectTheWZToTheAdvanceInvoice' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{
      "form-commercial-operation.connectTheWZToTheAdvanceInvoice" | translate
    }}
    (Ctrl + Shift + I)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#detachTheWZfromTheAdvanceInvoice' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{
      "form-commercial-operation.detachTheWZfromTheAdvanceInvoice" | translate
    }}
    (Ctrl + Shift + O)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#showTheWZtoTheAdvanceInvoice' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "form-commercial-operation.showTheWZtoTheAdvanceInvoice" | translate }}
    (Ctrl + Shift + P)
  </div>
</dx-tooltip>

<!-- <app-print-settings
  componentName="comercialOperations"
  [refreshPrintSettings]="refreshPrintSettings"
  (onSettingsRefreshed)="refreshPrintSettings = false"
>
</app-print-settings> -->

import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  Output,
} from '@angular/core';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { FormBuilder } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { PrintSettingsService } from 'src/app/core/print-settings/print-settings.service';

@Component({
    selector: 'app-clearances',
    templateUrl: './clearances.component.html',
    styleUrls: ['./clearances.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'isSelectedRows',
        'paymentDateSettlement',
    ],
    standalone: false
})
export class ClearancesComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @Output() onRefresh = new EventEmitter();

  @ViewChild('gridJobCost') gridJobCost;
  @ViewChild('focusFirst') focusFirst;
  @ViewChild('isValidUserExtensions') isValidUserExtensions;
  @ViewChild('appContractors') appContractors;
  @ViewChild('gridBank') gridBank: DxDataGridComponent;

  paymentDateSettlement: string;
  isSelectedRows = false;
  oauthCallbackUrl = environment.oauthCallbackUrl;
  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;
  scrollPosition = 0;
  widthWindow = '90%';
  heightWindow = '90%';

  shortcuts: ShortcutInput[] = [];

  submitted: boolean = false;
  tooltipAdd: boolean = false;
  tooltipCancel: boolean = false;
  unicalGuid;
  dataSource = [];
  settlementData = {
    settlementName: '',
    settlementPayerName: '',
    datePayment: '',
    date: '',
    ammount: '',
    payed: 0,
    toPay: 0,
    currency: '',
  };

  isSplitAll: boolean = false;
  isSettlement: boolean = false;
  paramsNumber;
  fullScreen: boolean = false;
  interestNoteVisible: boolean = false;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private printSettings: PrintSettingsService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }
  }

  ngOnDestroy() {}

  onValueChanged = () => {
    this.getData();
  };

  areAllPayersEqual = false;
  settlementPayerNameSelectedMode = '';
  focusedRowIndex: number = 0;
  getData = (refresh?) => {
    this.appService
      .getAuth(
        `finances/settlements/${this.selectedId[0].SettlementId}/clearances`
      )
      .subscribe(
        (res) => {
          this.dataSource = res.data;

          this.areAllPayersEqual = res.data.every((item, _index, array) => {
            return item?.PayerFullName === array[0]?.PayerFullName;
          });

          if (this.areAllPayersEqual) {
            this.settlementPayerNameSelectedMode = res.data[0]?.PayerFullName;
          } else {
            this.translate.get('differentPayers').subscribe((text) => {
              this.settlementPayerNameSelectedMode = text;
            });
          }

          if (res.data.length == 0) {
            this.focusedSelected = [];
          } else {
            this.focusedRowIndex = 0;
            this.focusedSelected = [res.data[0]];
          }
          this.cd.detectChanges();

          if (refresh) this.onRefresh.emit();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  ngOnChanges() {
    if (this.selectedId) {
      try {
        this.settlementData.settlementName = this.selectedId[0].Number;
        this.settlementData.settlementPayerName = this.selectedId[0].PayerName;
        this.settlementData.datePayment = this.selectedId[0].PaymentDate;
        this.settlementData.date = this.selectedId[0].SettlementDate;
        this.settlementData.ammount =
          this.selectedId[0].TotalAmountGross != 0
            ? this.selectedId[0].TotalAmountGross
            : this.selectedId[0].TotalGrossAmountCurrency;
        this.settlementData.payed =
          this.selectedId[0].TotalPayedAmount != 0
            ? this.selectedId[0].TotalPayedAmount
            : this.selectedId[0].TotalPayedAmountCurrency;
        this.settlementData.toPay =
          this.selectedId[0].TotalToPay != 0
            ? this.selectedId[0].TotalToPay
            : this.selectedId[0].TotalToPayCurrency;
        this.settlementData.currency = this.selectedId[0].CurrencyCode;
      } catch {}

      if (this.selectedId.length != 0 && this.isVisible) {
        this.getData();
      }
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'N',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            if (
              !this.readOnly &&
              this.selectedId[0]?.SettlementStatus == 2 &&
              this.selectedId[0]?.PaymentDirection == 1 &&
              this.selectedId[0]?.SettlementType == 'N' &&
              this.selectedId[0]?.DaysExpired != 0 &&
              this.focusedSelected.length != 0
            ) {
              this.interestNoteVisible = true;
            }
          }
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.isSelectedRows = false;
    }
  };

  focusedSelected: any = [];
  onFocusedRowChanged = (e) => {
    if (e.row && e.row.data) {
      this.focusedSelected = [e.row.data];
    }
  };

  isSplit: boolean = false;

  closeConfirm() {
    this.isSplit = false;
    this.isSplitAll = false;
    this.cd.detectChanges();
  }

  paramsConfirmDelete: any = null;
  isOnSplit = () => {
    if (this.focusedSelected.length > 0) {
      this.paramsConfirmDelete = {
        number: this.focusedSelected[0].Number,
        settlement: this.selectedId[0].Number,
      };
      this.isSplit = true;
      this.cd.detectChanges();
    }
  };

  isOnSplitAll = () => {
    this.paramsConfirmDelete = {
      settlement: this.selectedId[0].Number,
    };

    this.isSplitAll = true;
    this.cd.detectChanges();
  };

  disconect = () => {
    this.isSplit = false;
    this.cd.detectChanges();
    if (this.focusedSelected.length > 0) {
      let object = {
        DependentSettlementId: this.focusedSelected[0].SettlementDependsId,
        SettlementId: this.selectedId[0].SettlementId,
      };

      this.appService
        .postAuth(
          `finances/settlements/${this.selectedId[0].SettlementId}/clearances/split`,
          object
        )
        .subscribe(
          () => {
            this.getData(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
  };

  disconectAll = () => {
    this.isSplitAll = false;
    this.cd.detectChanges();
    let object = {
      DependentSettlementId: null,
      SettlementId: this.selectedId[0].SettlementId,
    };
    this.appService
      .postAuth(
        `finances/settlements/${this.selectedId[0].SettlementId}/clearances/split`,
        object
      )
      .subscribe(
        () => {
          this.getData(true);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  showConfirm() {
    this.paramsNumber = {
      number: this.selectedId[0].Number,
    };

    this.isSettlement = true;
    this.cd.detectChanges();
  }

  isShowSettlementToChoise: boolean = false;
  yesSettlement = () => {
    this.isSettlement = false;
    this.isShowSettlementToChoise = true;
    this.cd.detectChanges();
  };

  noSettlement = () => {
    this.isSettlement = false;
    this.cd.detectChanges();
  };

  closeReckoning() {
    this.isShowSettlementToChoise = false;
    this.cd.detectChanges();
  }

  onInsertedSettlements() {
    this.getData(true);
  }

  showNote() {
    this.interestNoteVisible = true;
  }

  closeNote() {
    this.interestNoteVisible = false;
    this.printSettings.currentComponentName.set('settlements');
  }
}

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-financial-document.selectSettlement' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp(); getSettlements()"
  (onHidden)="event.onHiddenPopUp(); dataSource = []"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{ boxShadow: scrollPosition > 0 }"
    >
      <dx-button
        text="{{ 'buttons.choose' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'contractors' + unicalGuid"
        (onClick)="onChoosedSettlements()"
        [disabled]="focusedSelected.length == 0 || readOnly"
      ></dx-button>

      <dx-button
        type="danger"
        text="{{ 'buttons.close' | translate }}"
        style="margin-right: 6px"
        (onClick)="isVisible = false"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#contractors' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Enter)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <app-custom-dropdown-box
        [items]="filterCriteria"
        [(selectedItem)]="valueCriteria"
        [(filterValue)]="filterValue"
        (onValueChanged)="onFilterDataChanged($event)"
        style="margin-right: 5px"
      >
      </app-custom-dropdown-box>

      <dx-data-grid
        [dataSource]="dataSource"
        [wordWrapEnabled]="false"
        [showBorders]="true"
        shortcut
        height="calc(70vh - 120px)"
        [(selectedRowKeys)]="selectedRows"
        [allowColumnReordering]="true"
        [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
        [columnResizingMode]="'widget'"
        (onInitialized)="onInitialized($event)"
        [remoteOperations]="true"
        (onRowDblClick)="onRowDblClick()"
        [columnAutoWidth]="true"
        [allowColumnResizing]="true"
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        [id]="'grid' + unicalGuid"
        (onContentReady)="onContentReady($event)"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onKeyDown)="onKeyDown($event)"
        [(focusedRowIndex)]="focusedRowIndex"
        [focusedRowEnabled]="true"
        #gridBank
        (onOptionChanged)="event.onOptionChanged($event)"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling
          mode="virtual"
          columnRenderingMode="virtual"
          preloadEnabled="true"
        ></dxo-scrolling>
        <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
        <dxo-selection
          mode="multiple"
          showCheckBoxesMode="always"
          selectAllMode="page"
        >
        </dxo-selection>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

        <dxi-column
          caption="{{ 'form-financial-document.number' | translate }}"
          [width]="150"
          [allowSorting]="false"
          dataField="Number"
          cellTemplate="mainColumn"
          headerCellTemplate="NumbertHeaderTemplate"
          [cssClass]="
            orderby === 'number' || orderby == 'numberIntuition'
              ? 'sort-column-bg'
              : null
          "
        >
        </dxi-column>
        <div *dxTemplate="let data of 'NumbertHeaderTemplate'">
          <div class="grid-header-wrapper">
            {{ "form-financial-document.number" | translate }}
            <i
              class="header-sort-icon"
              *ngIf="orderby == 'number' || orderby == 'numberIntuition'"
              (click)="switchOrder(); getSettlements()"
              [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
            ></i>
          </div>
        </div>
        <div *dxTemplate="let data of 'mainColumn'">
          <div
            *ngIf="data.data.Semaphore"
            [ngClass]="{
              editRowByMe: userId == data.data.Semaphore,
              editRowByAnotherUser: userId != data.data.Semaphore
            }"
          ></div>
          <span>{{ data.data.Number }}</span>
        </div>

        <dxi-column
          caption="{{ 'form-financial-document.date' | translate }}"
          [width]="150"
          [allowSorting]="false"
          dataField="SettlementDate"
          dataType="date"
          [format]="{ type: event.dateFormat }"
          headerCellTemplate="DateTemplate"
          [cssClass]="orderby === 'settlementDate' ? 'sort-column-bg' : null"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'DateTemplate'">
          <div class="grid-header-wrapper">
            {{ "form-financial-document.date" | translate }}
            <i
              class="header-sort-icon"
              *ngIf="orderby == 'settlementDate'"
              (click)="switchOrder(); getSettlements()"
              [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
            ></i>
          </div>
        </div>

        <dxi-column
          caption="{{
            'form-commercial-operation.dateOfDaymentts' | translate
          }}"
          [width]="150"
          [allowSorting]="false"
          dataField="PaymentDate"
          dataType="date"
          [format]="{ type: event.dateFormat }"
          [cssClass]="orderby === 'paymentDate' ? 'sort-column-bg' : null"
        >
        </dxi-column>

        <dxi-column
          caption="{{ 'form-commercial-operation.toPay' | translate }}"
          [width]="100"
          [allowSorting]="false"
          dataField="TotalAmountGross"
          cellTemplate="TotalAmountGross"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'TotalAmountGross'">
          {{ data.data.TotalAmountGross | amountFormatter : data }}
        </div>

        <dxi-column
          caption="{{ 'stockHistory.available' | translate }}"
          [width]="100"
          [allowSorting]="false"
          dataField="TotalToPay"
          headerCellTemplate="TotalToPayTemplate"
          cellTemplate="TotalToPay"
          [cssClass]="orderby == 'totalToPay' ? 'sort-column-bg' : null"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'TotalToPay'">
          {{ data.data.TotalToPay | amountFormatter : data }}
        </div>

        <div *dxTemplate="let data of 'TotalToPayTemplate'">
          <div class="grid-header-wrapper">
            {{ "stockHistory.available" | translate }}
            <i
              class="header-sort-icon"
              *ngIf="orderby == 'totalToPay'"
              (click)="switchOrder(); getSettlements()"
              [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
            ></i>
          </div>
        </div>

        <dxi-column
          caption="{{ 'bankAccounts.form.currency' | translate }}"
          [width]="100"
          [allowSorting]="false"
          dataField="CurrencyCode"
          alignment="left"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'form-commercial-operation.toPayCurrency' | translate }}"
          [width]="120"
          [allowSorting]="false"
          dataField="TotalGrossAmountCurrency"
          cellTemplate="TotalGrossAmountCurrency"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'TotalGrossAmountCurrency'">
          {{ data.data.TotalGrossAmountCurrency | amountFormatter : data : 4 }}
        </div>

        <dxi-column
          caption="{{ 'leftCurrency' | translate }}"
          [width]="120"
          [allowSorting]="false"
          dataField="TotalToPayCurrency"
          cellTemplate="TotalToPayCurrency"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'TotalToPayCurrency'">
          {{ data.data.TotalToPayCurrency | amountFormatter : data : 4 }}
        </div>

        <dxi-column
          caption="{{ 'form-financial-document.grid.payer-name' | translate }}"
          [allowSorting]="false"
          dataField="PayerName"
          [cssClass]="
            orderby === 'payerName' || orderby == 'payerNameIntuition'
              ? 'sort-column-bg'
              : null
          "
        >
        </dxi-column>
      </dx-data-grid>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

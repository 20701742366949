<ng-container *ngIf="componentVisible">
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <ng-template #topButtons>
    <dx-scroll-view
      width="100%"
      height="100%"
      direction="horizontal"
      *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
    >
      <app-wapro-selected-box
        [(ngModel)]="selectedRow"
        [dataSource]="dataSource"
        [selectedAll]="isAllSelected"
        [selectedAmount]="selectedAmount()"
        (onDeselectAll)="onDeselectAll()"
        (onOnlySelected)="onOnlySelected()"
      ></app-wapro-selected-box>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        [text]="'buttons.add' | translate"
        (onClick)="openNewRow()"
        id="btn-add-comercial-operation"
        [disabled]="readOnly"
        data-cy="add-financial-document-btn"
      ></dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--mode-edit"
        [text]="'buttons.edit' | translate"
        [id]="'btn-edit' + unicalGuid"
        (onClick)="showEditContr()"
        [disabled]="readOnly || focusedSelected?.length != 1"
        data-cy="edit-financial-document-btn"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--form-items"
        [id]="'contractorsShow' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="readOnly || focusedSelected?.length != 1"
        [text]="'buttons.show' | translate"
        (onClick)="showRow = true"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--highlight-off"
        [id]="'btn-delete' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="focusedSelected?.length == 0 || readOnly"
        [text]="'buttons.delete' | translate"
        (onClick)="isDelete()"
        data-cy="delete-financial-document-btn"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu"
        icon="icon absui-icon--sum"
        [text]="'stockHistory.sum' | translate"
        [id]="'sum' + unicalGuid"
        (onClick)="sumOpen()"
        *ngIf="!dropDownBoxMode"
        type="default"
        stylingMode="text"
      ></dx-button>

      <dx-button
        *ngIf="rkwbPermission"
        icon="icon absui-icon--file-money"
        [id]="'raport' + unicalGuid"
        class="btn-dx-top-menu"
        [text]="'form-financial-document.financialRaports' | translate"
        (onClick)="isFinancialRaports = true; cd.detectChanges()"
      ></dx-button>

      <!-- mobile reports popup -->
      <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

      <!-- mobile edocoments popup -->
      <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

      <app-product-scaner
        #scaner
        [readOnly]="readOnly"
        [showScanerContainer]="false"
        (onInputChanged)="onScanerInputChanged($event)"
        [keyboardShortcutsDisabled]="
          isDeleteRow ||
          newRowPopupVisible ||
          editRow ||
          showRow ||
          isExternalTableVisible ||
          isLabel ||
          isShortcutTableOpened ||
          isBlockRow ||
          visibleUserExtensions ||
          localStorageData?.sidePanel?.isVisible ||
          isShowBankList ||
          isExpansionListVisible ||
          isFinancialRaports ||
          isCustomerVisible ||
          isWorkersVisible ||
          isOfficeVisible ||
          isSumVisible
        "
        [parentId]="unicalGuid"
      ></app-product-scaner>

      <app-btn-export-excel
        [gridReference]="gridClass"
        [nameFile]="'menu.finance-documents' | translate"
        url="finances/documents"
        [paramsObj]="paramsObj"
      ></app-btn-export-excel>

      <app-additional-operation
        #additionalOperation
        nameComponent="FinancialOperationComponent"
        (onItemClick)="contextMenuClick($event)"
        (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
      ></app-additional-operation>

      <dx-button
        icon="icon absui-icon--tables"
        [id]="'tables' + unicalGuid"
        class="btn-dx-top-menu-folder"
        *ngIf="additionalTables.length"
        [disabled]="focusedSelected?.length == 0"
        [text]="'buttons.tables' | translate"
        (onClick)="isExternalTableVisible = true"
      ></dx-button>

      <dx-tooltip
        [target]="'#sum' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "stockHistory.sum" | translate }} (Ctrl + S)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#raport' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "form-financial-document.financialRaports" | translate }} (Ctrl +
          r)
        </div>
      </dx-tooltip>

      <dx-tooltip
        target="#btn-add-comercial-operation"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }} (Insert)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#contractorsShow' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.show" | translate }} (Shfit + F2)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-edit' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.edit" | translate }} (F2)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-delete' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }} (Del)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#tables' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.tables" | translate }} (Ctrl + Y)
        </div>
      </dx-tooltip>
      <div class="right-header-btn">
        <ng-container *ngIf="exportFakirService.isSelectMode()">
          <dx-button
            text="{{ 'buttons.choose' | translate }}"
            type="success"
            style="margin-right: 6px"
            (onClick)="selectChoosed()"
            [id]="'choose' + unicalGuid"
          ></dx-button>

          <dx-button
            text="{{ 'buttons.cancel' | translate }}"
            type="danger"
            (onClick)="cancelChoosed()"
            [id]="'cancel' + unicalGuid"
          ></dx-button>
        </ng-container>

        <dx-button
          class="btn-dx-top-menu-folder btn-dynamic-state"
          [ngClass]="{ active: localStorageData?.sidePanel?.isVisible }"
          icon="icon absui-icon--filter"
          (onClick)="toggleDynamicFilters()"
          style="margin-right: 6px"
          [id]="'btn-show-dynamic-filters' + unicalGuid"
          *ngIf="event.deviceType === 'desktop'"
        ></dx-button>
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon dx-icon-icon-refresh"
          (click)="getData()"
          *ngIf="!dropDownBoxMode"
          style="margin-right: 6px"
          [id]="'btn-refresh' + unicalGuid"
        ></dx-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="column-chooser"
          (click)="columnsChooserVisible = true"
          *ngIf="permissionToChooseColums && !dropDownBoxMode"
          [id]="'btn-columns' + unicalGuid"
        ></dx-button>

        <dx-tooltip
          [target]="'#choose' + unicalGuid"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.choose" | translate }} (Enter)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.cancel" | translate }} (Esc)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-refresh' + unicalGuid"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
          [showEvent]="event.tooltipShowEvent"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "refresh" | translate }}
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-columns' + unicalGuid"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
          [showEvent]="event.tooltipShowEvent"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "columnSettings" | translate }}
          </div>
        </dx-tooltip>
      </div>
    </dx-scroll-view>
  </ng-template>

  <div class="center-panel-scroll-verticall" data-cy="financial-operation-container">
    <div class="row" *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
      <div class="col-md-12">
        <div class="panels-flex-container">
          <div
            class="with_search_panel"
            #rightPanel
            [ngStyle]="{
              width: 'calc(100% - {{localStorageData.sidePanel.width}})'
            }"
          >
            <div class="row">
              <div class="col-md-12">
                <app-custom-dropdown-box
                  [openDropDown]="openCustomDropDown"
                  [items]="filterCriteria"
                  [(selectedItem)]="localStorageData.filter.orderBy"
                  [(filterValue)]="filterValue"
                  (onValueChanged)="onFilterDataChanged($event)"
                  style="margin-right: 5px"
                  (onArrowDown)="gridClass.instance.focus()"
                  #searchControl
                ></app-custom-dropdown-box>

                <app-date-range
                  #dateRange
                  [readOnly]="readOnly"
                  [isPopupMode]="true"
                  (onChoosed)="onDateRangeChoosed()"
                  #dataRange
                  [chipsVisible]="true"
                ></app-date-range>
                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="onlySelected"
                >
                  {{ "showSelected" | translate }}:
                  <b>{{ "yes" | translate }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetFiltr('onlySelected')"
                  ></i>
                </dx-button>
                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="valueCriteria2 != ''"
                  (click)="openRightPanel()"
                >
                  {{ "filterPanel.type" | translate }}:
                  <b>{{ valueCriteria2 }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetFiltr('valueCriteria2')"
                  ></i>
                </dx-button>

                <app-custom-chips-button
                  #payerChips
                  [name]="
                    'form-financial-document.payer-type.payer-type' | translate
                  "
                  [disabledClear]="true"
                  [selectedValueInput]="customerFilter?.PayerType"
                  [list]="payerTypes"
                  (onValueChanged)="onPayerTypeChanged($event)"
                ></app-custom-chips-button>

                <dx-button
                  *ngIf="
                    customerFilter.PayerType !== 'all' &&
                    customerFilter.PayerType
                  "
                  class="btn-filter-box"
                  [ngClass]="{
                    'chips-btn':
                      customerFilter.CustomerId ||
                      customerFilter.WorkerId ||
                      customerFilter.OfficeId
                  }"
                  (onClick)="openPayer()"
                >
                  <ng-container *ngIf="customerFilter.PayerType === 'k'">
                    {{
                      "form-financial-document.payer-type.contractor"
                        | translate
                    }}
                    <b
                      style="margin-left: 6px"
                      *ngIf="customerFilter.CustomerId"
                    >
                      {{ customerFilter.CustomerName }}
                    </b>
                  </ng-container>
                  <ng-container *ngIf="customerFilter.PayerType === 'u'">
                    {{
                      "form-financial-document.payer-type.office" | translate
                    }}
                    <b style="margin-left: 6px" *ngIf="customerFilter.OfficeId">
                      {{ customerFilter.OfficeName }}
                    </b>
                  </ng-container>
                  <ng-container *ngIf="customerFilter.PayerType === 'p'">
                    {{
                      "form-financial-document.payer-type.worker" | translate
                    }}
                    <b style="margin-left: 6px" *ngIf="customerFilter.WorkerId">
                      {{ customerFilter.WorkerName }}
                    </b>
                  </ng-container>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetPayerFilter()"
                    *ngIf="
                      customerFilter.CustomerId ||
                      customerFilter.WorkerId ||
                      customerFilter.OfficeId
                    "
                  ></i>
                </dx-button>

                <app-custom-chips-button
                  *ngIf="
                    localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
                    localStorageData?.advancedFilter?.IsPinned
                  "
                  [name]="'filterPanel.template' | translate"
                  [labelExpr]="'Name'"
                  [valueExpr]="'FilterUsersDefinitionId'"
                  [selectedValueInput]="
                    localStorageData?.advancedFilter?.Name || null
                  "
                  [list]="dynamicFilterUserDefinitions"
                  (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
                ></app-custom-chips-button>

                <dx-button
                  [id]="'clearValues' + unicalGuid"
                  class="btn-filter-box"
                  icon="icon absui-icon--clear-filter"
                  (onClick)="clearValues()"
                ></dx-button>
                <dx-tooltip
                  [target]="'#clearValues' + unicalGuid"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "clearAllFilters" | translate }} (Ctrl + F10)
                  </div>
                </dx-tooltip>

                <app-view-dynamic-quick-filters
                  [dictionaryCode]="DynamicFilterDictionaryCode"
                  [pinnedFilters]="
                    localStorageData.advancedFilter?.PinnedFilters
                  "
                  [values]="
                    localStorageData?.advancedFilter?.UserFilterDefinitionValues
                  "
                  (onValueChanged)="onPinnedValueChanged($event)"
                ></app-view-dynamic-quick-filters>

                <dx-data-grid
                  [showBorders]="true"
                  [remoteOperations]="true"
                  [allowColumnReordering]="true"
                  [dataSource]="dataSource"
                  #gridClass
                  (onSelectionChanged)="onSelectionChanged($event)"
                  shortcut
                  (onInitialized)="onInitialized($event)"
                  style="width: 100%; max-height: 100%; margin-bottom: 10px"
                  [focusedRowEnabled]="true"
                  [(selectedRowKeys)]="selectedRow"
                  [height]="heightGrid - event.getHeightBoxLicenseInformation()"
                  [(focusedRowIndex)]="focusedRowIndex"
                  [wordWrapEnabled]="false"
                  (onKeyDown)="onKeyDown($event)"
                  [autoNavigateToFocusedRow]="true"
                  (onRowDblClick)="onRowDblClick()"
                  (onOptionChanged)="onOptionChanged($event)"
                  [allowColumnResizing]="true"
                  [columnResizingMode]="'widget'"
                  [hoverStateEnabled]="true"
                  (onFocusedRowChanged)="onFocusedRowChanged($event)"
                  (onContentReady)="onGridContentReady($event)"
                  (onEditorPreparing)="onEditorPreparing($event)"
                  [id]="'grid' + unicalGuid"
                  (onRowClick)="onRowClick($event)"
                  (onContextMenuPreparing)="contextMenuPreparing($event)"
                  data-cy="financial-documents-grid"
                >
                  <dxo-load-panel [enabled]="false"></dxo-load-panel>
                  <dxo-scrolling
                    mode="infinite"
                    columnRenderingMode="virtual"
                    preloadEnabled="true"
                  ></dxo-scrolling>
                  <dxo-paging
                    [pageSize]="pageSize"
                    [enabled]="true"
                  ></dxo-paging>
                  <dxo-selection
                    [mode]="dropDownBoxMode ? 'single' : 'multiple'"
                    showCheckBoxesMode="always"
                    selectAllMode="page"
                  ></dxo-selection>
                  <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
                  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

                  <dxi-column
                    cellTemplate="markerConfigTemplate"
                    [fixed]="true"
                    [width]="0"
                  ></dxi-column>
                  <div *dxTemplate="let data of 'markerConfigTemplate'">
                    <div
                      *ngIf="data.data.Semaphore"
                      [ngClass]="{
                        editRowByMe: userId == data.data.Semaphore,
                        editRowByAnotherUser: userId != data.data.Semaphore
                      }"
                    ></div>
                  </div>
                  <dxi-column
                    caption="K"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="40"
                    alignment="center"
                    dataField="IsSetForAccounting"
                    cellTemplate="IsSetForAccounting"
                    headerCellTemplate="DocumentType"
                    [allowExporting]="false"
                  ></dxi-column>
                  <div
                    *dxTemplate="let data of 'DocumentType'"
                    class="text-center"
                  >
                    <div
                      class="grid-header-wrapper text-center"
                      [id]="'type' + unicalGuid"
                    >
                      K
                    </div>
                    <dx-tooltip
                      [target]="'#type' + unicalGuid"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{ "forAccounting" | translate }}
                      </div>
                    </dx-tooltip>
                  </div>
                  <div *dxTemplate="let cell of 'IsSetForAccounting'">
                    <i
                      class="icon absui-icon--insert-drive-file"
                      [id]="'IsSetForAccounting' + unicalGuid + cell.rowIndex"
                      *ngIf="cell.data.IsSetForAccounting"
                    ></i>
                    <i
                      class="icon absui-icon--set-lock"
                      *ngIf="
                        cell.data.IsBlocked && !cell.data.IsSetForAccounting
                      "
                      [id]="'IsBlocked' + unicalGuid + cell.rowIndex"
                    ></i>
                    <dx-tooltip
                      [target]="'#IsBlocked' + unicalGuid + cell.rowIndex"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{ "form-financial-document.isBlocked" | translate }}
                      </div>
                    </dx-tooltip>
                    <dx-tooltip
                      [target]="
                        '#IsSetForAccounting' + unicalGuid + cell.rowIndex
                      "
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{
                          "form-financial-document.isSetForAccounting"
                            | translate
                        }}
                      </div>
                    </dx-tooltip>
                  </div>

                  <dxi-column
                    caption="R"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="40"
                    alignment="center"
                    dataField="ReportId"
                    cellTemplate="cellTemplateReportId"
                    headerCellTemplate="headerCellReportId"
                    [allowExporting]="false"
                  ></dxi-column>
                  <div
                    *dxTemplate="let data of 'headerCellReportId'"
                    class="text-center"
                  >
                    <div
                      class="grid-header-wrapper text-center"
                      [id]="'toTheBankCashReport' + unicalGuid"
                    >
                      R
                    </div>
                    <dx-tooltip
                      [target]="'#toTheBankCashReport' + unicalGuid"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{ "toTheBankCashReport" | translate }}
                      </div>
                    </dx-tooltip>
                  </div>

                  <dxi-column
                    caption="{{ 'form-financial-document.number' | translate }}"
                    [allowSorting]="false"
                    dataField="Number"
                    width="100"
                    headerCellTemplate="NumbertHeaderTemplate"
                    [cssClass]="
                      localStorageData.filter.orderBy === 'number' ||
                      localStorageData.filter.orderBy == 'numberIntuition'
                        ? 'sort-column-bg'
                        : 'sort-column-muted'
                    "
                    cssClass="numberColumn"
                    cellTemplate="mainColumn"
                    alignment="left"
                  ></dxi-column>
                  <div *dxTemplate="let data of 'mainColumn'">
                    <span
                      class="hov-underline"
                      (click)="showEditContr(data.data)"
                    >
                      {{ data.data.Number }}
                    </span>
                  </div>
                  <div *dxTemplate="let data of 'NumbertHeaderTemplate'">
                    <div
                      class="grid-header-wrapper"
                      (click)="setSearchCriteria('numberIntuition')"
                    >
                      {{ "form-financial-document.number" | translate }}
                      <i
                        class="header-sort-icon"
                        [ngClass]="
                          localStorageData.filter.order === 'DESC'
                            ? 'arr-down'
                            : 'arr-up'
                        "
                      ></i>
                    </div>
                  </div>

                  <dxi-column
                    caption="{{ 'form-financial-document.type' | translate }}"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    dataField="Signature"
                    width="40"
                    alignment="left"
                  ></dxi-column>

                  <dxi-column
                    caption="{{ 'form-financial-document.date' | translate }}"
                    dataField="FinanceDocumentDate"
                    [allowSorting]="false"
                    dataType="date"
                    [format]="{ type: event.dateFormat }"
                    width="110"
                    alignment="left"
                    headerCellTemplate="dateTemplate"
                    [cssClass]="
                      localStorageData.filter.orderBy === 'financeDocumentDate'
                        ? 'sort-column-bg'
                        : 'sort-column-muted'
                    "
                  ></dxi-column>
                  <div *dxTemplate="let data of 'dateTemplate'">
                    <div
                      class="grid-header-wrapper"
                      (click)="setSearchCriteria('financeDocumentDate')"
                    >
                      {{ "form-financial-document.date" | translate }}
                      <i
                        class="header-sort-icon"
                        [ngClass]="
                          localStorageData.filter.order === 'DESC'
                            ? 'arr-down'
                            : 'arr-up'
                        "
                      ></i>
                    </div>
                  </div>

                  <dxi-column
                    caption="{{
                      'form-financial-document.grid.payer-name' | translate
                    }}"
                    dataField="PayerName"
                    [allowSorting]="false"
                    width="300"
                    headerCellTemplate="payerTemplate"
                    alignment="left"
                    [cssClass]="
                      localStorageData.filter.orderBy === 'payerName' ||
                      localStorageData.filter.orderBy == 'payerNameIntuition'
                        ? 'sort-column-bg'
                        : 'sort-column-muted'
                    "
                  ></dxi-column>
                  <div *dxTemplate="let data of 'payerTemplate'">
                    <div
                      class="grid-header-wrapper"
                      (click)="setSearchCriteria('payerName')"
                    >
                      {{
                        "form-financial-document.grid.payer-name" | translate
                      }}
                      <i
                        class="header-sort-icon"
                        [ngClass]="
                          localStorageData.filter.order === 'DESC'
                            ? 'arr-down'
                            : 'arr-up'
                        "
                      ></i>
                    </div>
                  </div>

                  <dxi-column
                    caption="{{ 'warehouseDocument.label' | translate }}"
                    width="105"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    dataField="LabelName"
                    cellTemplate="labelTemplate"
                    renderAsync="true"
                    alignment="left"
                  ></dxi-column>
                  <div *dxTemplate="let data of 'labelTemplate'">
                    <app-label
                      [bgColor]="data.data.LabelBackgroundColor"
                      [color]="data.data.LabelTextColor"
                      [text]="data.data.LabelName"
                    ></app-label>
                  </div>

                  <dxi-column
                    caption="{{
                      'form-financial-document.grid.impact' | translate
                    }}"
                    dataField="AmountIncome"
                    [allowSorting]="false"
                    width="100"
                    headerCellTemplate="AmountIncomeTemplate"
                    cellTemplate="AmountIncome"
                    [cssClass]="
                      localStorageData.filter.orderBy === 'amount'
                        ? 'sort-column-bg'
                        : 'sort-column-muted'
                    "
                  >
                    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                  </dxi-column>
                  <div *dxTemplate="let cell of 'AmountIncome'">
                    {{ cell.data.AmountIncome | amountFormatter : cell }}
                  </div>

                  <div *dxTemplate="let data of 'AmountIncomeTemplate'">
                    <div
                      class="grid-header-wrapper"
                      (click)="setSearchCriteria('amount')"
                    >
                      {{ "form-financial-document.grid.impact" | translate }}
                      <i
                        class="header-sort-icon"
                        [ngClass]="
                          localStorageData.filter.order === 'DESC'
                            ? 'arr-down'
                            : 'arr-up'
                        "
                      ></i>
                    </div>
                  </div>

                  <dxi-column
                    caption="{{
                      'form-financial-document.grid.outflow' | translate
                    }}"
                    dataField="AmountExpense"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    cellTemplate="AmountExpense"
                  >
                    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                  </dxi-column>
                  <div *dxTemplate="let cell of 'AmountExpense'">
                    {{ cell.data.AmountExpense | amountFormatter : cell }}
                  </div>

                  <dxi-column
                    caption="{{ 'document-type.type' | translate }}"
                    dataField="OperationType"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="80"
                  ></dxi-column>

                  <dxi-column
                    caption="{{ 'bankAccounts.form.currency' | translate }}"
                    dataField="CurrencyCode"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                  ></dxi-column>
                  <dxi-column
                    caption="{{
                      'form-financial-document.grid.impactCurency' | translate
                    }}"
                    dataField="AmountCurrencyIncome"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    cellTemplate="AmountCurrencyIncome"
                  >
                    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                  </dxi-column>
                  <div *dxTemplate="let cell of 'AmountCurrencyIncome'">
                    {{
                      cell.data.AmountCurrencyIncome
                        | amountFormatter : cell : 4
                    }}
                  </div>

                  <dxi-column
                    caption="{{
                      'form-financial-document.grid.outflowCurency' | translate
                    }}"
                    dataField="AmountCurrencyExpense"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    cellTemplate="AmountCurrencyExpense"
                  >
                    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                  </dxi-column>
                  <div *dxTemplate="let cell of 'AmountCurrencyExpense'">
                    {{
                      cell.data.AmountCurrencyExpense
                        | amountFormatter : cell : 4
                    }}
                  </div>

                  <dxi-column
                    caption="{{
                      'form-financial-document.statement-number' | translate
                    }}"
                    dataField="StatementNumber"
                    [allowSorting]="false"
                    width="200"
                    headerCellTemplate="StatementNumberTemplate"
                    [cssClass]="
                      localStorageData.filter.orderBy === 'statementNumber'
                        ? 'sort-column-bg'
                        : 'sort-column-muted'
                    "
                  ></dxi-column>

                  <div *dxTemplate="let data of 'StatementNumberTemplate'">
                    <div
                      class="grid-header-wrapper"
                      (click)="setSearchCriteria('statementNumber')"
                    >
                      {{
                        "form-financial-document.statement-number" | translate
                      }}
                      <i
                        class="header-sort-icon"
                        [ngClass]="
                          localStorageData.filter.order === 'DESC'
                            ? 'arr-down'
                            : 'arr-up'
                        "
                      ></i>
                    </div>
                  </div>
                  <dxi-column
                    caption="{{
                      'form-financial-document.the-titel' | translate
                    }}"
                    dataField="Title"
                    [allowSorting]="false"
                    width="200"
                    headerCellTemplate="TitleTemplate"
                    [cssClass]="
                      localStorageData.filter.orderBy === 'titleIntuition'
                        ? 'sort-column-bg'
                        : 'sort-column-muted'
                    "
                    alignment="left"
                  ></dxi-column>

                  <div *dxTemplate="let data of 'TitleTemplate'">
                    <div
                      class="grid-header-wrapper"
                      (click)="setSearchCriteria('titleIntuition')"
                    >
                      {{ "form-financial-document.the-titel" | translate }}
                      <i
                        class="header-sort-icon"
                        [ngClass]="
                          localStorageData.filter.order === 'DESC'
                            ? 'arr-down'
                            : 'arr-up'
                        "
                      ></i>
                    </div>
                  </div>
                  <dxi-column
                    caption="{{
                      'form-financial-document.operation-code' | translate
                    }}"
                    dataField="OperationCode"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="40"
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    caption="PP"
                    dataField="IsSplitPayment"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="40"
                    cellTemplate="IsSplitPayment"
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    caption="PP {{ 'companies.grid.columns.vat' | translate }}"
                    dataField="SplitPaymentVatNumber"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    caption="PP {{ 'workers.documentNumber' | translate }}"
                    dataField="SplitPaymentInvoiceNumber"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    caption="PP {{
                      'form-commercial-operation.grid.vatAmount' | translate
                    }}"
                    dataField="SplitPaymentVatAmountCash"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    cellTemplate="SplitPaymentVatAmountCash"
                  >
                    <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                  </dxi-column>
                  <div *dxTemplate="let cell of 'SplitPaymentVatAmountCash'">
                    {{
                      cell.data.SplitPaymentVatAmountCash
                        | amountFormatter : cell
                    }}
                  </div>

                  <dxi-column
                    caption="{{ 'worker' | translate }}"
                    dataField="EmployeeFullName"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="300"
                    alignment="left"
                  ></dxi-column>

                  <dxi-column
                    caption="{{ 'workers.attachment' | translate }}"
                    [allowSorting]="false"
                    dataField="AttachmentsAmount"
                    width="80"
                    alignment="right"
                    [cssClass]="'sorting-unavailable'"
                  ></dxi-column>

                  <dxi-column
                    [caption]="userExtensionColumns['Pole1'].Name"
                    dataField="Field1"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole1'] &&
                      userExtensionColumns['Pole1'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    [caption]="userExtensionColumns['Pole2'].Name"
                    dataField="Field2"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole2'] &&
                      userExtensionColumns['Pole2'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    [caption]="userExtensionColumns['Pole3'].Name"
                    dataField="Field3"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole3'] &&
                      userExtensionColumns['Pole3'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    [caption]="userExtensionColumns['Pole4'].Name"
                    dataField="Field4"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole4'] &&
                      userExtensionColumns['Pole4'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    [caption]="userExtensionColumns['Pole5'].Name"
                    dataField="Field5"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole5'] &&
                      userExtensionColumns['Pole5'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    [caption]="userExtensionColumns['Pole6'].Name"
                    dataField="Field6"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole6'] &&
                      userExtensionColumns['Pole6'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    [caption]="userExtensionColumns['Pole7'].Name"
                    dataField="Field7"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole7'] &&
                      userExtensionColumns['Pole7'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    [caption]="userExtensionColumns['Pole8'].Name"
                    dataField="Field8"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole8'] &&
                      userExtensionColumns['Pole8'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    [caption]="userExtensionColumns['Pole9'].Name"
                    dataField="Field9"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole9'] &&
                      userExtensionColumns['Pole9'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>
                  <dxi-column
                    [caption]="userExtensionColumns['Pole10'].Name"
                    dataField="Field10"
                    [allowSorting]="false"
                    [cssClass]="'sorting-unavailable'"
                    width="100"
                    *ngIf="
                      userExtensionColumns['Pole10'] &&
                      userExtensionColumns['Pole10'].IsActive
                    "
                    alignment="left"
                  ></dxi-column>

                  <div *dxTemplate="let data of 'IsSplitPayment'">
                    <i
                      class="icon absui-icon--check check-grid"
                      *ngIf="data.data.IsSplitPayment"
                    ></i>
                    <i
                      class="icon absui-icon--close close-grid"
                      *ngIf="!data.data.IsSplitPayment"
                    ></i>
                  </div>

                  <div *dxTemplate="let cell of 'IsBlocked'"></div>

                  <div *dxTemplate="let cell of 'cellTemplateReportId'">
                    <i
                      class="icon absui-icon--script"
                      [id]="'ReportId' + unicalGuid + cell.rowIndex"
                      *ngIf="cell.data.ReportId"
                    ></i>
                    <dx-tooltip
                      [target]="'#ReportId' + unicalGuid + cell.rowIndex"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{ "AddedToTheBankCashReport" | translate }}
                      </div>
                    </dx-tooltip>
                  </div>
                </dx-data-grid>

                <dx-tooltip
                  *ngFor="let icon of sortingIcons.asc"
                  [target]="icon"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "columnSorting.asc" | translate }}
                  </div>
                </dx-tooltip>
                <dx-tooltip
                  *ngFor="let icon of sortingIcons.desc"
                  [target]="icon"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "columnSorting.desc" | translate }}
                  </div>
                </dx-tooltip>

                <wapro-context-menu
                  [dataSource]="contextMenuSignal()"
                  [width]="200"
                  [target]="'#grid' + unicalGuid"
                  (onItemClick)="contextMenuClick($event)"
                  (onItemRendered)="event.onItemRendered($event)"
                ></wapro-context-menu>
              </div>
            </div>
            <app-bottom-panel
              [height]="localStorageData.bottomPanel.height"
              (onResizing)="onResizingBottom($event)"
            >
              <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
            </app-bottom-panel>
          </div>

          <div
            class="right-side-panel"
            mwlResizable
            (resizeEnd)="resizeEnd()"
            (resizeStart)="onResizeStart()"
            (resizing)="resizing($event)"
            #leftPanel
            [ngStyle]="{ width: localStorageData.sidePanel.width }"
          >
            <div class="right-panel-btn">
              <!-- <i class="icon text-icon"></i> -->
              <dx-button
                (mousedown)="mousedownSidePanel()"
                (mouseup)="mouseupSidePanel()"
                (touchend)="mouseupSidePanel()"
                (touchstart)="mousedownSidePanel()"
                [icon]="
                  localStorageData?.sidePanel?.isVisible
                    ? 'icon absui-icon--arrow-drop-right'
                    : 'icon absui-icon--arrow-drop-left'
                "
                class="btn-dx-right-filter"
                mwlResizeHandle
                [resizeEdges]="{ left: true }"
              ></dx-button>
            </div>
            <div
              class="resize-handle-left"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
              (mouseenter)="mouseEnter()"
              (mouseleave)="mouseLeave()"
            ></div>

            <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- component with configuration grid column -->
    <app-column-chooser
      [gridRef]="gridClass"
      [(visible)]="columnsChooserVisible"
      (columnsChanged)="onColumnsChanged($event)"
    ></app-column-chooser>
  </div>
</ng-container>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

<app-new-financial-operation
  *ngIf="newRowPopupVisible"
  [componentNests]="componentNests"
  [isVisible]="newRowPopupVisible"
  (onClosing)="closeNewComercialOperation()"
  [typeDocument]="typeDocumentNewDoc"
  title="{{ 'form-financial-document.title' | translate }}"
  (onInserted)="onInserted($event, 'add')"
  mode="add"
  [selectedTypeDocument]="localStorageData.valueCriteria2"
></app-new-financial-operation>

<app-new-financial-operation
  [isReport]="isReport"
  *ngIf="editRow"
  [componentNests]="componentNests"
  [isVisible]="editRow"
  (onClosing)="closeNewComercialOperation()"
  [typeDocument]="typeDocumentNewDoc"
  title="{{ 'form-financial-document.title' | translate }}"
  [selected]="focusedSelected"
  (refreshDocuments)="refreshDocuments()"
  (onInserted)="onInserted($event, 'edit')"
  [mode]="'edit'"
></app-new-financial-operation>

<app-new-financial-operation
  *ngIf="showRow"
  [isVisible]="showRow"
  (onClosing)="closeNewComercialOperation()"
  [typeDocument]="typeDocumentNewDoc"
  title="{{ 'menu.financiaDocuments' | translate }}"
  [selected]="focusedSelected"
  [readOnly]="true"
  [mode]="'show'"
></app-new-financial-operation>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  *ngIf="
    !newRowPopupVisible ||
    isExternalTableVisible ||
    isShortcutTableOpened ||
    isExpansionListVisible ||
    isFinancialRaports
  "
></wapro-keyboard-shortcuts>

<app-table-user-extension
  tableGroupCode="Dokument_finansowy"
  (onClosing)="isExternalTableVisible = false"
  [isVisible]="isExternalTableVisible"
  [isPopupMode]="true"
></app-table-user-extension>

<app-labels
  *ngIf="isLabel"
  [popUpMode]="true"
  (onChoosed)="onChoosedLabel()"
  (onClosed)="isLabel = false"
  [isVisible]="isLabel"
  [readOnly]="readOnly"
  IsForFinanceDoc="true"
  url="finances/selection/label"
></app-labels>

<app-table-data-user-extension
  [readOnly]="readOnly"
  [isVisible]="isShortcutTableOpened"
  tableGroupCode="Dokument_finansowy"
  (onClosing)="isShortcutTableOpened = false; shortcutTable = null"
  [table]="shortcutTable"
></app-table-data-user-extension>

<app-confirm-dialog
  *ngIf="isBlockRow"
  [isVisible]="true"
  (onRemoving)="blockUnblockDocument()"
  (onClosing)="isBlockRow = false"
  [confirmText]="textBlock"
  kindDialog="question"
></app-confirm-dialog>

<app-form-user-extensions
  [isVisible]="visibleUserExtensions"
  [groupCode]="USER_EXTENSIONS_GROUP_CODE"
  [readOnly]="readOnly || onlyShow"
  [groupChanged]="true"
  title="{{ 'categories.externalFields' | translate }}"
  (onChoosed)="setAdditionalFields($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="additionalFieldsList"
></app-form-user-extensions>

<!-- mobile template -->
<ng-template #mobileTemplate>
  <div class="mobile-search-box-container">
    <app-custom-dropdown-box
      [items]="filterCriteria"
      [(selectedItem)]="localStorageData.filter.orderBy"
      [(filterValue)]="filterValue"
      (onValueChanged)="onFilterDataChanged($event)"
      (onArrowDown)="gridClass.instance.focus()"
      #searchControl
    ></app-custom-dropdown-box>

    <dx-button type="normal">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--sort-ascending': localStorageData.filter.order === 'ASC',
          'absui-icon--sort-descending': localStorageData.filter.order !== 'ASC'
        }"
        (click)="switchOrder(); getData()"
      ></i>
    </dx-button>
  </div>
  <app-date-range
    #dateRange
    [readOnly]="readOnly"
    [isPopupMode]="true"
    (onChoosed)="onDateRangeChoosed()"
    #dataRange
    [chipsVisible]="true"
  ></app-date-range>
  <dx-button class="btn-filter-box chips-btn" *ngIf="onlySelected">
    {{ "showSelected" | translate }}:
    <b>{{ "yes" | translate }}</b>
    <i
      class="icon absui-icon--multiplication chips-close"
      (click)="resetFiltr('onlySelected')"
    ></i>
  </dx-button>
  <dx-button
    class="btn-filter-box chips-btn"
    *ngIf="valueCriteria2 != ''"
    (click)="openRightPanel()"
  >
    {{ "filterPanel.type" | translate }}:
    <b>{{ valueCriteria2 }}</b>
    <i
      class="icon absui-icon--multiplication chips-close"
      (click)="resetFiltr('valueCriteria2')"
    ></i>
  </dx-button>

  <app-custom-chips-button
    *ngIf="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
      localStorageData?.advancedFilter?.IsPinned
    "
    [name]="'filterPanel.template' | translate"
    [labelExpr]="'Name'"
    [valueExpr]="'FilterUsersDefinitionId'"
    [selectedValueInput]="localStorageData?.advancedFilter?.Name || null"
    [list]="dynamicFilterUserDefinitions"
    (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
  ></app-custom-chips-button>

  <app-view-dynamic-quick-filters
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [pinnedFilters]="localStorageData.advancedFilter?.PinnedFilters"
    [values]="localStorageData?.advancedFilter?.UserFilterDefinitionValues"
    (onValueChanged)="onPinnedValueChanged($event)"
  ></app-view-dynamic-quick-filters>

  <dx-data-grid
    [showBorders]="true"
    [remoteOperations]="true"
    [allowColumnReordering]="true"
    [dataSource]="dataSource"
    #gridClass
    (onEditorPreparing)="onEditorPreparing($event)"
    (onSelectionChanged)="onSelectionChanged($event)"
    shortcut
    (onInitialized)="onInitialized($event)"
    style="width: 100%; max-height: 100%; margin-bottom: 10px"
    [focusedRowEnabled]="true"
    [(selectedRowKeys)]="selectedRow"
    [height]="heightGrid - 90"
    [(focusedRowIndex)]="focusedRowIndex"
    [wordWrapEnabled]="false"
    [id]="'grid-mobile' + unicalGuid"
    (onKeyDown)="onKeyDown($event)"
    [autoNavigateToFocusedRow]="true"
    (onRowDblClick)="onRowDblClick()"
    (onOptionChanged)="onOptionChanged($event)"
    [allowColumnResizing]="true"
    [columnResizingMode]="'widget'"
    [hoverStateEnabled]="true"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    (onContentReady)="onContentReady($event)"
    class="main-mobile-grid"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="infinite"
      columnRenderingMode="virtual"
      preloadEnabled="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxo-selection
      [mode]="modeSelectionMobile"
      showCheckBoxesMode="always"
      selectAllMode="page"
    ></dxo-selection>

    <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="Number"
      cellTemplate="mainColumn"
      width="200"
    ></dxi-column>

    <div
      appPreventRightClick
      (touchend)="onTouchEnd()"
      (touchstart)="onTouchStart($event)"
      *dxTemplate="let data of 'mainColumn'"
    >
      <div style="display: flex">
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == data.data.Semaphore,
            'semafore-edit-by-another-user':
              userId != data.data.Semaphore && data?.data?.Semaphore != null
          }"
        ></div>
        <div
          class="content-main-grid"
          [ngClass]="{
            'content-main-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <span class="hov-underline-mobile" (click)="showEditContr(data.data)">
            {{ data?.data?.Number }}
          </span>
          <br />
          <label class="mobile-grid-label">
            {{ "form-financial-document.date" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data?.data?.FinanceDocumentDate | date : event.dateFormat }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "form-financial-document.grid.payer-name" | translate }}:
          </label>

          <p class="mobile-grid-text">
            {{
              data?.data?.PayerName?.length > 20
                ? (data?.data?.PayerName | slice : 0 : 20) + "..."
                : data?.data?.PayerName
            }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "form-financial-document.grid.impact" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data?.data?.AmountIncome | amountFormatter : null }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "form-financial-document.grid.outflow" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data?.data?.AmountExpense | amountFormatter : null }}
          </p>
          <br />
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px)"
            (onClick)="showContextMenuMobile(data.data)"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenuMobileSignal()"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>

  <div class="details-mobile-panel" *ngIf="isDetails">
    <header>
      <div class="d-flex flex-row">
        <div class="left-header">
          <i
            class="icon absui-backe"
            style="position: relative; top: 4px; margin-right: 10px"
            (click)="isDetails = false"
          ></i>
        </div>
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == focusedSelected[0].Semaphore,
            'semafore-edit-by-another-user':
              userId != focusedSelected[0].Semaphore &&
              focusedSelected[0].Semaphore != null
          }"
        ></div>
        <div class="right-details">
          <p *ngIf="focusedSelected[0]" class="nameSelectedPosition">
            {{ focusedSelected[0].Number }}
          </p>

          <label class="details-label">
            {{ "form-financial-document.date" | translate }}:
          </label>
          <p class="details-text">
            {{
              focusedSelected[0].FinanceDocumentDate | date : event.dateFormat
            }}
          </p>

          <label class="details-label">
            {{ "form-financial-document.grid.payer-name" | translate }}:
          </label>
          <p class="details-text">{{ focusedSelected[0].PayerName }}</p>

          <label class="details-label">
            {{ "form-financial-document.grid.impact" | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0].AmountIncome | amountFormatter : null }}
          </p>

          <label class="details-label">
            {{ "form-financial-document.grid.outflow" | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0].AmountExpense | amountFormatter : null }}
          </p>
        </div>
      </div>
    </header>

    <div
      class="circle-showMore"
      (click)="isShowMoreDetails = !isShowMoreDetails"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-less': isShowMoreDetails,
          'absui-icon--expand-more': !isShowMoreDetails
        }"
      ></i>
    </div>

    <div class="more-mobile-details" *ngIf="isShowMoreDetails">
      <div>
        <div class="c-frame-row">
          <h5>{{ "tasks.details" | translate }}</h5>
        </div>
      </div>

      <div>
        <table style="width: 100%">
          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.type" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0].Signature }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "bankAccounts.form.currency" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0].CurrencyCode }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.grid.impactCurency" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0].AmountCurrencyIncome
                    | amountFormatter : null : 4
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.grid.outflowCurency" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0].AmountCurrencyExpense
                    | amountFormatter : null : 4
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.statement-number" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].StatementNumber }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.the-titel" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0].Title }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.operation-code" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0].OperationCode }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">PP:</p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                <i
                  class="icon absui-icon--check check-grid"
                  *ngIf="
                    focusedSelected[0].IsSplitPayment;
                    else nonDefaultTemplate
                  "
                ></i>
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                PP {{ "companies.grid.columns.vat" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].SplitPaymentVatNumber }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                PP {{ "workers.documentNumber" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].SplitPaymentInvoiceNumber }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                PP {{ "form-commercial-operation.grid.vatAmount" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].SplitPaymentVatAmountCash }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "worker" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].EmployeeFullName }}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 25px">
        <div class="c-frame-row">
          <h5>{{ "comments" | translate }}</h5>
        </div>
        <p class="details-text">{{ focusedSelected[0].Remarks }}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #nonDefaultTemplate>
  <i class="icon absui-icon--close close-grid"></i>
</ng-template>

<div *ngIf="isFilterPanelComponent">
  <app-filter-panel
    [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
    (onFilterSave)="onFilterSaved($event)"
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [selectedTemplate]="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId
    "
    [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
  ></app-filter-panel>
</div>

<ng-template #rightPanelBox>
  <dx-tab-panel
    [visible]="localStorageData?.sidePanel?.isVisible"
    [width]="width - 30"
    [items]="[1, 2]"
    [(selectedIndex)]="localStorageData.sidePanel.selectedIndex"
    style="margin-top: -10px"
    class="no-padding-tab-panel right-panel-box"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="true"
  >
    <div *dxTemplate="let item of 'title'">
      <div class="title-temp">
        <i class="icon absui-icon--control-tree" *ngIf="item == 1"></i>
        <i class="icon absui-icon--filter" *ngIf="item == 2"></i>
      </div>
    </div>

    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="1">
          <dx-tree-view
            [dataSource]="typeDocument"
            keyExpr="TypeId"
            displayExpr="Name"
            #treeViewAll
            [selectByClick]="true"
            selectionMode="single"
            (onItemClick)="onItemAllClick($event)"
            style="width: 100%"
            (onSelectionChanged)="onTreeSelectionChanged($event)"
            (onInitialized)="restoreTreeConfig()"
          ></dx-tree-view>
        </div>

        <div *ngSwitchCase="2">
          <div style="padding-left: 5px; padding-right: 5px">
            <app-filter-panel
              [deleteFilters]="deleteFilter"
              [openPanelFilters]="visibleAdvanceFilters"
              (onFilterSave)="onFilterSaved($event)"
              [dictionaryCode]="DynamicFilterDictionaryCode"
              [selectedTemplate]="
                localStorageData?.advancedFilter?.FilterUsersDefinitionId
              "
              [isPinned]="localStorageData?.advancedFilter?.IsPinned"
              (onTemplatePinChanged)="onTemplatePinChanged($event)"
              [tabActive]="
                localStorageData?.sidePanel?.isVisible &&
                localStorageData?.sidePanel?.selectedIndex == 1
              "
              [panelWidth]="localStorageData.sidePanel.width"
              [values]="
                localStorageData?.advancedFilter?.UserFilterDefinitionValues
              "
              [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            ></app-filter-panel>
          </div>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <app-wapro-selected-box
      [(ngModel)]="selectedRow"
      [dataSource]="dataSource"
      [selectedAmount]="selectedAmount()"
      [selectedAll]="isAllSelected"
      [mobileSelectMode]="modeSelectionMobile === 'multiple'"
      (onMobileSelectModeOff)="modeSelectionMobile = 'none'"
      (onSelectAll)="gridClass.instance.selectAll()"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [text]="'buttons.add' | translate"
      (onClick)="openNewRow()"
      id="btn-add-comercial-operation"
      [disabled]="
        !perABD.addBtnB || !perABD.addBtnK || !perABD.addBtnI || readOnly
      "
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      (onClick)="mobileOperationsVisible = true; cd.detectChanges()"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder lastBtn"
      icon="icon absui-icon--filter"
      (onClick)="filterMobileVisible = true"
      style="position: absolute; right: 0"
    ></dx-button>

    <!-- mobile reports popup -->
    <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

    <!-- mobile edocoments popup -->
    <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

    <app-mobile-list
      [dataSource]="contextMenuOperationsMobile"
      [visible]="mobileOperationsVisible"
      (onClosed)="mobileOperationsVisible = false; cd.detectChanges()"
      (onItemClick)="contextMenuClick($event)"
    ></app-mobile-list>

    <app-additional-operation
      #additionalOperation
      nameComponent="FinancialOperationComponent"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
    ></app-additional-operation>
  </div>
</ng-template>

<dx-popup
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'filterPanel.filters' | translate }}"
  [(visible)]="filterMobileVisible"
  width="100%"
  height="100vh"
  *ngIf="filterMobileVisible"
  [maxHeight]="event.maxHeightSignal()"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="header-search-mobile">
      <i
        class="icon absui-icon--close"
        style="position: absolute; top: 14px; right: 16px; z-index: 1"
        (click)="filterMobileVisible = false"
      ></i>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
    >
      <br />
      <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
    </dx-scroll-view>

    <div class="bottom-mobile-form">
      <dx-button
        class="btn-dx-top-menu-folder"
        type="danger"
        style="margin-right: 12px; color: #333"
        icon="icon absui-icon--clear-filter"
        (onClick)="clearMobileFilters()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="filterMobileVisible = false"
      ></dx-button>
    </div>
  </div>
</dx-popup>

<app-bank-accounts
  [isVisible]="isShowBankList"
  [popUpMode]="true"
  (onChoosed)="onBankAccountChoosed($event)"
  (closeWindow)="onClosedBankList()"
></app-bank-accounts>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGFL'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-financial-raports
  [isVisible]="isFinancialRaports"
  [popUpMode]="true"
  (onClosing)="isFinancialRaports = false; getData(); cd.detectChanges()"
  [readOnly]="!rkwbPermission"
></app-financial-raports>

<app-contractors
  [popUpMode]="true"
  [selectedId]="focusedSelected[0]?.PayerId"
  [isVisible]="isCustomerVisible"
  *ngIf="isCustomerVisible"
  (onClosed)="isCustomerVisible = false"
  (onChoosed)="onChoosedCustomer($event)"
></app-contractors>
<app-workers
  *ngIf="isWorkersVisible"
  [isVisible]="isWorkersVisible"
  [popUpMode]="true"
  (onChoosed)="onChoosedWorker($event)"
  (onClosed)="isWorkersVisible = false"
></app-workers>
<app-offices
  *ngIf="isOfficeVisible"
  [isVisible]="isOfficeVisible"
  [popUpMode]="true"
  (onChoosed)="onChoosedOffice($event)"
  (onClosed)="isOfficeVisible = false"
></app-offices>

<app-financial-operation-sum
  *ngIf="isSumVisible"
  (onClosing)="onSumClosing()"
  [paramsObj]="paramsObj"
  [fromSelectionAvailable]="selectedRow?.length > 0"
></app-financial-operation-sum>

<app-record-card
  *ngIf="isRecordCardVisible"
  [cardMode]="recordCardMode"
  selectedTab="{{ 'recordCard.cashBank' | translate }}"
  [isPopupMode]="true"
  [isVisible]="isRecordCardVisible"
  [objectId]="recordCardObjectId"
  (onClosing)="onRecordCardClosing()"
></app-record-card>

<app-group-change-operation-code
  *ngIf="isGroupzmoper()"
  [isVisible]="isGroupzmoper()"
  (onClosing)="isGroupzmoper.set(false); event.setFocus(gridClass)"
  (onInserted)="
    isGroupzmoper.set(false);
    deselectAllRecords();
    getData();
    event.setFocus(gridClass)
  "
></app-group-change-operation-code>

<ng-template #bottomTab>
  <dx-tab-panel
    #tabPanel
    [items]="bottomPanelTabs"
    [(selectedIndex)]="localStorageData.bottomPanel.selectedIndex"
    (onTitleClick)="onItemClickTab()"
    (onSelectionChanged)="onSelectionTabChanged()"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    class="bottomTabPanelMobile comercial-operation-step-6"
  >
    <div *dxTemplate="let name of 'title'; let i = index">
      <div
        [id]="'tabToolTip' + i + unicalGuid"
        [attr.data-cy]="'invoices-tabs-' + name"
      >
        <span>{{ name }}</span>
      </div>
    </div>

    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'notes.notes' | translate">
          <app-notes
            #notesComponent
            componentName="financialOperation"
            [objectId]="
              focusedSelected?.length &&
              !newRowPopupVisible &&
              !editRow &&
              !showRow
                ? focusedSelected[0]?.FinanceDocumentId
                : null
            "
            *ngIf="
              bottomPanelTabs[tabPanel.selectedIndex] ===
                ('notes.notes' | translate) &&
              (event.deviceType === 'desktop'
                ? localStorageData.bottomPanel.isVisible
                : true)
            "
            [timeout]="500"
            [componentHeight]="
              event.deviceType != 'mobile'
                ? localStorageData.bottomPanel.height
                : 545
            "
          ></app-notes>
        </div>

        <div *ngSwitchCase="'menu.eDocuments' | translate">
          <app-e-dokuments
            #eDocumentss
            [permission]="perEdocuments"
            DocumentCode="Financial"
            tableName="DOKUMENT_FINANSOWY"
            style="display: inline-block; width: 100%"
            [contextObjectId]="focusedSelected[0]?.FinanceDocumentId"
            [parentDocumentName]="focusedSelected[0]?.Number"
            [isShowSidePanel]="false"
            [heightGrid]="
              event.deviceType != 'mobile' ? heightBottomGrid - 65 : 545 - 65
            "
            [ContextObjectId]="
              focusedSelected.length > 0
                ? focusedSelected[0]?.FinanceDocumentId
                : null
            "
            [readOnly]="focusedSelected.length === 0"
          ></app-e-dokuments>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #reportsTemplate>
  <app-reports
    #reports
    [code]="'Dokument_finansowy'"
    [menuName]="menuName"
    [currencyCode]="
      focusedSelected?.length ? focusedSelected[0]?.CurrencyCode : null
    "
    [type]="focusedSelected?.length ? focusedSelected[0]?.Signature : null"
    [objectId]="
      focusedSelected?.length ? focusedSelected[0]?.FinanceDocumentId : null
    "
    [financialDocument]="focusedSelected?.length ? focusedSelected[0] : null"
    [readOnly]="
      focusedSelected[0]?.Signature === 'PW' ||
      focusedSelected[0]?.Signature === 'PO'
    "
    (onReportsPopupClosed)="onReportsPopupClosed()"
    [isVisible]="
      event.deviceType === 'mobile' ? isMobileReportsPopupVisible : true
    "
    [params]="getLoadParams()"
  ></app-reports>
</ng-template>
<ng-template #edocumentsTemplate>
  <app-e-dokuments
    [isPopupMode]="true"
    [isVisible]="eDocumentsVisible"
    [id]="'eDocuments' + unicalGuid"
    #eDocuments
    [permission]="perEdocuments"
    DocumentCode="Financial"
    tableName="DOKUMENT_FINANSOWY"
    [contextObjectId]="focusedSelected[0]?.FinanceDocumentId"
    [parentDocumentName]="focusedSelected[0]?.Number"
    style="display: inline-block"
    (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
  ></app-e-dokuments>
</ng-template>

import { Socket } from 'src/app/socket.model';
import { Subscription, of, take, switchMap } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectionStrategy,
  ViewChild,
  inject,
  signal,
  computed,
} from '@angular/core';
import { NestFinishedEvent, NestOperation } from 'src/app/nest/nest.model';
import { environment } from '../../../environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';

import { AdditionalOperationComponent } from 'src/app/core/additional-operation/additional-operation.component';
import { CustomChipsButtonComponent } from 'src/app/core/custom-chips-button/custom-chips-button.component';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadOptions } from 'devextreme/data';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { CustomDropdownBoxComponent } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import { ICustomSearchItem } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import { GlobalDate } from 'src/app/core/date-range/GlobalDate';
import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import { DynamicFilterService, ViewUserDefinition } from 'src/app/core/dynamic-filter.service';
import { UserDefinition } from 'src/app/core/filter-panel/filterpanel';
import {
  DataToPrint,
  ReportData,
} from 'src/app/core/reports/IReportParameters';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { TableService } from 'src/app/core/table-user-extension/table-service.service';
import {
  DoubleClickResult,
  RefreshCardResult,
  SingleRecordMode,
} from 'src/app/event.model';
import { EventService } from 'src/app/event.service';
import { HelpService } from 'src/app/help-service.service';
import { PathToNestMap } from 'src/app/nest-resolver.service';
import { NestService } from 'src/app/nest-service.service';
import { FinancialDocument } from '../financial-operation/financial-operation.model';
import { SettlementsService } from './settlements.service';
import { PrintService } from 'src/app/print.service';
import { FINANCE_TYPE_DOCUMENT } from 'src/app/consts';
import { RecordCardMode } from 'src/app/libraries/record-card/record-card.interface';
import { Settlement } from './settlements.model';
import { OnInsertedData } from '../new-financial-operation/new-financial-operation.component';
import { PrintSettingsService } from 'src/app/core/print-settings/print-settings.service';

@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.scss'],
  inputs: [
    'dropDownBoxMode',
    'selectedId',
    'readOnly',
    'popUpMode',
    'isVisible',
    'filtrName',
    'className',
    'chooseBtn',
    'PayerId',
    'signature',
    'CurrencyCode',
    'title',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SettlementsComponent implements OnInit {
  //mobile
  @ViewChild('payerChips') payerChips: CustomChipsButtonComponent;
  @ViewChild('reports') reports: ReportsComponent;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  settlementsService = inject(SettlementsService);
  openCustomDropDown: boolean = false;
  modeSelectionMobile: any = 'none';
  isContextMenuMobile: boolean = false;
  isDetails: boolean = false;
  isShowMoreDetails: boolean = false;

  @ViewChild('additionalOperation')
  additionalOperation: AdditionalOperationComponent;
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @Output() onClosed = new EventEmitter();
  @Output() onChoosed = new EventEmitter();
  @ViewChild('gridBank') gridBank;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;

  USER_EXTENSIONS_GROUP_CODE = 'Rozrachunek';
  title: string = '';
  filterMobileVisible: boolean = false;
  pageSize: number = 100;
  dropDownBoxMode;
  chooseBtn;
  selectedId;
  CurrencyCode;
  readOnly;
  popUpMode;
  isVisible;
  filtrName;
  className;
  PayerId;
  selectedAmount = signal(0);
  helpService = inject(HelpService);
  componentVisible: boolean = false;
  isNestRunning: boolean = false;
  activatedRouteSub: Subscription;
  nestsSub: Subscription;
  nest;
  globalNestSub: Subscription;
  closeNest: Socket;
  componentNests;
  dynamicFilterUserDefinitions: any[] = [];
  readonly DynamicFilterDictionaryCode = 'SETTLEMENT';
  viewUserDefinitionSub: Subscription = null;
  isFilterPanelComponent = false;
  visibleAdvanceFiltersWithoutPanel = false;
  tooltipCancel: boolean = false;
  signature;
  settlementName = '';
  isGridBoxOpened: boolean = false;
  settlementList = [];
  selectedRows = [];
  doubleClickResult: DoubleClickResult = 'Edycja';
  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };
  rowSelected = [];

  unicalGuid;
  dataSource;

  localStorageData: any = {
    bottomPanel: {
      isVisible: true,
      height: 200,
      selectedIndex: 0,
    },
    selectionTab: 0,
    TypeName: '',
    filter: {
      value: '',
      order: 'ASC',
      orderBy: 'numberIntuition',
    },
    valueCriteria2: null,
    columns: <any>[],
    order: 'ASC',
    advancedFilter: null,
    mainPanelWidth: 'calc(100% - 300px)',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 300,
    },
  };

  bottomPanelTabs = ['Notatki'];

  valueCriteria2: any;

  mobileOperationsVisible: boolean = false;
  contextMenuOperationsMobile = [
    { name: 'Zaznacz wiele', icon: 'icon checkAll', itemIndex: 99 },
  ];
  OrderDocumentIdAdditionalField: any = null;
  filterCriteria: ICustomSearchItem[] = [
    // {
    //   value: 'number',
    //   label: '',
    //   translationSrc: 'form-financial-document.number',
    // },
    {
      value: 'numberIntuition',
      label: '',
      translationSrc: 'form-financial-document.numberIntuition',
    },
    {
      value: 'settlementDate',
      label: '',
      type: 'data',
      translationSrc: 'form-financial-document.date',
    },
    {
      value: 'paymentDate',
      label: '',
      type: 'data',
      translationSrc: 'form-commercial-operation.dateOfDaymentts',
    },
    // {
    //   value: 'payerName',
    //   label: '',
    //   translationSrc: 'form-financial-document.payer',
    // },
    {
      value: 'payerNameIntuition',
      label: '',
      translationSrc: 'form-financial-document.payerIntuition',
    },
    { value: 'symbol', label: '', translationSrc: 'warehouse.symbol' },
    { value: 'fkAccount', label: '', translationSrc: 'constractors.accounFK' },
    {
      value: 'totalToPay',
      label: '',
      type: 'number',
      translationSrc: 'stockHistory.available',
    },
  ];
  filterValue = '';
  heightGrid: number = 200;
  width = 345;

  columnsChooserVisible: boolean = false;
  permissionToChooseColums: boolean = false;
  widthLeftPanel = 280;
  focused = [1];

  treeFilterList = [
    {
      expanded: true,
      items: [
        { expanded: true, items: [], TypeId: 0, Name: 'Rozliczone' },
        { expanded: true, items: [], TypeId: 1, Name: 'Nierozliczone' },
        { expanded: true, items: [], TypeId: 2, Name: 'Rozliczone częściowo' },
        {
          expanded: true,
          items: [
            { TypeId: 30, Name: 'Rozliczone', items: [] },
            { TypeId: 31, Name: 'Nierozliczone', items: [] },
            { TypeId: 32, Name: 'Rozliczone częściowo', items: [] },
          ],
          TypeId: 3,
          Name: 'Należności',
        },
        {
          expanded: true,
          items: [
            { TypeId: 40, Name: 'Rozliczone', items: [] },
            { TypeId: 41, Name: 'Nierozliczone', items: [] },
            { TypeId: 42, Name: 'Rozliczone częściowo', items: [] },
          ],
          TypeId: 4,
          Name: 'Zobowiązania',
        },
        {
          expanded: true,
          items: [
            {
              TypeId: 50,
              Name: 'Wpływy',
              items: [
                { TypeId: 500, Name: 'Rozliczone' },
                { TypeId: 501, Name: 'Nierozliczone' },
              ],
              expanded: true,
            },
            {
              TypeId: 51,
              Name: 'Wypływy',
              items: [
                { TypeId: 510, Name: 'Rozliczone' },
                { TypeId: 511, Name: 'Nierozliczone' },
              ],
              expanded: true,
            },
          ],
          TypeId: 5,
          Name: 'Płatności',
        },
        {
          expanded: true,
          items: [
            { TypeId: 60, Name: 'Należności', expanded: true, items: [] },
            { TypeId: 61, Name: 'Zobowiązania', expanded: true, items: [] },
          ],
          TypeId: 6,
          Name: 'Przeterminowane',
        },
        {
          expanded: true,
          items: [
            { TypeId: 70, Name: 'Należności', expanded: true, items: [] },
            { TypeId: 71, Name: 'Zobowiązania', expanded: true, items: [] },
          ],
          TypeId: 7,
          Name: 'Płatne dzisiaj',
        },
        {
          expanded: true,
          items: [
            { TypeId: 80, Name: 'Rozliczone', expanded: true, items: [] },
            { TypeId: 81, Name: 'Nierozliczone', expanded: true, items: [] },
          ],
          TypeId: 8,
          Name: 'Noty odsetkowe',
        },
      ],
      TypeId: '-1',
      Name: 'Wszystkie',
    },
  ];

  isSingleRecordVisible: boolean = false;
  shortcuts: ShortcutInput[] = [];

  myEventChoose;

  defaultVisible: boolean = false;
  tooltipDelete: boolean = false;
  focusedSelected: any = [];
  isDeleteRow: boolean = false;

  userExtensionColumns = {
    Pole1: {
      IsActive: false,
      Name: 'pole1',
    },
    Pole2: {
      IsActive: false,
      Name: 'pole2',
    },
    Pole3: {
      IsActive: false,
      Name: 'pole3',
    },
    Pole4: {
      IsActive: false,
      Name: 'pole4',
    },
    Pole5: {
      IsActive: false,
      Name: 'pole5',
    },
    Pole6: {
      IsActive: false,
      Name: 'pole6',
    },
    Pole7: {
      IsActive: false,
      Name: 'pole7',
    },
    Pole8: {
      IsActive: false,
      Name: 'pole8',
    },
    Pole9: {
      IsActive: false,
      Name: 'pole9',
    },
    Pole10: {
      IsActive: false,
      Name: 'pole10',
    },
  };

  isShowSum: boolean = false;
  isClearances: boolean = false;
  loadChildComponent: boolean = false;
  isFinancial: boolean = false;

  orderby: string = 'number';
  order: string = 'ASC'; // asc/desc
  typeDocumentNewDoc: any = [];
  Type;
  isSendSms = false;
  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();
  printInterestNoteSub: Subscription;
  interestNoteSettlementIdSub: Subscription;

  FiltrDatRozGlo;
  FiltrDatRoz;
  customSettlementDate;
  refreshCardResult: RefreshCardResult = 'Tak';
  focusOnLastRow: RefreshCardResult = 'Nie';
  pinnedViewsSub: Subscription = null;
  closeSub = null;
  readOnlySub = null;
  OdznRozrachPoRozl;
  userId: number = null;
  selectedDocumentsData: FinancialDocument[];
  dataToPrint: DataToPrint = null;
  eDocumentsVisible: boolean;
  perEdocuments: boolean = false;

  RozlRozr: boolean = false;
  additionalOperationList = signal([]);

  contextMenuSignal = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: !this.perABD.addBtn || this.event.readOnly,
        visible:
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO',
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        disabled: !this.perABD.editBtn || this.event.readOnly,
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        disabled: !this.perABD.deleteBtn || this.event.readOnly,
      },
      {
        text: this.translate.instant('buttons.show'),
        icon: 'icon absui-icon--form-items',
        itemIndex: 3,
        disabled: !this.perABD.showBtn,
      },
      {
        text: this.translate.instant('form-commercial-operation.settlement'),
        disabled: !this.RozlRozr,
        itemIndex: 4,
      },
      {
        text: this.translate.instant('repairFunctions.selectAll'),
        icon: 'icon absui-icon--checkbox-all',
        itemIndex: 5,
      },
      {
        text: this.translate.instant('articles.setUnselectedAll'),
        icon: 'icon absui-icon--deselct-all',
        itemIndex: 7,
        disabled: this.selectedAmount() === 0 && !this.isAllSelected(),
      },
      {
        text: this.translate.instant('showSelected'),
        icon: '',
        itemIndex: 70,
        disabled: this.selectedAmount() === 0 && !this.isAllSelected(),
      },
      {
        text: this.translate.instant('labels.labels'),
        itemIndex: 8,
        items: [
          { text: this.translate.instant('labels.assign'), itemIndex: 9 },
          { text: this.translate.instant('labels.delete'), itemIndex: 10 },
        ],
      },
      {
        text: this.translate.instant(
          'form-commercial-operation.additionalOperations'
        ),
        items: this.additionalOperationList(),
        visible: this.additionalOperationList().length > 0,
      },
      // {
      //   text: this.translate.instant('menu.eDocuments'),
      //   itemIndex: 30,
      //   disabled: !this.eDokRozrach, // brak uprawnień
      // },
    ];
  });
  contextMenuMobileSignal = computed(() => {
    return [
      {
        text: this.mobileSelectionText(),
        icon: this.mobileSelectionIcon(),
        itemIndex: 99,
      },
      {
        text: this.translate.instant('tasks.details'),
        icon: 'icon absui-icon--file-edit',
        itemIndex: 98,
      },
      {
        text: this.translate.instant('print'),
        icon: 'icon absui-icon--print',
        itemIndex: 96,
        disabled: this.isPrintDisabled(),
        visible: this.event.deviceType === 'mobile',
      },
      ...this.contextMenuSignal(),
    ];
  });
  mobileSelectionIcon = signal('icon checkAll');
  mobileSelectionText = signal('');
  isPrintDisabled = signal<boolean>(false);
  isRecordCardVisible: boolean;
  recordCardMode: RecordCardMode = 'contractor';
  recordCardObjectId: number;
  heightBottomGrid: number = 210;
  mode: SingleRecordMode;
  isMobileReportsPopupVisible: boolean;

  onSetAdditionalOperation(e) {
    this.additionalOperationList.set(e);
  }

  constructor(
    private appService: AppServices,
    private print: PrintService,
    private tableService: TableService,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public nestService: NestService,
    private router: Router,
    private dynamicFilterService: DynamicFilterService,
    private printSettings: PrintSettingsService
  ) {
    this.RozlRozr = this.event.checkPermissions('RozlRozr');

    this.userId = this.event.returnUserId();
    this.OdznRozrachPoRozl =
      this.event.getConfigurationParameter('OdznRozrachPoRozl')?.Value;
    this.perEdocuments = !this.event.isDisabledEDocuments('Settlements');

    // this.translate
    //   .get('menu.eDocuments')
    //   .subscribe(
    //     (text) => (this.contextMenu.find((x) => x.itemIndex === 30).text = text)
    //   );
    this.translate
      .get('selectMultiple')
      .subscribe((text) => (this.contextMenuOperationsMobile[0].name = text));
    this.closeSub = this.event.closeDrobBox.subscribe(() => {
      this.closeDropBox();
    });

    this.readOnlySub = this.event.sendReadOnlyInfo.subscribe((res) => {
      this.readOnly = res;
      this.getData();
    });

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.event.translateSearchboxLabels(this.filterCriteria);

    this.translate
      .get('filterPanel.all')
      .subscribe((text) => (this.treeFilterList[0].Name = text));
    this.translate
      .get('form-financial-document.settled')
      .subscribe((text) => (this.treeFilterList[0].items[0].Name = text));
    this.translate
      .get('form-financial-document.unsettled')
      .subscribe((text) => (this.treeFilterList[0].items[1].Name = text));
    this.translate
      .get('form-financial-document.partiallySettled')
      .subscribe((text) => (this.treeFilterList[0].items[2].Name = text));
    this.translate
      .get('formPayment.receivables')
      .subscribe((text) => (this.treeFilterList[0].items[3].Name = text));
    this.translate
      .get('form-financial-document.settled')
      .subscribe(
        (text) => (this.treeFilterList[0].items[3].items[0].Name = text)
      );
    this.translate
      .get('form-financial-document.unsettled')
      .subscribe(
        (text) => (this.treeFilterList[0].items[3].items[1].Name = text)
      );
    this.translate
      .get('form-financial-document.partiallySettled')
      .subscribe(
        (text) => (this.treeFilterList[0].items[3].items[2].Name = text)
      );
    this.translate
      .get('formPayment.obligations')
      .subscribe((text) => (this.treeFilterList[0].items[4].Name = text));
    this.translate
      .get('form-financial-document.settled')
      .subscribe(
        (text) => (this.treeFilterList[0].items[4].items[0].Name = text)
      );
    this.translate
      .get('form-financial-document.unsettled')
      .subscribe(
        (text) => (this.treeFilterList[0].items[4].items[1].Name = text)
      );
    this.translate
      .get('form-financial-document.partiallySettled')
      .subscribe(
        (text) => (this.treeFilterList[0].items[4].items[2].Name = text)
      );
    this.translate
      .get('form-financial-document.paymentM')
      .subscribe((text) => (this.treeFilterList[0].items[5].Name = text));
    this.translate
      .get('form-financial-document.income')
      .subscribe(
        (text) => (this.treeFilterList[0].items[5].items[0].Name = text)
      );
    this.translate
      .get('form-financial-document.settled')
      .subscribe(
        (text) =>
          (this.treeFilterList[0].items[5].items[0].items[0].Name = text)
      );
    this.translate
      .get('form-financial-document.unsettled')
      .subscribe(
        (text) =>
          (this.treeFilterList[0].items[5].items[0].items[1].Name = text)
      );
    this.translate
      .get('form-financial-document.outflows')
      .subscribe(
        (text) => (this.treeFilterList[0].items[5].items[1].Name = text)
      );
    this.translate
      .get('form-financial-document.settled')
      .subscribe(
        (text) =>
          (this.treeFilterList[0].items[5].items[1].items[0].Name = text)
      );
    this.translate
      .get('form-financial-document.unsettled')
      .subscribe(
        (text) =>
          (this.treeFilterList[0].items[5].items[1].items[1].Name = text)
      );
    this.translate
      .get('form-financial-document.expired')
      .subscribe((text) => (this.treeFilterList[0].items[6].Name = text));
    this.translate
      .get('formPayment.receivables')
      .subscribe(
        (text) => (this.treeFilterList[0].items[6].items[0].Name = text)
      );
    this.translate
      .get('formPayment.obligations')
      .subscribe(
        (text) => (this.treeFilterList[0].items[6].items[1].Name = text)
      );
    this.translate
      .get('formPayment.paidToday')
      .subscribe((text) => (this.treeFilterList[0].items[7].Name = text));
    this.translate
      .get('formPayment.receivables')
      .subscribe(
        (text) => (this.treeFilterList[0].items[7].items[0].Name = text)
      );
    this.translate
      .get('formPayment.obligations')
      .subscribe(
        (text) => (this.treeFilterList[0].items[7].items[1].Name = text)
      );
    this.translate
      .get('formPayment.interestNotes')
      .subscribe((text) => (this.treeFilterList[0].items[8].Name = text));
    this.translate
      .get('form-financial-document.settled')
      .subscribe(
        (text) => (this.treeFilterList[0].items[8].items[0].Name = text)
      );
    this.translate
      .get('form-financial-document.unsettled')
      .subscribe(
        (text) => (this.treeFilterList[0].items[8].items[1].Name = text)
      );

    // this.translate
    //   .get('mark')
    //   .subscribe((text) => (this.contextMenuMobile[0].name = text));
    // this.translate
    //   .get('tasks.details')
    //   .subscribe((text) => (this.contextMenuMobile[1].name = text));

    // this.contextMenuSignal().forEach((field) =>
    //   this.contextMenuMobile.push(field)
    // );

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests || {};

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });

    this.viewUserDefinitionSub = this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          this.dynamicFilterUserDefinitions = data?.userDefinitions || [];
        },
      });

    this.printInterestNoteSub = this.event.printInterestNote.subscribe(
      (res) => {
        if (res) {
          this.interestNoteSettlementIdSub =
            this.event.interestNoteSettlementId.subscribe((res) => {
              this.printNoteAfterSave(res);
            });
        }
      }
    );

    this.translate
      .get('notes.notes')
      .subscribe((text) => (this.bottomPanelTabs[0] = text));
  }

  isNoGlobal: boolean = false;
  SettlementId = null;

  ngOnInit(): void {
    this.printSettings.currentComponentName.set('settlements');
    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    this.getUserParameters();
    this.deselectAllRecords();
    this.FiltrDatRozGlo =
      this.event.getConfigurationParameter('FiltrDatRozGlo')?.Value;

    this.FiltrDatRoz =
      this.event.getConfigurationParameter('FiltrDatRoz')?.Value;

    if (this.FiltrDatRozGlo != 'Tak') {
      this.isNoGlobal = true;
      this.cd.detectChanges();
    }

    if (localStorage.getItem('settlementCustomDate') && this.isNoGlobal) {
      try {
        this.setDate(JSON.parse(localStorage.getItem('settlementCustomDate')));

        this.customSettlementDate = JSON.parse(
          localStorage.getItem('settlementCustomDate')
        );
      } catch {}
    }
    this.getTypeDocument();
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 170;
    }

    this.perABD = this.event.checkPermissionsBtn(
      'DRozr',
      'ERozr',
      'URozr',
      'ORozr'
    );
    this.permissionToChooseColums = this.event.checkPermissions('KonfKolRozr');

    this.myEventChoose = this.event.choose.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        // if (this.gridClass.instance.getSelectedRowsData().length > 0) this.onChoosingRow();
      }
    });

    setTimeout(() => {
      this.closeNest = this.nestService.findNest(
        this.componentNests?.Sockets,
        'XGRLA'
      );
      this.findSocketAndRun('XGRLO1');
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.SettlementId = params.id;
      if (params.type == 'receivables') {
        this.Type = 3;
      }
      if (params.type == 'obligations') {
        this.Type = 4;
      }
    });

    this.pinnedViewsSub = this.dynamicFilterService
      .getPinnedViewsFilters(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          if (!data) {
            return;
          }
          if (this.localStorageData?.advancedFilter) {
            this.localStorageData.advancedFilter.PinnedFilters =
              data.pinnedFilters;
          } else {
            this.localStorageData.advancedFilter = {
              PinnedFilters: data.pinnedFilters,
            };
          }
        },
      });

    this.additionalOperationShortcutSub =
      this.event.additinalOperationShortcut$.subscribe((code: string) => {
        if (this.popUpMode && !this.isVisible) {
          return;
        }
        if (this.dropDownBoxMode) {
          return;
        }
        if (
          this.isSingleRecordVisible ||
          (this.dropDownBoxMode && !this.isGridBoxOpened) ||
          this.isFinancial ||
          this.isExternalTableVisible ||
          this.isShortcutTableOpened ||
          this.isDeleteRow ||
          (this.popUpMode && !this.isVisible)
        ) {
          return;
        }

        this.contextMenuClick({
          itemData: {
            itemIndex: code,
          },
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.PayerId) {
      this.getData();
    }
    if (changes?.isVisible?.currentValue) {
      this.deselectAllRecords();
    }

    if (changes?.title?.currentValue) {
      this.title = this.translate.instant(`${changes.title.currentValue}`);
    }
  }

  checkSettlementArray(_e?: OnInsertedData) {
    this.printSettings.currentComponentName.set('settlements');
    this.isFinancial = false;
    this.toSettlementArray.shift();
    if (this.toSettlementArray.length == 0) {
      this.getData(this.OdznRozrachPoRozl);
    }

    if (this.toSettlementArray.length > 0) {
      setTimeout(() => {
        this.isFinancial = true;
        this.cd.detectChanges();
      }, 1000);
    }
  }

  onShownPopup() {
    // const nierozliczone = this.treeFilterList[0].items.find(
    //   (field) => field.Name == 'Nierozliczone'
    // );
    let position;
    if (
      this.signature.trim() == 'BP' ||
      this.signature.trim() == 'KP' ||
      this.signature.trim() == 'IW'
    ) {
      const nierozliczone = this.treeFilterList[0].items.find(
        (field) => field.Name == 'Należności'
      );

      position = nierozliczone.items.find(
        (field) => field.Name == 'Nierozliczone'
      );
    }

    if (
      this.signature.trim() == 'KW' ||
      this.signature.trim() == 'BW' ||
      this.signature.trim() == 'IY'
    ) {
      const nierozliczone = this.treeFilterList[0].items.find(
        (field) => field.Name == 'Zobowiązania'
      );

      position = nierozliczone.items.find(
        (field) => field.Name == 'Nierozliczone'
      );
    }

    if (this.PayerId) {
      this.onItemAllClick({
        itemData: {
          TypeId: position.TypeId,
          Name: 'Nierozliczone',
        },
      });

      setTimeout(() => {
        if (this.localStorageData.sidePanel.isVisible) {
          this.treeView?.instance?.selectItem(
            this.localStorageData.valueCriteria2
          );
        }
      }, 0);
    }
  }

  setDate = (date: GlobalDate) => {
    const today = new Date();

    if (date.range == 'lastWeek') {
      date.dateFrom = new Date(new Date().setDate(today.getDate() - 6));
      date.dateTo = today;
      date.year = today.getFullYear();

      if (
        this.customSettlementDate != null &&
        this.customSettlementDate != undefined
      ) {
        localStorage.setItem(
          'settlementCustomDate',
          JSON.stringify(this.customSettlementDate)
        );
      }

      return new GlobalDate(date.range, date.year, date.dataType);
    }

    if (date.range == 'currentDay') {
      date.dateFrom = today;
      date.dateTo = today;
      date.year = today.getFullYear();
      if (
        this.customSettlementDate != null &&
        this.customSettlementDate != undefined
      ) {
        localStorage.setItem(
          'settlementCustomDate',
          JSON.stringify(this.customSettlementDate)
        );
      }
      return new GlobalDate(date.range, date.year, date.dataType);
    }

    if (date.range == 'currentMonth') {
      date.dateFrom = new Date(new Date().setDate(1));
      date.dateTo = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      date.year = today.getFullYear();
      if (
        this.customSettlementDate != null &&
        this.customSettlementDate != undefined
      ) {
        localStorage.setItem(
          'settlementCustomDate',
          JSON.stringify(this.customSettlementDate)
        );
      }
      return new GlobalDate(date.range, date.year, date.dataType);
    }

    if (date.range == 'lastMonth') {
      date.dateFrom = new Date(new Date().setDate(1));
      date.dateTo = new Date(date.dateFrom.setMonth(today.getMonth() - 1));
      date.year = today.getFullYear();
      if (
        this.customSettlementDate != null &&
        this.customSettlementDate != undefined
      ) {
        localStorage.setItem(
          'settlementCustomDate',
          JSON.stringify(this.customSettlementDate)
        );
      }
      return new GlobalDate(date.range, date.year, date.dataType);
    }

    if (date.range == 'currentYear') {
      date.dateFrom = new Date(today.getFullYear(), 0, 1);
      date.dateTo = new Date(today.getFullYear(), 12, 0);
      date.year = today.getFullYear();
      if (
        this.customSettlementDate != null &&
        this.customSettlementDate != undefined
      ) {
        localStorage.setItem(
          'settlementCustomDate',
          JSON.stringify(this.customSettlementDate)
        );
      }
      return new GlobalDate(date.range, date.year, date.dataType);
    }

    return new GlobalDate();
  };

  getSortIcon() {
    return this.localStorageData.filter.order === 'ASC'
      ? 'icon absui-icon--sort-ascending'
      : 'icon absui-icon--sort-descending';
  }

  switchOrder() {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      localStorage.setItem(
        'leftPanelSettlements',
        JSON.stringify(this.localStorageData)
      );
      return;
    }
    this.localStorageData.filter.order = 'ASC';
    localStorage.setItem(
      'leftPanelSettlements',
      JSON.stringify(this.localStorageData)
    );
  }

  toSettlementArray = [];

  setSettlement() {
    if (this.RozlRozr === false) return;
    if (
      this.focusedSelected[0].TotalToPay == 0 &&
      this.focusedSelected[0].TotalToPayCurrency == 0 &&
      !this.readOnly
    ) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.settlementsThatHaveAlreadyBeenClearedCannotBeCleared'
        )
      );
    } else if (!this.readOnly) {
      this.toSettlementArray = [];
      if (this.selectedRows.length > 1) {
        this.appService.postAuth(`finances/clearances`, null).subscribe(
          (res) => {
            res.forEach((field) => {
              this.toSettlementArray.push(field.Id);
            });
            this.isFinancial = true;
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
        return;
      }

      this.appService
        .deleteAuth(`finances/selection/settlements`)
        .subscribe(() => {
          this.appService
            .postAuth(`finances/selection/settlements/select`, {
              ObjectIds: [this.focusedSelected[0].SettlementId],
            })
            .subscribe(() => {
              this.appService.postAuth(`finances/clearances`, null).subscribe(
                (res) => {
                  res.forEach((field) => {
                    this.toSettlementArray.push(field.Id);
                  });
                  this.isFinancial = true;
                  this.cd.detectChanges();
                },
                (error) => {
                  this.event.httpErrorNotification(error);
                }
              );
            });
        });
      return;
    }
  }

  isAllSelected = signal(false);
  selectAll = () => {
    this.isAllSelected.set(true);
    this.cd.detectChanges();
    this.appService
      .postAuth(`finances/selection/settlements`, this.paramsObj)
      .subscribe(() => {});
    this.modeSelectionMobile = 'multiple';
  };

  selectedOwnOperation: any = null;
  runOwnAdditionalOperation(operation: any) {
    this.selectedOwnOperation = operation;
    this.findSocketAndRun(
      `XGRLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E1`
    );
  }

  runInnerOwnOperation() {
    const data = this.event.prepareAdditionalOperationData(
      this.selectedOwnOperation,
      this.currentDate,
      this.selectedRows.length == 0
        ? this.focusedSelected[0].SettlementId
        : null
    );
    this.event.runOwnAdditionalOperation(data).subscribe({
      next: () => {
        if (this.selectedOwnOperation?.RefreshList) {
          this.getData();
        }
        this.findSocketAndRun(
          `XGRLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E2`
        );
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      },
    });
  }

  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        localStorage.setItem(
          'leftPanelSettlements',
          JSON.stringify(this.localStorageData)
        );
        this.getData();
      }
    })
  }

  contextMenuClick = (e) => {
    if (e.itemData.IsOuter) {
      this.runOwnAdditionalOperation(e.itemData);
      return;
    }

    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly && this.perABD.addBtn) {
          this.addNewSettlements();
        }
        break;
      case 1:
        if (!this.readOnly && this.perABD.editBtn) {
          this.showEditContr();
        }
        break;
      case 2:
        if (!this.readOnly && this.perABD.deleteBtn) {
          this.isDelete();
        }
        break;
      case 3:
        if (!this.readOnly && this.perABD.showBtn) {
          this.isSingleRecordVisible = true;
          this.cd.detectChanges();
        }
        break;
      case 4:
        this.setSettlement();
        break;
      case 5:
        this.skipSendSelectionEvent = true;
        this.cd.detectChanges();
        this.gridBank.instance.selectAll();
        this.selectAll();
        break;
      case 6:
        if (!this.readOnly) {
          this.gridBank.instance.deselectRows(
            this.focusedSelected[0].SettlementId
          );
        }
        break;
      case 7:
        this.skipSendSelectionEvent = true;
        this.cd.detectChanges();
        this.selectedRows = [];
        this.deselectAllRecords();
        break;
      case 70:
        if (this.onlySelected) {
          this.revertSelectOnly();
        } else {
          this.onOnlySelected();
        }
        break;
      case 9:
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.isLabel = true;
            this.cd.detectChanges();
          }
        }
        break;
      case 10:
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.event.deleteLabel('finances/settlements/selection/label');
            setTimeout(() => {
              this.selectedRows = [];
              this.dataSource.reload();
            }, 100);
          }
        }
        break;
      // case 30:
      //   this.eDocumentsVisible = true;
      //   this.cd.detectChanges();
      //   break;

      case 99:
        this.modeSelectionMobile = 'multiple';
        let row = this.gridBank.instance.getDataSource().items()[
          this.focusedRowIndex
        ];
        const id = row.SettlementId;
        if (!this.selectedRows.includes(id)) {
          this.gridBank.instance.selectRows(id, true);
        } else {
          this.gridBank.instance.deselectRows(id);
        }
        this.isContextMenuMobile = false;
        break;
      case 98:
        this.isContextMenuMobile = false;
        this.isDetails = true;
        this.cd.detectChanges();
        break;
      case 96:
        this.isMobileReportsPopupVisible = true;
        this.cd.detectChanges();
        break;

      case '0011_000_POLADODGRUP': // grupowe
        this.additionalFieldsList = {
          Field1: '',
          Field2: '',
          Field3: '',
          Field4: '',
          Field5: '',
          Field6: '',
          Field7: '',
          Field8: '',
          Field9: '',
          Field10: '',
        };
        this.onlyShow = false;
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGRLEXROZRACH_BRWPOLADODGRUP_E1');
          }
        }
        break;
      case '0011_000_POLADOD': // zwykle
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.additionalFieldsList = this.focusedSelected[0];
          this.findSocketAndRun('XGRLEXROZRACH_BRWPOLADOD_E1');
        }
        break;
      case '1111_000_SMSROZRACH': // sms
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGRLEXROZRACH_BRWSMSROZRACH_E1');
          }
        }
        break;
      case '1111_000_KARTA_KONTR':
        this.showRecordCard('contractor');
        break;
    }
  };

  visibleUserExtensions = false;
  additionalFieldsList = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
    if (!this.additionalFieldDocumentId) {
      this.findSocketAndRun('XGRLEXROZRACH_BRWPOLADODGRUP_E2');
    } else {
      this.findSocketAndRun('XGRLEXROZRACH_BRWPOLADOD_E2');
    }
  };

  setAdditionalFields = (e) => {
    if (!this.additionalFieldDocumentId) {
      this.appService
        .putAuth(
          `finances/additionalOperations/settlements/setAdditionalFields`,
          e
        )
        .subscribe(
          () => {
            // this.visibleUserExtensions = false;
            this.additionalFieldsList = {
              Field1: '',
              Field2: '',
              Field3: '',
              Field4: '',
              Field5: '',
              Field6: '',
              Field7: '',
              Field8: '',
              Field9: '',
              Field10: '',
            };
            this.dataSource.reload();
            this.selectedRows = [];
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            this.findSocketAndRun('XGRLEXROZRACH_BRWPOLADODGRUP_E2');
          }
        );
    } else {
      // TO DO - brak endpointu dla pojedynczej zmiany pol dodatkowych
      // this.appService
      //   .putAuth(
      //     `finances/additionalOperations/settlements/${this.additionalFieldDocumentId}/setAdditionalFields`,
      //     e
      //   )
      //   .subscribe(
      //     (res) => {
      //       this.visibleUserExtensions = false;
      //       this.additionalFieldsList = {
      //         Field1: '',
      //         Field2: '',
      //         Field3: '',
      //         Field4: '',
      //         Field5: '',
      //         Field6: '',
      //         Field7: '',
      //         Field8: '',
      //         Field9: '',
      //         Field10: '',
      //       };
      //       this.dataSource.reload();
      //       this.cd.detectChanges();
      //     },
      //     (error) => {
      //       this.event.httpErrorNotification(error)
      //     },
      //     () => {
      //       this.findSocketAndRun('XGRLEXROZRACH_BRWPOLADOD_E2');
      //     }
      //   );
    }
  };

  additionalOperationShortcutSub: Subscription = null;

  ngOnDestroy() {
    this.additionalOperationShortcutSub?.unsubscribe();
    this.localStorageData.columns = this.event.setWidthColumnGrid(
      this.gridBank
    );
    this.tableService.removeTableContext('Rozrachunek');
    this.pinnedViewsSub?.unsubscribe();
    this.viewUserDefinitionSub?.unsubscribe();
    this.myEventChoose?.unsubscribe();
    this.currentDateSub?.unsubscribe();

    if (!this.popUpMode) {
      localStorage.setItem(
        'leftPanelSettlements',
        JSON.stringify(this.localStorageData)
      );
    }

    this.activatedRouteSub?.unsubscribe();
    this.globalNestSub?.unsubscribe();
    this.nestsSub?.unsubscribe();
    this.closeSub?.unsubscribe();
    this.readOnlySub?.unsubscribe();
    this.interestNoteSettlementIdSub?.unsubscribe();
    if (this.printInterestNoteSub) {
      this.printInterestNoteSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.event.isAdditionalTableAvailable()) {
      this.additionalTables = this.event.getTablesByGroupCode('Rozrachunek');
    }
    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.getData();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            return;
          }
          this.helpService.openHelp('sprzedaz-i-magazyn/dokumenty/rozrachunki');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + o',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.shiftKey) {
            this.additionalOperation.openList();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + s',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.altKey) {
            this.isShowSum = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.localStorageData?.sidePanel?.selectedIndex === 0) {
            this.localStorageData.sidePanel.selectedIndex = 1;
            this.cd.detectChanges();
          }
          if (
            this.localStorageData.sidePanel.selectedIndex === 1 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
              this.cd.detectChanges();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.cd.detectChanges();
            this.openAdvanceFiltersWithoutPanel();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.perABD.addBtn) {
            this.addNewSettlements();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.clearValues();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            if (this.onlySelected) {
              this.revertSelectOnly();
            } else {
              this.onOnlySelected();
            }
          } else {
            this.openCustomDropDown = !this.openCustomDropDown;
          }
        },
        preventDefault: true,
      },
      {
        key: 'f7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            this.setCustomerFromFocusedRow();
          } else {
            this.openPayer();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (
            !this.readOnly &&
            !e.event.shiftKey &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            if (this.focusedSelected.length > 0) {
              this.showEditContr();
            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isGridBoxOpened || !this.dropDownBoxMode) {
            if (this.focusedSelected.length > 0) {
              this.mode = 'show';
              this.isSingleRecordVisible = true;
              this.cd.detectChanges();
            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + r',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.setSettlement();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.isSingleRecordVisible && this.perABD.deleteBtn) {
            this.isDelete();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + y',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.additionalTables.length) {
            return;
          }

          if (this.focusedSelected.length == 1) {
            this.isExternalTableVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.localStorageData.bottomPanel.selectedIndex = 0;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );

    if (this.router.url == '/trade-operations/settlements/add') {
      this.addNewSettlements();
    }

    let idDocument;
    let mode;
    this.activatedRoute.params.subscribe(
      (params) => (idDocument = parseInt(params['SettlementId']))
    );
    this.activatedRoute.params.subscribe((params) => (mode = params['mode']));
    if (idDocument) {
      this.appService
        .getAuth(`finances/settlements?ObjectId=${idDocument}`)
        .subscribe((res) => {
          this.focusedSelected = [res.data[0]];
          if (mode == 'edit') {
            this.showEditContr();
          }
          if (mode == 'show' && this.perABD.showBtn) {
            this.showSingleRecord();
          }
        });
    }

    for (let table of this.additionalTables) {
      if (table.Shortcut) {
        this.shortcuts.push({
          key: table.Shortcut.toLowerCase(),
          allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
          command: () => {
            if (this.focusedSelected.length) {
              this.shortcutTable = [table];
              this.isShortcutTableOpened = true;
              this.cd.detectChanges();
            }
          },
          preventDefault: true,
        });
      }
    }

    this.event
      .getUserExtension('Rozrachunek', this.userExtensionColumns)
      .then((res: any) => {
        try {
          this.localStorageData.columns =
            this.event.getAdditionalColumnsCaptions(
              res,
              this.localStorageData.columns
            );
        } catch {}
      });
    this.getViewConfigurations();

    setTimeout(() => {
      this.loadChildComponent = true;
      this.cd.detectChanges();
    }, 1000);
  }
  showSingleRecord() {
    this.mode = 'show';
    this.title = this.translate.instant('buttons.preview');
    this.isSingleRecordVisible = true;
    this.cd.detectChanges();
  }

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
  }

  @ViewChild('treeView') treeView;
  getViewConfigurations = () => {
    try {
      if (!localStorage.getItem('leftPanelSettlements')) {
        this.heightGrid = window.innerHeight - 170;
        this.gridBank?.instance?.updateDimensions();
        this.getData();
      } else if (
        localStorage.getItem('leftPanelSettlements') &&
        !this.dropDownBoxMode
      ) {
        this.localStorageData = Object.assign(
          this.localStorageData,
          JSON.parse(localStorage.getItem('leftPanelSettlements'))
        );
        if(!this.localStorageData?.advancedFilter || !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId){
          this.getDefaultFilter();
        }
        else if (
          this.localStorageData?.advancedFilter
            ?.FilterUsersDefinitionId ||
          this.localStorageData?.advancedFilter?.IsPinned
        ) {
          this.getSavedFilterDefinitions();
        }
        if (this.localStorageData?.advancedFilter?.PinnedFilters) {
          this.dynamicFilterService.addPinnedViewsFilters(
            this.DynamicFilterDictionaryCode,
            this.localStorageData.advancedFilter.PinnedFilters
          );
        }

        this.onItemAllClick({
          itemData: {
            TypeId: this.localStorageData.valueCriteria2,
            Name: this.localStorageData.TypeName,
          },
        });

        setTimeout(() => {
          if (this.localStorageData?.sidePanel?.isVisible) {
            this.treeView?.instance?.selectItem(
              this.localStorageData.valueCriteria2
            );
          }
        }, 0);

        setTimeout(() => {
          if (this.localStorageData.sidePanel.width != null) {
            try {
              this.width = Number(
                this.localStorageData.sidePanel.width.replace('px', '')
              );
            } catch {
              this.width = this.localStorageData.sidePanel.width;
            }
          }
          if (this.localStorageData.mainPanelWidth != null) {
            this.rightPanel.nativeElement.style.width =
              this.localStorageData.mainPanelWidth;
          }
        }, 0);
        this.gridBank?.instance?.updateDimensions();
        this.cd.detectChanges();
        try {
          this.valueCriteria2 = this.localStorageData.valueCriteria2;
          this.nameFilterCriteria = this.localStorageData.TypeName;

          this.updateColumns();
          this.cd.detectChanges();

          this.getData();
        } catch (e) {}
      }
    } catch (e) {
      this.onInitErr = true;
      this.cd.detectChanges();
    }
  };

  onInitErr: boolean = false;
  nameFilterCriteria = '';
  checkItemFilter = (item) => {
    try {
      item.items.forEach((element) => {
        if (element.TypeId == this.localStorageData.valueCriteria2) {
          this.treeView.instance.selectItem(element.TypeId);
          this.nameFilterCriteria = element.Name;
        }
      });
      item.items.forEach((element) => {
        this.checkItemFilter(element);
      });
    } catch {}
  };

  resetFiltr = (filtr) => {
    switch (filtr) {
      case 'valueCriteria2':
        this.valueCriteria2 = '';
        this.treeView?.instance.unselectAll();
        this.restoreTreeConfig();
        break;
      case 'advancedFilter':
        this.localStorageData.advancedFilter =
          this.dynamicFilterService.createDefaultAdvancedFilter(
            this.localStorageData.advancedFilter.IsPinned
          );
        break;
      case 'onlySelected':
        this.onlySelected = false;
        break;
    }
    this.localStorageData.valueCriteria2 = null;
    this.localStorageData.TypeName = '';
    this.nameFilterCriteria = '';
    localStorage.setItem(
      'leftPanelSettlements',
      JSON.stringify(this.localStorageData)
    );
    this.getData();
  };

  contextMenuPreparing(e: any): void {
    if (e.target == 'header') e.items = [];
  }

  updateColumns() {
    if (this.localStorageData.columns) {
      setTimeout(() => {
        const instance = this.gridBank?.instance;

        if (!instance) {
          return;
        }

        this.localStorageData.columns.forEach((col, i) => {
          instance.columnOption(col.caption, 'visible', col.visible);
          instance.columnOption(col.caption, 'visibleIndex', i);
          instance.columnOption(col.caption, 'width', col.width);
          instance.columnOption(col.caption, 'fixed', col.fixed);
        });
        this.cd.detectChanges();
      }, 250);
    }
  }

  onRowClick = (e) => {
    try {
      this.focusedSelected = [e.data];
    } catch {}
  };

  firstLoad = true;
  indexToFocus: number;
  setFocusOnLoad = false;
  paramsObj = {};
  getData = (OdznRozrachPoRozl?, fromSearch: boolean = false) => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'SettlementId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}finances/settlements`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          this.focusedRowIndex =
            data.length > this.event.initFocusedRowIndex('settlementsDocuments')
              ? this.event.initFocusedRowIndex('settlementsDocuments')
              : 0;
          if (OdznRozrachPoRozl) {
            if (OdznRozrachPoRozl == 'Odznacz wszystkie') {
              this.selectedRows = [];
              this.focusedSelected = [];
            }
            if (OdznRozrachPoRozl == 'Odznacz rozliczone') {
              for (let i = this.selectedRows.length - 1; i >= 0; i--) {
                let dat = data.find(
                  (field) => field.SettlementId == this.selectedRows[0]
                );
                if (dat.TotalToPay == 0 && dat.TotalToPayCurrency == 0) {
                  this.selectedRows.splice(i, 1);
                }
              }
            }
          }
          if (!data.length) {
            this.focusedSelected = [];
            return;
          }
          const toAdd = data.filter(
            (el) =>
              el.IsSelected && this.selectedRows.indexOf(el.SettlementId) === -1
          );
          for (let item of toAdd) {
            this.selectedRows.push(item.SettlementId);
            this.skipSendSelectionEvent = true;
            this.cd.detectChanges();
          }
          const toDelete = [];
          for (let id of this.selectedRows) {
            const item = data.find((el) => el.SettlementId === id);
            if (item && !item.IsSelected) {
              toDelete.push(id);
            }
          }
          for (let id of toDelete) {
            this.selectedRows.splice(
              this.selectedRows.findIndex((el) => el === id),
              1
            );
            this.skipSendSelectionEvent = true;
            this.cd.detectChanges();
          }
          if (data.length > 0) {
            // this.focusedSelected = [data[this.focusedRowIndex]];
            if (!this.firstLoad) {
              this.event.setFocus(this.gridBank);
            }
          } else {
            this.focusedSelected = [];
          }
          if (this.firstLoad) {
            this.firstLoad = false;
            this.searchControl?.focusInput();
          } else if (fromSearch) {
            this.searchControl?.focusInput();
          }
        },
        deleteUrl: `${environment.domain}finances/settlements`,
      }),
      reshapeOnPush: true,
      requireTotalCount: true,
      paginate: true,
    });
    return;
  };

  isLabel: boolean = false;
  onChoosedLabel = () => {
    this.selectedRows = [];
    this.isLabel = false;
    this.dataSource.reload();
  };

  getLoadParams() {
    if (this.onlySelected) {
      this.paramsObj = {
        onlySelected: true,
      };
      return this.paramsObj;
    }

    let obj: any = {};
    (obj.orderBy = this.localStorageData.filter.orderBy), //this.valueCriteria[0].toLowerCase() + this.valueCriteria.substr(1),//
      (obj.order = this.localStorageData.filter.order);

    if (this.Type) {
      this.valueCriteria2 = this.Type;
      this.nameFilterCriteria = '';
    }

    if (this.CurrencyCode) {
      obj.CurrencyCode = this.CurrencyCode;
    }
    // if (this.classificationIdFilter != '') obj.classificationId = this.classificationIdFilter;
    // if (this.groupIdFilter != '') obj.groupId = this.groupIdFilter;

    obj.DateFrom =
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '';
    obj.DateTo =
      this.event.dataFormatted({ value: this.currentDate.dateTo }) || '';

    if (this.isNoGlobal && this.customSettlementDate) {
      obj.DateFrom =
        this.event.dataFormatted({
          value: this.customSettlementDate.dateFrom,
        }) || '';
      obj.DateTo =
        this.event.dataFormatted({ value: this.customSettlementDate.dateTo }) ||
        '';
    }

    if (this.useForSelection) {
      obj.UseForSelection = true;
    }

    obj.FilterByPaymentDate =
      this.FiltrDatRoz == 'termin płatności' ? true : false;

    switch (this.valueCriteria2) {
      case 0:
        obj.settlementStatus = 2;
        break;
      case 1:
        obj.settlementStatus = 0;
        break;
      case 2:
        obj.settlementStatus = 1;
        break;
      case 3:
        obj.settlementType = 'N';
        obj.PaymentDirection = 1;
        break;
      case 30:
        obj.settlementType = 'N';
        obj.settlementStatus = 2;
        obj.PaymentDirection = 1;
        break;
      case 31:
        obj.settlementType = 'N';
        obj.settlementStatus = 0;
        obj.PaymentDirection = 1;
        break;
      case 32:
        obj.settlementType = 'N';
        obj.settlementStatus = 1;
        obj.PaymentDirection = 1;
        break;
      case 4:
        obj.settlementType = 'Z';
        obj.PaymentDirection = -1;
        break;
      case 40:
        obj.settlementType = 'Z';
        obj.settlementStatus = 2;
        obj.PaymentDirection = -1;
        break;
      case 41:
        obj.settlementType = 'Z';
        obj.settlementStatus = 0;
        obj.PaymentDirection = -1;
        break;
      case 42:
        obj.settlementType = 'Z';
        obj.settlementStatus = 1;
        obj.PaymentDirection = -1;
        break;
      case 5:
        obj.settlementType = 'P';
        break;
      case 50:
        obj.settlementType = 'P';
        obj.paymentDirection = -1;
        break;
      case 500:
        obj.settlementType = 'P';
        obj.paymentDirection = -1;
        obj.settlementStatus = 2;
        break;
      case 501:
        obj.settlementType = 'P';
        obj.paymentDirection = -1;
        obj.settlementStatus = 0;
        break;
      case 51:
        obj.settlementType = 'P';
        obj.paymentDirection = 1;
        break;
      case 510:
        obj.settlementType = 'P';
        obj.paymentDirection = 1;
        obj.settlementStatus = 2;
        break;
      case 511:
        obj.settlementType = 'P';
        obj.paymentDirection = 1;
        obj.settlementStatus = 0;
        break;
      case 6:
        obj.settlementType = 'expired';
        break;
      case 60:
        obj.settlementType = 'expired';
        obj.paymentDirection = 1;
        break;
      case 61:
        obj.settlementType = 'expired';
        obj.paymentDirection = -1;
        break;
      case 7:
        obj.PaymentDate = new DatePipe('en-US').transform(
          new Date(),
          'yyyy-MM-dd'
        );
        obj.SettlementStatus = 3;
        break;
      case 70:
        obj.PaymentDate = new DatePipe('en-US').transform(
          new Date(),
          'yyyy-MM-dd'
        );
        obj.SettlementStatus = 3;
        obj.paymentDirection = 1;
        break;
      case 71:
        obj.PaymentDate = new DatePipe('en-US').transform(
          new Date(),
          'yyyy-MM-dd'
        );
        obj.SettlementStatus = 3;
        obj.paymentDirection = -1;
        break;
      case 8:
        obj.settlementType = 'interestNotes';
        break;
      case 80:
        obj.settlementType = 'interestNotes';
        obj.settlementStatus = 0;
        break;
      case 81:
        obj.settlementType = 'interestNotes';
        obj.settlementStatus = 1;
        break;
      default:
        break;
    }

    this.filterValue = this.localStorageData.filter.value;
    switch (this.localStorageData.filter.orderBy) {
      case 'number':
        obj.number = this.filterValue;
        break;
      case 'settlementDate':
        if (this.filterValue != '' && this.filterValue != null) {
          obj.DateFrom = this.event.setCorrectDate(this.filterValue);
          obj.DateTo = this.event.setCorrectDate(this.filterValue);
        }
        break;
      case 'paymentDate':
        obj.paymentDate = this.event.setCorrectDate(this.filterValue);
        break;
      case 'payerName':
        obj.payerName = this.filterValue;
        break;
      case 'payerNameIntuition':
        obj.payerNameIntuition = this.filterValue;
        break;
      case 'symbol':
        obj.symbol = this.filterValue;
        break;
      case 'numberIntuition':
        obj.numberIntuition = this.filterValue;
        break;
      case 'fkAccount':
        obj.fkAccount = this.filterValue;
        break;
      case 'totalToPay':
        obj.totalToPay = this.filterValue;
        break;
      default:
        break;
    }

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    if (this.customerFilter.PayerType !== 'all') {
      obj.PayerType = this.customerFilter.PayerType;
    }
    if (this.customerFilter.CustomerId) {
      obj.PayerId = this.customerFilter.CustomerId;
    } else if (this.customerFilter.OfficeId) {
      obj.PayerId = this.customerFilter.OfficeId;
    } else if (this.customerFilter.WorkerId) {
      obj.PayerId = this.customerFilter.WorkerId;
    }

    if (this.PayerId) {
      obj.PayerId = this.PayerId;
      obj.settlementType = null;
    }

    if (this.SettlementId) {
      obj.ObjectId = this.SettlementId;
      obj.DateFrom = '';
      obj.DateTo = '';
      obj.settlementStatus = null;
    }

    this.paramsObj = obj;
    return obj;
  }

  closeDropBox = () => {
    this.isGridBoxOpened = false;
  };

  visibleChange = (e) => {
    if (!e) {
      this.onClosed.emit(false);
      this.componentVisible = false;
    }
  };

  onChoosedSettlements = () => {
    if (this.focusedSelected.length > 0) {
      if (this.selectedRows.length > 0) {
        let PayerId;
        let PaymentDirection;
        this.toSettlementArray = [];
        this.selectedRows.forEach((field, index) => {
          let findItem = this.dataSource._items.find(
            (item) => item.SettlementId == field
          );
          if (index == 0) {
            PayerId = findItem.PayerId;
            PaymentDirection = findItem.PaymentDirection;
          }
          if (
            findItem.PayerId == PayerId &&
            findItem.PaymentDirection == PaymentDirection &&
            findItem.Semaphore == null
          ) {
            this.toSettlementArray.push(findItem);
          }
        });
        this.onChoosed.emit(this.toSettlementArray);
        this.componentVisible = false;
        return;
      } else {
        if (this.focusedSelected[0].Semaphore != null) {
          this.event.showNotification(
            'error',
            this.translate.instant('form-commercial-operation.cantAddDocument')
          );

          return;
        }

        this.toSettlementArray = this.focusedSelected;
        this.toSettlementArray[0].isNoSelect = true;

        this.onChoosed.emit(this.toSettlementArray);
        this.componentVisible = false;
      }
    }
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRows = [];
      this.onChoosed.emit(null);
    }
  };

  openedChange = (e) => {
    if (e) {
      try {
        setTimeout(() => {
          this.gridBank.instance.focus();
        }, 500);
      } catch {}
    }
  };

  addNewSettlements = () => {
    if (
      this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE' ||
      this.event.footerInfo.VariantName === 'FAKTUROWANIE' ||
      this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE PRO' ||
      this.event.footerInfo.VariantName === 'FAKTUROWANIE PRO'
    ) {
      return;
    }

    if (!this.readOnly && this.perABD.addBtn) {
      this.findSocketAndRun('XGRLYD1', 'add');
    }
  };

  addNewSettlementsBetween() {
    this.mode = 'add';
    this.title = this.translate.instant('form-commercial-operation.title');
    this.isSingleRecordVisible = true;
    this.cd.detectChanges();
  }

  showEditContr = (i?) => {
    if (i) {
      this.focusedSelected = [i];
    }
    if (!this.readOnly && this.perABD.editBtn) {
      this.findSocketAndRun('XGRLYP1', 'edit');
    }
  };

  showEditContrBetween() {
    this.prepareForModify();
  }

  onChoosingCustomers = () => {
    if (!this.readOnly) {
      // this.contractorsList = this.contractorsListPom;
      // this.customerName = this.selectedRows[0];
      // this.nestService.nestCardChoosed(this.contractorsList[0])
      // this.isGridBoxOpened = false;
      // this.cd.detectChanges();
    }
  };

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.localStorageData.filter.orderBy = e.selectedItem.value;
      this.filterValue = e.filterValue;
    }
    localStorage.setItem(
      'leftPanelSettlements',
      JSON.stringify(this.localStorageData)
    );
    this.localStorageData.filter.value = e.filterValue;
    this.filterValue = e.filterValue;
    this.getData(undefined, true);
  }

  focusedRowIndex = 0;
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  keyDownTimeout = null;

  handleSearchFromGrid(key: string) {
    const orderByItem = this.filterCriteria.find(
      (el) => el.value === this.localStorageData.filter.orderBy
    );
    this.filterValue = this.event.setFilterValueBySearchKey(
      this.filterValue,
      key,
      orderByItem.type === 'data'
    );
    this.localStorageData.filter.value = this.filterValue;

    if (this.keyDownTimeout) {
      clearTimeout(this.keyDownTimeout);
    }
    this.keyDownTimeout = setTimeout(() => {
      if (orderByItem.type === 'data') {
        if (
          this.event.setCorrectDate(this.filterValue) !==
          this.paramsObj[this.localStorageData.filter.orderBy]
        ) {
          this.getData();
        }
        return;
      }
      this.getData();
    }, 300);
  }

  onKeyDown(e) {
    if (
      this.event.isSearchGridKey(
        e.event.keyCode,
        e.event.altKey,
        e.event.ctrlKey
      )
    ) {
      this.handleSearchFromGrid(e.event.key);
    } else if (e.event.key === 'O' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.additionalOperation.openList();
    } else if (e.event.key === 'S' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.isShowSum = true;
      this.cd.detectChanges();
    } else if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.key === 'F9' && !e.event.ctrlKey && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.dateRange.openDateRange();
    } else if (e.event.key === 'F6' && e.event.shiftKey) {
      e.event.stopPropagation();
      e.event.preventDefault();
      if (this.onlySelected) {
        this.revertSelectOnly();
      } else {
        this.onOnlySelected();
      }
    } else if (e.event.key === 'F7') {
      e.event.preventDefault();
      e.event.stopPropagation();
      if (e.event.shiftKey) {
        this.setCustomerFromFocusedRow();
      } else {
        this.openPayer();
      }
      this.cd.detectChanges();
    } else if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.keyCode === 107) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.cd.detectChanges();
      this.gridBank.instance.selectAll();
      this.selectAll();
    } else if (e.event.keyCode === 109) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.cd.detectChanges();
      this.selectedRows = [];
      this.deselectAllRecords();
    }

    if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    }
    if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onGridContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }

    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onRowDblClick = () => {
    if (!this.readOnly) {
      if (!this.dropDownBoxMode && this.perABD.editBtn) {
        if (this.doubleClickResult === 'Edycja') {
          this.showEditContr();
        } else {
          this.showSingleRecord();
        }
      } else {
        this.onChoosingCustomers();
      }
    }
  };

  onFocusedRowChanged = (e) => {
    if (e && e.row && e.row.data) {
      this.focusedSelected = [e.row.data];
      this.event.setFocusedRowIndex(
        'settlementsDocuments',
        this.focusedRowIndex
      );
      if (e && e.row.data) {
        this.tableService.addTableContext({
          id: e.row.data.SettlementId,
          idParentObject: e.row.data,
          tableName: 'Rozrachunek',
        });
      }
    }
  };

  onOptionChanged = (e) => {
    if (e.name === 'columns' && e.fullName.indexOf('width') > -1) {
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        this.localStorageData.columns[colIndex].width = e.value;
      } catch (e) {}
    }
  };

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  }

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 280) {
      width = 280;
    }
    if (width > 620) {
      width = 620;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    this.localStorageData.sidePanel.width = `${width}px`;
    this.localStorageData.sidePanel.isVisible = true;
    this.localStorageData.mainPanelWidth = `calc(100% - ${rightWidth}px)`;
    localStorage.setItem(
      'leftPanelSettlements',
      JSON.stringify(this.localStorageData)
    );
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  hideRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '10px';
    this.localStorageData.sidePanel.isVisible = false;
    this.width = 21;
    this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
    this.localStorageData.sidePanel.width = `21px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
  };

  showRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '300px';
    this.width = 280;
    this.localStorageData.sidePanel.isVisible = true;
    this.rightPanel.nativeElement.style.width = `calc(100% - 300px)`;
    this.localStorageData.sidePanel.width = `300px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 300px)`;
  };

  resizeWindowBtn() {
    if (this.width > 21) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
    }
  }

  onItemAllClick = (e) => {
    this.nameFilterCriteria = '';
    e.itemData.TypeId == -1
      ? (this.valueCriteria2 = '')
      : (this.valueCriteria2 = e.itemData.TypeId);
    this.localStorageData.valueCriteria2 = e.itemData.TypeId;
    this.localStorageData.TypeName = e.itemData.Name;
    if (e.itemData.TypeId != -1) {
      this.nameFilterCriteria = e.itemData.Name;
    }
    if (!this.popUpMode) {
      localStorage.setItem(
        'leftPanelSettlements',
        JSON.stringify(this.localStorageData)
      );
    }
    this.getData();
  };

  onClosingForm = () => {
    this.printSettings.currentComponentName.set('settlements');
    this.dataSource.reload();
    this.isSingleRecordVisible = false;
    this.isShowSum = false;
    setTimeout(() => {
      this.gridBank.instance.focus();
    }, 300);
    this.cd.detectChanges();
  };

  onInserted = (e) => {
    this.focusedSelected = [e];
    this.dataSource.reload().then((data) => {
      let index = data.findIndex(
        (field) => field.SettlementId == e.SettlementId
      );

      if (index > -1) {
        this.focusedRowIndex = index;
        this.gridBank.instance.focus();
      } else {
        this.focusedRowIndex = 0;
        this.focusedSelected = [data[0]];
        this.gridBank.instance.focus();
      }
    });

    switch (this.mode) {
      case 'add':
        this.findSocketAndRun('XGRLYD2');
        break;
      case 'edit':
        this.findSocketAndRun('XGRLYP2');
        break;
    }
  };

  isDelete = () => {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.isDeleteRow = true;
      this.cd.detectChanges();
    }
  };

  onDateRangeChoosed(e) {
    if (this.isNoGlobal) {
      if (e != null && e != undefined) {
        localStorage.setItem('settlementCustomDate', JSON.stringify(e));
        this.customSettlementDate = e;
      }
    }
    if (this.refreshCardResult === 'Tak') {
      this.onlySelected = false;
      this.getData();
    }
  }

  getFinanceTypes() {
    const localCache = this.event.getSessionStorageData(FINANCE_TYPE_DOCUMENT);
    if (localCache) {
      return of({ data: localCache });
    }
    return this.appService.getAuth(`document/sales/types/finance`);
  }

  getTypeDocument = () => {
    this.typeDocumentNewDoc = [];
    this.getFinanceTypes().subscribe((res) => {
      this.event.saveSessionStorageData(FINANCE_TYPE_DOCUMENT, res.data);
      res.data.forEach((element) => {
        if (
          element.OperationType == 'k' ||
          element.OperationType == 'b' ||
          element.OperationType == 'i' ||
          element.OperationType == 'c'
        ) {
          this.typeDocumentNewDoc.push(element);
        }
      });
    });
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      this.gridBank.instance.focus();
    }, 500);
  }

  delete = () => {
    this.findSocketAndRun('XGRLYU1', 'delete');
  };

  deleteBetween() {
    this.isDeleteRow = false;
    this.gridBank.instance.deleteRow(
      this.gridBank.instance.getRowIndexByKey(
        this.focusedSelected[0].SettlementId
      )
    );
    setTimeout(() => {
      if (this.gridBank) {
        this.focusedSelected = [];
        this.gridBank.focusedRowIndex = 0;
        this.gridBank.instance.focus();
        this.getData();
      }
    }, 500);
  }

  sendRequestTimer;
  useForSelection: boolean = false;
  onlySelected = false;
  skipSendSelectionEvent = false;
  onSelectionChanged = (e: any) => {
    this.selectedDocumentsData = e.selectedRowsData;
    if (this.skipSendSelectionEvent) {
      this.toSelect = [];
      this.toDeselect = [];
      this.skipSendSelectionEvent = false;
      return;
    }

    if (!this.onlySelected) {
      if (
        e.currentDeselectedRowKeys.length &&
        e.currentDeselectedRowKeys.length === this.dataSource._totalCount
      ) {
        this.deselectAllRecords();
        return;
      }
    }

    this.toSelect = this.toSelect.concat(e.selectedRowKeys);
    this.selectedAmount.set(
      this.selectedAmount() + e.currentSelectedRowKeys.length
    );
    if (this.selectedAmount() < 0) {
      this.selectedAmount.set(0);
    }
    this.toDeselect = this.toDeselect.concat(e.currentDeselectedRowKeys);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (
      this.selectedRows.length === 0 ||
      (this.selectedRows.length &&
        this.selectedRows.length !== this.dataSource._items.length)
    ) {
      this.isAllSelected.set(false);
    }

    if (this.dataSource._items.length) {
      this.setSelectionTimeout();
    }
  };

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        this.toSelect = [];
        if (this.selectedRows.length != 0) {
          this.event.selectRecords(
            `finances/selection/settlements/select`,
            this.selectedRows
          );
        }
      }
      if (this.toDeselect.length) {
        const items = this.dataSource.items();
        const toRemove = [];
        for (let toDes of this.toDeselect) {
          const item = items.find((el) => el.SettlementId === toDes);
          if (!item) {
            toRemove.push(this.toDeselect.indexOf(toDes));
          }
        }
        for (let i = toRemove.length - 1; i >= 0; i--) {
          this.toDeselect.splice(toRemove[i], 1);
        }
        this.selectedAmount.set(this.selectedAmount() - this.toDeselect.length);
        if (this.selectedAmount() < 0) {
          this.selectedAmount.set(0);
        }
        if (this.toDeselect.length != 0) {
          this.event.selectRecords(
            `finances/selection/settlements/unselect`,
            this.toDeselect
          );
        }
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  };

  additionalTables = [];
  isExternalTableVisible: boolean = false;
  shortcutTable;
  isShortcutTableOpened: boolean = false;

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGRLYD1':
        this.findSocketAndRun('XGRLYA1', 'add');
        break;
      case 'XGRLYP1':
        this.findSocketAndRun('XGRLYA1', 'edit');
        break;
      case 'XGRLYU1':
        this.findSocketAndRun('XGRLYA1', 'delete');
        break;
      case 'XGRLYA1':
        if (this.nestOperation === 'add') {
          this.addNewSettlementsBetween();
        } else if (this.nestOperation === 'edit') {
          this.showEditContrBetween();
        } else if (this.nestOperation === 'delete') {
          this.deleteBetween();
          this.findSocketAndRun('XGRLYU2', 'delete');
        }
        break;
      case 'XGRLYU2':
        this.findSocketAndRun('XGRLYA2');
        break;
      case 'XGRLO1':
        this.componentVisible = true;
        this.cd.detectChanges();
        if (this.onInitErr) {
          this.getViewConfigurations();
        }
        this.findSocketAndRun('XGRLO2');
        break;
      case 'XGRLO2':
        this.searchControl?.focusInput();
        break;
      case 'XGRLYD2':
        this.findSocketAndRun('XGRLYA2');
        break;
      case 'XGRLYP2':
        this.findSocketAndRun('XGRLYA2');
        break;
      case 'XGRLEXROZRACH_BRWPOLADODGRUP_E1':
        this.onlyShow = false;
        this.additionalFieldDocumentId = null;
        this.visibleUserExtensions = true;
        this.cd.detectChanges();
        break;
      case 'XGRLEXROZRACH_BRWPOLADOD_E1':
        this.additionalFieldDocumentId = this.focusedSelected[0].SettlementId;
        this.onlyShow = true;
        this.visibleUserExtensions = true;
        this.cd.detectChanges();
        break;
      case 'XGRLEXROZRACH_BRWSMSROZRACH_E1':
        this.isSendSms = true;
        this.cd.detectChanges();
        break;
      case `XGRLEX${
        this.selectedOwnOperation?.GroupCode
      }_${this.selectedOwnOperation?.Name?.toUpperCase()}_E1`:
        this.runInnerOwnOperation();
        break;
      default:
        break;
    }
  }

  additionalFieldDocumentId: number = null;
  onlyShow = false;
  nestOperation: NestOperation;

  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false; // znalazlo
    } else {
      // nieznalazlo
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  clearMobileFilters = () => {
    this.resetFiltr('valueCriteria2');
  };

  getUserParameters() {
    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }

    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }

    const focusOnLastRow = this.event.getConfigurationParameter('NaOstatZapis');
    if (focusOnLastRow) {
      this.focusOnLastRow = focusOnLastRow.Value as RefreshCardResult;
    }
  }

  visibleAdvanceFilters = false;

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'leftPanelSettlements',
      this.event.encryptString(this.localStorageData)
    );

    this.getData();
  }

  restoreTreeConfig = () => {
    setTimeout(() => {
      if (this.nameFilterCriteria == '') {
        this.treeView?.instance.selectItem(-1);
      }
    }, 0);
  };

  lastTreeSelection;

  onTreeSelectionChanged = (e) => {
    const selectedNodes = e.component
      .getSelectedNodes()
      .map((node) => node.itemData);
    if (selectedNodes.length > 0) {
      this.lastTreeSelection = selectedNodes;
    }
    e.component.selectItem(this.lastTreeSelection);
  };

  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 1,
      isPanelVisible: this.localStorageData.sidePanel.isVisible,
    };

    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
        config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex ==
        config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
  };

  deleteFilter = false;
  advancedFilterTemplateData = null;

  clearValues(onlySelected: boolean = false) {
    this.SettlementId = null;
    const url = new URL(window.location.href);
    url.search = '';
    history.replaceState(null, '', url.toString());

    this.onlySelected = onlySelected;
    if (onlySelected) {
      this.event.setCurrentDateForOnlySelected();
    } else {
      this.event.setCurrentDate(new GlobalDate(), false, true);
    }
    this.deleteFilter = true;
    this.cd.detectChanges();
    if (
      !this.advancedFilterTemplateData &&
      this.localStorageData.advancedFilter
    ) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter?.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.cd.detectChanges();
    }
    this.customerFilter.PayerType = null;
    this.cd.detectChanges();
    this.clearCustomerFilterValues();
    this.resetFiltr('valueCriteria2');
    this.getData();
    this.deleteFilter = false;
  }

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getData();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onPinnedValueChanged(e) {
    const modifiedIndex =
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.findIndex(
        (el) => el.FilterDefinitionId === e.FilterDefinitionId
      );
    if (modifiedIndex > -1) {
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.splice(
        modifiedIndex,
        1
      );
    }
    this.localStorageData.advancedFilter.UserFilterDefinitionValues = [
      ...this.localStorageData.advancedFilter.UserFilterDefinitionValues,
      e,
    ];
    this.cd.detectChanges();
  }

  getSavedFilterDefinitions() {
    const windowId = this.event.getFilterWindowIdFromDictionaryCode(
      this.DynamicFilterDictionaryCode
    );
    this.dynamicFilterService.getViewUserDefinition(this.DynamicFilterDictionaryCode).pipe(
      take(1),
      switchMap((data: ViewUserDefinition) => {
        if(data){
          return of(null)
        }
        else{
          return this.dynamicFilterService.getUsersDefinitionsForWindowId(windowId)
        }
      })
    ).subscribe({
        next: (data: UserDefinition[]) => {
          if(!data) return;

          this.dynamicFilterService.addViewUserDefinitions(
            this.DynamicFilterDictionaryCode,
            data || []
          );
        },
        error: () => {},
      });
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.getData();
    this.cd.detectChanges();
  };

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGR');
    }
    this.gridBank.instance.focus();
  }

  onScanerInputChanged(e) {
    this.setSearchCriteria('numberIntuition');
    this.filterValue = e.toString();
    this.getData();
  }

  onOnlySelected() {
    this.clearValues(true);
  }

  revertSelectOnly() {
    this.onlySelected = false;
    this.getData();
  }

  onDeselectAll() {
    this.onlySelected = false;
    this.skipSendSelectionEvent = true;
    this.deselectAllRecords();
    this.getData();
  }

  deselectAllRecords() {
    this.selectedAmount.set(0);
    this.isAllSelected.set(false);
    return this.event.deselectAllRecords('finances/selection/settlements');
  }

  onEditorPreparing(e) {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  prepareForModify() {
    this.settlementsService
      .prepareForModify(this.focusedSelected[0].SettlementId)
      .subscribe({
        next: () => {
          this.mode = 'edit';
          this.title = this.translate.instant(
            'form-commercial-operation.editInvoice'
          );
          this.isSingleRecordVisible = true;
          this.cd.detectChanges();
        },
        error: () => {},
      });
  }

  payerTypes = [
    {
      label: 'wszystko',
      value: 'all',
    },
    {
      label: 'kontrahent',
      value: 'k',
    },
    {
      label: 'pracownik',
      value: 'p',
    },
    {
      label: 'urząd',
      value: 'u',
    },
  ];
  isWorkersVisible = false;
  isOfficeVisible = false;
  isCustomerVisible = false;

  customerFilter = {
    CustomerId: null,
    CustomerName: '',
    WorkerId: null,
    WorkerName: '',
    OfficeId: null,
    OfficeName: '',
    PayerType: 'all',
  };

  onChoosedCustomer = (e) => {
    if (e) {
      this.customerFilter.CustomerId = e.CustomerId;
      this.customerFilter.CustomerName = e.Name;
      this.customerFilter.PayerType = 'k';
      this.isCustomerVisible = false;
      this.getData();
    }
  };

  setCustomerFromFocusedRow() {
    if (
      this.focusedSelected[0] &&
      this.customerFilter.CustomerId !== this.focusedSelected[0].PayerId
    ) {
      this.clearCustomerFilterValues();
      this.customerFilter.PayerType = this.focusedSelected[0].PayerType;
      if (this.focusedSelected[0].PayerType === 'k') {
        this.customerFilter.CustomerId = this.focusedSelected[0].PayerId;
        this.customerFilter.CustomerName = this.focusedSelected[0].PayerName;
      } else if (this.focusedSelected[0].PayerType === 'u') {
        this.customerFilter.OfficeId = this.focusedSelected[0].PayerId;
        this.customerFilter.OfficeName = this.focusedSelected[0].PayerName;
      } else if (this.focusedSelected[0].PayerType === 'p') {
        this.customerFilter.WorkerId = this.focusedSelected[0].PayerId;
        this.customerFilter.WorkerName = this.focusedSelected[0].PayerName;
      }
      this.getData();
    }
  }

  onPayerTypeChanged(e: string) {
    this.customerFilter.PayerType = e;
    this.resetPayerFilter();
  }

  onChoosedWorker(e) {
    this.customerFilter.WorkerId = e?.EmployeeId;
    this.customerFilter.WorkerName = e?.FirstName + ' ' + e?.LastName;
    this.getData();
  }

  onChoosedOffice(e) {
    this.customerFilter.OfficeId = e?.TaxOfficeId;
    this.customerFilter.OfficeName = e?.Name;
    this.getData();
  }

  openPayer() {
    if (this.customerFilter.PayerType === 'all') {
      (this.payerChips as any)?.eRef?.nativeElement?.click();
      this.payerChips.isChipsList.set(true);
      this.cd.detectChanges();
      setTimeout(() => {
        this.payerChips.listRef.instance.focus();
      }, 100);
    } else if (this.customerFilter.PayerType === 'k') {
      this.isCustomerVisible = true;
      this.cd.detectChanges();
    } else if (this.customerFilter.PayerType === 'p') {
      this.isWorkersVisible = true;
      this.cd.detectChanges();
    } else if (this.customerFilter.PayerType === 'u') {
      this.isOfficeVisible = true;
      this.cd.detectChanges();
    }
  }

  clearCustomerFilterValues() {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
    this.customerFilter.WorkerId = null;
    this.customerFilter.WorkerName = '';
    this.customerFilter.OfficeId = null;
    this.customerFilter.OfficeName = '';
  }

  resetPayerFilter() {
    this.clearCustomerFilterValues();
    this.getData();
  }

  onSmsClosing() {
    this.isSendSms = false;
    this.findSocketAndRun('XGRLEXROZRACH_BRWSMSROZRACH_E2');
  }

  getSettlementData(id) {
    return new Promise((resolve) => {
      this.appService.getAuth(`finances/settlements?ObjectId=${id}`).subscribe({
        next: (res) => {
          resolve(res?.data[0]);
        },
        error: () => {
          resolve(null);
        },
      });
    });
  }

  async printNoteAfterSave(documentId: number) {
    /* get document data from api */
    const documentData: any = await this.getSettlementData(documentId);
    if (!documentData) {
      return;
    }

    /* prepare report data */
    const reportData: ReportData = {
      allowFiscalPrint: false,
      code: 'Rozrachunek',
      currencyCode: documentData?.CurrencyCode || 0,
      documentNumber: documentData.DocumentNumber,
      menuName: documentData.MenuName,
      objectId: documentId,
      report: null,
      reportTypeId: null,
      type: 'NOTA',
      actions: {
        downloadPdf: false,
        sendToEdocuments: false,
        email: false,
        print: true,
      },
      printAfterSave: true,
      isBatch: false,
      lang: null,
    };
    const reportTypes = await this.print.getReportTypes(documentData.MenuName);
    reportData.report = this.print.getReportTemplate(
      reportData.menuName,
      reportData.type,
      reportTypes
    );
    /* call print method */
    this.print.printReport(reportData);
  }

  showContextMenuMobile(rowData: Settlement) {
    if (this.selectedRows.includes(rowData.SettlementId)) {
      this.mobileSelectionIcon.set('icon absui-icon--deselct-all');
      this.mobileSelectionText.set(this.translate.instant('unmark'));
    } else {
      this.mobileSelectionIcon.set('icon checkAll');
      this.mobileSelectionText.set(this.translate.instant('mark'));
    }
    this.isContextMenuMobile = true;
  }

  touchTimeout: any;

  onTouchStart(e) {
    e.stopPropagation();
    this.touchTimeout = setTimeout(() => {
      this.modeSelectionMobile = 'multiple';
      let row = this.gridBank.instance.getDataSource().items()[
        this.focusedRowIndex
      ];
      const id = row.SettlementId;
      if (!this.selectedRows.includes(id)) {
        this.gridBank.instance.selectRows(id, true);
      } else {
        this.gridBank.instance.deselectRows(id);
      }
    }, 1500);
  }

  onTouchEnd() {
    clearTimeout(this.touchTimeout);
  }

  onRecordCardClosing(): void {
    this.isRecordCardVisible = false;
    this.cd.detectChanges();
  }

  showRecordCard(mode: RecordCardMode): void {
    this.recordCardMode = mode;
    switch (mode) {
      case 'contractor':
        this.recordCardObjectId = this.focusedSelected[0].PayerId;
        break;
    }
    if (!this.recordCardObjectId) return;
    this.isRecordCardVisible = true;
    this.cd.detectChanges();
  }

  onItemClickTab = () => {
    if (!this.localStorageData.bottomPanel.isVisible) {
      this.localStorageData.bottomPanel.isVisible;
      this.localStorageData.bottomPanel.height = 230;
      this.heightBottomGrid = 205;
      this.heightGrid = window.innerHeight - this.heightBottomGrid - 225;

      this.cd.detectChanges();
    }

    this.gridBank?.instance?.updateDimensions();
  };

  onSelectionTabChanged = () => {
    this.localStorageData.bottomPanel.selectedIndex =
      this.localStorageData.bottomPanel.selectedIndex;
    try {
      if (this.focusedSelected[0].InvoiceDocumentId) {
        this.getDetails(this.focusedSelected[0].InvoiceDocumentId);
      }
    } catch {}
  };

  getDetails = (_id: number) => {
    if (this.localStorageData.bottomPanel.isVisible) {
      // switch (this.selectedtabIndex) {
      switch (
        this.bottomPanelTabs[this.localStorageData.bottomPanel.selectedIndex]
      ) {
        case this.translate.instant('notes.notes'):
          break;
      }
    }
  };

  onResizingBottom(e) {
    this.localStorageData.bottomPanel.height = e.height;
    this.heightGrid = e.heightGrid;
    this.heightBottomGrid = e.heightBottomGrid;
    this.localStorageData.bottomPanel.isVisible = e.isVisible;
  }

  onReportsPopupClosed() {
    this.dataToPrint = null;
    this.isMobileReportsPopupVisible = false;
    this.cd.detectChanges();
  }
}

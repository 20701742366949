import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  signal,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

import { EventService } from '../../../event.service';
import { TranslateService } from '@ngx-translate/core';
import { FinanceOperationComponent } from '../../../libraries/finance-operation/finance-operation.component';
import { AppServices } from '../../../app-services.service';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

interface FinanceOperationCode {
  Code: string;
  Name: string;
}

@Component({
    selector: 'app-group-change-operation-code',
    templateUrl: './group-change-operation-code.component.html',
    styleUrl: './group-change-operation-code.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GroupChangeOperationCodeComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() isVisible: boolean = false;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('financialOperation')
  financialOperation: FinanceOperationComponent;
  title = signal<string>('');

  widthWindow: number | string;
  heightWindow: number | string = '90%';
  unicalGuid: number;
  maxWidth: number;
  shortcuts: ShortcutInput[] = [];

  private initializeShortcuts(): void {
    this.shortcuts = [
      {
        key: 'enter',
        preventDefault: true,
        allowIn: [AllowIn.Input, AllowIn.Textarea],
        command: () => this.choosedItem(),
      },
      {
        key: 'escape',
        preventDefault: true,
        allowIn: [AllowIn.Input, AllowIn.Textarea],
        command: () => this.closeWindow(),
      },
    ];
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public event: EventService,
    private translate: TranslateService,
    private appServices: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.maxWidth = window.innerWidth;
    this.widthWindow = this.event.setWidthPopUp();

    this.title.set(
      this.translate.instant('form-commercial-operation.financialOperation')
    );
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initializeShortcuts();
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp();
  }

  closeWindow(): void {
    this.isVisible = false;
    this.cdr.detectChanges();
    this.onClosing.emit(false);
  }

  visibleChange(e): void {
    if (!e) {
      this.onClosing.emit(false);
    }
    this.cdr.detectChanges();
  }

  choosedItem(): void {
    if (this.financialOperation.focusedSelected.length === 0) return;

    this.financialOperation.onChoosingRow();
  }

  onChoosed(e: FinanceOperationCode): void {
    this.appServices
      .putAuth(
        `finances/additionalOperations/changeOperationCodes`,
        `"${e.Code}"`,
        true
      )
      .subscribe({
        next: () => {
          this.onInserted.emit();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }
}

<dx-popup
  [width]="widthWindow"
  height="615px"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'form-financial-document.item' | translate }}"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  (onShown)="event.onShownPopUp(); setFocus()"
  *ngIf="openNestFinished"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="addItem()"
        id="btn-save-item"
        data-cy="financial-operation-item-form-submit"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        id="btn-cancel-item"
      >
      </dx-button>

      <dx-tooltip
        target="#btn-save-item"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        target="#btn-cancel-item"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <form [formGroup]="itemForm">
        <div class="form-columns">
          <div class="form-column">
            <!-- Kwota -->
            <div class="form-column__item">
              <label
                >{{ "form-financial-document.grid.amount" | translate
                }}<strong>*</strong></label
              >
              <div class="form-control">
                <dx-number-box
                  formControlName="Amount"
                  [readOnly]="
                    IsBaseCurrency ||
                    (itemForm.get('SettlementGeneratedType').value == 2 &&
                      mode == 'add')
                  "
                  [format]="format"
                  [ngClass]="{
                    'input-required':
                      !itemForm.controls.Amount.valid && submitted
                  }"
                  valueChangeEvent="input"
                  (onFocusIn)="event.onNumberBoxFocusIn($event)"
                  (onFocusOut)="event.onFocusOut('Amount', itemForm)"
                  #firsFocus
                  data-cy="financial-operation-form-input-amount"
                ></dx-number-box>
              </div>
            </div>

            <div class="form-column__item" *ngIf="IsBaseCurrency">
              <label>{{
                "form-financial-document.amountInCurrency" | translate
              }}</label>
              <div class="form-control">
                <dx-number-box
                  formControlName="AmountCurrency"
                  [readOnly]="
                    itemForm.get('SettlementGeneratedType').value == 2 &&
                    mode == 'add'
                  "
                  [format]="formatCurrency"
                  (onValueChanged)="countAmount()"
                  valueChangeEvent="input"
                  #secondFocus
                >
                </dx-number-box>
              </div>
            </div>

            <!-- Opis -->
            <div class="form-column__item">
              <label
                >{{ "form-financial-document.description" | translate
                }}<strong>*</strong></label
              >
              <div class="form-control">
                <app-description-dictionary
                  [dropDownBoxMode]="true"
                  type="O"
                  (onClosed)="remarksFault = false; cd.detectChanges()"
                  heightArea="230"
                  formControlName="Description"
                  title="{{
                    'form-financial-document.description' | translate
                  }}"
                  (onChoosed)="onChoosedDescription($event)"
                  #inputRemarks
                  [requiredWarning]="
                    !itemForm.controls.Description.valid && submitted
                  "
                  data-cy="financial-operation-form-input-description"
                >
                </app-description-dictionary>
              </div>
            </div>
            <div class="form-column__header">
              {{ "form-financial-document.selectHowToAddTtems" | translate }}
            </div>
            <div class="form-column__item">
              <div class="form-control">
                <dx-radio-group
                  displayExpr="label"
                  valueExpr="value"
                  [dataSource]="howToAddItems"
                  [readOnly]="itemForm.value.SettlementId != 0"
                  formControlName="SettlementGeneratedType"
                  (onValueChanged)="onValueSettlementTypeChange($event)"
                >
                </dx-radio-group>
              </div>
            </div>
            <div class="form-column__item">
              <div class="form-control">
                <dx-button
                  class="btn-dx-secondary"
                  text="{{
                    'form-financial-document.selectSettlement' | translate
                  }}"
                  (onClick)="isVisibleSettlements = true"
                  width="auto"
                  [disabled]="
                    itemForm.get('SettlementGeneratedType').value != 2 ||
                    itemForm.value.SettlementId != 0
                  "
                >
                </dx-button>

                <app-settlements
                  *ngIf="isVisibleSettlements"
                  [title]="'form-financial-document.selectSettlement'"
                  [popUpMode]="true"
                  [isVisible]="isVisibleSettlements"
                  [PayerId]="PayerId"
                  [selectedId]="itemForm.get('SettlementId').value"
                  chooseBtn="true"
                  (onChoosed)="onChoosedSettlements($event)"
                  (onClosed)="isVisibleSettlements = false"
                  [signature]="Signature"
                  [CurrencyCode]="CurrencyCode"
                >
                </app-settlements>
              </div>
            </div>

            <div class="form-column__item">
              <div class="form-control">
                <dx-check-box
                  text="{{ 'form-financial-document.payment' | translate }}"
                  [readOnly]="
                    itemForm.get('SettlementGeneratedType').value != 1
                  "
                  [(value)]="positionTypeValue"
                  (onValueChanged)="positionTypeChanged($event)"
                ></dx-check-box>
              </div>
            </div>

            <div class="form-column__item">
              <div class="form-control">
                <dx-select-box
                  displayExpr="label"
                  valueExpr="value"
                  (onValueChanged)="onSelectValueChanged($event)"
                  [(value)]="paymentMethod"
                  [dataSource]="saveGenerateList"
                  [readOnly]="
                    itemForm.get('SettlementGeneratedType').value != 1
                  "
                >
                </dx-select-box>
              </div>
            </div>

            <div class="form-column__item">
              <div class="form-control">
                <wapro-date-box
                  type="date"
                  [displayFormat]="event.dateFormat"
                  (onValueChanged)="onValueChangedDate($event)"
                  [readOnly]="
                    itemForm.get('SettlementGeneratedType').value != 1 ||
                    paymentMethod == 2
                  "
                  formControlName="PaymentDate"
                >
                </wapro-date-box>
              </div>

              <div class="form-column__item">
                <div class="form-control">
                  <dx-check-box
                    formControlName="IsBaseCurrency"
                    [readOnly]="true"
                    style="position: relative; top: 10px"
                    text="{{
                      'form-financial-document.generateSettlementTbaseCurrency'
                        | translate
                    }}"
                    *ngIf="IsBaseCurrency"
                  ></dx-check-box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <wapro-keyboard-shortcuts
        [shortcuts]="shortcuts"
        [disabled]="
          isVisibleSettlements || !isVisible || isExpansionListVisible
        "
        [unicalGuid]="unicalGuid"
      >
      </wapro-keyboard-shortcuts>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGFP'"
  [objectParameters]="nestObjectParameters"
  [parentObjectParameters]="parentObjectParameters"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGFP'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

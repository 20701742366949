import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { LoadOptions } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { environment } from '../../../../environments/environment';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import {
  PrintConfig,
} from 'src/app/core/print-settings/print-config-storage-data.model';
import { PrintService } from 'src/app/print.service';
import { DatePipe } from '@angular/common';
import { PrintSettingsService } from 'src/app/core/print-settings/print-settings.service';

@Component({
    selector: 'app-new-financial-raport',
    templateUrl: './new-financial-raport.component.html',
    styleUrls: ['./new-financial-raport.component.scss'],
    inputs: [
        'isVisible',
        'title',
        'readOnly',
        'mode',
        'ReportType',
        'SourceId',
        'CurrencySymbol',
        'selectedId',
    ],
    standalone: false
})
export class NewFinancialRaportComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @Output() onPrintAfterSave = new EventEmitter<{
    document: any;
    documentsToPrint: string[];
  }>();
  @ViewChild('dateFormat') dateFormat;
  isVisible;
  title;
  readOnly;
  unicalGuid;
  widthWindow = '1100';
  heightWindow = '90%';
  maxHeight;
  maxWidth;
  mode;
  dataSource;
  form;
  SourceId;
  pageSize = 100;
  ReportType;
  CurrencySymbol;
  selectedId;
  editDatePermission = false;
  addCurrencySum: boolean = false;
  modeCurrecny: string = 'add';

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices,
    private print: PrintService,
    private printSettings: PrintSettingsService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;

    this.editDatePermission = this.event.checkPermissions('RKWBDokFinData');
  }

  ngOnInit() {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.onCreateForm();
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes?.isVisible?.currentValue) {
      this.printSettings.currentComponentName.set('newFinancialRaport');
    }
    if (this.isVisible && this.mode == 'edit') {
      this.form.patchValue(this.selectedId[0]);
      this.getSums();
    }
  }

  shortcuts: ShortcutInput[] = [];
  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onCancel();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onAddCurrencySum();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onEditRow();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select],
        command: () => {
          this.onDelete();
        },
        preventDefault: true,
      },
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  onAddCurrencySum() {
    if (this.readOnly) return;

    this.modeCurrecny = 'add';
    this.addCurrencySum = true;
    this.cd.detectChanges();
  }

  onCancel() {
    if (this.mode == 'add' && this.form.value.ReportId != 0) {
      this.appService
        .deleteAuth(`finances/cashBankReports/${this.form.value.ReportId}`)
        .subscribe(
          () => {},
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
    this.isVisible = false;
    this.cd.detectChanges();
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.onCreateForm();
    }
  };

  createHeader() {
    if (this.mode == 'add') {
      this.form.controls['SourceId'].setValue(this.SourceId);
      this.form.controls['ReportType'].setValue(this.ReportType);
      this.form.controls['CurrencySymbol'].setValue(this.CurrencySymbol);

      let object = {
        SourceId: this.SourceId,
        ReportType: this.ReportType,
        CurrencySymbol: this.CurrencySymbol,
        IsCurrency: this.CurrencySymbol.trim() != 'PLN' ? true : false,
      };

      this.appService.postAuth(`finances/cashBankReports`, object).subscribe(
        (res) => {
          this.form.patchValue(res);
          (' ');
          this.form.controls['Date'].setValue(
            new DatePipe('en-US').transform(new Date(), 'yyyy-MM-ddTHH:mm:ss')
          );
          this.form.controls['DateFrom'].setValue(
            new DatePipe('en-US').transform(new Date(), 'yyyy-MM-ddTHH:mm:ss')
          );
          this.form.controls['DateTo'].setValue(
            new DatePipe('en-US').transform(new Date(), 'yyyy-MM-ddTHH:mm:ss')
          );
          this.form.controls['CurrencySymbol'].setValue(this.CurrencySymbol);
          this.cd.detectChanges();
          this.getSums();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
      return;
    }
  }

  focusedRowIndex = 0;
  getSums() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CurrencyReportId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}finances/cashBankReports/${this.form.value.ReportId}/sums`,
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          if (data.length > 0) {
            this.focusedSelected = [data[0]];
            this.focusedRowIndex = 0;
          }
        },
      }),
      reshapeOnPush: true,
      requireTotalCount: true,
      paginate: true,
    });
  }

  isDeleteRow: boolean = false;
  focusedSelected = [];
  onFocusedRowChanged(e) {
    this.focusedSelected = [e.row.data];
  }

  onDelete() {
    if (this.readOnly) return;

    this.isDeleteRow = true;
    this.cd.detectChanges();
  }

  closeConfirm() {
    this.isDeleteRow = false;
    this.cd.detectChanges();
  }

  delete() {
    this.isDeleteRow = false;
    this.cd.detectChanges();
    this.appService
      .deleteAuth(
        `finances/cashBankReports/${this.focusedSelected[0].ReportId}/sums/${this.focusedSelected[0].CurrencyReportId}`
      )
      .subscribe(
        () => {
          this.getSums();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  onRowClick = (e) => {
    this.focusedSelected = [e.data];
  };

  onEditRow = () => {
    if (this.readOnly) return;
    this.modeCurrecny = 'edit';
    this.addCurrencySum = true;
    this.cd.detectChanges();
  };

  onCreateForm = () => {
    this.form = this.formBuilder.group({
      ReportId: [0],
      SourceId: [0],
      ReportType: [''],
      Date: [null],
      Number: [''],
      AutoNumber: [0],
      DateFrom: [null],
      DateTo: [null],
      OpenBalance: [0],
      Income: [0],
      Outflow: [0],
      CloseBalance: [0],
      CurrencySymbol: [''],
      ReportTypeId: [0],
      Amount: [0],
      SplitPaymentId: [0],
      IsCurrency: [false],
    });
  };

  onSave() {
    this.form.controls['IsCurrency'].setValue(
      this.CurrencySymbol.trim() != 'PLN' ? true : false
    );
    if (this.mode == 'add') {
      let object = {
        SourceId: this.form.value.SourceId,
        ReportType: this.form.value.ReportType,
        CurrencySymbol: this.form.value.CurrencySymbol,
        IsCurrency: this.form.value.IsCurrency,
        ReportId: this.form.value.ReportId,
        CurrentDate: this.form.value.Date,
        DateFrom: this.form.value.DateFrom,
        DateTo: this.form.value.DateTo,
        OpenBalance: this.form.value.OpenBalance,
      };
      this.appService
        .postAuth(
          `finances/cashBankReports/${this.form.value.ReportId}`,
          object
        )
        .subscribe({
          next: async () => {
            await this.printAfterSave();
            this.isVisible = false;
            this.onRefresh.emit(true);
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
        });
    }

    if (this.mode == 'edit') {
      let object = {
        ReportId: this.form.value.ReportId,
        CurrentDate: this.form.value.Date,
        OpenBalance: this.form.value.OpenBalance,
        CloseBalance: this.form.value.CloseBalance,
        Number: this.form.value.Number,
      };
      this.appService
        .putAuth(`finances/cashBankReports/${this.form.value.ReportId}`, object)
        .subscribe({
          next: async () => {
            await this.printAfterSave();
            this.isVisible = false;
            this.onRefresh.emit(true);
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
        });
    }
  }

  refreshPrintSettings: boolean;
  printConfig: PrintConfig;
  getPrintConfig(e) {
    this.printConfig = e;
  }

  async printAfterSave() {
    const printConfig = this.print?.currentComponentConfig?.printConfig;
    let documentsToPrint: any = [];
    if (printConfig?.reportDocument) {
      documentsToPrint.push(this.ReportType);
      this.onPrintAfterSave.emit({
        document: this.form.value,
        documentsToPrint: documentsToPrint,
      });
    }
  }

  onReportsPopupClosed() {}
}

<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'form-commercial-operation.generateDocuments' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'choosed' + unicalGuid"
        *ngIf="!event.readOnly"
        [disabled]="event.readOnly"
        (onClick)="onSave()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          Esc {{ "buttons.cancel" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#choosed' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          F10 {{ "form-commercial-operation.generateDocuments" | translate }}
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <form [formGroup]="form">
        <div class="d-flex justify-content-center flex-wrap c-frame-row">
          <div class="form-group-inline-right" style="width: 9100%">
            <h5>{{ "articles.singleContractor" | translate }}</h5>

            <dx-radio-group
              [items]="radioForCustomer()"
              displayExpr="name"
              valueExpr="value"
              (onValueChanged)="onValueChanged()"
              formControlName="Mode"
            >
            </dx-radio-group>

            <br />

            <dx-text-box
              style="
                width: calc(100% - 40px);
                margin-right: 6px;
                max-width: 100%;
                display: inline-block;
              "
              [readOnly]="true"
              formControlName="Title"
            ></dx-text-box>
            <dx-button
              icon="icon absui-icon--filter-user"
              style="display: inline-block; height: 28px"
              (onClick)="isPopUpCustomerVisible.set(true)"
              [disabled]="form.value.Mode != 1 && form.value.Mode != 3"
              [id]="'filter' + unicalGuid"
            ></dx-button>

            <dx-tooltip
              [target]="'#filter' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                F7
                {{ "form-commercial-operation.listOfContractors" | translate }}
              </div>
            </dx-tooltip>
          </div>
        </div>

        <div class="form-column__item">
          <div class="form-control">
            <dx-button
              class="btn-dx-secondary"
              text="{{
                'form-commercial-operation.listOfContractors' | translate
              }}"
              style="flex: 0 1 fit-content"
              (onClick)="isShowTemplate.set(true)"
              [id]="'list' + unicalGuid"
            >
            </dx-button>

            <dx-tooltip
              [target]="'#list' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                Ctrl + L
                {{ "form-commercial-operation.listOfContractors" | translate }}
              </div>
            </dx-tooltip>
          </div>
        </div>

        <div class="d-flex justify-content-center flex-wrap c-frame-row">
          <div class="form-group-inline-right" style="width: 9100%">
            <h5>
              {{
                "form-commercial-operation.pricesAndDiscountsOnDocuments"
                  | translate
              }}
            </h5>

            <dx-radio-group
              [items]="radioPricesAndDiscount()"
              displayExpr="name"
              valueExpr="value"
              layout="horizontal"
              formControlName="PriceMode"
            >
            </dx-radio-group>
          </div>
        </div>

        <div class="form-columns">
          <div class="form-column form-column__full-width">
            <div class="form-column__item">
              <label style="min-width: 30px">{{
                "form-commercial-operation.dateOfIssue" | translate
              }}</label>
              <div class="form-control">
                <wapro-date-box
                  type="date"
                  width="276"
                  [displayFormat]="event.dateFormat"
                  formControlName="DateOfSell"
                >
                </wapro-date-box>
              </div>
            </div>
          </div>

          <div
            class="form-column form-column__full-width"
            style="padding-left: 10px"
          >
            <div class="form-column__item">
              <div class="form-control form-control__full-width">
                <dx-check-box
                  text="{{
                    'form-commercial-operation.generateFinancialDocumentsForPaymentMethods'
                      | translate
                  }}"
                  formControlName="IsGenerate"
                ></dx-check-box>
              </div>
            </div>
          </div>

          <div
            class="form-column form-column__full-width"
            style="padding-left: 10px"
          >
            <div class="form-column__item">
              <div class="form-control form-control__full-width">
                <dx-check-box
                  text="{{
                    'form-commercial-operation.skipGenerationIfThereIsAlreadyAdocumentFromTheTemplateWithTheSameIssueDate'
                      | translate
                  }}"
                  formControlName="IsSkip"
                ></dx-check-box>
              </div>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-customer-list-popup
  [isVisible]="isPopUpCustomerVisible()"
  (onChoosed)="onChoosedCustomerPopUp($event)"
  (onClosed)="onClosedCustomer()"
  [mode]="form.value.Mode === 1 ? 'single' : 'multiple'"
></app-customer-list-popup>

<app-confirm-dialog
  [confirmText]="confirmMsg"
  [isVisible]="isShowconfirm()"
  [kindDialog]="'information'"
  btnConfig="close"
  (onClosing)="isShowconfirm.set(false)"
>
</app-confirm-dialog>

<app-confirm-dialog
  confirmText="form-commercial-operation.documentGenerationHasFinished"
  [isVisible]="isShowSummary()"
  [kindDialog]="'information'"
  btnConfig="close"
  [paramsMsg]="paramsConfirm"
  (onClosing)="isShowSummary.set(false); closeWindow()"
>
</app-confirm-dialog>

<dx-popup
  width="400px"
  height="70px"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="false"
  [visible]="isProgrss()"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <dx-progress-bar
      #progressBar
      id="progress-bar-status"
      width="100%"
      [min]="0"
      [max]="100"
      [statusFormat]="format"
      [value]="progrsValue()"
    >
    </dx-progress-bar>
    {{ "loading" | translate }}: {{ progrsValue().toFixed(2) }}%
  </div>
</dx-popup>

<app-list-customer-template
  *ngIf="isShowTemplate()"
  [isVisible]="isShowTemplate()"
  (onClosing)="isShowTemplate.set(false)"
  [selectedTemplate]="selectedTemplateId"
></app-list-customer-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { environment } from '../../../../environments/environment';
import { FINANCE_TYPE_DOCUMENT } from 'src/app/consts';
import { map, of, tap } from 'rxjs';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DictionaryTitleComponent),
  multi: true,
};

@Component({
    selector: 'app-dictionary-title',
    templateUrl: './dictionary-title.component.html',
    styleUrls: ['./dictionary-title.component.scss'],
    inputs: [
        'dropDownBoxMode',
        'selectedId',
        'readOnly',
        'className',
        'type',
        'popUpMode',
        'isVisible',
        'title',
        'heightArea',
        'widthArea',
        'controllerType',
        'maxLength',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    standalone: false
})
export class DictionaryTitleComponent {
  @Output() onChoosed = new EventEmitter();
  @ViewChild('gridClass') gridClass;
  @Output() onClosed = new EventEmitter();
  @ViewChild('shortcutscccc') shortcutscccc;
  @ViewChild('descriptionTextBox') descriptionTextBox;

  heightArea;
  widthArea;
  controllerType: string = 'services';
  readOnly;
  type;
  popUpMode;
  isVisible;
  title;
  maxLength;
  selectedRow = [];
  selectedId;
  dropDownBoxMode;
  choosingDocument = [];
  documentListPom = [];
  className;
  isGridBoxOpened: boolean = false;
  documentList;

  dataSource;

  isAddRowVisible: boolean = false;
  editRow: boolean = false;
  isDeleteRow: boolean = false;

  shortcuts: ShortcutInput[] = [];
  heightGrid: number = 240;

  myEventEdit;
  myEventChoose;
  myEventEsc;
  myEventShow;
  myEventDelete;
  unicalGuid;
  contextMenu;
  widthCityColumn = '250';
  pomSelected = [];
  focusedRow = [];

  private _value: string;
  public get myValue(): string {
    return this._value;
  }
  public set myValue(v: string) {
    if (v !== this._value) {
      this._value = v;
      try {
        this.onChange(v);
      } catch {}
    }
  }

  @HostListener('document:click')
  clickout() {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (!this.isDeleteRow) this.isGridBoxOpened = false;
    }
  }

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    private eRef: ElementRef
  ) {
    this.event.closeDrobBox.pipe(takeUntilDestroyed()).subscribe(() => {
      this.closeDropBox();
    });
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (this.widthArea) {
      // if(typeof this.widthArea == 'number') this.widthArea -= 34;
      // if(typeof this.widthArea == 'string') this.widthArea = `calc(${this.widthArea} - 34px)`;
    }

    if (!this.readOnly) this.readOnly = this.event.readOnly;

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 170;
    }

    this.myEventEdit = this.event.edit.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.pomSelected.length > 0) this.editRow = true;
      }
    });

    this.myEventDelete = this.event.delete.subscribe(() => {
      this.isDelete();
    });

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        this.closeDropBox();
      }
    });

    this.myEventChoose = this.event.choose.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.focusedRow.length > 0) this.onChoosingRow();
      }
    });

    this.contextMenu = [
      { text: 'Dodaj', icon: 'icon absui-icon--add-circle' },
      { text: 'Edytuj', icon: 'icon absui-icon--mode-edit' },
      { text: 'Usuń', icon: 'icon absui-icon--highlight-off' },
    ];

    if (!this.dropDownBoxMode) {
      this.widthCityColumn = null;
    }

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
  }

  contextMenuClick = (e) => {
    switch (e.itemIndex) {
      case 0:
        if (!this.readOnly) this.isAddRowVisible = true;
        break;
      case 1:
        if (!this.readOnly) this.editRow = true;
        break;
      case 2:
        if (!this.readOnly) this.isDelete();
        break;
    }
  };

  onOpened() {
    this.getIdDocument();
  }

  TypeId;
  getIdDocument() {
    this.getFinanceType(this.type).subscribe((res) => {
      this.TypeId = res[0].TypeId;
      this.getDate();
      this.cd.detectChanges();
    });
  }

  getFinanceTypes() {
    const localCache = this.event.getSessionStorageData(FINANCE_TYPE_DOCUMENT);
    if (localCache) {
      return of({ data: localCache });
    }
    return this.appService.getAuth(`document/sales/types/finance`);
  }

  getFinanceType(signature: string) {
    return this.getFinanceTypes().pipe(
      tap((data) => {
        this.event.saveSessionStorageData(FINANCE_TYPE_DOCUMENT, data.data);
      }),
      map((data) => data?.data || []),
      map((data) => data.filter((field) => field.Signature == signature))
    );
  }

  ngOnChanges() {
    // if (this.selectedId && this.selectedId != '') {
    //   this.appService
    //     .getAuth(
    //       `${this.controllerType}/descriptionDictionary/typeCode/${this.type}?descriptionId=${this.selectedId}`
    //     )
    //     .subscribe((res) => {
    //       this.documentList = res.data;
    //       this.choosingDocument = [Number(this.selectedId)];
    //       this.cd.detectChanges();
    //     });
    // } else {
    //   this.documentList = [];
    //   this.choosingDocument = [];
    // }
  }

  getDate = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'FinanceDocumentDescriptionId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}finances/descriptionDictionary/${this.TypeId}`,
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}finances/descriptionDictionary`,
      }),
      reshapeOnPush: true,
    });
  };

  ngOnDestroy() {
    this.myEventEdit.unsubscribe();
    this.myEventDelete.unsubscribe();
    this.myEventEsc.unsubscribe();
    this.myEventChoose.unsubscribe();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.isAddRowVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeDropBox();
        },
        preventDefault: true,
      }
    );
  }

  addRow() {
    this.isAddRowVisible = true;
    this.cd.detectChanges();
  }

  onClosingForm = () => {
    this.isAddRowVisible = false;
    this.editRow = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 300);
    this.cd.detectChanges();
  };

  onInserted = (e) => {
    this.focusedRow = [e];
    this.dataSource.reload();
    this.onChoosingRow();
    this.cd.detectChanges();
  };

  onFocusedRowChanged = (e) => {
    this.focusedRow = [e.row.data];
    this.pomSelected = [e.row.data.FinanceDocumentDescriptionId];
  };

  onChoosingRow = () => {
    if (!this.readOnly) {
      if (this.isChangedMode) {
        this.popUpMode = false;
        this.dropDownBoxMode = true;
      }
      this.documentList = this.documentListPom;
      this.choosingDocument = this.selectedRow[0];
      let selectedPomRow = [];

      if (this.selectedRow.length > 0) {
        this.selectedRow.forEach((item) => {
          selectedPomRow.push(
            this.dataSource._items.find((field) => field.DescriptionId == item)
          );
        });
      } else {
        selectedPomRow.push(this.focusedRow[0]);
      }

      this.myValue = selectedPomRow[0].Description;

      this.onChoosed.emit(selectedPomRow);
      this.onClosingForm();
      this.closeDropBox();
    }
  };

  onOpenedChanged = (e) => {
    setTimeout(() => {
      this.event.disabletShortcuts.next(e);
    }, 0);

    if (e) {
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  onSelectionChanged = (e) => {
    this.documentListPom = [];
    this.documentListPom.push(e.selectedRowsData[0]);
  };

  isDelete = () => {
    if (this.pomSelected.length > 0 && !this.readOnly) {
      this.isDeleteRow = true;
      this.cd.detectChanges();
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) this.gridClass.instance.focus();
    }, 500);
  }

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode) this.editRow = true;
    else if (!this.dropdownTimeout) this.onChoosingRow();
  };

  delete = () => {
    this.appService
      .deleteAuth(`finances/descriptionDictionary/${this.pomSelected[0]}`)
      .subscribe(() => {
        this.dataSource.reload();
        this.isDeleteRow = false;
        if (this.choosingDocument == this.pomSelected[0])
          this.choosingDocument = [];

        setTimeout(() => {
          this.getDate();
          if (this.gridClass) {
            this.gridClass.focusedRowIndex = 0;
            this.gridClass.instance.focus();
          }
        }, 500);
      });
  };

  openedBoxDictionary = () => {
    this.isGridBoxOpened = !this.isGridBoxOpened;
    this.cd.detectChanges();
  };

  isChangedMode = false;
  changeModeWork = () => {
    //this.changeMode.emit(true)
    this.dropDownBoxMode = false;
    this.heightGrid = window.innerHeight - 250;
    this.popUpMode = true;
    this.isVisible = true;
    this.isChangedMode = true;
  };

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.isVisible = false;
    this.onChoosed.emit(null);
    this.cd.detectChanges;
  };

  clearValue = () => {
    this.myValue = '';
  };

  onClickArea = () => {
    this.isGridBoxOpened = false;
  };

  visibleChange = (e) => {
    if (!e) {
      if (this.isChangedMode) {
        setTimeout(() => {
          this.isVisible = false;
          this.popUpMode = false;
          this.dropDownBoxMode = true;
          this.isGridBoxOpened = false;
          this.cd.detectChanges();
        }, 0);
      } else {
        this.onClosed.emit(false);
      }
    }
  };

  onChange = (_) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.myValue = value;
    this.cd.detectChanges();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(_isDisabled: boolean): void {
    //throw new Error('Method not implemented.');
  }
  dropdownTimeout: boolean = false;

  onDropdownEditClick() {
    this.editRow = true;
    this.dropdownTimeout = true;
    setTimeout(() => {
      this.dropdownTimeout = false;
    }, 200);
  }
}

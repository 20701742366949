<dx-popup
  width="900"
  height="550"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-financial-document.sum' | translate"
  [visible]="true"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShowing)="event.onShownPopUp(unicalGuid)"
  (onHiding)="event.onHiddenPopUp(unicalGuid)"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        type="danger"
        text="{{ 'buttons.close' | translate }}"
        style="margin-right: 6px"
        (onClick)="onClosing.emit()"
        [id]="'cancel' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <dx-radio-group
        [items]="selectionDataSource"
        layout="horizontal"
        displayExpr="label"
        valueExpr="value"
        [readOnly]="readOnly"
        [(value)]="useForSelection"
        (onValueChanged)="onRadioValueChanged($event)"
        class="d-inline-block"
      >
      </dx-radio-group>
      <dx-check-box
        *ngIf="selectedTabIndex === 0"
        [text]="'form-financial-document.vatWithMpp' | translate"
        layout="horizontal"
        displayExpr="label"
        valueExpr="value"
        [readOnly]="readOnly"
        [(value)]="vatMpp"
        (onValueChanged)="onMPPValueChanged($event)"
      >
      </dx-check-box>

      <dx-tab-panel
        #tabPanel
        [items]="tabs"
        [(selectedIndex)]="selectedTabIndex"
        (onSelectionChanged)="onSelectionTabChanged()"
        [loop]="false"
        [animationEnabled]="false"
        [swipeEnabled]="false"
      >
        <div *dxTemplate="let name of 'title'; let i = index">
          <div>
            <span [id]="'tabToolTip' + i + unicalGuid">{{ name.title }}</span>
          </div>
          <dx-tooltip
            [target]="'#tabToolTip' + i + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="mouseleave"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data = data; of: 'content'">
              (Alt+{{ i + 1 }})
            </div>
          </dx-tooltip>
        </div>

        <div *dxTemplate="let name of 'item'">
          <ng-container [ngSwitch]="name.translation">
            <div *ngSwitchCase="'form-financial-document.sumDocs'">
              <dx-data-grid
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                shortcut
                [height]="350"
                [columnAutoWidth]="true"
                [allowColumnResizing]="true"
                (onOptionChanged)="event.onOptionChanged($event)"
              >
                <dxi-column
                  caption=""
                  dataField="Title"
                  alignment="left"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Wpływ"
                  dataField="Income"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Wypływ"
                  dataField="Outcome"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>
                <div *dxTemplate="let cell of 'valueTemplate'">
                  {{ cell.value | amountFormatter : cell : 2 }}
                </div>
              </dx-data-grid>
            </div>
            <div *ngSwitchCase="'form-financial-document.cashState'">
              <dx-data-grid
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                shortcut
                [height]="350"
                [columnAutoWidth]="true"
                [allowColumnResizing]="true"
                (onOptionChanged)="event.onOptionChanged($event)"
              >
                <dxi-column
                  caption="Kasa"
                  dataField="CashName"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Wal."
                  dataField="CurrencySymbol"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Data od"
                  dataField="DateFrom"
                  [allowSorting]="false"
                  cellTemplate="dateTemplate"
                >
                  <div *dxTemplate="let cell of 'dateTemplate'">
                    {{ cell.value | date : "yyyy-MM-dd" }}
                  </div>
                </dxi-column>
                <dxi-column
                  caption="Saldo otwarcia"
                  dataField="OpenBalance"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Wpływy"
                  dataField="Income"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Wypływy"
                  dataField="Outcome"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Saldo zamknięcia"
                  dataField="CloseBalance"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>
                <div *dxTemplate="let cell of 'valueTemplate'">
                  {{ cell.value | amountFormatter : cell : 2 }}
                </div>
              </dx-data-grid>
            </div>
            <div *ngSwitchCase="'form-financial-document.withMPP'">
              <dx-data-grid
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                shortcut
                [height]="350"
                [columnAutoWidth]="true"
                [allowColumnResizing]="true"
                (onOptionChanged)="event.onOptionChanged($event)"
              >
                <dxi-column
                  caption="Rachunek firmowy"
                  dataField="CashName"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="VAT wpływ"
                  dataField="Income"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="VAT wypływ"
                  dataField="Outcome"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Saldo"
                  dataField="OpenBalance"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                >
                </dxi-column>
                <div *dxTemplate="let cell of 'valueTemplate'">
                  {{ cell.value | amountFormatter : cell }}
                </div>
              </dx-data-grid>
            </div>
            <div *ngSwitchCase="'form-financial-document.sumCurrencies'">
              <dx-data-grid
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                shortcut
                [height]="350"
                [columnAutoWidth]="true"
                [allowColumnResizing]="true"
                (onOptionChanged)="event.onOptionChanged($event)"
              >
                <dxi-column
                  caption=""
                  dataField="ReportType"
                  alignment="left"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Wpływy walutowe"
                  dataField="Income"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>
                <dxi-column
                  caption="Wypływy walutowe"
                  dataField="Outcome"
                  cellTemplate="valueTemplate"
                  [allowSorting]="false"
                ></dxi-column>

                <div *dxTemplate="let cell of 'valueTemplate'">
                  {{ cell.value | amountFormatter : cell : 2 }}
                </div>
              </dx-data-grid>
            </div>
          </ng-container>
        </div>
      </dx-tab-panel>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts [shortcuts]="shortcuts" [unicalGuid]="unicalGuid">
</wapro-keyboard-shortcuts>

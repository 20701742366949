import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { ICustomSearchItem } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import { environment } from '../../../../../environments/environment';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-reckoning',
    templateUrl: './reckoning.component.html',
    styleUrls: ['./reckoning.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title'],
    standalone: false
})
export class ReckoningComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('itemGrid') gridBank: DxDataGridComponent;
  pageSize = 100;
  dropDownBoxMode = false;
  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;
  scrollPosition = 0;
  event = inject(EventService);
  widthWindow = this.event.setWidthPopUp();
  heightWindow = '70%';

  shortcuts: ShortcutInput[] = [];
  unicalGuid;

  focusedSelected: any = [];
  dataSource;

  filterCriteria: ICustomSearchItem[] = [
    { value: 'number', label: '' },
    { value: 'settlementDate', label: '', type: 'data' },
    { value: 'totalToPay', label: '', type: 'number' },
  ];
  userId: number = null;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.userId = this.event.returnUserId();
    this.translate
      .get('form-financial-document.number')
      .subscribe((text) => (this.filterCriteria[0].label = text));
    this.translate
      .get('form-financial-document.date')
      .subscribe((text) => (this.filterCriteria[1].label = text));
    this.translate
      .get('stockHistory.available')
      .subscribe((text) => (this.filterCriteria[2].label = text));
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'esc',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.onClosing.emit(false);
      },
      preventDefault: true,
    });
  }

  filterValue = '';
  valueCriteria = 'number';
  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.orderby = e.selectedItem.value;
      this.valueCriteria = e.selectedItem.value;
    }
    this.filterValue = e.filterValue;
    this.getSettlements();
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  focusedRowIndex;
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }
  }

  onKeyDown(e) {
    if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    }
  }

  onRowDblClick = () => {
    if (!this.readOnly) {
      this.onChoosedSettlements();
    }
  };

  getSettlements = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'SettlementId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}finances/settlements?SettlementStatus<>2&TotalToPay>0&parentObjectId=${this.selectedId[0].SettlementId}`,
        onAjaxError: this.event.onAjaxDataSourceError,
        loadParams: this.getLoadParams(),
      }),
      reshapeOnPush: true,
      paginate: true,
    });
  };

  orderby: string = 'number';
  order: string = 'ASC'; // asc/desc
  paramsObj;
  switchOrder() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
      return;
    }
    this.order = 'ASC';
  }

  getLoadParams() {
    let obj: any = {};

    if (this.selectedId[0].PaymentDirection == 1) {
      obj.PaymentDirection = -1;
      obj.settlementType = 'Z';
    } else {
      obj.PaymentDirection = 1;
      obj.settlementType = 'N';
    }

    obj.orderby = this.orderby;
    obj.order = this.order;
    obj.PayerId = this.selectedId[0].PayerId;
    obj.settlementStatus = 0;
    this.paramsObj = obj;
    return obj;
  }

  sendRequestTimer;
  useForSelection: boolean = false;

  onSelectionChanged = (e) => {
    this.toSelect.push(...e.currentSelectedRowKeys);
    this.toDeselect.push(...e.currentDeselectedRowKeys);

    this.toDeselect = this.event.removeDuplicates(this.toDeselect);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (
      this.selectedRows.length == this.dataSource?._items?.length &&
      this.selectedRows.length > 0
    ) {
      this.selectAll();
    } else this.isAllSelected = false;
    this.cd.detectChanges();

    if (this.selectedRows.length == 0) {
      this.toSelect = [];
      this.toDeselect = [];
      this.event.deselectAllRecords(`finances/selection/settlements`);
    }

    if (!this.isAllSelected && this.selectedRows.length > 0)
      this.setSelectionTimeout();
  };

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        this.toSelect = [];
        this.event.selectRecords(
          `finances/selection/settlements/select`,
          this.selectedRows
        );
      }
      if (this.toDeselect.length) {
        this.event.selectRecords(
          `finances/selection/settlements/unselect`,
          this.toDeselect
        );
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  isAllSelected: boolean = false;
  selectAll = () => {
    this.isAllSelected = true;
    this.cd.detectChanges();
    this.appService
      .postAuth(`finances/selection/settlements`, this.paramsObj)
      .subscribe(() => {});
  };

  async waitOperationJoin(item) {
    await this.join(item);
  }

  join(item) {
    return new Promise((resolve) => {
      this.appService
        .postAuth(
          `finances/settlements/${this.selectedId[0].SettlementId}/clearances/join`,
          {
            SettlementId: this.selectedId[0].SettlementId,
            DependentSettlementId: item,
          }
        )
        .subscribe(
          () => {
            resolve(true);
          },
          (error) => {
            resolve(true);
            this.event.httpErrorNotification(error);
          }
        );
    });
  }

  selectedRows: any = [];
  async onChoosedSettlements() {
    if (this.selectedRows.length > 0) {
      for (const index in this.selectedRows) {
        const item = this.selectedRows[index];
        await this.waitOperationJoin(item);
        if (Number(index) == this.selectedRows.length - 1) {
          this.isVisible = false;
          this.cd.detectChanges();
          this.onInserted.emit(true);
        }
      }
      return;
    }

    await this.join(this.focusedSelected[0].SettlementId);
    this.isVisible = false;
    this.cd.detectChanges();
    this.onInserted.emit(true);
  }
}

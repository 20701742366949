import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { RefreshCardResult } from 'src/app/event.model';
import { environment } from '../../../../environments/environment';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { OnInsertedData } from '../../new-comercial-operation/new-comercial-operation.component';

@Component({
    selector: 'app-advances',
    templateUrl: './advances.component.html',
    styleUrls: ['./advances.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'selected',
        'typeOfDocument',
        'componentNests',
        'selectedDocument',
        'componentNests',
    ],
    standalone: false
})
export class AdvancesComponent {
  @Output() onClosing = new EventEmitter();
  @ViewChild('gridBank') gridBank;
  @Output() onRefreshCard = new EventEmitter();
  @Output() onFocusedRow = new EventEmitter();
  @Output() onOpenCorection = new EventEmitter();
  @Output() onPrintAdvanceAfterSave = new EventEmitter();
  isVisible;
  selectedId;
  readOnly = false;
  title;
  selected;
  widthWindow: any = '75%';
  heightWindow: any = '620px';
  selectedDocument;
  typeOfDocument;
  isEditCustomer: boolean = false;
  headerDocument = {
    InvoiceDocumentNumber: '',
    DateOfInvoiceDocument: '',
    CustomerName: '',
  };
  CurrencyCode: string = '';

  sumPosition = {
    TotalGrossAmount: 0,
    TotalNetAmount: 0,
    TotalTaxAmount: 0,
    TotalGrossAmountAfterCorrection: 0,
    TotalNetAmountAfterCorrection: 0,
    TotalTaxAmountAfterCorrection: 0,
  };

  unicalGuid;

  dataSource;

  shortcuts: ShortcutInput[] = [];
  loadChildComponent: boolean = false;
  isSelectInvoiceAndCorrections: boolean = false;
  exportList = [
    { id: 0, text: 'Dodaj fakturę zaliczkową', disabled: false },
    { id: 1, text: 'Dodaj fakturę końcową', disabled: false },
  ];
  isDeleteRow: boolean = false;

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  heightGrid = 390;
  selectedRows: any = [];
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  toDeselect = [];
  toSelect = [];
  isQuickPress: boolean = true;
  focusedRowIndex = 0;
  advanceDocumentSummaryData: any = {
    TotalNetAmount: 0,
    TotalGrossAmount: 0,
    TotalTaxAmount: 0,
    FirstTotalNetAmount: 0,
    FirstTotalGrossAmount: 0,
    FirstTotalTaxAmount: 0,
    LeftTotalNetAmount: 0,
    LeftTotalGrossAmount: 0,
    LeftTotalTaxAmount: 0,
    CurrencyCode: '',
  };
  addRow: boolean = false;
  focusedSelectedRow: any = [];
  mode: string = 'add';

  event = inject(EventService);
  checkBoxListConfirmDelete = !this.event.footerInfo.VariantName.includes(
    'FAKTUROWANIE'
  )
    ? [
        {
          text: 'usuń powiązane dokumenty magazynowe',
          modelName: 'isDeleteOnlyInvoiceDocument',
          value: true,
          readOnly: false,
        },
      ]
    : null;

  isShowWarning: boolean = false;
  isShowWarehouseDocuments: boolean = false;
  isAllSettlement: boolean = false;
  showQuestionDisconect: boolean = false;
  IsFinalDocument: boolean = false;
  reportCode: string = 'Dokument_handlowy_zaliczka';
  menuName: string = null;
  type: string = 'FRA';
  refreshPrintSettings: boolean = false;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.translate
      .get('form-commercial-operation.addAnAdvanceInvoice')
      .subscribe((text) => (this.exportList[0].text = text));
    this.translate
      .get('form-commercial-operation.addAfinalInvoice')
      .subscribe((text) => (this.exportList[1].text = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.perABD = this.event.checkPermissionsBtn(
      'DDokHandl',
      'EDokHandl',
      'UDokHandl',
      'ODokHandl'
    );
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.addAdvanceInvoice(false);
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.addLastAdvance();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.editDocument();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isDelete();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + i',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.IsFinalDocument &&
            this.advanceDocumentSummaryData.LeftTotalGrossAmount == 0
          ) {
            this.connectTheWZToTheAdvanceInvoice();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + o',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.IsFinalDocument &&
            this.advanceDocumentSummaryData.LeftTotalGrossAmount == 0
          ) {
            this.isSelected = true;
            this.disconnectWarehouseDocument();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + p',
        preventDefault: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.IsFinalDocument &&
            this.advanceDocumentSummaryData.LeftTotalGrossAmount == 0
          ) {
            this.isSelected = true;
            this.selectGeneratedWarehouseDocument();
          }
        },
      }
    );
    setTimeout(() => {
      this.loadChildComponent = true;
    }, 500);
  }

  visibleChange = (e) => {
    if (!e) this.onClosing.emit(false);
    this.cd.detectChanges();
  };

  closeWindow() {
    this.onClosing.emit(false);
    this.isVisible = false;
    this.nextAdcance = null;
    this.cd.detectChanges();
  }

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }

  onKeyDown(e) {
    if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    }

    if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  onItemClickExport = (e) => {
    switch (e.itemData.id) {
      case 0:
        this.addAdvanceInvoice(false);
        break;
      case 1:
        if (!this.dataSource || this.dataSource._items.length == 0) {
          return;
        }

        this.addLastAdvance();

        break;
    }
  };

  isLastInvoice: boolean = false;
  addLastAdvance() {
    if (this.chechLeftTotalGrossAmount()) return;

    if (
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'
    ) {
      this.isLastInvoice = true;
      this.cd.detectChanges();
      return;
    }

    this.yesLastAdvance();
  }

  chechLeftTotalGrossAmount() {
    const DokHanKonDoFZal = this.event.getConfigurationParameter(
      'DokHanKonDoFZal',
      true
    );

    if (
      this.advanceDocumentSummaryData.LeftTotalGrossAmount == 0 &&
      this.dataSource &&
      DokHanKonDoFZal.Value == 'Nie'
    ) {
      this.isShowWarning = true;
      this.cd.detectChanges();
    }

    return this.advanceDocumentSummaryData.LeftTotalGrossAmount == 0 &&
      this.dataSource &&
      DokHanKonDoFZal.Value == 'Nie'
      ? true
      : false;
  }

  yesLastAdvance() {
    this.addAdvanceInvoice(true, true);
  }

  noLastAdvance() {
    this.addAdvanceInvoice(true);
  }

  nextAdcance;
  isLastAdvance: boolean = false;
  addAdvanceInvoice(isLastAdvance, isAddWarehouseDocument?) {
    this.isLastAdvance = false;
    if (this.chechLeftTotalGrossAmount()) return;
    if (!this.readOnly && this.perABD.addBtn) {
      if (!this.dataSource) {
        this.mode = 'add';
        this.addRow = true;
        this.cd.detectChanges();
      } else {
        this.appService
          .postAuth(
            `invoices/documents/advanceDocument/${this.dataSource._items[0].InvoiceDocumentId}/add`,
            {
              isLastAdvance: isLastAdvance,
              isAddWarehouseDocument: isAddWarehouseDocument
                ? isAddWarehouseDocument
                : false,
              InvoiceDocumentId: this.dataSource._items[0].InvoiceDocumentId,
            }
          )
          .subscribe(
            (res) => {
              this.isLastAdvance = isLastAdvance;
              this.mode = 'edit';
              this.nextAdcance = res;
              this.addRow = true;
              this.cd.detectChanges();
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  }

  showDocument() {
    this.nextAdcance = null;
    if (this.perABD.showBtn) {
      if (this.focusedSelectedRow.length > 0) {
        this.event.onShown();
        this.mode = 'show';
        this.addRow = true;
        this.cd.detectChanges();
      }
    }
  }

  abs(num) {
    return Math.abs(num);
  }

  editDocument() {
    this.nextAdcance = null;
    if (!this.readOnly && this.perABD.editBtn) {
      if (this.focusedSelectedRow.length > 0) {
        this.event.onShown();
        this.mode = 'edit';
        this.addRow = true;
        this.cd.detectChanges();
      }
    }
  }

  onFocusedRowChanged = (e) => {
    this.focusedSelectedRow = [e.row.data];
  };

  closeConfirm = () => {
    this.isDeleteRow = false;
    this.isCorrectionConfirm = false;
    this.cd.detectChanges();
  };

  onRowDblClick = () => {
    this.editDocument();
  };

  delete(e) {
    this.isDeleteRow = false;
    this.appService
      .deleteAuth(
        `invoices/documents/${
          this.dataSource._items[0].LastAdvanceInvoiceDocumentId
        }?isDeleteOnlyInvoiceDocument=${!e[0].value}&IsCancel=false`
      )
      .subscribe(
        () => {
          this.gridBank.focusedRowIndex = 0;
          this.gridBank.instance.focus();
          this.focusedSelectedRow = [];
          this.dataSource.reload().then(() => {
            if (!this.dataSource || this.dataSource._items.length == 0) {
              this.resetAdvaneDocumentSummary();
              this.dataSource = null;

              this.onFocusedRow.emit(0);
            } else {
              this.focusedRowIndex = 0;
              this.advanceDocumentSummary();
            }
          });
        },
        (error) => {
          this.event.loadingVisible = false;
          this.gridBank.instance.focus();
          this.event.httpErrorNotification(error);
        }
      );
  }

  isCorrectionConfirm: boolean = false;
  async isDelete() {
    if (
      !this.readOnly &&
      this.dataSource &&
      this.dataSource._items.length > 0 &&
      this.perABD.deleteBtn
    ) {
      if (
        this.dataSource._items[this.dataSource._items.length - 1]
          .IsCorrectionDocument
      ) {
        this.isCorrectionConfirm = true;
        this.cd.detectChanges();
      } else {
        this.isDeleteRow = true;
        this.cd.detectChanges();
      }
    }
  }

  yesOpenCorection() {
    this.isCorrectionConfirm = false;
    this.onOpenCorection.emit(true);
    this.cd.detectChanges();
  }

  firstLoad = true;
  indexToFocus: number;
  setFocusOnLoad = false;
  invoicesList;
  focusOnLastRow: RefreshCardResult = 'Nie';
  pageSize: number = 100;
  getDocument() {
    if (
      this.selected.length > 0 &&
      this.selected[0]?.InvoiceDocumentAdvancedOriginalId != 0
    ) {
      this.dataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'InvoiceDocumentId',
          onBeforeSend: this.event.onBeforeSendDataSource,
          loadUrl: `${environment.domain}invoices/documents`,
          loadParams: this.getLoadParams(),
          onAjaxError: this.event.onAjaxDataSourceError,
          onLoaded: (data) => {
            data.forEach((el) => {
              if (el.IsFinalDocument) this.IsFinalDocument = true;
            });

            if (data.length > 0) {
              this.CurrencyCode = data[0].CurrencyCode;
            }

            this.invoicesList = data;
            const selected: any[] = data
              .filter((el) => el.IsSelected)
              .map((el) => el.InvoiceDocumentId);
            if (selected.length > 0 && !this.isSelectInvoiceAndCorrections) {
              this.event.deselectAllRecords(`invoices/selection/documents`);
            }

            if (this.isSelectInvoiceAndCorrections)
              this.gridBank.instance.selectAll();

            this.cd.detectChanges();

            if (this.firstLoad) {
              this.firstLoad = false;
              setTimeout(() => {
                const filters: any = this.getLoadParams();
                const totalCount: number = this.dataSource.totalCount();
                if (!totalCount) return;

                if (
                  (filters?.order === 'ASC' && this.focusOnLastRow === 'Tak') ||
                  (filters?.order === 'DESC' && this.focusOnLastRow === 'Nie')
                ) {
                  this.indexToFocus = totalCount - 1;
                  if (this.indexToFocus < 0) {
                    this.indexToFocus = data.length - 1;
                  }
                  const page = Math.floor(totalCount / this.pageSize);
                  if (page > 0) {
                    this.setFocusOnLoad = true;
                    this.dataSource.pageIndex(page);
                    this.dataSource.load();
                  } else {
                    this.focusedRowIndex = this.indexToFocus;
                    this.onFocusedRowChanged({
                      row: { data: data[this.focusedRowIndex] },
                    });
                    this.cd.detectChanges();
                  }
                } else {
                  this.focusedRowIndex = 0;
                  this.onFocusedRowChanged({
                    row: { data: data[this.focusedRowIndex] },
                  });
                  this.cd.detectChanges();
                }
                this.focusedSelectedRow = [data[this.focusedRowIndex]];
                this.event.setFocus(this.gridBank);
              }, 200);
            }

            if (this.focusedSelectedRow.length > 0) {
              let index = data.findIndex(
                (field) =>
                  field.InvoiceDocumentId ==
                  this.focusedSelectedRow[0].InvoiceDocumentId
              );
              setTimeout(() => {
                this.focusedRowIndex = index;
              }, 0);
            }

            this.isSelectInvoiceAndCorrections = false;
          },
          deleteUrl: `${environment.domain}invoices/documents`,
        }),
        reshapeOnPush: true,
        requireTotalCount: true,
        paginate: true,
      });
    }
  }

  paramsObj;
  getLoadParams() {
    let obj: any = {};
    obj.InvoiceDocumentAdvancedOriginalId =
      this.selected[0]?.InvoiceDocumentAdvancedOriginalId;

    obj.OrderBy = 'InvoiceDocumentId';
    obj.Order = 'ASC';

    this.paramsObj = obj;
    return obj;
  }

  resetAdvaneDocumentSummary() {
    this.advanceDocumentSummaryData = {
      TotalNetAmount: 0,
      TotalGrossAmount: 0,
      TotalTaxAmount: 0,
      FirstTotalNetAmount: 0,
      FirstTotalGrossAmount: 0,
      FirstTotalTaxAmount: 0,
      LeftTotalNetAmount: 0,
      LeftTotalGrossAmount: 0,
      LeftTotalTaxAmount: 0,
      CurrencyCode: '',
    };
  }

  advanceDocumentSummary() {
    if (this.selected.length == 0 || this.selected == null) return;
    if (this.selected[0]?.InvoiceDocumentAdvancedOriginalId == 0) {
      this.resetAdvaneDocumentSummary();
      return;
    }
    this.appService
      .getAuth(
        `invoices/documents/${this.selected[0]?.InvoiceDocumentAdvancedOriginalId}/advanceDocumentSummary`
      )
      .subscribe(
        (res) => {
          this.advanceDocumentSummaryData = res;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  onInsertedDocument = (e: OnInsertedData) => {
    this.onRefreshCard.emit(e);
    this.appService
      .getAuth(
        `invoices/documents?InvoiceDocumentId=${e.document.InvoiceDocumentId}`
      )
      .subscribe((res) => {
        this.selected[0] = res.data[0];

        if (this.dataSource) {
          this.dataSource.reload().then((data) => {
            let index;
            index = data.findIndex(
              (field) =>
                field.InvoiceDocumentId === e.document.InvoiceDocumentId
            );

            if (index !== -1) {
              setTimeout(() => {
                this.focusedRowIndex = index;
                this.onFocusedRowChanged({
                  row: { data: data[this.focusedRowIndex] },
                });
                this.event.setFocus(this.gridBank);
                this.cd.detectChanges();
              }, 0);
            }
          });
        } else {
          this.getDocument();
        }

        this.advanceDocumentSummary();
        this.cd.detectChanges();
      });
  };

  onClosingWarehouse() {
    this.isSelected = false;
    this.isShowWarehouseDocuments = false;
    this.isDisconnectWarehouseDocument = false;
    this.cd.detectChanges();
  }

  connectTheWZToTheAdvanceInvoice() {
    if (!this.readOnly) {
      this.isSelected = false;
      this.isDisconnectWarehouseDocument = false;
      this.isShowWarehouseDocuments = true;
      this.cd.detectChanges();
    }
  }

  isGenerateWarehouseDocument() {
    if (!this.readOnly) {
      this.isAllSettlement = true;
      this.cd.detectChanges();
    }
  }

  showQuestionConnect: boolean = false;
  documentToConect: any;
  confirmText: string = '';
  isConfirmVisible: boolean = false;
  onChoosedDocument = (e) => {
    if (this.isDisconnectWarehouseDocument) {
      this.showQuestionDisconect = true;
      this.cd.detectChanges();
      return;
    }

    this.documentToConect = e;
    this.isShowWarehouseDocuments = false;
    this.showQuestionConnect = true;
    this.cd.detectChanges();
  };

  startConnectWarehouseDocument() {
    this.showQuestionConnect = false;
    this.appService
      .putAuth(
        `invoices/documents/advanceDocument/${this.selected[0].InvoiceDocumentId}/startConnectWarehouseDocument`,
        null
      )
      .subscribe(
        () => {},
        (error) => {
          if (JSON.parse(error)?.detail.indexOf('?') != -1) {
            this.confirmText = JSON.parse(error).detail.replace('|', '<br>');
            this.isConfirmVisible = true;
            this.cd.detectChanges();
          } else {
            this.event.httpErrorNotification(error);
          }
          this.cd.detectChanges();
        }
      );
    this.cd.detectChanges();
  }

  continueConnectWarehouseDocument() {
    this.appService
      .putAuth(
        `invoices/documents/advanceDocument/${this.selected[0].InvoiceDocumentId}/continueConnectWarehouseDocument`,
        null
      )
      .subscribe(
        () => {
          this.isConfirmVisible = false;
          this.appService
            .deleteAuth(`warehouseDocuments/selection/documents`)
            .subscribe(() => {});
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  isShowCreateDocument: boolean = false;
  generateWarehouseDocument() {
    this.isAllSettlement = false;
    this.cd.detectChanges();
    let object = {
      InvoiceDocumentId: this.selected[0].InvoiceDocumentId,
      CurrencyCode: this.selected[0].CurrencyCode,
      CurrencyFactor: this.selected[0].CurrencyFactor,
      CurrencyDate: this.selected[0].CurrencyDate,
    };

    this.appService
      .postAuth(
        `invoices/documents/advanceDocument/${this.selected[0].InvoiceDocumentId}/generateWarehouseDocument`,
        object
      )
      .subscribe(
        () => {
          this.isShowCreateDocument = true;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  isSelected: boolean = false;
  selectGeneratedWarehouseDocument() {
    this.event.onShown();
    this.appService
      .putAuth(
        `invoices/documents/advanceDocument/${this.selected[0].InvoiceDocumentId}/selectGeneratedWarehouseDocument`,
        null
      )
      .subscribe(
        () => {
          this.isShowWarehouseDocuments = true;
          this.event.onHidden();
          this.cd.detectChanges();
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  }

  isDisconnectWarehouseDocument: boolean = false;
  disconnectWarehouseDocument() {
    this.isDisconnectWarehouseDocument = true;
    this.selectGeneratedWarehouseDocument();
  }

  postDisconnectWarehouseDocument() {
    this.showQuestionDisconect = false;
    this.isSelected = false;
    this.cd.detectChanges();
    this.appService
      .putAuth(
        `invoices/documents/advanceDocument/${this.selected[0].InvoiceDocumentId}/disconnectWarehouseDocument`,
        null
      )
      .subscribe(
        () => {
          this.isDisconnectWarehouseDocument = false;
          this.isSelected = false;
          this.appService
            .deleteAuth(`warehouseDocuments/selection/documents`)
            .subscribe(() => {});
          this.isShowWarehouseDocuments = false;
          this.event.onHidden();
          this.cd.detectChanges();
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  }

  onForceRefresh() {
    this.dataSource.reload();
  }

  onClosingNewComercialOperation() {
    this.refreshPrintSettings = true;
    this.addRow = false;
    this.isLastInvoice = false;
    this.cd.detectChanges();
  }

  onInserted(e) {
    this.onPrintAdvanceAfterSave.emit(e);
    this.onInsertedDocument(e);
    this.isLastInvoice = false;
    this.refreshPrintSettings = true;
    this.addRow = false;
    this.cd.detectChanges();
  }
}

<div *ngIf="!dropDownBoxMode && !popUpMode">
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <div class="center-panel-scroll-verticall">
    <ng-container *ngTemplateOutlet="grid"></ng-container>
  </div>
</div>

<div *ngIf="popUpMode">
  <dx-popup
    width="1000"
    height="500"
    position="center"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="true"
    [resizeEnabled]="true"
    [title]="title"
    [(visible)]="isVisible"
    (visibleChange)="visibleChange($event)"
    (onShown)="event.onShownPopUp()"
    (onHidden)="event.onHiddenPopUp()"
    [wrapperAttr]="{ id: unicalGuid }"
  >
    <div *dxTemplate="let content of 'content'">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px"
          (onClick)="onChoosingRow()"
          [id]="'btn-choose' + unicalGuid"
          *ngIf="!readOnly"
          [disabled]="focusedRow.length == 0 || readOnly"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.close' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          [id]="'cancel' + unicalGuid"
        ></dx-button>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.close" | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>

      <dx-scroll-view width="100%" height="100%" direction="vertical">
        <div class="top-menu-popup">
          <ng-container *ngTemplateOutlet="topButtons"></ng-container>
        </div>
        <br />
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </dx-scroll-view>
    </div>
  </dx-popup>
</div>

<div
  style="display: inline-block; position: relative"
  *ngIf="dropDownBoxMode"
  class="box-dictionary"
>
  <dx-text-area
    class="dictionary"
    [minHeight]="heightArea ? heightArea : 58"
    [width]="widthArea ? widthArea : 276"
    [autoResizeEnabled]="true"
    style="max-width: none !important"
    [(value)]="myValue"
    (click)="onClickArea()"
    [readOnly]="readOnly"
    #descriptionTextBox
    [maxLength]="maxLength"
    [maxHeight]="heightArea ? heightArea : 58"
    valueChangeEvent="input"
    [ngClass]="{ 'input-required': className === true }"
  >
  </dx-text-area>
  <div
    class="button-content"
    style="
      left: 253px !important;
      position: relative;
      right: -6px;
      top: -63px;
      width: 20px;
    "
  >
    <i
      class="icon"
      [ngClass]="{
        'dx-icon-spinup': isGridBoxOpened,
        'dx-icon-spindown': !isGridBoxOpened
      }"
      style="
        margin-top: 4.5px;
        margin-left: 2.5px;
        position: relative;
        top: 0px !important;
        right: 2px !important;
        margin-right: 0px !important;
      "
      (click)="openedBoxDictionary()"
    ></i>
  </div>

  <dx-drop-down-box
    [deferRendering]="false"
    [openOnFieldClick]="false"
    [opened]="isGridBoxOpened"
    [dataSource]="[]"
    [showClearButton]="true"
    height="30"
    style="
      max-width: 450px;
      z-index: -1;
      position: absolute;
      top: 1px;
      left: 0;
      border-radius: 6px;
    "
    [readOnly]="readOnly"
    [width]="widthArea ? widthArea : 276"
    [ngStyle]="{ 'left.px': widthArea <= 450 ? 0 : widthArea - 460 }"
    (onOpened)="onOpened()"
    [ngClass]="{ 'input-required': className === true }"
  >
    <div
      *dxTemplate="let data of 'content'"
      style="height: 280px; min-height: 280px !important"
    >
      <div class="row">
        <div class="col-md-12" class="no-padding">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="258"
            [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            (onRowClick)="onRowDblClick()"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            (onSelectionChanged)="onSelectionChanged($event)"
            [focusedRowEnabled]="true"
            #gridClass
            [hoverStateEnabled]="true"
            [showColumnHeaders]="true"
            style="max-width: 450px"
            [columnAutoWidth]="true"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              [mode]="dropDownBoxMode ? 'single' : 'multiple'"
              showCheckBoxesMode="always"
            >
            </dxo-selection>

            <div *dxTemplate="let data of 'IsDefault'">
              <i
                class="icon absui-icon--status-ok-green"
                *ngIf="data.data.IsDefault"
              ></i>
            </div>
            <dxi-column
              [width]="event.deviceType != 'mobile' ? 45 : 10"
              cellTemplate="editTemplate"
              [allowSorting]="false"
              alignment="center"
            >
            </dxi-column>

            <dxi-column
              [caption]="'constractors.default' | translate"
              width="70"
              dataField="IsDefault"
              [allowSorting]="false"
              cellTemplate="IsDefault"
              renderAsync="true"
            >
            </dxi-column>

            <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
              <dx-button
                class="btn-dx-top-menu-folder-grid"
                icon="icon absui-icon--mode-edit hover-edit-icon"
                [disabled]="readOnly"
                (onClick)="onDropdownEditClick()"
              >
              </dx-button>
            </div>

            <dxi-column
              caption="{{ 'form-financial-document.description' | translate }}"
              cellTemplate="companyCell"
              dataField="Description"
              [allowSorting]="false"
            ></dxi-column>

            <div *dxTemplate="let data of 'companyCell'">
              {{ data.data.Description | slice : 0 : 50 }}..
            </div>
          </dx-data-grid>

          <dx-context-menu
            [dataSource]="contextMenu"
            [width]="200"
            [target]="'#grid' + unicalGuid"
            (onItemClick)="contextMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          >
          </dx-context-menu>
        </div>
      </div>

      <div class="bottom-drop-box pb-0 d-flex align-items-center">
        <dx-button
          [ngClass]="{
            'btn-dx-top-menu': !dropDownBoxMode,
            'btn-dx-top-menu-folder': dropDownBoxMode
          }"
          icon="icon absui-icon--add-circle"
          [disabled]="readOnly"
          [id]="'addDropDown' + unicalGuid"
          (onClick)="addRow()"
        >
        </dx-button>

        <dx-button
          [ngClass]="{
            'btn-dx-top-menu': !dropDownBoxMode,
            'btn-dx-top-menu-folder': dropDownBoxMode
          }"
          icon="icon absui-icon--import-contacts"
          [disabled]="readOnly"
          (onClick)="changeModeWork()"
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu-folder ml-auto"
          icon="icon absui-icon--clear-filter"
          (onClick)="clearValue()"
        >
        </dx-button>

        <dx-tooltip
          [target]="'#addDropDown' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.add" | translate }} (Insert)
          </div>
        </dx-tooltip>
      </div>
    </div>
  </dx-drop-down-box>
</div>

<ng-template #topButtons>
  <dx-scroll-view
    width="95%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
  >
    <dx-button
      [ngClass]="{
        'btn-dx-top-menu': !dropDownBoxMode,
        'btn-dx-top-menu-folder': dropDownBoxMode
      }"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      [text]="'buttons.add' | translate"
      [disabled]="readOnly"
      (onClick)="addRow()"
    >
    </dx-button>
    <dx-button
      [ngClass]="{
        'btn-dx-top-menu': !dropDownBoxMode,
        'btn-dx-top-menu-folder': dropDownBoxMode
      }"
      [id]="'btn-edit' + unicalGuid"
      icon="icon absui-icon--mode-edit"
      [text]="'buttons.edit' | translate"
      [disabled]="pomSelected.length == 0 || readOnly"
      (onClick)="editRow = true"
    >
    </dx-button>
    <dx-button
      [ngClass]="{
        'btn-dx-top-menu': !dropDownBoxMode,
        'btn-dx-top-menu-folder': dropDownBoxMode
      }"
      icon="icon absui-icon--highlight-off"
      [text]="'buttons.delete' | translate"
      [id]="'btn-delete' + unicalGuid"
      [disabled]="pomSelected.length == 0 || readOnly"
      (onClick)="isDelete()"
    >
    </dx-button>

    <dx-tooltip
      [target]="'#btn-add' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-choose' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.choose" | translate }} (Enter)
      </div>
    </dx-tooltip>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <dx-data-grid
        id="gridContainer"
        #gridClass
        [dataSource]="dataSource"
        [remoteOperations]="true"
        [wordWrapEnabled]="true"
        [showBorders]="true"
        shortcut
        [height]="heightGrid"
        [(selectedRowKeys)]="selectedRow"
        (onSelectionChanged)="onSelectionChanged($event)"
        [focusedRowEnabled]="true"
        (onRowDblClick)="onRowDblClick()"
        [id]="'grid' + unicalGuid"
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-paging [pageSize]="200"></dxo-paging>
        <dxo-selection
          mode="multiple"
          showCheckBoxesMode="always"
        ></dxo-selection>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
        <dxi-column
          [caption]="'constractors.default' | translate"
          width="70"
          dataField="IsDefault"
          [allowSorting]="false"
          cellTemplate="IsDefault"
          renderAsync="true"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'IsDefault'">
          <i
            class="icon absui-icon--status-ok-green"
            *ngIf="data.data.IsDefault"
          ></i>
        </div>
        <dxi-column
          caption="{{ 'form-financial-document.description' | translate }}"
          cellTemplate="companyCell"
          [width]="isChangedMode ? null : widthCityColumn"
          dataField="Description"
          [allowSorting]="false"
        ></dxi-column>

        <div *dxTemplate="let data of 'companyCell'">
          {{ data.data.Description | slice : 0 : 50 }}..
        </div>
      </dx-data-grid>
      <dx-context-menu
        [dataSource]="contextMenu"
        [width]="200"
        [target]="'#grid' + unicalGuid"
        (onItemClick)="contextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
      >
      </dx-context-menu>
    </div>
  </div>

  <wapro-keyboard-shortcuts
    [shortcuts]="shortcuts"
    [unicalGuid]="unicalGuid"
    [disabled]="
      (isGridBoxOpened && !dropDownBoxMode) ||
      isAddRowVisible ||
      editRow ||
      isDeleteRow ||
      !isVisible
    "
  >
  </wapro-keyboard-shortcuts>
</ng-template>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
>
</app-confirm-dialog>

<app-add-dictionary-title
  [isVisible]="isAddRowVisible"
  [controllerType]="controllerType"
  (onClosing)="onClosingForm()"
  (onInserted)="onInserted($event)"
  title="{{ 'servicesDevices.addingDescription' | translate }}"
  [type]="TypeId"
>
</app-add-dictionary-title>

<app-add-dictionary-title
  [isVisible]="editRow"
  [controllerType]="controllerType"
  [selectedId]="focusedRow"
  (onClosing)="onClosingForm()"
  (onInserted)="onInserted($event)"
  title="{{ 'servicesDevices.edditingDescription' | translate }}"
  [type]="TypeId"
>
</app-add-dictionary-title>

<ng-template #falseCheck>
  <i class="icon absui-icon--close close-grid"></i>
</ng-template>

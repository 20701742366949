<dx-popup
  width="900"
  [height]="event.deviceType != 'mobile' ? 440 : 600"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        type="danger"
        text="{{ 'buttons.close' | translate }}"
        style="margin-right: 6px"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <dx-data-grid
        [dataSource]="dataSourceSum"
        [wordWrapEnabled]="true"
        [showBorders]="true"
        [columnResizingMode]="'widget'"
        shortcut
        [height]="280"
        [columnAutoWidth]="true"
        [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
        (onOptionChanged)="event.onOptionChanged($event)"
      >
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxi-column
          caption=""
          dataField="name"
          alignment="left"
          [allowSorting]="false"
        ></dxi-column>

        <dxi-column
          caption="{{ 'sumCommercialOperations.outcome' | translate }}"
          alignment="center"
          [allowSorting]="false"
          [hidingPriority]="4"
        >
          <dxi-column
            alignment="right"
            caption="{{ 'form-commercial-operation.net' | translate }}"
            [width]="134"
            dataField="outcomeNet"
            [allowSorting]="false"
            cellTemplate="outcomeNetCell"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let cell of 'outcomeNetCell'">
            {{ cell.data.outcomeNet | amountFormatter : cell }}
          </div>

          <dxi-column
            alignment="right"
            caption="{{ 'form-commercial-operation.gross' | translate }}"
            [width]="134"
            [hidingPriority]="3"
            dataField="outcomeGross"
            [allowSorting]="false"
            cellTemplate="outcomeGrossCell"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let cell of 'outcomeGrossCell'">
            {{
              cell.data.outcomeGross === ""
                ? ""
                : (cell.data.outcomeGross | amountFormatter : cell)
            }}
          </div>
        </dxi-column>

        <dxi-column
          caption="{{ 'sumCommercialOperations.income' | translate }}"
          alignment="center"
          [allowSorting]="false"
          [hidingPriority]="0"
        >
          <dxi-column
            alignment="right"
            caption="{{ 'form-commercial-operation.net' | translate }}"
            [width]="134"
            dataField="incomeNet"
            [allowSorting]="false"
            cellTemplate="incomeNetCell"
            [hidingPriority]="1"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let cell of 'incomeNetCell'">
            {{
              cell.data.incomeNet === ""
                ? ""
                : (cell.data.incomeNet | amountFormatter : cell)
            }}
          </div>

          <dxi-column
            alignment="right"
            caption="{{ 'form-commercial-operation.gross' | translate }}"
            [width]="134"
            dataField="incomeGross"
            [allowSorting]="false"
            cellTemplate="incomeGrossCell"
            [hidingPriority]="0"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let cell of 'incomeGrossCell'">
            {{
              cell.data.incomeGross === ""
                ? ""
                : (cell.data.incomeGross | amountFormatter : cell)
            }}
          </div>
        </dxi-column>
      </dx-data-grid>

      <div class="form-group">
        <div class="form-item">
          <label>{{
            "sumCommercialOperations.summaryType.label" | translate
          }}</label>
          <dx-select-box
            [dataSource]="isOnlySellPriceButtons"
            displayExpr="label"
            valueExpr="value"
            (onValueChanged)="onSummaryTypeValueChanged()"
            [(value)]="isOnlySellPrice"
          >
          </dx-select-box>
        </div>
        <div class="form-item">
          <label>{{
            "sumCommercialOperations.displayedDocuments.label" | translate
          }}</label>
          <dx-select-box
            [dataSource]="isOnlyFromFiltringPriceButtons"
            displayExpr="label"
            valueExpr="value"
            (onValueChanged)="onDisplayedDocumentsValueChanged()"
            [(value)]="forFiltered"
          >
          </dx-select-box>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { ComercialDocumentsGuard, isCashId, isPermission, SettlementsGuard } from '../auth.guard';
import { NestDeactivateGuard } from '../can-deactive-guard';
import { NestResolverService } from '../nest-resolver.service';
import { ChangeCashBoxComponent } from './change-cash-box/change-cash-box.component';
import { ComercialOperationComponent } from './comercial-operation/comercial-operation.component';
import { FinancialOperationComponent } from './financial-operation/financial-operation.component';
import { FinancialRaportsComponent } from './financial-raports/financial-raports.component';
import { CanDeactivateGuard } from './retail-sales/canDeactiveGuard';
import { RetailSalesComponent } from './retail-sales/retail-sales.component';
import { SettlementsComponent } from './settlements/settlements.component';

const routes: Routes = [
  {
    path: '',
    component: FinancialOperationComponent,
  },
  {
    path: 'financial-raports',
    component: FinancialRaportsComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isPermission],
    data: { permissions: ['RKWBDokFin'] },
  },
  {
    path: 'sales',
    component: ComercialOperationComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['Sprzedaz'] },
  },
  {
    path: 'buy',
    component: ComercialOperationComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['Zakup'] },
  },
  {
    path: 'retail-sales',
    component: RetailSalesComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['Detal'] },
  },
  {
    path: 'finances-documents',
    component: FinancialOperationComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['DokFin'] },
  },
  {
    path: 'finances-documents/add',
    component: FinancialOperationComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['DokFin'] },
  },
  {
    path: 'finances-documents/:FinanceDocumentId/:mode',
    component: FinancialOperationComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['DokFin'] },
  },
  {
    path: 'trader-documents',
    component: ComercialOperationComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['DokHandl'] },
  },
  {
    path: 'trader-documents/add',
    component: ComercialOperationComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['DDokHandl'] },
  },
  {
    path: 'trader-documents/:id/:mode',
    component: ComercialOperationComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, ComercialDocumentsGuard, isPermission],
    data: { permissions: ['DokHandl'] },
  },
  {
    path: 'settlements',
    component: SettlementsComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['Rozr'] },
  },
  {
    path: 'settlements/add',
    component: SettlementsComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, isPermission],
    data: { permissions: ['DRozr'] },
  },
  {
    path: 'settlements/:SettlementId/:mode',
    component: SettlementsComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isCashId, SettlementsGuard, isPermission],
  },
  {
    path: 'change-cash-box',
    component: ChangeCashBoxComponent,
  },
  {
    path: 'change-cash-box/add',
    component: ChangeCashBoxComponent,
  },
  {
    path: 'change-cash-box/:CashId/:mode',
    component: ChangeCashBoxComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class routing {}

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [fullScreen]="fullScreen"
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{ boxShadow: scrollPosition > 0 }"
    >
      <dx-button
        type="default"
        stylingMode="contained"
        *ngIf="!fullScreen"
        (click)="fullScreen = !fullScreen"
        [id]="'fitToWidth' + unicalGuid"
        icon="icon absui-icon--fullscreen2"
      >
      </dx-button>
      <dx-button
        type="default"
        stylingMode="contained"
        *ngIf="fullScreen"
        (click)="fullScreen = !fullScreen"
        [id]="'fitToWidth' + unicalGuid"
        icon="icon absui-icon--fullscreen-off"
      >
      </dx-button>
      <dx-button
        type="danger"
        text="{{ 'buttons.close' | translate }}"
        style="margin-right: 6px"
        (onClick)="isVisible = false"
        [id]="'externalFields' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px">
        <div class="row">
          <div class="col-md-12">
            <p
              *ngIf="!isSelectedRows"
              class="p-header-info"
              style="line-height: 16px"
            >
              {{ "form-financial-document.settlement" | translate }}:
              <strong>{{ settlementData.settlementName }}</strong
              ><br />
              {{ "form-financial-document.payer" | translate }}:
              <strong>{{ settlementData.settlementPayerName }}</strong
              ><br />
              {{ "form-commercial-operation.dateOfPaymentt" | translate }}:
              <strong>{{
                settlementData.datePayment | date : "yyyy-MM-dd"
              }}</strong>
              <br />
              {{
                "form-commercial-operation.form.dateOfIssue" | translate
              }}:<strong>
                {{ settlementData.date | date : "yyyy-MM-dd" }}
              </strong>
            </p>
            <p
              *ngIf="isSelectedRows"
              class="p-header-info"
              style="line-height: 16px"
            >
              {{ "form-financial-document.settlement" | translate }}:
              <strong>{{ "checked" | translate }}</strong
              ><br />
              {{ "form-financial-document.payer" | translate }}:
              <strong>{{ settlementPayerNameSelectedMode | translate }}</strong
              ><br />
              {{ "form-commercial-operation.dateOfPaymentt" | translate }}:
              <strong>{{ "..." }}</strong>
              <br />
              {{
                "form-commercial-operation.form.dateOfIssue" | translate
              }}:<strong>
                {{ "..." }}
              </strong>
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <dx-button
            class="outline-secondary"
            stylingMode="outlined"
            style="margin-left: 6px"
            text="{{ 'form-financial-document.disconect' | translate }}"
            type="default"
            stylingMode="text"
            [id]="'btn-disconect' + unicalGuid"
            (onClick)="isOnSplit()"
            [disabled]="readOnly || focusedSelected.length == 0"
          >
          </dx-button>

          <dx-button
            class="outline-secondary"
            stylingMode="outlined"
            style="margin-left: 6px"
            text="{{ 'form-financial-document.disconectAll' | translate }}"
            type="default"
            stylingMode="text"
            [id]="'btn-disconect' + unicalGuid"
            (onClick)="isOnSplitAll()"
            [disabled]="readOnly"
          >
          </dx-button>

          <dx-button
            class="outline-secondary"
            stylingMode="outlined"
            style="margin-left: 6px"
            text="{{ 'form-financial-document.join' | translate }}"
            type="default"
            stylingMode="text"
            [id]="'btn-join' + unicalGuid"
            (onClick)="showConfirm()"
            [disabled]="readOnly || selectedId[0]?.SettlementStatus == 2"
          >
          </dx-button>

          <!--          *ngIf="oauthCallbackUrl === 'https://wphtmldev.abs.assecobs.pl' || oauthCallbackUrl === 'http://localhost:4200'"-->
          <dx-button
            class="outline-secondary"
            style="margin-left: 6px"
            text="{{ 'note' | translate }}"
            type="default"
            stylingMode="outlined"
            [id]="'btn-note' + unicalGuid"
            (onClick)="showNote()"
            [disabled]="
              readOnly ||
              selectedId[0]?.PaymentDirection != 1 ||
              selectedId[0]?.SettlementType != 'N' ||
              selectedId[0]?.DaysExpired === 0 ||
              focusedSelected.length == 0 ||
              !areAllPayersEqual
            "
          >
          </dx-button>
          <!--          selectedId[0]?.SettlementStatus != 2 ||-->

          <dx-tooltip
            [target]="'#btn-note' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "note" | translate }} (Shift+N)
            </div>
          </dx-tooltip>

          <dx-data-grid
            #gridBank
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="'calc(90vh - 300px)'"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [focusedRowEnabled]="true"
            [focusedRowIndex]="focusedRowIndex"
            style="margin-top: 10px"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxi-column
              alignment="right"
              [width]="100"
              [allowSorting]="false"
              dataType="date"
              dataField="ClearanceDate"
              caption="{{
                'form-commercial-operation.settlementDate' | translate
              }}"
              [format]="{ type: event.dateFormat }"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'workers.documentNumber' | translate }}"
              [allowSorting]="false"
              dataField="Number"
              [width]="180"
            >
              <!-- <dxo-format type="fixedPoint" [precision]="2">
                            </dxo-format> -->
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.documentDate' | translate
              }}"
              [width]="100"
              alignment="right"
              [allowSorting]="false"
              dataField="FinanceDocumentDate"
              [format]="{ type: event.dateFormat }"
              dataType="date"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'form-financial-document.grid.amount' | translate }}"
              [width]="100"
              [allowSorting]="false"
              dataField="Amount"
              cellTemplate="Amount"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'Amount'">
              {{ data.data.Amount | amountFormatter : data }}
            </div>

            <dxi-column
              caption="{{ 'bankAccounts.form.currency' | translate }}"
              [width]="100"
              [allowSorting]="false"
              dataField="CurrencyCode"
              alignment="right"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-financial-document.grid.currency-amount' | translate
              }}"
              [width]="100"
              [allowSorting]="false"
              dataField="CurrencyAmount"
              cellTemplate="CurrencyAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'CurrencyAmount'">
              {{ data.data.CurrencyAmount | amountFormatter : data : 4 }}
            </div>

            <dxi-column
              caption="{{ 'baseCurrencies.currencyValue' | translate }}"
              [width]="100"
              [allowSorting]="false"
              dataField="CurrencyFactor"
            >
              <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.dateOfPaymentt' | translate
              }}"
              [width]="120"
              [allowSorting]="false"
              dataField="PaymentDate"
              alignment="right"
              [format]="{ type: event.dateFormat }"
              dataType="date"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'form-payment.paymentMethod' | translate }}"
              [width]="150"
              [allowSorting]="false"
              dataField="PaymentFormName"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'form-financial-document.description' | translate }}"
              [width]="200"
              [allowSorting]="false"
              dataField="Description"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.financialDocumentOfThe' | translate
              }}"
              [width]="400"
              [allowSorting]="false"
            >
              <dxi-column
                caption="{{
                  'form-financial-document.grid.item-description' | translate
                }}"
                [width]="100"
                [allowSorting]="false"
                dataField="FinanceDocumentPositionDescription"
              >
              </dxi-column>

              <dxi-column
                caption="{{ 'form-financial-document.the-titel' | translate }}"
                [width]="200"
                [allowSorting]="false"
                dataField="Title"
              >
              </dxi-column>

              <dxi-column
                caption="{{
                  'form-financial-document.statement-number' | translate
                }}"
                [allowSorting]="false"
                dataField="InvoiceNumber"
              >
              </dxi-column>
            </dxi-column>
          </dx-data-grid>

          <div
            class="count-container"
            style="margin-top: 12px; height: auto; width: 99%"
          >
            <div class="row">
              <div class="col-md-2"></div>

              <div class="col-md-10 text-right">
                <div class="contener-pay" style="border-left: none">
                  <p class="correction-label">
                    {{
                      settlementData.ammount | currency : " " | pointReplacer
                    }}
                  </p>

                  <p class="correction-label">
                    {{ settlementData.payed | currency : " " | pointReplacer }}
                  </p>

                  <p class="summary-amount-correction">
                    <strong>{{
                      settlementData.toPay | currency : " " | pointReplacer
                    }}</strong>
                  </p>
                </div>

                <div class="contener-pay" style="border-left: none">
                  <p class="correction-label">
                    {{
                      "form-financial-document.sumOfSettlements" | translate
                    }}:
                  </p>
                  <p class="correction-label">
                    {{ "form-commercial-operation.payed" | translate }}:
                  </p>
                  <p class="correction-label">
                    <strong>{{ "left" | translate }}:</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

<app-confirm-dialog
  [isVisible]="isSplit"
  (onRemoving)="disconect()"
  (onClosing)="closeConfirm()"
  confirmText="form-financial-document.whetherToUnlinkThePaymentWithTheSettlement"
  [paramsMsg]="paramsConfirmDelete"
></app-confirm-dialog>

<app-confirm-dialog
  [isVisible]="isSettlement"
  (onRemoving)="yesSettlement()"
  (onClosing)="noSettlement()"
  confirmText="form-financial-document.settleAmutualSettlementWithAnotherSettlementWithoutGeneratingCompensation"
  [paramsMsg]="paramsNumber"
></app-confirm-dialog>

<app-confirm-dialog
  [isVisible]="isSplitAll"
  (onRemoving)="disconectAll()"
  (onClosing)="closeConfirm()"
  confirmText="form-financial-document.doYouWantSplitAllPaymentsWithTheSettlement"
  [paramsMsg]="paramsConfirmDelete"
></app-confirm-dialog>

<app-reckoning
  [isVisible]="isShowSettlementToChoise"
  [selectedId]="selectedId"
  [readOnly]="readOnly"
  (onInserted)="onInsertedSettlements()"
  (onClosing)="closeReckoning()"
></app-reckoning>

<app-interest-note
  *ngIf="interestNoteVisible"
  [settlementAmount]="settlementData?.ammount"
  [paymentDateSettlement]="paymentDateSettlement"
  [settlementName]="settlementData?.settlementName"
  [isSelectedRows]="isSelectedRows"
  [settlementId]="focusedSelected[0]?.SettlementId"
  [settlementType]="focusedSelected[0]?.SettlementType"
  [payerId]="focusedSelected[0]?.PayerId"
  [isVisible]="interestNoteVisible"
  [readOnly]="readOnly"
  (onClosing)="closeNote()"
>
</app-interest-note>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-financial-document.financialRaports' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="
    event.onShownPopUp(); createHeader(); event.setFocus(dateFormat.dateBox)
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
  [maxWidth]="maxWidth"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <app-print-settings
        (onComponentLoaded)="getPrintConfig($event)"
        [dropDownMode]="true"
        [isVisible]="mode === 'add' || mode === 'edit'"
        *ngIf="mode === 'add' || mode === 'edit'"
        [refreshPrintSettings]="refreshPrintSettings"
        [splitButton]="false"
        [isChild]="true"
        componentName="newFinancialRaport"
        style="margin-right: 6px"
      >
      </app-print-settings>
      <app-reports
        #reports
        [code]="'Raport_finansowy'"
        [currencyCode]="
          form.value.CurrencyCode ? form.value.CurrencyCode : null
        "
        [menuName]="selectedId[0]?.MenuName"
        [type]="selectedId[0]?.ReportType"
        [objectId]="selectedId[0]?.ReportTypeId"
        [isChild]="true"
        [readOnly]="mode !== 'show'"
        style="margin-right: 6px"
        (onReportsPopupClosed)="onReportsPopupClosed()"
        [isVisible]="mode === 'show'"
        *ngIf="isVisible"
      >
      </app-reports>
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        id="btn-save-comercial"
        *ngIf="!readOnly"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onCancel()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="102%"
      direction="vertical"
      showScrollbar="never"
      #scrollView
    >
      <div class="d-flex justify-content-center flex-wrap" [formGroup]="form">
        <div class="row">
          <div class="col-md-6 text-right">
            <div class="form-group-inline-right">
              <label style="width: auto">{{
                "form-financial-document.number" | translate
              }}</label>
              <dx-text-box
                [readOnly]="true"
                width="276"
                formControlName="Number"
              ></dx-text-box>
              <br />

              <label style="width: auto">{{
                "form-financial-document.date" | translate
              }}</label>
              <wapro-date-box
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                [readOnly]="readOnly || !editDatePermission"
                formControlName="Date"
                #dateFormat
              >
              </wapro-date-box>
              <br />

              <label style="width: auto">{{
                "document-type.dateFrom" | translate
              }}</label>
              <wapro-date-box
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                [readOnly]="readOnly"
                formControlName="DateFrom"
              >
              </wapro-date-box>
              <br />

              <label style="width: auto">{{
                "document-type.dateEnd" | translate
              }}</label>
              <wapro-date-box
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                [readOnly]="readOnly"
                formControlName="DateTo"
              >
              </wapro-date-box>
            </div>
          </div>

          <div class="col-md-6 text-right">
            <div class="form-group-inline-right">
              <label style="width: auto">{{
                "bankAccounts.form.currency" | translate
              }}</label>
              <dx-text-box
                [readOnly]="true"
                width="276"
                formControlName="CurrencySymbol"
              ></dx-text-box>
              <br />

              <label style="width: auto">{{
                "form-financial-document.openingBalance" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="true"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="OpenBalance"
              >
              </dx-number-box>
              <br />

              <label style="width: auto">{{
                "form-financial-document.Deposits" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="true"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="Income"
              >
              </dx-number-box>
              <br />

              <label style="width: auto">{{
                "form-financial-document.Withdrawals" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="true"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="Outflow"
              >
              </dx-number-box>
              <br />

              <label style="width: auto">{{
                "form-financial-document.closingBalance" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="true"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="CloseBalance"
              >
              </dx-number-box>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group-inline-right" style="width: 100% !important">
            <div class="c-frame-row">
              <h5 style="margin-top: 5px; text-align: left; width: 100%">
                {{ "form-financial-document.currencyBreakdown" | translate }}
              </h5>
            </div>

            <dx-button
              class="btn-dx-top-menu-folder"
              icon="icon absui-icon--add-circle"
              type="default"
              stylingMode="text"
              [id]="'btn-add' + unicalGuid"
              [disabled]="readOnly"
              (onClick)="onAddCurrencySum()"
            >
            </dx-button>
            <dx-button
              class="btn-dx-top-menu-folder"
              icon="icon absui-icon--mode-edit"
              type="default"
              stylingMode="text"
              [id]="'btn-edit' + unicalGuid"
              [disabled]="readOnly || focusedSelected.length == 0"
              (onClick)="onEditRow()"
            >
            </dx-button>

            <dx-button
              icon="icon absui-icon--highlight-off"
              [id]="'btn-delete' + unicalGuid"
              class="btn-dx-top-menu-folder"
              type="default"
              stylingMode="text"
              [disabled]="readOnly || focusedSelected.length == 0"
              (onClick)="onDelete()"
            >
            </dx-button>

            <br />

            <dx-data-grid
              [dataSource]="dataSource"
              [wordWrapEnabled]="false"
              [showBorders]="true"
              shortcut
              [height]="300"
              [allowColumnReordering]="true"
              [columnResizingMode]="'widget'"
              [remoteOperations]="true"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [id]="'gridRaport' + unicalGuid"
              (onFocusedRowChanged)="onFocusedRowChanged($event)"
              (onRowClick)="onRowClick($event)"
              [focusedRowEnabled]="true"
              [(focusedRowIndex)]="focusedRowIndex"
              (onOptionChanged)="event.onOptionChanged($event)"
            >
              <dxo-load-panel [enabled]="false"></dxo-load-panel>
              <dxo-scrolling
                mode="virtual"
                columnRenderingMode="virtual"
                preloadEnabled="true"
              ></dxo-scrolling>
              <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
              <dxo-selection
                mode="single"
                showCheckBoxesMode="always"
                selectAllMode="page"
              >
              </dxo-selection>
              <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

              <dxi-column
                caption="{{ 'bankAccounts.form.currency' | translate }}"
                [allowSorting]="false"
                dataField="CurrencySymbol"
                width="70"
              >
              </dxi-column>

              <dxi-column
                caption="{{
                  'form-financial-document.openingBalance' | translate
                }}"
                [allowSorting]="false"
                dataField="OpenBalance"
                width="150"
                cellTemplate="OpenBalance"
              >
              </dxi-column>
              <div
                *dxTemplate="let cell of 'OpenBalance'"
                style="padding-right: 10px"
              >
                {{ cell.data.OpenBalance | amountFormatter : cell }}
              </div>

              <dxi-column
                caption="{{
                  'form-financial-document.openingBalanceBaseCurrency'
                    | translate
                }}"
                [allowSorting]="false"
                dataField="OpenBalanceCurrency"
                width="200"
                cellTemplate="OpenBalanceCurrency"
              >
              </dxi-column>
              <div
                *dxTemplate="let cell of 'OpenBalanceCurrency'"
                style="padding-right: 10px"
              >
                {{ cell.data.OpenBalanceCurrency | amountFormatter : cell }}
              </div>

              <dxi-column
                caption="{{ 'form-financial-document.income' | translate }}"
                [allowSorting]="false"
                dataField="Income"
                width="150"
                cellTemplate="Income"
              >
              </dxi-column>
              <div
                *dxTemplate="let cell of 'Income'"
                style="padding-right: 10px"
              >
                {{ cell.data.Income | amountFormatter : cell }}
              </div>

              <dxi-column
                caption="{{ 'form-financial-document.outflows' | translate }}"
                [allowSorting]="false"
                dataField="Outflow"
                width="150"
                cellTemplate="Outflow"
              >
              </dxi-column>
              <div
                *dxTemplate="let cell of 'Outflow'"
                style="padding-right: 10px"
              >
                {{ cell.data.Outflow | amountFormatter : cell }}
              </div>
              <dxi-column
                caption="{{
                  'form-financial-document.closingBalance' | translate
                }}"
                [allowSorting]="false"
                dataField="CloseBalance"
                width="150"
                cellTemplate="CloseBalance"
              >
              </dxi-column>
              <div
                *dxTemplate="let cell of 'CloseBalance'"
                style="padding-right: 10px"
              >
                {{ cell.data.CloseBalance | amountFormatter : cell }}
              </div>
              <dxi-column
                caption="{{
                  'form-financial-document.closingBalanceBaseCurrency'
                    | translate
                }}"
                [allowSorting]="false"
                dataField="CloseBalanceCurrency"
                width="200"
                cellTemplate="CloseBalanceCurrency"
              >
              </dxi-column>
              <div
                *dxTemplate="let cell of 'CloseBalanceCurrency'"
                style="padding-right: 10px"
              >
                {{ cell.data.CloseBalanceCurrency | amountFormatter : cell }}
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-tooltip
  [target]="'#btn-add' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.add" | translate }} (Insert)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#btn-edit' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.edit" | translate }} (F2)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#btn-delete' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.delete" | translate }} (Del)
  </div>
</dx-tooltip>

<app-add-currency-sum
  [isVisible]="addCurrencySum"
  [mode]="modeCurrecny"
  [selectedId]="focusedSelected"
  [ReportId]="form.value.ReportId"
  (onClosing)="addCurrencySum = false; cd.detectChanges()"
  (onRefresh)="getSums(); addCurrencySum = false; cd.detectChanges()"
></app-add-currency-sum>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

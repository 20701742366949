import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { map } from 'rxjs';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';
import { BaseGridDataResponse } from 'src/app/event.model';
import { OrderStatus } from 'src/app/warehouse/documents-orders/new-documents-orders/order.model';

@Component({
    selector: 'app-invoices-status',
    templateUrl: './invoices-status.component.html',
    styleUrls: ['./invoices-status.component.scss'],
    inputs: ['isVisible', 'title', 'readOnly'],
    standalone: false
})
export class InvoicesStatusComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;

  isVisible;
  title;
  readOnly;
  unicalGuid;

  widthWindow = 700;
  heightWindow = 365;
  form;
  shortcuts: ShortcutInput[] = [];
  documentStatusList: OrderStatus[] = [];

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    this.createForm();
    this.getStatuses();
  }
  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'F10',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        if (!this.readOnly) {
          this.onSave();
        }
      },
      preventDefault: true,
    });
  }

  getStatuses = () => {
    if (localStorage.getItem('statusDocumentTypeH')) {
      let list = this.event.decryptString(
        localStorage.getItem('statusDocumentTypeH')
      );
      this.documentStatusList = list;
      for (let i = this.documentStatusList.length - 1; i >= 0; i--) {
        if (!this.documentStatusList[i].IsActive)
          this.documentStatusList.splice(i, 1);
      }
      this.cd.detectChanges();
      return;
    }

    this.appService
      .getAuth(`document/sales/status?documentType=H`)
      .pipe(
        map((res: BaseGridDataResponse<OrderStatus>) => res.data || []),
        map((data: OrderStatus[]) =>
          data
            .filter((s) => s.IsActive)
            .sort((a, b) => a.OrderNumber - b.OrderNumber)
        )
      )
      .subscribe((data) => {
        localStorage.setItem(
          'statusDocumentTypeH',
          this.event.encryptString(data)
        );
        this.documentStatusList = data;
        this.cd.detectChanges();
      });
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      InvoiceDocumentStatus: '',
      Comment: '',
    });
  };

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onChoosedComment(e) {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });

      this.form.controls.Comment.setValue(text);
    }
  }

  onSave = () => {
    if (!this.readOnly) {
      this.appService
        .putAuth(`invoices/additionalOperations/changesStatus`, this.form.value)
        .subscribe(
          () => {
            this.createForm();
            this.onInserted.emit(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
  };
}

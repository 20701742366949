<div *ngIf="!popUpMode">
  <ng-container>
    <div class="top-menu-panel">
      <div class="right-header-btn">
        <ng-container *ngIf="exportFakirService.isSelectMode()">
          <dx-button
            text="{{ 'buttons.choose' | translate }}"
            type="success"
            style="margin-right: 6px"
            (onClick)="selectChoosed()"
            [id]="'choose' + unicalGuid"
          ></dx-button>

          <dx-button
            text="{{ 'buttons.cancel' | translate }}"
            type="danger"
            (onClick)="cancelChoosed()"
            [id]="'cancel' + unicalGuid"
          ></dx-button
        ></ng-container>

        <dx-tooltip
          [target]="'#choose' + unicalGuid"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.choose" | translate }} (Enter)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.cancel" | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>
    </div>

    <div class="center-panel-scroll-verticall">
      <div>
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #topButtons>
  <dx-button
    type="default"
    stylingMode="contained"
    *ngIf="!fullScreen"
    (click)="fullScreen = !fullScreen"
    [id]="'fitToWidth' + unicalGuid"
    icon="icon absui-icon--fullscreen2"
  >
  </dx-button>
  <dx-button
    type="default"
    stylingMode="contained"
    *ngIf="fullScreen"
    (click)="fullScreen = !fullScreen"
    [id]="'fitToWidth' + unicalGuid"
    icon="icon absui-icon--fullscreen-off"
  >
  </dx-button>

  <dx-button
    text="{{ 'buttons.close' | translate }}"
    type="danger"
    (onClick)="onCancel()"
    [id]="'cancel' + unicalGuid"
  ></dx-button>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.close" | translate }} (Esc)
    </div>
  </dx-tooltip>
</ng-template>

<ng-template #grid>
  <div class="header-document" #headerDocument>
    <div class="justify-content-center-header">
      <div class="box-body">
        <div class="form-group-inline-right">
          <label style="width: auto">{{
            "form-financial-document.kindOfRaposrt" | translate
          }}</label>

          <dx-select-box
            width="276"
            [dataSource]="kindsOfRaportList"
            displayExpr="name"
            valueExpr="id"
            [(value)]="selectedKindOfRaport"
            (onValueChanged)="getCashBankRaports()"
            #kindOfRaportBox
          >
          </dx-select-box>
        </div>
      </div>

      <div class="box-body">
        <div
          class="form-group-inline-right"
          *ngIf="selectedKindOfRaport == 'k'"
        >
          <label style="width: auto">{{ "menu.cashBoxe" | translate }}</label>

          <app-cash-boxes
            [dropDownBoxMode]="true"
            [readOnly]="readOnly"
            [selectedId]="CashId"
            #cashBox
            (onChoosed)="onChoosedCashBox($event)"
          >
          </app-cash-boxes>
        </div>

        <div
          class="form-group-inline-right"
          *ngIf="selectedKindOfRaport == 'b'"
        >
          <label style="width: auto">{{
            "form-financial-document.bill" | translate
          }}</label>

          <app-bank-accounts
            [dropDownBoxMode]="true"
            style="display: inline-block; width: 276px"
            [readOnly]="readOnly"
            (onChoosed)="onChoosedCompanyBank($event)"
            [selectedId]="BankAccountId"
          >
          </app-bank-accounts>
        </div>
      </div>
      <div class="box-body" style="border-right: none">
        <div class="form-group-inline-right">
          <label style="width: auto">{{
            "bankAccounts.form.currency" | translate
          }}</label>

          <app-currency-box
            [dropDownBoxMode]="true"
            [readOnly]="readOnly"
            #currencyBox
            [selectedId]="CurrencySymbol"
            [width]="276"
            (onChoosed)="onChoosedCurrency($event)"
          >
          </app-currency-box>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7">
      <div #gridButtons>
        <div style="padding-bottom: 13px">
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--add-circle"
            type="default"
            stylingMode="text"
            [id]="'btn-add' + unicalGuid"
            [disabled]="readOnly"
            (onClick)="onAddNew()"
          >
          </dx-button>
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--mode-edit"
            type="default"
            stylingMode="text"
            [id]="'btn-edit' + unicalGuid"
            (onClick)="onEdit()"
            [disabled]="readOnly || focusedSelected.length != 1"
          >
          </dx-button>
          <dx-button
            icon="icon absui-icon--highlight-off"
            [id]="'btn-delete' + unicalGuid"
            class="btn-dx-top-menu-folder"
            type="default"
            stylingMode="text"
            [disabled]="readOnly || focusedSelected.length != 1"
            (onClick)="isDelete()"
          >
          </dx-button>
          <dx-button
            icon="icon absui-icon--money-coins"
            [id]="'btn-raport' + unicalGuid"
            text="{{
              'form-financial-document.generateRaportForAccountVATPP'
                | translate
            }}"
            class="btn-dx-top-menu"
            type="default"
            stylingMode="text"
          >
          </dx-button>
          <app-reports
            #reports
            [code]="'Raport_finansowy'"
            [menuName]="menuName"
            [currencyCode]="
              focusedSelected?.length
                ? focusedSelected[0]?.CurrencySymbol
                : null
            "
            [type]="
              focusedSelected?.length ? focusedSelected[0]?.ReportType : null
            "
            [objectId]="
              focusedSelected?.length ? focusedSelected[0]?.ReportId : null
            "
            [selectedDocumentsData]="selectedDocumentsData"
            [financialDocument]="
              focusedSelected?.length ? focusedSelected[0] : null
            "
            (onForceRefresh)="getCashBankRaports()"
            [params]="getLoadParams()"
          >
          </app-reports>
          <dx-tooltip
            [target]="'#btn-add' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.add" | translate }} (Insert)
            </div>
          </dx-tooltip>
          <dx-tooltip
            [target]="'#btn-edit' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.edit" | translate }} (F2)
            </div>
          </dx-tooltip>
          <dx-tooltip
            [target]="'#btn-delete' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.delete" | translate }} (Del)
            </div>
          </dx-tooltip>
        </div>
        <app-custom-dropdown-box
          [items]="filterCriteria"
          [selectedItem]="valueCriteria"
          [filterValue]="filterValue"
          (onValueChanged)="onFilterDataChanged($event)"
          style="margin-right: 5px"
        >
        </app-custom-dropdown-box>
        <app-date-range
          #dateRange
          [readOnly]="readOnly"
          [isPopupMode]="true"
          (onChoosed)="onDateRangeChoosed()"
          [chipsVisible]="true"
        >
        </app-date-range>
        <!-- [(value)]="statusValue" -->
        <app-custom-chips-button
          name="Status"
          [list]="statusList"
          (onValueChanged)="onValueChangedState($event)"
        >
        </app-custom-chips-button>
      </div>
      <div>
        <dx-data-grid
          [dataSource]="dataSource"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [height]="heightGrid"
          [(selectedRowKeys)]="selectedRows"
          [allowColumnReordering]="true"
          [columnResizingMode]="'widget'"
          (onInitialized)="onInitialized($event)"
          [remoteOperations]="true"
          (onRowDblClick)="onRowDblClick()"
          (onRowClick)="onRowClick($event)"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [id]="'grid' + unicalGuid"
          (onContentReady)="onGridContentReady($event)"
          (onKeyDown)="onKeyDown($event)"
          [(focusedRowIndex)]="focusedRowIndex"
          (onSelectionChanged)="onSelectionChanged($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          [focusedRowEnabled]="true"
          #gridBank
          (onOptionChanged)="event.onOptionChanged($event)"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling
            mode="virtual"
            columnRenderingMode="virtual"
            preloadEnabled="true"
          ></dxo-scrolling>
          <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
          <dxo-selection
            mode="multiple"
            showCheckBoxesMode="always"
            selectAllMode="page"
          >
          </dxo-selection>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

          <dxi-column
            caption="{{ 'form-financial-document.howMuchDoc' | translate }}"
            width="100"
            [allowSorting]="false"
            dataField="Amount"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'form-financial-document.number' | translate }}"
            width="100"
            [allowSorting]="false"
            dataField="Number"
            cellTemplate="mainColumn"
            headerCellTemplate="NumbertHeaderTemplate"
            [cssClass]="
              valueCriteria == 'number' ? 'sort-column-bg' : 'sort-column-muted'
            "
          >
          </dxi-column>
          <div *dxTemplate="let data of 'NumbertHeaderTemplate'">
            <div
              class="grid-header-wrapper"
              (click)="setSearchCriteria('number')"
            >
              {{ "form-financial-document.number" | translate }}
              <i
                class="header-sort-icon"
                [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
              ></i>
            </div>
          </div>
          <div *dxTemplate="let data of 'mainColumn'">
            <span class="hov-underline" (click)="editDocument()">{{
              data.data.Number
            }}</span>
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.date' | translate }}"
            [allowSorting]="false"
            dataField="Date"
            headerCellTemplate="WarehouseDateOfDocument"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
            [cssClass]="
              valueCriteria == 'date' ? 'sort-column-bg' : 'sort-column-muted'
            "
          >
          </dxi-column>

          <div *dxTemplate="let data of 'WarehouseDateOfDocument'">
            <div
              class="grid-header-wrapper"
              (click)="setSearchCriteria('date')"
            >
              {{ "form-financial-document.date" | translate }}
              <i
                class="header-sort-icon"
                [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
              ></i>
            </div>
          </div>

          <dxi-column
            caption="{{ 'document-type.dateFrom' | translate }}"
            [allowSorting]="false"
            dataField="DateFrom"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'document-type.dateEnd' | translate }}"
            [allowSorting]="false"
            dataField="DateTo"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'bankAccounts.form.currency' | translate }}"
            [allowSorting]="false"
            dataField="CurrencySymbol"
            width="110"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'form-financial-document.openingBalance' | translate }}"
            [allowSorting]="false"
            dataField="OpenBalance"
            width="150"
            cellTemplate="OpenBalance"
          >
          </dxi-column>
          <div
            *dxTemplate="let data of 'OpenBalance'"
            style="padding-right: 10px"
          >
            {{ data.data.OpenBalance | amountFormatter : data }}
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.income' | translate }}"
            [allowSorting]="false"
            dataField="Income"
            width="150"
            cellTemplate="Income"
          >
          </dxi-column>
          <div *dxTemplate="let data of 'Income'" style="padding-right: 10px">
            {{ data.data.Income | amountFormatter : data }}
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.outflows' | translate }}"
            [allowSorting]="false"
            dataField="Outflow"
            width="150"
            cellTemplate="Outflow"
          >
          </dxi-column>
          <div *dxTemplate="let data of 'Outflow'" style="padding-right: 10px">
            {{ data.data.Outflow | amountFormatter : data }}
          </div>

          <dxi-column
            caption="{{ 'form-financial-document.closingBalance' | translate }}"
            [allowSorting]="false"
            dataField="CloseBalance"
            width="150"
            cellTemplate="CloseBalance"
          >
          </dxi-column>
          <div
            *dxTemplate="let data of 'CloseBalance'"
            style="padding-right: 10px"
          >
            {{ data.data.CloseBalance | amountFormatter : data }}
          </div>
        </dx-data-grid>
      </div>
    </div>

    <div class="col-md-5 border-left">
      <dx-tab-panel
        #tabPanel
        width="100%"
        [items]="bottomPanelTabs"
        [(selectedIndex)]="selectedtabIndex"
        [animationEnabled]="false"
        [swipeEnabled]="false"
        class="bottomTabPanelMobile contractors-step-7"
        [scrollByContent]="true"
      >
        <div *dxTemplate="let name of 'item'">
          <ng-container [ngSwitch]="name">
            <div
              *ngSwitchCase="
                'form-financial-document.raportDocuments' | translate
              "
              style="padding-top: 2px"
            >
              <app-custom-chips-button
                name="{{ 'form-financial-document.type' | translate }}"
                [list]="kindCashRaportList"
                (onValueChanged)="onValueChangedType($event)"
              >
              </app-custom-chips-button>

              <dx-data-grid
                [dataSource]="dataSourceRaport"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                shortcut
                [height]="heightGrid"
                [allowColumnReordering]="true"
                [columnResizingMode]="'widget'"
                [remoteOperations]="true"
                [columnAutoWidth]="true"
                [allowColumnResizing]="true"
                [id]="'gridRaport' + unicalGuid"
                [focusedRowEnabled]="true"
                (onOptionChanged)="event.onOptionChanged($event)"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling
                  mode="virtual"
                  columnRenderingMode="virtual"
                  preloadEnabled="true"
                ></dxo-scrolling>
                <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
                <dxo-selection
                  mode="single"
                  showCheckBoxesMode="always"
                  selectAllMode="page"
                >
                </dxo-selection>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

                <dxi-column
                  caption="{{ 'document-type.signature' | translate }}"
                  [allowSorting]="false"
                  dataField="Signature"
                  width="80"
                >
                </dxi-column>

                <dxi-column
                  caption="{{ 'form-financial-document.number' | translate }}"
                  width="100"
                  [allowSorting]="false"
                  dataField="Number"
                >
                </dxi-column>

                <dxi-column
                  caption="{{ 'form-financial-document.date' | translate }}"
                  [allowSorting]="false"
                  dataField="FinanceDocumentDate"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="110"
                  alignment="left"
                >
                </dxi-column>

                <dxi-column
                  caption="{{
                    'form-financial-document.grid.amount' | translate
                  }}"
                  dataField="Amount"
                  [allowSorting]="false"
                  width="100"
                  cellTemplate="Amount"
                >
                  <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'Amount'">
                  {{ data.data.Amount | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="{{ 'bankAccounts.form.currency' | translate }}"
                  dataField="CurrencyCode"
                  [allowSorting]="false"
                  width="100"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-financial-document.grid.currency-amount' | translate
                  }}"
                  dataField="AmountCurrency"
                  [allowSorting]="false"
                  width="100"
                  cellTemplate="AmountCurrency"
                >
                  <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'AmountCurrency'">
                  {{ data.data.AmountCurrency | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="PP {{
                    'form-commercial-operation.grid.vatAmount' | translate
                  }}"
                  dataField="SplitPaymentVatAmountCash"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  cellTemplate="SplitPaymentVatAmountCash"
                >
                  <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'SplitPaymentVatAmountCash'">
                  {{
                    data.data.SplitPaymentVatAmountCash | amountFormatter : data
                  }}
                </div>

                <dxi-column
                  caption="PP {{ 'companies.grid.columns.vat' | translate }}"
                  dataField="SplitPaymentVatNumber"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  alignment="left"
                ></dxi-column>

                <dxi-column
                  caption="PP {{ 'workers.documentNumber' | translate }}"
                  dataField="SplitPaymentInvoiceNumber"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="150"
                  alignment="left"
                >
                </dxi-column>

                <dxi-column
                  caption="{{
                    'form-financial-document.statement-number' | translate
                  }}"
                  dataField="StatementNumber"
                  [allowSorting]="false"
                  width="200"
                ></dxi-column>
              </dx-data-grid>
            </div>

            <div
              *ngSwitchCase="
                'form-financial-document.currencyBreakdown' | translate
              "
              style="padding-top: 2px"
            >
              <dx-data-grid
                [dataSource]="dataSourceSum"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                shortcut
                [height]="heightGrid"
                [allowColumnReordering]="true"
                [columnResizingMode]="'widget'"
                [remoteOperations]="true"
                [columnAutoWidth]="true"
                [allowColumnResizing]="true"
                [id]="'gridRaportSum' + unicalGuid"
                [focusedRowEnabled]="true"
                (onOptionChanged)="event.onOptionChanged($event)"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling
                  mode="virtual"
                  columnRenderingMode="virtual"
                  preloadEnabled="true"
                ></dxo-scrolling>
                <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
                <dxo-selection
                  mode="single"
                  showCheckBoxesMode="always"
                  selectAllMode="page"
                >
                </dxo-selection>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

                <dxi-column
                  caption="{{ 'bankAccounts.form.currency' | translate }}"
                  dataField="CurrencySymbol"
                  [allowSorting]="false"
                  width="100"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-financial-document.openingBalance' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="OpenBalance"
                  width="150"
                  cellTemplate="OpenBalance"
                >
                </dxi-column>
                <div
                  *dxTemplate="let data of 'OpenBalance'"
                  style="padding-right: 10px"
                >
                  {{ data.data.OpenBalance | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="{{
                    'form-financial-document.openingBalanceBaseCurrency'
                      | translate
                  }}"
                  [allowSorting]="false"
                  dataField="OpenBalanceCurrency"
                  width="200"
                  cellTemplate="OpenBalanceCurrency"
                >
                </dxi-column>
                <div
                  *dxTemplate="let data of 'OpenBalanceCurrency'"
                  style="padding-right: 10px"
                >
                  {{ data.data.OpenBalanceCurrency | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.income' | translate }}"
                  [allowSorting]="false"
                  dataField="Income"
                  width="150"
                  cellTemplate="Income"
                >
                </dxi-column>
                <div
                  *dxTemplate="let data of 'Income'"
                  style="padding-right: 10px"
                >
                  {{ data.data.Income | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.outflows' | translate }}"
                  [allowSorting]="false"
                  dataField="Outflow"
                  width="150"
                  cellTemplate="Outflow"
                >
                </dxi-column>
                <div
                  *dxTemplate="let data of 'Outflow'"
                  style="padding-right: 10px"
                >
                  {{ data.data.Outflow | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="{{
                    'form-financial-document.closingBalance' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="CloseBalance"
                  width="150"
                  cellTemplate="CloseBalance"
                >
                </dxi-column>
                <div
                  *dxTemplate="let data of 'CloseBalance'"
                  style="padding-right: 10px"
                >
                  {{ data.data.CloseBalance | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="{{
                    'form-financial-document.closingBalanceBaseCurrency'
                      | translate
                  }}"
                  [allowSorting]="false"
                  dataField="CloseBalanceCurrency"
                  width="200"
                  cellTemplate="CloseBalanceCurrency"
                >
                </dxi-column>
                <div
                  *dxTemplate="let data of 'CloseBalanceCurrency'"
                  style="padding-right: 10px"
                >
                  {{ data.data.CloseBalanceCurrency | amountFormatter : data }}
                </div>
              </dx-data-grid>
            </div>
          </ng-container>
        </div>
      </dx-tab-panel>
    </div>
  </div>
</ng-template>

<dx-popup
  #popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-financial-document.financialRaports' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="onShown()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
  [maxWidth]="maxWidth"
  [(fullScreen)]="fullScreen"
  (fullScreenChange)="fullScreenChange($event)"
  [resizeEnabled]="true"
  (onResizeEnd)="onResizeEnd($event)"
  *ngIf="popUpMode"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <dx-scroll-view
      width="100%"
      height="100%"
      direction="vertical"
      showScrollbar="never"
      #scrollView
    >
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-new-financial-raport
  [isVisible]="isVisibleNewRaport"
  [mode]="mode"
  [CurrencySymbol]="CurrencySymbol"
  (onClosing)="onClosingNewRaport()"
  [SourceId]="selectedKindOfRaport == 'k' ? CashId : BankAccountId"
  [ReportType]="selectedKindOfRaport"
  (onRefresh)="onRefresh()"
  (onPrintAfterSave)="printAfterSave($event)"
  [selectedId]="focusedSelected"
></app-new-financial-raport>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<ng-container *ngIf="openNestFinished && !fullScreen; else fullScreenView">
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <div class="center-panel-scroll-verticall">
    <ng-container *ngTemplateOutlet="grid"></ng-container>
  </div>
</ng-container>

<ng-template #topButtons>
  <div class="row">
    <div class="col-md-6 col-lg-7">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        stylingMode="text"
        type="default"
        [id]="'btn-add' + unicalGuid"
        [disabled]="readOnly"
        (onClick)="beforeAddArticle()"
        data-cy="btn-add-article-retail-sales"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--mode-edit"
        stylingMode="text"
        type="default"
        [id]="'btn-edit' + unicalGuid"
        [disabled]="focusedSelected.length == 0 || readOnly"
        (onClick)="beforeEditPosition()"
        data-cy="btn-edit-retail-document"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--highlight-off"
        [id]="'btn-delete' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="focusedSelected.length == 0 || readOnly"
        stylingMode="text"
        type="default"
        (onClick)="isDeletePosition()"
        data-cy="btn-delete-retail-document"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--point-list"
        [id]="'btn-amount' + unicalGuid"
        class="btn-dx-top-menu"
        stylingMode="text"
        type="default"
        [disabled]="focusedSelected.length == 0 || readOnly"
        [text]="'form-commercial-operation.grid.count' | translate"
        (onClick)="isPrice = false; isAmount = true"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--wallet"
        [id]="'btn-price' + unicalGuid"
        class="btn-dx-top-menu"
        stylingMode="text"
        type="default"
        [disabled]="focusedSelected.length == 0 || readOnly"
        [text]="'customerGroups.price' | translate"
        (onClick)="isPrice = true; isAmount = false"
      ></dx-button>

      <!-- <dx-button icon="icon absui-icon--package-front" [id]="'btn-box'+unicalGuid" class="btn-dx-top-menu"
    [disabled]="readOnly" [text]="'articles.package' | translate">
  </dx-button> -->

      <dx-button
        icon="icon absui-icon--renumber"
        [id]="'btn-order' + unicalGuid"
        (onClick)="beforeOrderRealisation()"
        stylingMode="text"
        type="default"
        class="btn-dx-top-menu"
        [disabled]="readOnly"
        *ngIf="!fullScreen"
        [text]="'form-commercial-operation.orderFulfillment' | translate"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--docs-many"
        [id]="'btn-attachment' + unicalGuid"
        (onClick)="beforeWarehouseDocuments()"
        stylingMode="text"
        type="default"
        class="btn-dx-top-menu"
        [disabled]="readOnly"
        *ngIf="!fullScreen"
        [text]="'menu.warehouseDocuments' | translate"
      ></dx-button>

      <!-- <dx-check-box
        [(value)]="onlyWZ"
        text="Tylko WZ"
        style="display: inline-block"
      ></dx-check-box> -->
    </div>
    <div
      class="col-md-6 col-lg-5"
      style="
        display: flex;
        align-items: center;
        justify-content: end;
        flex-wrap: wrap;
      "
    >
      <div
        class="right-header-btn"
        style="position: relative; top: 0; margin-left: 4px; padding-left: 4px"
        [ngClass]="{ topMinus: fullScreen }"
      >
        <dx-button
          type="default"
          stylingMode="text"
          [id]="'showCalculator' + unicalGuid"
          (onClick)="isShowSidePanel = !isShowSidePanel; cd.detectChanges()"
          icon="icon absui-icon--hide-right-panel"
          class="btn-dx-top-menu-folder"
          *ngIf="fullScreen"
        ></dx-button>

        <dx-button
          type="default"
          stylingMode="text"
          (onClick)="getRequestFullScreen()"
          [id]="'fitToWidth' + unicalGuid"
          [icon]="icon"
          class="btn-dx-top-menu-folder"
        ></dx-button>
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon dx-icon-icon-refresh"
          stylingMode="text"
          type="default"
          (click)="getPosition(form.value.InvoiceDocumentId)"
          style="margin-right: 6px"
          [id]="'btn-refresh' + unicalGuid"
        ></dx-button>

        <div class="left-border-box">
          <app-print-settings
            componentName="retailSales"
            [dropDownMode]="true"
            [dropDownOptions]="dropDownOptions"
            (onButtonClick)="onParamsBtnClick($event)"
            [ngClass]="{ top8: fullScreen, onlyIcon: narrowScreen }"
            style="margin-right: 6px"
            [splitButton]="false"
            icon="absui-icon--printer-settings"
            (onPrintConfigChanged)="onPrintConfigChanged($event)"
          ></app-print-settings>

          <app-reports
            #reports
            [code]="'Dokument_handlowy'"
            [currencyCode]="
              form.value.CurrencyCode ? form.value.CurrencyCode : null
            "
            [menuName]="null"
            type="FRA"
            [objectId]="form.value.InvoiceDocumentId"
            [isChild]="false"
            [readOnly]="false"
            [isVisible]="false"
            [params]="getLoadParams()"
          ></app-reports>

          <dx-button
            class="outline-secondary"
            stylingMode="outlined"
            text="{{ 'menu.user-extensions' | translate }}"
            style="margin-right: 6px"
            (onClick)="visibleUserExtensions = true"
            [disabled]="readOnly"
            [id]="'btn-field' + unicalGuid"
          ></dx-button>

          <dx-button
            text="{{ 'form-financial-document.invoice' | translate }}"
            type="success"
            style="margin-right: 6px"
            (onClick)="addInvoices()"
            [disabled]="readOnly || onlyWZ"
            [id]="'btn-invoice' + unicalGuid"
            data-cy="btn-add-invoice-retail-sales"
          ></dx-button>

          <dx-button
            [text]="
              onlyWZ
                ? 'WZ'
                : ('offers.prefferedDocumentTypes.receipt' | translate)
            "
            type="success"
            style="margin-right: 6px"
            (onClick)="addReceipt()"
            [id]="'btn-receipt' + unicalGuid"
            data-cy="btn-add-receipt-retail-sales"
            *ngIf="!readOnly"
          ></dx-button>

          <dx-button
            text="{{ 'buttons.cancel' | translate }}"
            type="danger"
            style="margin-right: 6px"
            (onClick)="cancelChange()"
            [id]="'btn-cancel' + unicalGuid"
          ></dx-button>
        </div>

        <dx-tooltip
          [target]="'#btn-refresh' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "refresh" | translate }}
          </div>
        </dx-tooltip>
      </div>
    </div>
  </div>

  <dx-tooltip
    [target]="'#btn-add' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.add" | translate }} (Insert)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-edit' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.edit" | translate }} (F2)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-delete' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.delete" | translate }} (Del)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-amount' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "offers.changeTheQuantity" | translate }} (Num. *)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-price' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "offers.changePrice" | translate }} (Num. /)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-box' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "crmDeliveries.returnOfPackaging" | translate }} (Ctrl + O)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-default-payment' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "offers.setDefaultCustomerPayment" | translate }}
    </div>
  </dx-tooltip>
</ng-template>

<ng-template #grid>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-12">
        <div class="panels-flex-container">
          <div
            [ngClass]="{
              without_search_panel: dropDownBoxMode,
              with_search_panel: !dropDownBoxMode
            }"
            #rightPanel
          >
            <div class="row">
              <div class="col-md-12">
                <app-custom-dropdown-box
                  [openDropDown]="openCustomDropDown"
                  [items]="wgList"
                  [(selectedItem)]="valueCriteriaPosition"
                  [(filterValue)]="filterValuePosition"
                  (onValueChanged)="onFilterPosition($event)"
                  style="margin-right: 5px; margin-top: 5px"
                  class="warehouse-documents-search"
                ></app-custom-dropdown-box>

                <!-- [fullScreen]="fullScreen" -->
                <app-product-scaner
                  [readOnly]="readOnly"
                  style="position: relative; float: right; margin-top: 5px"
                  (onInputChanged)="onProductScanerInputChanged($event)"
                  (onCollectorOpened)="onCollectorOpened($event)"
                  [readOnly]="
                    !form.value.CustomerId || !form.value.InvoiceDocumentId
                  "
                  [keyboardShortcutsDisabled]="
                    addRow ||
                    isAnswerEditPosition ||
                    isForSimpleDetal ||
                    addRow ||
                    newPositionConf ||
                    isEditPosition ||
                    isWarehouseDocuments ||
                    isOrderRealization ||
                    articleAlreadyExistBlockVisible ||
                    articleAlreadyExistWarningVisible ||
                    visibleUserExtensions ||
                    isDeleteRow ||
                    isValidSum ||
                    isCancelRow ||
                    isClipboardVisible ||
                    isDetalFormaPlatKontr ||
                    isToPay ||
                    isContractorsVisible ||
                    isCollectorVisible
                  "
                  [parentId]="unicalGuid"
                ></app-product-scaner>

                <ng-container *ngTemplateOutlet="productGrid"></ng-container>

                <div
                  class="count-container"
                  style="position: fixed; bottom: 15px"
                  [ngStyle]="{ width: rightPanel.clientWidth + 'px' }"
                >
                  <ng-container *ngTemplateOutlet="sumContent"></ng-container>
                </div>
              </div>
            </div>
          </div>

          <div
            class="right-side-panel"
            mwlResizable
            *ngIf="!dropDownBoxMode"
            (resizeEnd)="resizeEnd()"
            (resizeStart)="onResizeStart()"
            (resizing)="resizing($event)"
            #leftPanel
          >
            <div class="right-panel-btn">
              <!-- <i class="icon text-icon"></i> -->
              <dx-button
                (mousedown)="mousedownSidePanel()"
                (mouseup)="mouseupSidePanel()"
                (touchend)="mouseupSidePanel()"
                (touchstart)="mousedownSidePanel()"
                [icon]="getResizeIcon()"
                class="btn-dx-right-filter"
                mwlResizeHandle
                [resizeEdges]="{ left: true }"
              ></dx-button>
            </div>
            <div
              class="resize-handle-left"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
              (mouseenter)="mouseEnter()"
              (mouseleave)="mouseLeave()"
            ></div>
            <div #leftPanelTab>
              <ng-container *ngTemplateOutlet="sidePanelContent"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<div *ngIf="loadChildComponent">
  <app-confirm-dialog
    kindDialog="question"
    confirmText="form-commercial-operation.onlyThePriceCanBeChangedInTheAttachedItemsOfTheWarehouseDocument"
    [isVisible]="isAnswerEditPosition"
    (onRemoving)="yesEditPosition()"
    (onClosing)="noEditPosition()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    kindDialog="question"
    confirmText="form-payment.theFormOfPaymentIsNotMarked"
    [isVisible]="isForSimpleDetal"
    [paramsMsg]="paramsMsg"
    (onRemoving)="yesChangeFormPayment()"
    (onClosing)="noChangeFormPayment()"
  ></app-confirm-dialog>

  <app-articles
    [isPopupMode]="true"
    [customerId]="form.get('CustomerId').value"
    [documentDirection]="selectedDocumentType.IsIncome ? 1 : 0"
    usageContext="document"
    [allowEnterAmount]="true"
    [chooseFromDelivery]="!selectedDocumentType.IsIncome"
    [isVisible]="addRow"
    [chooseFromDelivery]="true"
    [focusedProductId]="
      focusedSelected?.length ? focusedSelected[0]?.ProductId : null
    "
    (onChoosed)="onChoosedProduct($event)"
    (onClosing)="closeArticle()"
    #article
    [ProductContextFilter]="DocumentContext"
    [intuitiveFilter]="intuitiveFilter"
    [isDeliveryId]="form.value.type.trim() == 's' ? true : false"
    [DocumentMode]="4"
    [customerId]="form.value.CustomerId"
    (refreshArticleDocumentList)="refreshArticleDocumentList()"
    [warehouseDocumentId]="form.value.InvoiceDocumentId"
    *ngIf="form.valid && !readOnly"
  ></app-articles>

  <app-position-invoice-document
    [isVisible]="newPositionConf"
    (onClosing)="closeArticle()"
    [selected]="positionArticleToAdd"
    [customerId]="form.get('CustomerId').value"
    [documentId]="form.get('InvoiceDocumentId').value"
    [documentDate]="form.get('DateOfInvoiceDocument').value"
    [documentType]="selectedDocumentType"
    [isCorrect]="isCorrect"
    [IsCurrencyDocument]="form.value.IsCurrencyDocument"
    title="{{ 'servicesDevices.addingItems' | translate }}"
    [componentNests]="componentNests"
    [typeOfCrossBorderSale]="this.form.value.SumBy"
    (onInserted)="onInsertedPosition($event, 'add')"
    [Currency]="form.value.CurrencyCode"
    [parentObjectParameters]="nestObjectParameters"
    (nestParentUpdated)="onReceivedUpdateNestFromChildren($event)"
    [CurrencyFactor]="form.value.CurrencyFactor"
    [sumBy]="form.value.SumBy"
    [IsTripartiteEu]="form.value.IsTripartiteEu"
    [IsOpposite]="form.value.IsOpposite"
    [IsDocCurrencyDomestic]="form.value.IsDocCurrencyDomestic"
  ></app-position-invoice-document>

  <app-position-invoice-document
    [isVisible]="isEditPosition"
    (onClosing)="closeArticle()"
    [selected]="focusedSelected"
    [customerId]="form.get('CustomerId').value"
    [IsCurrencyDocument]="form.value.IsCurrencyDocument"
    [componentNests]="componentNests"
    [documentId]="form.get('InvoiceDocumentId').value"
    [documentDate]="form.get('DateOfInvoiceDocument').value"
    [documentType]="selectedDocumentType"
    [editMode]="true"
    [isCorrect]="isCorrect"
    [typeOfCrossBorderSale]="this.form.value.SumBy"
    (onInserted)="onInsertedPosition($event, 'edit')"
    [Signature]="form.value.DocumentTypeId"
    [WarehouseDocumentOriginalId]="
      selectedId && selectedId.length > 0
        ? selectedId[0]?.InvoiceDocumentOrginalId
        : null
    "
    [Currency]="form.value.CurrencyCode"
    [CurrencyFactor]="form.value.CurrencyFactor"
    [isOnlyPriceEdit]="isOnlyPriceEdit"
    [parentObjectParameters]="nestObjectParameters"
    (nestParentUpdated)="onReceivedUpdateNestFromChildren($event)"
    title="{{ 'servicesDevices.edditinItems' | translate }}"
    [sumBy]="form.value.SumBy"
    [IsTripartiteEu]="form.value.IsTripartiteEu"
    [IsOpposite]="form.value.IsOpposite"
    [IsDocCurrencyDomestic]="form.value.IsDocCurrencyDomestic"
  ></app-position-invoice-document>

  <app-connected-warehouse-document
    *ngIf="isWarehouseDocuments"
    [isVisible]="isWarehouseDocuments"
    (onClosing)="
      isWarehouseDocuments = false;
      getPosition(form.value.InvoiceDocumentId);
      countAmountDocument();
      findSocketAndRun('XGHSDWDM2')
    "
    [readOnly]="readOnly"
    title="{{
      'form-commercial-operation.commercialDocumentWarehouseDocuments'
        | translate
    }}"
    [invoiceDocumentId]="form.value.InvoiceDocumentId"
    [customerId]="form.value.CustomerId"
    [payerId]="form.value.PayerId"
  ></app-connected-warehouse-document>

  <!-- connect order to documents -->
  <app-order-realization
    *ngIf="isOrderRealization"
    [isVisible]="isOrderRealization"
    (onClosing)="isOrderRealization = false"
    [readOnly]="readOnly"
    title="{{ 'form-commercial-operation.orderFulfillment' | translate }}"
    [invoiceDocumentId]="form.value.InvoiceDocumentId"
    [customerId]="form.value.CustomerId"
    [DateOfInvoiceDocument]="form.value.DateOfInvoiceDocument"
    [SumBy]="form.value.SumBy"
    [customerName]="form.value.CustomerName"
    (onInserted)="onInsertedOrderRealization($event)"
  ></app-order-realization>

  <!-- blokada - dubel artykulu -->
  <app-confirm-dialog
    [title]="'offers.validationError' | translate"
    [confirmText]="'articles.articleAlreadyOnDocument'"
    [width]="450"
    [isVisible]="articleAlreadyExistBlockVisible"
    kindDialog="error"
    btnConfig="close"
    (onClosing)="articleAlreadyExistBlockVisible = false; cd.detectChanges()"
  ></app-confirm-dialog>

  <!-- ostrzezenie - dubel artykulu -->
  <app-confirm-dialog
    [title]="'articles.articleAlreadyExist' | translate"
    [showIcon]="false"
    [confirmText]="'articles.articleAlreadyExistContinue'"
    [btnConfig]="'saveCancel'"
    kindDialog="warning"
    [width]="450"
    [isVisible]="articleAlreadyExistWarningVisible"
    (onRemoving)="confirmAddArticle()"
    (onClosing)="articleAlreadyExistWarningVisible = false; cd.detectChanges()"
  ></app-confirm-dialog>

  <!-- pola dodatkowe -->
  <app-form-user-extensions
    [isVisible]="visibleUserExtensions"
    groupCode="Dokument_handlowy"
    [readOnly]="readOnly"
    title="{{ 'categories.externalFields' | translate }}"
    (onChoosed)="onInsertedUserExtensions($event)"
    (onClosing)="onClosingUserExtensions()"
    [userExtensionsValue]="form.value"
    #isValidUserExtensions
  ></app-form-user-extensions>

  <app-confirm-dialog
    [isVisible]="isDeleteRow"
    [confirmText]="
      selectedRow.length > 1 ? 'confirmDeleteMultiple' : 'confirmDelete'
    "
    (onRemoving)="deletePosition()"
    (onClosing)="isDeleteRow = false"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isValidSum"
    kindDialog="question"
    (onRemoving)="checkSumBy(); isValidSum = true"
    (onClosing)="isValidSum = false"
    [btnConfig]="btnConfig"
    [confirmText]="confirmText"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isCancelRow"
    (onRemoving)="checkSumBy()"
    kindDialog="question"
    btnConfig="yesnocancel"
    (onCanceling)="isCancelRow = false"
    confirmText="warehouseDocument.valueWarehouseDocumentHaveChanged"
    (onClosing)="deleteDocument(true)"
  ></app-confirm-dialog>

  <app-clipboard
    *ngIf="isClipboardVisible"
    [isPopupMode]="true"
    [documentId]="form.value.InvoiceDocumentId"
    documentType="DOCUMENT_INVOICE"
    [isVisible]="true"
    (onClosing)="onClosingClipboard()"
    (onChoosed)="onClipboardChoosed()"
  ></app-clipboard>

  <!-- question about change default form payment for customer -->
  <app-confirm-dialog
    [title]="'nests.types.question' | translate"
    [showIcon]="false"
    [confirmText]="
      'form-commercial-operation.doYouWantToChangeTheFormOfPaymentToTheDefaultForTheContractor'
    "
    kindDialog="question"
    [width]="450"
    [isVisible]="isDetalFormaPlatKontr"
    (onRemoving)="changeDefaultForm()"
    (onClosing)="closingDefaultForm()"
  ></app-confirm-dialog>

  <app-payment-invoice
    *ngIf="isToPay"
    [isVisible]="true"
    (onClosing)="isToPay = false"
    [TotalGrossAmount]="sumPosition.TotalGrossAmount"
    [InvoiceDocumentId]="form.value.InvoiceDocumentId"
    [CurrencyCode]="form.value.CurrencyCode"
    (onInserted)="setCurrentPayment($event); onSave()"
    [PaymentFormName]="form.value.PaymentFormName"
    (onPaymentFormNameChanged)="onPaymentFormNameChanged($event)"
  ></app-payment-invoice>
</div>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="
    addRow ||
    newPositionConf ||
    isWarehouseDocuments ||
    editRow ||
    isCancelRow ||
    isDeleteRow ||
    visibleUserExtensions ||
    articleAlreadyExistBlockVisible ||
    articleAlreadyExistWarningVisible ||
    isValidSum ||
    isEditPosition ||
    isExpansionListVisible
  "
></wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGHS'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<ng-template #fullScreenView>
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <div class="grid-content">
    <ng-container *ngTemplateOutlet="productGrid"></ng-container>

    <div class="count-container">
      <ng-container *ngTemplateOutlet="sumContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #sumContent>
  <div class="row">
    <div
      [ngClass]="{ 'col-5': narrowScreen, 'col-6': !narrowScreen }"
      style="padding-top: 6px"
    >
      <dx-tooltip
        [target]="'#btn-fiscal-print' + unicalGuid"
        hideEvent="dxhoverend"
        position="top"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "fiscalPrinter.printParams" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-order' + unicalGuid"
        hideEvent="dxhoverend"
        position="top"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "menu.orders" | translate }} (Ctrl + R)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-invoice' + unicalGuid"
        hideEvent="dxhoverend"
        position="top"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "form-financial-document.invoices" | translate }}
          (F9)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-receipt' + unicalGuid"
        hideEvent="dxhoverend"
        position="top"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "offers.prefferedDocumentTypes.receipt" | translate }}
          (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-attachment' + unicalGuid"
        hideEvent="dxhoverend"
        position="top"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "form-commercial-operation.AddDocuments" | translate }}
          (Ctrl + M)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-cancel' + unicalGuid"
        hideEvent="dxhoverend"
        position="top"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (ESC)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-field' + unicalGuid"
        hideEvent="dxhoverend"
        position="top"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "form-commercial-operation.additionalOperations" | translate }}
          (Ctrl + P)
        </div>
      </dx-tooltip>
    </div>
    <div
      class="text-right"
      [ngClass]="{ 'col-7': narrowScreen, 'col-6': !narrowScreen }"
    >
      <div class="contener-pay">
        <p class="p-toPay">
          {{
            sumPosition.TotalGrossAmount.toFixed(2)
              | currency : " "
              | pointReplacer
          }}
        </p>
        <p class="des-toPay">
          {{ "form-commercial-operation.toPay" | translate }}
        </p>
      </div>
      <!--
      <div class="contener-pay" style="padding-right: 15px;">
        <p class="p-toPay-net">
          {{sumPosition.TotalGrossAmountBeforeDiscount.toFixed(2)| currency:' ' | pointReplacer}}
        </p>
        <p class="des-toPay">
          {{'discountMarkupFactor.beforeDiscount' |translate}}
        </p>
      </div> -->

      <div class="contener-pay" style="padding-right: 15px">
        <p class="p-toPay-net">
          {{
            sumPosition.TotalTaxAmount.toFixed(2)
              | currency : " "
              | pointReplacer
          }}
        </p>
        <p class="des-toPay">VAT</p>
      </div>

      <div class="contener-pay" style="padding-right: 15px">
        <p class="p-toPay-net">
          {{
            sumPosition.TotalNetAmount.toFixed(2)
              | currency : " "
              | pointReplacer
          }}
        </p>
        <p class="des-toPay">
          {{ "form-commercial-operation.net" | translate }}
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sidePanelContent>
  <form [formGroup]="form">
    <dx-scroll-view
      width="100%"
      height="calc(100vh - 130px)"
      direction="vertical"
      style="margin-top: -10px"
    >
      <dx-tab-panel
        [items]="[1, 2, 3, 4, 5]"
        [(selectedIndex)]="selectedtab"
        style="width: 100%"
        class="no-padding-tab-panel right-panel-box"
        [animationEnabled]="false"
        [swipeEnabled]="false"
        [showNavButtons]="true"
        (onSelectionChanged)="onSelectionChangedTab($event)"
      >
        <div *dxTemplate="let item of 'title'">
          <div class="title-temp">
            <i
              class="icon absui-icon--mode-edit"
              [id]="'btn-dod-edit' + unicalGuid"
              *ngIf="item == 1"
            ></i>
            <i
              class="icon absui-icon--keyboard"
              [id]="'btn-dod-key' + unicalGuid"
              *ngIf="item == 2"
            ></i>
            <i
              class="icon absui-icon--select-none"
              [id]="'btn-dod-pic' + unicalGuid"
              *ngIf="item == 3"
            ></i>
            <i
              class="icon absui-icon--file-lock"
              [id]="'btn-dod-loc' + unicalGuid"
              *ngIf="item == 4"
            ></i>
            <i
              class="icon absui-icon--form-items"
              [id]="'btn-dod-com' + unicalGuid"
              *ngIf="item == 5"
            ></i>
          </div>

          <dx-tooltip
            [target]="'#btn-dod-edit' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.edit" | translate }}
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-dod-key' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "form-commercial-operation.touchKeyboard" | translate }}
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-dod-pic' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "articles.productPhoto" | translate }}
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-dod-loc' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "form-financial-document.suspendedReceipts" | translate }}
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-dod-com' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "comments" | translate }}
            </div>
          </dx-tooltip>
        </div>

        <div *dxTemplate="let name of 'item'">
          <ng-container [ngSwitch]="name">
            <div *ngSwitchCase="1">
              <label class="p-remarks">
                {{ "form-financial-document.number" | translate }}
                <strong>*</strong>
              </label>
              <dx-text-box
                [readOnly]="readOnly || isAutoNumeration"
                formControlName="InvoiceDocumentNumber"
                [ngClass]="{
                  'input-required':
                    !form.controls.InvoiceDocumentNumber.valid && submitted
                }"
              ></dx-text-box>

              <label class="p-remarks">
                {{ "form-financial-document.date" | translate }}
                <strong>*</strong>
              </label>
              <wapro-date-box
                [readOnly]="readOnly"
                type="date"
                [displayFormat]="event.dateFormat"
                formControlName="DateOfInvoiceDocument"
                (onValueChanged)="onDateOfInvoiceDocumentChanged()"
                [ngClass]="{
                  'input-required':
                    !form.controls.DateOfInvoiceDocument.valid && submitted
                }"
              ></wapro-date-box>

              <!-- <label class="p-remarks">{{'articles.typeOfArticleIdentification' | translate}}</label>
          <dx-select-box width="276" [dataSource]="typeOfArticleIdentification" displayExpr="label"
            valueExpr="value" [readOnly]="readOnly"></dx-select-box> -->

              <label class="p-remarks">
                {{ "form-financial-document.typeOfAmount" | translate }}
              </label>
              <dx-select-box
                [dataSource]="typeOfAmount"
                displayExpr="label"
                valueExpr="value"
                [readOnly]="readOnly"
                formControlName="SumBy"
                (onClosed)="recalculatePositions()"
              ></dx-select-box>

              <div class="seperatorRetail"></div>

              <label class="p-remarks">
                {{ "form-payment.paymentMethod" | translate }}
              </label>
              <app-forms-payment
                [dropDownBoxMode]="true"
                [onlyActive]="true"
                [readOnly]="readOnly"
                [selectedId]="form.value.PaymentFormName"
                (onChoosed)="onChoosedFormPeyment($event)"
                #paymentFormList
              ></app-forms-payment>

              <dx-button
                class="btn-dx-secondary"
                (onClick)="setDefaultPaymentForm()"
                text="{{
                  'form-financial-document.defaultPaymentValues' | translate
                }}"
                style="width: 100%; height: 27px; margin-top: 6px"
                [disabled]="readOnly"
              ></dx-button>

              <label class="p-remarks">
                {{ "form-commercial-operation.dateOfPaymentt" | translate }}
              </label>
              <dx-number-box
                [readOnly]="readOnly"
                valueChangeEvent="input"
                [showSpinButtons]="true"
                formControlName="DateOfPaymentDay"
                [useLargeSpinButtons]="true"
                style="display: block; max-width: 100%; width: 100%"
                (onValueChanged)="DateOfPaymentNumberChanged($event)"
              ></dx-number-box>

              <label class="p-remarks">
                {{ "form-commercial-operation.dateOfDaymentts" | translate }}
              </label>
              <wapro-date-box
                [readOnly]="readOnly"
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                formControlName="DateOfPayment"
                (onChoosed)="DateOfPaymentChanged($event)"
              ></wapro-date-box>

              <div class="seperatorRetail"></div>

              <label class="p-remarks">
                {{
                  "form-financial-document.payer-type.contractor" | translate
                }}
                <strong>*</strong>
              </label>
              <app-contractors
                [selectCustomerShortcut]="selectCustomerShortcut"
                (onSelectCustomerShortcut)="onSelectCustomerShortcut($event)"
                [selectedId]="form.value.CustomerId"
                [readOnly]="readOnly"
                [dropDownBoxMode]="true"
                (onDropdownOpened)="onCustomerOpened()"
                (onChoosed)="onChoosedCustomer($event)"
                [className]="
                  !form.controls.CustomerId.valid && submitted ? true : false
                "
              ></app-contractors>

              <label class="p-remarks">
                {{ "articles.overheadsAndMargins" | translate }}
              </label>
              <div class="d-flex">
                <dx-number-box
                  [readOnly]="readOnly"
                  valueChangeEvent="input"
                  format="#0.00%"
                  [showSpinButtons]="true"
                  [useLargeSpinButtons]="true"
                  [step]="0.01"
                  style="display: inline-block; flex: 1; max-width: 100%"
                  formControlName="DiscountMarkup"
                  (onEnterKey)="setDiscountForAllPosition()"
                >
                  <!-- [ngModelOptions]="{ standalone: true }" -->
                </dx-number-box>
                <dx-button
                  class="btn-dx-secondary"
                  (onClick)="setDiscountForAllPosition()"
                  text="{{
                    'form-commercial-operation.convertTheDiscount' | translate
                  }}"
                  style="width: 100px; margin-left: 6px; height: 27px"
                  [disabled]="readOnly"
                ></dx-button>
              </div>

              <label class="p-remarks">{{ "worker" | translate }}</label>
              <app-workers
                [readOnly]="readOnly"
                [dropDownBoxMode]="true"
                (onDropdownOpened)="onEmployeeOpened()"
                [selectedId]="form.get('EmployeeId').value"
                (onChoosed)="onChoosedEmployee($event)"
              ></app-workers>

              <div class="seperatorRetail"></div>

              <!-- <dx-button class="btn-dx-top-menu-folder" icon="icon absui-icon--clear-filter"
            [text]="'buttons.edit' | translate" [id]="'btn-default'+unicalGuid" [disabled]="readOnly">
          </dx-button> -->

              <!-- <dx-tooltip
                [target]="'#btn-default' + unicalGuid"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
                [showEvent]="event.tooltipShowEvent"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "offers.setDefaultCustomerPayment" | translate }}
                </div>
              </dx-tooltip> -->
            </div>
            <div *ngSwitchCase="2">
              <div>
                <input
                  type="number"
                  class="calculator-window"
                  [(ngModel)]="quantity"
                  [ngModelOptions]="{ standalone: true }"
                />
                <p class="quantity-label" *ngIf="isAmount">
                  {{ "form-commercial-operation.grid.count" | translate }}
                </p>
                <p class="quantity-label" *ngIf="isPrice">
                  {{ "stockHistory.price" | translate }}
                </p>
              </div>

              <div class="justify-content-center-calculator">
                <div
                  class="box-decrease-increase"
                  tabindex="0"
                  #reduce
                  (keydown.arrowdown)="setFocus(back)"
                  (keydown.arrowright)="setFocus(increase)"
                  (click)="reduceOne()"
                >
                  <p class="decrease-value">-1</p>
                  <label class="decrease-increase-label">
                    {{ "decrease" | translate }}
                  </label>
                </div>
                <div
                  class="box-decrease-increase"
                  tabindex="0"
                  #increase
                  (click)="increaseOne()"
                  (keydown.arrowdown)="setFocus(selectA)"
                  (keydown.arrowleft)="setFocus(reduce)"
                >
                  <p class="increase-value">+1</p>
                  <label class="decrease-increase-label">
                    {{ "increase" | translate }}
                  </label>
                </div>
              </div>

              <div class="justify-content-center-calculator">
                <div
                  class="function-calculator-btn function-calculator-btn-retail-sales"
                  (click)="backNumber()"
                  tabindex="0"
                  (keyup.enter)="backNumber()"
                  #back
                  (keydown.arrowdown)="setFocus(number7)"
                  (keydown.arrowright)="setFocus(delLast)"
                  (keydown.arrowup)="setFocus(reduce)"
                >
                  <i class="icon absui-icon--error-money-outline"></i>
                  <br />
                  <label class="decrease-increase-label">
                    {{ "undo" | translate }}
                  </label>
                </div>

                <div
                  class="function-calculator-btn function-calculator-btn-retail-sales"
                  tabindex="0"
                  #delLast
                  (click)="resetQuantity()"
                  (keydown.arrowdown)="setFocus(number8)"
                  (keydown.arrowright)="setFocus(selectA)"
                  (keydown.arrowleft)="setFocus(back)"
                  (keydown.arrowup)="setFocus(increase)"
                >
                  <i class="icon absui-icon--close"></i>
                  <br />
                  <label class="decrease-increase-label">
                    {{ "removeLastOne" | translate }}
                  </label>
                </div>

                <div
                  class="function-calculator-btn function-calculator-btn-retail-sales"
                  tabindex="0"
                  #selectA
                  (keydown.arrowdown)="setFocus(number9)"
                  (keydown.arrowleft)="setFocus(delLast)"
                  (keydown.arrowup)="setFocus(increase)"
                >
                  <i class="icon absui-icon--symbol-asterisk"></i>
                  <br />
                  <label class="decrease-increase-label">
                    {{ "mark" | translate }}
                  </label>
                </div>
              </div>

              <div class="justify-content-center-calculator">
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(7)"
                  tabindex="0"
                  #number7
                  (keyup.enter)="numberClick(7)"
                  (keydown.arrowdown)="setFocus(number4)"
                  (keydown.arrowright)="setFocus(number8)"
                  (keydown.arrowup)="setFocus(back)"
                >
                  <i class="icon absui-icon--number-7"></i>
                </div>
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(8)"
                  tabindex="0"
                  #number8
                  (keyup.enter)="numberClick(8)"
                  (keydown.arrowdown)="setFocus(number5)"
                  (keydown.arrowright)="setFocus(number9)"
                  (keydown.arrowleft)="setFocus(number7)"
                  (keydown.arrowup)="setFocus(delLast)"
                >
                  <i class="icon absui-icon--number-8"></i>
                </div>
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(9)"
                  tabindex="0"
                  #number9
                  (keyup.enter)="numberClick(9)"
                  (keydown.arrowdown)="setFocus(number6)"
                  (keydown.arrowleft)="setFocus(number8)"
                  (keydown.arrowup)="setFocus(selectA)"
                >
                  <i class="icon absui-icon--number-9"></i>
                </div>
              </div>

              <div class="justify-content-center-calculator">
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(4)"
                  tabindex="0"
                  (keyup.enter)="numberClick(4)"
                  (keydown.arrowdown)="setFocus(number1)"
                  #number4
                  (keydown.arrowright)="setFocus(number5)"
                  (keydown.arrowup)="setFocus(number7)"
                >
                  <i class="icon absui-icon--number-4"></i>
                </div>
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(5)"
                  tabindex="0"
                  (keyup.enter)="numberClick(5)"
                  (keydown.arrowdown)="setFocus(number2)"
                  #number5
                  (keydown.arrowright)="setFocus(number6)"
                  (keydown.arrowleft)="setFocus(number4)"
                  (keydown.arrowup)="setFocus(number8)"
                >
                  <i class="icon absui-icon--number-5"></i>
                </div>
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(6)"
                  tabindex="0"
                  (keyup.enter)="numberClick(6)"
                  (keydown.arrowdown)="setFocus(number3)"
                  #number6
                  (keydown.arrowleft)="setFocus(number5)"
                  (keydown.arrowup)="setFocus(number9)"
                >
                  <i class="icon absui-icon--number-6"></i>
                </div>
              </div>

              <div class="justify-content-center-calculator">
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(1)"
                  tabindex="0"
                  (keyup.enter)="numberClick(1)"
                  #number1
                  (keydown.arrowdown)="setFocus(number0)"
                  (keydown.arrowright)="setFocus(number2)"
                  (keydown.arrowup)="setFocus(number4)"
                >
                  <i class="icon absui-icon--number-1"></i>
                </div>
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(2)"
                  tabindex="0"
                  (keyup.enter)="numberClick(2)"
                  #number2
                  (keydown.arrowdown)="setFocus(dot)"
                  (keydown.arrowright)="setFocus(number3)"
                  (keydown.arrowleft)="setFocus(number1)"
                  (keydown.arrowup)="setFocus(number5)"
                >
                  <i class="icon absui-icon--number-2"></i>
                </div>
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(3)"
                  tabindex="0"
                  (keyup.enter)="numberClick(3)"
                  #number3
                  (keydown.arrowdown)="setFocus(enter)"
                  (keydown.arrowup)="setFocus(number6)"
                  (keydown.arrowleft)="setFocus(number2)"
                >
                  <i class="icon absui-icon--number-3"></i>
                </div>
              </div>

              <div class="justify-content-center-calculator">
                <div
                  class="calculator-btn calculator-btn-retail-sales"
                  (click)="numberClick(0)"
                  tabindex="0"
                  (keyup.enter)="numberClick(0)"
                  #number0
                  (keydown.arrowright)="setFocus(dot)"
                  (keydown.arrowup)="setFocus(number1)"
                >
                  <i class="icon absui-icon--number-0"></i>
                </div>

                <div
                  class="function-calculator-btn function-calculator-btn-retail-sales"
                  tabindex="0"
                  #dot
                  (keydown.arrowright)="setFocus(enter)"
                  (keydown.arrowleft)="setFocus(number0)"
                  (keydown.arrowup)="setFocus(number2)"
                  (click)="dotClick()"
                >
                  <i class="icon absui-icon--symbol-dot"></i>
                  <br />
                  <label class="decrease-increase-label">
                    {{ "dot" | translate }}
                  </label>
                </div>

                <div
                  class="enter-calculator-btn enter-calculator-btn-retail-sales"
                  tabindex="0"
                  #enter
                  (keydown.arrowleft)="setFocus(dot)"
                  (keydown.arrowup)="setFocus(number3)"
                  (click)="enterValue()"
                >
                  <i class="icon absui-icon--check"></i>
                  <br />
                  <label class="decrease-increase-label">Enter</label>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="3">
              <p class="p-remarks">
                {{ "articles.productPhoto" | translate }}
              </p>

              <div class="img-box" [ngClass]="{ 'no-height': prevPhoto != '' }">
                <img
                  [src]="
                    prevPhoto == ''
                      ? '../../../assets/img/Empty-state/Photo.svg'
                      : prevPhoto
                  "
                  alt="Asseco Business Solutions SA"
                  style="position: relative; top: calc(50% - 26px)"
                  [ngClass]="{ height247: prevPhoto != '' }"
                />
              </div>
            </div>

            <div *ngSwitchCase="4">
              <dx-button
                class="btn-dx-secondary"
                text="{{
                  'form-commercial-operation.suspendEdited' | translate
                }}"
                style="width: 135px; margin-right: 6px; display: inline-block"
                [disabled]="readOnly"
                [id]="'suspendEdited' + unicalGuid"
                (onClick)="suspend()"
                #btnSuspend
              ></dx-button>

              <dx-button
                class="btn-dx-secondary"
                text="{{
                  'form-commercial-operation.switchToHighlighted' | translate
                }}"
                style="width: auto; display: inline-block"
                [disabled]="readOnly || focusedSuspendDocument.length == 0"
                [id]="'switchToHighlighted' + unicalGuid"
                (onClick)="unLockSuponded()"
              ></dx-button>

              <br />

              <dx-data-grid
                [showBorders]="true"
                [remoteOperations]="true"
                [allowColumnReordering]="true"
                [dataSource]="dataSourceSuspend"
                style="
                  width: 100%;
                  max-height: 100%;
                  margin-bottom: 10px;
                  height: calc(100vh - 220px);
                  margin-top: 6px;
                "
                [focusedRowEnabled]="true"
                [wordWrapEnabled]="false"
                [allowColumnResizing]="true"
                [columnResizingMode]="'widget'"
                [hoverStateEnabled]="true"
                (onFocusedRowChanged)="onFocusedRowChangedSuspend($event)"
                (onOptionChanged)="event.onOptionChanged($event)"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxo-scrolling mode="infinite"></dxo-scrolling>
                <dxo-selection
                  mode="single"
                  showCheckBoxesMode="always"
                  selectAllMode="page"
                ></dxo-selection>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

                <dxi-column
                  [caption]="'form-financial-document.number' | translate"
                  [allowSorting]="false"
                  dataField="InvoiceDocumentNumber"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.grossValue' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="TotalGrossAmount"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
              </dx-data-grid>

              <dx-tooltip
                [target]="'#suspendEdited' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "form-commercial-operation.suspendEdited" | translate }}
                </div>
              </dx-tooltip>

              <dx-tooltip
                [target]="'#switchToHighlighted' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{
                    "form-commercial-operation.switchToHighlighted" | translate
                  }}
                </div>
              </dx-tooltip>
            </div>

            <div *ngSwitchCase="5">
              <p class="p-remarks">
                {{
                  "form-financial-document.addRemarksToTheDocument" | translate
                }}
              </p>

              <dx-text-area
                class="remarks-textarea"
                formControlName="Remarks"
                #textAreaRemarks
              ></dx-text-area>
            </div>
          </ng-container>
        </div>
      </dx-tab-panel>
    </dx-scroll-view>
  </form>
</ng-template>

<ng-template #productGrid>
  <div *ngIf="!isData">
    <ng-container *ngTemplateOutlet="emptyStateGird"></ng-container>
  </div>

  <dx-data-grid
    [showBorders]="true"
    [remoteOperations]="true"
    [allowColumnReordering]="true"
    [dataSource]="dataSource"
    #gridClass
    (onSelectionChanged)="onSelectionChanged($event)"
    shortcut
    (onInitialized)="onInitialized($event)"
    style="width: 100%; max-height: 100%; margin-bottom: 10px"
    [focusedRowEnabled]="true"
    [(selectedRowKeys)]="selectedRow"
    [height]="!fullScreen ? heightGrid : 'calc(100vh - 180px)'"
    [wordWrapEnabled]="false"
    [id]="'grid' + unicalGuid"
    (onKeyDown)="onKeyDown($event)"
    (onCellDblClick)="onRowDblClick()"
    (onOptionChanged)="onOptionChanged($event)"
    (onRowClick)="onRowClick($event)"
    [allowColumnResizing]="true"
    [columnResizingMode]="'widget'"
    [hoverStateEnabled]="true"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    (onContentReady)="onContentReady($event)"
    [(focusedRowIndex)]="focusedRowIndex"
    data-cy="retail-documents-grid"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-paging [pageSize]="500" [enabled]="true"></dxo-paging>

    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-selection
      [mode]="dropDownBoxMode ? 'single' : 'multiple'"
      showCheckBoxesMode="always"
      selectAllMode="page"
    ></dxo-selection>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

    <dxi-column
      [caption]="'form-commercial-operation.grid.Lp' | translate"
      width="32"
      [allowSorting]="false"
      cellTemplate="lpTemplate"
    ></dxi-column>
    <div *dxTemplate="let data of 'lpTemplate'; let index = i">
      {{ data.data.lp }}
    </div>

    <dxi-column
      [caption]="'form-commercial-operation.grid.articleName' | translate"
      width="220"
      [cssClass]="
        orderby == 'ProductFullName' ? 'sort-column-bg' : 'sort-column-muted'
      "
      [allowSorting]="false"
      cellTemplate="NameTemplate"
      headerCellTemplate="NumbertHeaderTemplate"
    ></dxi-column>
    <div *dxTemplate="let data of 'NumbertHeaderTemplate'">
      <div
        class="grid-header-wrapper"
        (click)="setSearchCriteria('ProductFullName')"
      >
        {{ "form-commercial-operation.grid.articleName" | translate }},
        {{ "document-type.type" | translate }}

        <i
          class="header-sort-icon"
          [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
        ></i>
      </div>
    </div>
    <div *dxTemplate="let data of 'NameTemplate'">
      <span class="hov-underline">{{ data.data.ProductFullName }}</span>
      <p class="second-row">{{ data.data.ProductType }}</p>
    </div>

    <dxi-column
      [caption]="'form-commercial-operation.grid.count' | translate"
      width="152"
      [allowSorting]="false"
      cellTemplate="Amount"
    ></dxi-column>
    <div *dxTemplate="let data of 'Amount'; let index = i">
      <div *ngIf="!fullScreen">
        <dx-number-box
          [(ngModel)]="data.data.AmountNew"
          [ngModelOptions]="{ standalone: true }"
          (onEnterKey)="changeAmountProduct(data.data)"
          [showSpinButtons]="true"
          width="132"
          format="#0.0000"
          [useLargeSpinButtons]="true"
          (onValueChanged)="
            isOpened = false; changeAmountProduct(data.data, null, $event)
          "
          style="display: inline-block"
          [disabled]="readOnly || isWhaitForResponse"
        ></dx-number-box>
      </div>
      <div *ngIf="fullScreen">
        <p class="amount-fullscreen">{{ data.data.Amount }}</p>

        <dx-button
          class="btn-fullscreen"
          icon="icon dx-numberbox-spin-up-icon"
          style="display: inline-block"
          [disabled]="readOnly || isWhaitForResponse"
          (onClick)="changeAmountProduct(data.data, 'up')"
        ></dx-button>

        <dx-button
          icon="icon numberbox-spin-down-icon"
          class="btn-fullscreen"
          style="display: inline-block"
          [disabled]="readOnly || isWhaitForResponse"
          (onClick)="changeAmountProduct(data.data, 'down')"
        ></dx-button>
      </div>
    </div>

    <dxi-column
      caption="{{ 'articles.unit' | translate }}"
      width="80"
      dataField="Unit"
      [allowSorting]="false"
    ></dxi-column>

    <dxi-column
      caption="{{ 'form-commercial-operation.grid.grossAmount' | translate }}"
      [width]="!fullScreen ? 152 : 200"
      [allowSorting]="false"
      cellTemplate="Price"
    ></dxi-column>
    <div *dxTemplate="let data of 'Price'; let index = i">
      <div *ngIf="!fullScreen">
        <dx-number-box
          [(ngModel)]="data.data.PriceGrossNew"
          [ngModelOptions]="{ standalone: true }"
          [showSpinButtons]="true"
          width="132"
          [format]="event.formatPLN"
          [useLargeSpinButtons]="true"
          (onEnterKey)="changePriceProduct(data.data)"
          (onValueChanged)="
            isOpened = false; changePriceProduct(data.data, null, $event)
          "
          [readOnly]="readOnly || isWhaitForResponse || !ArtykCenESDok"
        ></dx-number-box>
      </div>
      <div *ngIf="fullScreen">
        <p class="amount-fullscreen">{{ data.data.PriceGross }}</p>

        <dx-button
          class="btn-fullscreen"
          icon="icon dx-numberbox-spin-up-icon"
          style="display: inline-block"
          [disabled]="readOnly || isWhaitForResponse || !ArtykCenESDok"
          (onClick)="changePriceProduct(data.data, 'up')"
        ></dx-button>

        <dx-button
          icon="icon numberbox-spin-down-icon"
          class="btn-fullscreen"
          style="display: inline-block"
          [disabled]="readOnly || isWhaitForResponse || !ArtykCenESDok"
          (onClick)="changePriceProduct(data.data, 'down')"
        ></dx-button>
      </div>
    </div>

    <dxi-column
      caption="{{
        'form-commercial-operation.grid.grossAmountDiscount' | translate
      }}"
      width="130"
      [allowSorting]="false"
      cellTemplate="grossAmountDiscount"
      headerCellTemplate="grossAmountDiscountHeaderTemplate"
    ></dxi-column>

    <div *dxTemplate="let data of 'grossAmountDiscount'">
      <p class="p-amount text-right">
        {{ data.data.PriceGrossWithDiscount | amountFormatter : data }}
      </p>
      <p class="second-row text-right">{{ data.data.FoldableDiscount }}%</p>
    </div>

    <div *dxTemplate="let data of 'grossAmountDiscountHeaderTemplate'">
      <div class="grid-header-wrapper">
        {{ "offerPosition.grossAmountWithDiscount" | translate }}
        <br />
        {{ "form-payment.discountMarkUp" | translate }} [%]
      </div>
    </div>

    <dxi-column
      caption="{{ 'form-commercial-operation.grid.grossValue' | translate }}"
      [allowSorting]="false"
      width="100"
      dataField="TotalGrossAmount"
      cellTemplate="TotalGrossAmount"
      headerCellTemplate="grossValueDiscountHeaderTemplate"
    >
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <div *dxTemplate="let data of 'grossValueDiscountHeaderTemplate'">
      <div class="grid-header-wrapper text-left">
        {{ "expansionSlote.form.value" | translate }}
        <br />
        {{ "form-commercial-operation.gross" | translate }}
      </div>
    </div>
    <div *dxTemplate="let data of 'TotalGrossAmount'">
      <p class="p-amount">
        {{ data.data.TotalGrossAmount | amountFormatter : data }}
      </p>
    </div>

    <dxi-column
      caption="{{ 'form-commercial-operation.grid.vatAmount' | translate }}"
      [allowSorting]="false"
      width="100"
      dataField="TaxCode"
      cellTemplate="vatAmount"
      headerCellTemplate="vatAmountHeaderTemplate"
    >
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxi-column>
    <div *dxTemplate="let data of 'vatAmountHeaderTemplate'">
      <div class="grid-header-wrapper">
        {{ "form-commercial-operation.grid.vatAmount" | translate }}
        <br />
        VAT
      </div>
    </div>
    <div *dxTemplate="let data of 'vatAmount'">
      <p class="p-amount text-right">
        {{
          data.data.TotalGrossAmount - data.data.TotalNetAmount
            | amountFormatter : data
        }}
      </p>
      <p class="second-row text-right">{{ data.data.TaxCode }}</p>
    </div>

    <dxi-column
      caption="{{ 'offers.defaultBarcode' | translate }}"
      width="180"
      [allowSorting]="false"
      dataField="EAN"
      headerCellTemplate="EANHeaderTemplate"
      [cssClass]="orderby == 'EAN' ? 'sort-column-bg' : 'sort-column-muted'"
    ></dxi-column>
    <div *dxTemplate="let data of 'EANHeaderTemplate'">
      <div class="grid-header-wrapper" (click)="setSearchCriteria('EAN')">
        {{ "offers.defaultBarcode" | translate }}
        <i
          class="header-sort-icon"
          [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
        ></i>
      </div>
    </div>

    <dxi-column
      caption="{{ 'form-commercial-operation.grid.vatAmount' | translate }}"
      [allowSorting]="false"
      width="183"
      dataField="IndexCatalogue"
      cellTemplate="catalogIndex"
      style="border-top: none !important"
      headerCellTemplate="catalogIndexHeaderTemplate"
      [cssClass]="
        orderby == 'IndexCatalogue' ? 'sort-column-bg' : 'sort-column-muted'
      "
    ></dxi-column>
    <div *dxTemplate="let data of 'catalogIndexHeaderTemplate'">
      <div
        style="border-top: none !important"
        class="grid-header-wrapper"
        (click)="setSearchCriteria('IndexCatalogue')"
      >
        {{ "customerGroups.catalogIndex" | translate }}
        <br />
        {{ "customerGroups.tradingIndex" | translate }}
        <i
          class="header-sort-icon"
          [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
        ></i>
      </div>
    </div>
    <div
      style="border-top: none !important"
      *dxTemplate="let data of 'catalogIndex'"
    >
      <p style="border-top: none !important" class="p-amount">
        {{ data.data.IndexCatalogue }}
      </p>
      <p style="border-top: none !important" class="p-amount">
        {{ data.data.IndexTrading }}
      </p>
    </div>
  </dx-data-grid>
  <dx-context-menu
    [dataSource]="contextMenu"
    [width]="200"
    [target]="'#grid' + unicalGuid"
    (onItemClick)="contextMenuClick($event)"
    (onItemRendered)="event.onItemRendered($event)"
  ></dx-context-menu>
</ng-template>

<div
  [ngClass]="{
    'queue-tasks-hide': !isShowSidePanel,
    'queue-tasks': isShowSidePanel
  }"
  *ngIf="fullScreen"
>
  <ng-container *ngTemplateOutlet="sidePanelContent"></ng-container>
</div>

<app-data-collector
  *ngIf="isCollectorVisible"
  [visible]="isCollectorVisible"
  (onPopupClose)="isCollectorVisible = false"
  [transferConfig]="collectorTransferConfig"
  (onSend)="getPosition(form.value.InvoiceDocumentId)"
></app-data-collector>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGHS'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-contractors
  [selectedId]="form.value.CustomerId"
  [readOnly]="readOnly"
  [popUpMode]="true"
  (onChoosed)="
    onChoosedCustomer($event, true);
    isContractorsVisible = false;
    cd.detectChanges()
  "
  (onClosed)="isContractorsVisible = false; cd.detectChanges()"
  *ngIf="isContractorsVisible"
  [isVisible]="true"
></app-contractors>

<ng-template #emptyStateGird>
  <div
    class="text-center"
    [ngStyle]="{
      'height.px': heightGrid + 20
    }"
    style="display: flex; justify-content: center; align-items: center"
    [id]="'grid' + unicalGuid"
  >
    <dx-scroll-view width="100%" [height]="'100%'" direction="vertical">
      <div class="inside">
        <app-empty-state-grid-svg></app-empty-state-grid-svg>
        <p class="noDataToDisplay">
          {{
            "form-commercial-operation.noDataToDisplayAddTheFirstItem"
              | translate
          }}
        </p>

        <dx-button
          text="{{ 'form-commercial-operation.newPositino' | translate }}"
          icon="icon absui-icon--add-circle-visible"
          type="success"
          style="margin-right: 6px; border: 2px !important"
          [width]="150"
          [disabled]="readOnly"
          (onClick)="beforeAddArticle(); cd.detectChanges()"
        ></dx-button>
      </div>
    </dx-scroll-view>
  </div>
</ng-template>

<app-confirm-dialog
  kindDialog="question"
  confirmText="form-commercial-operation.anItemWithZeroStockHasBeenSelected"
  *ngIf="isShowSubstitutesQuestion()"
  [isVisible]="true"
  (onRemoving)="yesSubstitutes()"
  (onClosing)="noSubstitutes()"
></app-confirm-dialog>

<app-substitutes
  [isPopupMode]="true"
  *ngIf="isShowSubstitutes()"
  [isVisible]="true"
  [productId]="positionArticleToAdd?.ProductId"
  [product]="positionArticleToAdd"
  [ProductContextFilter]="DocumentContext"
  [readOnly]="readOnly"
  (onClosed)="isShowSubstitutesQuestion.set(false)"
  (onChoosed)="onSubstituteChoosed($event)"
></app-substitutes>

<app-confirm-dialog
  kindDialog="question"
  confirmText="form-financial-document.setSelectedSelectComponentsPerDocument"
  *ngIf="isSet()"
  [isVisible]="true"
  (onRemoving)="yesAddSet()"
  (onClosing)="isSet.set(false); cd.detectChanges()"
></app-confirm-dialog>

<app-quantity-sets
  [isVisible]="true"
  *ngIf="isVisibleSets()"
  (onClosing)="isVisibleSets.set(false); cd.detectChanges()"
  (onSaving)="onSavingSets($event)"
></app-quantity-sets>

<app-confirm-dialog
  kindDialog="question"
  confirmText="form-commercial-operation.shouldThePricesofTheComponentsBeAdjustedToThePriceofTheSetRecordedInTheAssortmentFile"
  *ngIf="isSetPrice()"
  [isVisible]="true"
  (onRemoving)="yesAddSetPrice()"
  (onClosing)="noAddSetPrice(); cd.detectChanges()"
></app-confirm-dialog>

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  AfterViewInit,
  signal,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxPopupModule,
  DxProgressBarModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { InvoiceDocument } from '../comercial-operation/invoice-document.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '../../event.service';
import { WaproDateBoxComponent } from '../../core/wapro-date-box/wapro-date-box.component';
import { CustomerListPopupComponent } from '../../core/customer-list-popup/customer-list-popup.component';
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
import { AppServices } from '../../app-services.service';
import { ListCustomerTemplateComponent } from './list-customer-template/list-customer-template.component';
import { NgShortcutsComponent } from '../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

interface RadioData {
  value: number;
  name: string;
}

interface ParamsConfirm {
  generate: number;
  skip: number;
}

interface RequestObject {
  InvoiceDocumentId: number;
  DateOfSell: Date;
  PriceMode: number;
  IsSkip: boolean;
  IsGenerate: boolean;
  CustomerId: number;
}

interface TemplateDictionary {
  InvoiceDocumentTemplateId: number;
  TemplateDictionaryId: number;
  TemplateDictionaryName: string;
  TemplateName: string;
  Use: number;
}

@Component({
    selector: 'app-serial-doc-generator',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DxPopupModule,
        DxButtonModule,
        DxTemplateModule,
        DxScrollViewModule,
        TranslateModule,
        DxRadioGroupModule,
        DxTextBoxModule,
        WaproDateBoxComponent,
        DxCheckBoxModule,
        CustomerListPopupComponent,
        ConfirmDialogComponent,
        DxProgressBarModule,
        ListCustomerTemplateComponent,
        NgShortcutsComponent,
        DxTooltipModule,
    ],
    templateUrl: './serial-doc-generator.component.html',
    styleUrl: './serial-doc-generator.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SerialDocGeneratorComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() isVisible: boolean = false;
  @Input() selectedTemplateId: number[] = [];
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  widthWindow: number | string | Function = 500;
  heightWindow: number | string | Function = 560;
  title: string = 'Generator dokumentów seryjnych';
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  radioForCustomer = signal<RadioData[]>([]);
  radioPricesAndDiscount = signal<RadioData[]>([]);
  isPopUpCustomerVisible = signal<boolean>(false);
  form;
  forCustomer: number = 0;
  confirmMsg: string = '';
  isShowconfirm = signal<boolean>(false);

  isProgrss = signal<boolean>(false);
  progrsValue = signal(0);
  isShowSummary = signal<boolean>(false);

  paramsConfirm: ParamsConfirm = {
    generate: 0,
    skip: 0,
  };

  isShowTemplate = signal<boolean>(false);
  shortcuts: ShortcutInput[] = [];
  dataSource: TemplateDictionary[] = [];

  constructor(
    public event: EventService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private appService: AppServices,
    private cdr: ChangeDetectorRef
  ) {
    this.initRadioCustomer();
    this.initRadioPricesAndDiscount();
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.initTranslate();
    this.initShortcuts();
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp();
  }

  visibleChange(e): void {
    if (!e) this.onClosing.emit(false);
  }

  closeWindow(): void {
    this.onClosing.emit(false);
    this.isVisible = false;
  }

  private async getData() {
    this.event.onShown();
    for (const item of this.selectedTemplateId) {
      await this.getTemplateDictionary(item);
    }
    this.getCustomer();
    this.event.onHidden();
  }

  private async getCustomer() {
    for (const item of this.dataSource) {
      await this.getCustomerListForTemplate(item.TemplateDictionaryId);
    }
  }

  private getCustomerListForTemplate(id) {
    return new Promise((resolve) => {
      if (id === null) {
        resolve(true);
        return;
      }

      this.appService.getAuth(`customers?SelectedListId=${id}`).subscribe({
        next: (data) => {
          const customerIds = data.data.map((item) => item.CustomerId);
          this.form.controls.CustomerId.setValue(
            this.form.value.CustomerId.concat(customerIds)
          );
          resolve(true);
        },
        error: (err) => {
          resolve(true);
          this.event.httpErrorNotification(err);
        },
      });
    });
  }

  private async getTemplateDictionary(id) {
    const InvoiceDocumentNumber = await this.getDataInvoice(id);
    return new Promise((resolve) => {
      this.appService
        .getAuth(
          `invoices/additionalOperations/templateDictionary?templateName=${InvoiceDocumentNumber}`
        )
        .subscribe({
          next: (data: TemplateDictionary[]) => {
            this.dataSource = this.dataSource.concat(data);
            this.cdr.detectChanges();
            resolve(true);
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
          },
        });
    });
  }

  private getDataInvoice(id) {
    return new Promise((resolve) => {
      this.appService.getAuth(`invoices/documents/${id}`).subscribe({
        next: (data: InvoiceDocument) => {
          resolve(data.InvoiceDocumentNumber);
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
    });
  }

  async onValueChanged() {
    this.form.controls.Title.setValue('');

    if (this.form.value.Mode === 0) this.form.controls.CustomerId.setValue([0]);
    if (this.form.value.Mode === 2) {
      this.dataSource = [];
      this.form.controls.CustomerId.setValue([]);
      this.getData();
    }

    if (this.form.value.Mode === 1 || this.form.value.Mode === 3) {
      this.form.controls.CustomerId.setValue([]);
      this.isPopUpCustomerVisible.set(true);
    }
  }

  private initRadioCustomer() {
    this.radioForCustomer.set([
      {
        value: 0,
        name: this.translate.instant(
          'form-commercial-operation.accordingToTheTemplate'
        ),
      },
      {
        value: 1,
        name: this.translate.instant('form-commercial-operation.oneChosen'),
      },
      {
        value: 2,
        name: this.translate.instant(
          'form-commercial-operation.accordingToTheListsOfTemplateContractors'
        ),
      },
      {
        value: 3,
        name: this.translate.instant('form-commercial-operation.marked'),
      },
    ]);
  }

  private initRadioPricesAndDiscount(): void {
    this.radioPricesAndDiscount.set([
      {
        value: 0,
        name: this.translate.instant(
          'form-commercial-operation.accordingToTheTemplate'
        ),
      },
      {
        value: 1,
        name: this.translate.instant(
          'form-commercial-operation.accordingToTheCurrentPriceList'
        ),
      },
    ]);
  }

  private initTranslate(): void {
    this.title = this.translate.instant(
      'form-commercial-operation.serialDocumentGenerator'
    );
  }

  private initForm() {
    this.form = this.formBuilder.group({
      InvoiceDocumentId: [0],
      Mode: [0],
      DateOfSell: [new Date()],
      PriceMode: [0],
      IsSkip: [true],
      IsGenerate: [false],
      CustomerId: [[0]],
      Title: [''],
    });
  }

  onChoosedCustomerPopUp(e) {
    this.isPopUpCustomerVisible.set(false);
    if (Array.isArray(e) && this.form.value.Mode === 3) {
      this.form.controls.CustomerId.setValue(e);
      this.form.controls.Title.setValue('Kontrahenci zostali zaznaczeni');
      return;
    }

    this.form.controls.CustomerId.setValue([e.CustomerId]);
    this.form.controls.Title.setValue(`Wybrany kontrahent: ${e.ShortName}`);
  }

  onClosedCustomer(): void {
    this.isPopUpCustomerVisible.set(false);

    if (this.form.value.Mode === 1 && this.form.value.Title === '') {
      this.confirmMsg = this.translate.instant(
        'form-commercial-operation.pleaseIndicateOneContractor'
      );
      this.isShowconfirm.set(true);
    }

    if (this.form.value.Mode === 3 && this.form.value.Title === '') {
      this.confirmMsg = this.translate.instant(
        'form-commercial-operation.pleaseSelectContractors'
      );
      this.isShowconfirm.set(true);
    }
  }

  onSave(): void {
    if (this.event.readOnly) return;

    if (this.form.value.CustomerId.length == 0) {
      this.paramsConfirm.skip++;
      this.showSummary();
      return;
    }

    this.generateDocuments();
  }

  private async generateDocuments() {
    // this.event.onShown();
    this.progrsValue.set(0);
    this.paramsConfirm = {
      skip: 0,
      generate: 0,
    };
    this.isProgrss.set(true);
    let progrssCount = (
      100 /
      (this.selectedTemplateId.length * this.form.value.CustomerId.length)
    ).toFixed(2);

    for (const item of this.selectedTemplateId) {
      await this.waitTemplate(item, progrssCount);
    }

    // this.event.onHidden();
  }

  private async waitTemplate(idTemplate, progrssCount) {
    for (const item of this.form.value.CustomerId) {
      await this.waitForGenerate(item, idTemplate, progrssCount);
    }
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  private waitForGenerate(id, idTemplate, progrssCount) {
    return new Promise((resolve) => {
      const object: RequestObject = {
        InvoiceDocumentId: idTemplate,
        DateOfSell: this.form.value.DateOfSell,
        PriceMode: this.form.value.PriceMode,
        IsSkip: this.form.value.IsSkip,
        IsGenerate: this.form.value.IsGenerate,
        CustomerId: id,
      };

      this.appService
        .postAuth(
          `invoices/additionalOperations/generateFromTemplate/${idTemplate}`,
          object
        )
        .subscribe({
          next: () => {
            this.handleSuccessfulResponse(progrssCount);
            resolve(true);
          },
          error: (err) => {
            this.handleErrorResponse(progrssCount);
            this.event.httpErrorNotification(err);

            resolve(true);
          },
        });

      resolve(true);
    });
  }

  private handleErrorResponse(progrssCount) {
    this.progrsValue.set(this.progrsValue() + Number(progrssCount));
    if (Math.round(this.progrsValue()) == 100) {
      this.isProgrss.set(false);
      this.showSummary();
    }
    this.paramsConfirm.skip++;
  }

  private handleSuccessfulResponse(progrssCount) {
    this.progrsValue.set(this.progrsValue() + Number(progrssCount));
    if (Math.round(this.progrsValue()) == 100) {
      this.isProgrss.set(false);
      this.showSummary();
    }
    this.paramsConfirm.generate++;
  }

  private showSummary() {
    this.isShowSummary.set(true);
    this.onInserted.emit(true);
  }

  private initShortcuts() {
    this.shortcuts = [
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.generateDocuments();
        },
        preventDefault: true,
      },
      {
        key: 'f7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.form.value.Mode != 1 && this.form.value.Mode != 3) return;

          this.isPopUpCustomerVisible.set(true);
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + l',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isShowTemplate.set(true);
        },
        preventDefault: true,
      },
    ];
  }

  format(value) {
    return `Loading: ${value * 100}%`;
  }
}

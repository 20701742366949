import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ResizableModule } from 'angular-resizable-element';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { LibrariesModule } from '../libraries/libraries.module';
import { PipesModule } from '../pipes/pipes.module';
import { CoreModule } from './../core/core.module';
import { routing } from './comercial-operation-routing-module';
import { InvoicesStatusComponent } from './comercial-operation/invoices-status/invoices-status.component';

import { DxTooltipModule } from 'devextreme-angular';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ComercialOperationComponent } from './comercial-operation/comercial-operation.component';
import { FinancialOperationComponent } from './financial-operation/financial-operation.component';
import { NewFinancialOperationComponent } from './new-financial-operation/new-financial-operation.component';
import { NewItemComponent } from './new-financial-operation/new-item/new-item.component';

import { DxAccordionModule, DxNumberBoxModule } from 'devextreme-angular';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { QuicklinkModule } from 'ngx-quicklink';
import { UserExtensionModule } from '../user-extension/user-extension.module';
import { RetailSalesComponent } from './retail-sales/retail-sales.component';
import { AddSettlementsComponent } from './settlements/add-settlements/add-settlements.component';
import { ClearancesComponent } from './settlements/clearances/clearances.component';
import { SettlementsComponent } from './settlements/settlements.component';
import { SumaComponent } from './settlements/suma/suma.component';

import { WarehouseNewDocumentModule } from '../warehouse/warehouse-new-document.module';
// import { OrderRealizationComponent } from './new-comercial-operation/order-realization/order-realization.component';
// import { StartRealizationComponent } from './new-comercial-operation/order-realization/start-realization/start-realization.component';
import { LabelModule } from '../label/label.module';

import { CorrectionComponentInvoice } from './comercial-operation/correction/correction.component';

import { ChangeJPKComponent } from './comercial-operation/change-jpk/change-jpk.component';

import { AdvancesComponent } from './comercial-operation/advances/advances.component';
import { SumComercialOperationComponent } from './comercial-operation/sum-comercial-operation/sum-comercial-operation.component';
import { ReckoningComponent } from './settlements/clearances/reckoning/reckoning.component';

import { isCashId } from '../auth.guard';
import { CrmModule } from '../crm/crm.module';
import { ChangeCashBoxComponent } from './change-cash-box/change-cash-box.component';
import { FinancialOperationSumComponent } from './financial-operation-sum/financial-operation-sum.component';
import { FinancialRaportsComponent } from './financial-raports/financial-raports.component';
import { AddCurrencySumComponent } from './financial-raports/new-financial-raport/add-currency-sum/add-currency-sum.component';
import { NewFinancialRaportComponent } from './financial-raports/new-financial-raport/new-financial-raport.component';
import { AddDictionaryTitleComponent } from './new-financial-operation/dictionary-title/add-dictionary-title/add-dictionary-title.component';
import { DictionaryTitleComponent } from './new-financial-operation/dictionary-title/dictionary-title.component';
import { InterestNoteComponent } from './settlements/clearances/interest-note/interest-note.component';
import { ConfirmDialogComponent } from '../core/confirm-dialog/confirm-dialog.component';
import { BtnExportExcelComponent } from '../menu-wapro/btn-export-excel/btn-export-excel.component';
import { NgShortcutsComponent } from '../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { ExpansionListComponent } from '../expansion-slote/expansion-list/expansion-list.component';
import { CustomChipsButtonComponent } from '../core/custom-chips-button/custom-chips-button.component';
import { MobileListComponent } from '../core/mobile-list/mobile-list.component';
import { CustomDropdownBoxComponent } from '../core/custom-dropdown-box/custom-dropdown-box.component';
import { WaproDateBoxComponent } from '../core/wapro-date-box/wapro-date-box.component';
import { TableDataUserExtensionComponent } from '../core/table-user-extension/table-data-user-extension/table-data-user-extension.component';
import { TableUserExtensionComponent } from '../core/table-user-extension/table-user-extension.component';
import { NestComponent } from 'src/app/nest/nest.component';
import { StatusLabelComponent } from '../core/status-label/status-label.component';
import { ExportJpkXmlComponent } from '../core/export-jpk-xml/export-jpk-xml.component';
import { RemarksToOrdersComponent } from './new-comercial-operation/remarks-to-orders/remarks-to-orders.component';
// import { LabelPrintersModule } from '../libraries/external-devices/label-printers/label-printers.module';
import { DxProgressBarModule } from 'devextreme-angular/ui/progress-bar';
import { LabelComponent } from '../core/label/label.component';
import { AddCrmDeliveryComponent } from '../crm/crm-deliveries/add-crm-delivery/add-crm-delivery.component';
import { PreventRightClickDirective } from '../core/directives/prevent-right-click.directive';
import { RecordCardComponent } from '../libraries/record-card/record-card.component';
import { GroupChangeOperationCodeComponent } from './financial-operation/group-change-operation-code/group-change-operation-code.component';
import { NotesComponent } from '../core/notes/notes.component';
import { SerialDocGeneratorComponent } from './serial-doc-generator/serial-doc-generator.component';
import { LabelPrinterOperationsComponent } from '../libraries/external-devices/label-printers/label-printer-operations/label-printer-operations.component';
import { EmptyStateGridSvgComponent } from 'src/assets/svg/empty-state-grid-svg/empty-state-grid-svg.component';

@NgModule({
  declarations: [
    GroupChangeOperationCodeComponent,
    InterestNoteComponent,
    NewFinancialRaportComponent,
    FinancialRaportsComponent,
    AdvancesComponent,
    ReckoningComponent,
    AddCurrencySumComponent,
    FinancialOperationComponent,
    CorrectionComponentInvoice,
    //StartRealizationComponent,
    NewFinancialOperationComponent,
    NewItemComponent,
    //OrderRealizationComponent,
    DictionaryTitleComponent,
    ComercialOperationComponent,
    SettlementsComponent,

    AddSettlementsComponent,
    SumaComponent,
    RetailSalesComponent,
    ClearancesComponent,
    InvoicesStatusComponent,

    ChangeJPKComponent,
    SumComercialOperationComponent,
    NewFinancialRaportComponent,
    AddDictionaryTitleComponent,
    ChangeCashBoxComponent,
    FinancialOperationSumComponent,
  ],
  imports: [
    SerialDocGeneratorComponent,
    AddCrmDeliveryComponent,
    BtnExportExcelComponent,
    CommonModule,
    ConfirmDialogComponent,
    CoreModule,
    CrmModule,
    CustomChipsButtonComponent,
    CustomDropdownBoxComponent,
    DxAccordionModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxListModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxProgressBarModule,
    DxRadioGroupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTabPanelModule,
    DxTabsModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTooltipModule,
    DxTreeViewModule,
    ExpansionListComponent,
    ExportJpkXmlComponent,
    FormsModule,
    KeyboardShortcutsModule.forRoot(),
    LabelComponent,
    LabelModule,
    // LabelPrintersModule,
    LabelPrinterOperationsComponent,
    LibrariesModule,
    MobileListComponent,
    NestComponent,
    NgShortcutsComponent,
    NotesComponent,
    PipesModule,
    PreventRightClickDirective,
    QuicklinkModule,
    ReactiveFormsModule,
    RecordCardComponent,
    RemarksToOrdersComponent,
    ResizableModule,
    routing,
    StatusLabelComponent,
    TableDataUserExtensionComponent,
    TableUserExtensionComponent,
    TranslateModule,
    UserExtensionModule,
    WaproDateBoxComponent,
    WarehouseNewDocumentModule,
    EmptyStateGridSvgComponent
  ],
  exports: [
    AddSettlementsComponent,
    NewFinancialOperationComponent,
    ComercialOperationComponent,
  ],
  providers: [isCashId],
})
export class ComercialOperationModule {}

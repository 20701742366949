<div *ngIf="!dropDownBoxMode && !popUpMode">
  <ng-container *ngIf="componentVisible">
    <div class="top-menu-panel">
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <div class="center-panel-scroll-verticall">
      <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="popUpMode">
  <dx-popup
    width="1400"
    height="700"
    position="center"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="true"
    [resizeEnabled]="true"
    [title]="title"
    [visible]="isVisible"
    minWidth="750"
    (visibleChange)="visibleChange($event)"
    (onShown)="event.onShownPopUp(); onShownPopup()"
    (onHidden)="event.onHiddenPopUp()"
    [wrapperAttr]="{ id: unicalGuid }"
  >
    <div *dxTemplate="let content of 'content'">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px"
          [id]="'contractors' + unicalGuid"
          *ngIf="chooseBtn"
          (onClick)="onChoosedSettlements()"
          [disabled]="focusedSelected.length == 0 || readOnly"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.close' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          [id]="'cancel' + unicalGuid"
          (mouseenter)="tooltipCancel = true"
        ></dx-button>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.close" | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>

      <dx-scroll-view width="100%" height="100%" direction="vertical">
        <div class="top-menu-popup">
          <ng-container *ngTemplateOutlet="topButtons"></ng-container>
        </div>
        <br />
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </dx-scroll-view>
    </div>
  </dx-popup>
</div>

<dx-drop-down-box
  #contractorsBox
  [(value)]="settlementName"
  valueExpr="SettlementId"
  [deferRendering]="false"
  displayExpr="Number"
  [(opened)]="isGridBoxOpened"
  width="276"
  [dataSource]="settlementList"
  [showClearButton]="true"
  [readOnly]="readOnly"
  (onValueChanged)="onValueChanged($event)"
  [ngClass]="{ 'input-required': className == true }"
  (openedChange)="openedChange($event)"
  (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
  *ngIf="dropDownBoxMode"
>
  <div *dxTemplate="let data of 'content'" style="height: 300px">
    <div class="top-menu-popup">
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <ng-container *ngTemplateOutlet="grid"></ng-container>
  </div>
</dx-drop-down-box>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <app-wapro-selected-box
      [(ngModel)]="selectedRows"
      [selectedAmount]="selectedAmount()"
      [dataSource]="dataSource"
      [selectedAll]="isAllSelected()"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>
    @if(event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
    event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
    event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
    event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'){
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      type="default"
      stylingMode="text"
      [id]="'btn-add' + unicalGuid"
      (onClick)="addNewSettlements()"
      [disabled]="readOnly || !perABD.addBtn"
      *ngIf="!dropDownBoxMode"
      data-cy="btn-add-settlement"
    ></dx-button>
    }

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--mode-edit"
      type="default"
      stylingMode="text"
      [id]="'btn-edit' + unicalGuid"
      (onClick)="showEditContr()"
      [disabled]="readOnly || focusedSelected.length != 1 || !perABD.editBtn"
      *ngIf="!dropDownBoxMode"
      data-cy="btn-edit-settlement"
    ></dx-button>

    <dx-button
      icon="icon absui-icon--highlight-off"
      [id]="'btn-delete' + unicalGuid"
      class="btn-dx-top-menu-folder"
      [disabled]="focusedSelected.length == 0 || readOnly || !perABD.deleteBtn"
      type="default"
      stylingMode="text"
      (onClick)="isDelete()"
      (mouseenter)="tooltipDelete = true"
      data-cy="btn-delete-settlement"
    ></dx-button>

    <dx-button
      icon="icon absui-icon--form-items"
      [id]="'contractorsShow' + unicalGuid"
      class="btn-dx-top-menu-folder"
      [disabled]="focusedSelected.length != 1 || !perABD.showBtn"
      type="default"
      stylingMode="text"
      (onClick)="showSingleRecord()"
      *ngIf="!dropDownBoxMode"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu"
      icon="icon absui-icon--tool-select-rectangle"
      [text]="'buttons.choose' | translate"
      type="default"
      stylingMode="text"
      [disabled]="rowSelected.length == 0 || readOnly"
      *ngIf="dropDownBoxMode"
      (onClick)="onChoosingCustomers()"
      [id]="'contractors' + unicalGuid"
    ></dx-button>

    <dx-button
      icon="icon absui-icon--sum"
      [id]="'suma' + unicalGuid"
      class="btn-dx-top-menu"
      type="default"
      stylingMode="text"
      [text]="'stockHistory.sum' | translate"
      (onClick)="isShowSum = true"
      *ngIf="!dropDownBoxMode"
    ></dx-button>

    <dx-button
      icon="icon absui-icon--dollar"
      [id]="'clearances' + unicalGuid"
      class="btn-dx-top-menu"
      type="default"
      stylingMode="text"
      [text]="'form-commercial-operation.clearances' | translate"
      (onClick)="isClearances = true"
      [disabled]="focusedSelected.length == 0"
      *ngIf="!dropDownBoxMode"
    ></dx-button>

    <dx-button
      [id]="'settlement' + unicalGuid"
      class="btn-dx-top-menu"
      [text]="'form-commercial-operation.settlement' | translate"
      (onClick)="setSettlement()"
      [disabled]="focusedSelected.length == 0 || !RozlRozr"
      *ngIf="!dropDownBoxMode"
    ></dx-button>

    <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

    <!-- Brak uprawnień -->
    <!-- <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container> -->

    <app-product-scaner
      #scaner
      [readOnly]="readOnly"
      [showScanerContainer]="false"
      (onInputChanged)="onScanerInputChanged($event)"
      [keyboardShortcutsDisabled]="
        isSingleRecordVisible ||
        isShowSum ||
        isClearances ||
        isDeleteRow ||
        isLabel ||
        isShortcutTableOpened ||
        isFinancial ||
        visibleUserExtensions ||
        (dropDownBoxMode && !isGridBoxOpened) ||
        isExternalTableVisible ||
        localStorageData?.sidePanel?.isVisible ||
        (popUpMode && !isVisible) ||
        isExpansionListVisible
      "
      [parentId]="unicalGuid"
    ></app-product-scaner>

    <!-- <app-e-dokuments
      [isPopupMode]="true"
      [isVisible]="eDocumentsVisible"
      [id]="'eDocuments' + unicalGuid"
      #eDocuments
      [permission]="perEdocuments"
      DocumentCode="CustomerRelated"
      tableName="KONTRAHENT"
      [contextObjectId]="focusedSelected[0]?.SettlementId"
      [parentDocumentName]="focusedSelected[0]?.Number"
      style="display: inline-block"
      (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
    ></app-e-dokuments> -->

    <app-btn-export-excel
      [gridReference]="gridBank"
      [nameFile]="'menu.settlements' | translate"
      url="finances/settlements"
      [paramsObj]="paramsObj"
    ></app-btn-export-excel>

    <app-additional-operation
      #additionalOperation
      nameComponent="SettlementsComponent"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
    ></app-additional-operation>

    <dx-button
      icon="icon absui-icon--tables"
      [id]="'tables' + unicalGuid"
      class="btn-dx-top-menu"
      *ngIf="additionalTables.length"
      [disabled]="focusedSelected.length == 0"
      [text]="'buttons.tables' | translate"
      (onClick)="isExternalTableVisible = true"
    ></dx-button>

    <dx-tooltip
      [target]="'#settlement' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "form-commercial-operation.settlement" | translate }} (Ctrl + R)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#contractorsShow' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.show" | translate }} (Shift + F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#suma' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "stockHistory.sum" | translate }} (Ctrl + S)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-add' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#contractors' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.choose" | translate }} (ENTER)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#tables' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.tables" | translate }} (Ctrl + Y)
      </div>
    </dx-tooltip>

    <div class="right-header-btn">
      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        [ngClass]="{ active: localStorageData?.sidePanel?.isVisible }"
        icon="icon absui-icon--filter"
        (onClick)="toggleDynamicFilters()"
        style="margin-right: 6px"
        [id]="'btn-show-dynamic-filters' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      ></dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        type="default"
        stylingMode="text"
        (click)="getData()"
        *ngIf="!dropDownBoxMode"
        style="margin-right: 6px"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="column-chooser"
        type="default"
        stylingMode="text"
        (click)="columnsChooserVisible = true"
        *ngIf="permissionToChooseColums && !dropDownBoxMode"
        [id]="'btn-columns' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "refresh" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-columns' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "columnSettings" | translate }}
        </div>
      </dx-tooltip>
    </div>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <div class="panels-flex-container">
        <div
          [ngClass]="{
            without_search_panel: dropDownBoxMode,
            with_search_panel: !dropDownBoxMode
          }"
          #rightPanel
          [ngStyle]="{ width: localStorageData.mainPanelWidth }"
        >
          <div class="row">
            <div class="col-md-12">
              <app-custom-dropdown-box
                [openDropDown]="openCustomDropDown"
                [items]="filterCriteria"
                [selectedItem]="localStorageData?.filter?.orderBy"
                [filterValue]="localStorageData?.filter?.value"
                (onValueChanged)="onFilterDataChanged($event)"
                style="margin-right: 5px"
                (onArrowDown)="gridBank.instance.focus()"
                #searchControl
              ></app-custom-dropdown-box>

              <app-date-range
                #dateRange
                [readOnly]="readOnly"
                [isPopupMode]="true"
                (onChoosed)="onDateRangeChoosed($event)"
                [chipsVisible]="true"
                [isGlobal]="isNoGlobal"
                [customSettlementDate]="customSettlementDate"
              ></app-date-range>
              <dx-button class="btn-filter-box chips-btn" *ngIf="onlySelected">
                {{ "showSelected" | translate }}:
                <b>{{ "yes" | translate }}</b>
                <i
                  class="icon absui-icon--multiplication chips-close"
                  (click)="resetFiltr('onlySelected')"
                ></i>
              </dx-button>

              <dx-button
                class="btn-filter-box chips-btn"
                *ngIf="
                  nameFilterCriteria != '' && nameFilterCriteria != 'Wszystko'
                "
                (click)="showRightPanel()"
              >
                {{ "filterPanel.type" | translate }}:
                <b>{{ nameFilterCriteria }}</b>
                <i
                  class="icon absui-icon--multiplication chips-close"
                  (click)="resetFiltr('valueCriteria2')"
                ></i>
              </dx-button>

              <app-custom-chips-button
                #payerChips
                [name]="
                  'form-financial-document.payer-type.payer-type' | translate
                "
                [disabledClear]="true"
                [selectedValueInput]="customerFilter?.PayerType"
                [list]="payerTypes"
                (onValueChanged)="onPayerTypeChanged($event)"
              ></app-custom-chips-button>

              <dx-button
                *ngIf="
                  customerFilter.PayerType !== 'all' && customerFilter.PayerType
                "
                class="btn-filter-box"
                [ngClass]="{
                  'chips-btn':
                    customerFilter.CustomerId ||
                    customerFilter.WorkerId ||
                    customerFilter.OfficeId
                }"
                (onClick)="openPayer()"
              >
                <ng-container *ngIf="customerFilter.PayerType === 'k'">
                  {{
                    "form-financial-document.payer-type.contractor" | translate
                  }}
                  <b style="margin-left: 6px" *ngIf="customerFilter.CustomerId">
                    {{ customerFilter.CustomerName }}
                  </b>
                </ng-container>
                <ng-container *ngIf="customerFilter.PayerType === 'u'">
                  {{ "form-financial-document.payer-type.office" | translate }}
                  <b style="margin-left: 6px" *ngIf="customerFilter.OfficeId">
                    {{ customerFilter.OfficeName }}
                  </b>
                </ng-container>
                <ng-container *ngIf="customerFilter.PayerType === 'p'">
                  {{ "form-financial-document.payer-type.worker" | translate }}
                  <b style="margin-left: 6px" *ngIf="customerFilter.WorkerId">
                    {{ customerFilter.WorkerName }}
                  </b>
                </ng-container>
                <i
                  class="icon absui-icon--multiplication chips-close"
                  (click)="resetPayerFilter()"
                  *ngIf="
                    customerFilter.CustomerId ||
                    customerFilter.WorkerId ||
                    customerFilter.OfficeId
                  "
                ></i>
              </dx-button>

              <app-custom-chips-button
                *ngIf="
                  localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
                  localStorageData?.advancedFilter?.IsPinned
                "
                [name]="'filterPanel.template' | translate"
                [labelExpr]="'Name'"
                [valueExpr]="'FilterUsersDefinitionId'"
                [selectedValueInput]="
                  localStorageData?.advancedFilter?.Name || null
                "
                [list]="dynamicFilterUserDefinitions"
                (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
              ></app-custom-chips-button>

              <dx-button
                [id]="'clearValues' + unicalGuid"
                class="btn-filter-box"
                icon="icon absui-icon--clear-filter"
                (onClick)="clearValues()"
              ></dx-button>
              <dx-tooltip
                [target]="'#clearValues' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "clearAllFilters" | translate }} (Ctrl + F10)
                </div>
              </dx-tooltip>

              <app-view-dynamic-quick-filters
                [dictionaryCode]="DynamicFilterDictionaryCode"
                [pinnedFilters]="localStorageData.advancedFilter?.PinnedFilters"
                [values]="
                  localStorageData?.advancedFilter?.UserFilterDefinitionValues
                "
                (onValueChanged)="onPinnedValueChanged($event)"
              ></app-view-dynamic-quick-filters>

              <dx-data-grid
                (onEditorPreparing)="onEditorPreparing($event)"
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                shortcut
                [height]="heightGrid - event.getHeightBoxLicenseInformation()"
                [(selectedRowKeys)]="selectedRows"
                [allowColumnReordering]="true"
                [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
                [columnResizingMode]="'widget'"
                (onInitialized)="onInitialized($event)"
                [remoteOperations]="true"
                (onRowDblClick)="onRowDblClick()"
                [columnAutoWidth]="true"
                [allowColumnResizing]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [id]="'grid' + unicalGuid"
                (onContentReady)="onGridContentReady($event)"
                (onKeyDown)="onKeyDown($event)"
                [(focusedRowIndex)]="focusedRowIndex"
                (onSelectionChanged)="onSelectionChanged($event)"
                [focusedRowEnabled]="true"
                #gridBank
                (onOptionChanged)="onOptionChanged($event)"
                (onRowClick)="onRowClick($event)"
                (onContextMenuPreparing)="contextMenuPreparing($event)"
                data-cy="settlements-grid"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling
                  mode="infinite"
                  columnRenderingMode="virtual"
                ></dxo-scrolling>
                <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
                <dxo-selection
                  [mode]="dropDownBoxMode ? 'single' : 'multiple'"
                  showCheckBoxesMode="always"
                  selectAllMode="page"
                ></dxo-selection>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
                <dxo-column-fixing [enabled]="!popUpMode"></dxo-column-fixing>

                <dxi-column
                  cellTemplate="markerConfigTemplate"
                  [fixed]="true"
                  [width]="0"
                ></dxi-column>
                <div *dxTemplate="let data of 'markerConfigTemplate'">
                  <div
                    *ngIf="data.data.Semaphore"
                    [ngClass]="{
                      editRowByMe: userId == data.data.Semaphore,
                      editRowByAnotherUser: userId != data.data.Semaphore
                    }"
                  ></div>
                </div>
                <dxi-column
                  caption="{{ 'form-financial-document.number' | translate }}"
                  [width]="150"
                  [allowSorting]="false"
                  dataField="Number"
                  cellTemplate="mainColumn"
                  headerCellTemplate="NumbertHeaderTemplate"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'number' ||
                    localStorageData.filter.orderBy == 'numberIntuition'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'mainColumn'">
                  <span
                    class="hov-underline"
                    (click)="showEditContr(data.data)"
                  >
                    {{ data.data.Number }}
                  </span>
                </div>
                <div *dxTemplate="let data of 'NumbertHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('number')"
                  >
                    {{ "form-financial-document.number" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.date' | translate }}"
                  [width]="150"
                  [allowSorting]="false"
                  dataField="SettlementDate"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  headerCellTemplate="DateTemplate"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'settlementDate'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'DateTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('settlementDate')"
                  >
                    {{ "form-financial-document.date" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{
                    'form-financial-document.grid.payer-name' | translate
                  }}"
                  [width]="300"
                  [allowSorting]="false"
                  dataField="PayerName"
                  headerCellTemplate="PayerNameTemplate"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'payerName' ||
                    localStorageData.filter.orderBy == 'payerNameIntuition'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'PayerNameTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('payerName')"
                  >
                    {{ "form-financial-document.grid.payer-name" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'warehouseDocument.label' | translate }}"
                  width="105"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="LabelName"
                  cellTemplate="labelTemplate"
                  renderAsync="true"
                  alignment="left"
                ></dxi-column>
                <div *dxTemplate="let data of 'labelTemplate'">
                  <app-label
                    [bgColor]="data.data.LabelBackgroundColor"
                    [color]="data.data.LabelTextColor"
                    [text]="data.data.LabelName"
                  ></app-label>
                </div>

                <dxi-column
                  caption="{{ 'form-commercial-operation.toPay' | translate }}"
                  [width]="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TotalAmountGross"
                  cellTemplate="TotalAmountGross"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalAmountGross'">
                  {{ data.data.TotalAmountGross | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="{{ 'stockHistory.available' | translate }}"
                  [width]="100"
                  [allowSorting]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'totalToPay'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  dataField="TotalToPay"
                  cellTemplate="TotalToPay"
                  headerCellTemplate="TotalToPayTemplate"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalToPay'">
                  {{ data.data.TotalToPay | amountFormatter : data }}
                </div>

                <div *dxTemplate="let data of 'TotalToPayTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('totalToPay')"
                  >
                    {{ "stockHistory.available" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'form-payment.paymentMethod' | translate }}"
                  [width]="150"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="PaymentFormName"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.dateOfDaymentts' | translate
                  }}"
                  [width]="150"
                  [allowSorting]="false"
                  dataField="PaymentDate"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  headerCellTemplate="PaymentDateTemplate"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'paymentDate'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'PaymentDateTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('paymentDate')"
                  >
                    {{
                      "form-commercial-operation.dateOfDaymentts" | translate
                    }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'bankAccounts.form.currency' | translate }}"
                  [width]="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="CurrencyCode"
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  caption="{{
                    'form-commercial-operation.toPayCurrency' | translate
                  }}"
                  [width]="120"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TotalGrossAmountCurrency"
                  cellTemplate="TotalGrossAmountCurrency"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalGrossAmountCurrency'">
                  {{
                    data.data.TotalGrossAmountCurrency
                      | amountFormatter : data : 4
                  }}
                </div>

                <dxi-column
                  caption="{{ 'leftCurrency' | translate }}"
                  [width]="120"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TotalToPayCurrency"
                  cellTemplate="TotalToPayCurrency"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalToPayCurrency'">
                  {{
                    data.data.TotalToPayCurrency | amountFormatter : data : 4
                  }}
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.daysExpired' | translate
                  }}"
                  [width]="150"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="DaysExpired"
                  cellTemplate="DaysExpired"
                ></dxi-column>
                <div *dxTemplate="let data of 'DaysExpired'">
                  <div
                    [ngClass]="{
                      statusN:
                        data.data.DaysExpired == 0 &&
                        data.data.DaysExpired == 0,
                      statusY:
                        data.data.DaysExpired > 0 &&
                        (data.data.SettlementStatus == 1 ||
                          data.data.SettlementStatus == 0),
                      statusZ: data.data.SettlementStatus == 2
                    }"
                  >
                    {{ data.data.DaysExpired }}
                  </div>
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.daysLate' | translate
                  }}"
                  [width]="150"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="DaysDelayed"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'countryChooser.grid.columns.symbol' | translate
                  }}"
                  [width]="200"
                  [allowSorting]="false"
                  dataField="Symbol"
                  headerCellTemplate="SymbolTemplate"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'symbol'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'SymbolTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('symbol')"
                  >
                    {{ "countryChooser.grid.columns.symbol" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'comments' | translate }}"
                  [width]="200"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="Remarks"
                ></dxi-column>
                <dxi-column
                  caption="{{
                    'bankAccounts.grid.columns.comments' | translate
                  }}"
                  [width]="200"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="Description"
                ></dxi-column>
                <dxi-column
                  caption="{{ 'constractors.accounFK' | translate }}"
                  [width]="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="FKAccount"
                  headerCellTemplate="FKAccountTemplate"
                  alignment="left"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'fkAccount'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'FKAccountTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('fkAccount')"
                  >
                    {{ "constractors.accounFK" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.lastSettlementDate' | translate
                  }}"
                  [width]="150"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="LastSettlementDate"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                ></dxi-column>
                <dxi-column
                  caption="{{ 'baseCurrencies.currencyValue' | translate }}"
                  [width]="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="CurrencyFactor"
                  cellTemplate="CurrencyConverter"
                ></dxi-column>

                <div *dxTemplate="let data of 'CurrencyConverter'">
                  <p
                    style="margin-bottom: 0px"
                    *ngIf="
                      data.data.CurrencyCode != '' &&
                      data.data.CurrencyCode != 'PLN'
                    "
                  >
                    {{ data.data.CurrencyFactor }}
                  </p>
                </div>

                <dxi-column
                  caption="PP"
                  [width]="40"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="IsSplitPayment"
                  cellTemplate="IsSplitPayment"
                ></dxi-column>
                <div *dxTemplate="let data of 'IsSplitPayment'">
                  <i
                    class="icon absui-icon--check check-grid"
                    *ngIf="data.data.IsSplitPayment"
                  ></i>
                  <i
                    class="icon absui-icon--close close-grid"
                    *ngIf="!data.data.IsSplitPayment"
                  ></i>
                </div>
                <dxi-column
                  caption="PP {{ 'companies.grid.columns.vat' | translate }}"
                  [width]="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="SplitPaymentVatNumber"
                ></dxi-column>

                <dxi-column
                  caption="PP {{ 'loyaltyProram.documentNumber' | translate }}"
                  [width]="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="SplitPaymentInvoiceNumber"
                ></dxi-column>

                <dxi-column
                  caption="PP {{
                    'form-commercial-operation.grid.vatAmount' | translate
                  }}"
                  [width]="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="SplitPaymentVatAmountCash"
                  cellTemplate="SplitPaymentVatAmountCash"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'SplitPaymentVatAmountCash'">
                  {{
                    data.data.SplitPaymentVatAmountCash | amountFormatter : data
                  }}
                </div>

                <dxi-column
                  [caption]="userExtensionColumns['Pole1'].Name"
                  dataField="Field1"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole1'] &&
                    userExtensionColumns['Pole1'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole2'].Name"
                  dataField="Field2"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole2'] &&
                    userExtensionColumns['Pole2'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole3'].Name"
                  dataField="Field3"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole3'] &&
                    userExtensionColumns['Pole3'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole4'].Name"
                  dataField="Field4"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole4'] &&
                    userExtensionColumns['Pole4'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole5'].Name"
                  dataField="Field5"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole5'] &&
                    userExtensionColumns['Pole5'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole6'].Name"
                  dataField="Field6"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole6'] &&
                    userExtensionColumns['Pole6'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole7'].Name"
                  dataField="Field7"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole7'] &&
                    userExtensionColumns['Pole7'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole8'].Name"
                  dataField="Field8"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole8'] &&
                    userExtensionColumns['Pole8'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole9'].Name"
                  dataField="Field9"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole9'] &&
                    userExtensionColumns['Pole9'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole10'].Name"
                  dataField="Field10"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole10'] &&
                    userExtensionColumns['Pole10'].IsActive
                  "
                ></dxi-column>
              </dx-data-grid>

              <dx-tooltip
                *ngFor="let icon of sortingIcons.asc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "columnSorting.asc" | translate }}
                </div>
              </dx-tooltip>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.desc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "columnSorting.desc" | translate }}
                </div>
              </dx-tooltip>

              <!-- [typeDocument]="typeDocumentNewDoc" -->
              <wapro-context-menu
                [dataSource]="contextMenuSignal()"
                [width]="200"
                [target]="'#grid' + unicalGuid"
                (onItemClick)="contextMenuClick($event)"
                (onItemRendered)="event.onItemRendered($event)"
              ></wapro-context-menu>
            </div>
          </div>

          <app-bottom-panel
            [height]="localStorageData.bottomPanel.height"
            (onResizing)="onResizingBottom($event)"
          >
            <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
          </app-bottom-panel>
        </div>

        <div
          class="right-side-panel"
          mwlResizable
          *ngIf="!dropDownBoxMode"
          (resizeEnd)="resizeEnd()"
          (resizeStart)="onResizeStart()"
          (resizing)="resizing($event)"
          style="width: 300px"
          [ngStyle]="{ width: localStorageData?.sidePanel?.width }"
          #leftPanel
        >
          <div class="right-panel-btn">
            <!-- <i class="icon text-icon"></i> -->
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="getResizeIcon()"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>
          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>
          <div #leftPanelTab>
            <dx-scroll-view
              width="100%"
              height="calc(100vh - 130px)"
              direction="vertical"
              style="margin-top: -10px"
            >
              <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
            </dx-scroll-view>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- component with configuration grid column -->
  <app-column-chooser
    [gridRef]="gridBank"
    [(visible)]="columnsChooserVisible"
    (columnsChanged)="onColumnsChanged($event)"
  ></app-column-chooser>
</ng-template>

<div *ngIf="loadChildComponent">
  <app-sms-offers
    [isVisible]="isSendSms"
    [readOnly]="readOnly"
    type="finances"
    [CustomerId]="selectedRows"
    title="{{ 'offers.SMSSending' | translate }}"
    (onClosing)="onSmsClosing()"
  ></app-sms-offers>

  <app-add-settlements
    [isVisible]="isSingleRecordVisible"
    (onClosing)="onClosingForm()"
    [componentNests]="componentNests"
    [selected]="mode !== 'add' ? focusedSelected : null"
    [title]="title"
    (onInserted)="onInserted($event)"
    [mode]="mode"
    [readOnly]="mode === 'show' || readOnly"
  ></app-add-settlements>

  <app-suma
    [isVisible]="isShowSum"
    (onClosing)="isShowSum = false; cd.detectChanges()"
    title="{{ 'form-financial-document.settlement' | translate }}"
    [selectedId]="focusedSelected"
    [forSelected]="selectedRows.length > 0 ? true : false"
  ></app-suma>

  <app-clearances
    [paymentDateSettlement]="focusedSelected[0]?.PaymentDate"
    [isSelectedRows]="selectedRows.length > 0 ? true : false"
    [isVisible]="isClearances"
    (onClosing)="isClearances = false; cd.detectChanges()"
    title="{{ 'form-commercial-operation.settlementPayments' | translate }}"
    [selectedId]="focusedSelected"
    (onRefresh)="getData()"
  ></app-clearances>

  <app-confirm-dialog
    [isVisible]="isDeleteRow"
    (onRemoving)="delete(); gridBank.instance.focus()"
    (onClosing)="closeConfirm()"
  ></app-confirm-dialog>

  <!-- <app-table-user-extension
    tableGroupCode="Rozrachunek"
    (onClosing)="isExternalTableVisible = false"
    [isVisible]="isExternalTableVisible"
    [isPopupMode]="true"
  ></app-table-user-extension> -->

  <app-labels
    [popUpMode]="true"
    (onChoosed)="onChoosedLabel()"
    (onClosed)="isLabel = false"
    [isVisible]="isLabel"
    [readOnly]="readOnly"
    IsForSettlementDoc="true"
    url="finances/settlements/selection/label"
  ></app-labels>

  <app-table-data-user-extension
    [readOnly]="readOnly"
    [isVisible]="isShortcutTableOpened"
    tableGroupCode="Rozrachunek"
    (onClosing)="isShortcutTableOpened = false; shortcutTable = null"
    [table]="shortcutTable"
  ></app-table-data-user-extension>

  <app-new-financial-operation
    *ngIf="isFinancial"
    [isVisible]="isFinancial"
    [selectedId]="toSettlementArray"
    (onClosing)="checkSettlementArray()"
    [typeDocument]="typeDocumentNewDoc"
    [settlement]="true"
    mode="add"
    (onInserted)="checkSettlementArray($event)"
    title="{{ 'form-financial-document.title' | translate }}"
  ></app-new-financial-operation>

  <app-form-user-extensions
    [isVisible]="visibleUserExtensions"
    [groupCode]="USER_EXTENSIONS_GROUP_CODE"
    [readOnly]="readOnly || onlyShow"
    [groupChanged]="true"
    title="{{ 'categories.externalFields' | translate }}"
    (onChoosed)="setAdditionalFields($event)"
    (onClosing)="onClosingUserExtensions()"
    [userExtensionsValue]="
      onlyShow ? OrderDocumentIdAdditionalField : additionalFieldsList
    "
    #isValidUserExtensions
  ></app-form-user-extensions>
</div>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="
    isSingleRecordVisible ||
    (dropDownBoxMode && !isGridBoxOpened) ||
    isFinancial ||
    isExternalTableVisible ||
    isShortcutTableOpened ||
    isDeleteRow ||
    (popUpMode && !isVisible) ||
    isExpansionListVisible
  "
></wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGRL'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<!-- mobile template -->
<ng-template #mobileTemplate>
  <div class="mobile-search-box-container">
    <app-custom-dropdown-box
      [items]="filterCriteria"
      [(selectedItem)]="localStorageData.filter.orderBy"
      [(filterValue)]="filterValue"
      (onValueChanged)="onFilterDataChanged($event)"
      (onArrowDown)="gridBank.instance.focus()"
      #searchControl
    ></app-custom-dropdown-box>
    <dx-button type="normal">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--sort-ascending': localStorageData.filter.order === 'ASC',
          'absui-icon--sort-descending': localStorageData.filter.order !== 'ASC'
        }"
        (click)="switchOrder(); getData()"
      ></i>
    </dx-button>
  </div>

  <app-date-range
    [readOnly]="readOnly"
    [isPopupMode]="true"
    (onChoosed)="onDateRangeChoosed($event)"
    #dateRange
    [chipsVisible]="true"
    [isGlobal]="isNoGlobal"
    [customSettlementDate]="customSettlementDate"
  ></app-date-range>
  <dx-button class="btn-filter-box chips-btn" *ngIf="onlySelected">
    {{ "showSelected" | translate }}:
    <b>{{ "yes" | translate }}</b>
    <i
      class="icon absui-icon--multiplication chips-close"
      (click)="resetFiltr('onlySelected')"
    ></i>
  </dx-button>

  <dx-button
    class="btn-filter-box chips-btn"
    *ngIf="nameFilterCriteria != ''"
    (click)="showRightPanel()"
  >
    {{ "filterPanel.type" | translate }}:
    <b>{{ nameFilterCriteria }}</b>
    <i
      class="icon absui-icon--multiplication chips-close"
      (click)="resetFiltr('valueCriteria2')"
    ></i>
  </dx-button>

  <app-custom-chips-button
    *ngIf="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
      localStorageData?.advancedFilter?.IsPinned
    "
    [name]="'filterPanel.template' | translate"
    [labelExpr]="'Name'"
    [valueExpr]="'FilterUsersDefinitionId'"
    [selectedValueInput]="localStorageData?.advancedFilter?.Name || null"
    [list]="dynamicFilterUserDefinitions"
    (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
  ></app-custom-chips-button>

  <app-view-dynamic-quick-filters
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [pinnedFilters]="localStorageData.advancedFilter?.PinnedFilters"
    [values]="localStorageData?.advancedFilter?.UserFilterDefinitionValues"
    (onValueChanged)="onPinnedValueChanged($event)"
  ></app-view-dynamic-quick-filters>

  <dx-data-grid
    (onEditorPreparing)="onEditorPreparing($event)"
    [dataSource]="dataSource"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [height]="heightGrid - 80"
    [(selectedRowKeys)]="selectedRows"
    [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
    [columnResizingMode]="'widget'"
    (onInitialized)="onInitialized($event)"
    [remoteOperations]="true"
    (onRowDblClick)="onRowDblClick()"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    [id]="'grid-mobile' + unicalGuid"
    (onContentReady)="onContentReady($event)"
    (onKeyDown)="onKeyDown($event)"
    [(focusedRowIndex)]="focusedRowIndex"
    (onSelectionChanged)="onSelectionChanged($event)"
    [focusedRowEnabled]="true"
    #gridBank
    (onOptionChanged)="onOptionChanged($event)"
    class="main-mobile-grid"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="infinite"
      columnRenderingMode="virtual"
      preloadEnabled="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxo-selection
      [mode]="modeSelectionMobile"
      showCheckBoxesMode="always"
      selectAllMode="page"
    ></dxo-selection>

    <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="Number"
      cellTemplate="mainColumn"
      width="200"
    ></dxi-column>

    <div
      appPreventRightClick
      (touchend)="onTouchEnd()"
      (touchstart)="onTouchStart($event)"
      *dxTemplate="let data of 'mainColumn'"
    >
      <div style="display: flex">
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == data.data.Semaphore,
            'semafore-edit-by-another-user':
              userId != data.data.Semaphore && data.data.Semaphore != null
          }"
        ></div>
        <div
          class="content-main-grid"
          [ngClass]="{
            'content-main-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <span class="hov-underline-mobile" (click)="showEditContr(data.data)">
            {{ data.data.Number }}
          </span>
          <br />
          <label class="mobile-grid-label">
            {{ "form-financial-document.date" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.SettlementDate | date : event.dateFormat }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "form-financial-document.grid.payer-name" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{
              data.data.PayerName.length > 25
                ? (data.data.PayerName | slice : 0 : 25) + "..."
                : data.data.PayerName
            }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "form-commercial-operation.toPay" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.TotalAmountGross | amountFormatter : null }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "stockHistory.available" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.TotalToPay | amountFormatter : null }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "bankAccounts.form.currency" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.CurrencyCode != "" ? data.data.CurrencyCode : "PLN" }}
          </p>
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px)"
            (onClick)="showContextMenuMobile(data.data)"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenuMobileSignal()"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>

  <div class="details-mobile-panel" *ngIf="isDetails">
    <header>
      <div class="d-flex flex-row">
        <div class="left-header">
          <i
            class="icon absui-backe"
            style="position: relative; top: 4px; margin-right: 10px"
            (click)="isDetails = false"
          ></i>
        </div>
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == focusedSelected[0].Semaphore,
            'semafore-edit-by-another-user':
              userId != focusedSelected[0].Semaphore &&
              focusedSelected[0].Semaphore != null
          }"
        ></div>
        <div class="right-details">
          <p *ngIf="focusedSelected[0]" class="nameSelectedPosition">
            {{ focusedSelected[0].Number }}
          </p>

          <label class="details-label">
            {{ "form-financial-document.date" | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0].SettlementDate | date : event.dateFormat }}
          </p>

          <label class="details-label">
            {{ "form-financial-document.grid.payer-name" | translate }}:
          </label>
          <p class="details-text">{{ focusedSelected[0].PayerName }}</p>

          <label class="details-label">
            {{ "form-commercial-operation.toPay" | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0].TotalAmountGross | amountFormatter : null }}
          </p>

          <label class="details-label">
            {{ "stockHistory.available" | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0].TotalToPay | amountFormatter : null }}
          </p>

          <label class="details-label">
            {{ "bankAccounts.form.currency" | translate }}:
          </label>
          <p class="details-text">
            {{
              focusedSelected[0].CurrencyCode != ""
                ? focusedSelected[0].CurrencyCode
                : "PLN"
            }}
          </p>
        </div>
      </div>
    </header>

    <div
      class="circle-showMore"
      (click)="isShowMoreDetails = !isShowMoreDetails"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-less': isShowMoreDetails,
          'absui-icon--expand-more': !isShowMoreDetails
        }"
      ></i>
    </div>

    <div class="more-mobile-details" *ngIf="isShowMoreDetails">
      <div>
        <div class="c-frame-row">
          <h5>{{ "tasks.details" | translate }}</h5>
        </div>
      </div>

      <div>
        <table style="width: 100%">
          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-payment.paymentMethod" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].PaymentFormName }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.dateOfDaymentts" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].PaymentDate | date : event.dateFormat }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "bankAccounts.form.currency" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0].CurrencyCode }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.toPayCurrency" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0].TotalToPayCurrency
                    | amountFormatter : null : 4
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.daysExpired" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0].DaysExpired }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "countryChooser.grid.columns.symbol" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0].Symbol }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "bankAccounts.grid.columns.comments" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0].Description }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "constractors.accounFK" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0].FKAccount }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{
                  "form-commercial-operation.lastSettlementDate" | translate
                }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0].LastSettlementDate
                    | date : event.dateFormat
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "baseCurrencies.currencyValue" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].CurrencyFactor }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">PP</p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                <i
                  class="icon absui-icon--check check-grid"
                  *ngIf="focusedSelected[0].IsSplitPayment"
                ></i>
                <i
                  class="icon absui-icon--close close-grid"
                  *ngIf="!focusedSelected[0].IsSplitPayment"
                ></i>
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                PP {{ "companies.grid.columns.vat" | translate }}
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].SplitPaymentVatNumber }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                PP {{ "loyaltyProram.documentNumber" | translate }}
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0].SplitPaymentInvoiceNumber }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                PP {{ "form-commercial-operation.grid.vatAmount" | translate }}
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0].SplitPaymentVatAmountCash
                    | amountFormatter : null
                }}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 25px">
        <div class="c-frame-row">
          <h5>{{ "comments" | translate }}</h5>
        </div>
        <p class="details-text">{{ focusedSelected[0].Remarks }}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rightPanelBox>
  <dx-tab-panel
    [visible]="localStorageData?.sidePanel?.isVisible"
    [items]="[1, 2]"
    [(selectedIndex)]="localStorageData.sidePanel.selectedIndex"
    class="filter-panel right-panel-box"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="true"
    style="padding-left: 6px; padding-right: 10px"
  >
    <div *dxTemplate="let item of 'title'">
      <div class="title-temp">
        <i class="icon absui-icon--control-tree" *ngIf="item == 1"></i>
        <i class="icon absui-icon--filter" *ngIf="item == 2"></i>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="1">
          <dx-tree-view
            [dataSource]="treeFilterList"
            keyExpr="TypeId"
            displayExpr="Name"
            #treeView
            (onSelectionChanged)="onTreeSelectionChanged($event)"
            (onInitialized)="restoreTreeConfig()"
            [selectByClick]="true"
            selectionMode="single"
            (onItemClick)="onItemAllClick($event)"
            style="width: 100%"
            *ngIf="localStorageData?.sidePanel?.isVisible"
          ></dx-tree-view>
        </div>
        <div *ngSwitchCase="2">
          <div>
            <app-filter-panel
              [deleteFilters]="deleteFilter"
              [openPanelFilters]="visibleAdvanceFilters"
              (onFilterSave)="onFilterSaved($event)"
              [dictionaryCode]="DynamicFilterDictionaryCode"
              [selectedTemplate]="
                localStorageData?.advancedFilter?.FilterUsersDefinitionId
              "
              [isPinned]="localStorageData?.advancedFilter?.IsPinned"
              (onTemplatePinChanged)="onTemplatePinChanged($event)"
              [tabActive]="
                localStorageData.sidePanel.isVisible &&
                localStorageData.sidePanel.selectedIndex == 1
              "
              [panelWidth]="localStorageData?.sidePanel?.width"
              [values]="
                localStorageData?.advancedFilter?.UserFilterDefinitionValues
              "
              [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            ></app-filter-panel>
          </div>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<div *ngIf="isFilterPanelComponent">
  <app-filter-panel
    [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
    (onFilterSave)="onFilterSaved($event)"
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [selectedTemplate]="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId
    "
    [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
  ></app-filter-panel>
</div>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <app-wapro-selected-box
      [(ngModel)]="selectedRows"
      [dataSource]="dataSource"
      [selectedAll]="isAllSelected()"
      [selectedAmount]="selectedAmount()"
      [mobileSelectMode]="modeSelectionMobile === 'multiple'"
      (onMobileSelectModeOff)="modeSelectionMobile = 'none'"
      (onSelectAll)="gridBank.instance.selectAll()"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [text]="'buttons.add' | translate"
      [id]="'btn-add' + unicalGuid"
      (onClick)="addNewSettlements()"
      [disabled]="readOnly || !perABD.addBtn"
      *ngIf="!dropDownBoxMode"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      (onClick)="mobileOperationsVisible = true; cd.detectChanges()"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder lastBtn"
      icon="icon absui-icon--filter"
      (onClick)="filterMobileVisible = true"
      style="position: absolute; right: 0"
    ></dx-button>

    <!-- mobile reports popup -->
    <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

    <!-- mobile edocoments popup -->
    <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

    <app-mobile-list
      [dataSource]="contextMenuOperationsMobile"
      [visible]="mobileOperationsVisible"
      (onClosed)="mobileOperationsVisible = false; cd.detectChanges()"
      (onItemClick)="contextMenuClick($event)"
    ></app-mobile-list>

    <app-additional-operation
      #additionalOperation
      nameComponent="SettlementsComponent"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
    ></app-additional-operation>
  </div>
</ng-template>

<dx-popup
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'filterPanel.filters' | translate }}"
  [(visible)]="filterMobileVisible"
  width="100%"
  height="100vh"
  *ngIf="filterMobileVisible"
  [maxHeight]="event.maxHeightSignal()"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="header-search-mobile">
      <i
        class="icon absui-icon--close"
        style="position: absolute; top: 14px; right: 16px; z-index: 1"
        (click)="filterMobileVisible = false"
      ></i>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
    >
      <br />
      <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
    </dx-scroll-view>

    <div class="bottom-mobile-form">
      <dx-button
        class="btn-dx-top-menu-folder"
        type="danger"
        style="margin-right: 12px; color: #333"
        icon="icon absui-icon--clear-filter"
        (onClick)="clearMobileFilters()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="filterMobileVisible = false"
      ></dx-button>
    </div>
  </div>
</dx-popup>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGRL'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-contractors
  [popUpMode]="true"
  [selectedId]="focusedSelected[0]?.PayerId"
  [isVisible]="isCustomerVisible"
  *ngIf="isCustomerVisible"
  (onClosed)="isCustomerVisible = false"
  (onChoosed)="onChoosedCustomer($event)"
></app-contractors>
<app-workers
  *ngIf="isWorkersVisible"
  [isVisible]="isWorkersVisible"
  [popUpMode]="true"
  (onChoosed)="onChoosedWorker($event)"
  (onClosed)="isWorkersVisible = false"
></app-workers>
<app-offices
  *ngIf="isOfficeVisible"
  [isVisible]="isOfficeVisible"
  [popUpMode]="true"
  (onChoosed)="onChoosedOffice($event)"
  (onClosed)="isOfficeVisible = false"
></app-offices>

<app-record-card
  *ngIf="isRecordCardVisible"
  [cardMode]="recordCardMode"
  selectedTab="{{ 'menu.settlements' | translate }}"
  [isPopupMode]="true"
  [isVisible]="isRecordCardVisible"
  [objectId]="recordCardObjectId"
  (onClosing)="onRecordCardClosing()"
></app-record-card>

<ng-template #bottomTab>
  <dx-tab-panel
    #tabPanel
    [items]="bottomPanelTabs"
    [(selectedIndex)]="localStorageData.bottomPanel.selectedIndex"
    (onTitleClick)="onItemClickTab()"
    (onSelectionChanged)="onSelectionTabChanged()"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    class="bottomTabPanelMobile comercial-operation-step-6"
  >
    <div *dxTemplate="let name of 'title'; let i = index">
      <div
        [id]="'tabToolTip' + i + unicalGuid"
        [attr.data-cy]="'invoices-tabs-' + name"
      >
        <span>{{ name }}</span>
      </div>
    </div>

    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'notes.notes' | translate">
          <app-notes
            #notesComponent
            componentName="settlements"
            [objectId]="
              focusedSelected?.length && !isSingleRecordVisible
                ? focusedSelected[0]?.SettlementId
                : null
            "
            *ngIf="
              bottomPanelTabs[tabPanel.selectedIndex] ===
                ('notes.notes' | translate) &&
              (event.deviceType === 'desktop'
                ? localStorageData.bottomPanel.isVisible
                : isDetails)
            "
            [timeout]="500"
            [componentHeight]="
              event.deviceType != 'mobile'
                ? localStorageData.bottomPanelHeight
                : 545
            "
          ></app-notes>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #reportsTemplate>
  <app-reports
    #reports
    [code]="'Rozrachunek'"
    [currencyCode]="
      focusedSelected?.length ? focusedSelected[0]?.CurrencyCode : null
    "
    [menuName]="focusedSelected?.length ? focusedSelected[0]?.MenuName : null"
    [type]="focusedSelected?.length ? focusedSelected[0]?.Signature : null"
    [objectId]="
      focusedSelected?.length ? focusedSelected[0]?.SettlementId : null
    "
    [settlement]="focusedSelected[0]"
    [selectedDocumentsData]="selectedDocumentsData"
    [isVisible]="
      event.deviceType === 'mobile' ? isMobileReportsPopupVisible : true
    "
    [params]="getLoadParams()"
    (onReportsPopupClosed)="onReportsPopupClosed()"
  ></app-reports>
</ng-template>

<ng-template #edocumentsTemplate>
  <app-e-dokuments
    [isPopupMode]="true"
    [isVisible]="eDocumentsVisible"
    [id]="'eDocuments' + unicalGuid"
    #eDocuments
    [permission]="perEdocuments"
    DocumentCode="CustomerRelated"
    tableName="KONTRAHENT"
    [contextObjectId]="focusedSelected[0]?.SettlementId"
    [parentDocumentName]="focusedSelected[0]?.Number"
    style="display: inline-block"
    (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
  ></app-e-dokuments>
</ng-template>

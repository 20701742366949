<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-financial-document.financialRaports' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="
    event.onShownPopUp(); event.setFocus(currencyBox.currencyDropDownBox)
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
  [maxWidth]="maxWidth"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        id="btn-save-comercial"
        *ngIf="!readOnly"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onCancel()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="102%"
      direction="vertical"
      showScrollbar="never"
      #scrollView
    >
      <div class="d-flex justify-content-center flex-wrap" [formGroup]="form">
        <div class="row">
          <div class="col-md-12 text-right">
            <div class="form-group-inline-right" style="width: 480px">
              <label style="width: auto">{{
                "bankAccounts.form.currency" | translate
              }}</label>

              <app-currency-box
                [className]="
                  !form.controls.CurrencySymbol.valid && submitted
                    ? true
                    : false
                "
                [dropDownBoxMode]="true"
                [readOnly]="readOnly"
                [selectedId]="form.value.CurrencySymbol"
                [width]="276"
                (onChoosed)="onChoosedCurrency($event)"
                #currencyBox
              >
              </app-currency-box
              ><br />

              <label style="width: auto">{{
                "form-financial-document.openingBalance" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="readOnly || !editStatePermission"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="OpenBalance"
                (onValueChanged)="onValueChanged()"
              >
              </dx-number-box
              ><br />

              <label style="width: auto">{{
                "form-financial-document.openingBalanceBaseCurrency" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="
                  this.form.controls['CurrencySymbol'].value === 'PLN' ||
                  readOnly ||
                  !editStatePermission
                "
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="OpenBalanceCurrency"
              >
              </dx-number-box
              ><br />

              <label style="width: auto">{{
                "form-financial-document.income" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="true"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="Income"
              >
              </dx-number-box
              ><br />

              <label style="width: auto">{{
                "form-financial-document.outflows" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="true"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="Outflows"
              >
              </dx-number-box
              ><br />

              <label style="width: auto">{{
                "form-financial-document.closingBalance" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="true"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="ClosingBalance"
              >
              </dx-number-box
              ><br />

              <label style="width: auto">{{
                "form-financial-document.closingBalanceBaseCurrency" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="true"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="ClosingBalanceCurrency"
              >
              </dx-number-box
              ><br />
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

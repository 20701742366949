<ng-container *ngIf="openNestFinished">
  <div *ngIf="!dropDownBoxMode; else dropDownMode">
    <div class="top-menu-panel">
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <div class="center-panel-scroll-verticall">
      <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #dropDownMode>
  <dx-drop-down-box
    [(value)]="choosingInvoices"
    valueExpr="InvoiceDocumentId"
    [deferRendering]="false"
    displayExpr="InvoiceDocumentNumber"
    [(opened)]="isGridBoxOpened"
    [dataSource]="invoicesList"
    [showClearButton]="false"
    style="width: 276px"
    (openedChange)="onOpenedChanged($event)"
    (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
    [ngClass]="{ 'input-required': className == true }"
    [readOnly]="readOnly"
  >
    <div *dxTemplate="let data of 'content'" style="height: 280px">
      <div class="row">
        <div class="col-md-12" class="no-padding" *ngIf="isGridBoxOpened">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="258"
            [(selectedRowKeys)]="selectedRows"
            [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            (onRowClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            [(focusedRowIndex)]="focusedRowIndex"
            (onEditorPreparing)="onEditorPreparing($event)"
            (onSelectionChanged)="onSelectionChanged($event)"
            [focusedRowEnabled]="true"
            #gridClass
            [hoverStateEnabled]="true"
            s
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              [mode]="dropDownBoxMode ? 'single' : 'multiple'"
              showCheckBoxesMode="always"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              [width]="event.deviceType != 'mobile' ? 45 : 10"
              cellTemplate="editTemplate"
              [allowSorting]="false"
              alignment="center"
              renderAsync="true"
            ></dxi-column>

            <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
              <dx-button
                class="btn-dx-top-menu-folder-grid"
                icon="icon absui-icon--mode-edit hover-edit-icon"
                [disabled]="readOnly || !this.perABD.editBtn"
                (onClick)="editDocument()"
              ></dx-button>
            </div>
            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              dataField="InvoiceDocumentNumber"
              [allowSorting]="false"
            ></dxi-column>

            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              dataField="DateOfInvoiceDocument"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
            ></dxi-column>

            <dxi-column
              caption="{{ 'bankAccounts.form.currency' | translate }}"
              dataField="CurrencyCode"
              [allowSorting]="false"
            ></dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.TotalNetAmountCurrency' | translate
              }}"
              dataField="TotalNetAmountCurrency"
              cellTemplate="TotalNetAmountCurrency"
              [allowSorting]="false"
              headerCellTemplate="headerCellTemplateCurrency"
            ></dxi-column>

            <div
              *dxTemplate="let data of 'headerCellTemplateCurrency'"
              style="padding-right: 15px"
            >
              {{
                "form-commercial-operation.TotalNetAmountCurrency" | translate
              }}
            </div>

            <div
              *dxTemplate="let data of 'TotalNetAmountCurrency'"
              style="padding-right: 10px"
            >
              {{ data.data.TotalNetAmountCurrency }}
            </div>

            <!-- <dxi-column caption="{{'form-financial-document.payer-type.contractor' | translate}}"
              dataField="CustomerName"></dxi-column> -->
          </dx-data-grid>
        </div>
      </div>
      <wapro-context-menu
        [dataSource]="contextMenuSignal()"
        [width]="200"
        [target]="'#grid' + unicalGuid"
        (onItemClick)="contextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
      ></wapro-context-menu>
      <div class="bottom-drop-box">
        <dx-button
          [ngClass]="{
            'btn-dx-top-menu': !dropDownBoxMode,
            'btn-dx-top-menu-folder': dropDownBoxMode
          }"
          icon="icon absui-icon--add-circle"
          [id]="'btn-drop-add' + unicalGuid"
          [text]="'buttons.add' | translate"
          [disabled]="readOnly || !this.perABD.addBtn"
          (onClick)="openNewRow()"
        ></dx-button>

        <dx-tooltip
          [target]="'#btn-drop-add' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.add" | translate }}
          </div>
        </dx-tooltip>
        <!-- <ng-container *ngTemplateOutlet="topButtons"></ng-container> -->
      </div>
    </div>
  </dx-drop-down-box>
</ng-template>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    class=""
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <div class="inline-block comercial-operation-step-1" style="padding: 0 1px">
      <app-wapro-selected-box
        [(ngModel)]="selectedRows"
        [dataSource]="dataSource"
        [selectedAll]="isAllSelected"
        [selectedAmount]="selectedAmount()"
        (onDeselectAll)="onDeselectAll()"
        (onOnlySelected)="onOnlySelected()"
      ></app-wapro-selected-box>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        stylingMode="text"
        type="default"
        [disabled]="readOnly || !perABD.addBtn"
        (onClick)="openNewRow()"
        name="btn-add-invoice"
        [id]="'btn-add' + unicalGuid"
        data-cy="btn-add-invoice-document"
      ></dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--mode-edit"
        stylingMode="text"
        type="default"
        [id]="'btn-edit' + unicalGuid"
        [disabled]="focusedSelected.length == 0 || readOnly || !perABD.editBtn"
        (onClick)="editDocument($event)"
        data-cy="btn-edit-invoice-document"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--form-items"
        [id]="'btn-show' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="focusedSelected.length == 0 || !perABD.showBtn"
        stylingMode="text"
        type="default"
        (onClick)="mode = 'show'; newRowPopupVisible = true; cd.detectChanges()"
        (disabledChange)="disabledChange($event)"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--highlight-off"
        [id]="'btn-delete' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="
          focusedSelected.length == 0 || readOnly || !perABD.deleteBtn
        "
        stylingMode="text"
        type="default"
        (onClick)="isDelete()"
        data-cy="btn-delete-invoice-document"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu"
        icon="icon absui-icon--sum"
        [text]="'stockHistory.sum' | translate"
        [id]="'sum' + unicalGuid"
        (onClick)="sumOpen()"
        *ngIf="!dropDownBoxMode"
        type="default"
        stylingMode="text"
        [disabled]="!SumDokHandl"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--file-edit"
        [id]="'correction' + unicalGuid"
        class="btn-dx-top-menu"
        [disabled]="focusedSelected.length == 0 || readOnly || isIncomeDocument()"
        [text]="'warehouseDocument.corrections' | translate"
        (onClick)="showCorrect()"
        data-cy="btn-correction-invoice-document"
      ></dx-button>

      <dx-button
        [id]="'advances' + unicalGuid"
        class="btn-dx-top-menu"
        [disabled]="focusedSelected.length == 0 || readOnly"
        [text]="'buffor.Advances' | translate"
        (onClick)="showAdvances()"
        data-cy="btn-advances-invoice-document"
      ></dx-button>

      <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

      <app-btn-export-excel
        [gridReference]="gridBank"
        [nameFile]="'form-financial-document.invoices' | translate"
        url="invoices/documents"
        [paramsObj]="paramsObj"
        *ngIf="permissionEksportExcel"
      ></app-btn-export-excel>

      <!-- <app-product-scaner
        #scaner
        [readOnly]="readOnly"
        [showScanerContainer]="false"
        (onInputChanged)="onScanerInputChanged($event)"
        [keyboardShortcutsDisabled]="
          newRowPopupVisible ||
          isDeleteRow ||
          showRow ||
          editRow ||
          isShowSum ||
          isCorrection ||
          isLabel ||
          isCountrySelect ||
          isChangeJPK ||
          isExpansionListVisible ||
          isVerificationPosition
        "
        [parentId]="unicalGuid"
      >
      </app-product-scaner> -->

      <app-additional-operation
        #additionalOperation
        nameComponent="ComercialOperationComponent"
        (onItemClick)="contextMenuClick($event)"
        (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
      ></app-additional-operation>

      <dx-button
        icon="icon absui-icon--tables"
        [id]="'tables' + unicalGuid"
        class="btn-dx-top-menu"
        *ngIf="additionalTables.length"
        [disabled]="focusedSelected.length == 0 || readOnly"
        [text]="'buttons.tables' | translate"
        (onClick)="isExternalTableVisible = true"
      ></dx-button>
    </div>

    <dx-tooltip
      [target]="'#advances' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buffor.Advances" | translate }} (Ctrl + Z)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#eDocuments' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.eDocuments" | translate }} (Ctrl + E)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#sum' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "stockHistory.sum" | translate }} (Ctrl + S)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#tables' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.tables" | translate }} (Ctrl + Y)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-add' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-edit' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-delete' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-show' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.show" | translate }} (Shift + F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#correction' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "warehouseDocument.corrections" | translate }} (Ctrl + K)
      </div>
    </dx-tooltip>

    <div class="right-header-btn">
      <ng-container *ngIf="exportFakirService.isSelectMode()">
        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px"
          (onClick)="selectChoosed()"
          [id]="'choose' + unicalGuid"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.cancel' | translate }}"
          type="danger"
          (onClick)="cancelChoosed()"
          [id]="'cancel' + unicalGuid"
        ></dx-button>
      </ng-container>

      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        [ngClass]="{ active: localStorageData.sidePanel.isVisible }"
        icon="icon absui-icon--filter"
        (onClick)="toggleDynamicFilters()"
        style="margin-right: 6px"
        [id]="'btn-show-dynamic-filters' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      ></dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        style="margin-right: 6px"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        (click)="getDocuments(); refreshTypeTree(null); selectedRows = []"
        *ngIf="!dropDownBoxMode"
        style="margin-right: 6px"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder comercial-operation-step-2"
        icon="column-chooser"
        (click)="columnsChooserVisible = true"
        *ngIf="permissionToChooseColums && !dropDownBoxMode"
        [id]="'btn-columns' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#choose' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.choose" | translate }} (Enter)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "userGuide.runGuideAgain" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "menu.navigationPanelUser.help" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "refresh" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-columns' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "columnSettings" | translate }}
        </div>
      </dx-tooltip>
    </div>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <div class="panels-flex-container">
        <div
          [ngClass]="{
            without_search_panel: dropDownBoxMode,
            with_search_panel: !dropDownBoxMode
          }"
          #rightPanel
          [style.width]="localStorageData.mainPanelWidth"
        >
          <div class="row">
            <div class="col-md-12">
              <app-custom-dropdown-box
                [openDropDown]="openCustomDropDown"
                [items]="filterCriteria"
                [(selectedItem)]="localStorageData.filter.orderBy"
                [(filterValue)]="filterValue"
                (onValueChanged)="onFilterDataChanged($event)"
                style="margin-right: 5px"
                class="comercial-operation-step-3"
                (onArrowDown)="gridBank.instance.focus()"
                #searchControl
              ></app-custom-dropdown-box>

              <div
                class="d-inline-block comercial-operation-step-4"
                style="line-height: 38px"
              >
                <app-date-range
                  #dateRange
                  [readOnly]="readOnly"
                  [isPopupMode]="true"
                  (onChoosed)="onDateRangeChoosed()"
                  #dataRange
                  [chipsVisible]="true"
                ></app-date-range>

                <!-- <dx-button
                  class="btn-filter-box"
                  [id]="'barcode' + unicalGuid"
                  (onClick)="openSearchBarcode()"
                  [text]="'constractors.barcode' | translate"
                >
                </dx-button> -->

                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="onlySelected"
                >
                  {{ "showSelected" | translate }}:
                  <b>{{ "yes" | translate }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetFiltr('onlySelected')"
                  ></i>
                </dx-button>

                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="
                    typeFilter.invoiceTypeId != -1 && typeFilter.invoiceTypeId
                  "
                  (click)="openRightPanel()"
                >
                  {{ "form-financial-document.type" | translate }}:
                  <b>{{ typeFilter.typeName }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetType()"
                  ></i>
                </dx-button>

                <dx-button
                  class="btn-filter-box"
                  [ngClass]="{ 'chips-btn': customerFilter.CustomerId }"
                  (onClick)="isCustomerVisible = true"
                >
                  {{
                    "form-financial-document.payer-type.contractor" | translate
                  }}
                  <b style="margin-left: 6px" *ngIf="customerFilter.CustomerId">
                    {{ customerFilter.CustomerName }}
                  </b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetContrahentFilter()"
                    *ngIf="customerFilter.CustomerId"
                  ></i>
                </dx-button>

                <app-custom-chips-button
                  *ngIf="
                    localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
                    localStorageData?.advancedFilter?.IsPinned
                  "
                  [name]="'filterPanel.template' | translate"
                  [labelExpr]="'Name'"
                  [valueExpr]="'FilterUsersDefinitionId'"
                  [selectedValueInput]="
                    localStorageData?.advancedFilter?.Name || null
                  "
                  [list]="dynamicFilterUserDefinitions"
                  (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
                ></app-custom-chips-button>

                <app-view-dynamic-quick-filters
                  [dictionaryCode]="DynamicFilterDictionaryCode"
                  [pinnedFilters]="
                    localStorageData.advancedFilter?.PinnedFilters
                  "
                  [values]="
                    localStorageData?.advancedFilter?.UserFilterDefinitionValues
                  "
                  (onValueChanged)="onPinnedValueChanged($event)"
                ></app-view-dynamic-quick-filters>

                <dx-button
                  [id]="'clearValues' + unicalGuid"
                  class="btn-filter-box"
                  icon="icon absui-icon--clear-filter"
                  (onClick)="clearValues()"
                ></dx-button>
                <dx-tooltip
                  [target]="'#clearValues' + unicalGuid"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "clearAllFilters" | translate }} (Ctrl + F10)
                  </div>
                </dx-tooltip>
              </div>

              <dx-data-grid
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                [height]="heightGrid - event.getHeightBoxLicenseInformation()"
                [(selectedRowKeys)]="selectedRows"
                class=""
                [ngClass]="{
                  dropDownBoxClassGrid: dropDownBoxMode,
                  'focus-on-detail': isDetailOnFocus
                }"
                [columnResizingMode]="'widget'"
                (onInitialized)="onInitialized($event)"
                [remoteOperations]="true"
                (onEditorPreparing)="onEditorPreparing($event)"
                (onCellClick)="onCellClick($event)"
                (onFocusedCellChanged)="onFocusedCellChanged($event)"
                (onRowDblClick)="onRowDblClick()"
                [columnAutoWidth]="false"
                [allowColumnResizing]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [id]="'grid' + unicalGuid"
                [autoNavigateToFocusedRow]="true"
                (onContentReady)="onContentReady($event)"
                (onKeyDown)="onKeyDown($event)"
                [(focusedRowIndex)]="focusedRowIndex"
                [allowColumnReordering]="true"
                (onSelectionChanged)="onSelectionChanged($event)"
                [focusedRowEnabled]="true"
                #gridBank
                (onOptionChanged)="onOptionChanged($event)"
                (onRowClick)="onRowClick($event)"
                data-cy="invoices-documents-grid"
                (onContextMenuPreparing)="contextMenuPreparing($event)"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling
                  mode="infinite"
                  columnRenderingMode="virtual"
                  [preloadEnabled]="true"
                ></dxo-scrolling>
                <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
                <dxo-selection
                  [mode]="dropDownBoxMode ? 'single' : 'multiple'"
                  showCheckBoxesMode="always"
                  selectAllMode="page"
                ></dxo-selection>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
                <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

                <dxi-column
                  cellTemplate="markerConfigTemplate"
                  [width]="0"
                  [fixed]="true"
                ></dxi-column>
                <div *dxTemplate="let data of 'markerConfigTemplate'">
                  <div
                    *ngIf="data.data.Semaphore"
                    [ngClass]="{
                      editRowByMe: userId == data.data.Semaphore,
                      editRowByAnotherUser: userId != data.data.Semaphore
                    }"
                  ></div>
                </div>

                <dxi-column
                  caption="S"
                  [width]="26"
                  [allowReordering]="false"
                  [allowSorting]="false"
                  dataField="IsBlocked"
                  cellTemplate="blockTemplate"
                  headerCellTemplate="blockHeaderTemplate"
                  [allowExporting]="false"
                  [fixed]="false"
                ></dxi-column>
                <div
                  *dxTemplate="let data of 'blockHeaderTemplate'"
                  class="text-center"
                >
                  <div
                    class="grid-header-wrapper"
                    [id]="'IsBlocked' + unicalGuid"
                  >
                    S
                  </div>
                  <dx-tooltip
                    [target]="'#IsBlocked' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{
                        "form-commercial-operation.documentStatus" | translate
                      }}
                    </div>
                  </dx-tooltip>
                </div>

                <dxi-column
                  caption="Status"
                  [width]="40"
                  [allowReordering]="false"
                  [allowSorting]="false"
                  dataField="DocumentStatus"
                  cellTemplate="cellStatus"
                  [fixed]="false"
                ></dxi-column>

                <div
                  *dxTemplate="let data of 'cellStatus'"
                  style="text-align: center"
                >
                  <app-status-label
                    [text]="data.data.DocumentStatus"
                    [color]="data.data.StatusStringColor"
                    [backgroundColor]="data.data.StatusBackgroundColor"
                  ></app-status-label>
                </div>

                <div *dxTemplate="let cell of 'blockTemplate'">
                  <div class="status-icon-wrapper">
                    <i
                      class="icon absui-icon--set-lock"
                      *ngIf="
                        cell.data.IsBlocked &&
                        !cell.data.IsSetForAccounting &&
                        !cell.data.IsDeferredIncome &&
                        !cell.data.IsPostedAccounting
                      "
                      [id]="'IsBlocked' + unicalGuid + cell.rowIndex"
                    ></i>
                    <i
                      class="icon absui-icon--partly-check-circle"
                      *ngIf="
                        cell.data.IsSetForAccounting &&
                        !cell.data.IsDeferredIncome &&
                        !cell.data.IsPostedAccounting
                      "
                      [id]="'IsSetForAccounting' + unicalGuid + cell.rowIndex"
                    ></i>
                    <i
                      class="icon absui-icon--check"
                      [id]="'IsPostedAccounting' + unicalGuid + cell.rowIndex"
                      *ngIf="
                        cell.data.IsPostedAccounting &&
                        !cell.data.IsDeferredIncome
                      "
                    ></i>
                    <i
                      *ngIf="cell.data.IsDeferredIncome"
                      [id]="'IsDeferredIncome' + unicalGuid + cell.rowIndex"
                      class="icon absui-icon--status-cancel-pz"
                    ></i>
                  </div>

                  <dx-tooltip
                    [target]="'#IsBlocked' + unicalGuid + cell.rowIndex"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ "form-financial-document.isBlocked" | translate }}
                    </div>
                  </dx-tooltip>
                  <dx-tooltip
                    [target]="
                      '#IsSetForAccounting' + unicalGuid + cell.rowIndex
                    "
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{
                        "form-financial-document.isSetForAccounting" | translate
                      }}
                    </div>
                  </dx-tooltip>
                  <dx-tooltip
                    [target]="
                      '#IsPostedAccounting' + unicalGuid + cell.rowIndex
                    "
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ "form-financial-document.posted" | translate }}
                    </div>
                  </dx-tooltip>
                  <dx-tooltip
                    [target]="'#IsDeferredIncome' + unicalGuid + cell.rowIndex"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{
                        "form-financial-document.isDeferredIncome" | translate
                      }}
                    </div>
                  </dx-tooltip>
                </div>

                <dxi-column
                  caption="F"
                  dataField="IsFiscalDocument"
                  [allowSorting]="false"
                  [allowExporting]="false"
                  width="32"
                  cellTemplate="IsFiscalDocumentTemplate"
                  headerCellTemplate="IsFiscalDocumentHeaderTemplate"
                  [fixed]="false"
                >
                  <div
                    *dxTemplate="let data of 'IsFiscalDocumentHeaderTemplate'"
                    class="text-center"
                  >
                    <div
                      class="grid-header-wrapper"
                      [id]="'IsFiscalDocument' + unicalGuid"
                    >
                      F
                    </div>
                    <dx-tooltip
                      [target]="'#IsFiscalDocument' + unicalGuid"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{
                          "form-financial-document.isFiscalDocument" | translate
                        }}
                      </div>
                    </dx-tooltip>
                  </div>
                  <div *dxTemplate="let cell of 'IsFiscalDocumentTemplate'">
                    <i
                      *ngIf="cell.data.IsFiscalDocument"
                      class="icon absui-icon--bill-credit-card"
                      [id]="'IsFiscalDocument' + unicalGuid + cell.rowIndex"
                    ></i>
                    <dx-tooltip
                      [target]="
                        '#IsFiscalDocument' + unicalGuid + cell.rowIndex
                      "
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{
                          "form-financial-document.isFiscalDocument" | translate
                        }}
                      </div>
                    </dx-tooltip>
                  </div>
                </dxi-column>

                <dxi-column
                  caption="{{ 'form-financial-document.number' | translate }}"
                  [width]="150"
                  [allowSorting]="false"
                  dataField="InvoiceDocumentNumber"
                  cellTemplate="mainColumn"
                  headerCellTemplate="NumbertHeaderTemplate"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy == 'InvoiceDocumentNumber'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'NumbertHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('InvoiceDocumentNumber')"
                  >
                    {{ "form-financial-document.number" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <div *dxTemplate="let data of 'mainColumn'">
                  <span class="hov-underline" (click)="editDocument($event)">
                    {{ data.data.InvoiceDocumentNumber }}
                  </span>
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.date' | translate }}"
                  [allowSorting]="false"
                  dataField="DateOfInvoiceDocument"
                  headerCellTemplate="WarehouseDateOfDocument"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="110"
                  alignment="left"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy == 'DateOfInvoiceDocument'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'WarehouseDateOfDocument'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('DateOfInvoiceDocument')"
                  >
                    {{ "form-financial-document.date" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{
                    'form-financial-document.payer-type.contractor' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="CustomerName"
                  headerCellTemplate="CustomerName"
                  width="200"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy == 'CustomerName'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  cellTemplate="CustomerNameTemp"
                ></dxi-column>
                <div *dxTemplate="let data of 'CustomerName'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('CustomerName')"
                  >
                    {{
                      "form-financial-document.payer-type.contractor"
                        | translate
                    }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <div *dxTemplate="let data of 'CustomerNameTemp'">
                  {{ data.data.CustomerName }}
                </div>

                <dxi-column
                  caption="{{ 'warehouseDocument.label' | translate }}"
                  width="105"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="LabelName"
                  cellTemplate="labelTemplate"
                  renderAsync="true"
                  alignment="left"
                  [fixed]="false"
                ></dxi-column>
                <div *dxTemplate="let data of 'labelTemplate'">
                  <app-label
                    [bgColor]="data.data.LabelBackgroundColor"
                    [color]="data.data.LabelTextColor"
                    [text]="data.data.LabelName"
                  ></app-label>
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.netValue' | translate
                  }}"
                  dataField="TotalNetAmount"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  cellTemplate="TotalNetAmountCell"
                  [fixed]="false"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let cell of 'TotalNetAmountCell'">
                  {{ cell.data.TotalNetAmount | amountFormatter : cell }}
                </div>

                <dxi-column
                  caption="{{ 'buffor.TotalTaxAmount' | translate }}"
                  dataField="TotalTaxAmount"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  cellTemplate="TotalTaxAmount"
                  [fixed]="false"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let cell of 'TotalTaxAmount'">
                  {{
                    cell.data.TotalGrossAmount - cell.data.TotalNetAmount
                      | amountFormatter : cell
                  }}
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.grossValue' | translate
                  }}"
                  dataField="TotalGrossAmount"
                  [allowSorting]="false"
                  cellTemplate="TotalGrossAmountCell"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'TotalGrossAmount'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="TotalGrossAmount"
                  width="150"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>

                <dxi-column
                  caption="{{ 'left' | translate }}"
                  dataField="TotalToPay"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  cellTemplate="TotalToPay"
                  width="150"
                  [fixed]="false"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let cell of 'TotalToPay'">
                  {{ cell.data.TotalToPay | amountFormatter : cell }}
                </div>

                <!-- pozostało w walucie -->
                <dxi-column
                  caption="{{ 'leftCurrency' | translate }}"
                  dataField="TotalToPayCurrency"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  cellTemplate="TotalToPayCurrency"
                  width="150"
                  [fixed]="false"
                >
                  <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let cell of 'TotalToPayCurrency'">
                  {{ cell.data.TotalToPayCurrency | amountFormatter : cell }}
                </div>

                <div *dxTemplate="let data of 'TotalGrossAmount'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('TotalGrossAmount')"
                  >
                    {{
                      "form-commercial-operation.grid.grossValue" | translate
                    }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <div *dxTemplate="let cell of 'TotalGrossAmountCell'">
                  {{ cell.data.TotalGrossAmount | amountFormatter : cell }}
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.ReceiptNumber' | translate
                  }}"
                  dataField="ReceiptNumber"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="150"
                  [fixed]="false"
                ></dxi-column>

                <dxi-column
                  caption="{{ 'comments' | translate }}"
                  dataField="Remarks"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="150"
                  [fixed]="false"
                ></dxi-column>

                <dxi-column
                  caption="{{ 'bankAccounts.form.currency' | translate }}"
                  dataField="CurrencyCode"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.TotalNetAmountCurrency'
                      | translate
                  }}"
                  dataField="TotalNetAmountCurrency"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="150"
                  cellTemplate="TotalNetAmountCurrency"
                  [fixed]="false"
                >
                  <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let cell of 'TotalNetAmountCurrency'">
                  {{
                    cell.data.TotalNetAmountCurrency | amountFormatter : cell
                  }}
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.TotalGrossAmountCurrency'
                      | translate
                  }}"
                  dataField="TotalGrossAmountCurrency"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="150"
                  cellTemplate="TotalGrossAmountCurrency"
                  [fixed]="false"
                >
                  <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let cell of 'TotalGrossAmountCurrency'">
                  {{
                    cell.data.TotalGrossAmountCurrency | amountFormatter : cell
                  }}
                </div>

                <dxi-column
                  caption="{{ 'servicesDevices.saleDate' | translate }}"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="DateOfSell"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="110"
                  alignment="left"
                  [fixed]="false"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.DistinguishCode' | translate
                  }}"
                  dataField="DistinguishCode"
                  [allowSorting]="false"
                  width="100"
                  headerCellTemplate="DistinguishCode"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'DistinguishCode'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'DistinguishCode'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('DistinguishCode')"
                  >
                    {{
                      "form-commercial-operation.DistinguishCode" | translate
                    }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'menu.warehouse' | translate }}"
                  dataField="WarehouseName"
                  [allowSorting]="false"
                  width="150"
                  [fixed]="false"
                ></dxi-column>

                <dxi-column
                  caption="{{ 'form-financial-document.payer' | translate }}"
                  dataField="PayerName"
                  [allowSorting]="false"
                  width="150"
                  headerCellTemplate="PayerName"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'PayerName'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'PayerName'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('PayerName')"
                  >
                    {{ "form-financial-document.payer" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="RD"
                  dataField="Signature"
                  [allowSorting]="false"
                  width="40"
                  headerCellTemplate="SignatureHeaderTemplate"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'PayerName'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                >
                  <div *dxTemplate="let data of 'SignatureHeaderTemplate'">
                    <span [id]="'signature' + unicalGuid">
                      {{ "form-financial-document.signature" | translate }}
                    </span>
                    <dx-tooltip
                      [target]="'#signature' + unicalGuid"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{ "document-type.signature" | translate }}
                      </div>
                    </dx-tooltip>
                  </div>
                </dxi-column>

                <dxi-column
                  caption="{{ 'baseCurrencies.currencyValue' | translate }}"
                  dataField="CurrencyFactor"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  cellTemplate="CurrencyFactor"
                  [fixed]="false"
                >
                  <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let cell of 'CurrencyFactor'">
                  {{ cell.data.CurrencyFactor | amountFormatter : cell : 4 }}
                </div>

                <dxi-column
                  caption="{{ 'constractors.barcode' | translate }}"
                  dataField="EAN"
                  [allowSorting]="false"
                  width="150"
                  headerCellTemplate="EAN"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'EAN'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'EAN'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('EAN')"
                  >
                    {{ "constractors.barcode" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{
                    'form-financial-document.receivedDate' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="DateOfReceived"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="130"
                  alignment="left"
                  headerCellTemplate="DateOfReceived"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'DateOfReceived'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'DateOfReceived'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('DateOfReceived')"
                  >
                    {{ "form-financial-document.receivedDate" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'form-payment.paymentMethod' | translate }}"
                  dataField="PaymentFormName"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="150"
                  [fixed]="false"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.dateOfPaymentt' | translate
                  }}"
                  dataField="DateOfPayment"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="110"
                  alignment="left"
                  [fixed]="false"
                ></dxi-column>

                <dxi-column
                  caption="Nr KSEF"
                  dataField="KsefId"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="150"
                  [fixed]="false"
                ></dxi-column>

                <!-- data utworzenia -->
                <dxi-column
                  caption="{{ 'crmDeliveries.creationDate' | translate }}"
                  [allowSorting]="false"
                  dataField="DateOfCreate"
                  headerCellTemplate="DateOfCreateDocument"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="150"
                  alignment="left"
                  [fixed]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy == 'DateOfCreate'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'DateOfCreateDocument'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('DateOfCreate')"
                  >
                    {{ "crmDeliveries.creationDate" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'workers.attachment' | translate }}"
                  dataField="AttachmentsAmount"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  cellTemplate="attachmentTemplate"
                  width="80"
                  alignment="right"
                  [fixed]="false"
                ></dxi-column>

                <div
                  *dxTemplate="let cell of 'attachmentTemplate'"
                  class="attachmentTemplate"
                >
                  <!-- <i
                    class="icon icon-absui-icon--attach-file attachment-icon grid-attachment"
                    *ngIf="cell.value"
                  ></i> -->
                  {{ cell.value }}
                </div>

                <dxi-column
                  [caption]="userExtensionColumns['Pole1'].Name"
                  dataField="Field1"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole1'] &&
                    userExtensionColumns['Pole1'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole2'].Name"
                  dataField="Field2"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole2'] &&
                    userExtensionColumns['Pole2'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole3'].Name"
                  dataField="Field3"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole3'] &&
                    userExtensionColumns['Pole3'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole4'].Name"
                  dataField="Field4"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole4'] &&
                    userExtensionColumns['Pole4'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole5'].Name"
                  dataField="Field5"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole5'] &&
                    userExtensionColumns['Pole5'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole6'].Name"
                  dataField="Field6"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole6'] &&
                    userExtensionColumns['Pole6'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole7'].Name"
                  dataField="Field7"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole7'] &&
                    userExtensionColumns['Pole7'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole8'].Name"
                  dataField="Field8"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole8'] &&
                    userExtensionColumns['Pole8'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole9'].Name"
                  dataField="Field9"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole9'] &&
                    userExtensionColumns['Pole9'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole10'].Name"
                  dataField="Field10"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  [fixed]="false"
                  *ngIf="
                    userExtensionColumns['Pole10'] &&
                    userExtensionColumns['Pole10'].IsActive
                  "
                  alignment="left"
                ></dxi-column>
              </dx-data-grid>
            </div>
          </div>

          <!--
            [isVisible]="localStorageData.bottomPanel.isVisible" -->
          <app-bottom-panel
            [height]="localStorageData.bottomPanel.height"
            (onResizing)="onResizingBottom($event)"
          >
            <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
          </app-bottom-panel>
          <!--<div class="row">
             <div
              class="bottom-side-panel"
              mwlResizable
              *ngIf="!dropDownBoxMode"
              #bottomSidePanel
              (resizeStart)="onResizeStartBottom()"
              (resizeEnd)="resizeEndBottom()"
              (resizing)="resizingBottom($event)"
            >
              <div
                class="resize-handle-top"
                mwlResizeHandle
                [resizeEdges]="{ top: true }"
                (mouseleave)="mouseLeaveDown()"
                (mouseenter)="mouseEnterDown()"
              ></div>
              <div class="col-md-12 text-right resize-bottop-panel">
                <i
                  class="icon bottom-panel-icon"
                  mwlResizeHandle
                  [resizeEdges]="{ top: true }"
                  [ngClass]="{
                    'absui-icon--arrow-drop-down bottom-icon-resize-down':
                      localStorageData.bottomPanel.isVisible,
                    'absui-icon--arrow-drop-up bottom-icon-resize-up':
                      !localStorageData.bottomPanel.isVisible
                  }"
                  (mousedown)="mousedown()"
                  (mouseup)="mouseup()"
                  (touchend)="mouseup()"
                  (touchstart)="mousedown()"
                ></i>


              </div>
            </div>
          </div> -->
        </div>

        <div
          class="right-side-panel"
          mwlResizable
          *ngIf="!dropDownBoxMode"
          (resizeEnd)="resizeEnd()"
          (resizeStart)="onResizeStart()"
          (resizing)="resizing($event)"
          #leftPanel
          [style.width]="localStorageData.sidePanel.width"
          [style.height]="'calc(100vh - 100px)'"
        >
          <div class="right-panel-btn">
            <!-- <i class="icon text-icon"></i> -->
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="currentResizeIcon"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>
          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>
          <div #leftPanelTab>
            <dx-scroll-view
              width="100%"
              height="calc(100vh - 130px)"
              class="comercial-operation-step-5"
              direction="vertical"
              style="margin-top: -10px"
            >
              <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
            </dx-scroll-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- component with configuration grid column -->
<app-column-chooser
  [gridRef]="gridBank"
  [(visible)]="columnsChooserVisible"
  (columnsChanged)="onColumnsChanged($event)"
></app-column-chooser>

<div *ngIf="loadChildComponent">
  <app-confirm-dialog
    [isVisible]="confirmPerBLBC"
    [confirmHeader]="'bussinesLink.sendingDocuments' | translate"
    confirmText="buffor.confirmPerBLBC"
    [showIcon]="false"
    textAligne="left"
    [kindDialog]="'information'"
    btnConfig="PerBLBC"
    [width]="528"
    (onClosing)="
      sendToBusinessCheckOrBL(
        'businesslink/BlmtDocument/BlmtDocumentSendRecordInit',
        'businesslink/BlmtDocument/BlmtDocumentSend'
      )
    "
    (onRemoving)="
      sendToBusinessCheckOrBL(
        'businesslink/BlmtDocument/BlmtDocumentSendToBCRecordInit',
        'businesslink/BlmtDocument/BlmtDocumentSendToBC'
      )
    "
    (onCanceling)="onClosingConfirmPerBLBC()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    kindDialog="question"
    confirmText="form-commercial-operation.confirmDocumentHasAlreadyBeenSentToTheBuffer"
    [paramsMsg]="paramsDocumentHasAlreadyBeenSentToTheBuffer()"
    [isVisible]="isDocumentHasAlreadyBeenSentToTheBufferVisible"
    (onRemoving)="reUploadToTheBuffer()"
    (onClosing)="isDocumentHasAlreadyBeenSentToTheBufferVisible = false"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [hideGenius]="true"
    [isVisible]="confirmBufferSent"
    [confirmHeader]="'buffor.headerConfirmButton'"
    [genius]="genius"
    [showIcon]="false"
    textAligne="left"
    [kindDialog]="'information'"
    btnConfig="close"
    [width]="490"
    (onClosing)="cancelConfirmBufferSent()"
  ></app-confirm-dialog>

  <app-clipboard
    [isPopupMode]="true"
    [showMode]="true"
    *ngIf="isClipboardVisible"
    [documentId]="focusedSelected[0]?.InvoiceDocumentId"
    documentType="DOCUMENT_INVOICE"
    [isVisible]="true"
    (onClosing)="onClosingClipboard()"
  ></app-clipboard>
  <!-- [parentCurrencyCode]="
      focusedSelected?.length ? focusedSelected[0]?.CurrencyCode : null
    " -->
  <app-correction
    *ngIf="isCorrectionWarehouse"
    [isVisible]="isCorrectionWarehouse"
    (onClosing)="isCorrectionWarehouse = false; cd.detectChanges()"
    [selected]="positionFocused"
    [typeOfDocument]="typeTreeWarehouse"
    (onClosing)="onClosingFormWarehouse()"
    [componentNests]="componentNests"
    title="{{
      'warehouseDocument.correctionsOfTheWarehouseDocument' | translate
    }}"
  ></app-correction>

  <app-new-warehouse-document
    *ngIf="showWarehouseDocument"
    [isVisible]="true"
    (onClosing)="onClosingFormWarehouse()"
    title="{{ 'warehouseDocument.addingWarehouseDocument' | translate }}"
    [selected]="positionFocused"
    [isCorrect]="positionFocused[0]?.IsCorrectionDocument"
    [editMode]="true"
    [readOnly]="true"
  ></app-new-warehouse-document>

  <app-new-documents-orders
    *ngIf="showOrderDocuments"
    [isVisible]="true"
    [mode]="'show'"
    [selectedId]="[positionFocused[0]?.OrderDocumentId]"
    [componentNests]="null"
    [isCurrentlyEdit]="
      positionFocused?.length ? !!positionFocused[0]?.Semaphore : false
    "
    [readOnly]="true"
    (onClosed)="onClosingFormWarehouse()"
  ></app-new-documents-orders>

  <app-new-task
    [isVisible]="isTask"
    mode="edit"
    (onClosing)="onTaskClosing()"
    [readOnly]="readOnly"
    [selectedId]="taskSelectedId"
    *ngIf="isTask"
    (onInserted)="onTaskClosing()"
  ></app-new-task>

  <app-dependent-postition
    *ngIf="showRelatedItems"
    [isVisible]="showRelatedItems"
    [readOnly]="readOnly"
    [displayInfo]="true"
    documentContext="DOCUMENT_INVOICE_POSITION"
    [productId]="positionFocused[0]?.ProductId"
    [serialNo]="positionFocused[0]?.SerialNumber"
    [expireDate]="positionFocused[0]?.ExpirationDate"
    [docPositionId]="positionFocused[0]?.WarehouseDocumentPositionId"
    (onClosed)="showRelatedItems = false; cd.detectChanges()"
  ></app-dependent-postition>

  <app-confirm-dialog
    [isVisible]="isDeleteRow"
    (onRemoving)="delete($event)"
    (onClosing)="closeConfirm()"
    *ngIf="isDeleteRow"
    [checkBoxList]="checkBoxListConfirmDelete"
    confirmText="form-commercial-operation.doYouWantDelete"
    [paramsMsg]="paramsConfirmDelete"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isAddDescription"
    (onRemoving)="addQuestion()"
    *ngIf="isAddDescription"
    (onClosing)="isAddDescription = false; cd.detectChanges()"
    confirmText="warehouseDocument.theDocumentHasAcorrection"
    kindDialog="question"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isOutcomeDocument"
    (onRemoving)="addQuestionIncome()"
    *ngIf="isOutcomeDocument"
    (onClosing)="isOutcomeDocument = false; cd.detectChanges()"
    confirmText="form-commercial-operation.modificationOfTheIncomeDocumentWillNotAffectTheIssueDocumentsValuedAtPurchasePricesContinue"
    kindDialog="question"
  ></app-confirm-dialog>

  <app-change-jpk
    [isVisible]="isChangeJPK"
    *ngIf="isChangeJPK"
    (onClosing)="isChangeJPK = false; cd.detectChanges()"
    [selectedRows]="selectedRows"
  ></app-change-jpk>

  <app-confirm-dialog
    [isVisible]="isCloneDocument"
    *ngIf="isCloneDocument"
    (onRemoving)="cloneDocument()"
    (onClosing)="isCloneDocument = false; cd.detectChanges()"
    [confirmText]="
      selectedRows.length < 1
        ? 'warehouse.doYouWantToDuplicateTheDocument'
        : 'form-commercial-operation.shouldIDuplicateSelectedDocuments'
    "
    kindDialog="question"
    [paramsMsg]="paramsConfirmClone"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isSetFiscal"
    *ngIf="isSetFiscal"
    (onRemoving)="setFiscal(selectedRows, true)"
    (onClosing)="closeSetFiscalDialog()"
    [confirmText]="
      setFiscalMode == 'setFiscal'
        ? selectedRows.length
          ? setFiscalConfirmTextSelect
          : setFiscalConfirmTextFocus
        : selectedRows.length
        ? setNonFiscalConfirmTextSelect
        : setNonFiscalConfirmTextFocus
    "
    kindDialog="question"
  ></app-confirm-dialog>

  <app-country-box
    *ngIf="!dropDownBoxMode && isCountrySelect"
    [popUpMode]="true"
    [isVisible]="isCountrySelect"
    [advancedChooser]="true"
    (onClosed)="isCountrySelect = false; cd.detectChanges()"
    (onChoosed)="onChoosedCountry($event)"
  ></app-country-box>

  <wapro-context-menu
    [dataSource]="contextMenuSignal()"
    [width]="200"
    [target]="'#grid' + unicalGuid"
    (onItemClick)="contextMenuClick($event)"
    (onItemRendered)="event.onItemRendered($event)"
  ></wapro-context-menu>

  <app-position-veryfication
    [isVisible]="isVerificationPosition"
    [isOrderComponent]="true"
    [documentNumber]="
      focusedSelected && focusedSelected?.length > 0
        ? focusedSelected[0]?.InvoiceDocumentNumber
        : 0
    "
    [documentId]="
      focusedSelected?.length > 0 ? focusedSelected[0]?.InvoiceDocumentId : 0
    "
    typeDocument="invoices"
    [detalisDocument]="focusedSelected?.length > 0 ? focusedSelected[0] : null"
    (onClosing)="onPositionVerificationClosing($event)"
    [readOnly]="readOnly"
    *ngIf="isVerificationPosition"
  ></app-position-veryfication>

  <app-pz-postponed
    [isVisible]="isPzPostponed"
    (onClosing)="onPostponedClosing()"
    [readOnly]="readOnly"
    document="invoice"
    *ngIf="isPzPostponed"
    [isOutcome]="isOutcome"
  ></app-pz-postponed>

  <app-invoices-status
    [isVisible]="isChangesStatus"
    [readOnly]="readOnly"
    title="{{
      'form-commercial-operation.statusChangeForSelectedInvoices' | translate
    }}"
    (onClosing)="isChangesStatus = false"
    *ngIf="isChangesStatus"
    (onInserted)="onInsertedStatus()"
  ></app-invoices-status>

  <app-labels
    [popUpMode]="true"
    (onChoosed)="onChoosedLabel()"
    (onClosed)="isLabel = false"
    [isVisible]="isLabel"
    [readOnly]="readOnly"
    IsForInvoiceDoc="true"
    *ngIf="isLabel"
    url="invoices/selection/label"
  ></app-labels>

  <app-correction-invoice
    [isVisible]="true"
    [componentNests]="componentNests"
    [selectedDocument]="selectedRows"
    [selected]="focusedSelected"
    [readOnly]="readOnly"
    [typeOfDocument]="typeTree"
    *ngIf="isCorrection"
    (onClosing)="
      onClosingCorrection();
      event.setFocus(gridBank);
      isCorrection = false;
      cd.detectChanges()
    "
    title="{{
      'form-commercial-operation.correctionsOfTheInvoiceDocument' | translate
    }}"
    (onPrintCorrectionAfterSave)="onPrintCorrectionAfterSave($event)"
    [parentCurrencyCode]="
      focusedSelected?.length ? focusedSelected[0]?.CurrencyCode : null
    "
    (onRefreshCard)="getDocuments()"
  ></app-correction-invoice>

  <app-advances
    [isVisible]="isAdvance"
    [selected]="focusedSelected"
    [selectedDocument]="selectedRows"
    [readOnly]="readOnly"
    *ngIf="isAdvance"
    (onRefreshCard)="refreshCard($event)"
    (onFocusedRow)="onFocusedRowSet($event)"
    (onClosing)="
      onClosingCorrection();
      event.setFocus(gridBank);
      isAdvance = false;
      cd.detectChanges()
    "
    (onPrintAdvanceAfterSave)="onPrintAdvanceAfterSave($event)"
    (onOpenCorection)="onOpenCorection()"
    title="{{ 'form-commercial-operation.advanceInvoices' | translate }}"
  ></app-advances>

  <!-- pola dodatkowe -->
  <app-form-user-extensions
    [isVisible]="visibleUserExtensions"
    [groupCode]="USER_EXTENSIONS_GROUP_CODE"
    [readOnly]="readOnly || onlyShow"
    [groupChanged]="true"
    title="{{ 'categories.externalFields' | translate }}"
    (onChoosed)="setAdditionalFields($event)"
    (onClosing)="onClosingUserExtensions()"
    [userExtensionsValue]="additionalFieldsList"
    #isValidUserExtensions
  ></app-form-user-extensions>

  <app-confirm-dialog
    [isVisible]="isBlockRow"
    (onRemoving)="blockUnblockDocument()"
    (onClosing)="isBlockRow = false"
    [confirmText]="textBlock"
    kindDialog="question"
    *ngIf="isBlockRow"
  ></app-confirm-dialog>

  <dx-popup
    position="center"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="true"
    title="{{ 'form-financial-document.addRemarksToTheDocument' | translate }}"
    [(visible)]="isShowDocumentDescription"
    [width]="event.deviceType != 'mobile' ? 500 : '100%'"
    [height]="188"
    *ngIf="isShowDocumentDescription"
    [maxHeight]="event.maxHeightSignal()"
    (onHidden)="Remarks = ''"
    [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
  >
    <div *dxTemplate="let content of 'content'">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.save' | translate }}"
          type="success"
          style="margin-right: 6px"
          (onClick)="onSave()"
          [id]="'add' + unicalGuid"
          *ngIf="!readOnly"
        ></dx-button>
        <dx-button
          text="{{ 'buttons.cancel' | translate }}"
          type="danger"
          (onClick)="isShowDocumentDescription = false"
          [id]="'cancel' + unicalGuid"
        ></dx-button>
      </div>
      <dx-scroll-view
        width="100%"
        height="100%"
        direction="vertical"
        #scrollView
      >
        <app-description-dictionary
          [dropDownBoxMode]="true"
          type="U"
          heightArea="100"
          [widthArea]="460"
          title="{{ 'comments' | translate }}"
          [(ngModel)]="Remarks"
          [readOnly]="readOnly"
        ></app-description-dictionary>
      </dx-scroll-view>
    </div>
  </dx-popup>

  <app-table-user-extension
    tableGroupCode="Dokument_handlowy"
    (onClosing)="isExternalTableVisible = false"
    [isVisible]="isExternalTableVisible"
    [isPopupMode]="true"
  ></app-table-user-extension>

  <app-table-data-user-extension
    [readOnly]="readOnly"
    [isVisible]="isShortcutTableOpened"
    tableGroupCode="Dokument_handlowy"
    (onClosing)="isShortcutTableOpened = false; shortcutTable = null"
    [table]="shortcutTable"
  ></app-table-data-user-extension>
</div>

<ng-template #nonDefaultTemplate>
  <i class="icon absui-icon--close close-grid"></i>
</ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="
    newRowPopupVisible ||
    isDeleteRow ||
    showRow ||
    editRow ||
    isShowSum ||
    isCorrection ||
    isLabel ||
    isCountrySelect ||
    isChangeJPK ||
    isExpansionListVisible ||
    isVerificationPosition ||
    isAdvance ||
    notesService.isNotesFocused()
  "
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

<!-- mobile template -->
<ng-template #mobileTemplate>
  <div class="mobile-search-box-container">
    <app-custom-dropdown-box
      [items]="filterCriteria"
      [(selectedItem)]="valueCriteria"
      [(filterValue)]="filterValue"
      (onValueChanged)="onFilterDataChanged($event)"
      *ngIf="!dropDownBoxMode"
      (onArrowDown)="gridBank.instance.focus()"
      #searchControl
    ></app-custom-dropdown-box>

    <dx-button type="normal">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--sort-ascending': localStorageData.filter.order === 'ASC',
          'absui-icon--sort-descending': localStorageData.filter.order !== 'ASC'
        }"
        (click)="switchOrder(); getDocuments()"
      ></i>
    </dx-button>
    <!--
    <i
      class="icon"
      style="margin-left: 12px"
      [ngClass]="{
        'absui-icon--sort-ascending': localStorageData.filter.order === 'ASC',
        'absui-icon--sort-descending': localStorageData.filter.order !== 'ASC'
      }"
      (click)="switchOrder(); getDocuments()"
    ></i> -->
  </div>

  <dx-scroll-view
    width="100%"
    height="35"
    [scrollByContent]="true"
    direction="horizontal"
    showScrollbar="never"
  >
    <div
      class="d-flex align-items-center"
      style="width: 1000px; padding-top: 2px"
    >
      <app-date-range
        #dateRange
        [readOnly]="readOnly"
        [isPopupMode]="true"
        (onChoosed)="onDateRangeChoosed()"
        #dataRange
        [chipsVisible]="true"
      ></app-date-range>
      <dx-button class="btn-filter-box chips-btn" *ngIf="onlySelected">
        {{ "showSelected" | translate }}:
        <b>{{ "yes" | translate }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetFiltr('onlySelected')"
        ></i>
      </dx-button>
      <dx-button
        class="btn-filter-box chips-btn"
        *ngIf="typeFilter.invoiceTypeId != null"
        (click)="openRightPanel()"
      >
        {{ "form-financial-document.type" | translate }}:
        <b>{{ typeFilter.typeName }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetType()"
        ></i>
      </dx-button>

      <app-custom-chips-button
        *ngIf="
          localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
          localStorageData?.advancedFilter?.IsPinned
        "
        [name]="'filterPanel.template' | translate"
        [labelExpr]="'Name'"
        [valueExpr]="'FilterUsersDefinitionId'"
        [selectedValueInput]="localStorageData?.advancedFilter?.Name || null"
        [list]="dynamicFilterUserDefinitions"
        (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
      ></app-custom-chips-button>

      <app-view-dynamic-quick-filters
        [dictionaryCode]="DynamicFilterDictionaryCode"
        [pinnedFilters]="localStorageData.advancedFilter?.PinnedFilters"
        [values]="localStorageData?.advancedFilter?.UserFilterDefinitionValues"
        (onValueChanged)="onPinnedValueChanged($event)"
      ></app-view-dynamic-quick-filters>
    </div>
  </dx-scroll-view>

  <dx-data-grid
    (onEditorPreparing)="onEditorPreparing($event)"
    [dataSource]="dataSource"
    [wordWrapEnabled]="false"
    [showBorders]="true"
    [height]="heightGrid - 60"
    [(selectedRowKeys)]="selectedRows"
    [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
    [columnResizingMode]="'widget'"
    (onInitialized)="onInitialized($event)"
    [remoteOperations]="true"
    (onRowDblClick)="onRowDblClick()"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    [id]="'grid-mobile' + unicalGuid"
    [autoNavigateToFocusedRow]="true"
    (onContentReady)="onContentReady($event)"
    (onKeyDown)="onKeyDown($event)"
    [(focusedRowIndex)]="focusedRowIndex"
    (onSelectionChanged)="onSelectionChanged($event)"
    [focusedRowEnabled]="true"
    #gridBank
    (onOptionChanged)="onOptionChanged($event)"
    class="main-mobile-grid"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="virtual"
      columnRenderingMode="virtual"
      preloadEnabled="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxo-selection
      [mode]="modeSelectionMobile"
      showCheckBoxesMode="always"
      selectAllMode="page"
    ></dxo-selection>

    <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="Name"
      cellTemplate="mainColumn"
      width="200"
    ></dxi-column>

    <div
      appPreventRightClick
      (touchend)="onTouchEnd()"
      (touchstart)="onTouchStart($event)"
      *dxTemplate="let data of 'mainColumn'"
    >
      <div style="display: flex">
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == data.data.Semaphore,
            'semafore-edit-by-another-user':
              userId != data.data.Semaphore && data.data.Semaphore != null
          }"
        ></div>
        <div
          class="content-main-grid"
          [ngClass]="{
            'content-main-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <span class="hov-underline-mobile" (click)="editDocument($event)">
            {{ data.data.InvoiceDocumentNumber }}
          </span>
          <br />
          <label class="mobile-grid-label">
            {{ "form-financial-document.date" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.DateOfInvoiceDocument | date : event.dateFormat }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "form-financial-document.payer-type.contractor" | translate }}:
          </label>

          <p class="mobile-grid-text">
            {{
              data.data.CustomerName?.length > 25
                ? (data.data.CustomerName | slice : 0 : 25) + "..."
                : data.data.CustomerName
            }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "form-commercial-operation.grid.netValue" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.TotalNetAmount | amountFormatter : null }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "form-commercial-operation.grid.grossValue" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.TotalGrossAmount | amountFormatter : null }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ "bankAccounts.form.currency" | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.CurrencyCode != "" ? data.data.CurrencyCode : "PLN" }}
          </p>
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px)"
            (onClick)="showContextMenuMobile(data.data)"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenuMobileSignal()"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>

  <app-mobile-details-panel
    [isVisible]="isDetails"
    [semaphore]="focusedSelected[0]?.Semaphore"
    (onClosing)="isDetails = false"
  >
    <div headerData>
      <p *ngIf="focusedSelected[0]" class="nameSelectedPosition">
        {{ focusedSelected[0]?.InvoiceDocumentNumber }}
      </p>

      <label class="details-label">
        {{ "form-financial-document.date" | translate }}:
      </label>
      <p class="details-text">
        {{
          focusedSelected[0]?.DateOfInvoiceDocument | date : event.dateFormat
        }}
      </p>

      <label class="details-label">
        {{ "form-financial-document.payer-type.contractor" | translate }}:
      </label>
      <p class="details-text">{{ focusedSelected[0]?.CustomerName }}</p>
      <br />

      <label class="details-label">
        {{ "form-commercial-operation.grid.netValue" | translate }}:
      </label>
      <p class="details-text">
        {{ focusedSelected[0]?.TotalNetAmount | amountFormatter : null }}
      </p>

      <label class="details-label">
        {{ "form-commercial-operation.grid.grossValue" | translate }}:
      </label>
      <p class="details-text">
        {{ focusedSelected[0]?.TotalGrossAmount | amountFormatter : null }}
      </p>

      <label class="details-label">
        {{ "bankAccounts.form.currency" | translate }}:
      </label>
      <p class="details-text">
        {{
          focusedSelected[0]?.CurrencyCode != ""
            ? focusedSelected[0]?.CurrencyCode
            : "PLN"
        }}
      </p>
    </div>

    <ng-container moreDetails>
      <div>
        <table style="width: 100%">
          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.ReceiptNumber" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0]?.ReceiptNumber }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "bankAccounts.form.currency" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0]?.CurrencyCode }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{
                  "form-commercial-operation.TotalNetAmountCurrency"
                    | translate
                }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.TotalNetAmountCurrency
                    | amountFormatter : null
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{
                  "form-commercial-operation.TotalGrossAmountCurrency"
                    | translate
                }}
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.TotalGrossAmountCurrency
                    | amountFormatter : null
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "servicesDevices.saleDate" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0]?.DateOfSell | date : event.dateFormat }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.DistinguishCode" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0]?.DistinguishCode }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.payer" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0]?.PayerName }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "baseCurrencies.currencyValue" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.CurrencyFactor
                    | amountFormatter : null : 4
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "constractors.barcode" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0]?.EAN }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.receivedDate" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.DateOfReceived | date : event.dateFormat
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-payment.paymentMethod" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0]?.PaymentFormName }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.dateOfPaymentt" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.DateOfPayment | date : event.dateFormat
                }}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 25px">
        <div class="c-frame-row">
          <h5>{{ "comments" | translate }}</h5>
        </div>
        <p class="details-text">{{ focusedSelected[0]?.Remarks }}</p>
      </div>
    </ng-container>
    <ng-container *ngTemplateOutlet="bottomTab" bottomTab></ng-container>
  </app-mobile-details-panel>

  <div class="details-mobile-panel" *ngIf="isDetails && false">
    <header>
      <div class="d-flex flex-row">
        <div class="left-header">
          <i
            class="icon absui-backe"
            style="position: relative; top: 4px; margin-right: 10px"
            (click)="isDetails = false"
          ></i>
        </div>
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == focusedSelected[0]?.Semaphore,
            'semafore-edit-by-another-user':
              userId != focusedSelected[0]?.Semaphore &&
              focusedSelected[0]?.Semaphore != null
          }"
        ></div>
        <div class="right-details">
          <p *ngIf="focusedSelected[0]" class="nameSelectedPosition">
            {{ focusedSelected[0]?.InvoiceDocumentNumber }}
          </p>

          <label class="details-label">
            {{ "form-financial-document.date" | translate }}:
          </label>
          <p class="details-text">
            {{
              focusedSelected[0]?.DateOfInvoiceDocument
                | date : event.dateFormat
            }}
          </p>

          <label class="details-label">
            {{ "form-financial-document.payer-type.contractor" | translate }}:
          </label>
          <p class="details-text">{{ focusedSelected[0]?.CustomerName }}</p>
          <br />

          <label class="details-label">
            {{ "form-commercial-operation.grid.netValue" | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0]?.TotalNetAmount | amountFormatter : null }}
          </p>

          <label class="details-label">
            {{ "form-commercial-operation.grid.grossValue" | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0]?.TotalGrossAmount | amountFormatter : null }}
          </p>

          <label class="details-label">
            {{ "bankAccounts.form.currency" | translate }}:
          </label>
          <p class="details-text">
            {{
              focusedSelected[0]?.CurrencyCode != ""
                ? focusedSelected[0]?.CurrencyCode
                : "PLN"
            }}
          </p>
        </div>
      </div>
    </header>

    <div
      class="circle-showMore"
      (click)="isShowMoreDetails = !isShowMoreDetails"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-less': isShowMoreDetails,
          'absui-icon--expand-more': !isShowMoreDetails
        }"
      ></i>
    </div>

    <div class="more-mobile-details" *ngIf="isShowMoreDetails">
      <div>
        <div class="c-frame-row">
          <h5>{{ "tasks.details" | translate }}</h5>
        </div>
      </div>

      <div>
        <table style="width: 100%">
          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.ReceiptNumber" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0]?.ReceiptNumber }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "bankAccounts.form.currency" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0]?.CurrencyCode }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{
                  "form-commercial-operation.TotalNetAmountCurrency"
                    | translate
                }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.TotalNetAmountCurrency
                    | amountFormatter : null
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{
                  "form-commercial-operation.TotalGrossAmountCurrency"
                    | translate
                }}
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.TotalGrossAmountCurrency
                    | amountFormatter : null
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "servicesDevices.saleDate" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0]?.DateOfSell | date : event.dateFormat }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.DistinguishCode" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0]?.DistinguishCode }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.payer" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0]?.PayerName }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "baseCurrencies.currencyValue" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.CurrencyFactor
                    | amountFormatter : null : 4
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "constractors.barcode" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ focusedSelected[0]?.EAN }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-financial-document.receivedDate" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.DateOfReceived | date : event.dateFormat
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-payment.paymentMethod" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ focusedSelected[0]?.PaymentFormName }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.dateOfPaymentt" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  focusedSelected[0]?.DateOfPayment | date : event.dateFormat
                }}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 25px">
        <div class="c-frame-row">
          <h5>{{ "comments" | translate }}</h5>
        </div>
        <p class="details-text">{{ focusedSelected[0]?.Remarks }}</p>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
  </div>
</ng-template>

<ng-template #bottomTab>
  <dx-tab-panel
    #tabPanel
    [items]="bottomPanelTabs"
    [(selectedIndex)]="selectedtabIndex"
    (onTitleClick)="onItemClickTab()"
    (onSelectionChanged)="onSelectionTabChanged()"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    class="bottomTabPanelMobile comercial-operation-step-6"
  >
    <div *dxTemplate="let name of 'title'; let i = index">
      <div
        [id]="'tabToolTip' + i + unicalGuid"
        [attr.data-cy]="'invoices-tabs-' + name"
      >
        <span>{{ name }}</span>
      </div>
    </div>

    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'BusinessLink'">
          <dx-data-grid
            class="sad-position"
            [columnResizingMode]="'widget'"
            [showBorders]="true"
            [allowColumnReordering]="true"
            [dataSource]="detailsKSeFDocument"
            [wordWrapEnabled]="false"
            style="width: 100%; margin-bottom: 10px"
            [(focusedRowIndex)]="KSeFDocumentFocusedRowIndex"
            [focusedRowEnabled]="true"
            [height]="heightBottomGrid - 10"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [allowColumnResizing]="event.deviceType != 'mobile'"
            (onOptionChanged)="event.onOptionChanged($event)"
            [id]="'detailsKSeFDocument' + unicalGuid"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection [mode]="'single'"></dxo-selection>

            <dxi-column
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              caption="{{ 'buffor.StatusBusinessCheck' | translate }}"
              alignment="center"
            >
              <dxi-column
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="{{ 'buffor.StatusBusinessCheck' | translate }}"
                dataField="BusinessCheckStatus"
                cellTemplate="BusinessCheckStatusTemplate"
                width="180"
              ></dxi-column>
              <div *dxTemplate="let data of 'BusinessCheckStatusTemplate'">
                <div class="d-flex align-items-center">
                  <div
                    [id]="'stock' + unicalGuid + data.rowIndex"
                    [ngClass]="{
                      statusNOTREADY:
                        data.data.BusinessCheckStatusStatus != '' &&
                        data.data.BusinessCheckStatusCode === 'NOTREADY',
                      statusGETOnlyForBusinessLinkStatus:
                        data.data.BusinessCheckStatus != '' &&
                        data.data.BusinessCheckStatusCode === 'GET',
                      statusERROR:
                        data.data.BusinessCheckStatus != '' &&
                        data.data.BusinessCheckStatusCode === 'ERROR',
                      status_WARNING_WAITSTAT_UNAVAILABLE_OnlyForBusinessLinkStatus:
                        (data.data.BusinessCheckStatus != '' &&
                          data.data.BusinessCheckStatusCode === 'WARNING') ||
                        data.data.BusinessCheckStatusCode === 'WAITSTAT' ||
                        data.data.BusinessCheckStatusCode === 'UNAVAILABLE',
                      statusSENT:
                        data.data.BusinessCheckStatus != '' &&
                        data.data.BusinessCheckStatusCode.startsWith('SENT'),
                      statusOthers:
                        data.data.BusinessCheckStatus != '' &&
                        data.data.BusinessCheckStatusCode != 'NOTREADY' &&
                        data.data.BusinessCheckStatusCode != 'GET' &&
                        data.data.BusinessCheckStatusCode != 'ERROR' &&
                        data.data.BusinessCheckStatusCode != 'WARNING' &&
                        data.data.BusinessCheckStatusCode != 'WAITSTAT' &&
                        data.data.BusinessCheckStatusCode != 'UNAVAILABLE' &&
                        data.data.BusinessCheckStatusCode != 'SENT'
                    }"
                  ></div>
                  <div>{{ data.data.BusinessCheckStatus }}</div>
                </div>
              </div>

              <dxi-column
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="{{ 'buffor.dateAndTime' | translate }}"
                [format]="{ type: event.dateFormat + ' HH:mm' }"
                dataField="BusinessCheckDate"
                dataType="date"
                width="150"
              ></dxi-column>
            </dxi-column>

            <dxi-column
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              caption="{{ 'buffor.StatusBusinessLink' | translate }}"
              alignment="center"
            >
              <dxi-column
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="{{ 'buffor.StatusBusinessLink' | translate }}"
                dataField="BusinessLinkStatus"
                cellTemplate="BusinessLinkStatusTemplate"
                width="180"
              ></dxi-column>
              <div *dxTemplate="let data of 'BusinessLinkStatusTemplate'">
                <div class="d-flex align-items-center">
                  <div
                    [id]="'stock' + unicalGuid + data.rowIndex"
                    [ngClass]="{
                      statusNOTREADY:
                        data.data.BusinessLinkStatus != '' &&
                        data.data.BusinessLinkStatusCode === 'NOTREADY',
                      statusGETOnlyForBusinessLinkStatus:
                        data.data.BusinessLinkStatus != '' &&
                        data.data.BusinessLinkStatusCode === 'GET',
                      statusERROR:
                        data.data.BusinessLinkStatus != '' &&
                        data.data.BusinessLinkStatusCode === 'ERROR',
                      status_WARNING_WAITSTAT_UNAVAILABLE_OnlyForBusinessLinkStatus:
                        (data.data.BusinessLinkStatus != '' &&
                          data.data.BusinessLinkStatusCode === 'WARNING') ||
                        data.data.BusinessLinkStatusCode === 'WAITSTAT' ||
                        data.data.BusinessLinkStatusCode === 'UNAVAILABLE',
                      statusSENT:
                        data.data.BusinessLinkStatus != '' &&
                        data.data.BusinessLinkStatusCode.startsWith('SENT'),
                      statusOthers:
                        data.data.BusinessLinkStatus != '' &&
                        data.data.BusinessLinkStatusCode != 'NOTREADY' &&
                        data.data.BusinessLinkStatusCode != 'GET' &&
                        data.data.BusinessLinkStatusCode != 'ERROR' &&
                        data.data.BusinessLinkStatusCode != 'WARNING' &&
                        data.data.BusinessLinkStatusCode != 'WAITSTAT' &&
                        data.data.BusinessLinkStatusCode != 'UNAVAILABLE' &&
                        data.data.BusinessLinkStatusCode != 'SENT'
                    }"
                  ></div>
                  <div>{{ data.data.BusinessLinkStatus }}</div>
                </div>
              </div>

              <dxi-column
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="{{ 'buffor.dateAndTime' | translate }}"
                [format]="{ type: event.dateFormat + ' HH:mm' }"
                dataField="BusinessLinkDate"
                dataType="date"
                width="150"
              ></dxi-column>
            </dxi-column>

            <dxi-column
              [hidingPriority]="8"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              caption="{{ 'buffor.StatusKSeF' | translate }}"
              alignment="center"
            >
              <dxi-column
                [hidingPriority]="7"
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="{{ 'buffor.StatusKSeF' | translate }}"
                dataField="KsefStatus"
                cellTemplate="KsefStatusTemplate"
                width="200"
              ></dxi-column>
              <div *dxTemplate="let data of 'KsefStatusTemplate'">
                <div class="d-flex align-items-center">
                  <div
                    [id]="'stock' + unicalGuid + data.rowIndex"
                    [ngClass]="{
                      statusNOTREADY:
                        data.data.KsefStatus != '' &&
                        data.data.KsefStatusCode === 'NOTREADY',
                      statusERROR:
                        data.data.KsefStatus != '' &&
                        data.data.KsefStatusCode === 'ERROR',
                      status_WARNING_WAITSTAT_UNAVAILABLE_OnlyForBusinessLinkStatus:
                        (data.data.KsefStatus != '' &&
                          data.data.KsefStatusCode === 'WARNING') ||
                        data.data.BusinessLinkStatusCode === 'WAITSTAT' ||
                        data.data.KsefStatusCode === 'UNAVAILABLE',
                      statusSENT:
                        data.data.KsefStatus != '' &&
                        data.data.KsefStatusCode === 'SENT',
                      statusOthers:
                        data.data.KsefStatus != '' &&
                        data.data.KsefStatusCode != 'NOTREADY' &&
                        data.data.KsefStatusCode != 'GET' &&
                        data.data.KsefStatusCode != 'ERROR' &&
                        data.data.KsefStatusCode != 'WARNING' &&
                        data.data.KsefStatusCode != 'WAITSTAT' &&
                        data.data.KsefStatusCode != 'UNAVAILABLE' &&
                        data.data.KsefStatusCode != 'SENT'
                    }"
                  ></div>
                  <div>{{ data.data.KsefStatus }}</div>
                </div>
              </div>

              <dxi-column
                [hidingPriority]="6"
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="{{ 'buffor.dateAndTime' | translate }}"
                [format]="{ type: event.dateFormat + ' HH:mm' }"
                dataField="KsefDate"
                dataType="date"
                width="150"
              ></dxi-column>
            </dxi-column>

            <dxi-column
              [hidingPriority]="5"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              caption="{{ 'buffor.confirmationToKSeF' | translate }}"
              alignment="center"
            >
              <dxi-column
                [hidingPriority]="4"
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="{{ 'buffor.referenceNumberKSeF' | translate }}"
                dataField="KsefReferenceNumber"
                width="250"
              ></dxi-column>
              <dxi-column
                [hidingPriority]="3"
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="{{ 'buffor.dateAndTime' | translate }}"
                [format]="{ type: event.dateFormat + ' HH:mm' }"
                dataField="KsefConfirmDate"
                dataType="date"
                width="150"
              ></dxi-column>
            </dxi-column>

            <dxi-column
              [hidingPriority]="2"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              caption="UPO"
              alignment="center"
            >
              <dxi-column
                [hidingPriority]="1"
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="{{ 'buffor.referenceNumber' | translate }}"
                dataField="KsefUpoReference"
                width="250"
              ></dxi-column>
              <dxi-column
                [hidingPriority]="0"
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                caption="URL"
                dataField="KsefUpoUrl"
                width="300"
              ></dxi-column>
            </dxi-column>
          </dx-data-grid>
        </div>
        <div *ngSwitchCase="'buffor.controlBusinessCheck' | translate">
          <dx-data-grid
            [columnResizingMode]="'widget'"
            [showBorders]="true"
            [allowColumnReordering]="true"
            [dataSource]="controlBusinessCheckList"
            [wordWrapEnabled]="false"
            style="width: 100%; margin-bottom: 10px"
            [focusedRowEnabled]="true"
            [height]="heightBottomGrid - 10"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [allowColumnResizing]="event.deviceType != 'mobile'"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection [mode]="'single'"></dxo-selection>

            <dxi-column
              [caption]="'form-commercial-operation.grid.Lp' | translate"
              width="32"
              dataField="lp"
              *ngIf="
                event.deviceType != 'mobile' ||
                controlBusinessCheckList?._items?.length > 0
              "
              [allowSorting]="false"
              [renderAsync]="true"
            ></dxi-column>

            <dxi-column
              [hidingPriority]="3"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              caption="{{ 'buffor.ControlStatus' | translate }}"
              dataField="StatusDescription"
              cellTemplate="StatusCodeTemplate"
            ></dxi-column>
            <div *dxTemplate="let data of 'StatusCodeTemplate'">
              <div class="d-flex align-items-center">
                <div
                  [id]="'stock' + unicalGuid + data.rowIndex"
                  [ngClass]="{
                    WAR:
                      data.data.StatusDescription != '' &&
                      data.data.StatusCode.startsWith('WAR'),
                    ERR:
                      data.data.StatusDescription != '' &&
                      data.data.StatusCode.startsWith('ERR'),
                    OK:
                      data.data.StatusDescription != '' &&
                      data.data.StatusCode.startsWith('OK'),

                    statusOthers:
                      data.data.StatusDescription != '' &&
                      !data.data.StatusCode.startsWith('WAR') &&
                      !data.data.StatusCode.startsWith('ERR') &&
                      !data.data.StatusCode.startsWith('OK')
                  }"
                ></div>
                <div>{{ data.data.StatusDescription }}</div>
              </div>
            </div>

            <dxi-column
              [hidingPriority]="2"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              caption="{{ 'buffor.Control' | translate }}"
              dataField="ControlDescription"
            ></dxi-column>

            <dxi-column
              [hidingPriority]="1"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              caption="{{ 'buffor.ControlMessage' | translate }}"
              dataField="ControlResult"
            ></dxi-column>

            <dxi-column
              [hidingPriority]="0"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              caption="{{ 'buffor.Positions' | translate }}"
              dataField="ControlItems"
            ></dxi-column>
          </dx-data-grid>
        </div>
        <div *ngSwitchCase="'menu.eDocuments' | translate">
          <app-e-dokuments
            #eDocuments
            [permission]="eDokDokHandl"
            [DocumentCode]="eDocumentsCode"
            tableName="DOKUMENT_HANDLOWY"
            style="display: inline-block; width: 100%"
            [contextObjectId]="focusedSelected[0]?.InvoiceDocumentId"
            [parentDocumentName]="focusedSelected[0]?.InvoiceDocumentNumber"
            [isShowSidePanel]="false"
            [heightGrid]="
              event.deviceType != 'mobile' ? heightBottomGrid - 65 : 545 - 65
            "
            [ContextObjectId]="
              focusedSelected.length > 0
                ? focusedSelected[0].InvoiceDocumentId
                : null
            "
            [readOnly]="focusedSelected.length === 0"
          ></app-e-dokuments>
        </div>
        <div *ngSwitchCase="'menu.warehouseDocuments' | translate">
          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="documentWarehouse"
            [focusedRowEnabled]="true"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [id]="'gridwarehouse' + unicalGuid"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="infinite"
              columnRenderingMode="virtual"
              preloadEnabled="true"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="WarehouseDocumentNumber"
              [hidingPriority]="5"
            ></dxi-column>
            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              [allowSorting]="false"
              dataField="DateOfWarehouseDocument"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
              [hidingPriority]="4"
            ></dxi-column>
            <dxi-column
              caption="{{ 'warehouseDocument.dateOfTax' | translate }}"
              [allowSorting]="false"
              dataField="DateOfTax"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
              [hidingPriority]="3"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              dataField="TotalNetAmount"
              [allowSorting]="false"
              width="100"
              cellTemplate="TotalNetAmountCell"
              [hidingPriority]="2"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalNetAmountCell'">
              {{ cell.data.TotalNetAmount | amountFormatter : cell }}
            </div>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              dataField="TotalGrossAmount"
              [allowSorting]="false"
              width="100"
              cellTemplate="TotalGrossAmountCell"
              [hidingPriority]="1"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.warehouseName' | translate
              }}"
              dataField="WarehouseName"
              [allowSorting]="false"
              width="150"
              [hidingPriority]="1"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>

            <div *dxTemplate="let cell of 'TotalGrossAmountCell'">
              {{ cell.data.TotalGrossAmount | amountFormatter : cell }}
            </div>
            <dxi-column
              caption="{{ 'comments' | translate }}"
              [allowSorting]="false"
              dataField="Remarks"
              alignment="left"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>

          <dx-context-menu
            [dataSource]="contextMenuWarehouse"
            [width]="200"
            [target]="'#gridwarehouse' + unicalGuid"
            (onItemClick)="contextMenuWarehoseClick($event, 'gridwarehouse')"
            (onItemRendered)="event.onItemRendered($event)"
          ></dx-context-menu>
        </div>

        <div *ngSwitchCase="'form-financial-document.tabs.items' | translate">
          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="positionList"
            [focusedRowEnabled]="true"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [id]="'invoicesPositionGrid'"
            [width]="'100%'"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="infinite"
              columnRenderingMode="virtual"
              preloadEnabled="true"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.articleName' | translate
              }}"
              width="230"
              [allowSorting]="false"
              dataField="ProductFullName"
              [hidingPriority]="18"
            ></dxi-column>

            <dxi-column
              caption="{{ 'customerGroups.catalogIndex' | translate }}"
              width="130"
              [allowSorting]="false"
              dataField="IndexCatalogue"
              [hidingPriority]="17"
            ></dxi-column>
            <dxi-column
              caption="{{ 'customerGroups.tradingIndex' | translate }}"
              width="130"
              [allowSorting]="false"
              dataField="IndexTrading"
              [hidingPriority]="16"
            ></dxi-column>

            <dxi-column
              caption="{{ 'form-commercial-operation.grid.count' | translate }}"
              width="50"
              [allowSorting]="false"
              dataField="Amount"
              [hidingPriority]="15"
            ></dxi-column>
            <dxi-column
              caption="{{ 'articles.unit' | translate }}"
              width="80"
              [allowSorting]="false"
              dataField="Unit"
              [hidingPriority]="14"
            ></dxi-column>
            <dxi-column
              caption="{{ 'constractors.barcode' | translate }}"
              width="130"
              [allowSorting]="false"
              [hidingPriority]="13"
              dataField="EAN"
            ></dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netAmount' | translate
              }}"
              [hidingPriority]="12"
              [allowSorting]="false"
              width="100"
              dataField="PriceNet"
              cellTemplate="PriceNet"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'PriceNet'">
              {{
                cell.data.PriceNetCurrency != 0
                  ? (cell.data.PriceNetCurrency | amountFormatter : cell)
                  : (cell.data.PriceNet | amountFormatter : cell)
              }}
            </div>

            <dxi-column
              caption="VAT"
              width="80"
              [allowSorting]="false"
              dataField="TaxCode"
              [hidingPriority]="11"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossAmount' | translate
              }}"
              [hidingPriority]="10"
              [allowSorting]="false"
              width="100"
              dataField="PriceGross"
              cellTemplate="PriceGross"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'PriceGross'">
              {{
                cell.data.PriceGrossCurrency != 0
                  ? (cell.data.PriceGrossCurrency | amountFormatter : cell)
                  : (cell.data.PriceGross | amountFormatter : cell)
              }}
            </div>

            <dxi-column
              caption="R/N 1"
              width="130"
              [allowSorting]="false"
              dataField="Discount"
              [hidingPriority]="9"
            ></dxi-column>
            <dxi-column
              caption="R/N 2"
              width="130"
              [allowSorting]="false"
              dataField="Discount2"
              [hidingPriority]="8"
            ></dxi-column>

            <dxi-column
              caption="{{ 'offerPosition.netAmountWithDiscount' | translate }}"
              [allowSorting]="false"
              width="100"
              dataField="PriceNetWithDiscount"
              cellTemplate="PriceNetWithDiscount"
              [hidingPriority]="7"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'PriceNetWithDiscount'">
              {{
                cell.data.PriceNetCurrencyWithDiscount != 0
                  ? (cell.data.PriceNetCurrencyWithDiscount
                    | amountFormatter : cell)
                  : (cell.data.PriceNetWithDiscount | amountFormatter : cell)
              }}
            </div>

            <dxi-column
              caption="{{
                'offerPosition.grossAmountWithDiscount' | translate
              }}"
              [allowSorting]="false"
              width="150"
              dataField="PriceGrossWithDiscount"
              cellTemplate="PriceGrossWithDiscount"
              [hidingPriority]="6"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'PriceGrossWithDiscount'">
              {{
                cell.data.PriceGrossCurrencyWithDiscount != 0
                  ? (cell.data.PriceGrossCurrencyWithDiscount
                    | amountFormatter : cell)
                  : (cell.data.PriceGrossWithDiscount | amountFormatter : cell)
              }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              [hidingPriority]="5"
              [allowSorting]="false"
              width="150"
              dataField="TotalNetAmount"
              cellTemplate="TotalNetAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalNetAmount'">
              {{
                cell.data.TotalNetAmountCurrency != 0
                  ? (cell.data.TotalNetAmountCurrency | amountFormatter : cell)
                  : (cell.data.TotalNetAmount | amountFormatter : cell)
              }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              [allowSorting]="false"
              width="150"
              dataField="TotalGrossAmount"
              cellTemplate="TotalGrossAmount"
              [hidingPriority]="4"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalGrossAmount'">
              {{
                cell.data.TotalGrossAmountCurrency != 0
                  ? (cell.data.TotalGrossAmountCurrency
                    | amountFormatter : cell)
                  : (cell.data.TotalGrossAmount | amountFormatter : cell)
              }}
            </div>

            <dxi-column
              caption="{{ 'deliveries.serialNo' | translate }}"
              width="130"
              [allowSorting]="false"
              dataField="SerialNumber"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              caption="{{ 'articles.certificateDate' | translate }}"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="180"
              [allowSorting]="false"
              dataField="ExpirationDate"
              [hidingPriority]="2"
            ></dxi-column>

            <dxi-column
              caption="{{ 'form-financial-document.description' | translate }}"
              width="200"
              [allowSorting]="false"
              dataField="Description"
              [hidingPriority]="1"
            ></dxi-column>

            <dxi-column
              caption="{{ 'articles.availableAmount' | translate }}"
              width="100"
              [allowSorting]="false"
              dataField="TotalLeft"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>

          <wapro-context-menu
            [dataSource]="contextMenuPositionSignal()"
            [width]="200"
            [target]="'#invoicesPositionGrid'"
            (onItemClick)="contextPositionMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          ></wapro-context-menu>
        </div>

        <div *ngSwitchCase="'menu.orders' | translate">
          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="orderList"
            [focusedRowEnabled]="true"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            [id]="'gridOrders' + unicalGuid"
            *ngIf="localStorageData.bottomPanel.isVisible"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="OrderDocumentNumber"
            ></dxi-column>
            <dxi-column
              caption="S"
              width="32"
              [allowSorting]="false"
              headerCellTemplate="StatusCodeOrders"
              dataField="OrderStatus"
              [hidingPriority]="7"
            ></dxi-column>
            <div
              *dxTemplate="let data of 'StatusCodeOrders'"
              class="text-center"
            >
              <div
                class="grid-header-wrapper"
                [id]="'StatusCodeOrders' + unicalGuid"
              >
                {{ "production-documents.s" | translate }}
              </div>
              <dx-tooltip
                [target]="'#StatusCodeOrders' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "production-documents.status" | translate }}
                </div>
              </dx-tooltip>
            </div>
            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              dataField="Date"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="180"
              alignment="left"
              [hidingPriority]="6"
            ></dxi-column>
            <dxi-column
              caption="{{
                'warehouseDocument.customerOrderNumber' | translate
              }}"
              width="150"
              [allowSorting]="false"
              dataField="CustomerOrderNumber"
              [hidingPriority]="5"
            ></dxi-column>
            <dxi-column
              caption="{{ 'plannedOrders.realisationDate' | translate }}"
              dataField="RealizationDate"
              [allowSorting]="false"
              dataType="date"
              [hidingPriority]="4"
              [format]="{ type: event.dateFormat }"
              width="180"
              alignment="left"
            ></dxi-column>
            <dxi-column
              caption="{{ 'plannedOrders.priority' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="Priority"
              [hidingPriority]="4"
            ></dxi-column>

            <dxi-column
              caption="{{
                'warehouseDocument.prefereTypeOfdocument' | translate
              }}"
              width="250"
              [allowSorting]="false"
              dataField="PreferredDocumentTypeName"
              [hidingPriority]="10"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              [hidingPriority]="3"
              [allowSorting]="false"
              width="100"
              dataField="TotalNetAmount"
              cellTemplate="NetAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'NetAmount'">
              {{ cell.data.TotalNetAmount | amountFormatter : cell }}
            </div>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              [hidingPriority]="2"
              [allowSorting]="false"
              width="100"
              dataField="TotalGrossAmount"
              cellTemplate="GrossAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'GrossAmount'">
              {{ cell.data.TotalGrossAmount | amountFormatter : cell }}
            </div>
            <dxi-column
              caption="{{ 'bankAccounts.form.currency' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="CurrencyCode"
              [hidingPriority]="1"
            ></dxi-column>
            <dxi-column
              caption="{{ 'comments' | translate }}"
              [allowSorting]="false"
              dataField="Remarks"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>
          <dx-context-menu
            [dataSource]="contextMenuOrders"
            [width]="200"
            [target]="'#gridOrders' + unicalGuid"
            (onItemClick)="contextMenuWarehoseClick($event, 'gridOrders')"
            (onItemRendered)="event.onItemRendered($event)"
          ></dx-context-menu>
        </div>

        <div
          *ngSwitchCase="'warehouseDocument.dockOriginalCorrection' | translate"
        >
          <dx-data-grid
            [showBorders]="true"
            [id]="'contextMenuCorection'"
            [allowColumnReordering]="true"
            [dataSource]="correctionList"
            [wordWrapEnabled]="false"
            style="width: 100%; margin-bottom: 10px"
            [focusedRowEnabled]="true"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            [columnResizingMode]="'widget'"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            *ngIf="localStorageData.bottomPanel.isVisible"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection [mode]="'single'"></dxo-selection>

            <dxi-column
              caption="K"
              width="40"
              [allowSorting]="false"
              dataField="IsCorrection"
              cellTemplate="IsCorrection"
              headerCellTemplate="IsCorrectionHeader"
            ></dxi-column>
            <div
              *dxTemplate="let data of 'IsCorrectionHeader'"
              class="text-center"
            >
              <div
                class="grid-header-wrapper"
                [id]="'IsCorrection' + unicalGuid"
              >
                K
              </div>
              <dx-tooltip
                [target]="'#IsCorrection' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "load.isCorrection" | translate }}
                </div>
              </dx-tooltip>
            </div>
            <div *dxTemplate="let data of 'IsCorrection'">
              <i
                class="icon absui-icon--check check-grid"
                *ngIf="data.data.IsCorrection; else nonDefaultTemplate"
              ></i>
            </div>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="InvoiceDocumentNumber"
              [hidingPriority]="4"
            ></dxi-column>
            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              [hidingPriority]="3"
              dataField="DateOfDocumentSys"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="180"
              alignment="left"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              [hidingPriority]="2"
              [allowSorting]="false"
              width="100"
              dataField="TotalNetAmount"
              cellTemplate="NetAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'NetAmount'">
              {{ cell.data.TotalNetAmount | amountFormatter : cell }}
            </div>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              [hidingPriority]="1"
              [allowSorting]="false"
              width="100"
              dataField="TotalGrossAmount"
              cellTemplate="GrossAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'GrossAmount'">
              {{ cell.data.TotalGrossAmount | amountFormatter : cell }}
            </div>

            <dxi-column
              caption="{{ 'servicesDevices.saleDate' | translate }}"
              dataField="DateOfSellSys"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="180"
              alignment="left"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>
          <dx-context-menu
            [dataSource]="contextMenuCorection"
            [width]="200"
            [target]="'#contextMenuCorection'"
            (onItemClick)="contextCorectionMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          ></dx-context-menu>
        </div>

        <div *ngSwitchCase="'form-commercial-operation.clearances' | translate">
          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="settlementsList"
            [focusedRowEnabled]="true"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [id]="'settlements'"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="infinite"
              columnRenderingMode="virtual"
              preloadEnabled="true"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              [caption]="'form-commercial-operation.paymentNumberS' | translate"
              dataField="Number"
              [hidingPriority]="6"
              [width]="150"
            ></dxi-column>

            <dxi-column
              [caption]="'form-commercial-operation.dateOfPaymentS' | translate"
              [width]="150"
              [allowSorting]="false"
              dataField="ClearanceDate"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              [hidingPriority]="5"
            ></dxi-column>

            <dxi-column
              [caption]="'form-financial-document.grid.amount' | translate"
              dataField="TotalAmount"
              cellTemplate="TotalAmountGross"
              [width]="100"
              [hidingPriority]="4"
            ></dxi-column>
            <div *dxTemplate="let cell of 'TotalAmountGross'">
              {{ cell.data.TotalAmount | amountFormatter : cell }}
            </div>

            <dxi-column
              [caption]="'bankAccounts.form.currency' | translate"
              dataField="CurrencyCode"
              [width]="100"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              [caption]="
                'form-financial-document.grid.currency-amount' | translate
              "
              dataField="CurrencyAmount"
              [width]="120"
              [hidingPriority]="2"
              cellTemplate="TotalGrossAmountCurrency"
            ></dxi-column>
            <div *dxTemplate="let cell of 'TotalGrossAmountCurrency'">
              {{ cell.data.CurrencyAmount | amountFormatter : cell }}
            </div>

            <dxi-column
              [caption]="'baseCurrencies.currencyValue' | translate"
              dataField="CurrencyFactor"
              cellTemplate="CurrencyConverter"
              [width]="100"
              [hidingPriority]="1"
            ></dxi-column>
            <div *dxTemplate="let cell of 'CurrencyConverter'">
              <p
                style="margin-bottom: 0px"
                *ngIf="
                  cell.data.CurrencyCode != '' &&
                  cell.data.CurrencyCode != 'PLN'
                "
              >
                {{ cell.data.CurrencyFactor | amountFormatter : cell : 4 }}
              </p>
            </div>

            <dxi-column
              [caption]="'form-financial-document.description' | translate"
              dataField="Description"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>
        </div>

        <div *ngSwitchCase="'documentsOrders.onlinePayments' | translate">
          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="onlinePaymentsList"
            [focusedRowEnabled]="true"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [id]="'onlinePayment'"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="infinite"
              columnRenderingMode="virtual"
              preloadEnabled="true"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              [caption]="'formPayment.operator' | translate"
              dataField="PaymentOperator"
              [hidingPriority]="6"
            ></dxi-column>

            <dxi-column
              [caption]="'documentsOrders.creationTime' | translate"
              width="100"
              dataField="CreationTime"
              [hidingPriority]="5"
              cellTemplate="timeTemplate"
            ></dxi-column>
            <div *dxTemplate="let data of 'timeTemplate'">
              <div class="text-right black-txt">
                {{
                  data.value
                    | date : "dd.MM.yyyy" : translate.currentLang || "pl"
                }}
              </div>
              <div class="text-right grey-txt">
                {{
                  data.value | date : "HH:mm" : translate.currentLang || "pl"
                }}
              </div>
            </div>

            <dxi-column
              caption="S"
              width="80"
              dataField="State"
              [hidingPriority]="4"
            ></dxi-column>

            <dxi-column
              [caption]="'status-type.statusDescription' | translate"
              width="40"
              dataField="StateDescription"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              [caption]="
                'form-financial-document.transactionAmount' | translate
              "
              width="150"
              dataField="Amount"
              [hidingPriority]="2"
              cellTemplate="Amount"
            ></dxi-column>
            <div *dxTemplate="let cell of 'Amount'">
              {{ cell.data.Amount | amountFormatter : cell }}
            </div>

            <dxi-column
              [caption]="'bankAccounts.form.currency' | translate"
              width="150"
              dataField="CurrencyCode"
              [hidingPriority]="1"
            ></dxi-column>

            <dxi-column
              [caption]="'documentsOrders.confirmTime' | translate"
              width="150"
              dataField="ConfirmationTime"
              [hidingPriority]="0"
              cellTemplate="timeTemplate"
            ></dxi-column>
          </dx-data-grid>
          <dx-context-menu
            [dataSource]="contextMenuOnline"
            [width]="200"
            [target]="'#onlinePayment'"
            (onItemClick)="contextPaymentMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          ></dx-context-menu>
        </div>

        <div
          *ngSwitchCase="
            'form-commercial-operation.deliveryAddress' | translate
          "
        >
          <dx-data-grid
            [showBorders]="true"
            [allowColumnReordering]="true"
            [dataSource]="deliveryAddress"
            [wordWrapEnabled]="false"
            style="width: 100%; margin-bottom: 10px"
            [focusedRowEnabled]="true"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [columnResizingMode]="'widget'"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection [mode]="'single'"></dxo-selection>

            <dxi-column
              [caption]="'companies.form.addressDetail.country' | translate"
              width="100"
              dataField="CountryCode"
            ></dxi-column>

            <dxi-column
              [caption]="'companies.form.postalCode' | translate"
              width="100"
              dataField="PostalCode"
            ></dxi-column>

            <dxi-column
              [caption]="'companies.form.city' | translate"
              width="150"
              dataField="City"
            ></dxi-column>

            <dxi-column
              [caption]="'companies.form.street' | translate"
              width="150"
              dataField="Street"
            ></dxi-column>

            <dxi-column
              [caption]="'constractors.company' | translate"
              width="150"
              dataField="CompanyName"
            ></dxi-column>

            <dxi-column
              [caption]="'constractors.recipient' | translate"
              width="150"
              dataField="Receiver"
            ></dxi-column>

            <dxi-column
              [caption]="'companies.form.phone' | translate"
              width="100"
              dataField="Phone"
            ></dxi-column>

            <dxi-column
              [caption]="'delivery.addressType' | translate"
              width="100"
              dataField="AddressType"
              [customizeText]="customizeAddressType"
            ></dxi-column>

            <dxi-column
              [caption]="'formPayment.operator' | translate"
              width="100"
              dataField="PostageOperator"
            ></dxi-column>

            <dxi-column
              [caption]="'constractors.pickupPoint' | translate"
              width="100"
              dataField="PickUpAddressCode"
            ></dxi-column>

            <dxi-column
              [caption]="'form-financial-document.description' | translate"
              width="300"
              dataField="Description"
            ></dxi-column>
          </dx-data-grid>
        </div>

        <div *ngSwitchCase="'form-financial-document.tabs.package' | translate">
          <dx-data-grid
            [showBorders]="true"
            [allowColumnReordering]="true"
            [dataSource]="packagesData"
            [wordWrapEnabled]="false"
            style="width: 100%; margin-bottom: 10px"
            [focusedRowEnabled]="true"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection [mode]="'single'"></dxo-selection>

            <dxi-column
              [caption]="'crmDeliveries.packageNo' | translate"
              [allowSorting]="false"
              width="120"
              dataField="PackageNumber"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.postingDate' | translate"
              [allowSorting]="false"
              width="120"
              dataField="ShipmentDate"
              dataType="date"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.returnDate' | translate"
              [allowSorting]="false"
              width="120"
              dataField="ReturnDate"
              dataType="date"
            ></dxi-column>
            <dxi-column
              [caption]="'formPayment.operator' | translate"
              [allowSorting]="false"
              width="130"
              dataField="DeliveryOperatorName"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.courier' | translate"
              [allowSorting]="false"
              width="130"
              dataField="Courier"
            ></dxi-column>
            <dxi-column
              [caption]="'production-documents.status' | translate"
              [allowSorting]="false"
              width="80"
              dataField="StatusCode"
            ></dxi-column>
            <dxi-column
              [caption]="'userExtensions.customers' | translate"
              [allowSorting]="false"
              width="130"
              dataField="CustomerName"
            ></dxi-column>
            <dxi-column
              [caption]="'constractors.recipient' | translate"
              [allowSorting]="false"
              width="120"
              dataField="ReceiverLastName !== null ? ReceiverLastName : CustomerName"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.pickingUp' | translate"
              [allowSorting]="false"
              width="120"
              dataField="CashOnDeliveryValue"
            ></dxi-column>
          </dx-data-grid>
        </div>

        <div
          *ngSwitchCase="
            localStorageData?.additionalTable?.text || 'articles.externalTable'
              | translate
          "
        >
          <app-table-user-extension
            tableGroupCode="Dokument_handlowy"
            (onTableChanged)="onAdditionalTableChanged($event)"
            (onFocusElementChanged)="onFocusElementChanged($event)"
            [selectedTable]="localStorageData.additionalTable"
            *ngIf="selectedtabIndex == 7 && focusedSelected.length"
          ></app-table-user-extension>
        </div>

        <div *ngSwitchCase="'notes.notes' | translate">
          <app-notes
            #notesComponent
            componentName="comercialOperation"
            [objectId]="
              focusedSelected?.length && !newRowPopupVisible
                ? focusedSelected[0].InvoiceDocumentId
                : null
            "
            *ngIf="
              bottomPanelTabs[selectedtabIndex] ===
                ('notes.notes' | translate) &&
              (event.deviceType === 'desktop'
                ? localStorageData.bottomPanel.isVisible
                : isDetails)
            "
            [timeout]="500"
            [componentHeight]="
              event.deviceType != 'mobile'
                ? localStorageData.bottomPanel.height
                : 545
            "
          ></app-notes>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>

  <dx-tooltip
    *ngFor="let tabs of bottomPanelTabs; let i = index"
    [target]="'#tabToolTip' + i + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="mouseleave"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data = data; of: 'content'">(Alt+{{ i + 1 }})</div>
  </dx-tooltip>
</ng-template>

<div *ngIf="isFilterPanelComponent">
  <app-filter-panel
    [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
    (onFilterSave)="onFilterSaved($event)"
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [selectedTemplate]="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId
    "
    [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
  ></app-filter-panel>
</div>

<ng-template #rightPanelBox>
  <dx-tab-panel
    [visible]="localStorageData.sidePanel.isVisible"
    [items]="[1, 2]"
    [(selectedIndex)]="selectedRightTabIndex"
    (onSelectionChanged)="onSelectionSideTabChanged()"
    class="filter-panel right-panel-box"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="true"
    *ngIf="localStorageData.sidePanel.isVisible"
  >
    <div *dxTemplate="let item of 'title'">
      <div class="title-temp">
        <i class="icon absui-icon--control-tree" *ngIf="item == 1"></i>
        <i class="icon absui-icon--filter" *ngIf="item == 2"></i>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="1">
          <dx-scroll-view
            width="100%"
            height="calc(100vh - 170px)"
            direction="vertical"
          >
            <dx-tree-view
              [dataSource]="typeTreeGroup"
              keyExpr="InvoiceTypeTreeId"
              displayExpr="Name"
              [selectByClick]="true"
              selectionMode="single"
              (onItemClick)="onItemClickType($event)"
              style="width: 100%; height: 100%"
              #treeView
              (onSelectionChanged)="onTreeSelectionChanged($event)"
              (onInitialized)="restoreTreeConfig()"
            ></dx-tree-view>
          </dx-scroll-view>
        </div>
        <div *ngSwitchCase="2">
          <div style="width: 100%">
            <app-filter-panel
              [deleteFilters]="deleteFilter"
              [openPanelFilters]="visibleAdvanceFilters"
              (onFilterSave)="onFilterSaved($event)"
              [dictionaryCode]="DynamicFilterDictionaryCode"
              [selectedTemplate]="
                localStorageData?.advancedFilter?.FilterUsersDefinitionId
              "
              [isPinned]="localStorageData?.advancedFilter?.IsPinned"
              (onTemplatePinChanged)="onTemplatePinChanged($event)"
              [tabActive]="
                localStorageData.sidePanel.isVisible &&
                selectedRightTabIndex == 1
              "
              [panelWidth]="localStorageData.sidePanel.width"
              [values]="
                localStorageData?.advancedFilter?.UserFilterDefinitionValues
              "
              [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            ></app-filter-panel>
          </div>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <app-wapro-selected-box
      [(ngModel)]="selectedRows"
      [dataSource]="dataSource"
      [selectedAll]="isAllSelected"
      [mobileSelectMode]="modeSelectionMobile === 'multiple'"
      (onMobileSelectModeOff)="modeSelectionMobile = 'none'"
      (onSelectAll)="gridBank.instance.selectAll()"
      [selectedAmount]="selectedAmount()"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [text]="'buttons.add' | translate"
      [disabled]="readOnly || !perABD.addBtn"
      (onClick)="openNewRow()"
      [id]="'btn-add' + unicalGuid"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      (onClick)="mobileOperationsVisible = true; cd.detectChanges()"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder lastBtn"
      icon="icon absui-icon--filter"
      (onClick)="filterMobileVisible = true"
      style="position: absolute; right: 0"
    ></dx-button>

    <app-mobile-list
      [dataSource]="contextMenuOperationsMobile"
      [visible]="mobileOperationsVisible"
      (onClosed)="mobileOperationsVisible = false; cd.detectChanges()"
      (onItemClick)="contextMenuClick($event)"
    ></app-mobile-list>

    <app-additional-operation
      #additionalOperation
      nameComponent="ComercialOperationComponent"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
    ></app-additional-operation>

    <!-- mobile reports popup -->
    <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

    <!-- mobile edocoments popup -->
    <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #reportsTemplate>
  <app-reports
    #reports
    [code]="reportCode"
    [currencyCode]="
      focusedSelected?.length ? focusedSelected[0]?.CurrencyCode : null
    "
    [menuName]="menuName"
    [type]="type"
    [objectId]="
      focusedSelected?.length ? focusedSelected[0]?.InvoiceDocumentId : null
    "
    [commercialDocument]="focusedSelected[0]"
    (onReportsPopupClosed)="onReportsPopupClosed()"
    (onForceRefresh)="getDocuments()"
    [isVisible]="
      event.deviceType === 'mobile' ? isMobileReportsPopupVisible : true
    "
    [params]="getLoadParams()"
  ></app-reports>
</ng-template>

<ng-template #edocumentsTemplate>
  <app-e-dokuments
    [isPopupMode]="true"
    [isVisible]="eDocumentsVisible"
    [id]="'eDocuments' + unicalGuid"
    #eDocuments
    [permission]="eDokDokHandl"
    [DocumentCode]="eDocumentsCode"
    tableName="DOKUMENT_HANDLOWY"
    style="display: inline-block"
    [contextObjectId]="focusedSelected[0]?.InvoiceDocumentId"
    [parentDocumentName]="focusedSelected[0]?.InvoiceDocumentNumber"
    (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
  ></app-e-dokuments>
</ng-template>

<dx-popup
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'filterPanel.filters' | translate }}"
  [(visible)]="filterMobileVisible"
  width="100%"
  height="100vh"
  *ngIf="filterMobileVisible"
  [maxHeight]="event.maxHeightSignal()"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="header-search-mobile">
      <i
        class="icon absui-icon--close"
        style="position: absolute; top: 14px; right: 16px; z-index: 1"
        (click)="filterMobileVisible = false"
      ></i>
      <i
        class="icon absui-icon--close"
        style="position: absolute; top: 14px; right: 16px; z-index: 1"
        (click)="filterMobileVisible = false"
      ></i>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
    >
      <br />
      <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
    </dx-scroll-view>

    <div class="bottom-mobile-form">
      <dx-button
        class="btn-dx-top-menu-folder"
        type="danger"
        style="margin-right: 12px; color: #333"
        icon="icon absui-icon--clear-filter"
        (onClick)="clearMobileFilters()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="filterMobileVisible = false"
      ></dx-button>
    </div>
  </div>
</dx-popup>

<app-user-guide
  *ngIf="isGuideActive"
  (finished)="onGuideFinished()"
  [view]="'comercial-operation'"
></app-user-guide>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGHL'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-sum-comercial-operation
  [isVisible]="isSumVisible"
  (onClosing)="isSumVisible = false"
  [title]="'sumCommercialOperations.popupTitle' | translate"
  [selectedId]="focusedSelected"
  [forFiltered]="!(selectedRows.length > 0)"
  [filters]="sumFilters"
></app-sum-comercial-operation>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGHL'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-print-settings
  componentName="comercialOperations"
  [refreshPrintSettings]="refreshPrintSettings"
  (onSettingsRefreshed)="refreshPrintSettings = false"
></app-print-settings>

<app-add-crm-delivery
  [isVisible]="isDelivery"
  [readOnly]="readOnly"
  [objectToDelivery]="objectToDelivery"
  mode="add"
  (onClosing)="onSingleRecordClosing()"
  (onInserted)="onSingleRecordClosing()"
></app-add-crm-delivery>

<app-contractors
  [popUpMode]="true"
  [selectedId]="focusedSelected[0]?.CustomerId"
  [isVisible]="isCustomerVisible"
  *ngIf="isCustomerVisible"
  (onClosed)="isCustomerVisible = false"
  (onChoosed)="onChoosedCustomer($event)"
></app-contractors>

<app-export-jpk-xml
  *ngIf="isExportVisible()"
  (onClosing)="isExportVisible.set(false)"
></app-export-jpk-xml>

<app-label-printer-operations
  *ngIf="isLabelPrintOperationVisible"
  [isVisible]="true"
  context="Handlowe"
  [labelType]="labelType"
  (onClosing)="onLabelPrintOperationClosing()"
></app-label-printer-operations>

<dx-popup
  width="400px"
  height="80px"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="false"
  [(visible)]="isProgrss"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <dx-progress-bar
      #progressBar
      id="progress-bar-status"
      width="100%"
      [min]="0"
      [max]="100"
      [statusFormat]="format"
      [value]="progrsValue"
    ></dx-progress-bar>
    {{ "loading" | translate }}: {{ progrsValue.toFixed(0) }}%
    <p class="p-progress-name">
      {{ "workers.documentNumber" | translate }}: {{ DocNumber }}
    </p>
  </div>
</dx-popup>

<app-record-card
  *ngIf="isRecordCardVisible"
  [selectedTab]="selectedRecordTab"
  [cardMode]="recordCardMode"
  [isPopupMode]="true"
  [isVisible]="isRecordCardVisible"
  [objectId]="recordCardObjectId"
  (onClosing)="onRecordCardClosing()"
></app-record-card>

<app-serial-doc-generator
  *ngIf="isSerialGeneratorDoc()"
  [isVisible]="isSerialGeneratorDoc()"
  (onClosing)="isSerialGeneratorDoc.set(false)"
  (onInserted)="resetType(); deselectAllRecords()"
  [selectedTemplateId]="templateArray"
></app-serial-doc-generator>

@if(isShowPaymentSchedule()){
<app-payment-schedule
  [readOnly]="readOnly"
  [isVisible]="isShowPaymentSchedule()"
  (onClosing)="isShowPaymentSchedule.set(false)"
  [sumPosition]="returnSumPositionForFocusedRow()"
  [DateOfPayment]="focusedSelected[0]?.DateOfPayment"
  [PaymentFormName]="focusedSelected[0]?.PaymentFormName"
  [BankAccountId]="focusedSelected[0]?.BankAccountId"
  [CurrencyCode]="focusedSelected[0]?.CurrencyCode"
  [type]="returnTypDocument()"
  [CustomerId]="focusedSelected[0]?.CustomerId"
  [IsApproveSettlementsActive]="true"
  [InvoiceDocumentId]="focusedSelected[0]?.InvoiceDocumentId"
  (onInserted)="onInsertedPaymentSchedule()"
></app-payment-schedule>
}

<app-new-comercial-operation
  [isVisible]="newRowPopupVisible"
  (onClosing)="
    closeNewComercialOperation();
    isModyfieOnlyRemarks = false;
    checkArrayToClone()
  "
  title="addEditTitle"
  (onSendToBusinessCheckAutomatically)="checkKsefConfiguration(true)"
  (onInserted)="onInsertedDocument($event); isModyfieOnlyRemarks = false"
  [typeSelectedDocument]="typeFilter"
  [mode]="mode"
  [selectedId]="focusedSelected"
  [documentObject]="focusedSelected[0]"
  [readOnly]="mode == 'show' ? true : readOnly"
  [isNonRevolvingInvoice]="isNonRevolvingInvoice"
  [fromClone]="fromClone"
  [componentNests]="componentNests"
  [isModyfieOnlyRemarks]="isModyfieOnlyRemarks"
  [InputMode]="InputMode"
  [recalculatePosition]="recalculatePosition"
  [reportsList]="reportsList"
  [customerSelected]="customerSelected"
  (onRefreshList)="getDocuments()"
></app-new-comercial-operation>

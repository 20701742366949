import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  HostListener,
  inject,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {TranslateService} from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {LoadOptions} from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import _ from 'lodash';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {EMPTY, forkJoin, from, map, Observable, of, Subscription, switchMap, take, throwError,} from 'rxjs';
import {AdditionalOperationComponent} from 'src/app/core/additional-operation/additional-operation.component';
import {CustomDropdownBoxComponent} from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import {ICustomSearchItem} from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import {DateRangeComponent} from 'src/app/core/date-range/date-range.component';
import {DynamicFilterService, ViewUserDefinition,} from 'src/app/core/dynamic-filter.service';
import {UserDefinition} from 'src/app/core/filter-panel/filterpanel';
import {DataToPrint, DataToPrintV2, ReportData,} from 'src/app/core/reports/IReportParameters';
import {ReportsComponent} from 'src/app/core/reports/reports.component';
import {TableService} from 'src/app/core/table-user-extension/table-service.service';
import {AdditionalTable, DoubleClickResult, ErrorListRecord, RefreshCardResult,} from 'src/app/event.model';
import {HelpService} from 'src/app/help-service.service';
import {InterconnectorService} from 'src/app/interconnector.service';
import {EDokumentsService} from 'src/app/libraries/e-dokuments/e-dokuments.service';
import {ProductScanerComponent} from 'src/app/libraries/product-scaner/product-scaner.component';
import {PathToNestMap} from 'src/app/nest-resolver.service';
import {NestFinishedEvent, NestOperation} from 'src/app/nest/nest.model';
import {ExportFakirService} from 'src/app/other/export-fakir.service';
import {PrintService} from 'src/app/print.service';
import {Socket} from 'src/app/socket.model';
import {environment} from '../../../environments/environment';
import {AppServices} from '../../app-services.service';
import {GlobalDate} from '../../core/date-range/GlobalDate';
import {CustomWindowConfig} from '../../event.model';
import {EventService} from '../../event.service';
import {FinancialDocument} from '../financial-operation/financial-operation.model';
import {SingleRecordMode} from './../../event.model';
import {NestService} from './../../nest-service.service';
import {InvoiceDocument} from './invoice-document.model';
import {GeniusSummaryGroup, RadioBoxType,} from 'src/app/core/confirm-dialog/confirm-dialog.model';
import {PrintSettingsService} from 'src/app/core/print-settings/print-settings.service';
import notify from 'devextreme/ui/notify';
import {catchError, concatMap} from 'rxjs/operators';
import {paramsSentDocuments} from '../../other/documents-buffer/documents-buffer.component';
import {ErrorListService} from '../../error-list.service';
import {NestedRecordCard, RecordCardMode,} from 'src/app/libraries/record-card/record-card.interface';
import {NotesComponent} from 'src/app/core/notes/notes.component';
import {NotesService} from 'src/app/core/notes/notes.service';
import {OnInsertedData} from '../new-comercial-operation/new-comercial-operation.component';

const helper = new JwtHelperService();

interface paramsDocumentHasAlreadyBeenSentToTheBuffer {
  InvoiceDocumentNumber: string;
}

@Component({
  selector: 'app-comercial-operation',
  templateUrl: './comercial-operation.component.html',
  styleUrls: ['./comercial-operation.component.scss'],
  inputs: [
    'readOnly',
    'dropDownBoxMode',
    'selectedId',
    'customerName',
    'className',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ComercialOperationComponent
  implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @ViewChild('reports') reports: ReportsComponent;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  @ViewChild('additionalOperation')
  additionalOperation: AdditionalOperationComponent;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  @ViewChild('gridBank') gridBank;
  @ViewChild('scaner') scaner: ProductScanerComponent;
  @ViewChild(ReportsComponent) reportsComponent: ReportsComponent;
  @ViewChild('notesComponent') notesComponent: NotesComponent;
  @ViewChild('eDocuments') eDocuments: any;

  selectedAmount = signal(0);
  helpService = inject(HelpService);
  exportFakirService = inject(ExportFakirService);
  @Output() onChoosed = new EventEmitter();

  USER_EXTENSIONS_GROUP_CODE = 'Dokument_handlowy';
  //mobile
  event = inject(EventService);
  modeSelectionMobile: string = 'none';
  isContextMenuMobile: boolean = false;
  // contextMenuMobile: any[] = [
  //   { name: 'Zaznacz', icon: 'icon checkAll', itemIndex: 99 },
  //   { name: 'Szczegóły', icon: 'icon absui-icon--file-edit', itemIndex: 98 },
  // ];
  reportsList: any = [];
  openCustomDropDown: boolean = false;
  selectedId;
  isSumVisible = false;
  isDetails: boolean = false;
  isShowMoreDetails: boolean = false;
  filterMobileVisible: boolean = false;
  newRowPopupVisible: boolean = false;
  shortcuts: ShortcutInput[] = [];
  dropDownBoxMode;
  mode: SingleRecordMode;
  readOnly: boolean = false;
  dynamicFilterUserDefinitions: any[] = [];
  readonly DynamicFilterDictionaryCode = 'DOCUMENT_INVOICE';
  isFilterPanelComponent = false;
  visibleAdvanceFiltersWithoutPanel = false;
  viewUserDefinitionSub: Subscription = null;
  pageSize: number = 200;
  className;
  doubleClickResult: DoubleClickResult = 'Edycja';
  addEditTitle: string = '';

  public translate = inject(TranslateService);

  bottomPanelTabs = [
    this.translate.instant('menu.warehouseDocuments'),
    this.translate.instant('form-financial-document.tabs.items'),
    this.translate.instant('menu.orders'),
    this.translate.instant('warehouseDocument.dockOriginalCorrection'),
    this.translate.instant('form-commercial-operation.clearances'),
    this.translate.instant('documentsOrders.onlinePayments'),
    this.translate.instant('form-commercial-operation.deliveryAddress'),
    'BusinessLink',
    this.translate.instant('buffor.controlBusinessCheck'),
    this.translate.instant('form-financial-document.tabs.package'),
    this.translate.instant('notes.notes'),
    this.translate.instant('menu.eDocuments'),
    this.translate.instant('articles.externalTable'),
  ];

  columns = [
    {
      caption: 'Nr paragonu/podstawy',
      visibleIndex: 7,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },

    {
      caption: 'Płatnik',
      visibleIndex: 13,
      visible: true,
      width: '100',
      dataField: 'PayerName',
      cellTemplate: null,
    },
    {
      caption: 'RD',
      visibleIndex: 14,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },
    {
      caption: 'Zał.',
      visibleIndex: 15,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },
    {
      caption: 'Kurs wal.',
      visibleIndex: 16,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },
    {
      caption: 'Kod kreskowy.',
      visibleIndex: 17,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },
    {
      caption: 'Data otrzymania',
      visibleIndex: 18,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },

    {
      caption: 'Forma płatności',
      visibleIndex: 20,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },
    {
      caption: 'Kraj VAT',
      visibleIndex: 21,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },
    {
      caption: 'JPK Klasyfikacja',
      visibleIndex: 22,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },
    {
      caption: 'Termin płat.',
      visibleIndex: 23,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },
    {
      caption: 'S',
      visibleIndex: 24,
      visible: true,
      width: '100',
      dataField: 'VatNumber',
      cellTemplate: null,
    },
  ];
  dataSource;
  selectedRows = [];
  heightGrid;
  mobileOperationsVisible: boolean = false;

  contextMenuWarehouse = [
    {text: 'Pokaż', icon: 'icon absui-icon--form-items', itemIndex: 0},
    {text: 'Korekty', icon: 'icon absui-icon--file-edit', itemIndex: 1},
  ];

  contextMenuOrders = [
    {text: 'Pokaż', icon: 'icon absui-icon--form-items', itemIndex: 0},
  ];
  isOutcome: boolean = false;
  contextMenuOperationsMobile = [
    {name: 'Zaznacz wiele', icon: 'icon checkAll', itemIndex: 99},
    // { name: 'Suma', icon: 'icon absui-icon--sum', itemIndex: 97 },
  ];
  PDokHandl: boolean = false;
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'InvoiceDocumentNumber',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 210,
    },
    mainPanelWidth: 'calc(100% - 385px)',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 385,
    },
    windowSize: {
      isFullscreen: false,
      width: this.event.setWidthPopUp(),
      height: '90%',
    },
    params: [],
  };

  customerName;

  filterCriteria: ICustomSearchItem[] = [
    {
      value: 'InvoiceDocumentNumber',
      label: 'Numer',
      translationSrc: 'form-financial-document.number',
    },
    {
      value: 'DateOfInvoiceDocument',
      label: 'Data wystawienia',
      type: 'data',
      translationSrc: 'form-commercial-operation.form.dateOfIssue',
    },
    {
      value: 'CustomerName',
      label: 'Kontrahent',
      translationSrc: 'form-financial-document.payer-type.contractor',
    },
    {
      value: 'TotalGrossAmount',
      label: 'Wartość brutto',
      type: 'number',
      translationSrc: 'form-commercial-operation.grid.grossValue',
    },
    {
      value: 'PayerName',
      label: 'Płatnik',
      translationSrc: 'form-financial-document.payer',
    },
    {
      value: 'DistinguishCode',
      label: 'Wyróżnik',
      translationSrc: 'menu.distinguishCodes',
    },
    {
      value: 'DateOfReceived',
      label: 'Data otrzymania',
      type: 'data',
      translationSrc: 'form-financial-document.receivedDate',
    },
    {
      value: 'EAN',
      label: 'Kod kreskowy',
      type: 'number',
      translationSrc: 'articles.filter.ean',
    },
    {
      value: 'DateOfCreate',
      label: 'Data utworzenia',
      type: 'data',
      translationSrc: 'crmDeliveries.creationDate',
    },
  ];
  valueCriteria = 'Number';
  filterValue = '';
  choosingInvoices: Number;
  isGridBoxOpened: boolean = false;
  invoicesList;
  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;

  orderby: string = 'Number';
  order: string = 'ASC'; // asc/desc
  SumDokHandl: boolean = false;

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  userExtensionColumns = {
    Pole1: {
      IsActive: false,
      Name: 'pole1',
    },
    Pole2: {
      IsActive: false,
      Name: 'pole2',
    },
    Pole3: {
      IsActive: false,
      Name: 'pole3',
    },
    Pole4: {
      IsActive: false,
      Name: 'pole4',
    },
    Pole5: {
      IsActive: false,
      Name: 'pole5',
    },
    Pole6: {
      IsActive: false,
      Name: 'pole6',
    },
    Pole7: {
      IsActive: false,
      Name: 'pole7',
    },
    Pole8: {
      IsActive: false,
      Name: 'pole8',
    },
    Pole9: {
      IsActive: false,
      Name: 'pole9',
    },
    Pole10: {
      IsActive: false,
      Name: 'pole10',
    },
  };

  isDeleteRow: boolean = false;
  isTask: boolean = false;
  checkBoxListConfirmDelete = !this.event.footerInfo.VariantName.includes(
    'FAKTUROWANIE'
  )
    ? [
      {
        text: 'usuń powiązane dokumenty magazynowe',
        modelName: 'isDeleteOnlyInvoiceDocument',
        value: true,
        readOnly: false,
      },
    ]
    : null;

  unicalGuid;

  typeTree;
  permissionToChooseColums: boolean = false;
  permissionEksportExcel = false;
  columnsChooserVisible: boolean = false;
  showRow: boolean = false;
  loadChildComponent: boolean = false;

  editRow: boolean = false;
  isShowSum: boolean = false;
  isCorrection: boolean = false;

  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();

  CustomerId;
  InvoiceDocumentId;
  isShowPaymentSchedule = signal<boolean>(false);

  // contextMenu;
  isLabel: boolean = false;
  user_id;

  isKeyboard: boolean = false;
  refreshCardResult: RefreshCardResult = 'Tak';
  focusOnLastRow: RefreshCardResult = 'Nie';
  isPzPostponed: boolean = false;
  DEtykietyDokHandl: boolean = false;
  UEtykietyDokHandl: boolean = false;
  hideCorrect: boolean = false;
  InputMode: Number = 0;
  contextMenuPositionSignal = computed(() => {
    return [
      {
        text: this.translate.instant('recordCard.articleCard'),
        disabled: !this.articleCardPermission,
        itemIndex: '1111_000_KARTA_ART',
      },
      {
        text: this.translate.instant('warehouseDocument.relatedItems'),
        disabled: false,
        itemIndex: 0,
      },
      {
        text: this.translate.instant('offers.card'),
        disabled: false,
        itemIndex: 1,
      },
    ];
  });

  contextMenuCorection = [
    {text: 'Zaznacz dokument i jego korekty', disabled: false, itemIndex: 0},
  ];

  contextMenuOnline = [
    {text: 'Sprawdź/potwierdź', disabled: false, itemIndex: 0},
  ];

  sumFilters: any;
  pinnedViewsSub: Subscription = null;
  deleteFilter = false;
  advancedFilterTemplateData = null;

  showWarehouseDocument: boolean = false;
  focusedSelectedWarehouseDocument: any = null;
  isCorrectWarehouseDocument: boolean = false;
  showOrderDocuments: boolean = false;
  eDokDokHandl: boolean = false;
  refreshPrintSettings: boolean = false;
  userId: number = null;
  selectedDocumentsData: InvoiceDocument[];
  sendToEDocumentsAfterSave: any;
  eDocumentsVisible: boolean = false;
  eDocumentsCode: string = 'Trade';
  paramsDocumentHasAlreadyBeenSentToTheBuffer: WritableSignal<paramsDocumentHasAlreadyBeenSentToTheBuffer> =
    signal({
      InvoiceDocumentNumber: '',
    });
  isDocumentHasAlreadyBeenSentToTheBufferVisible: boolean = false;
  perBLBC = null;

  additionalOperationList = signal<any[]>([]);
  isEditDeleteDisabled = signal<boolean>(true);
  GenDokSer = signal<boolean>(false);
  isSerialGeneratorDoc = signal<boolean>(false);

  contextMenuSignal = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: !this.perABD.addBtn || this.event.readOnly,
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        disabled:
          !this.perABD.editBtn ||
          this.isEditDeleteDisabled() ||
          this.event.readOnly,
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        disabled:
          !this.perABD.deleteBtn ||
          this.isEditDeleteDisabled() ||
          this.event.readOnly,
      },
      {
        text: this.translate.instant('buttons.show'),
        icon: 'icon absui-icon--form-items',
        itemIndex: 3,
        disabled: !this.perABD.showBtn || this.isEditDeleteDisabled(),
      },
      {
        text: this.translate.instant('buttons.duplicate'),
        itemIndex: 13,
        icon: 'icon absui-icon--files-copy',
        disabled:
          !this.PDokHandl || this.isEditDeleteDisabled() || this.event.readOnly,
      },
      {
        text: this.translate.instant('repairFunctions.selectAll'),
        icon: 'icon absui-icon--checkbox-all',
        itemIndex: 4,
      },
      {
        text: this.translate.instant('articles.setUnselectedAll'),
        icon: 'icon absui-icon--deselct-all',
        itemIndex: 5,

        disabled: this.selectedAmount() === 0,
      },
      {
        text: this.translate.instant('showSelected'),
        icon: '',
        itemIndex: 6,
        items: [],

        disabled: this.selectedAmount() === 0,
      },
      {
        text: this.translate.instant(
          'form-commercial-operation.paymentSchedule'
        ),
        icon: '',
        itemIndex: 25,
        items: [],
      },

      {
        text: this.translate.instant(
          'form-commercial-operation.serialDocumentGenerator'
        ),
        disabled: !this.GenDokSer(),
        icon: '',
        itemIndex: 15,
        items: [],
        visible:
          this.event.footerInfo.VariantName !== 'START' &&
          this.event.footerInfo.VariantName !== 'BIZNES' &&
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE',
      },
      {
        text: this.translate.instant('labels.labels'),
        items: [
          {
            text: this.translate.instant('labels.assign'),
            itemIndex: 7,
            disabled: !this.DEtykietyDokHandl,
          },
          {
            text: this.translate.instant('labels.delete'),
            itemIndex: 8,
            disabled: !this.UEtykietyDokHandl,
          },
        ],
      },
      {
        text: this.translate.instant(
          'form-commercial-operation.additionalOperations'
        ),
        items: this.additionalOperationList(),
        visible: this.additionalOperationList().length > 0,
      },
      {
        text: this.translate.instant('tasks.addTask'),
        itemIndex: 10,
        items: [],
        visible:
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO',
      },
      {
        text: this.translate.instant('menu.eDocuments'),
        itemIndex: 30,
        disabled: !this.eDokDokHandl,
      },
    ];
  });
  contextMenuMobileSignal = computed(() => {
    return [
      {
        text: this.mobileSelectionText(),
        icon: this.mobileSelectionIcon(),
        itemIndex: 99,
      },
      {
        text: this.translate.instant('tasks.details'),
        icon: 'icon absui-icon--file-edit',
        itemIndex: 98,
      },
      {
        text: this.translate.instant('print'),
        icon: 'icon absui-icon--print',
        itemIndex: 96,
        disabled: this.isPrintDisabled(),
        visible: this.event.deviceType === 'mobile',
      },
      ...this.contextMenuSignal(),
    ];
  });
  mobileSelectionIcon = signal('icon checkAll');
  mobileSelectionText = signal('');
  isPrintDisabled = signal<boolean>(false);

  isRecordCardVisible: boolean = false;
  customerCardPermission: boolean;
  isNestedRecordCardVisible: boolean;
  nestedRecordCard: NestedRecordCard;
  recordCardMode: RecordCardMode = 'contractor';
  recordCardObjectId: number;
  articleCardPermission: boolean;
  selectedRecordTab: string;
  isMobileReportsPopupVisible: boolean;

  contextMenuPreparing(e: any): void {
    if (e.target == 'header') e.items = [];
  }

  onSetAdditionalOperation(e) {
    this.additionalOperationList.set(e);
  }

  disabledChange(e) {
    this.isEditDeleteDisabled.set(e);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private dynamicFilterService: DynamicFilterService,
    private interconnector: InterconnectorService,
    private nestService: NestService,
    private router: Router,
    private tableService: TableService,
    public appServices: AppServices,
    public cd: ChangeDetectorRef,
    public edocs: EDokumentsService,
    public formBuilder: FormBuilder,
    public print: PrintService,
    private printSettings: PrintSettingsService,
    private errorListService: ErrorListService,
    public notesService: NotesService
  ) {
    this.GenDokSer.set(this.event.checkPermissions('GenDokSer'));
    this.SumDokHandl = this.event.checkPermissions('SumDokHandl');
    this.print.onRefreshRequested.pipe(takeUntilDestroyed()).subscribe(() => {
      this.dataSource.reload();
    });
    this.translate
      .get('form-commercial-operation.selectTheDocumentAndItsCorrections')
      .subscribe((text) => {
        this.contextMenuCorection[0].text = text;
      });
    this.eDokDokHandl = this.event.checkPermissions('eDokDokHandl');
    this.translate
      .get('form-commercial-operation.check/cinfirm')
      .subscribe((text) => {
        this.contextMenuOnline[0].text = text;
      });

    this.translate.get('buttons.show').subscribe((text) => {
      this.contextMenuWarehouse[0].text = text;
      this.contextMenuOrders[0].text = text;
    });

    this.userId = this.event.returnUserId();
    this.translate.get('warehouseDocument.corrections').subscribe((text) => {
      this.contextMenuWarehouse[1].text = text;
    });

    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.readOnly = data;
      this.getDocuments();
      try {
        this.cd.detectChanges();
      } catch {
      }
    });

    this.user_id = helper.decodeToken(
      localStorage.getItem('WAPRO-HTML-token')
    ).user_id;
    if (!this.dropDownBoxMode || this.event.deviceType === 'mobile') {
      this.heightGrid = window.innerHeight - 176;
    }
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('selectMultiple')
      .subscribe((text) => (this.contextMenuOperationsMobile[0].name = text));

    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.PDokHandl = this.event.checkPermissions('PDokHandl');

    this.event.translateSearchboxLabels(this.filterCriteria);

    // this.contextMenu = [
    //   { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
    //   { text: 'Edytuj', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
    //   { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
    //   {
    //     text: 'Pokaż',
    //     icon: 'icon absui-icon--form-items',
    //     itemIndex: 3,
    //   },
    //   {
    //     text: 'Powiel',
    //     itemIndex: 13,
    //     icon: 'icon absui-icon--files-copy',
    //     disabled: !this.PDokHandl,
    //   },
    //   {
    //     text: 'Zaznacz wszystko',
    //     icon: 'icon absui-icon--checkbox-all',
    //     itemIndex: 4,
    //   },
    //   {
    //     text: 'Odznacz wszystko',
    //     icon: 'icon absui-icon--deselct-all',
    //     itemIndex: 5,
    //     isDependedForSelectedRow: true,
    //     disabled: true,
    //   },
    //   {
    //     text: 'Pokaż zaznaczone',
    //     icon: '',
    //     itemIndex: 6,
    //     items: [],
    //     isDependedForSelectedRow: true,
    //     disabled: true,
    //   },
    //   {
    //     text: 'Etykiety',
    //     items: [
    //       { text: 'Przypisz', itemIndex: 7 },
    //       { text: 'Skasuj', itemIndex: 8 },
    //     ],
    //   },
    //   {
    //     text: 'Operacje dodatkowe',
    //     items: [],
    //   },
    //   {
    //     text: 'Dodaj zadanie CRM',
    //     itemIndex: 10,
    //     items: [],
    //   },
    //   { text: 'e-Dokumenty', itemIndex: 30, disabled: !this.eDokDokHandl },
    // ];
    // this.translate
    //   .get('menu.eDocuments')
    //   .subscribe(
    //     (text) => (this.contextMenu.find((x) => x.itemIndex === 30).text = text)
    //   );
    // this.translate
    //   .get('mark')
    //   .subscribe((text) => (this.contextMenuMobile[0].name = text));
    // this.translate
    //   .get('tasks.details')
    //   .subscribe((text) => (this.contextMenuMobile[1].name = text));

    this.translate
      .get('form-commercial-operation.setFiscal')
      .subscribe((text) => {
        this.setFiscalConfirmTextFocus = text;
      });
    this.translate
      .get('form-commercial-operation.setFiscalMultiple')
      .subscribe((text) => {
        this.setFiscalConfirmTextSelect = text;
      });
    this.translate
      .get('form-commercial-operation.setNonFiscal')
      .subscribe((text) => {
        this.setNonFiscalConfirmTextFocus = text;
      });
    this.translate
      .get('form-commercial-operation.setNonFiscalMultiple')
      .subscribe((text) => {
        this.setNonFiscalConfirmTextSelect = text;
      });

    // this.contextMenu.forEach((field) => this.contextMenuMobile.push(field));

    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests || {};

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });

    this.viewUserDefinitionSub = this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          this.dynamicFilterUserDefinitions = data?.userDefinitions || [];
        },
      });
  }

  istoggleDefault = false;

  ngOnInit(): void {
    if (localStorage.getItem('ComercialOperationComponent') && !this.dropDownBoxMode) {
      this.localStorageData = JSON.parse(localStorage.getItem('ComercialOperationComponent'));
    }
    this.abortLoading = this.event.abortLoading.subscribe((abort: boolean) => {
      this.abortPrint = abort;
    });
    if (this.event.isAdditionalTableAvailable()) {
      this.additionalTables =
        this.event.getTablesByGroupCode('Dokument_handlowy');
    }

    this.perABD = this.event.checkPermissionsBtn(
      'DDokHandl',
      'EDokHandl',
      'UDokHandl',
      'ODokHandl'
    );

    this.DEtykietyDokHandl = this.event.checkPermissions('DEtykietyDokHandl');
    this.UEtykietyDokHandl = this.event.checkPermissions('UEtykietyDokHandl');

    this.getUserParameters();
    this.deselectAllRecords();

    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    this.permissionToChooseColums =
      this.event.checkPermissions('KonfKolDokHandl');
    this.permissionEksportExcel = this.event.checkPermissions('EksportExcel');
    this.getTypeTree();

    setTimeout(() => {
      if (!this.dropDownBoxMode) {
        this.closeNest = this.nestService.findNest(
          this.componentNests?.Sockets,
          'XGHLA'
        );
        this.findSocketAndRun('XGHLO1');
      } else {
        this.openNestFinished = true;
        this.cd.detectChanges();
      }
    });

    this.pinnedViewsSub = this.dynamicFilterService
      .getPinnedViewsFilters(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          if (!data) {
            return;
          }
          if (this.localStorageData?.advancedFilter) {
            this.localStorageData.advancedFilter.PinnedFilters =
              data.pinnedFilters;
          } else {
            this.localStorageData.advancedFilter = {
              PinnedFilters: data.pinnedFilters,
            } as any;
          }
        },
      });

    this.additionalOperationShortcutSub =
      this.event.additinalOperationShortcut$.subscribe((code: string) => {
        if (this.dropDownBoxMode) {
          return;
        }
        if (
          this.newRowPopupVisible ||
          this.isDeleteRow ||
          this.showRow ||
          this.editRow ||
          this.isShowSum ||
          this.isCorrection ||
          this.isLabel ||
          this.isCountrySelect ||
          this.isChangeJPK ||
          this.isAdvance ||
          this.isVerificationPosition
        ) {
          return;
        }

        this.contextMenuClick({
          itemData: {
            itemIndex: code,
          },
        });
      });
    this.printSettings.currentComponentName.set('comercialOperations');
    this.checkRecordCardPermission();

    if (!this.eDokDokHandl) {
      const index = this.bottomPanelTabs.findIndex(
        (tab) => tab === this.translate.instant('menu.eDocuments')
      );
      this.bottomPanelTabs.splice(index, 1);
    }

    if (
      this.event.returnEnvironmentInfo() === 'prod' ||
      this.event.returnEnvironmentInfo() === 'stage' ||
      this.event.returnEnvironmentInfo() === 'demo'
    ) {
      const keysToRemove = ['buffor.controlBusinessCheck', 'BusinessLink'];
      this.bottomPanelTabs = this.bottomPanelTabs.filter(
        (item) => !keysToRemove.some((key) => this.translate.instant(key) === item)
      );
    }

    if (
      this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE' ||
      this.event.footerInfo.VariantName === 'FAKTUROWANIE' ||
      this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE PRO' ||
      this.event.footerInfo.VariantName === 'FAKTUROWANIE PRO'
    ) {
      let index = this.bottomPanelTabs.findIndex(
        (tab) => tab === this.translate.instant('menu.warehouseDocuments')
      );
      if (index !== -1) {
        this.bottomPanelTabs.splice(index, 1);
      }

      index = this.bottomPanelTabs.findIndex(
        (tab) =>
          tab === this.translate.instant('form-financial-document.tabs.package')
      );
      if (index !== -1) {
        this.bottomPanelTabs.splice(index, 1);
      }
    }
  }

  checkRecordCardPermission(): void {
    const additionalOperations = this.event.decryptString(
      localStorage.getItem('appAdditionalOperationsPermissions')
    );
    const ART_BRW = additionalOperations.find(
      (op) => op.GroupCode === 'ART_BRW'
    );
    this.articleCardPermission = ART_BRW.Permissions.find(
      (x) => x.Shortcut === 'Ctrl+Shift+['
    )
      ? true
      : false;
  }

  additionalOperationShortcutSub: Subscription = null;

  ngOnChanges(changes: SimpleChanges): void {
    this.choosingInvoices = 0;
    if (this.selectedId && this.selectedId != 0) {
      this.appServices
        .getAuth(`invoices/documents?InvoiceDocumentId=${this.selectedId}`)
        .subscribe((res) => {
          this.invoicesList = res.data;
          this.choosingInvoices = Number(this.selectedId);
        });
    }
    if (changes?.isVisible?.currentValue) {
      this.deselectAllRecords();
    }
  }

  ngOnDestroy(): void {
    this.abortLoading?.unsubscribe();

    this.exportFakirService.isSelectMode.set(false);
    this.additionalOperationShortcutSub?.unsubscribe();
    this.tableService.removeTableContext('Dokument_handlowy');
    this.pinnedViewsSub?.unsubscribe();
    this.viewUserDefinitionSub?.unsubscribe();
    this.localStorageData.columns = this.event.setWidthColumnGrid(
      this.gridBank
    );

    if (this.event.deviceType != 'mobile') {
      // this.localStorageData.bottomPanel.height = `34px`;
      // alert(this.localStorageData.bottomPanel.height);
      if (
        localStorage.getItem('ComercialOperationComponent') &&
        JSON.stringify(this.localStorageData) !=
        localStorage.getItem('ComercialOperationComponent')
      ) {
        let object = {
          View: 'ComercialOperationComponent',
          Configuration: JSON.stringify(this.localStorageData),
          TenantId: this.event.footerInfo.TenantId,
        };
        localStorage.setItem(
          'ComercialOperationComponent',
          JSON.stringify(this.localStorageData)
        );
        this.appServices
          .postAuth(`viewConfigurations`, object)
          .subscribe(() => {
          });
      }
    }

    this.currentDateSub?.unsubscribe();
    this.isAutoOnboardingSub?.unsubscribe();
    this.activatedRouteSub?.unsubscribe();
    this.globalNestSub?.unsubscribe();
  }

  textBlock;
  isBlockRow: boolean = false;
  isCountrySelect: boolean = false;
  isChangesStatus: boolean = false;
  isVerificationPosition: boolean = false;
  isChangeJPK: boolean = false;

  paramsConfirmClone;
  isCloneDocument: boolean = false;

  isAllSelected: boolean = false;
  isClipboardVisible: boolean = false;
  onlyShow: boolean = false;
  objectToDelivery: any;
  isDelivery: boolean = false;

  onSingleRecordClosing() {
    this.isDelivery = false;
    this.printSettings.currentComponentName.set('comercialOperations');
    this.cd.detectChanges();
  }

  ratesNotifier: HTMLElement;
  notifyFunction = (message, visible) => {
    notify(
      {
        message: this.translate.instant(message),
        closeOnClick: true,
        position: {
          my: 'top right',
          at: 'top right',
          of: window,
          offset: '0px 80px',
        },
        accessKey: 'ratesNotifier',
        visible: visible,
        wrapperAttr: {
          id: 'ratesNotifier',
        },
        width: 300,
      },
      'info',
      1000000000
    );
    this.ratesNotifier = document.getElementById(
      'ratesNotifier'
    ) as HTMLElement;
  };
  showNotification: boolean = true;
  completedRequests = 0;
  errorRequests = 0;
  totalRequests = 0;
  successRequests = 0;
  confirmBufferSent = false;
  blockSentButton = false;
  abortLoading: Subscription;
  abortPrint: boolean = false;
  singleRequest = false;

  sendToBusinessCheckOrBL(url2: string, url3: string) {
    this.event.loadingVisible = false;
    this.onClosingConfirmPerBLBC();
    if (!this.readOnly) {
      this.event.abortLoading.next(false);
      this.showNotification = true;
      this.completedRequests = 0;
      this.errorRequests = 0;
      this.totalRequests = 0;
      this.successRequests = 0;
      this.totalRequests = this.selectedRows.length;
      this.blockSentButton = true;
      this.singleRequest = false;

      if (this.totalRequests >= 1) {
        this.event.onShown('Wysyłanie dokumentów', true, 'Przerwij wysyłanie');
      } else if (this.totalRequests === 0) {
        this.event.onShown('Wysyłanie dokumentu', true, 'Przerwij wysyłanie');
      }

      if (this.totalRequests > 0) {
        const rows$ = from(this.selectedRows);
        rows$
          .pipe(
            concatMap((selectedRow) => {
              if (!this.abortPrint) {
                const documentBody = {
                  DocumentType: 'DH',
                  DocumentId: selectedRow,
                  BusinessCheck: 0,
                  ErrorMessage: '',
                  Status: 0,
                  TenantBufferId: 0,
                };

                return this.sendRecord(documentBody, url2, url3).pipe(
                  catchError(() => {
                    this.errorRequests++;
                    if (
                      this.totalRequests === this.errorRequests ||
                      this.totalRequests === this.completedRequests
                    ) {
                      this.handleSummary();
                    }
                    return EMPTY;
                  })
                );
              } else {
                this.blockSentButton = false;
                this.event.onHidden();
                if (this.showNotification) {
                  this.event.showNotification('warning', 'Wysyłanie przerwane');
                  this.showNotification = false;
                }
                this.cd.detectChanges();
                return EMPTY;
              }
            })
          )
          .subscribe(
            () => {
              this.successRequests++;
              if (
                this.totalRequests === this.successRequests ||
                this.totalRequests === this.completedRequests
              ) {
                this.handleSummary();
              }
            },
            () => {
              if (
                this.totalRequests === this.errorRequests ||
                this.totalRequests === this.completedRequests
              ) {
                this.handleSummary();
              }
              return EMPTY;
            }
          );
      } else if (this.focusedSelected.length > 0 && this.totalRequests === 0) {
        this.totalRequests++;
        this.singleRequest = true;

        const documentBody = {
          DocumentType: 'DH',
          DocumentId: this.focusedSelected[0].InvoiceDocumentId,
          BusinessCheck: 0,
          ErrorMessage: '',
          Status: 0,
          TenantBufferId: 0,
        };

        this.sendRecord(documentBody, url2, url3)
          .pipe(
            catchError((error) => {
              this.errorRequests++;
              if (this.totalRequests === this.errorRequests) {
                this.event.httpErrorNotification(error);
                this.handleSummary();
              }
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.successRequests++;
            if (this.totalRequests === this.successRequests) {
              this.handleSummary();
            }
          });
      }
    }
  }

  sendRecord = (documentId, url2: string, url3: string) => {
    this.completedRequests++;
    return this.appServices
      .postAuth('buffer/documents/exportDocumentToBuffer', documentId)
      .pipe(
        concatMap((res1: any) => {
          if (res1.ErrorMessage) {
            this.errorRequests++;
            if (
              this.totalRequests === this.errorRequests ||
              this.totalRequests === this.completedRequests
            ) {
              this.handleSummary();
              this.handleError(
                res1.ErrorMessage,
                'buffer/documents/exportDocumentToBuffer'
              );
              if (this.singleRequest) {
                this.event.httpErrorNotification(res1.ErrorMessage);
              }
            }
            return EMPTY;
          } else {
            return this.appServices
              .postAuth(`${url2}/${res1.TenantBufferId}`, null)
              .pipe(
                concatMap((res2: any) => {
                  if (res2.Error) {
                    this.errorRequests++;
                    if (
                      this.totalRequests === this.errorRequests ||
                      this.totalRequests === this.completedRequests
                    ) {
                      this.handleSummary();
                      this.handleError(
                        res2.Error,
                        `${url2}/${res1.TenantBufferId}`
                      );
                    }
                    if (this.singleRequest) {
                      this.event.httpErrorNotification(res2.Error);
                    }
                    return EMPTY;
                  } else {
                    return this.appServices
                      .postAuth(`${url3}/${res2.Id}`, null)
                      .pipe(
                        concatMap((res3) => {
                          if (res3.Error) {
                            this.errorRequests++;
                            if (
                              this.totalRequests === this.errorRequests ||
                              this.totalRequests === this.completedRequests
                            ) {
                              this.handleSummary();
                              this.handleError(
                                res3.Error,
                                `${url3}/${res2.Id}`
                              );
                            }
                            if (this.singleRequest) {
                              this.event.httpErrorNotification(res3.Error);
                            }
                            return EMPTY;
                          } else {
                            return of(res3);
                          }
                        }),
                        catchError((error) => {
                          return throwError(error);
                        })
                      );
                  }
                }),
                catchError((error) => {
                  return throwError(error);
                })
              );
          }
        }),
        catchError((error) => {
          return throwError(error); // Przekazanie błędu
        })
      );
  };

  paramSentDocuments: WritableSignal<paramsSentDocuments> = signal({
    NumberOfDocumentsSent: 0,
    NumberOfMissedDocuments: 0,
  });
  genius: GeniusSummaryGroup[];
  cancelConfirmBufferSent = () => {
    this.confirmBufferSent = false;
    this.blockSentButton = false;
  };

  handleError = (res, url) => {
    const errorObj: ErrorListRecord = {
      date: new Date(),
      message: res.Error,
      statusCode: 201,
      url: url,
      method: 'POST',
    };
    this.errorListService.addLatestError(errorObj);
  };

  handleSummary = () => {
    this.paramSentDocuments.set({
      NumberOfDocumentsSent: this.successRequests,
      NumberOfMissedDocuments: this.errorRequests,
    });
    this.genius = [
      {
        items: [
          {
            description: 'buffor.Numberofdocumentssent',
            value: this.paramSentDocuments().NumberOfDocumentsSent,
          },
        ],
      },
      {
        items: [
          {
            description: 'buffor.Numberofmisseddocuments',
            value: this.paramSentDocuments().NumberOfMissedDocuments,
            highlighted: true,
          },
        ],
      },
    ];
    this.confirmBufferSent = true;
    this.getDocuments();
    this.event.onHidden();
    this.cd.detectChanges();
  };

  KSeFDocumentFocusedRowIndex = -1;
  detailsKSeFDocument = [];
  getDetailsKSeFDocument = (id) => {
    this.appServices
      .getAuth(`buffer/documents/${id}/getDocumentSendingStatuses`)
      .subscribe(
        (res) => {
          this.detailsKSeFDocument = res.data;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  controlBusinessCheckList: any = [];
  getControlBusinessCheckList = (id) => {
    this.controlBusinessCheckList = new DataSource({
      store: AspNetData.createStore({
        key: 'BufferDocumentSendStatusId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}buffer/documents/${id}/getDocumentBusienssCheckControl`,
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          const offset =
            this.controlBusinessCheckList.pageIndex() *
            this.controlBusinessCheckList.pageSize();
          for (let i = 0; i < data.length; i++) {
            data[i].lp = offset + i + 1;
          }
        },
      }),
    });
  };

  confirmPerBLBC = false;
  onClosingConfirmPerBLBC = () => {
    this.confirmPerBLBC = false;
  };

  returnSumPositionForFocusedRow() {
    return {
      TotalGrossAmount: this.focusedSelected[0].TotalGrossAmount,
      TotalNetAmount: this.focusedSelected[0].TotalNetAmount,
      TotalTaxAmount: this.focusedSelected[0].TotalTaxAmount,
      TotalGrossAmountCurrency:
      this.focusedSelected[0].TotalGrossAmountCurrency,
      TotalNetAmountCurrency: this.focusedSelected[0].TotalNetAmountCurrency,
      TotalTaxAmountCurrency: this.focusedSelected[0].TotalTaxAmountCurrency,
    };
  }

  returnTypDocument(): 's' | 'z' {
    const list = this.event.decryptString(localStorage.getItem('typesInvoice'));

    return list.find(
      (item) => item.TypeId === this.focusedSelected[0].DocumentTypeId
    ).DocumentType;
  }

  selectedOwnOperation: any = null;

  runOwnAdditionalOperation(operation: any) {
    this.selectedOwnOperation = operation;
    this.findSocketAndRun(
      `XGHLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E1`
    );
  }

  runInnerOwnOperation() {
    const data = this.event.prepareAdditionalOperationData(
      this.selectedOwnOperation,
      this.currentDate,
      this.selectedRows.length == 0
        ? this.focusedSelected[0].InvoiceDocumentId
        : null
    );
    this.event.runOwnAdditionalOperation(data).subscribe({
      next: () => {
        if (this.selectedOwnOperation?.RefreshList) {
          this.dataSource.reload();
        }
        this.findSocketAndRun(
          `XGHLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E2`
        );
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      },
    });
  }

  checkIfDocumentAddedToBuffer(
    selectedInvoiceNumber: string
  ): Observable<boolean> {
    return this.appServices
      .getAuth(`buffer/documents?DocumentNumber=${selectedInvoiceNumber}`)
      .pipe(
        map((res) => res.data.length > 0),
        catchError(() => {
          return of(false);
        })
      );
  }

  showDocumentInBuffer() {
    const selectedInvoiceNumber = this.focusedSelected[0].InvoiceDocumentNumber;
    this.checkIfDocumentAddedToBuffer(selectedInvoiceNumber).subscribe(
      (isDocumentInBuffer) => {
        if (!isDocumentInBuffer) {
          this.event.showNotification(
            'warning',
            this.translate.instant('buffor.noDocumentInTheBuffer')
          );
          return;
        } else {
          this.router.navigate(['/other/documents-buffer'], {
            state: {selectedInvoiceNumber},
          });
        }
      }
    );
  }

  templateArray = [];
  contextMenuClick = async (e) => {
    let BlokDokHandl = this.event.checkPermissions('BlokDokHandl');
    let DEtykietyDokHandl = this.event.checkPermissions('DEtykietyDokHandl');
    let UEtykietyDokHandl = this.event.checkPermissions('UEtykietyDokHandl');
    if (e.itemData.IsOuter) {
      this.runOwnAdditionalOperation(e.itemData);
      return;
    }
    switch (e.itemData.itemIndex) {
      case '1111_000_BL_POKAZ_E':
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.showDocumentInBuffer();
        }
        break;
      case 25:
        this.isShowPaymentSchedule.set(true);
        break;
      // case '1111_000_BL_DH_C':
      //   if (!this.readOnly) {
      //     this.sendToBusinessCheckOrBL(
      //       'businesslink/BlmtDocument/BlmtDocumentSendToBCRecordInit',
      //       'businesslink/BlmtDocument/BlmtDocumentSendToBC'
      //     );
      //   }
      //   break;
      case 15:
        this.templateArray = [];
        if (this.selectedRows.length === 0) {
          this.event.showNotification(
            'info',
            this.translate.instant(
              'form-commercial-operation.pleaseSelectDocumentTemplates'
            )
          );
        } else {
          let isTemplate = false;
          this.selectedRows.forEach((field) => {
            const find = this.dataSource._items.find(
              (doc) => doc.InvoiceDocumentId === field
            );

            if (find.IsTemplate) {
              this.templateArray.push(field);
              isTemplate = true;
            }
          });

          if (!isTemplate) {
            this.event.showNotification(
              'info',
              this.translate.instant(
                'form-commercial-operation.pleaseSelectDocumentTemplates'
              )
            );
            return;
          }
          this.isSerialGeneratorDoc.set(true);
        }

        break;
      case '1111_000_KARTA_KONTR':
        this.showRecordCard('contractor');
        break;
      case '1111_000_BL_DH_E':
        if (!this.readOnly && this.dataSource._items.length != 0) {
          this.checkKsefConfiguration();
        }
        break;
      case '1111_000_EKS_DO_JPK':
        this.isExportVisible.set(true);
        break;
      case '1111_000_PACZKAGEN':
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.appServices
            .postAuth(
              `invoices/additionalOperations/addPackage/${this.focusedSelected[0].InvoiceDocumentId}`,
              null
            )
            .subscribe((res) => {
              this.objectToDelivery = res;
              this.isDelivery = true;
              this.cd.detectChanges();
            });
        }
        break;

      case 0:
        if (!this.readOnly) {
          this.openNewRow();
        }
        break;
      case 1:
        if (!this.readOnly) {
          this.editDocument();
        }
        break;
      case 2:
        if (!this.readOnly) {
          this.isDelete();
        }
        break;

      case 3:
        this.showDocuments();
        break;
      case 4:
        this.skipSendSelectionEvent = true;
        this.gridBank.instance.selectAll();
        this.selectAll();
        break;
      // case 5:
      //   if (!this.readOnly) {
      //     this.gridBank.instance.deselectRows(
      //       this.focusedSelected[0].InvoiceDocumentId
      //     );
      //   }
      //   break;
      case 5:
        this.skipSendSelectionEvent = true;
        this.selectedRows = [];
        this.selectedDocumentsData = [];
        this.deselectAllRecords();
        break;
      case 6:
        if (this.onlySelected) {
          this.revertSelectOnly();
        } else {
          this.onOnlySelected();
        }
        break;
      case 7:
        if (!this.readOnly && DEtykietyDokHandl) {
          if (this.selectedRows.length == 0 && this.DEtykietyDokHandl) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.isLabel = true;
            this.cd.detectChanges();
          }
        }
        break;
      case 10:
        this.createCrmTask();
        break;
      case 8:
        if (!this.readOnly && UEtykietyDokHandl) {
          if (this.selectedRows.length == 0 && this.UEtykietyDokHandl) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.event.deleteLabel('invoices/selection/label');
            setTimeout(() => {
              this.selectedRows = [];
              this.selectedDocumentsData = [];
              this.dataSource.reload();
            }, 100);
          }
        }
        break;
      case 30:
        this.eDocumentsVisible = true;
        this.cd.detectChanges();
        break;
      case '1111_000_PZ_W_CZASIE':
        if (!this.readOnly) {
          this.isOutcome = false;
          this.findSocketAndRun('XGHLEXDOKHAN_BRWPZ_W_CZASIE_E1');
        }
        break;

      case '1111_000_FV_W_CZASIE':
        if (!this.readOnly) {
          this.isOutcome = true;
          this.isPzPostponed = true;
          this.cd.detectChanges();
        }
        break;
      case '1111_000_FN_DO_PAR':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWFN_DO_PAR_E1');
          }
        }
        break;
      case '1111_000_WERPOZDOK':
        if (!this.readOnly && this.focusedSelected.length > 0) {
          if (this.focusedSelected[0].IsCorrectionDocument) {
            this.event.showNotification(
              'info',
              this.translate.instant(
                'form-financial-document.theVerificationOperationIsNotAllowedForCorrectionDocuments'
              )
            );
            return;
          }
          this.findSocketAndRun('XGHLEXDOKHAN_BRWWERPOZDOK_E1');
        }
        break;
      case '0011_000_POLADODGRUP':
        this.onlyShow = false;
        this.additionalFieldsList = {
          Field1: '',
          Field2: '',
          Field3: '',
          Field4: '',
          Field5: '',
          Field6: '',
          Field7: '',
          Field8: '',
          Field9: '',
          Field10: '',
        };
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.InvoiceDocumentIdAdditionalField = null;

            this.findSocketAndRun('XGHLEXDOKHAN_BRWPOLADODGRUP_E1');
          }
        }
        break;
      case '0011_000_POLADOD':
        this.onlyShow = true;
        this.cd.detectChanges();
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.InvoiceDocumentIdAdditionalField =
            this.focusedSelected[0].InvoiceDocumentId;
          this.additionalFieldsList = this.focusedSelected[0];
          this.findSocketAndRun('XGHLEXDOKHAN_BRWPOLADOD_E1');
        }
        break;
      case '1111_000_DHIKOR':
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.selectInvoiceAndCorrections(
            this.focusedSelected[0].InvoiceDocumentId
          );
          this.cd.detectChanges();
        }
        break;
      case '1111_000_CENAKTDH':
        if (
          !this.readOnly &&
          this.focusedSelected.length > 0 &&
          this.perABD.editBtn
        ) {
          this.findSocketAndRun('XGHLEXDOKHAN_BRWCENAKTDH_E1');
        }
        break;
      case '1111_000_BLOKDOK':
        if (!this.readOnly && BlokDokHandl) {
          this.textBlock =
            'form-commercial-operation.doYouPerformDocumentBlock';
          this.findSocketAndRun('XGHLEXDOKHAN_BRWBLOKDOK_E1');
        }
        break;
      case '1111_000_ODBLOKDOK':
        if (!this.readOnly && BlokDokHandl) {
          this.textBlock =
            'form-commercial-operation.doYouPerformDocumentUnblock';
          this.findSocketAndRun('XGHLEXDOKHAN_BRWODBLOKDOK_E1');
        }
        break;
      case '1111_000_LINK_PLAT':
        if (this.selectedRows.length == 0) {
          this.event.showNotification(
            'info',
            this.translate.instant('buffor.selectLeastPosition')
          );
        } else {
          this.findSocketAndRun('XGHLEXDOKHAN_BRWLINK_PLAT_E1');
        }
        break;
      case '1111_000_BUFOR_DH_E':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWBUFOR_DH_E_E1');
          }
        }
        break;

      case '1111_000_KRAJ_VAT':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWKRAJ_VAT_E1');
          }
        }
        break;

      case '1111_000_DO_KSIEGOW':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWDO_KSIEGOW_E1');
          }
        }
        break;

      case '1111_000_OD_KSIEGOW':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWOD_KSIEGOW_E1');
          }
        }
        break;

      case '1111_000_ZMSTATDH':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWZMSTATDH_E1');
          }
        }
        break;

      case '1111_000_KLASJPK':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.isChangeJPK = true;
            this.cd.detectChanges();
          }
        }
        break;

      case 13:
        if (
          !this.readOnly &&
          this.PDokHandl &&
          this.focusedSelected.length > 0
        ) {
          this.paramsConfirmClone = {
            number: this.focusedSelected[0].InvoiceDocumentNumber,
          };
          this.isCloneDocument = true;
        }
        this.cd.detectChanges();
        break;

      case '1111_000_SCHOWVDH':
        this.isClipboardVisible = true;
        this.cd.detectChanges();
        break;

      case '1111_000_SCHOWCDH':
        this.copyToClipboard();
        break;

      case '1111_000_DRUKSERPAR':
        const defaultPrinterId =
          await this.interconnector.getDefaultFiscalPrinter(null, true);
        if (!defaultPrinterId) {
          this.event.showNotification(
            'error',
            this.translate.instant('fiscalPrinter.noFiscalPrinter')
          );
          return;
        }
        if (!this.selectedDocumentsData?.length) {
          this.event.showNotification(
            'info',
            this.translate.instant(
              'form-commercial-operation.noCommercialDocumentsSelected'
            )
          );
          return;
        }

        this.print.newTask.next({
          event: 'batchFiscalPrint',
          data: this.selectedDocumentsData,
        });

        break;

      case 99:
        this.modeSelectionMobile = 'multiple';
        let row = this.gridBank.instance.getDataSource().items()[
          this.focusedRowIndex
          ];
        const id = row.InvoiceDocumentId;
        if (!this.selectedRows.includes(id)) {
          this.gridBank.instance.selectRows(id, true);
        } else {
          this.gridBank.instance.deselectRows(id);
        }
        this.isContextMenuMobile = false;
        break;
      case 98:
        this.isContextMenuMobile = false;
        this.localStorageData.bottomPanel.isVisible = true;
        this.localStorageData.bottomPanel.height = 230;
        this.getDetails(this.focusedSelected[0].InvoiceDocumentId);
        this.isDetails = true;
        this.cd.detectChanges();
        break;
      case 97:
        this.isContextMenuMobile = false;
        this.sumOpen();
        break;
      case 96:
        this.isMobileReportsPopupVisible = true;
        this.cd.detectChanges();
        break;
      case '0111_000_DRUKETY':
        this.printLabels('goods');
        break;
      case '0111_000_DRUKETYADR':
        this.printLabels('address');
        break;
    }
  };

  integrationKSeFEnabled = null;
  tenantConfigurationList = null;
  automaticDocumentValidationBusinesscheck = false;

  checkKsefConfiguration(skipSlTenantParamsGet = false) {
    this.event.loadingVisible = true;

    if (!skipSlTenantParamsGet) {
      this.appServices
        .getAuth(
          `businesslink/SlTenantParams/SlTenantParamsGet?GroupName=BL\\Portal\\Konfiguracja\\KSeF`
        )
        .subscribe(
          (res) => {
            this.integrationKSeFEnabled = res.data.find(
              (item) => item.ParID === 'BL.KSEF.IntegrationEnabled'
            ).Value;

            if (this.integrationKSeFEnabled == 1) {
              this.checkBLBCParametr();
            } else {
              this.sendToBusinessCheckOrBL(
                'businesslink/BlmtDocument/BlmtDocumentSendRecordInit',
                'businesslink/BlmtDocument/BlmtDocumentSend'
              );
            }
          },
          (error) => {
            this.event.loadingVisible = false;
            this.event.httpErrorNotification(
              error + 'Błąd podczas pobierania parametrów KSeF'
            );
          }
        );
    } else {
      this.checkBLBCParametr();
    }
  }

  checkBLBCParametr() {
    this.perBLBC = this.event.getConfigurationParameter('BLBC', true);
    const permission = this.perBLBC.Value;
    console.log(permission);
    if (permission === 'Tak') {
      this.sendToBusinessCheckOrBL(
        'businesslink/BlmtDocument/BlmtDocumentSendToBCRecordInit',
        'businesslink/BlmtDocument/BlmtDocumentSendToBC'
      );
    } else if (permission === 'Nie') {
      this.sendToBusinessCheckOrBL(
        'businesslink/BlmtDocument/BlmtDocumentSendRecordInit',
        'businesslink/BlmtDocument/BlmtDocumentSend'
      );
    } else if (permission === 'Pytaj') {
      this.event.loadingVisible = false;
      this.confirmPerBLBC = true;
      this.cd.detectChanges();
    } else {
      this.event.loadingVisible = false;
    }
  }

  printLabels(type: 'goods' | 'address') {
    if (this.selectedRows.length != 0) {
      this.isLabelPrintOperationVisible = true;
      this.labelType = type;
    } else {
      this.event.showNotification(
        'info',
        this.translate.instant('buffor.selectLeastPosition')
      );
    }
  }

  labelType: 'goods' | 'address' = 'goods';
  isLabelPrintOperationVisible: boolean = false;

  onLabelPrintOperationClosing() {
    this.isLabelPrintOperationVisible = false;
    this.selectedRows = [];
    this.deselectAllRecords();
    this.cd.detectChanges();
  }

  onClosingClipboard = () => {
    setTimeout(() => {
      this.isClipboardVisible = false;
      this.cd.detectChanges();
    }, 1000);
  };

  taskSelectedId: any = [];

  createCrmTask() {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.event.onShown();
      this.appServices
        .postAuth(
          `invoices/additionalOperations/${this.focusedSelected[0].InvoiceDocumentId}/createCrmTask`,
          null
        )
        .subscribe(
          (res) => {
            this.taskSelectedId = [res['Id']];
            this.isTask = true;
            this.cd.detectChanges();
            this.event.onHidden();
          },
          (error) => {
            this.event.httpErrorNotification(error);
            this.event.onHidden();
          }
        );
    }
  }

  onTaskClosing = () => {
    this.isTask = false;
    this.cd.detectChanges();
  };

  async copyToClipboard() {
    if (this.selectedRows.length == 0 && this.focusedSelected.length == 1) {
      this.appServices
        .postAuth(
          `positionsClipboard/copyAllPositions?documentType=DOCUMENT_INVOICE&documentId=${this.focusedSelected[0].InvoiceDocumentId}`,
          {
            documentType: 'DOCUMENT_INVOICE',
            documentId: this.focusedSelected[0].InvoiceDocumentId,
          }
        )
        .subscribe(() => {
          this.appServices
            .putAuth(
              `invoices/documents/${this.focusedSelected[0].InvoiceDocumentId}`,
              {
                invoiceDocumentId: this.focusedSelected[0].InvoiceDocumentId,
              }
            )
            .subscribe(() => {
            });
        });
    }

    if (this.selectedRows.length > 0) {
      let forkArray = [];
      this.selectedRows.forEach((element) => {
        forkArray.push(
          this.appServices.postAuth(
            `positionsClipboard/copyAllPositions?documentType=DOCUMENT_INVOICE&documentId=${element}`,
            {
              documentType: 'DOCUMENT_INVOICE',
              documentId: element,
            }
          )
        );
      });

      forkJoin(forkArray).subscribe(() => {
      });
    }
  }

  fromClone;
  arrayToClone: any = [];

  checkArrayToClone = () => {
    if (this.arrayToClone.length > 0) {
      this.arrayToClone.splice(0, 1);
    }
  };

  cloneDocument = () => {
    this.isCloneDocument = false;
    this.cd.detectChanges();
    this.event.onShown();
    if (this.selectedRows.length > 1) {
      this.arrayToClone = this.selectedRows;
      let customerId = this.dataSource._items.find(
        (x) => x.InvoiceDocumentId == this.selectedRows[0]
      ).CustomerId;

      this.appServices
        .postAuth(`invoices/documents/${this.selectedRows[0]}/clone`, null)
        .subscribe(
          (res) => {
            this.fromClone = {
              InvoiceDocumentId: res.Id,
              CustomerId: customerId,
            };
            this.InputMode = 5;
            this.mode = 'add';
            this.newRowPopupVisible = true;
            this.event.onHidden();
            this.cd.detectChanges();
          },
          (error) => {
            this.event.onHidden();
            this.event.httpErrorNotification(error);
          }
        );
      return;
    }

    this.appServices
      .postAuth(
        `invoices/documents/${this.focusedSelected[0].InvoiceDocumentId}/clone`,
        null
      )
      .subscribe(
        (res) => {
          this.fromClone = {
            InvoiceDocumentId: res.Id,
            CustomerId: this.focusedSelected[0].CustomerId,
          };
          this.InputMode = 5;
          this.mode = 'add';
          this.newRowPopupVisible = true;
          this.event.onHidden();
          this.cd.detectChanges();
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  };

  onInsertedStatus = () => {
    this.isChangesStatus = false;
    this.dataSource.reload();
    this.findSocketAndRun('XGHLEXDOKHAN_BRWZMSTATDH_E2');
  };

  setDocumentForAccounting = () => {
    this.appServices
      .putAuth(`invoices/additionalOperations/setDocumentForAccounting`, null)
      .subscribe(
        () => {
          this.getDocuments();
          this.event.showNotification(
            'info',
            this.translate.instant(
              'warehouseDocument.theDocumentToBePostedWasMarked'
            )
          );
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHLEXDOKHAN_BRWDO_KSIEGOW_E2');
        }
      );
  };

  setBackDocumentForAccounting = () => {
    this.appServices
      .putAuth(
        `invoices/additionalOperations/setBackDocumentForAccounting`,
        null
      )
      .subscribe(
        () => {
          this.getDocuments();
          this.event.showNotification(
            'info',
            this.translate.instant('warehouseDocument.unlockedForEditing')
          );
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHLEXDOKHAN_BRWOD_KSIEGOW_E2');
        }
      );
  };

  onChoosedCountry = (e) => {
    this.isCountrySelect = false;

    if (e && e.length > 0) {
      this.appServices
        .putAuth(
          `invoices/additionalOperations/changeCountryOfVarRecords`,
          `"${e[0].CountryCode}"`,
          true
        )
        .subscribe(
          () => {
            this.getDocuments();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWKRAJ_VAT_E2');
          }
        );
    }
  };

  format(value) {
    return `Loading: ${value * 100}%`;
  }

  progrsValue: number = 0;
  isProgrss: boolean = false;
  DocNumber: string = '';

  async moveToBuffor(item, progrssCount, info) {
    return new Promise((resolve) => {
      this.appServices
        .putAuth(`invoices/additionalOperations/${item}/moveToBuffer`, {
          InvoiceDocumentId: item,
          IsContinue: true,
        })
        .subscribe(
          () => {
            if (info) {
              this.event.showNotification(
                'info',
                this.translate.instant('plannedOrders.addedToTheBuffer')
              );
            }

            const document = this.dataSource._items.find(
              (doc) => doc.InvoiceDocumentId == item
            );
            this.DocNumber = document.InvoiceDocumentNumber;
            this.progrsValue += Number(progrssCount);
            this.cd.detectChanges();
            if (Math.round(this.progrsValue) == 100) {
              setTimeout(() => {
                this.isProgrss = false;
                this.selectedRows = [];
                this.selectedDocumentsData = [];
                this.getDocuments();
                this.cd.detectChanges();
              }, 1000);
            }

            resolve(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
            this.progrsValue += Number(progrssCount);
            this.cd.detectChanges();
            if (Math.round(this.progrsValue) == 100) {
              this.isProgrss = false;
              this.cd.detectChanges();
            } else {
              resolve(true);
            }
          },
          () => {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWBUFOR_DH_E_E2');
          }
        );
    });
  }

  isAlreadySent: boolean = false;
  reUploadToTheBuffer = () => {
    this.addToBuffor();
  };

  async waitOperation(item, progrssCount, info) {
    await this.moveToBuffor(item, progrssCount, info);
  }

  async addToBuffor() {
    this.isDocumentHasAlreadyBeenSentToTheBufferVisible = false;
    this.progrsValue = 0;
    this.isProgrss = true;
    let progrssCount = (100 / this.selectedRows.length).toFixed(2);

    for (const item of this.selectedRows) {
      if (this.selectedRows.at(-1) === item) {
        await this.waitOperation(item, progrssCount, true);
      } else {
        await this.waitOperation(item, progrssCount, false);
      }
    }
  }

  generateOnlinePaymentLink = () => {
    this.appServices
      .postAuth(`invoices/additionalOperations/generateOnlinePaymentLink`, null)
      .subscribe(
        (res) => {
          this.event.showNotification('info', res.MessageContent);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHLEXDOKHAN_BRWLINK_PLAT_E2');
        }
      );
  };

  blockUnblockDocument = () => {
    let idDoc =
      this.selectedRows.length > 0
        ? 0
        : this.focusedSelected[0].InvoiceDocumentId;
    if (
      this.textBlock == 'form-commercial-operation.doYouPerformDocumentBlock'
    ) {
      this.appServices
        .putAuth(`invoices/additionalOperations/${idDoc}/block`, null)
        .subscribe({
          next: () => {
            this.event.showNotification(
              'info',
              this.translate.instant(
                'form-commercial-operation.theDocumentHasBeenBlocked'
              )
            );
            this.dataSource.reload();
            this.selectedRows = [];
            this.selectedDocumentsData = [];
            this.sendToEDocumentsAfterBlocking();
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
          complete: () => {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWBLOKDOK_E2');
          },
        });
    }

    if (
      this.textBlock == 'form-commercial-operation.doYouPerformDocumentUnblock'
    ) {
      this.appServices
        .putAuth(`invoices/additionalOperations/${idDoc}/unblock`, null)
        .subscribe(
          () => {
            this.event.showNotification(
              'info',
              this.translate.instant(
                'form-commercial-operation.theDocumentHasBeenUnblocked'
              )
            );
            this.dataSource.reload();
            this.selectedRows = [];
            this.selectedDocumentsData = [];
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWODBLOKDOK_E2');
          }
        );
    }

    this.isBlockRow = false;
  };

  sendToEDocumentsAfterBlocking() {
    this.sendToEDocumentsAfterSave = this.event.getConfigurationParameter(
      'ZapiszObrazEDok',
      true
    ).Value;
    if (
      this.sendToEDocumentsAfterSave !==
      'Po zatwierdzeniu gdy dokument z zamówienia internetowego'
    ) {
      return;
    }

    if (this.selectedDocumentsData?.length) {
      this.reportsComponent.performBatchAction(
        this.selectedDocumentsData,
        'sendToEdocuments'
      );
    } else {
      this.reportsComponent.performBatchAction(
        this.focusedSelected,
        'sendToEdocuments'
      );
    }
  }

  changePositionsPriceForUpToDate = (id) => {
    this.appServices
      .putAuth(
        `invoices/additionalOperations/${id}/changePositionsPriceForUpToDate`,
        null
      )
      .subscribe(
        () => {
          this.mode = 'edit';
          this.newRowPopupVisible = true;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHLEXDOKHAN_BRWCENAKTDH_E2');
        }
      );
  };

  isSelectInvoiceAndCorrections: boolean = false;

  selectInvoiceAndCorrections = (id) => {
    this.onItemClickType({
      itemData: {
        InvoiceTypeTreeId: this.typeTreeGroup[0].InvoiceTypeTreeId,
        Name: this.typeTreeGroup[0].TypeName,
        InvoiceTypeIdShort: this.typeTreeGroup[0].invoiceTypeTreeId,
        InvoiceTypeTreeParentId: this.typeTreeGroup[0].invoiceTypeTreeParentId,
      },
    });

    this.treeView?.instance?.selectItem(
      this.typeTreeGroup[0].InvoiceTypeTreeId
    );

    this.appServices
      .putAuth(
        `invoices/additionalOperations/${id}/selectInvoiceAndCorrections`,
        null
      )
      .subscribe(
        () => {
          this.isSelectInvoiceAndCorrections = true;

          this.getDocuments();
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  isNonRevolvingInvoice: any = null;
  InvoiceDocumentIdAdditionalField: any = null;

  postAddNonRevolvingInvoiceDocumentForReceipt = () => {
    this.appServices
      .postAuth(
        `invoices/additionalOperations/addNonRevolvingInvoiceDocumentForReceipt`,
        null
      )
      .subscribe(
        (res) => {
          this.isNonRevolvingInvoice = res.Id;

          this.mode = 'add';
          this.newRowPopupVisible = true;
          this.selectedRows = [];
          this.selectedDocumentsData = [];
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHLEXDOKHAN_BRWFN_DO_PAR_E2');
        }
      );
  };

  visibleUserExtensions: boolean = false;
  additionalFieldsList = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
  };

  setAdditionalFields = (e) => {
    if (!this.InvoiceDocumentIdAdditionalField) {
      this.appServices
        .putAuth(`invoices/additionalOperations/setAdditionalFields`, e)
        .subscribe(
          () => {
            // this.visibleUserExtensions = false;
            this.additionalFieldsList = {
              Field1: '',
              Field2: '',
              Field3: '',
              Field4: '',
              Field5: '',
              Field6: '',
              Field7: '',
              Field8: '',
              Field9: '',
              Field10: '',
            };
            this.dataSource.reload();
            this.selectedRows = [];
            this.selectedDocumentsData = [];
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWPOLADODGRUP_E2');
          }
        );
    } else {
      this.appServices
        .putAuth(
          `invoices/additionalOperations/${this.InvoiceDocumentIdAdditionalField}/setAdditionalFields`,
          e
        )
        .subscribe(
          () => {
            this.visibleUserExtensions = false;
            this.additionalFieldsList = {
              Field1: '',
              Field2: '',
              Field3: '',
              Field4: '',
              Field5: '',
              Field6: '',
              Field7: '',
              Field8: '',
              Field9: '',
              Field10: '',
            };
            this.dataSource.reload();
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            this.findSocketAndRun('XGHLEXDOKHAN_BRWPOLADOD_E2');
          }
        );
    }
  };

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
  }

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.localStorageData.filter.orderBy = e.selectedItem.value;
      this.localStorageData.filter.value = e.filterValue;
      this.filterValue = e.filterValue;
      this.getDocuments(true);
    }
  }

  switchOrder() {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      return;
    }

    this.localStorageData.filter.order = 'ASC';
  }

  firstLoad = true;
  indexToFocus: number;
  setFocusOnLoad = false;

  getDocuments = (fromSearch: boolean = false, focusIndex?) => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          this.focusedRowIndex =
            data.length > this.event.initFocusedRowIndex('comercialOperation')
              ? this.event.initFocusedRowIndex('comercialOperation')
              : 0;
          this.cd.detectChanges();
          this.loadChildComponent = true;
          if (data.length == 0) {
            this.focusedSelected = [];

            this.documentWarehouse = null;
            this.positionList = [];
            this.orderList = [];
            this.correctionList = [];
            this.settlementsList = [];
            this.onlinePaymentsList = [];
            this.deliveryAddress = [];

            return;
          }

          this.invoicesList = data;
          const toAdd = data.filter(
            (el) =>
              el.IsSelected &&
              this.selectedRows.indexOf(el.InvoiceDocumentId) === -1
          );
          for (let item of toAdd) {
            this.selectedRows.push(item.InvoiceDocumentId);
            this.selectedDocumentsData.push(item);
            this.skipSendSelectionEvent = true;
          }
          const toDelete = [];
          for (let id of this.selectedRows) {
            const item = data.find((el) => el.InvoiceDocumentId === id);
            if (item && !item.IsSelected) {
              toDelete.push(id);
            }
          }
          for (let id of toDelete) {
            this.selectedRows.splice(
              this.selectedRows.findIndex((el) => el === id),
              1
            );
            this.selectedDocumentsData.splice(
              this.selectedDocumentsData.findIndex(
                (el) => el.InvoiceDocumentId === id
              ),
              1
            );
            this.skipSendSelectionEvent = true;
          }
          if (!this.dropDownBoxMode) {
            const instance = this.gridBank.instance;
            var ff = this.localStorageData.columns;
            ff = ff;
            this.localStorageData.columns.forEach((col, i) => {
              instance.columnOption(col.caption, 'visible', col.visible);
              instance.columnOption(col.caption, 'visibleIndex', i);
              instance.columnOption(col.caption, 'width', col.width);
              instance.columnOption(col.caption, 'fixed', col.fixed);
            });
          }
          // this.cd.detectChanges();
          if (this.firstLoad) {
            this.firstLoad = false;
            setTimeout(() => {
              const filters: any = this.getLoadParams();
              const totalCount: number = this.dataSource.totalCount();
              if (!totalCount) {
                return;
              }
              if (
                (filters?.order === 'ASC' && this.focusOnLastRow === 'Tak') ||
                (filters?.order === 'DESC' && this.focusOnLastRow === 'Nie')
              ) {
                this.indexToFocus =
                  this.event.initFocusedRowIndex('comercialOperation') === 0
                    ? totalCount - 1
                    : this.event.initFocusedRowIndex('comercialOperation');
                if (this.indexToFocus < 0) {
                  this.indexToFocus = data.length - 1;
                }
                const page = Math.floor(totalCount / this.pageSize);
                if (page > 0) {
                  this.setFocusOnLoad = true;
                  this.dataSource.pageIndex(page);
                  this.dataSource.load();
                } else {
                  this.focusedRowIndex = this.indexToFocus;
                  this.onFocusedRowChanged({
                    row: {data: data[this.focusedRowIndex]},
                  });
                  this.cd.detectChanges();
                }
              } else {
                this.focusedRowIndex =
                  data.length >
                  this.event.initFocusedRowIndex('comercialOperation')
                    ? this.event.initFocusedRowIndex('comercialOperation')
                    : 0;
                this.onFocusedRowChanged({
                  row: {data: data[this.focusedRowIndex]},
                });
                this.cd.detectChanges();
              }
              this.focusedSelected = [data[this.focusedRowIndex]];
              this.searchControl.focusInput();
            }, 200);
          }
          //else {
          //  if (data.length > 0) {
          //    this.focusedSelected = [data[this.focusedRowIndex]];
          //  }
          //}
          if (this.focusedSelected.length == 0) {
            this.documentWarehouse = null;
            this.positionList = [];
            this.orderList = [];
            this.correctionList = [];
            this.settlementsList = [];
            this.onlinePaymentsList = [];
            this.deliveryAddress = [];
          }
          this.isSelectInvoiceAndCorrections = false;

          if (fromSearch) {
            this.searchControl.focusInput();
          }

          this.isAllSelected = false;

          if (this.selectedRows.length) {
            this.selectedDocumentsData = data.filter(
              (el) => this.selectedRows.indexOf(el.InvoiceDocumentId) !== -1
            );
            this.cd.detectChanges();
            // this.gridBank.instance.selectRows(this.selectedRows, false);
          }

          if (focusIndex == 0) {
            setTimeout(() => {
              this.focusedRowIndex = focusIndex;
              if (data.length > 0) {
                this.onFocusedRowChanged({
                  row: {data: data[this.focusedRowIndex]},
                });
              } else {
                this.focusedSelected = [];
              }
              this.event.setFocus(this.gridBank);
              this.cd.detectChanges();
            }, 0);
          }

          if (this.arrayToClone.length > 0) {
            let customerId = data.find(
              (x) => x.InvoiceDocumentId == this.arrayToClone[0]
            ).CustomerId;

            this.appServices
              .postAuth(
                `invoices/documents/${this.arrayToClone[0]}/clone`,
                null
              )
              .subscribe(
                (res) => {
                  this.fromClone = {
                    InvoiceDocumentId: res.Id,
                    CustomerId: customerId,
                  };
                  this.InputMode = 5;
                  this.mode = 'add';
                  this.newRowPopupVisible = true;
                  this.event.onHidden();
                  this.cd.detectChanges();
                },
                (error) => {
                  this.event.onHidden();
                  this.event.httpErrorNotification(error);
                }
              );
          }
          setTimeout(() => {
            if (this.focusedRowIndex === -1) {
              this.focusedRowIndex = 0;
            }
            if (data.length > this.focusedRowIndex) {
              this.focusedSelected = [data[this.focusedRowIndex]];
              this.getDetails(this.focusedSelected[0]?.InvoiceDocumentId);
            } else {
              this.focusedRowIndex = 0;
            }
            this.cd.detectChanges();
          }, 0);
          this.event.onHidden();
        },
        deleteUrl: `${environment.domain}invoices/documents`,
      }),
      reshapeOnPush: true,
      requireTotalCount: true,
      paginate: true,
    });
  };

  onFocusedRowSet(e) {
    if (this.dataSource && this.dataSource._items.length > 0) {
      this.focusedRowIndex = e;
    }
  }

  setFocusAfterLoad(data) {
    this.focusedRowIndex = this.indexToFocus;
    this.setFocusOnLoad = false;
    this.onFocusedRowChanged({row: {data: data[this.focusedRowIndex]}});
  }

  useForSelection: boolean = false;
  sendRequestTimer;
  onlySelected = false;
  skipSendSelectionEvent = false;

  onSelectionChanged = (e) => {
    this.selectedDocumentsData = e.selectedRowsData;
    if (this.skipSendSelectionEvent) {
      this.toSelect = [];
      this.toDeselect = [];
      this.skipSendSelectionEvent = false;
      return;
    }

    if (!this.onlySelected) {
      if (
        e.currentDeselectedRowKeys.length &&
        e.currentDeselectedRowKeys.length === this.dataSource._totalCount
      ) {
        this.deselectAllRecords();
        return;
      }
    }
    this.toSelect = this.toSelect.concat(e.selectedRowKeys);
    this.selectedAmount.set(
      this.selectedAmount() + e.currentSelectedRowKeys.length
    );
    if (this.selectedAmount() < 0) {
      this.selectedAmount.set(0);
    }
    this.toDeselect = this.toDeselect.concat(e.currentDeselectedRowKeys);
    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (
      this.selectedRows.length === 0 ||
      (this.selectedRows.length &&
        this.selectedRows.length !== this.dataSource._items.length)
    ) {
      this.isAllSelected = false;
    }

    if (this.dataSource._items.length) {
      this.setSelectionTimeout();
    }
  };

  removeDuplicates = (array) => {
    const result = [];
    const blocker = {}; // zapobiaga duplikowaniu elementów
    for (const item of array) {
      if (blocker.hasOwnProperty(item)) {
        continue;
      }
      blocker[item] = true;
      result.push(item);
    }
    return result;
  };

  getDefaultFilter() {
    this.event
      .getDefaultFilter(this.DynamicFilterDictionaryCode)
      .subscribe((data: any) => {
        if (data) {
          this.event.convertDefaultFilter(
            this.DynamicFilterDictionaryCode,
            data
          );
          this.localStorageData.advancedFilter = {
            ...data,
            PinnedFilters:
              this.localStorageData?.advancedFilter?.PinnedFilters || [],
          };
          localStorage.setItem(
            'ComercialOperationComponent',
            JSON.stringify(this.localStorageData)
          );
          this.getDocuments();
        }
      });
  }

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toDeselect.length > 0) {
        const items = this.dataSource.items();
        const toRemove = [];
        for (let toDes of this.toDeselect) {
          const item = items.find((el) => el.InvoiceDocumentId === toDes);
          if (!item) {
            toRemove.push(this.toDeselect.indexOf(toDes));
          }
        }
        for (let i = toRemove.length - 1; i >= 0; i--) {
          this.toDeselect.splice(toRemove[i], 1);
        }
        if (this.toDeselect.length) {
          this.selectedAmount.set(
            this.selectedAmount() - this.toDeselect.length
          );
          if (this.selectedAmount() < 0) {
            this.selectedAmount.set(0);
          }
          this.event.selectRecords(
            `invoices/selection/documents/unselect`,
            this.toDeselect
          );
        }
        this.toDeselect = [];
      }
      if (this.toSelect.length > 0 && this.selectedRows?.length) {
        this.toSelect = [];
        this.event.selectRecords(
          `invoices/selection/documents/select`,
          this.selectedRows
        );
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  focusedRowIndex;
  keyDownTimeout = null;

  handleSearchFromGrid(key: string) {
    const orderByItem = this.filterCriteria.find(
      (el) => el.value === this.localStorageData.filter.orderBy
    );

    this.filterValue = this.event.setFilterValueBySearchKey(
      this.filterValue,
      key,
      orderByItem.type === 'data'
    );

    if (this.keyDownTimeout) {
      clearTimeout(this.keyDownTimeout);
    }
    this.keyDownTimeout = setTimeout(() => {
      if (orderByItem.type === 'data') {
        if (
          this.event.setCorrectDate(this.filterValue) !==
          this.paramsObj[this.localStorageData.filter.orderBy]
        ) {
          this.getDocuments();
        }
        return;
      }
      this.getDocuments();
    }, 300);
  }

  onKeyDown(e) {
    // if (e.event.keyCode === 122) {
    //   this.openSearchBarcode();
    // }
    if (e.event.shiftKey && e.event.key === 'J') {
      this.isExportVisible.set(true);
      e.event.preventDefault();
    }

    if (e.event.shiftKey && e.event.key === 'K') {
      this.selectInvoiceAndCorrections(
        this.positionFocused[0].InvoiceDocumentId
      );
      e.event.preventDefault();
    }
    if (e.event.key === 'F' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      if (this.event.footerInfo.AppUserId === 3000001) {
        this.showSetFiscalDialog();
      }
    } else if (e.event.key === 'O' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.additionalOperation.openList();
    } else if (e.event.key === 'E' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      if (this.eDokDokHandl) {
        this.edocs.getToEDocuments(
          this.eDocumentsCode,
          this.focusedSelected[0]?.InvoiceDocumentId,
          this.focusedSelected[0]?.InvoiceDocumentNumber
        );
      }
    } else if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.key === 'S' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.sumOpen();
    } else if (e.event.key === 'F9' && !e.event.ctrlKey && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.dateRange.openDateRange();
    } else if (e.event.key === 'F7') {
      e.event.preventDefault();
      if (e.event.shiftKey) {
        this.setCustomerFromFocusedRow();
      } else {
        this.isCustomerVisible = true;
      }
      this.cd.detectChanges();
    } else if (e.event.keyCode === 113) {
      //f2
      e.event.preventDefault();
    } else if (e.event.key === 'F6' && e.event.shiftKey) {
      e.event.stopPropagation();
      e.event.preventDefault();
      if (this.onlySelected) {
        this.revertSelectOnly();
      } else {
        this.onOnlySelected();
      }
    } else if (e.event.keyCode === 107) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.gridBank.instance.selectAll();
      this.selectAll();
    } else if (e.event.keyCode === 109) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.selectedRows = [];
      this.selectedDocumentsData = [];
      this.deselectAllRecords();
    } else if (
      this.event.isSearchGridKey(
        e.event.keyCode,
        e.event.altKey,
        e.event.ctrlKey
      )
    ) {
      this.handleSearchFromGrid(e.event.key);
    } else if (e.event.key === 'Enter') {
      if (this.selectedRows.length && this.exportFakirService.isSelectMode()) {
        this.selectChoosed();
      }
    } else if (e.event.key === 'Escape') {
      if (this.exportFakirService.isSelectMode()) {
        this.cancelChoosed();
      }
    } else if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    } else if (e.event.key === 'J' && e.event.ctrlKey && e.event.shiftKey) {
      this.printLabels('address');
    } else if (e.event.key === 'T' && e.event.ctrlKey && e.event.shiftKey) {
      this.printLabels('goods');
    }
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }
    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  onRowDblClick = (i?) => {
    if (i) {
      this.focusedSelected = [i];
    }
    if (!this.readOnly && !this.dropDownBoxMode) {
      if (this.doubleClickResult === 'Edycja' && this.perABD.editBtn) {
        this.editDocument();
      } else if (this.perABD.showBtn) {
        this.event.loadingVisible = true;
        this.mode = 'show';
        this.newRowPopupVisible = true;
        this.cd.detectChanges();
      }
    } else {
      this.onChoosingInvoices();
    }
  };

  onChoosingInvoices = () => {
    if (!this.readOnly) {
      this.choosingInvoices = this.focusedSelected[0]?.InvoiceDocumentId;
      this.onChoosed.emit(this.focusedSelected[0]);
      this.isGridBoxOpened = false;
      this.cd.detectChanges();
    }
  };

  onOptionChanged = (e) => {
    this.event.onOptionChanged(e);
    if (
      e.name === 'columns' &&
      e.fullName.indexOf('width') > -1 &&
      e.value != 'auto'
    ) {
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        const indexCol = this.localStorageData.columns.findIndex(
          (col) => col.name === this.gridBank.columns[colIndex].name
        );
        this.localStorageData.columns[indexCol].width = e.value;
      } catch (e) {
      }
    }

    setTimeout(() => {
      this.localStorageData.columns.forEach((field, index) => {
        field.visibleIndex = index;
      });
    }, 0);
  };

  myTimer;
  onFocusedRowChanged = (e) => {
    if (e && e.row) {
      this.focusedSelected = [e.row.data];

      this.event.setFocusedRowIndex('comercialOperation', this.focusedRowIndex);
      this.tableService.addTableContext({
        id: this.focusedSelected[0].InvoiceDocumentId,
        idParentObject: this.focusedSelected[0],
        tableName: 'Dokument_handlowy',
      });
      this.menuName = e.row.data?.MenuName || null;
      this.isDetailOnFocus = false;
      if (this.myTimer) {
        clearTimeout(this.myTimer);
      }

      this.myTimer = setTimeout(() => {
        this.myTimer = null;
        try {
          this.getDetails(this.focusedSelected[0].InvoiceDocumentId);
        } catch (e) {
        }
      }, 300);
    }
  };

  paramsObj;

  getLoadParams() {
    if (this.onlySelected) {
      this.paramsObj = {
        onlySelected: true,
      };
      return this.paramsObj;
    }
    let obj: any = {};

    (obj.orderBy = this.localStorageData.filter.orderBy),
      (obj.order = this.localStorageData.filter.order);

    obj.DateOfInvoiceDocumentStart =
      this.event.dataFormatted({value: this.currentDate.dateFrom}) || '';
    obj.DateOfInvoiceDocumentStop =
      this.event.dataFormatted({value: this.currentDate.dateTo}) || '';

    obj.InvoiceDocumentId = this.InvoiceDocumentId;
    obj.CustomerId = this.CustomerId;

    if (this.InvoiceDocumentId || this.CustomerId) {
      obj.DateOfInvoiceDocumentStart = null;
      obj.DateOfInvoiceDocumentStop = null;
    }
    if (this.typeFilter.invoiceTypeId && this.typeFilter.invoiceTypeId != -1) {
      obj.InvoiceTypeTreeId = this.typeFilter.invoiceTypeId;
    }

    if (this.typeFilter.invoiceTypeId == -1) {
      obj.InvoiceTypeTreeId = 0;
    }

    if (this.isSelectInvoiceAndCorrections) {
      obj.DateOfInvoiceDocumentStart = null;
      obj.DateOfInvoiceDocumentStop = null;
      obj.IsSelected = true;
    }

    // if(this.useForSelection) obj.UseForSelection = true

    if (this.filterValue) {
      switch (this.localStorageData.filter.orderBy) {
        case 'InvoiceDocumentNumber':
          obj.InvoiceDocumentNumber = this.filterValue;
          break;

        case 'DateOfInvoiceDocument':
          obj.DateOfInvoiceDocument = this.event.setCorrectDate(
            this.filterValue
          );
          if (this.filterValue != '' && this.filterValue != null) {
            obj.DateOfInvoiceDocumentStart = this.event.setCorrectDate(
              this.filterValue
            );
            obj.DateOfInvoiceDocumentStop = this.event.setCorrectDate(
              this.filterValue
            );
          }
          break;

        case 'CustomerName':
          obj.CustomerName = this.filterValue;
          break;

        case 'TotalGrossAmount':
          obj.TotalGrossAmount = this.filterValue;
          break;

        case 'PayerName':
          obj.PayerName = this.filterValue;
          break;

        case 'DistinguishCode':
          obj.DistinguishCode = this.filterValue;
          break;

        case 'DateOfReceived':
          obj.DateOfReceived = this.filterValue;
          break;

        case 'EAN':
          obj.EAN = this.filterValue;
          break;
        case 'DateOfCreate':
          obj.DateOfCreate = this.filterValue;
          break;
        default:
          break;
      }
    }

    if (this.customerFilter.CustomerId) {
      obj.CustomerId = this.customerFilter.CustomerId;
    }

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    if (this.customerName && this.dropDownBoxMode) {
      obj = {
        CustomerName: this.customerName,
      };
    }

    if (!obj.InvoiceTypeTreeId) {
      obj.InvoiceTypeTreeId = 0;
    }

    this.paramsObj = obj;

    const url = window.location.pathname;
    if (url.includes('/edit')) {
      obj = {
        InvoiceDocumentId: this.invoiceDocumentIdUrl,
      };
    }
    return obj;
  }

  async refreshCard(e) {
    await this.dataSource.reload();
    this.focusedRowIndex = this.event.returnFocusIndex(
      this.dataSource._items,
      'InvoiceDocumentId',
      e?.invoiceDocument?.InvoiceDocumentId
    );
  }

  changeSize(size) {
    document.documentElement.style.setProperty('--dynamic-font-size', size);
    this.event.version = 'start';
  }

  openNewRow = () => {
    this.translate
      .get('form-commercial-operation.title')
      .subscribe((text) => (this.addEditTitle = text));

    if (!this.readOnly && this.perABD.addBtn) {
      this.findSocketAndRun('XGHLYD1');
    }
  };

  private readonly INCOME_DOCUMENT_TYPES = ['FZ', 'FR', 'FZUE', 'GVZ'];
  isIncomeDocument(): boolean {
    if(!this.event.footerInfo.VariantName.includes('FAKTUROWANIE')) return false

    if (!this.focusedSelected.length) {
      return false;
    }

    const normalizedSignature = this.focusedSelected[0].Signature.replace(/ /g, '');
    return this.INCOME_DOCUMENT_TYPES.includes(normalizedSignature);
  }

  showCorrect = () => {
    // if (
    //   this.focusedSelected[0].TypeOfDocument.replace(' ', '').indexOf('WZ') !=
    //     -1 ||
    //   this.focusedSelected[0].TypeOfDocument.replace(' ', '').indexOf('PZ') !=
    //     -1
    // ) {
    this.isCorrection = true;
    this.cd.detectChanges();
    //   return;
    // }

    // this.event.showNotification(
    //   'info',
    //   this.translate.instant(
    //     'warehouseDocument.CorrectionsApplyOnlyWZandGRNdocuments'
    //   )
    // );
  };

  isAdvance: boolean = false;
  showAdvances = () => {
    this.isAdvance = true;
    this.cd.detectChanges();
  };

  invoiceDocumentIdUrl;

  ngAfterViewInit(): void {
    let idDocument;
    this.activatedRoute.params.subscribe((params) => {
      idDocument = parseInt(params['id']);
      this.invoiceDocumentIdUrl = idDocument;
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.InvoiceDocumentId = params.id;
      this.CustomerId = params.customerId;
      const type = params.type;
      if (type === 'buy') {
        this.typeFilter.invoiceTypeId = 6;
        this.typeFilter.typeName == 'Zakup';
      } else if (type === 'sell') {
        this.typeFilter.invoiceTypeId = 1;
        this.typeFilter.typeName == 'Sprzedaż';
      }
      if (params.customerId) {
        this.customerFilter.CustomerId = params.customerId;
        this.customerFilter.CustomerName = params.customerName;
      }
      if (this.InvoiceDocumentId || this.CustomerId || type) {
        this.getDocuments();
      }
    });

    this.activatedRoute.params.subscribe(
      (params) => (this.mode = params['mode'])
    );
    if (idDocument) {
      this.appServices
        .getAuth(`invoices/documents?InvoiceDocumentId=${idDocument}`)
        .subscribe((res) => {
          this.focusedSelected = [res.data[0]];
          if (this.mode == 'edit') {
            this.editDocument();
          }
          if (this.mode == 'show' && this.perABD.showBtn) {
            this.newRowPopupVisible = true;
            this.cd.detectChanges();
          }
        });
    }

    setTimeout(() => {
      this.event
        .getUserExtension('Dokument_handlowy', this.userExtensionColumns)
        .then((res) => {
          try {
            this.localStorageData.columns =
              this.event.getAdditionalColumnsCaptions(
                res,
                this.localStorageData.columns
              );
          } catch {
          }
        });

      if (
        this.router.url == '/trade-operations/trader-documents/add' ||
        this.router.url == '/trade-operations/sales' ||
        this.router.url == '/trade-operations/buy'
      ) {
        this.openNewRow();
      }
    }, 0);

    this.getCustomConfiguration();

    if (!this.additionalTables.length) {
      const index = this.bottomPanelTabs.findIndex(
        (tab) => tab === this.translate.instant('articles.externalTable')
      );
      if (index !== -1) {
        this.bottomPanelTabs.splice(index, 1);
      }
    }

    this.shortcuts.push(
      {
        key: 'alt + shift + l',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.sendToBusinessCheckOrBL(
              'businesslink/BlmtDocument/BlmtDocumentSendRecordInit',
              'businesslink/BlmtDocument/BlmtDocumentSend'
            );
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + shift + k',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length > 0) {
            this.showDocumentInBuffer();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + shift + c',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.sendToBusinessCheckOrBL(
              'businesslink/BlmtDocument/BlmtDocumentSendToBCRecordInit',
              'businesslink/BlmtDocument/BlmtDocumentSendToBC'
            );
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.getDocuments();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            return;
          }
          this.helpService.openHelp('sprzedaz-i-magazyn/dokumenty/handlowe/');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + o',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.shiftKey) {
            this.additionalOperation.openList();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + e',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.eDokDokHandl) {
            this.edocs.getToEDocuments(
              this.eDocumentsCode,
              this.focusedSelected[0]?.InvoiceDocumentId,
              this.focusedSelected[0]?.InvoiceDocumentNumber
            );
          }
        },
        preventDefault: true,
      },
      // {
      //   key: 'f11',
      //   allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      //   command: (e) => {
      //     let barcode = document.getElementById(`barcode${this.unicalGuid}`);
      //     try {
      //       barcode.click();
      //     } catch {}
      //   },
      //   preventDefault: true,
      // },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'f7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            this.setCustomerFromFocusedRow();
          } else {
            this.isCustomerVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + s',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.altKey) {
            this.sumOpen();
          }
          if (e.event.altKey && !this.readOnly) {
            this.router.navigateByUrl('trade-operations/sales');
          }
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (
            !this.editRow &&
            !this.showRow &&
            !this.newRowPopupVisible &&
            this.perABD.addBtn &&
            !this.isDeleteRow &&
            !this.isLabel &&
            !this.isCorrection &&
            !e.event.shiftKey
          ) {
            this.openNewRow();
          }

          if (
            !this.readOnly &&
            this.PDokHandl &&
            this.focusedSelected.length > 0 &&
            e.event.shiftKey
          ) {
            this.paramsConfirmClone = {
              number: this.focusedSelected[0].InvoiceDocumentNumber,
            };
            this.isCloneDocument = true;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },

      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (
            !this.editRow &&
            !this.showRow &&
            !this.newRowPopupVisible &&
            this.perABD.editBtn &&
            !this.isDeleteRow &&
            !this.isLabel &&
            !this.isShowSum &&
            !this.isCorrection &&
            this.focusedSelected.length > 0 &&
            !e.event.shiftKey
          ) {
            this.editDocument();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.editRow &&
            !this.showRow &&
            !this.newRowPopupVisible &&
            this.perABD.deleteBtn &&
            !this.isDeleteRow &&
            !this.isShowSum &&
            !this.isCorrection &&
            !this.isLabel &&
            this.focusedSelected.length > 0
          ) {
            this.isDelete();
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.showDocuments();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + k',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.editRow &&
            !this.showRow &&
            !this.newRowPopupVisible &&
            this.perABD.showBtn &&
            !this.isDeleteRow &&
            !this.isShowSum &&
            !this.isLabel &&
            !this.isCorrection &&
            this.focusedSelected.length > 0 &&
            !this.isIncomeDocument()
          ) {
            this.event.setFocus(this.gridBank);
            this.isCorrection = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + y',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.additionalTables.length) {
            return;
          }

          if (this.focusedSelected.length) {
            this.isExternalTableVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + z',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.altKey) {
            this.isAdvance = true;
            this.cd.detectChanges();
          }

          if (e.event.altKey && !this.readOnly) {
            this.router.navigateByUrl('trade-operations/buy');
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 0;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 1;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 2;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 4',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 3;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 4;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 5;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 6;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 7;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 8;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 0',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 9;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.localStorageData.bottomPanel.isVisible = true;
            this.localStorageData.bottomPanel.height = 230;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.selectedRightTabIndex === 0) {
            this.selectedRightTabIndex = 1;
            this.cd.detectChanges();
          }
          if (
            this.selectedRightTabIndex === 1 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
              this.cd.detectChanges();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.openAdvanceFiltersWithoutPanel();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            if (this.onlySelected) {
              this.revertSelectOnly();
            } else {
              this.onOnlySelected();
            }
          } else {
            this.openCustomDropDown = !this.openCustomDropDown;
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onlySelected = false;
          this.deleteFilter = !this.deleteFilter;
          if (!this.readOnly) {
            if (
              !this.advancedFilterTemplateData &&
              this.localStorageData.advancedFilter
            ) {
              this.localStorageData.advancedFilter =
                this.dynamicFilterService.createDefaultAdvancedFilter(
                  this.localStorageData.advancedFilter?.IsPinned,
                  this.localStorageData.advancedFilter.PinnedFilters
                );
              this.cd.detectChanges();
            }
            this.resetTypeShortcuts();
            this.resetContrahentFilterNoRefresh();
            //dodać kasowanie pdf (2 filtry)
            this.getDocuments();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          e.event.preventDefault();
          if (this.event.footerInfo.AppUserId === 3000001) {
            this.showSetFiscalDialog();
          }
        },
        preventDefault: true,
      },
      {
        key: 'enter',
        allowIn: [AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRows.length &&
            this.exportFakirService.isSelectMode()
          ) {
            this.selectChoosed();
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRows.length &&
            this.exportFakirService.isSelectMode()
          ) {
            this.cancelChoosed();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + shift + t',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.printLabels('goods');
        },
        preventDefault: true,
      },
      {
        key: 'alt + shift + T',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.printLabels('goods');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + j',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.printLabels('address');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + J',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.printLabels('address');
        },
        preventDefault: true,
      }
    );

    for (let table of this.additionalTables) {
      if (table.Shortcut) {
        this.shortcuts.push({
          key: table.Shortcut.toLowerCase(),
          allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
          command: () => {
            if (this.focusedSelected.length) {
              this.shortcutTable = [table];
              this.isShortcutTableOpened = true;
              this.cd.detectChanges();
            }
          },
          preventDefault: true,
        });
      }
    }

    setTimeout(() => {
      if (!this.readOnly) {
        this.readOnly = this.event.readOnly;
      }
    }, 500);

    if (
      !this.localStorageData.skipUserGuide &&
      this.isAutoOnboarding &&
      this.isInNormalMode()
    ) {
      this.isGuideActive = true;
      this.cd.detectChanges();
    }

    setTimeout(() => {
      this.cd.detectChanges();
    }, 0);
  }

  showDocuments() {
    if (
      !this.editRow &&
      !this.showRow &&
      !this.newRowPopupVisible &&
      this.perABD.showBtn &&
      !this.isDeleteRow &&
      !this.isShowSum &&
      !this.isLabel &&
      !this.isCorrection &&
      this.focusedSelected.length > 0
    ) {
      this.event.loadingVisible = true;
      this.mode = 'show';
      this.newRowPopupVisible = true;

      this.cd.detectChanges();
    }
  }

  isInNormalMode() {
    return !(this.dropDownBoxMode || this.event.deviceType === 'mobile');
  }

  onRowClick = (e) => {
    try {
      this.focusedSelected = [e.data];
    } catch {
    }
  };

  setUserWindowConf = () => {
    try {
      this.filterValue = this.localStorageData.filter.value;
      if (
        this.additionalTables.length &&
        this.localStorageData?.additionalTable?.TableId
      ) {
        const name = this.additionalTables.find(
          (el) => el.TableId === this.localStorageData?.additionalTable?.TableId
        )?.Name;

        const index = this.bottomPanelTabs.findIndex(
          (tab) => tab === this.translate.instant('articles.externalTable')
        );
        this.bottomPanelTabs[index] = name;
        this.localStorageData.additionalTable.text = name;
      }
      if (
        !this.localStorageData?.advancedFilter ||
        !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId
      ) {
        this.getDefaultFilter();
      } else if (
        this.localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
        this.localStorageData?.advancedFilter?.IsPinned
      ) {
        this.getSavedFilterDefinitions();
      }
      if (this.localStorageData?.advancedFilter?.PinnedFilters) {
        this.dynamicFilterService.addPinnedViewsFilters(
          this.DynamicFilterDictionaryCode,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      }

      if (this.localStorageData.tree.length > 0) {
        this.onItemClickType({
          itemData: {
            InvoiceTypeTreeId:
            this.localStorageData.tree[0].value.InvoiceTypeTreeId,
            Name: this.localStorageData.tree[0].value.TypeName,
            InvoiceTypeIdShort:
            this.localStorageData.tree[0].value.invoiceTypeTreeId,
            InvoiceTypeTreeParentId:
            this.localStorageData.tree[0].value.invoiceTypeTreeParentId,
          },
        });

        setTimeout(() => {
          this.treeView?.instance?.selectItem(
            this.localStorageData.tree[0].value.InvoiceTypeTreeId
          );
        }, 0);
      }

      this.selectedtabIndex = this.localStorageData.bottomPanel.selectedIndex;
      this.currentResizeIcon =
        this.resizeIcon[this.localStorageData.sidePanel.isVisible ? 0 : 1];

      if (typeof this.localStorageData.sidePanel.width != 'number') {
        this.width = Number(
          this.localStorageData.sidePanel.width.replace('px', '')
        );
      }
      if (typeof this.localStorageData.bottomPanel.height != 'number') {
        this.heightBottomGrid = Number(
          this.localStorageData.bottomPanel.height.replace('px', '')
        );
      }

      if (typeof this.localStorageData.bottomPanel.height == 'number') {
        this.heightBottomGrid = this.localStorageData.bottomPanel.height;
      }

      if (this.event.deviceType === 'desktop') {
        this.heightGrid = window.innerHeight - this.heightBottomGrid - 176;
      }
    } catch {
    }
    this.cd.detectChanges();
  };

  @ViewChild('treeView') treeView;
  // comercial-operation
  getCustomConfiguration = () => {
    if (
      !localStorage.getItem('ComercialOperationComponent') &&
      !this.dropDownBoxMode
    ) {
      this.appServices
        .getAuth(`viewConfigurations/ComercialOperationComponent`)
        .subscribe((res) => {
          if (res.Configuration != null) {
            localStorage.setItem(
              'ComercialOperationComponent',
              res.Configuration
            );
            this.localStorageData = JSON.parse(res.Configuration);
            this.setUserWindowConf();
          }
          this.getDocuments();
        });
      return;
    }

    if (!this.dropDownBoxMode) {
      this.localStorageData = JSON.parse(
        localStorage.getItem('ComercialOperationComponent')
      );

      this.setUserWindowConf();
    }

    if (
      this.router.url == '/trade-operations/sales' ||
      this.router.url == '/trade-operations/buy'
    ) {
      this.hideCorrect = true;
      this.cd.detectChanges();
      return;
    }

    this.getDocuments();
  };

  dontRefresh = false;
  closeNewComercialOperation = () => {
    this.isNonRevolvingInvoice = false;
    this.newRowPopupVisible = false;
    this.showRow = false;
    this.editRow = false;
    this.isDeleteRow = false;
    this.isShowSum = false;
    this.isCorrection = false;
    this.fromClone = null;
    this.InputMode = 0;
    this.refreshPrintSettings = true;
    this.printSettings.currentComponentName.set('comercialOperations');
    this.cd.detectChanges();
    // this.dataSource.reload().then(() => {
    //   this.gridBank.instance.focus();
    // });

    const url = window.location.pathname;
    if (url.includes('/edit')) {
      this.router.navigateByUrl('/trade-operations/trader-documents');
    }
  };

  //bottom panel resize
  heightBottomPanel;
  @ViewChild('tabPanel') tabPanel;
  heightBottomGrid = 210;

  selectedtabIndex = 0;
  timeoutHandler;

  //left panle resizing
  widthLeftPanel = 385;
  width = 345;

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  }

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 385) {
      width = 385;
    }
    if (width > 620) {
      width = 620;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;

    this.localStorageData.sidePanel = {
      isVisible: true,
      width: `calc(100% - ${rightWidth}px)`,
      selectedIndex: 0,
    };
    this.localStorageData.sidePanel.width = `${width}px`;
    this.localStorageData.mainPanelWidth = `calc(100% - ${rightWidth}px)`;
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  resizeIcon = [
    'icon absui-icon--arrow-drop-right',
    'icon absui-icon--arrow-drop-left',
  ];
  currentResizeIcon = this.resizeIcon[0];

  showRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '385px';
    this.width = 385;
    this.localStorageData.sidePanel.isVisible = true;
    this.cd.detectChanges();
    this.localStorageData.sidePanel.width = `385px`;
    this.localStorageData.mainPanelWidth = 'calc(100% - 385px)';
    this.rightPanel.nativeElement.style.width = `calc(100% - 385px)`;
    this.currentResizeIcon = this.resizeIcon[0];
  };

  hideRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '10px';
    this.width = 21;
    this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;

    this.localStorageData.sidePanel.isVisible = false;
    this.localStorageData.sidePanel.width = `21px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
    this.currentResizeIcon = this.resizeIcon[1];
  };

  resizeWindowBtn() {
    if (this.width > 21) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
    }
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  };
  //---------------------------------------------------------------------

  onItemClickTab = () => {
    if (!this.localStorageData.bottomPanel.isVisible) {
      this.localStorageData.bottomPanel.isVisible;
      this.localStorageData.bottomPanel.height = 230;
      this.heightBottomGrid = 205;
      this.heightGrid = window.innerHeight - this.heightBottomGrid - 225;

      this.cd.detectChanges();
    }

    this.gridBank?.instance?.updateDimensions();
  };

  onValueChangedPriceFilter = () => {
    // this.defaultPayer = e;
    // this.getIndywidualPrice();
  };

  focusedSelected = [];
  onSelectionTabChanged = () => {
    this.localStorageData.bottomPanel.selectedIndex = this.selectedtabIndex;
    try {
      if (this.focusedSelected[0].InvoiceDocumentId) {
        this.getDetails(this.focusedSelected[0].InvoiceDocumentId);
      }
    } catch {
    }
  };

  settlementsList;
  getDetails = (id) => {
    if (this.localStorageData.bottomPanel.isVisible) {
      // switch (this.selectedtabIndex) {
      switch (this.bottomPanelTabs[this.selectedtabIndex]) {
        // case 0:
        case this.translate.instant('menu.warehouseDocuments'):
          this.getConnectedWarehouseDocuments(id);
          break;
        // case 1:
        case this.translate.instant('form-financial-document.tabs.items'):
          this.getPositions(id);
          break;
        // case 2:
        case this.translate.instant('menu.orders'):
          this.getOrders(id);
          break;
        // case 3:
        case this.translate.instant('warehouseDocument.dockOriginalCorrection'):
          let idDocument = this.focusedSelected[0]?.InvoiceDocumentOrginalId
            ? this.focusedSelected[0]?.InvoiceDocumentOrginalId
            : this.focusedSelected[0]?.InvoiceDocumentId;
          this.getCorrectionList(idDocument);
          break;

        // case 4:
        case this.translate.instant('form-commercial-operation.clearances'):
          if (this.focusedSelected[0]?.InvoiceDocumentId) {
            this.getSettlementsList(this.focusedSelected[0]?.InvoiceDocumentId);
          }
          break;
        // case 5:
        case this.translate.instant('documentsOrders.onlinePayments'):
          this.getOnlinePayments(id);
          break;
        // case 6:
        case this.translate.instant(
          'form-commercial-operation.deliveryAddress'
        ):
          this.getDeliveryAddresses(id);
          break;
        // case 8:
        case this.translate.instant('form-financial-document.tabs.package'):
          this.getPackages(id);
          break;

        case this.translate.instant('menu.eDocuments'):
          this.eDocuments.getDocuments();
          this.cd.detectChanges();
          break;
        // case this.translate.instant('notes.notes'):
        // this.notesComponent.getNotes(id);
        // break;
        case this.translate.instant('buffor.controlBusinessCheck'):
          this.getControlBusinessCheckList(id);
          break;
        case 'BusinessLink':
          this.getDetailsKSeFDocument(id);
          break;
      }
    }
  };

  getSettlementsList(id) {
    this.settlementsList = new DataSource({
      store: AspNetData.createStore({
        key: 'PositionId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}finances/settlements/0/clearances`,
        loadParams: {documentId: id},
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
    this.cd.detectChanges();
  }

  onlinePaymentsList: any;

  getOnlinePayments(id) {
    this.onlinePaymentsList = new DataSource({
      store: AspNetData.createStore({
        key: 'OnlinePaymentDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${id}/onlinePayments`,
        loadParams: {ParentObjectId: id},
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
    this.cd.detectChanges();
  }

  positionList;
  getPositions = (id) => {
    this.positionList = new DataSource({
      store: AspNetData.createStore({
        key: 'WarehouseDocumentPositionId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${id}/positions`,
        loadParams: {ParentObjectId: id, IsCorrectionDocumentView: false},
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
    this.cd.detectChanges();
  };

  correctionList;
  getCorrectionList = (id) => {
    if (id == null || id == 'undefinde') {
      return;
    }
    this.correctionList = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${id}/corrections?isShowOnlyCorrections=false
        `,
        loadParams: {ParentObjectId: id},
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
    this.cd.detectChanges();
  };

  documentWarehouse: DataSource;
  getConnectedWarehouseDocuments = (id) => {
    this.documentWarehouse = new DataSource({
      store: AspNetData.createStore({
        key: 'WarehouseDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${id}/connectedWarehouseDocuments`,
        loadParams: {ParentObjectId: id},
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
    this.cd.detectChanges();
  };

  deliveryAddress = [];
  getDeliveryAddresses = (id) => {
    this.appServices.getAuth(`invoices/${id}/deliveryAddresses`).subscribe(
      (res) => {
        this.deliveryAddress = res.data;
        this.cd.detectChanges();
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  };

  packagesData = [];
  getPackages = (id) => {
    this.appServices
      .getAuth(
        `deliveries/operators/-1/packages?ObjectId=${id}&DictionaryCode=DOCUMENT_INVOICE`
      )
      .subscribe(
        (res) => {
          this.packagesData = res.data;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  orderList = [];
  getOrders = (id) => {
    this.appServices
      .getAuth(`orders/documents/connected?InvoiceDocumentId=${id}`)
      .subscribe(
        (res) => {
          this.orderList = res;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  paramsConfirmDelete: any = null;
  isDelete = () => {
    if (this.focusedSelected[0].IsCorrectionDocument) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'warehouseDocument.removingCorrectionsFromHereIsNotPossible'
        )
      );
      return;
    }

    if (
      this.focusedSelected.length > 0 &&
      !this.readOnly &&
      this.perABD.deleteBtn
    ) {
      this.paramsConfirmDelete = {
        number: this.focusedSelected[0].InvoiceDocumentNumber,
      };

      this.findSocketAndRun('XGHLYU1');
    }
  };

  onOpenedChanged = (e) => {
    this.cd.detectChanges();
    if (e) {
      this.getDocuments();
    }
  };

  setType = () => {
    if (this.router.url == '/warehouse/sales') {
      this.perABD = this.event.checkPermissionsBtn(
        'DPrzeceny',
        'EPrzeceny',
        'UPrzeceny',
        null
      );

      // Brak parametru "OPrzeceny" => sztywniaczek :)
      this.perABD.showBtn = true;
    }

    if (this.router.url == '/trade-operations/sales' && !this.readOnly) {
      //this.openNewRow();
      this.typeTreeGroup[0].items.forEach((field) => {
        if (field.Name == 'Sprzedaż') {
          this.typeFilter.invoiceTypeId = field.InvoiceTypeTreeId;
          this.typeFilter.typeName = field.Name;
          this.getDocuments();
        }
      });
      return;
    }

    if (this.router.url == '/trade-operations/buy' && !this.readOnly) {
      //   this.openNewRow();
      this.typeTreeGroup[0].items.forEach((field) => {
        if (field.Name == 'Zakup') {
          this.typeFilter.invoiceTypeId = field.InvoiceTypeTreeId;
          this.typeFilter.typeName = field.Name;
          this.getDocuments();
        }
      });
      return;
    }
  };

  removeCurrencyType(list) {
    for (let i = 0; i < list.length; i++) {
      let element = list[i];
      if (
        element.Name.includes('FUE') ||
        element.Name.includes('FZUE') ||
        element.Name.includes('FW')
      ) {
        list.splice(i, 1); // Usunięcie elementu, jeśli zawiera odpowiednią nazwę
        i--; // Zmniejszenie indeksu, aby przejrzeć następny element po usunięciu
      } else if (element.items && element.items.length > 0) {
        this.removeCurrencyType(element.items); // Rekurencja dla zagnieżdżonych elementów
      }
    }
    return list;
  }

  getTypeTree = () => {
    return new Promise((resolve) => {
      if (localStorage.getItem('InvoicesDocumentsType')) {
        let items = this.event.decryptString(
          localStorage.getItem('InvoicesDocumentsType')
        );

        if (
          this.event.footerInfo.VariantName === 'START' ||
          this.event.footerInfo.VariantName === 'BIZNES' ||
          this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE' ||
          this.event.footerInfo.VariantName === 'FAKTUROWANIE'
        ) {
          items = this.removeCurrencyType(items);
        }

        this.typeTreeGroup = [
          {
            expanded: true,
            InvoiceTypeTreeId: -1,
            InvoiceTypeTreeParentId: null,
            Name: 'Wszystko',
            items: items,
          },
        ];
        this.setType();
        resolve(true);
      } else {
        this.refreshTypeTree(resolve);
      }
    });
  };

  grouped;
  typeTreeGroup = [];
  refreshTypeTree = (resolve) => {
    this.appServices.getAuth(`invoices/documents/typeTree`).subscribe(
      (res) => {
        this.typeTree = res;

        if (
          this.event.footerInfo.VariantName === 'START' ||
          this.event.footerInfo.VariantName === 'BIZNES'
        ) {
          for (let i = 0; i < this.typeTree.length; i++) {
            let element = this.typeTree[i];
            if (
              element.Name.includes('FUE') ||
              element.Name.includes('FZUE') ||
              element.Name.includes('FW')
            ) {
              this.typeTree.splice(i, 1); // Usunięcie elementu, jeśli zawiera odpowiednią nazwę
              i--; // Zmniejszenie indeksu, aby przejrzeć następny element po usunięciu
            }
          }
        }

        this.grouped = _.groupBy(
          this.typeTree,
          (product) => product.InvoiceTypeTreeParentId
        );
        this.typeTreeGroup = [
          {
            expanded: true,
            InvoiceTypeTreeId: -1,
            InvoiceTypeTreeParentId: null,
            Name: 'Wszystko',
            items: this.childrenOf(null, true),
          },
        ];

        localStorage.setItem(
          'InvoicesDocumentsType',
          this.event.encryptString(this.childrenOf(null, true))
        );
        this.translate
          .get('constractors.filter.everything')
          .subscribe((text) => (this.typeTreeGroup[0].Name = text));

        if (resolve) {
          resolve(true);
        }

        this.appServices
          .getAuth(`document/sales/types/invoice`)
          .subscribe((res) => {
            localStorage.setItem(
              'typesInvoice',
              this.event.encryptString(res.data)
            );
            this.setType();
          });
      },
      (error) => {
        if (resolve) {
          resolve(false);
        }
        this.event.httpErrorNotification(error);
      }
    );
  };

  childrenOf(ParentPermissionId, expanded) {
    return (this.grouped[ParentPermissionId] || []).map((product) => ({
      expanded: expanded,
      InvoiceTypeTreeId: product.InvoiceTypeTreeId,
      InvoiceTypeTreeParentId: product.InvoiceTypeTreeParentId,
      Name: product.Name,
      items: this.childrenOf(product.InvoiceTypeTreeId, true),
    }));
  }

  delete = (e) => {
    const isDeleteOnlyInvoiceDocument =
      this.event.footerInfo.VariantName.includes('FAKTUROWANIE')
        ? false
        : !e[0].value;
    this.isDeleteRow = false;
    this.appServices
      .deleteAuth(
        `invoices/documents/${this.focusedSelected[0].InvoiceDocumentId}?isDeleteOnlyInvoiceDocument=${isDeleteOnlyInvoiceDocument}&IsCancel=false`
      )
      .subscribe(
        () => {
          this.gridBank.focusedRowIndex = 0;
          this.gridBank.instance.focus();
          this.gridBank.instance.deselectRows(
            this.focusedSelected[0].InvoiceDocumentId
          );
          this.focusedSelected = [];

          this.dataSource.reload();
        },
        (error) => {
          this.event.loadingVisible = false;
          this.gridBank.instance.focus();
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHLYU2');
        }
      );
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      this.gridBank.instance.focus();
    }, 500);
  }

  async onInsertedDocument(e: OnInsertedData) {
    this.sendToEDocumentsAfterSave = this.event.getConfigurationParameter(
      'ZapiszObrazEDok',
      true
    ).Value;
    this.focusedSelected = [e.document];
    this.InputMode = 0;
    this.fromClone = null;
    this.dontRefresh = true;

    this.isNonRevolvingInvoice = null;
    if (
      this.router.url != '/trade-operations/buy' &&
      this.router.url != '/trade-operations/sales'
    ) {
      // this.newRowPopupVisible = false;
      this.cd.detectChanges();
    }

    if (this.mode === 'add') {
      this.findSocketAndRun('XGHLYD2');
    } else if (this.mode === 'edit') {
      this.findSocketAndRun('XGHLYP2');
    }

    this.dataSource.reload().then((data) => {
      const index = data.findIndex(
        (x) => x.InvoiceDocumentId == e.document.InvoiceDocumentId
      );
      setTimeout(() => {
        if (index >= 0) {
          this.focusedRowIndex = index;
          this.focusedSelected = [data[index]];
          this.cd.detectChanges();
        }
      }, 500);

      this.cd.detectChanges();
    });
  }

  onDateRangeChoosed() {
    if (this.refreshCardResult === 'Tak') {
      this.onlySelected = false;
      this.dataSource = null;

      this.getDocuments();

      setTimeout(() => {
        this.cd.detectChanges();
      }, 1000);
    }
  }

  typeFilter = {
    invoiceTypeId: null,
    typeName: '',
    invoiceTypeIdShort: null,
    invoiceTypeTreeParentId: null,
  };

  resetType = () => {
    this.localStorageData.tree = [];
    this.typeFilter.invoiceTypeId = -1;
    this.typeFilter.typeName = '';
    this.treeView.instance.unselectAll();
    this.getDocuments();
    this.restoreTreeConfig();
  };

  resetTypeShortcuts() {
    this.localStorageData.tree = [];
    this.typeFilter.invoiceTypeId = -1;
    this.typeFilter.typeName = '';
    this.treeView.instance.unselectAll();
    this.restoreTreeConfig();
  }

  resetFiltr(filterType: string) {
    if (filterType === 'advancedFilter') {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned
        );
    } else if (filterType === 'onlySelected') {
      this.onlySelected = false;
    }
    this.getDocuments();
  }

  onItemClickType = (e) => {
    if (e.itemData.InvoiceTypeTreeId == -1) {
      this.typeFilter.invoiceTypeId = -1;
      this.typeFilter.invoiceTypeIdShort = null;
      this.typeFilter.invoiceTypeTreeParentId = null;
      this.localStorageData.tree = [];
    } else {
      let InvoiceTypeTreeId = e.itemData.InvoiceTypeTreeId;
      if (InvoiceTypeTreeId > 3) {
        InvoiceTypeTreeId = String(InvoiceTypeTreeId).substr(1, 3);
      }
      this.typeFilter.invoiceTypeId = e.itemData.InvoiceTypeTreeId;
      this.typeFilter.invoiceTypeIdShort = InvoiceTypeTreeId;
      this.typeFilter.invoiceTypeTreeParentId =
        e.itemData.InvoiceTypeTreeParentId;
      this.typeFilter.typeName = e.itemData.Name;

      let tree = this.localStorageData.tree.findIndex(
        (field) => field.key == 'TypeDocument'
      );

      if (tree == -1) {
        this.localStorageData.tree.push({
          key: 'TypeDocument',
          value: {
            InvoiceTypeTreeId: e.itemData.InvoiceTypeTreeId,
            TypeName: e.itemData.Name,
            invoiceTypeIdShort: InvoiceTypeTreeId,
            invoiceTypeTreeParentId: e.itemData.InvoiceTypeTreeParentId,
          },
        });
      } else {
        this.localStorageData.tree[tree].value = {
          InvoiceTypeTreeId: e.itemData.InvoiceTypeTreeId,
          TypeName: e.itemData.Name,
          invoiceTypeIdShort: InvoiceTypeTreeId,
          invoiceTypeTreeParentId: e.itemData.InvoiceTypeTreeParentId,
        };
      }
    }
    this.getDocuments(false, 0);
    this.selectedRecordTab = this.getSelectedRecordTab(e.itemData);
  };

  getSelectedRecordTab = (treeData: {
    InvoiceTypeTreeId: number;
    InvoiceTypeTreeParentId: number;
    Name: string;
    expanded: boolean;
    items: Array<any>;
    selected: boolean;
  }): string => {
    let mode: 'buy' | 'sell' = 'sell';

    if (
      treeData.InvoiceTypeTreeId === 6 || // Zakup
      treeData.InvoiceTypeTreeId === 7 || // Zakup - dokumenty pierwotne
      treeData.InvoiceTypeTreeId === 9 || // Zakup - dokumenty korygujące
      treeData.InvoiceTypeTreeParentId?.toString()[0] === '6' ||
      treeData.InvoiceTypeTreeParentId?.toString()[0] === '7' ||
      treeData.InvoiceTypeTreeParentId?.toString()[0] === '9'
    ) {
      mode = 'buy';
    }
    return this.translate.instant('dashboard.' + mode);
  };

  onSave = () => {
    this.isShowDocumentDescription = false;
    this.cd.detectChanges;

    // this.appServices
    //   .putAuth(
    //     `invoices/documents/${form.InvoiceDocumentId}/modifyRemarks`,
    //     {
    //       InvoiceDocumentId: form.InvoiceDocumentId,
    //       Remarks: this.Remarks,
    //     }
    //   )
    //   .subscribe(
    //     (res) => {
    //       this.getDocuments();
    //       this.isShowDocumentDescription = false;
    //       this.cd.detectChanges;
    //     },
    //     (error) => {
    //       this.event.loadingVisible = false;
    //       this.event.httpErrorNotification(error);
    //     }
    //   );
  };

  //getFocusedElement() {
  //  return this.dataSource.items().find((el, i) => this.focusedRowIndex === i);
  //}

  isModyfieOnlyRemarks: boolean = false;
  recalculatePosition: boolean = false;

  chechSignature() {
    return new Promise((resolve) => {
      if (!this.focusedSelected.length || !this.focusedSelected[0]) {
        resolve({});
      }
      let data: any = localStorage.getItem('typesInvoice');
      if (data) {
        data = this.event.decryptString(data);
        const el = data.find(
          (el) => el.TypeId == this.focusedSelected[0].DocumentTypeId
        );
        resolve(el || null);
      } else {
        this.appServices
          .getAuth(
            `document/sales/types/invoice?typeId=${this.focusedSelected[0]?.DocumentTypeId}`
          )
          .subscribe({
            next: (res) => {
              resolve(res.data[0]);
            },
            error: (error) => {
              this.event.httpErrorNotification(error);
              resolve({});
            },
          });
      }
    });
  }

  isOutcomeDocument: boolean = false;

  async editDocument(noAsk?) {
    //this.focusedSelected = [this.getFocusedElement()];
    if (!this.focusedSelected[0]) {
      return;
    }

    this.translate
      .get('form-commercial-operation.editInvoice')
      .subscribe((text) => (this.addEditTitle = text));

    this.recalculatePosition = false;
    if (this.readOnly) {
      return;
    }

    if (noAsk != 'yes') {
      const IsOutcome: any = await this.chechSignature();

      if (
        IsOutcome.DocumentType &&
        this.focusedSelected[0] &&
        IsOutcome.DocumentType.trim() == 'z' &&
        this.focusedSelected[0].InvoiceDocumentId ==
        this.focusedSelected[0].LastInvoiceDocumentCorrectionId &&
        this.focusedSelected[0].LastInvoiceDocumentCorrectionId != null
      ) {
        this.isOutcomeDocument = true;
        this.cd.detectChanges();
        return;
      }
    }

    if (noAsk != 'yes') {
      this.isModyfieOnlyRemarks = false;
    }

    if (
      noAsk != 'yes' &&
      this.focusedSelected[0] &&
      this.focusedSelected[0].InvoiceDocumentId !=
      this.focusedSelected[0].LastInvoiceDocumentCorrectionId &&
      this.focusedSelected[0].LastInvoiceDocumentCorrectionId != null
    ) {
      setTimeout(() => {
        this.isAddDescription = true;
        this.cd.detectChanges();
      }, 500);
      return;
    }

    if (
      this.focusedSelected[0] &&
      this.focusedSelected[0].LastInvoiceDocumentCorrectionId == null
    ) {
      this.recalculatePosition = true;
      this.cd.detectChanges();
    }

    if (!this.readOnly && !this.editRow) {
      this.findSocketAndRun('XGHLYP1');
    }
  }

  beforeEditDocument() {
    this.event.loadingVisible = true;
    this.appServices
      .putAuth(
        `invoices/documents/${this.focusedSelected[0].InvoiceDocumentId}/prepareForModify`,
        {
          invoiceDocumentId: this.focusedSelected[0].InvoiceDocumentId,
          isCorrection: this.focusedSelected[0].IsCorrectionDocument,
        }
      )
      .subscribe(
        () => {
          this.event.loadingVisible = false;
          this.mode = 'edit';
          this.newRowPopupVisible = true;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.loadingVisible = false;
          this.cd.detectChanges();
          this.event.httpErrorNotification(error);
        }
      );
  }

  onClosingCorrection = () => {
    this.dataSource.reload().then(() => {
      setTimeout(() => {
        this.searchControl?.focusInput();
      }, 0);
    });
  };

  onChoosedLabel = () => {
    this.selectedRows = [];
    this.selectedDocumentsData = [];
    this.isLabel = false;
    this.dataSource.reload();
  };

  clearMobileFilters = () => {
    this.resetType();
  };

  getUserParameters() {
    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }

    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }

    const focusOnLastRow = this.event.getConfigurationParameter('NaOstatZapis');
    if (focusOnLastRow) {
      this.focusOnLastRow = focusOnLastRow.Value as RefreshCardResult;
    }
  }

  isDetailOnFocus = false;

  onFocusElementChanged(e: boolean) {
    this.isDetailOnFocus = e;
  }

  onCellClick(e) {
    if (this.event.deviceType != 'mobile') {
      if (!e?.row?.cells) {
        return;
      }

      for (let cell of e?.row?.cells) {
        this.event.removeFocusClassFromCell(cell.cellElement);
      }
      e.cellElement.className += ' dx-focused';
    }
  }

  currentFocusedCell = null;

  onFocusedCellChanged(e) {
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    this.currentFocusedCell = e.cellElement[0];
  }

  positionFocused: any[] = [];

  onFocusedRowChangedDetail(e) {
    this.onFocusElementChanged(e.rowIndex > -1);
    this.positionFocused = [e.row.data];
  }

  showRelatedItems: boolean = false;
  contextPositionMenuClick = (e) => {
    if (this.positionFocused.length == 0) {
      return;
    }
    switch (e.itemData.itemIndex) {
      case 0:
        this.showRelatedItems = true;
        this.cd.detectChanges();
        break;
      case 1:
        this.router.navigate(['/library/articles'], {
          queryParams: {
            id: this.positionFocused[0].ProductId,
            extWarehouseId: this.positionFocused[0].WarehouseId,
          },
        });
        break;
      case '1111_000_KARTA_ART':
        this.showRecordCard('article');
        break;
      default:
        break;
    }
  };

  currentFocusedDetailCell = null;

  onFocusedCellDetailChanged(e) {
    if (this.currentFocusedDetailCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedDetailCell);
    }
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    this.currentFocusedDetailCell = e.cellElement[0];
  }

  visibleAdvanceFilters = false;

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'ComercialOperationComponent',
      JSON.stringify(this.localStorageData)
    );

    this.getDocuments();
  }

  onSelectionSideTabChanged() {
  }

  isGuideActive = false;
  isGuideButtonHighlighted = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) {
      this.isGuideButtonHighlighted = false;
    }
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  onGuideFinished() {
    this.isGuideActive = false;
    this.highlightGuideButton(true, 2000);
    this.localStorageData.skipUserGuide = true;
    localStorage.setItem(
      'ComercialOperationComponent',
      JSON.stringify(this.localStorageData)
    );
  }

  runGuide() {
    this.isGuideActive = true;
    this.cd.detectChanges();
  }

  sumPositionIncome;
  isAddDescription: boolean = false;
  isShowDocumentDescription: boolean = false;
  Remarks: String = '';
  addQuestion = () => {
    this.isModyfieOnlyRemarks = true;
    this.isOutcomeDocument = false;
    this.editDocument('yes');
    this.isAddDescription = false;
    this.cd.detectChanges;
  };

  addQuestionIncome = () => {
    this.isOutcomeDocument = false;
    this.editDocument('yes');
    this.cd.detectChanges;
  };

  onAdditionalTableChanged(e) {
    this.localStorageData.additionalTable = Object.assign({}, e);
    const index = this.bottomPanelTabs.findIndex(
      (tab) => tab === this.translate.instant('articles.externalTable')
    );
    this.bottomPanelTabs[index] = e.text;

    localStorage.setItem(
      'ComercialOperationComponent',
      JSON.stringify(this.localStorageData)
    );
  }

  additionalTables: AdditionalTable[] = [];
  shortcutTable;
  isShortcutTableOpened: boolean = false;
  isExternalTableVisible: boolean = false;

  menuName: string = null;

  openNestFinished = false;
  onInitErr: boolean = false;
  activatedRouteSub: Subscription;
  componentNests: Socket;
  nest: Socket;
  nestsSub: Subscription;
  isNestRunning = false;
  nestOperation: NestOperation;
  closeNest: Socket;
  globalNestSub: Subscription;

  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({SocketCode});
      return true;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGHLO1':
        this.openNestFinished = true;
        this.findSocketAndRun('XGHLO2');
        break;
      case 'XGHLO2':
        this.searchControl?.focusInput();
        break;
      case 'XGHLYD1':
        this.findSocketAndRun('XGHLYA1', 'add');
        break;
      case 'XGHLYA1':
        if (this.nestOperation === 'add') {
          this.mode = 'add';
          this.newRowPopupVisible = true;
          this.cd.detectChanges();
        } else if (this.nestOperation === 'edit') {
          this.beforeEditDocument();
        } else if (this.nestOperation === 'delete') {
          this.isDeleteRow = true;
          this.cd.detectChanges();
        }
        break;
      case 'XGHLYD2':
        this.findSocketAndRun('XGHLYA2');
        break;
      case 'XGHLYP1':
        this.findSocketAndRun('XGHLYA1', 'edit');
        break;
      case 'XGHLYP2':
        this.findSocketAndRun('XGHLYA2');
        break;
      case 'XGHLYU1':
        this.findSocketAndRun('XGHLYA1', 'delete');
        break;
      case 'XGHLYU2':
        this.findSocketAndRun('XGHLYA2');
        break;
      //operacje dod.

      case 'XGHLEXDOKHAN_BRWBLOKDOK_E1':
        this.isBlockRow = true;
        this.cd.detectChanges();
        break;
      case 'XGHLEXDOKHAN_BRWODBLOKDOK_E1':
        this.isBlockRow = true;
        this.cd.detectChanges();
        break;
      case 'XGHLEXDOKHAN_BRWFN_DO_PAR_E1':
        this.postAddNonRevolvingInvoiceDocumentForReceipt();
        break;
      case 'XGHLEXDOKHAN_BRWPOLADODGRUP_E1':
        this.visibleUserExtensions = true;
        this.cd.detectChanges();
        break;
      case 'XGHLEXDOKHAN_BRWPOLADOD_E1':
        this.visibleUserExtensions = true;
        this.cd.detectChanges();
        break;
      case 'XGHLEXDOKHAN_BRWDO_KSIEGOW_E1':
        this.setDocumentForAccounting();
        break;
      case 'XGHLEXDOKHAN_BRWOD_KSIEGOW_E1':
        this.setBackDocumentForAccounting();
        break;
      case 'XGHLEXDOKHAN_BRWCENAKTDH_E1':
        this.changePositionsPriceForUpToDate(
          this.focusedSelected[0].InvoiceDocumentId
        );
        break;
      case 'XGHLEXDOKHAN_BRWLINK_PLAT_E1':
        this.generateOnlinePaymentLink();
        break;
      case 'XGHLEXDOKHAN_BRWKRAJ_VAT_E1':
        this.isCountrySelect = true;
        this.cd.detectChanges();
        this.cd.detectChanges();
        break;
      case 'XGHLEXDOKHAN_BRWBUFOR_DH_E_E1':
        const selectedDocument = this.dataSource._items.find((item) => {
          return item.InvoiceDocumentId === this.selectedRows[0];
        });
        if (this.selectedRows.length == 1 && selectedDocument.IsSendToBLBuff) {
          this.paramsDocumentHasAlreadyBeenSentToTheBuffer.set({
            InvoiceDocumentNumber: selectedDocument?.InvoiceDocumentNumber,
          });
          this.isDocumentHasAlreadyBeenSentToTheBufferVisible = true;
        } else {
          this.addToBuffor();
        }
        break;
      case 'XGHLEXDOKHAN_BRWPZ_W_CZASIE_E1':
        this.isPzPostponed = true;
        this.cd.detectChanges();
        break;
      case 'XGHLEXDOKHAN_BRWWERPOZDOK_E1':
        this.onPositionVerificationOpening();
        this.cd.detectChanges();
        break;
      case 'XGHLEXDOKHAN_BRWZMSTATDH_E1':
        this.isChangesStatus = true;
        this.cd.detectChanges();
        break;
      case `XGHLEX${
        this.selectedOwnOperation?.GroupCode
      }_${this.selectedOwnOperation?.Name?.toUpperCase()}_E1`:
        this.runInnerOwnOperation();
        break;
      default:
        break;
    }
  }

  onPostponedClosing() {
    this.isPzPostponed = false;
    this.getDocuments();
    this.findSocketAndRun('XGHLEXDOKHAN_BRWPZ_W_CZASIE_E2');
  }

  onPositionVerificationOpening() {
    this.isVerificationPosition = true;
  }

  onPositionVerificationClosing(printAfterVerification: boolean) {
    if (printAfterVerification) {
      this.printAfterSave({
        document: this.focusedSelected[0],
        documentsToPrint: ['FRA'],
      });
    }

    this.isVerificationPosition = false;
    this.printSettings.currentComponentName.set('comercialOperations');
    this.getDocuments();
    this.findSocketAndRun('XGHLEXDOKHAN_BRWWERPOZDOK_E2');
  }

  restoreTreeConfig = () => {
    setTimeout(() => {
      this.treeView.instance.selectItem(
        this.typeFilter.invoiceTypeId ? this.typeFilter.invoiceTypeId : -1
      );
    }, 500);
  };

  lastTreeSelection;

  onTreeSelectionChanged = (e) => {
    const selectedNodes = e.component
      .getSelectedNodes()
      .map((node) => node.itemData);
    if (selectedNodes.length > 0) {
      this.lastTreeSelection = selectedNodes;
    }
    if (selectedNodes.length == 0) {
      e.component.selectItem(this.lastTreeSelection);
    }
    if (
      selectedNodes[0]?.InvoiceTypeTreeId == 1 ||
      selectedNodes[0]?.InvoiceTypeTreeParentId == 1
    ) {
      this.eDocumentsCode = 'TradeSale';
    } else if (
      selectedNodes[0]?.InvoiceTypeTreeId == 6 ||
      selectedNodes[0]?.InvoiceTypeTreeParentId == 6
    ) {
      this.eDocumentsCode = 'TradePurchase';
    } else {
      this.eDocumentsCode = 'Trade';
    }
  };

  openRightPanel = () => {
    if (this.width <= 21) {
      this.resizeWindowBtn();
    }
  };

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getDocuments();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onPinnedValueChanged(e) {
    const modifiedIndex =
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.findIndex(
        (el) => el.FilterDefinitionId === e.FilterDefinitionId
      );
    if (modifiedIndex > -1) {
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.splice(
        modifiedIndex,
        1
      );
    }
    this.localStorageData.advancedFilter.UserFilterDefinitionValues = [
      ...this.localStorageData.advancedFilter.UserFilterDefinitionValues,
      e,
    ];
    this.cd.detectChanges();
  }

  getSavedFilterDefinitions() {
    const windowId = this.event.getFilterWindowIdFromDictionaryCode(
      this.DynamicFilterDictionaryCode
    );
    this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .pipe(
        take(1),
        switchMap((data: ViewUserDefinition) => {
          if (data) {
            return of(null);
          } else {
            return this.dynamicFilterService.getUsersDefinitionsForWindowId(
              windowId
            );
          }
        })
      )
      .subscribe({
        next: (data: UserDefinition[]) => {
          if (!data) return;

          this.dynamicFilterService.addViewUserDefinitions(
            this.DynamicFilterDictionaryCode,
            data || []
          );
        },
        error: () => {
        },
      });
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  selectedRightTabIndex;
  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 1,
      isPanelVisible: this.localStorageData.sidePanel.isVisible,
    };

    if (
      config.isPanelVisible &&
      this.selectedRightTabIndex != config.dynamicFilterTabIndex
    ) {
      this.selectedRightTabIndex = config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.selectedRightTabIndex == config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.selectedRightTabIndex = config.dynamicFilterTabIndex;
    }
  };

  sumOpen() {
    this.sumFilters = this.getLoadParams();
    this.isSumVisible = true;
    this.cd.detectChanges();
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.getDocuments();
    this.cd.detectChanges();
  };

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGH');
    }
    this.gridBank.instance.focus();
  }

  clearValues(onlySelected: boolean = false) {
    this.event.onShown();
    this.InvoiceDocumentId = null;

    const url = new URL(window.location.href);
    url.search = '';
    history.replaceState(null, '', url.toString());

    this.CustomerId = null;
    this.customerSelected = null;
    this.onlySelected = onlySelected;
    if (onlySelected) {
      this.event.setCurrentDateForOnlySelected();
    } else {
      this.event.setCurrentDate(new GlobalDate(), false, true);
    }
    this.deleteFilter = !this.deleteFilter;
    if (!this.readOnly) {
      if (
        !this.advancedFilterTemplateData &&
        this.localStorageData.advancedFilter
      ) {
        this.localStorageData.advancedFilter =
          this.dynamicFilterService.createDefaultAdvancedFilter(
            this.localStorageData.advancedFilter?.IsPinned,
            this.localStorageData.advancedFilter.PinnedFilters
          );
        this.cd.detectChanges();
      }
      this.resetTypeShortcuts();
      this.resetContrahentFilterNoRefresh();
      //dodać kasowanie pdf (2 filtry)
      this.getDocuments();
      this.cd.detectChanges();
    }
  }

  openSearchBarcode() {
    this.scaner.isBarcodePopupVisible = true;
    this.cd.detectChanges();
  }

  contextCorectionMenuClick(e) {
    switch (e.itemData.itemIndex) {
      case 0:
        if (this.positionFocused.length > 0) {
          this.resetTypeShortcuts();
          this.selectInvoiceAndCorrections(
            this.positionFocused[0].InvoiceDocumentId
          );
        }
        break;
    }
  }

  contextPaymentMenuClick(e) {
    switch (e.itemData.itemIndex) {
      case 0:
        if (this.positionFocused.length > 0) {
          this.checkOrConfirmOnlinePayment(
            this.positionFocused[0].OnlinePaymentDocumentId
          );
        }
        break;
    }
  }

  checkOrConfirmOnlinePayment(onlinePaymentDocumentId) {
    this.appServices
      .postAuth(
        `invoices/documents/${onlinePaymentDocumentId}/checkOrConfirmOnlinePayment`,
        null
      )
      .subscribe(
        () => {
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  contextMenuWarehoseClick(e, t) {
    switch (e.itemData.itemIndex) {
      case 0:
        if (t == 'gridwarehouse' && this.positionFocused.length > 0) {
          if (!this.event.checkPermissions('ODokMag')) {
            this.event.showNotification(
              'info',
              this.translate.instant('permissions.noPermission')
            );
          } else {
            this.showWarehouseDocument = true;
            this.cd.detectChanges();
          }
        }

        if (t == 'gridOrders' && this.positionFocused.length > 0) {
          if (!this.event.checkPermissions('OZam')) {
            this.event.showNotification(
              'info',
              this.translate.instant('permissions.noPermission')
            );
          } else {
            this.showOrderDocuments = true;
            this.cd.detectChanges();
          }
        }
        break;
      case 1:
        this.getTypeTreeWarehouse().then(() => {
          this.showCorrectWarehouse();
        });

        break;
    }
  }

  isCorrectionWarehouse: boolean = false;
  showCorrectWarehouse = () => {
    if (
      this.positionFocused[0].TypeOfDocument.replace(' ', '').indexOf('WZ') !=
      -1 ||
      this.positionFocused[0].TypeOfDocument.replace(' ', '').indexOf('PZ') !=
      -1
    ) {
      this.isCorrectionWarehouse = true;
      this.cd.detectChanges();
      return;
    }

    this.event.showNotification(
      'info',
      this.translate.instant(
        'warehouseDocument.CorrectionsApplyOnlyWZandGRNdocuments'
      )
    );
  };

  getTypeTreeWarehouse = () => {
    return new Promise((resolve) => {
      if (localStorage.getItem('WarehouseDocumentsType')) {
        this.setTypeTree(
          this.event.decryptString(
            localStorage.getItem('WarehouseDocumentsType')
          )
        );
        resolve(true);
      } else {
        this.refreshTypeTree(resolve);
      }
    });
  };

  typeTreeWarehouse: any = [];
  setTypeTree = (res) => {
    this.typeTreeWarehouse = [
      {
        id: '1',
        WarehouseDocumentTypeId: res.WarehouseDocumentTypeId,
        TypeName: res.TypeName,
        TypeCode: res.TypeCode,
        Types: res.Types,
        expanded: true,
        items: res.Types,
      },
    ];
    this.setItems(this.typeTreeWarehouse, 'Types');
  };

  setItems = (item, typeName) => {
    item.forEach((field) => {
      field.items = field[typeName];
      field.expanded = true;
      if (field.items != null) {
        this.setItems(field[typeName], typeName);
      }
    });
  };

  onClosingFormWarehouse() {
    this.showWarehouseDocument = false;
    this.showOrderDocuments = false;
    this.isCorrectionWarehouse = false;
    this.printSettings.currentComponentName.set('comercialOperations');
    this.cd.detectChanges();
  }

  setFiscalMode: 'setFiscal' | 'setNonFiscal' = 'setFiscal';
  isSetFiscal: boolean = false;
  setFiscalConfirmTextFocus: string =
    'Dokument jest niezafiskalizowany <br/>Czy <strong>ustawić</strong> znacznik fiskalizacji?';
  setFiscalConfirmTextSelect: string =
    'Czy <strong>ustawić</strong> znacznik fiskalizacji dla zaznaczonych dokumentów?';
  setNonFiscalConfirmTextFocus: string =
    'Dokument jest zafiskalizowany <br/>Czy <strong>odblokować</strong> znacznik fiskalizacji?';
  setNonFiscalConfirmTextSelect: string =
    'Czy <strong>odblokować</strong> znacznik fiskalizacji dla zaznaczonych dokumentów?';

  showSetFiscalDialog() {
    if (!this.event.checkPermissions('DokHandlPonowFiskal')) {
      return;
    }
    if (!this.focusedSelected.length) {
      this.event.showNotification(
        'info',
        this.translate.instant('buffor.selectLeastDocument')
      );
      return;
    }
    this.isSetFiscal = true;
    this.setFiscalMode = this.focusedSelected[0].IsFiscalDocument
      ? 'setNonFiscal'
      : 'setFiscal';
    this.cd.detectChanges();
  }

  closeSetFiscalDialog() {
    this.isSetFiscal = false;
    this.cd.detectChanges();
  }

  async setFiscal(id: number[], shouldRefresh: boolean = false) {
    await this.toggleFiscalizationTag(id);
    if (shouldRefresh) {
      this.getDocuments(
        false,
        this.gridBank.instance.getRowIndexByKey(
          this.focusedSelected[0].InvoiceDocumentId
        )
      );
      this.cd.detectChanges();
    }
    this.selectedRows = [];
    this.selectedDocumentsData = [];
  }

  toggleFiscalizationTag(id: number[]) {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.selectedRows.length) {
        id = [this.focusedSelected[0].InvoiceDocumentId];
      }
      if (!id.length) {
        this.event.showNotification('success', 'setFiscal');
        this.isSetFiscal = false;
        this.cd.detectChanges();
        resolve(true);
      }
      switch (this.setFiscalMode) {
        case 'setFiscal':
          this.appServices
            .postAuth(`invoices/documents/${id[0]}/setFiscal`, null)
            .subscribe({
              next: () => {
                id.shift();
                if (id.length) {
                  resolve(this.toggleFiscalizationTag(id));
                } else {
                  this.event.showNotification(
                    'success',
                    this.translate.instant(
                      'form-commercial-operation.setFiscalSuccess'
                    )
                  );
                  this.isSetFiscal = false;
                  resolve(true);
                }
              },
              error: (error) => {
                this.event.httpErrorNotification(error);
                reject(false);
              },
            });
          break;

        case 'setNonFiscal':
          this.appServices
            .postAuth(`invoices/documents/${id[0]}/setNonFiscal`, null)
            .subscribe({
              next: () => {
                id.shift();
                if (id.length) {
                  resolve(this.toggleFiscalizationTag(id));
                } else {
                  this.event.showNotification(
                    'success',
                    this.translate.instant(
                      'form-commercial-operation.setNonFiscalSuccess'
                    )
                  );
                  this.isSetFiscal = false;
                  resolve(true);
                }
              },
              error: (error) => {
                this.event.httpErrorNotification(error);
                reject(false);
              },
            });
          break;
      }
    });
  }

  onChoosedDescription() {
  }

  onParamsBtnClick() {
  }

  fullScreen: any = null;
  narrowScreen: any = null;

  customerFilter = {
    CustomerId: null,
    CustomerName: '',
  };
  isCustomerVisible = false;

  customerSelected: any = null;
  onChoosedCustomer = (e) => {
    if (e) {
      this.customerSelected = e;
      this.customerFilter.CustomerId = e.CustomerId;
      this.customerFilter.CustomerName = e.Name;
      this.isCustomerVisible = false;
      this.getDocuments();
    } else {
      this.customerSelected = null;
    }
  };

  resetContrahentFilter = () => {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
    this.customerSelected = null;
    this.getDocuments();
  };

  resetContrahentFilterNoRefresh = () => {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
  };

  setCustomerFromFocusedRow() {
    if (
      this.focusedSelected[0] &&
      this.customerFilter.CustomerId !== this.focusedSelected[0].CustomerId
    ) {
      this.customerFilter.CustomerId = this.focusedSelected[0].CustomerId;
      this.customerFilter.CustomerName = this.focusedSelected[0].CustomerName;
      this.getDocuments();
    }
  }

  onScanerInputChanged(e) {
    this.setSearchCriteria('EAN');
    this.filterValue = e.toString();
    this.scaner.isBarcodePopupVisible = false;
    this.getDocuments();
    this.event.setFocus(this.gridBank);
  }

  onOnlySelected() {
    this.clearValues(true);
  }

  revertSelectOnly() {
    this.onlySelected = false;
    this.getDocuments();
  }

  onDeselectAll() {
    this.onlySelected = false;
    this.skipSendSelectionEvent = true;
    this.deselectAllRecords();
    this.getDocuments();
  }

  selectAll() {
    this.isAllSelected = true;
    this.selectedAmount.set(this.dataSource._totalCount);
    if (this.selectedAmount() < 0) {
      this.selectedAmount.set(0);
    }
    this.appServices
      .postAuth(`invoices/selection/documents`, this.paramsObj)
      .subscribe(({RowNumber}) => {
        this.selectedAmount.set(RowNumber);
      });
    this.modeSelectionMobile = 'multiple';
  }

  deselectAllRecords() {
    this.selectedAmount.set(0);
    this.isAllSelected = false;
    return this.event.deselectAllRecords('invoices/selection/documents');
  }

  onEditorPreparing(e) {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  documentFinance: FinancialDocument[] = [];
  printQueue: DataToPrint[] = [];
  dataToPrint: DataToPrint;
  type: string = 'FRA';
  reportCode: string = 'Dokument_handlowy';

  onPrintAdvanceAfterSave(e) {
    this.printAfterSave(e);
  }

  onPrintCorrectionAfterSave(e: DataToPrintV2) {
    // this.dataToPrint = e;
    this.printAfterSave(e);
    this.cd.detectChanges();
  }

  radioBoxList: RadioBoxType[] = [
    {
      text: '',
      modelName: 'radioList',
      items: [
        {value: 0, label: 'PLN'},
        {value: 1, label: 'EUR'},
      ],
      value: 0,
      readOnly: false,
      layout: 'vertical',
    },
  ];

  async printAfterSave(e: DataToPrintV2) {
    let invoice: InvoiceDocument = await new Promise<InvoiceDocument>(
      (resolve) => {
        this.appServices
          .getAuth(
            `invoices/documents?InvoiceDocumentId=${e.document.InvoiceDocumentId}`
          )
          .subscribe({
            next: (res) => {
              if (res.data.length > 0) {
                resolve(res.data[0]);
              } else {
                resolve(null);
              }
            },
            error: (err) => {
              this.event.httpErrorNotification(err);
              resolve(null);
            },
          });
      }
    );

    let printData = {
      invoiceDocument: invoice,
      code: this.reportCode,
      currencyCode: invoice.CurrencyCode,
      documentNumber: invoice.InvoiceDocumentNumber,
      // fiscalPrinterEnabled: e.fiscalPrinterEnabled,
      menuName: invoice.MenuName,
      objectId: invoice.InvoiceDocumentId,
      type: this.type,
      documentsToPrint: e.documentsToPrint,
      sendToEDocuments: e.sendToEDocuments,
    };

    if (e.documentsToPrint.includes('FRA')) {
      let reportData: ReportData = this.print.getReportData(printData, 'FRA');
      const reportTypes = await this.print.getReportTypes(invoice.MenuName);
      reportData.report = this.print.getReportTemplate(
        reportData.menuName,
        reportData.type,
        reportTypes
      );
      reportData.reportTypeId = reportData.report.ReportId;
      await this.print.printReport(reportData);
    }

    if (e.documentsToPrint.includes('KP') && invoice?.SettlementId) {
      let connectedFinanceDocuments =
        await this.print.getConnectedFinanceDocuments(invoice.SettlementId);
      let reportData = this.print.getReportData(printData, 'KP');
      reportData.type = 'KP';
      const reportTypes = await this.print.getReportTypes('AP_DokFin1');
      reportData.report = this.print.getReportTemplate(
        reportData.menuName,
        reportData.type,
        reportTypes
      );
      reportData.reportTypeId = reportData.report.ReportId;
      this.print.connectedDocumentsToPdf(connectedFinanceDocuments, reportData);
    }
    if (e.documentsToPrint.includes('WZ')) {
      this.print.handleConnectedWarehouseDocumentsPrint('WZ', printData);
    }
    if (e.documentsToPrint.includes('PZ')) {
      this.print.handleConnectedWarehouseDocumentsPrint('PZ', printData);
    }

    this.cd.detectChanges();
  }

  onReportsPopupClosed() {
    this.dataToPrint = null;
    this.isMobileReportsPopupVisible = false;
    this.cd.detectChanges();
  }

  onResizingBottom(e) {
    this.localStorageData.bottomPanel.height = e.height;
    this.heightGrid = e.heightGrid;
    this.heightBottomGrid =
      e.heightBottomGrid === 0 ? e.height : e.heightBottomGrid;
    this.localStorageData.bottomPanel.isVisible = e.isVisible;
  }

  selectChoosed() {
    const value = this.exportFakirService.selections.getValue();
    if (this.isAllSelected) {
      this.exportFakirService.selections.next({
        ...value,
        Commercial: this.dataSource._totalCount,
      });
    } else if (this.selectedRows) {
      this.exportFakirService.selections.next({
        ...value,
        Commercial: this.selectedRows.length,
      });
    }
    this.event.isExportFKVisible.set(true);
  }

  cancelChoosed() {
    this.deselectAllRecords();
    const value = this.exportFakirService.selections.getValue();
    this.exportFakirService.selections.next({
      ...value,
      Commercial: 0,
    });
    this.event.isExportFKVisible.set(true);
  }

  onOpenCorection() {
    this.isAdvance = false;
    this.isCorrection = true;
    this.cd.detectChanges();
  }

  showContextMenuMobile = (rowData: InvoiceDocument) => {
    if (this.selectedRows.includes(rowData.InvoiceDocumentId)) {
      this.mobileSelectionIcon.set('icon absui-icon--deselct-all');
      this.mobileSelectionText.set(this.translate.instant('unmark'));
    } else {
      this.mobileSelectionIcon.set('icon checkAll');
      this.mobileSelectionText.set(this.translate.instant('mark'));
    }
    this.isContextMenuMobile = true;
  };

  customizeAddressType(cellInfo: any): string {
    const addressType = cellInfo.value;

    switch (addressType) {
      case 0:
        if (localStorage.getItem('language') === 'pl') {
          return `adres wysyłki`;
        } else if (localStorage.getItem('language') === 'en') {
          return `shipping address`;
        } else if (localStorage.getItem('language') === 'ua') {
          return `адреса доставки`;
        } else {
          return 'adres wysyłki';
        }
      case 1:
        if (localStorage.getItem('language') === 'pl') {
          return `adres punktu odbioru`;
        } else if (localStorage.getItem('language') === 'en') {
          return `pickup point address`;
        } else if (localStorage.getItem('language') === 'ua') {
          return `адреса пункту видачі`;
        } else {
          return 'adres punktu odbioru';
        }
      default:
        return '';
    }
  }

  isExportVisible = signal(false);

  touchTimeout: any;

  onTouchStart(e) {
    e.stopPropagation();
    this.touchTimeout = setTimeout(() => {
      this.modeSelectionMobile = 'multiple';
      let row = this.gridBank.instance.getDataSource().items()[
        this.focusedRowIndex
        ];
      const id = row.InvoiceDocumentId;
      if (!this.selectedRows.includes(id)) {
        this.gridBank.instance.selectRows(id, true);
      } else {
        this.gridBank.instance.deselectRows(id);
      }
    }, 1500);
  }

  onTouchEnd() {
    clearTimeout(this.touchTimeout);
  }

  onRecordCardClosing(): void {
    this.isRecordCardVisible = false;
    this.cd.detectChanges();
  }

  showRecordCard(mode: RecordCardMode): void {
    this.recordCardMode = mode;
    switch (mode) {
      case 'contractor':
        try {
          this.recordCardObjectId = this.focusedSelected[0].CustomerId;
        } catch (e) {
          this.recordCardObjectId = null;
          this.event.showNotification(
            'warning',
            this.translate.instant('recordCard.noCustomer')
          );
        }
        break;
      case 'article':
        try {
          this.recordCardObjectId = this.positionFocused[0].ProductId;
        } catch (e) {
          this.recordCardObjectId = null;
          this.event.showNotification(
            'warning',
            this.translate.instant('recordCard.noArticle')
          );
        }
        break;
    }
    if (!this.recordCardObjectId) return;
    this.isRecordCardVisible = true;
    this.cd.detectChanges();
  }

  protected onInsertedPaymentSchedule() {
    this.isShowPaymentSchedule.set(false);
    this.dataSource.reload();
  }
}

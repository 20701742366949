import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription, forkJoin } from 'rxjs';
import {
  PrintConfig,
  PrintConfigPerComponent,
} from 'src/app/core/print-settings/print-config-storage-data.model';
import { HelpService } from 'src/app/help-service.service';
import { CollectorTransferConfig } from 'src/app/libraries/data-collector/data-collector.model';
import { PathToNestMap } from 'src/app/nest-resolver.service';
import { NestFinishedEvent, NestOperation } from 'src/app/nest/nest.model';
import { PrintService } from 'src/app/print.service';
import { Socket } from 'src/app/socket.model';
import { environment } from '../../../environments/environment';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';
import { NestService } from '../../nest-service.service';
import {
  PositionsClipboardAction,
  WarnArticleResult,
} from './../../event.model';
import { CanComponentDeactivate } from './CanComponentDeactivate';

import {
  DataToPrint,
  // ReportData,
} from 'src/app/core/reports/IReportParameters';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
// import { InterconnectorService } from 'src/app/interconnector.service';
import { InvoiceDocument } from '../comercial-operation/invoice-document.model';
import { PrintSettingsService } from 'src/app/core/print-settings/print-settings.service';
import { WarehouseDocument } from 'src/app/warehouse/warehouse-documents/warehouse-documents.model';
import { FinancialDocument } from '../financial-operation/financial-operation.model';

@Component({
    selector: 'app-retail-sales',
    templateUrl: './retail-sales.component.html',
    styleUrls: ['./retail-sales.component.scss'],
    inputs: ['dropDownBoxMode', 'readOnly'],
    standalone: false
})
export class RetailSalesComponent
  implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate
{
  @ViewChild('reports') reports: ReportsComponent;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  @ViewChild('gridClass') gridClass;
  @ViewChild('enter') enter;
  @ViewChild('dot') dot;
  @ViewChild('back') back;
  @ViewChild('reduce') reduce;
  @ViewChild('increase') increase;
  @ViewChild('delLast') delLast;
  @ViewChild('selectA') selectA;
  @ViewChild('number0') number0;
  @ViewChild('number1') number1;
  @ViewChild('number2') number2;
  @ViewChild('number3') number3;
  @ViewChild('number4') number4;
  @ViewChild('number5') number5;
  @ViewChild('number6') number6;
  @ViewChild('number7') number7;
  @ViewChild('number8') number8;
  @ViewChild('number9') number9;
  @ViewChild('paymentFormList') paymentFormList;
  @ViewChild('isValidUserExtensions') isValidUserExtensions;
  printerStatusSub: Subscription;
  reportCode: string = 'Dokument_handlowy';
  reportType: string = 'FRA';

  helpService = inject(HelpService);
  onReceivedUpdateNestFromChildren(_e) {}
  onParamsBtnClick(_e) {}
  nestObjectParameters: any = {};
  dropDownBoxMode;
  unicalGuid;
  isCustomer: boolean = false;
  widthLeftPanel = 330;
  width = 345;
  tabRightVisible = true;
  selectedId: any = null;
  DocumentContext: any = null;
  loadChildComponent: boolean = false;
  localStorageData = {
    leftPanelWidth: null,
    rightPanelWidth: null,
    selectionTab: 0,
    columns: [],
    printConfig: {
      fiscalPrinterEnabled: false,
      financialDocument: false,
      commercialDocument: false,
      warehouseDocument: false,
      warehouseDocOnly: false,
    },
  };
  storageKey: string = 'retailSales';
  sessionKey: string = 'retailSalesConfiguration';

  quantity: number = 0;
  selectedDocumentType = {
    Signature: 'PR',
    IsIncome: false,
  };
  isCorrect;
  sumPosition = {
    TotalNetAmount: 0.0,
    TotalGrossAmount: 0.0,
    TotalTaxAmount: 0.0,
    TotalGrossAmountBeforeDiscount: 0.0,
  };
  readOnly;
  selectCustomerShortcut = false;

  typeOfArticleIdentification = [
    { value: 'catalogIndex', label: 'Indeks katalogowy' },
    { value: 'tradingIndex', label: 'Indeks handlowy' },
    { value: 'copyCode', label: 'Kod egzemplarzowy' },
    { value: 'serialNo', label: 'Nr serii' },
    { value: 'PLU', label: 'PLU' },
  ];

  wgList = [
    { label: 'wg nazwy', value: 'ProductFullName' },
    { label: 'wg indeksu katologoweg', value: 'IndexCatalogue' },
    { label: 'wg kodu kreskowego', value: 'EAN' },
  ];
  openCustomDropDown: boolean = false;

  valueCriteriaPosition = 'ProductFullName';
  filterValuePosition = '';

  typeOfAmount = [
    { value: 'Brutto', label: 'Brutto' },
    { value: 'Netto', label: 'Netto' },
  ];

  focusedSelected = [];
  selectedRow = [];
  heightGrid;
  dataSource;
  DokHandlBezPozycji: boolean = false;
  shortcuts: ShortcutInput[] = [];

  addRow: boolean = false;
  editRow: boolean = false;
  isAppArticles: boolean = false;
  form;
  SzybkaSprzedazH;
  warnRepeatArticle: WarnArticleResult = 'Nie';
  intuitiveFilter = {
    mode: 'Kasuj',
    value: '',
  };

  isWarehouseDocuments: boolean = false;
  articleAlreadyExistWarningVisible: boolean = false;
  articleAlreadyExistBlockVisible: boolean = false;
  selectedtab = 0;
  isEditPosition: boolean = false;
  isContractorsVisible: boolean = false;
  isPrice: boolean = false;
  isAmount: boolean = true;
  plusBtn;
  minusBtn;
  isChangeDate: boolean = false;
  isCancelRow: boolean = false;
  isAutoNumeration: boolean = true;

  contextMenu = [
    { text: 'Dodaj', icon: 'icon absui-icon--add-circle' },
    { text: 'Edytuj', icon: 'icon absui-icon--mode-edit' },
    { text: 'Usuń', icon: 'icon absui-icon--highlight-off' },
    { text: 'Zaznacz wszystko', icon: 'icon absui-icon--checkbox-all' },
    { text: 'Odznacz wszystko', icon: 'icon absui-icon--deselct-all' },
    // { text: 'Odwróć zaznaczone', icon: 'icon absui-icon--checkbox-arrowback' },
    { text: 'Kopiuj pozycje do schowka', icon: '' },
    { text: 'Przenieś pozycje do schowka', icon: '' },
    { text: 'Schowek pozycji', icon: '' },
  ];
  isClipboardVisible: boolean = false;

  DetalFormaPlatKontr;
  DetalFormaPlat;
  dataSourceSuspend;
  isToPay: boolean = false;
  isDetalFormaPlatKontr: boolean = false;
  isOrderRealization: boolean = false;

  //
  openNestFinished = false;
  onInitErr: boolean = false;
  activatedRouteSub;
  componentNests: Socket;
  nest: Socket;
  nestsSub: Subscription;
  isNestRunning = false;
  nestOperation: NestOperation;
  closeNest: Socket;
  globalNestSub: Subscription;

  isWhaitForResponse: boolean = false;
  fullScreen: boolean = false;
  isShowSidePanel: boolean = false;

  isCollectorVisible: boolean = false;
  collectorTransferConfig: CollectorTransferConfig = {
    CustomerId: null,
    DocumentId: null,
    DocumentType: 4,
    Date: new Date(),
  };

  savedPrintConfigValue: any = {
    fiscalPrinterEnabled: false,
    financialDocument: false,
    commercialDocument: false,
    warehouseDocument: false,
    warehouseDocOnly: false,
  };
  firstPrintParamChange: boolean = true;

  dropDownOptions = {
    width: 350,
    minWidth: 350,
  };
  openWorkers = false;
  narrowScreen = true;
  AutomatArtykHan;

  submitted: boolean = false;
  ArtykCenESDok: boolean = false;
  DetalWylFiskal: boolean;
  DetalWystawTylkoWZ: boolean;
  onlyWZ: boolean = false;

  reportsMenuName: string = null;
  reportsSelectedRows: any[] = [];
  reportToPrint: DataToPrint = null;
  emptyStateHeight;
  ObslZam;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppServices,
    // private interconnector: InterconnectorService,
    private router: Router,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public formBuilder: FormBuilder,
    public nestService: NestService,
    public print: PrintService,
    public translate: TranslateService,
    private printSettingsService: PrintSettingsService
  ) {
    this.createForm();
    this.AutomatArtykHan = this.event.getConfigurationParameter(
      'AutomatArtykHan',
      true
    );
    this.ArtykCenESDok = this.event.checkPermissions('ArtykCenESDok');
    this.DetalWylFiskal = this.event.checkPermissions('DetalWylFiskal');
    this.DetalWystawTylkoWZ = this.event.checkPermissions('DetalWystawTylkoWZ');

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.getViewConfigurations();
    this.translate
      .get('customerGroups.catalogIndex')
      .subscribe((text) => (this.typeOfArticleIdentification[0].label = text));
    this.translate
      .get('customerGroups.tradingIndex')
      .subscribe((text) => (this.typeOfArticleIdentification[1].label = text));
    this.translate
      .get('customerGroups.copyCode')
      .subscribe((text) => (this.typeOfArticleIdentification[2].label = text));
    this.translate
      .get('deliveries.serialNo')
      .subscribe((text) => (this.typeOfArticleIdentification[3].label = text));

    this.translate
      .get('form-commercial-operation.gross')
      .subscribe((text) => (this.typeOfAmount[0].label = text));
    this.translate
      .get('form-commercial-operation.net')
      .subscribe((text) => (this.typeOfAmount[1].label = text));

    this.heightGrid = window.innerHeight - 244;

    this.SzybkaSprzedazH =
      this.event.getConfigurationParameter('SzybkaSprzedazH')?.Value;

    const OstrzOArtNaDok =
      this.event.getConfigurationParameter('OstrzOArtNaDok');
    if (OstrzOArtNaDok) {
      this.warnRepeatArticle = OstrzOArtNaDok.Value as WarnArticleResult;
    }

    this.DetalFormaPlatKontr = this.event.getConfigurationParameter(
      'DetalFormaPlatKontr'
    )?.Value;

    this.DetalFormaPlat =
      this.event.getConfigurationParameter('DetalFormaPlat')?.Value;

    this.translate
      .get('warehouseDocument.byName')
      .subscribe((text) => (this.wgList[0].label = text));
    this.translate
      .get('warehouseDocument.accordingToTheCatalogIndex')
      .subscribe((text) => (this.wgList[1].label = text));
    this.translate
      .get('warehouseDocument.byBarcode')
      .subscribe((text) => (this.wgList[2].label = text));

    const intuitiveFilter =
      this.event.getConfigurationParameter('FiltrArtIntuicyjne');
    if (intuitiveFilter) {
      this.intuitiveFilter.mode = intuitiveFilter.Value;
    }

    this.DokHandlBezPozycji = this.event.checkPermissions('DokHandlBezPozycji');

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
    this.translate
      .get('repairFunctions.selectAll')
      .subscribe((text) => (this.contextMenu[3].text = text));
    this.translate
      .get('articles.setUnselectedAll')
      .subscribe((text) => (this.contextMenu[4].text = text));
    // this.translate
    //   .get('repairFunctions.invertSelected')
    //   .subscribe((text) => (this.contextMenu[5].text = text));

    this.translate
      .get('clipboard.copyPositionsToClipboard')
      .subscribe((text) => (this.contextMenu[5].text = text));
    this.translate
      .get('clipboard.movePositionsToClipboard')
      .subscribe((text) => (this.contextMenu[6].text = text));
    this.translate
      .get('clipboard.positionsClipboard')
      .subscribe((text) => (this.contextMenu[7].text = text));

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests || {};

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });
  }

  getViewConfigurations = () => {
    const localData = localStorage.getItem(this.storageKey);
    if (!localData) {
      this.appService
        .getAuth(`viewConfigurations/${this.sessionKey}`)
        .subscribe((res) => {
          if (res.Configuration) {
            // this.localStorageData = JSON.parse(res.Configuration);
            this.localStorageData = Object.assign(
              this.localStorageData,
              JSON.parse(res.Configuration)
            );
          }

          localStorage.setItem(
            this.storageKey,
            JSON.stringify(this.localStorageData)
          );
          this.getViewConfigurations();
        });
    } else {
      // this.localStorageData = JSON.parse(localData);
      this.localStorageData = Object.assign(
        this.localStorageData,
        JSON.parse(localData)
      );
    }
  };

  ngOnInit(): void {
    this.ObslZam = this.event.getConfigurationParameter('ObslZam', true);
    this.printSettingsService.currentComponentName.set('retailSales');
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.getTypeDocuemnt().then(() => {
      this.setCustomerId();
    });

    setTimeout(() => {
      this.getSuspendDocument();
    }, 2000);

    setTimeout(() => {
      this.closeNest = this.nestService.findNest(
        this.componentNests?.Sockets,
        'XGHSDA'
      );
      this.findSocketAndRun('XGHSDO1');
    });
  }

  canDeactivate(): boolean {
    if (this.sumPosition.TotalGrossAmount > 0) {
      this.isCancelRow = true;
      return false;
    }

    this.deleteDocument(false).then(() => {
      return true;
    });

    return true;
  }

  ngOnDestroy(): boolean {
    this.submitted = false;
    this.activatedRouteSub?.unsubscribe();
    this.globalNestSub?.unsubscribe();

    localStorage.setItem(
      this.storageKey,
      JSON.stringify(this.localStorageData)
    );
    return true;
  }

  contextMenuClick = (e) => {
    switch (e.itemIndex) {
      case 0:
        this.isAppArticles = true;
        if (!this.readOnly) {
          this.beforeAddArticle();
        }
        break;
      case 1:
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.beforeEditPosition();
        }
        break;
      case 2:
        this.isDeletePosition();
        break;
      case 3:
        this.itemGrid.instance.selectAll();
        break;
      case 4:
        this.itemGrid.instance.selectRows([], false);
        break;
      case 5:
        this.copyToClipboard();
        break;
      case 6:
        this.copyToClipboard('move');
        break;
      case 7:
        this.isClipboardVisible = true;
        break;
    }
  };

  onClosingClipboard = () => {
    setTimeout(() => {
      this.isClipboardVisible = false;
      this.cd.detectChanges();
    }, 1000);
  };

  onClipboardChoosed() {
    this.getPosition(this.form.value.InvoiceDocumentId);
  }

  isLabel: boolean = false;
  onChoosedLabel = () => {
    this.selectedRow = [];
    this.isLabel = false;
    this.dataSource.reload();
  };

  async copyToClipboard(action: PositionsClipboardAction = 'copy') {
    if (this.form.controls.InvoiceDocumentId.value) {
      if (this.selectedRow.length) {
        await this.event.saveToClipboard(
          'DOCUMENT_INVOICE',
          this.form.controls.InvoiceDocumentId.value,
          action
        );
      } else if (this.focusedRowIndex !== -1) {
        const el = this.getFocusedElement();
        await this.event.selectRecords(`invoices/selection/positions/select`, [
          el.WarehouseDocumentPositionId,
        ]);

        await this.event.saveToClipboard(
          'DOCUMENT_INVOICE',
          this.form.controls.InvoiceDocumentId.value,
          action
        );
      }

      if (action === 'move') {
        this.getPosition(this.form.value.InvoiceDocumentId);
        this.recalculatePositions();
        this.cd.detectChanges();
        this.event.showNotification(
          'success',
          this.translate.instant('clipboard.positionsMoved')
        );
      } else {
        this.getPosition(this.form.value.InvoiceDocumentId);
        this.event.showNotification(
          'success',
          this.translate.instant('clipboard.positionsCopied')
        );
      }
    }
  }

  getFocusedElement() {
    return this.dataSource.items().find((_el, i) => this.focusedRowIndex === i);
  }

  setDocumentTypeId = (list) => {
    let paragon = list.find((field) => field.Signature == 'PR');
    this.form.controls.DocumentTypeId.setValue(paragon.TypeId);

    this.isAutoNumeration = this.event.checkAutoNumeration(
      'InvoiceDocuments',
      this.form.value.DocumentTypeId
    );

    if (this.isAutoNumeration) {
      this.form.controls.InvoiceDocumentNumber.setValue('<auto>');
    } else {
      this.form.controls.InvoiceDocumentNumber.setValue('');
    }
  };

  discount = 0;
  setDiscountForAllPosition = () => {
    let object = {
      InvoiceDocumentId: this.form.value.InvoiceDocumentId,
      SumBy: this.form.value.SumBy,
      DiscountMarkup: this.form.value.DiscountMarkup * 100,
    };
    this.appService
      .putAuth(
        `invoices/documents/${this.form.value.InvoiceDocumentId}/discount`,
        object
      )
      .subscribe(
        (res) => {
          this.getPosition(this.form.value.InvoiceDocumentId);
          (this.sumPosition.TotalNetAmount = res.TotalNetAmount),
            (this.sumPosition.TotalGrossAmount = res.TotalGrossAmount);
          this.sumPosition.TotalTaxAmount = res.TotalTaxAmount;
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  recalculatePositions = () => {
    if (this.form.value.InvoiceDocumentId != 0) {
      this.appService
        .putAuth(
          `invoices/documents/${this.form.value.InvoiceDocumentId}/recalculatePositions?sumBy=${this.form.value.SumBy}`,
          { sumBy: this.form.value.SumBy }
        )
        .subscribe(
          (res) => {
            this.sumPosition.TotalNetAmount = res.TotalNetAmount;
            this.sumPosition.TotalGrossAmount = res.TotalGrossAmount;
            this.sumPosition.TotalTaxAmount = res.TotalTaxAmount;
          },
          (error) => {
            this.event.onHidden();
            this.event.httpErrorNotification(error);
          }
        );
    }
  };

  async setCustomerId() {
    return new Promise((resolve) => {
      this.appService
        .getAuth(`customers?ShortName=Sprzedaż detaliczna`)
        .subscribe(
          (res) => {
            this.form.controls.CustomerId.setValue(res.data[0].CustomerId);
            this.form.controls.CustomerName.setValue(res.data[0].ShortName);
            this.form.controls.PayerId.setValue(res.data[0].PayerId);
            this.form.controls.PaymentFormName.setValue(
              res.data[0].PaymentForm
            );
            this.form.controls.DiscountMarkup.setValue(
              res.data[0].DefaultDiscount / 100
            );
            let DaysForDue = new DatePipe('en-US').transform(
              new Date().getTime() +
                res.data[0].DaysForDue * 1000 * 60 * 60 * 24,
              'yyyy-MM-dd'
            );
            this.form.controls.DateOfPaymentDay.setValue(
              res.data[0].DaysForDue
            );
            this.form.controls.DateOfPayment.setValue(DaysForDue);
            if (res.data[0].TraderId) {
              this.form.controls.EmployeeId.setValue(res.data[0].TraderId);
            } else {
              this.form.controls.EmployeeId.setValue(0);
            }

            if (!res.data[0].PaymentForm) {
              if (sessionStorage.getItem('paymentForms')) {
                let formPaymenyList = this.event.decryptString(
                  sessionStorage.getItem('paymentForms')
                );
                this.form.controls.PaymentFormName.setValue(
                  formPaymenyList.find((field) => field.Code == 'g')
                    .PaymentFormName
                );
              } else {
                this.appService
                  .getAuth(`finances/paymentForms?code=g`)
                  .subscribe((res) => {
                    this.form.controls.PaymentFormName.setValue(
                      res.data[0].PaymentFormName
                    );
                  });
              }
            }

            this.createHeader();
            resolve(true);
          },
          (error) => {
            resolve(false);
            this.event.httpErrorNotification(error);
          }
        );
    });
  }

  getTypeDocuemnt() {
    return new Promise((resolve) => {
      if (localStorage.getItem('typesInvoice')) {
        let list = this.event.decryptString(
          localStorage.getItem('typesInvoice')
        );
        this.setDocumentTypeId(list);
        resolve(true);
      }

      this.appService
        .getAuth(`document/sales/types/invoice`)
        .subscribe((res) => {
          this.setDocumentTypeId(res.data);
          localStorage.setItem(
            'typesInvoice',
            this.event.encryptString(res.data)
          );
          resolve(false);
        });
    });
  }

  onPaymentFormNameChanged = (e) => {
    if (e != '') {
      this.form.controls.PaymentFormName.setValue(e);
    }
  };

  setCurrentPayment = (e) => {
    if (this.form.value.DateOfPaymentDay == 0 && e.length == 1) {
      if (
        e[0].formPaymentName == 'gotówka' &&
        e[0].value > this.sumPosition.TotalGrossAmount
      ) {
        this.form.controls.CurrentPayment.setValue(
          this.sumPosition.TotalGrossAmount
        );
        return;
      }

      if (
        e[0].formPaymentName == 'gotówka' &&
        e[0].value <= this.sumPosition.TotalGrossAmount
      ) {
        this.form.controls.CurrentPayment.setValue(e[0].value);
        return;
      }

      // this.form.controls.CurrentPayment.setValue(e);
    }

    if (e.length > 1) {
      e.forEach((field) => {
        if (
          field.formPaymentName == 'gotówka' &&
          field.value > this.sumPosition.TotalGrossAmount
        ) {
          this.form.controls.CurrentPayment.setValue(
            this.sumPosition.TotalGrossAmount
          );
          return;
        }

        if (
          field.formPaymentName == 'gotówka' &&
          field.value <= this.sumPosition.TotalGrossAmount
        ) {
          this.form.controls.CurrentPayment.setValue(field.value);
          return;
        }
      });
    }
  };

  isData = false;
  createForm = () => {
    this.isData = false;
    this.form = this.formBuilder.group({
      Advance: [false],
      AdvancePayment: [0],
      BankAccountId: [0],
      BankId: [0],
      checboxList: this.formBuilder.array([]),
      CommercialTransactionList: [''],
      CurrencyCode: [''],
      CurrencyFactor: [1],
      CurrencyFactorDate: [null],
      CurrentPayment: [0],
      CustomerId: [null, Validators.required],
      CustomerName: [''],
      DateOfInvoiceDocument: [new Date(), Validators.required],
      DateOfPayment: [new Date()],
      DateOfPaymentDay: [0],
      DateOfReceived: [new Date(), Validators.required],
      DateOfRegistration: [null],
      DateOfSell: [new Date()],
      DiscountMarkup: [0],
      DocumentCreationPlace: [''],
      DocumentStatus: ['N'],
      DocumentTypeId: [0],
      EmployeeId: [0],
      Field1: [''],
      Field10: [''],
      Field2: [''],
      Field3: [''],
      Field4: [''],
      Field5: [''],
      Field6: [''],
      Field7: [''],
      Field8: [''],
      Field9: [''],
      InvoiceDocumentId: [0],
      InvoiceDocumentNumber: ['<auto>', Validators.required],
      IsAdvancePaymentDeferred: [false],
      IsCheckoutMethod: [false],
      IsConfirmedEu: [false],
      IsCorrectionDocument: [false],
      IsCurrencyDocument: [false],
      IsDocCurrencyDomestic: [false],
      IsEditDocumentMode: [false],
      IsGroupPositions: [false],
      IsInner: [false],
      IsMargin: [false],
      IsOpposite: [false],
      IsSimplified: [false],
      IsSmallTaxpayer: [false],
      IsTripartiteEu: [false],
      LastAdvanceInvoiceId: [0],
      MarginRemarks: [''],
      OriginalAdvanceInvoiceId: [0],
      PayerId: [null],
      PayerName: [''],
      PaymentFormName: [''],
      PickedUpBy: [''],
      ReceiptNumber: [''],
      Remarks: [''],
      Signature: ['PR'],
      StatusComment: [''],
      SumBy: ['Brutto'],
      type: ['s'],
    });

    this.sumPosition = {
      TotalNetAmount: 0.0,
      TotalGrossAmount: 0.0,
      TotalTaxAmount: 0.0,
      TotalGrossAmountBeforeDiscount: 0.0,
    };
  };

  getPosition = (InvoiceDocumentId) => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WarehouseDocumentPositionId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${InvoiceDocumentId}/positions`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          data.forEach((el, index) => {
            el.lp = this.dataSource._items.length + index + 1;
          });

          if (data.length > 0) {
            this.isData = true;
          }

          setTimeout(() => {
            this.focusedRowIndex = this.focusedRowIndex;
            if (data.length > 0) {
              this.focusedSelected = [data[this.focusedRowIndex]];
            }
            this.event.setFocus(this.gridClass);
            this.cd.detectChanges();
          }, 0);

          const selected: any[] = data
            .filter((el) => el.IsSelected)
            .map((el) => el.FinanceDocumentId);
          if (selected.length > 0) {
            this.event.deselectAllRecords(`invoices/selection/positions`);
          }
          this.cd.detectChanges();
          data.forEach((field) => {
            (field.AmountNew = field.Amount),
              (field.PriceGrossNew = field.PriceGross);
          });

          setTimeout(() => {
            this.isWhaitForResponse = false;
            this.event.onHidden();
          }, 0);
        },
        deleteUrl: `${environment.domain}invoices/documents/${InvoiceDocumentId}/positions`,
      }),
      reshapeOnPush: true,
    });
  };

  switchOrder() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
      return;
    }
    this.order = 'ASC';
  }

  getLoadParams = () => {
    let obj: any = {};

    obj.ParentObjectId = this.form.value.InvoiceDocumentId;

    (obj.OrderBy = this.orderby), //this.valueCriteria[0].toLowerCase() + this.valueCriteria.substr(1),//
      (obj.Order = this.order);

    switch (this.valueCriteriaPosition) {
      case 'ProductFullName':
        obj.ProductFullName = this.filterValuePosition;
        break;
      case 'IndexCatalogue':
        obj.IndexCatalogue = this.filterValuePosition;
        break;
      case 'IndexTrading':
        obj.IndexTrading = this.filterValuePosition;
        break;
      case 'Location':
        obj.Location = this.filterValuePosition;
        break;
      case 'EAN':
        obj.EAN = this.filterValuePosition;
        break;
    }

    return obj;
  };

  createHeader = () => {
    this.appService
      .postAuth(`invoices/documents/header`, this.form.value)
      .subscribe(
        (res) => {
          this.form.controls.InvoiceDocumentId.setValue(res.Id);
          this.getPosition(res.Id);
          this.loadCollectorConfig();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHSDDND2');
        }
      );
  };

  beforeWarehouseDocuments() {
    this.findSocketAndRun('XGHSDWDM1');
  }

  onPrintConfigChanged(e) {
    if (e.item.name == 'warehouseDocOnly') {
      this.onlyWZ = e.value;
    }
  }

  ngAfterViewInit() {
    let printConfig = this.event.getPrintConfig();

    if (printConfig && printConfig.config) {
      let componentConfig: PrintConfigPerComponent = printConfig.config.find(
        (el) => el.componentName === 'retailSales'
      );

      this.onlyWZ = componentConfig.printConfig.warehouseDocOnly;
    }
    this.cd.detectChanges();
    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.form.valid)
            this.getPosition(this.form.value.InvoiceDocumentId);
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/operacje-handlowe/sprzedaz-detal'
          );
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f11',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.form.value.CustomerId && this.form.value.InvoiceDocumentId)
            this.isCollectorVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.beforeAddArticle();
          }
          this.isAppArticles = true;
        },
        preventDefault: true,
      },
      {
        key: 'F2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            this.focusedSelected.length > 0 &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.beforeEditPosition();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select],
        command: () => {
          if (
            !this.readOnly &&
            this.focusedSelected.length > 0 &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.isDeletePosition();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + p',
        preventDefault: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.visibleUserExtensions = true;
          }
          this.cd.detectChanges();
        },
      },
      {
        key: 'ctrl + f',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.printSettingsService.printSettings.next({
              event: 'printParamChange',
              data: 'fiscalPrinterEnabled',
            });
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + k',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.printSettingsService.printSettings.next({
              event: 'printParamChange',
              data: 'financialDocument',
            });
          }
        },
        preventDefault: true,
      },
      {
        key: 'f7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.selectCustomerShortcut = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + h',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.printSettingsService.printSettings.next({
              event: 'printParamChange',
              data: 'commercialDocument',
            });
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.printSettingsService.printSettings.next({
              event: 'printParamChange',
              data: 'warehouseDocument',
            });
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + e',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.printSettingsService.printSettings.next({
              event: 'printParamChange',
              data: 'warehouseDocOnly',
            });
          }
        },
        preventDefault: true,
      },
      {
        key: '*',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.selectedtab === 1) {
          this.isAmount = true;
          this.isPrice = false;
          }
        },
        preventDefault: false,
      },
      {
        key: '/',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.selectedtab === 1) {
          this.isAmount = false;
          this.isPrice = true;
          }
        },
        preventDefault: false,
      },
      {
        key: 'ctrl + r',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.beforeOrderRealisation();
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + m',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.newPositionConf &&
            !this.isWarehouseDocuments &&
            !this.articleAlreadyExistBlockVisible &&
            !this.articleAlreadyExistWarningVisible &&
            !this.visibleUserExtensions &&
            !this.isDeleteRow &&
            !this.isValidSum &&
            !this.isCancelRow &&
            !this.isEditPosition
          ) {
            this.beforeWarehouseDocuments();
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 0;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 1;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 2;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 4',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 3;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 4;
        },
        preventDefault: true,
      },
      {
        key: 'F9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.form.valid) {
            this.addInvoices();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.openCustomDropDown = !this.openCustomDropDown;
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.form.valid) {
            this.addReceipt();
          }
        },
        preventDefault: true,
      }
    );

    setTimeout(() => {
      this.loadChildComponent = true;

      this.toggleButtonsTextVisible();

      //
    }, 1000);
  }

  toggleButtonsTimeoutActive = false;
  toggleButtonsTextVisible() {
    if (!this.toggleButtonsTimeoutActive) {
      this.toggleButtonsTimeoutActive = true;
      setTimeout(() => {
        this.narrowScreen = this.rightPanel.nativeElement.clientWidth < 1100;
        this.toggleButtonsTimeoutActive = false;
      }, 50);
    }
  }

  onInsertedUserExtensions = (e) => {
    this.form.patchValue(e);
  };

  visibleUserExtensions: boolean = false;
  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
  };

  nResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  }

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 330) {
      width = 330;
    }
    if (width > 460) {
      width = 460;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    this.localStorageData.leftPanelWidth = `${width}px`;
    this.localStorageData.rightPanelWidth = `calc(100% - ${rightWidth}px)`;
    this.toggleButtonsTextVisible();
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  resizeWindowBtn() {
    if (this.width > 21) {
      this.leftPanel.nativeElement.style.width = '10px';
      this.tabRightVisible = false;
      this.width = 21;
      this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
      this.localStorageData.leftPanelWidth = `21px`;
      this.localStorageData.rightPanelWidth = `calc(100% - 26px)`;
    } else {
      this.leftPanel.nativeElement.style.width = '330px';
      this.width = 330;
      this.tabRightVisible = true;
      this.rightPanel.nativeElement.style.width = `calc(100% - 330px)`;
      this.localStorageData.leftPanelWidth = `330px`;
      this.localStorageData.rightPanelWidth = `calc(100% - 330px)`;
    }
    this.toggleButtonsTextVisible();
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  numberClick = (e) => {
    let pomNumber = '';
    this.quantity ? (pomNumber = String(this.quantity)) : (pomNumber = '');

    if (pomNumber == '0') {
      pomNumber = '';
    }
    pomNumber += e;
    if (this.displayQuantity == '0') {
      this.displayQuantity = '';
    }
    this.displayQuantity += e;
    this.quantity = Number(this.displayQuantity);
  };

  backNumber = () => {
    let pomNumber = String(this.quantity);
    pomNumber = pomNumber.slice(0, -1);
    this.displayQuantity = pomNumber;
    this.quantity = Number(pomNumber);
  };

  async reduceOne() {
    if (this.focusedSelected.length > 0) {
      const isPossible = await this.checkModifiScaner(this.focusedSelected[0]);

      if (isPossible) {
        this.getProductDetails(this.focusedSelected[0]).then((res) => {
          const details = res;
          if (this.isAmount) {
            if (this.focusedSelected[0].Amount - 1 == 0) {
              this.isDeletePosition();
            } else {
              this.focusedSelected[0].Amount = this.focusedSelected[0].Amount;
              this.changePosition(this.focusedSelected[0], details, 'concat');
              this.getPosition(this.form.value.InvoiceDocumentId);
            }
          }

          if (this.isPrice) {
            this.focusedSelected[0].Amount = this.focusedSelected[0].Amount - 1;
            this.focusedSelected[0].PriceGross -= 1;
            this.changePosition(this.focusedSelected[0], details, 'add');
            this.getPosition(this.form.value.InvoiceDocumentId);
          }
        });
      }
    }
  }

  async increaseOne() {
    if (this.focusedSelected.length > 0) {
      const isPossible = await this.checkModifiScaner(this.focusedSelected[0]);

      if (isPossible) {
        this.getProductDetails(this.focusedSelected[0]).then((res) => {
          const details = res;
          if (this.isAmount) {
            if (this.focusedSelected[0].Amount == 0) {
              this.isDeletePosition();
            } else {
              this.changePosition(this.focusedSelected[0], details, 'add');
              this.getPosition(this.form.value.InvoiceDocumentId);
            }
          }

          if (this.isPrice) {
            this.focusedSelected[0].Amount = this.focusedSelected[0].Amount - 1;
            this.focusedSelected[0].PriceGross += 1;
            this.changePosition(this.focusedSelected[0], details, 'add');
            this.getPosition(this.form.value.InvoiceDocumentId);
          }
        });
      }
    }
  }

  enterValue = () => {
    if (this.focusedSelected.length > 0) {
      if (this.quantity == 0) {
        this.isDeleteRow = true;
        this.cd.detectChanges();
        return;
      }
      this.getProductDetails(this.focusedSelected[0]).then((res) => {
        const details = res;
        if (this.isAmount) {
          this.focusedSelected[0].Amount = this.quantity - 1;
          this.changePosition(this.focusedSelected[0], details, 'add');
          this.getPosition(this.form.value.InvoiceDocumentId);
          this.resetQuantity();
        }

        if (this.isPrice) {
          this.focusedSelected[0].Amount = this.focusedSelected[0].Amount - 1;
          this.focusedSelected[0].PriceGross = this.quantity;
          this.changePosition(this.focusedSelected[0], details, 'add');
          this.getPosition(this.form.value.InvoiceDocumentId);
          this.resetQuantity();
        }
      });
    }
  };

  resetQuantity = () => {
    this.displayQuantity = '0';
    this.quantity = 0;
  };

  onDateOfInvoiceDocumentChanged = () => {
    this.form.controls.DateOfPayment.setValue(
      new DatePipe('en-US').transform(
        new Date(this.form.get('DateOfInvoiceDocument').value).getTime() +
          1000 * 60 * 60 * 24 * this.form.value.DateOfPaymentDay,
        'yyyy-MM-dd'
      )
    );

    this.form.controls.DateOfSell.setValue(
      new DatePipe('en-US').transform(
        new Date(this.form.get('DateOfInvoiceDocument').value),
        'yyyy-MM-dd'
      )
    );
  };

  DateOfPaymentChanged = (e) => {
    let amountDay =
      new Date(this.event.dataFormatted(e)).getTime() -
      new Date(
        this.event.dataFormatted({
          value: this.form.value.DateOfInvoiceDocument,
        })
      ).getTime();
    if (Math.ceil(amountDay / (1000 * 3600 * 24)) < 0) {
      amountDay = 0;
      this.form.controls.DateOfPayment.setValue(
        new DatePipe('en-US').transform(
          new Date(this.form.value.DateOfInvoiceDocument).getTime(),
          'yyyy-MM-dd'
        )
      );
    }
    this.cd.detectChanges();
    this.form.controls.DateOfPaymentDay.setValue(
      Math.ceil(amountDay / (1000 * 3600 * 24))
    );
  };

  DateOfPaymentNumberChanged = (e) => {
    this.form.controls.DateOfPayment.setValue(
      new DatePipe('en-US').transform(
        new Date(this.form.get('DateOfInvoiceDocument').value).getTime() +
          1000 * 60 * 60 * 24 * e.value,
        'yyyy-MM-dd'
      )
    );

    if (
      this.form.value.PaymentFormName == 'gotówka' &&
      this.form.value.DateOfPaymentDay == 0
    ) {
      this.form.controls.CurrentPayment.setValue(
        this.sumPosition.TotalGrossAmount
      );
    } else {
      this.form.controls.CurrentPayment.setValue(0);
    }
  };

  changeDefaultForm = () => {
    this.isDetalFormaPlatKontr = false;
    let quantityDay =
      this.form.value.type == 's'
        ? this.defaultDateCutomer.DaysForDue
        : this.defaultDateCutomer.DaysForCommitment;
    let DaysForDue = new DatePipe('en-US').transform(
      new Date().getTime() + quantityDay * 1000 * 60 * 60 * 24,
      'yyyy-MM-dd'
    );

    setTimeout(() => {
      this.form.controls.DateOfPayment.setValue(DaysForDue);
      this.form.controls.PaymentFormName.setValue(
        this.defaultDateCutomer.PaymentForm
      );
      this.form.controls.DateOfPaymentDay.setValue(quantityDay);
      this.form.controls.DiscountMarkup.setValue(
        this.defaultDateCutomer.DefaultDiscount / 100
      );
      this.cd.detectChanges();
    }, 0);

    //if default form payment is null I will set cash
    if (!this.defaultDateCutomer.PaymentForm) {
      if (sessionStorage.getItem('paymentForms')) {
        let formPaymenyList = this.event.decryptString(
          sessionStorage.getItem('paymentForms')
        );
        this.form.controls.PaymentFormName.setValue(
          formPaymenyList.find((field) => field.Code == 'g').PaymentFormName
        );
      } else {
        this.appService
          .getAuth(`finances/paymentForms?code=g`)
          .subscribe((res) => {
            this.form.controls.PaymentFormName.setValue(
              res.data[0].PaymentFormName
            );
          });
      }
    }
    if (this.form.value.Signature.trim() == 'FV') {
      this.beforeConfirmPay();
    }
  };

  closingDefaultForm = () => {
    this.isDetalFormaPlatKontr = false;

    this.cd.detectChanges();

    if (this.form.value.Signature.trim() == 'FV') {
      this.beforeConfirmPay();
    }
  };

  beforeConfirmPay() {
    this.findSocketAndRun('XGHSDZ1');
  }

  defaultDateCutomer;
  onChoosedCustomer = (e, fv: boolean = false) => {
    if (e) {
      this.defaultDateCutomer = e;
      this.form.controls.CustomerId.setValue(e.CustomerId);
      this.form.controls.CustomerName.setValue(e.ShortName);
      this.form.controls.PayerId.setValue(e.PayerId);
      this.form.controls.DocumentCreationPlace.setValue(e.City);

      if (this.DetalFormaPlatKontr == 'Pytaj') {
        this.isDetalFormaPlatKontr = true;
      }
      if (this.DetalFormaPlatKontr == 'Zmieniaj') {
        this.changeDefaultForm();
      }

      if (e.TraderId) {
        this.form.controls.EmployeeId.setValue(e.TraderId);
      } else {
        this.form.controls.EmployeeId.setValue(0);
      }

      this.loadCollectorConfig();
      this.cd.detectChanges();
    } else {
      this.form.controls.CustomerName.setValue('');
      this.form.controls.DocumentCreationPlace.setValue('');
      this.form.controls.DiscountMarkup.setValue(0);
      this.form.controls.PaymentFormName.setValue('');
      this.form.controls.PayerId.setValue(null);
      this.form.controls.EmployeeId.setValue(0);
      this.form.controls.CustomerId.setValue(null);
    }

    this.isCustomer = false;
    this.cd.detectChanges();

    if (fv && !this.isDetalFormaPlatKontr) {
      this.beforeConfirmPay();
    }

    if (!fv) {
      this.findSocketAndRun('XGHSDWK2');
    }
  };

  addInvoices = () => {
    if (this.onlyWZ) return;

    if (this.sumPosition.TotalGrossAmount == 0) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.theDocumentCannotBeSavedBecauseTheTotalAmountIsZero'
        )
      );

      return;
    }

    let list = this.event.decryptString(localStorage.getItem('typesInvoice'));
    let TypeId = list.find((field) => field.Signature == 'FV');

    this.form.controls.DocumentTypeId.setValue(TypeId.TypeId);
    this.form.controls.Signature.setValue(TypeId.Signature);
    if (this.isAutoNumeration) {
      this.form.controls.InvoiceDocumentNumber.setValue('<auto>');
    }

    if (this.form.value.CustomerName == 'Sprzedaż detaliczna') {
      this.isContractorsVisible = true;
      this.cd.detectChanges();

      return;
    }

    this.beforeConfirmPay();
  };

  onRowClick(e) {
    this.focusedSelected = [e.data];
  }

  addReceipt = () => {
    let list = this.event.decryptString(localStorage.getItem('typesInvoice'));
    let TypeId = list.find((field) => field.Signature == 'PR');

    this.form.controls.DocumentTypeId.setValue(TypeId.TypeId);
    this.form.controls.Signature.setValue(TypeId.Signature);

    if (this.isAutoNumeration) {
      this.form.controls.InvoiceDocumentNumber.setValue('<auto>');
    }

    if (this.sumPosition.TotalGrossAmount == 0) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.theDocumentCannotBeSavedBecauseTheTotalAmountIsZero'
        )
      );

      return;
    }

    if (this.onlyWZ && this.isValidUserExtensions.checkIsValid()) {
      this.acceptDocument();
      return;
    }

    this.beforeConfirmPay();
  };

  onChoosedEmployee = (e) => {
    if (e) {
      this.form.controls.EmployeeId.setValue(e.EmployeeId);
    } else {
      this.form.controls.EmployeeId.setValue(null);
    }
    this.cd.detectChanges();

    this.findSocketAndRun('XGHSDWPR2');
  };

  isForSimpleDetal: boolean = false;
  chossedFormPayment;

  paramsMsg;
  onChoosedFormPeyment = (e) => {
    if (e && e.ForSimpleDetal == 0) {
      this.paramsMsg = {
        name: e.PaymentFormName,
      };
      this.isForSimpleDetal = true;
      this.chossedFormPayment = e;
      this.cd.detectChanges();
      return;
    }

    this.setFormPayment(e);
  };

  setFormPayment(e) {
    if (e) {
      this.form.controls.PaymentFormName.setValue(e.PaymentFormName);

      if (this.form.value.type == 's') {
        this.form.controls.DateOfPaymentDay.setValue(e.DaysForDue);
      } else {
        this.form.controls.DateOfPaymentDay.setValue(e.DaysForCommitment);
      }
    } else {
      this.form.controls.PaymentFormName.setValue(null);
    }

    if (
      this.form.value.PaymentFormName == 'gotówka' &&
      this.form.value.DateOfPaymentDay == 0
    ) {
      this.form.controls.CurrentPayment.setValue(
        this.sumPosition.TotalGrossAmount
      );
    } else {
      this.form.controls.CurrentPayment.setValue(0);
    }
    this.cd.detectChanges();
    this.findSocketAndRun('XGHSDFZP2');
  }

  noChangeFormPayment() {
    this.isForSimpleDetal = false;
    this.paymentFormList.dropDownInput.text = this.form.value.PaymentFormName;
    this.paymentFormList.selectedId = this.form.value.PaymentFormName;

    let currentFormPayment = this.paymentFormList.dataSource._items.find(
      (field) => field.PaymentFormName == this.form.value.PaymentFormName
    );
    this.event.setFormPayment.next(currentFormPayment);
    this.cd.detectChanges();
  }

  yesChangeFormPayment() {
    this.isForSimpleDetal = false;
    this.setFormPayment(this.chossedFormPayment);
    this.cd.detectChanges();
  }

  sendRequestTimer;
  onSelectionChanged = (e) => {
    this.toSelect.push(...e.currentSelectedRowKeys);
    this.toDeselect.push(e.currentDeselectedRowKeys[0]);

    this.toDeselect = this.event.removeDuplicates(this.toDeselect);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        this.toSelect = [];
        this.event.selectRecords(
          `invoices/selection/positions/select`,
          this.selectedRow
        );
      }
      if (this.toDeselect.length) {
        this.event.selectRecords(
          `invoices/selection/positions/unselect`,
          this.toDeselect
        );
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);
  };

  isOpened: boolean = true;
  onRowDblClick = () => {
    if (!this.readOnly && this.isOpened) {
      this.beforeEditPosition();
    }
  };

  onFocusedRowChanged = (e) => {
    this.prevPhoto = '';
    this.focusedSelected = [e.row.data];
    if (this.selectedtab == 2) {
      this.getPhoto();
    }
  };

  isDeleteRow: boolean = false;
  isDeletePosition = () => {
    if (!this.readOnly && this.focusedSelected.length > 0) {
      this.findSocketAndRun('XGHSDYU1');
    }
  };

  prevPhoto = '';
  @ViewChild('btnSuspend') btnSuspend;
  @ViewChild('textAreaRemarks') textAreaRemarks;
  onSelectionChangedTab = (e) => {
    //get product photo
    if (e.addedItems[0] == 3) {
      this.getPhoto();
    }

    if (e.addedItems[0] == 4) {
      setTimeout(() => {
        this.event.setFocus(this.btnSuspend);
      }, 100);
    }

    if (e.addedItems[0] == 5) {
      setTimeout(() => {
        this.event.setFocus(this.textAreaRemarks);
      }, 100);
    }
  };

  getPhoto = () => {
    if (this.focusedSelected && this.focusedSelected[0]) {
      this.appService
        .getAuth(`products/${this.focusedSelected[0].IndexCatalogue}/pictures`)
        .subscribe((res) => {
          if (res.data.length > 0) {
            this.appService
              .getAuth(
                `products/${this.focusedSelected[0].IndexCatalogue}/pictures/${res.data[0].PictureId}`
              )
              .subscribe((res) => {
                this.prevPhoto =
                  'data:image/gif;base64,' + res.data[0].PictureBase64;
              });
          }
        });
    }
  };

  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  isQuickPress: boolean = true;
  toDeselect: any[] = [];
  toSelect: any[] = [];

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  countValueDocument = (e) => {
    this.isOrderRealization = false;
    this.sumPosition.TotalGrossAmount = e.TotalGrossAmount;
    this.sumPosition.TotalNetAmount = e.TotalNetAmount;
    this.sumPosition.TotalTaxAmount = e.TotalTaxAmount;
  };

  beforeOrderRealisation() {
    this.findSocketAndRun('XGHSDRLZ1');
  }

  focusedRowIndex = 0;

  async onKeyDown(e) {
    if (
      e.event.shiftKey &&
      (e.event.keyCode == 107 || e.event.keyCode == 187)
    ) {
      this.event.onShown();
      const details = await this.getProductDetails(this.focusedSelected[0]);
      this.changePosition(this.focusedSelected[0], details, 'add');
      this.cd.detectChanges();
      this.dataSource.reload();
    } else if (
      e.event.shiftKey &&
      (e.event.keyCode == 109 || e.event.keyCode == 189)
    ) {
      this.event.onShown();
      const details = await this.getProductDetails(this.focusedSelected[0]);
      this.changePosition(this.focusedSelected[0], details, 'concat');
      this.cd.detectChanges();
      this.dataSource.reload();
    } else if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    } else if (e.event.key === 'f' && e.event.ctrlKey) {
      e.event.preventDefault();
      this.printSettingsService.printSettings.next({
        event: 'printParamChange',
        data: 'fiscalPrinterEnabled',
      });
    } else if (e.event.key === 'k' && e.event.ctrlKey) {
      e.event.preventDefault();
      this.printSettingsService.printSettings.next({
        event: 'printParamChange',
        data: 'financialDocument',
      });
    } else if (e.event.key === 'h' && e.event.ctrlKey) {
      e.event.preventDefault();
      this.printSettingsService.printSettings.next({
        event: 'printParamChange',
        data: 'commercialDocument',
      });
    } else if (e.event.key === 'd' && e.event.ctrlKey) {
      e.event.preventDefault();
      this.printSettingsService.printSettings.next({
        event: 'printParamChange',
        data: 'warehouseDocument',
      });
    }
    // else if (e.event.key === 'D' && e.event.ctrlKey) {
    //   e.event.preventDefault();
    //   e.event.stopPropagation();
    //   this.reports.openDropdown();
    // }
  }

  onOptionChanged = (e) => {
    this.event.onOptionChanged(e);
    if (e.name === 'columns' && e.fullName.indexOf('width') > -1) {
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        this.localStorageData.columns[colIndex].width = e.value;
      } catch (e) {}
    }

    setTimeout(() => {
      this.localStorageData.columns.forEach((field, index) => {
        field.visibleIndex = index;
      });
    }, 0);
  };

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  getPositionsWithProductId(): Promise<boolean> {
    return new Promise((resolve) => {
      this.appService
        .getAuth(
          `invoices/documents/${this.form.value.InvoiceDocumentId}/positions?ProductId=${this.positionArticleToAdd.ProductId}&ParentObjectId=${this.form.value.InvoiceDocumentId}`
        )
        .subscribe({
          next: (res) => {
            resolve(res.data.length);
          },
          error: () => {
            resolve(false);
          },
        });
    });
  }

  async onSubstituteChoosed(e) {
    this.isShowSubstitutes.set(false);
    this.positionArticleToAdd = { ProductId: e.SubstituteId };
    const exist = await this.getPositionsWithProductId();

    this.appService
      .getAuth(`products?ObjectId=${e.SubstituteId}`)
      .subscribe((res) => {
        this.positionArticleToAdd = res.data[0];
        this.positionArticleToAdd.EnteredAmount = e.Amount;
        if (this.warnRepeatArticle === 'Tak' && exist) {
          this.articleAlreadyExistWarningVisible = true;
          this.cd.detectChanges();
          return;
        } else if (this.warnRepeatArticle === 'Blokuj' && exist) {
          this.articleAlreadyExistBlockVisible = true;
          this.cd.detectChanges();
          return;
        }
        this.sell();
      });
  }

  positionArticleToAdd;
  newPositionConf: boolean = false;

  isShowSubstitutesQuestion = signal(false);
  isSet = signal(false);

  async onChoosedProduct(e) {
    if (e && e.length > 1) {
      this.appService
        .postAuth(
          `invoices/documents/${this.form.value.InvoiceDocumentId}/positions/AddMultiple`,
          {
            InvoiceDocumentId: this.form.value.InvoiceDocumentId,
            DocumentDate: this.form.value.DateOfInvoiceDocument,
            CustomerId: this.form.value.CustomerId,
            SumBy: this.form.value.SumBy,
          }
        )
        .subscribe(
          (res) => {
            this.sumPosition.TotalGrossAmount = res.TotalGrossAmount;
            this.sumPosition.TotalNetAmount = res.TotalNetAmount;
            this.sumPosition.TotalTaxAmount = res.TotalTaxAmount;
            this.getPosition(this.form.value.InvoiceDocumentId);
          },
          (error) => {
            this.getPosition(this.form.value.InvoiceDocumentId);
            this.recalculatePositions();
            this.event.httpErrorNotification(error);
          },
          () => {
            this.findSocketAndRun('XGHSDYD2');
          }
        );
      this.addRow = false;
    } else {
      if (Array.isArray(e)) {
        this.positionArticleToAdd = e[0];
      } else {
        this.positionArticleToAdd = e;
      }

      if (
        this.positionArticleToAdd?.EnteredAmount != 0 &&
        this.positionArticleToAdd?.ProductType != 'Zestaw'
      ) {
        this.addNewPos(this.positionArticleToAdd);
        return;
      }

      if (this.positionArticleToAdd?.ProductType == 'Zestaw') {
        this.isSet.set(true);
        this.cd.detectChanges();
        return;
      }

      if (
        this.positionArticleToAdd.StockAvailable == 0 &&
        this.ObslZam.Value == 'Tak' &&
        this.form.value.type == 's'
      ) {
        this.appService
          .getAuth(
            `products/${this.positionArticleToAdd.ProductId}/substitutes`
          )
          .subscribe((res) => {
            if (res.data.length > 0) {
              this.isShowSubstitutesQuestion.set(true);
              this.cd.detectChanges();
            } else {
              this.sell();
            }
          });
      } else {
        const exist = await this.getPositionsWithProductId();

        if (this.warnRepeatArticle === 'Tak' && exist) {
          this.articleAlreadyExistWarningVisible = true;
          this.cd.detectChanges();
          return;
        } else if (this.warnRepeatArticle === 'Blokuj' && exist) {
          this.articleAlreadyExistBlockVisible = true;
          this.cd.detectChanges();
          return;
        }
        this.sell();
      }
    }
    this.cd.detectChanges();
  }

  isVisibleSets = signal<boolean>(false);
  isSetPrice = signal<boolean>(false);
  yesAddSet = () => {
    this.isSet.set(false);
    this.isVisibleSets.set(true);
  };

  onSavingSets(e) {
    this.positionArticleToAdd.Amount = e;
    this.isVisibleSets.set(false);
    this.isSetPrice.set(true);
    this.cd.detectChanges();
  }

  yesAddSetPrice() {
    this.isSetPrice.set(false);
    let object = {
      IsSelectVariantComponents: true,
      ProductVariantId: this.positionArticleToAdd.ProductId,
      InvoiceDocumentId: this.form.value.InvoiceDocumentId,
      DocumentDate: this.form.value.DateOfInvoiceDocument,
      CustomerId: this.form.value.CustomerId,
      SumBy: this.form.value.SumBy,
      AmountOfSets: this.positionArticleToAdd.Amount,
      IsQuestionCalcPriceOfSets: true,
    };

    this.event.onShown()
    this.appService
      .postAuth(
        `invoices/documents/${this.form.value.InvoiceDocumentId}/positions/AddMultiple`,
        object
      )
      .subscribe(
        (res) => {
          this.sumPosition.TotalGrossAmount = res.TotalGrossAmount;
          this.sumPosition.TotalNetAmount = res.TotalNetAmount;
          this.sumPosition.TotalTaxAmount = res.TotalTaxAmount;
          this.dataSource.reload();
          this.cd.detectChanges;
          this.event.onHidden()
        },
        (error) => {
          this.event.onHidden()
          this.getPosition(this.form.value.InvoiceDocumentId);
          this.event.httpErrorNotification(error);
        }
      );
  }

  noAddSetPrice() {
    this.isSetPrice.set(false);
    let object = {
      IsSelectVariantComponents: true,
      ProductVariantId: this.positionArticleToAdd.ProductId,
      InvoiceDocumentId: this.form.value.InvoiceDocumentId,
      DocumentDate: this.form.value.DateOfInvoiceDocument,
      CustomerId: this.form.value.CustomerId,
      SumBy: this.form.value.SumBy,
      AmountOfSets: this.positionArticleToAdd.Amount,
      IsQuestionCalcPriceOfSets: false,
    };

    this.event.onShown()
    this.appService
      .postAuth(
        `invoices/documents/${this.form.value.InvoiceDocumentId}/positions/AddMultiple`,
        object
      )
      .subscribe(
        (res) => {
          this.sumPosition.TotalGrossAmount = res.TotalGrossAmount;
          this.sumPosition.TotalNetAmount = res.TotalNetAmount;
          this.sumPosition.TotalTaxAmount = res.TotalTaxAmount;
          this.dataSource.reload();
          this.event.onHidden()
        },
        (error) => {
          this.event.onHidden()
          this.getPosition(this.form.value.InvoiceDocumentId);
          this.event.httpErrorNotification(error);
        }
      );
  }

  sell = () => {
    this.addRow = false;
    this.cd.detectChanges();
    if (this.SzybkaSprzedazH == 'Tak') {
      this.addNewPos(this.positionArticleToAdd);
    } else {
      setTimeout(() => {
        this.newPositionConf = true;
        this.cd.detectChanges();
      }, 0);
    }
  };

  orderby: string = 'ProductFullName';
  order: string = 'ASC'; // asc/desc
  onFilterPosition(e) {
    if (e.selectedItem) {
      this.orderby = e.selectedItem.value;
      this.valueCriteriaPosition = e.selectedItem.value;
    }
    this.filterValuePosition = e.filterValue;
    this.getPosition(this.form.value.InvoiceDocumentId);
  }

  confirmAddArticle() {
    this.articleAlreadyExistWarningVisible = false;
    this.sell();
  }

  addPositionForm(positionForm) {
    if (!this.form.value.IsCurrencyDocument) {
      positionForm.PriceGrossCurrency = 0;
      positionForm.PriceNetCurrency = 0;
    }
    this.appService
      .postAuth(
        `invoices/documents/${this.form.value.InvoiceDocumentId}/positions/Add`,
        positionForm
      )
      .subscribe(
        (res) => {
          this.sumPosition.TotalGrossAmount = res.TotalGrossAmount;
          this.sumPosition.TotalNetAmount = res.TotalNetAmount;
          this.sumPosition.TotalTaxAmount = res.TotalTaxAmount;
          this.dataSource != null
            ? this.dataSource.reload().then((data) => {
                let index = data.findIndex(
                  (el) =>
                    el.WarehouseDocumentPositionId ==
                    res.WarehouseDocumentPositionId
                );

                setTimeout(() => {
                  if (index != -1) {
                    this.focusedRowIndex = index;
                    this.cd.detectChanges();
                  }
                }, 0);
              })
            : this.getPosition(this.form.value.InvoiceDocumentId);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  addNewPos = (e, barcodeUse?) => {
    let filter = `ProductId=${e.ProductId}&CustomerId=${
      this.form.controls.CustomerId.value
    }&DocumentId=${this.form.value.InvoiceDocumentId}&DocumentType=${
      this.form.value.Signature
    }&documentDate=${this.event.dataFormatted({
      value: new Date(this.form.controls.DateOfInvoiceDocument.value),
    })}&IsBuy=${this.selectedDocumentType.IsIncome}&CurrencyCode=${
      this.form.controls.CurrencyCode.value
    }&CurrencyFactor=${this.form.controls.CurrencyFactor.value}`;
    this.appService
      .getAuth(
        `products/${e.ProductId}/productDetailsForCustomer/${this.form.controls.CustomerId.value}?${filter}`
      )
      .subscribe(
        (res) => {
          let positionForm: any = {};
          positionForm.ProductId = e.ProductId;
          positionForm.Amount =
            e.EnteredAmount && e.EnteredAmount != 0 ? e.EnteredAmount : 1;
          positionForm.Unit = res.BaseUnitCode;
          positionForm.PriceNet = res.PriceDetail.PriceNet;
          positionForm.PriceGross = res.PriceDetail.PriceGross;
          positionForm.PriceNetCurrency = res.PriceDetail.PriceNetCurrency;
          positionForm.PriceGrossCurrency = res.PriceDetail.PriceGrossCurrency;
          positionForm.Discount = res.PriceDetail.Discount;
          positionForm.TaxCode = res.TaxCode;
          positionForm.Description = '';
          positionForm.PriceIdentificator = res.PriceDetail.PriceIdentificator;
          positionForm.SerialNumber = e.SerialNumber;
          positionForm.ExpirationDate = null;
          positionForm.Field1 = null;
          positionForm.Field2 = null;
          positionForm.Field3 = null;
          positionForm.Field4 = null;
          positionForm.Field5 = null;
          positionForm.Field6 = null;
          positionForm.Field7 = null;
          positionForm.Field8 = null;
          positionForm.Field9 = null;
          positionForm.Field10 = null;
          positionForm.WarehouseDocumentPositionId = 0;
          positionForm.DestinationWarehouseId = 0;
          positionForm.DocumentDate = this.form.value.DateOfInvoiceDocument;
          positionForm.WarehouseDateOfDocument =
            this.form.value.DateOfInvoiceDocument;
          positionForm.InvoiceDocumentId = this.form.value.InvoiceDocumentId;
          positionForm.SumBy = this.form.value.SumBy;
          positionForm.Factor = res.PriceDetail.Factor;
          positionForm.TypeOfCrossBorderSale = res.typeOfCrossBorderSale;
          positionForm.DeliveryId = 0;

          if (barcodeUse) {
            this.appService
              .getAuth(
                `products/${positionForm.ProductId}/barcodes?barcode=${barcodeUse}`
              )
              .subscribe((res) => {
                positionForm.Amount = res.data[0].DefaultQuantity;
                const ProductUnitId = res.data[0].ProductUnitId;
                if (
                  res.data[0].ProductUnitId != 0 &&
                  res.data[0].ProductUnitId != null
                ) {
                  this.appService
                    .getAuth(
                      `products/units?articleId=${positionForm.ProductId}&unitId=${ProductUnitId} `
                    )
                    .subscribe((res) => {
                      if (res.data.length > 0) {
                        positionForm.Unit = res.data[0].Code;
                        positionForm.Factor = res.data[0].Factor;
                        positionForm.Amount =
                          positionForm.Amount * res.data[0].Factor;
                        positionForm.PriceNet = (
                          positionForm.PriceNet * res.data[0].Factor
                        ).toFixed(2);
                        positionForm.PriceGross = (
                          positionForm.PriceGross * res.data[0].Factor
                        ).toFixed(2);
                        positionForm.PriceNetCurrency = (
                          positionForm.PriceNetCurrency * res.data[0].Factor
                        ).toFixed(4);
                        positionForm.PriceGrossCurrency = (
                          positionForm.PriceGrossCurrency * res.data[0].Factor
                        ).toFixed(4);
                        this.addPositionForm(positionForm);
                      }

                      if (res.data.length == 0) {
                        this.appService
                          .getAuth(`products/units?unitId=${ProductUnitId}`)
                          .subscribe((res) => {
                            positionForm.Unit = res.data[0].Code;
                            positionForm.Factor = res.data[0].Factor;
                            positionForm.Amount =
                              positionForm.Amount * res.data[0].Factor;
                            positionForm.PriceNet = Number(
                              (
                                positionForm.PriceNet * res.data[0].Factor
                              ).toFixed(2)
                            );
                            positionForm.PriceGross = Number(
                              (
                                positionForm.PriceGross * res.data[0].Factor
                              ).toFixed(2)
                            );
                            positionForm.PriceNetCurrency = Number(
                              (
                                positionForm.PriceNetCurrency *
                                res.data[0].Factor
                              ).toFixed(4)
                            );
                            positionForm.PriceGrossCurrency = Number(
                              (
                                positionForm.PriceGrossCurrency *
                                res.data[0].Factor
                              ).toFixed(4)
                            );
                            this.addPositionForm(positionForm);
                          });
                      }
                    });
                } else {
                  this.addPositionForm(positionForm);
                }
              });
          } else {
            this.addPositionForm(positionForm);
          }
        },
        () => {}
      );
  };

  closeArticle = () => {
    this.addRow = false;
    this.isEditPosition = false;
    this.newPositionConf = false;
    this.editRow = false;

    setTimeout(() => {
      this.isAppArticles = false;
      this.event.setFocus(this.itemGrid);
    }, 100);
    this.cd.detectChanges();
  };

  getProductDetails(position: any) {
    return new Promise((resolve) => {
      let filter = `ProductId=${position.ProductId}&CustomerId=${
        this.form.controls.CustomerId.value
      }&DocumentId=${this.form.value.InvoiceDocumentId}&DocumentType=${
        this.form.value.Signature
      }&documentDate=${this.event.dataFormatted({
        value: new Date(this.form.controls.DateOfInvoiceDocument.value),
      })}&IsBuy=${this.selectedDocumentType.IsIncome}&Currency=${
        this.form.controls.CurrencyCode.value
      }&CurrencyFactor=${this.form.controls.CurrencyFactor.value}`;
      this.appService
        .getAuth(
          `products/${position.ProductId}/productDetailsForCustomer/${this.form.controls.CustomerId.value}?${filter}`
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          () => {}
        );
    });
  }

  checkBarcodeDefaultData(barcodeUse, ProductId) {
    return new Promise((resolve) => {
      let data = {
        DefaultQuantity: 1,
        Code: '',
        Factor: 1,
      };
      this.appService
        .getAuth(`products/${ProductId}/barcodes?barcode=${barcodeUse}`)
        .subscribe((res) => {
          data.DefaultQuantity = res.data[0].DefaultQuantity;
          const ProductUnitId = res.data[0].ProductUnitId;
          if (
            res.data[0].ProductUnitId != 0 &&
            res.data[0].ProductUnitId != null
          ) {
            this.appService
              .getAuth(
                `products/units?articleId=${ProductId}&unitId=${res.data[0].ProductUnitId} `
              )
              .subscribe((response) => {
                if (response.data.length > 0) {
                  data.Code = response.data[0].Code;
                  data.Factor = response.data[0].Factor;

                  resolve(data);
                }

                if (response.data.length == 0) {
                  this.appService
                    .getAuth(`products/units?unitId=${ProductUnitId}`)
                    .subscribe((res) => {
                      data.Code = res.data[0].Code;
                      data.Factor = res.data[0].Factor;
                      resolve(data);
                    });
                }
              });
          } else {
            resolve(data);
          }
        });
    });
  }

  async changePosition(position, details, action: string = 'add', e?) {
    const units = details.Units;
    const positionUnit = units.find((el) => el.Code === position.Unit);
    //position.Factor = details.PriceDetail.Factor
    position.Factor = positionUnit.Factor;
    position.Amount = position.Factor * position.Amount;
    let barcode: any = {};

    if (e && e.items.barcodeUse) {
      barcode = await this.checkBarcodeDefaultData(
        e.items.barcodeUse,
        position.ProductId
      );

      if (barcode.Code == '') {
        barcode.DefaultQuantity = position.Factor;
        barcode.Code = position.Unit;
        barcode.Factor = position.Factor;
      }
    } else {
      barcode.DefaultQuantity = 1;
      barcode.Code = position.Unit;
      barcode.Factor = position.Factor;
    }

    if (positionUnit) {
      if (action === 'add') {
        position.AmountNew =
          position.Unit == barcode.Code
            ? (position.Amount += barcode.DefaultQuantity * barcode.Factor)
            : (position.Amount =
                position.Amount + barcode.DefaultQuantity * barcode.Factor);
      } else if (action === 'concat') {
        position.Amount =
          position.Unit == barcode.Code
            ? (position.Amount -= barcode.DefaultQuantity * barcode.Factor)
            : (position.Amount =
                position.Amount - barcode.DefaultQuantit * barcode.Factor);
      } else if (action === 'update') {
        position.Amount = position.Factor * position.AmountNew;
      }
      if (position.Amount <= 0) {
        await this.deletePosition(position.WarehouseDocumentPositionId);
      } else {
        await this.updatePosition(position, barcode);

        this.dataSource.reload().then((data) => {
          let index = data.findIndex(
            (el) =>
              el.WarehouseDocumentPositionId ==
              position.WarehouseDocumentPositionId
          );

          setTimeout(() => {
            if (index != -1) {
              this.focusedRowIndex = index;
              this.cd.detectChanges();
            }
          }, 0);
        });
        if (action === 'update') {
          this.getPosition(this.form.value.InvoiceDocumentId);
        }
      }
    }
  }

  displayQuantity = '0';
  dotClick = () => {
    this.displayQuantity = this.displayQuantity + '.';
  };

  @ViewChild('gridClass') itemGrid;

  deletePosition = (WarehouseDocumentPositionId?) => {
    this.isDeleteRow = false;

    let idPosition = WarehouseDocumentPositionId
      ? WarehouseDocumentPositionId
      : this.focusedSelected[0].WarehouseDocumentPositionId;

    if (this.selectedRow.length > 0) {
      let arrayToDelete = [];

      this.selectedRow.forEach((field) => {
        arrayToDelete.push(
          this.appService.deleteAuth(
            `invoices/documents/${this.form.value.InvoiceDocumentId}/positions/${field}?sumBy=${this.form.value.SumBy}`
          )
        );
      });

      forkJoin(arrayToDelete).subscribe(
        (res) => {
          this.event.setFocus(this.gridClass);
          this.focusedSelected = [];
          this.focusedRowIndex = 0;
          this.dataSource.reload();
          this.sumPosition.TotalNetAmount = res[res.length - 1].TotalNetAmount;
          this.sumPosition.TotalGrossAmount =
            res[res.length - 1].TotalGrossAmount;
          this.sumPosition.TotalTaxAmount = res[res.length - 1].TotalTaxAmount;
        },
        () => {},
        () => {
          this.findSocketAndRun('XGHSDYU2');
        }
      );
      return;
    }

    this.appService
      .deleteAuth(
        `invoices/documents/${this.form.value.InvoiceDocumentId}/positions/${idPosition}?sumBy=${this.form.value.SumBy}`
      )
      .subscribe(
        (res) => {
          this.focusedSelected = [];
          this.focusedRowIndex = 0;
          this.event.setFocus(this.gridClass);
          this.dataSource.reload();
          this.sumPosition.TotalNetAmount = res.TotalNetAmount;
          this.sumPosition.TotalGrossAmount = res.TotalGrossAmount;
          this.sumPosition.TotalTaxAmount = res.TotalTaxAmount;
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHSDYU2');
        }
      );
  };

  updatePosition(position, barcode) {
    position.WarehouseDateOfDocument =
      this.form.controls.DateOfInvoiceDocument.value;
    position.SumBy = this.form.controls.SumBy.value;

    if (position.Unit != barcode.Code) {
      position.Factor = barcode.Factor;
      position.Unit = barcode.Code;
      position.PriceNet = (position.PriceNet * barcode.Factor).toFixed(2);
      position.PriceGross = (position.PriceGross * barcode.Factor).toFixed(2);
      position.PriceGrossCurrency = (
        position.PriceGrossCurrency * barcode.Factor
      ).toFixed(4);
      position.PriceGrossCurrency = (
        position.PriceGrossCurrency * barcode.Factor
      ).toFixed(4);
    }

    return new Promise((resolve) => {
      this.appService
        .putAuth(
          `invoices/documents/${this.form.controls.InvoiceDocumentId.value}/positions/${position.WarehouseDocumentPositionId}`,
          position
        )
        .subscribe(
          (res) => {
            this.sumPosition = res;
            resolve(true);
          },
          () => {
            resolve(false);
          }
        );
    });
  }

  async onProductScanerInputChanged(e) {
    if (e.items.length == 0) {
      this.event.showNotification(
        'error',
        this.translate.instant('reader.noProductsFound')
      );
      return;
    }

    switch (e.mode) {
      case 0: // add
        if (e.items.length == 1) {
          const element = this.dataSource
            .items()
            .find((x) => x.ProductId == e.items[0].ProductId);
          if (element) {
            // zwieksz ilosc + 1
            const isPossible = await this.checkModifiScaner(element);

            if (isPossible) {
              this.newPositionConf = true;
              this.cd.detectChanges(); // Dodana detekcja zmian
              // zwieksz ilosc + 1
              this.getProductDetails(element).then((details) => {
                this.changePosition(element, details, 'add', e);
                this.cd.detectChanges();
                this.event.onHidden();
              });
            }
            return;
          } else {
            // dodaj nowy
            this.newPositionConf = true;
            this.cd.detectChanges(); // Dodana detekcja zmian
            this.addNewPos(e.items[0], e.items.barcodeUse);
          }
        } else {
          // dodaj kilka - popup z wyborem zawierajacy wszystkie pozycje oferty
        }
        break;

      case 1: // odejmij
        if (e.items.length == 1) {
          const element = this.dataSource
            .items()
            .find((x) => x.ProductId == e.items[0].ProductId);
          if (element) {
            // zmniejsz ilosc - 1
            const isPossible = await this.checkModifiScaner(element);

            if (isPossible) {
              // zwieksz ilosc - 1
              this.getProductDetails(element).then((details) => {
                this.changePosition(element, details, 'concat', e);
                this.cd.detectChanges();

                this.event.onHidden();
              });
            }
            return;
          }
        } else {
          // zmniejsz ilosc - popup z wyborem zawierajacy pozycje oferty
        }
        break;

      case 2: // szukaj - podswietlenie znalezionego elementu / popup z produktami ?
        if (e.items.length == 1) {
          const index = this.dataSource
            .items()
            .findIndex((x) => x.ProductId == e.items[0].ProductId);
          if (index !== -1) {
            this.focusedRowIndex = index;
            this.cd.detectChanges();
            setTimeout(() => {
              this.itemGrid.instance.focus();
            }, 100);
          } else {
            this.event.showNotification(
              'error',
              this.translate.instant('reader.noProductsFound')
            );
          }
        }
        break;

      case 3: // nadpisz - nadpisanie wybranego elementu ?
        if (e.items.length == 1) {
          const index = this.dataSource
            .items()
            .findIndex((x) => x.ProductId == e.items[0].ProductId);

          if (index == -1) {
            this.newPositionConf = true;
            this.cd.detectChanges(); // Dodana detekcja zmian
            this.addNewPos(e.items[0]);
          } else {
            const details = await this.getProductDetails(
              this.dataSource._items[index]
            );
            let data = this.dataSource._items[index];
            this.changePosition(data, details, 'update');
          }
        }
        break;

      case 4: // nowy - dodanie nowego Tow000033
        if (e.items.length == 1) {
          // dodaj nowy
          this.newPositionConf = true;
          this.cd.detectChanges(); // Dodana detekcja zmian
          this.addNewPos(e.items[0]);
        } else {
          // popup z elementami
        }
        break;

      default:
        break;
    }
  }

  setFocus = (number) => {
    number.focus();
  };

  onInsertedPosition = (e, mode: 'add' | 'edit') => {
    this.sumPosition.TotalGrossAmount = e.res.TotalGrossAmount;
    this.sumPosition.TotalNetAmount = e.res.TotalNetAmount;
    this.sumPosition.TotalTaxAmount = e.res.TotalTaxAmount;

    if (!this.dataSource) {
      this.getPosition(this.form.value.InvoiceDocumentId);
    } else {
      this.dataSource.reload().then(() => {
        setTimeout(() => {
          if (
            this.AutomatArtykHan.Value == 'Tak' &&
            !this.readOnly &&
            mode == 'add'
          ) {
            this.beforeAddArticle();
          }
        }, 0);

        const index = this.dataSource._items.findIndex(
          (x) =>
            x.WarehouseDocumentPositionId == e.res.WarehouseDocumentPositionId
        );
        if (index == -1) return;
        else {
          this.focusedRowIndex = index;
          this.itemGrid.instance.scrollToIndex(index);
          this.focusedSelected = [this.dataSource._items[index]];
        }
      });
    }

    if (mode === 'add') {
      this.findSocketAndRun('XGHSDYD2');
    } else {
      this.findSocketAndRun('XGHSDYP2');
    }
  };

  cancelChange = () => {
    if (this.sumPosition.TotalGrossAmount > 0) {
      this.isCancelRow = true;
      return;
    } else {
      this.deleteDocument(true);
    }
  };

  deleteDocument = (isHeader) => {
    return new Promise((resolve) => {
      if (this.form.value.InvoiceDocumentId != 0) {
        this.isCancelRow = false;
        this.appService
          .deleteAuth(
            `invoices/documents/${this.form.value.InvoiceDocumentId}?isCancel=true&isDeleteOnlyInvoiceDocument=false`
          )
          .subscribe(
            () => {
              this.createForm();
              this.dataSource = null;
              let list = this.event.decryptString(
                localStorage.getItem('typesInvoice')
              );
              this.setDocumentTypeId(list);
              if (isHeader) {
                this.setCustomerId();
              }
              this.event.onHidden();
              this.cd.detectChanges();
              resolve(true);
            },
            (error) => {
              resolve(true);
              this.event.onHidden();
              this.cd.detectChanges();
              this.event.httpErrorNotification(error);
            }
          );
      } else resolve(true);
    });
  };

  checkModifiScaner(item, notWarning?) {
    return new Promise((resolve) => {
      this.appService
        .getAuth(
          `invoices/documents/${this.form.value.InvoiceDocumentId}/positions/${item.WarehouseDocumentPositionId}/checkForModify`
        )
        .subscribe(
          (res) => {
            if (res.QuestionMessage != null) {
              if (!notWarning) {
                this.event.showNotification(
                  'info',
                  this.translate.instant(
                    'form-commercial-operation.onlyThePriceCanBeChanged'
                  )
                );
              }

              this.event.onHidden();
              resolve(false);
            } else {
              resolve(true);
            }
          },
          (error) => {
            this.event.httpErrorNotification(error);
            resolve(false);
          }
        );
    });
  }

  countAmountDocument = () => {
    setTimeout(() => {
      this.sumPosition.TotalGrossAmount = 0;
      this.sumPosition.TotalNetAmount = 0;
      this.sumPosition.TotalTaxAmount = 0;

      this.dataSource._items.forEach((field) => {
        this.sumPosition.TotalGrossAmount += field.TotalGrossAmount;
        this.sumPosition.TotalNetAmount += field.TotalNetAmount;
        this.sumPosition.TotalTaxAmount =
          field.TotalGrossAmount - field.TotalNetAmount;
      });
    }, 500);
  };

  async changeAmountProduct(data, up?, e?) {
    if (up == 'up') {
      data.AmountNew = data.Amount + 1;
    }

    if (up == 'down') {
      data.AmountNew = data.Amount - 1;
    }

    if (data.Amount != data.AmountNew) {
      this.isWhaitForResponse = true;
      this.focusedSelected[0] = data;

      const isPossible = await this.checkModifiScaner(this.focusedSelected[0]);

      if (isPossible && (e?.event || up)) {
        // data.Amount = data.AmountNew - 1;
        const details = await this.getProductDetails(data);
        this.changePosition(data, details, 'update');
        // this.getPosition(this.form.value.InvoiceDocumentId);
      } else {
        data.AmountNew = data.Amount;
      }
    }

    setTimeout(() => {
      this.isOpened = true;
    }, 2000);
  }

  async changePriceProduct(data, up?, e?) {
    if (up == 'up') {
      data.PriceGrossNew = data.PriceGross + 0.01;
    }

    if (up == 'down') {
      data.PriceGrossNew = data.PriceGross - 0.01;
    }
    if (data.PriceGross != data.PriceGrossNew && (e?.event || up)) {
      this.isWhaitForResponse = true;
      const details = await this.getProductDetails(data);
      data.PriceGross = data.PriceGrossNew;
      this.focusedSelected[0] = data;
      data.Amount--;

      data.PriceNet = this.event.countAmountNet(
        data.PriceGross,
        Number(data.TaxCode)
      );

      this.changePosition(data, details, 'add');
      this.getPosition(this.form.value.InvoiceDocumentId);
    }

    setTimeout(() => {
      this.isOpened = true;
    }, 2000);
  }

  onSave = () => {
    this.submitted = true;
    if (this.form.valid) {
      if (this.isValidUserExtensions.checkIsValid()) {
        this.acceptDocument();
        return;
      }
    }
  };

  isValidSum: boolean = false;
  btnConfig: any = 'saveCancel';
  confirmText: string = '';
  checkSumBy = () => {
    let KontrSumDokHanMag =
      this.event.getConfigurationParameter('KontrSumDokHanMag')?.Value;

    if (KontrSumDokHanMag == 'Ostrzegaj') {
      this.appService
        .getAuth(
          `invoices/documents/${this.form.value.InvoiceDocumentId}/checkSumBy?sumBy=${this.form.value.SumBy}`
        )
        .subscribe((res) => {
          if (!res.Value) {
            this.btnConfig = 'saveCancel';
            this.confirmText =
              'form-commercial-operation.thereIsAwarehouseDocumentWithAtotalingModeDifferentFromTheDocumentBeingApprovedDoYouContinue';
            this.isValidSum = true;
          } else {
            this.onSave();
          }
        });
    }

    if (KontrSumDokHanMag == 'Brak reakcji') {
      this.onSave();
    }

    if (KontrSumDokHanMag == 'Blokuj') {
      this.btnConfig = 'close';
      this.confirmText = 'form-commercial-operation.noApprovalPossible';
      this.isValidSum = true;
    }
  };

  setDefaultPaymentForm = () => {
    this.appService
      .getAuth(`customers/${this.form.value.CustomerId}`)
      .subscribe((res) => {
        let quantityDay =
          this.form.value.type == 's' ? res.DaysForDue : res.DaysForCommitment;
        let DaysForDue = new DatePipe('en-US').transform(
          new Date().getTime() + quantityDay * 1000 * 60 * 60 * 24,
          'yyyy-MM-dd'
        );
        this.form.controls.DateOfPayment.setValue(DaysForDue);
        this.form.controls.PaymentFormName.setValue(res.PaymentForm);
        this.form.controls.DateOfPaymentDay.setValue(quantityDay);
      });
  };

  focusedSuspendDocument = [];
  onFocusedRowChangedSuspend = (e) => {
    this.focusedSuspendDocument = [e.row.data];
  };

  unLockSuponded() {
    this.appService
      .deleteAuth(
        `invoices/documents/${this.form.value.InvoiceDocumentId}?isCancel=true&isDeleteOnlyInvoiceDocument=false`
      )
      .subscribe(
        () => {
          this.createForm();
          this.getDetalisDocument(
            this.focusedSuspendDocument[0].InvoiceDocumentId
          );
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  }

  getDetalisDocument = (InvoiceDocumentId) => {
    this.event.onShown();
    this.appService
      .getAuth(`invoices/documents/${InvoiceDocumentId}`)
      .subscribe(
        (res) => {
          setTimeout(() => {
            this.form.controls.SumBy.setValue(res.SumBy.replace(' ', ''));
          }, 0);

          setTimeout(() => {
            this.sumPosition.TotalGrossAmount = res.TotalGrossAmount;
            this.sumPosition.TotalNetAmount = res.TotalNetAmount;
            this.sumPosition.TotalTaxAmount =
              res.TotalGrossAmount - res.TotalNetAmount;
          }, 500);

          this.form.patchValue(res);
          this.cd.detectChanges();
          this.event.onHidden();
          this.getPosition(this.form.value.InvoiceDocumentId);

          let amountDay =
            (new Date(this.form.value.DateOfPayment).getTime() -
              new Date(this.form.value.DateOfInvoiceDocument).getTime()) /
            3600000;
          this.form.controls.DateOfPaymentDay.setValue(
            Math.round(amountDay / 24)
          );
          if (res.AdvancePayment == null) {
            this.form.controls.AdvancePayment.setValue(0);
          }
          this.form.controls.DiscountMarkup.setValue(res.DiscountMarkup / 100);
          this.getSuspendDocument();
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  };

  getSuspendDocument = () => {
    //dataSourceSuspend
    this.appService
      .getAuth(
        `invoices/documents?InvoiceDocumentId=${this.form.value.InvoiceDocumentId}&IsSuspended=true`
      )
      .subscribe((res) => {
        this.dataSourceSuspend = res.data;
        for (let i = this.dataSourceSuspend.length - 1; i >= 0; i--) {
          if (this.dataSourceSuspend[i].InvoiceDocumentId == 0) {
            this.dataSourceSuspend.splice(i, 1);
          }
        }
      });
  };

  suspend = () => {
    let object = {
      InvoiceDocumentId: this.form.value.InvoiceDocumentId,
      CustomerId: this.form.value.CustomerId,
      SumBy: this.form.value.SumBy,
      PaymentFormName: this.form.value.PaymentFormName,
      DiscountMarkup: this.form.value.DiscountMarkup * 100,
      EmployeeId: this.form.value.EmployeeId,
      DateOfPayment: new DatePipe('en-US').transform(
        this.form.value.DateOfPayment,
        'yyyy-MM-dd'
      ),
    };
    this.appService
      .putAuth(
        `invoices/documents/${this.form.value.InvoiceDocumentId}/suspend`,
        object
      )
      .subscribe(
        () => {
          this.createForm();
          this.getTypeDocuemnt();
          this.setCustomerId().then(() => {
            this.getSuspendDocument();
          });
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  };

  acceptDocument = () => {
    // const documentTypeSignature = this.form.get('Signature').value;
    // let printerData = {
    //   documentId: this.form.value.InvoiceDocumentId,
    //   reportTypeId: documentTypeSignature == 'PR' ? 5 : null,
    // };
    this.event.onShown();
    if (this.dataSource._items.length == 0 && !this.DokHandlBezPozycji) {
      this.event.showNotification(
        'warning',
        this.translate.instant(
          'form-commercial-operation.YouAreNotAuthorizedToApproveAdocumentWithNoItems'
        )
      );
      this.event.onHidden();
      return;
    }

    this.form.value.DiscountMarkup = this.form.value.DiscountMarkup * 100;
    this.appService
      .postAuth(
        `invoices/documents/${this.form.value.InvoiceDocumentId}/accept`,
        this.form.value
      )
      .subscribe({
        next: async () => {
          this.submitted = false;
          this.event.onHidden();

          await this.getDocumentsToPrint();

          await this.printDocumentsAfterSave();

          if (this.onlyWZ) {
            //kiedy ustawiony jest zapis tylko WZ usuwam dokument handlowy zostawiajac tylko WZ
            this.appService
              .deleteAuth(
                `invoices/documents/${this.form.value.InvoiceDocumentId}?isDeleteOnlyInvoiceDocument=true&IsCancel=false`
              )
              .subscribe(() => {});
          }

          this.createForm();
          this.getTypeDocuemnt();
          this.setCustomerId();

          this.event.showNotification(
            'success',
            this.translate.instant('buffor.documentAdded')
          );
        },
        error: (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        },
        complete: () => {
          this.findSocketAndRun('XGHSDZ2');
        },
      });
  };

  async getDocumentsToPrint(): Promise<
    InvoiceDocument[] | WarehouseDocument | FinancialDocument[] | any[]
  > {
    let documentsToPrint:
      | InvoiceDocument[]
      | WarehouseDocument
      | FinancialDocument[]
      | any[] = [];
    this.print.printAfterSaveSignal.set(false);
    this.print.disallowFiscalPrint.set(false);
    this.print.documentsToPrintAfterSaveSignal.set([]);
    let invoice: InvoiceDocument = undefined;
    const printConfig: PrintConfig =
      this.print.currentComponentConfig.printConfig;
    this.print.disallowFiscalPrint.set(!printConfig.fiscalPrinterEnabled);
    this.print.closePrintReportWindowSubject.next('');

    if (printConfig.warehouseDocument) {
      let connectedWZs = await this.print.getConnectedWarehouseDocuments(
        this.form.value.InvoiceDocumentId
      );
      for (let connectedWZ of connectedWZs) {
        documentsToPrint.push(connectedWZ);
      }
    }

    if (printConfig.commercialDocument && !this.onlyWZ) {
      invoice = invoice !== undefined ? invoice : await this.getInvoiceData();
      documentsToPrint.push(invoice);
    }

    if (printConfig.financialDocument && !this.onlyWZ) {
      invoice = invoice !== undefined ? invoice : await this.getInvoiceData();
      try {
        const connectedKPs = await this.print.getConnectedFinanceDocuments(
          invoice.SettlementId
        );
        for (let connectedKP of connectedKPs) {
          documentsToPrint.push(connectedKP);
        }
      } catch (e) {
        console.error(e);
      }
    }

    this.print.documentsToPrintAfterSaveSignal.set(documentsToPrint);
    this.print.printAfterSaveSignal.set(documentsToPrint.length > 0);
    return documentsToPrint;
  }

  getInvoiceData(): Promise<InvoiceDocument> {
    return new Promise<InvoiceDocument>((resolve, reject) => {
      this.appService
        .getAuth(
          `invoices/documents?InvoiceDocumentId=${this.form.value.InvoiceDocumentId}`
        )
        .subscribe({
          next: (res) => {
            if (res.data.length > 0) {
              resolve(res.data[0]);
            } else {
              resolve(null);
            }
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
            reject(err);
          },
        });
    });
  }

  async printDocumentsAfterSave(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const resolveFunc = this.print.printDocumentsAfterSave(this.reports);
      resolve(resolveFunc);
    });
  }

  //
  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;

    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false; // znalazlo
    } else {
      // gniazdo przed - uruchom gniazdo po
      this.onNestFinished({ SocketCode });
      return true; // nieznalazlo
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGHSDO1':
        this.openNestFinished = true;
        this.findSocketAndRun('XGHSDO2');
        break;
      case 'XGHSDZ1':
        this.isToPay = true;
        this.cd.detectChanges();
        break;
      case 'XGHSDRLZ1':
        this.isOrderRealization = true;
        break;
      case 'XGHSDWDM1':
        this.isWarehouseDocuments = true;
        break;
      case 'XGHSDYD1':
        this.findSocketAndRun('XGHSDYA1', 'add');
        break;
      case 'XGHSDYP1':
        this.findSocketAndRun('XGHSDYA1', 'edit');
        break;
      case 'XGHSDYU1':
        this.findSocketAndRun('XGHSDYA1', 'delete');
        break;
      case 'XGHSDYA1':
        if (this.nestOperation === 'add') {
          this.addRow = true;
          this.isAppArticles = true;
        } else if (this.nestOperation === 'edit') {
          this.editPosition();
        } else if (this.nestOperation === 'delete') {
          this.isDeleteRow = true;
        }
        break;
      case 'XGHSDYD2':
        this.findSocketAndRun('XGHSDYA2');
        break;
      case 'XGHSDYP2':
        this.findSocketAndRun('XGHSDYA2');
        break;
      case 'XGHSDYU2':
        this.findSocketAndRun('XGHSDYA2');
        break;
    }
  }

  isAnswerEditPosition: boolean = false;

  async editPosition() {
    const isPossible = await this.checkModifiScaner(
      this.focusedSelected[0],
      true
    );
    if (isPossible) {
      this.isAnswerEditPosition = false;
      this.isEditPosition = true;
      this.cd.detectChanges();
    } else {
      this.isAnswerEditPosition = true;
      this.cd.detectChanges();
    }
  }

  isOnlyPriceEdit: boolean = false;
  yesEditPosition = () => {
    this.isAnswerEditPosition = false;
    this.isOnlyPriceEdit = true;
    this.isEditPosition = true;
    this.cd.detectChanges();
  };

  noEditPosition = () => {
    this.isAnswerEditPosition = false;
    this.cd.detectChanges();
  };

  onCustomerOpened() {
    this.findSocketAndRun('XGHSDWK1');
  }

  onSelectCustomerShortcut(e) {
    setTimeout(() => {
      this.selectCustomerShortcut = e;
      this.cd.detectChanges();
    }, 100);
  }

  onEmployeeOpened() {
    this.findSocketAndRun('XGHSDWPR1');
  }

  beforeAddArticle() {
    if (
      this.form.value.InvoiceDocumentNumber === '' ||
      this.form.value.InvoiceDocumentNumber === null
    ) {
      this.submitted = true;
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.insertDocumentNumbert'
        )
      );
      return;
    }

    if (this.form.value.CustomerId == null) {
      this.submitted = true;
      this.event.showNotification(
        'info',
        this.translate.instant('buffor.selectAontractor')
      );
      return;
    }

    this.findSocketAndRun('XGHSDYD1');
  }

  beforeEditPosition() {
    this.findSocketAndRun('XGHSDYP1');
    //
  }

  onResizeStart = () => {};

  icon = 'icon absui-icon--fullscreen2';

  getRequestFullScreen() {
    if (this.fullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      this.icon = 'icon absui-icon--fullscreen2';
      this.cd.detectChanges();
      this.event.setFullscreen.next(false);
      this.isShowSidePanel = false;
      this.fullScreen = false;

      return;
    }

    this.icon = 'icon absui-icon--fullscreen-off';
    const html = document.documentElement;
    if (html.requestFullscreen) {
      html.requestFullscreen();
    }
    this.event.setFullscreen.next(true);
    this.fullScreen = true;
    this.cd.detectChanges();
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.valueCriteriaPosition) {
      this.valueCriteriaPosition = orderBy;
    } else {
      this.switchOrder();
    }
    this.getPosition(this.form.value.InvoiceDocumentId);
    this.cd.detectChanges();
  };

  onCollectorOpened = (e) => {
    if (e) {
      this.isCollectorVisible = true;
    }
  };

  loadCollectorConfig() {
    if (this.form.value.InvoiceDocumentId) {
      this.collectorTransferConfig.DocumentId =
        this.form.value.InvoiceDocumentId;
    }
    if (this.form.get('CustomerId').value) {
      this.collectorTransferConfig.CustomerId =
        this.form.get('CustomerId').value;
    }
  }

  async refreshArticleDocumentList() {
    await this.dataSource.reload();
    this.recalculatePositions();
  }

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGH');
    }
    this.gridClass.instance.focus();
  }

  onInsertedOrderRealization(e) {
    this.printSettingsService.currentComponentName.set('retailSales');
    this.countValueDocument(e);
    this.getPosition(this.form.value.InvoiceDocumentId);
    this.findSocketAndRun('XGHSDRLZ2');
  }

  noSubstitutes = () => {
    this.isShowSubstitutesQuestion.set(false);
    this.sell();
  };

  isShowSubstitutes = signal(false);
  yesSubstitutes = () => {
    this.isShowSubstitutesQuestion.set(false);
    this.isShowSubstitutes.set(true);
  };

  // printConfig: PrintConfig;
  // getPrintConfig(e) {
  //   this.printConfig = e;
  // }
}

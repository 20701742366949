<dx-popup
  [(width)]="localStorageData.windowSize.width"
  [(height)]="localStorageData.windowSize.height"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="event.deviceType != 'mobile'"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="280"
  [fullScreen]="
    event.deviceType != 'mobile'
      ? localStorageData.windowSize.isFullscreen
      : false
  "
  (onResizeEnd)="onResizeEnd()"
  (onResize)="onResizeStart($event)"
  [maxHeight]="maxHeight"
  (fullScreenChange)="fullScreenChange($event)"
  (onShown)="
    setFocus();
    event.onShownPopUp();
    loadChildComponent = true;
    cd.detectChanges();
    onShown()
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  (onInitialized)="onInit($event)"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{
        boxShadow: scrollPosition > 0 && event.deviceType != 'mobile'
      }"
    >
      <div
        style="display: inline-block"
      >
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>
    </div>

    <dx-scroll-view
      width="100%"
      direction="vertical"
      [height]="'100%'"
      (onScroll)="onScroll($event)"
    >
      <form [formGroup]="form">
        <div
          class="header-document"
          *ngIf="event.deviceType != 'mobile'; else mobileHeaderTemplate"
        >
          <header (dblclick)="changeHeader()">
            <div *ngIf="showHeader">
              <p class="p-header-document">
                {{ "form-financial-document.headerData" | translate }}
              </p>
              <i
                class="icon dx-icon-spinup"
                (click)="changeHeader()"
                *ngIf="showHeader"
              ></i>
            </div>
            <div *ngIf="!showHeader">
              <p class="p-header-info">
                {{ "noteNumber" | translate }}:
                <strong>{{ form.value.Number }}</strong> |
                {{ "baseCurrencies.ofTheDay" | translate }}:
                <strong>{{ form.value.Date | date : event.dateFormat }}</strong> |
                {{"form-financial-document.payer" | translate }}:
                <strong>{{ form.value.PayerId }}</strong> |
                {{ "form-payment.paymentMethod" | translate }}:
                <strong>{{ form.value.PaymentType }}</strong> |
                {{ "sadDocuments.headerDate.paymentTerm" | translate }}:
                <strong>{{ daysForDue }}</strong> |
                {{ "form-commercial-operation.dateOfDaymentts" | translate }}:
                <strong>{{ form.value.PaymentDate | date : event.dateFormat}}</strong> |
                {{ "worker" | translate }}:
                <strong>{{ employee }}</strong> |
                {{ "constractors.interestTable" | translate }}:
                <strong>{{ tableInterestName }}</strong>
              </p>
              <i
                class="icon absui-icon--arrow-drop-down-box"
                (click)="changeHeader()"
                *ngIf="!showHeader"
              ></i>
            </div>
          </header>

          <div class="justify-content-center-header" [hidden]="!showHeader">
            <div class="box-body">
              <div class="form-group-inline-right">
                <div>
                  <label
                  >{{ "noteNumber" | translate
                    }}</label
                  >
                  <dx-text-box
                    width="276"
                    formControlName="Number"
                    [readOnly]="true"
                  >
                  </dx-text-box>
                </div>

                <div>
                  <label style="top: -3px;"
                  >{{ "baseCurrencies.ofTheDay" | translate
                    }}</label
                  >
                  <wapro-date-box
                    type="date"
                    width="276"
                    [displayFormat]="event.dateFormat"
                    formControlName="Date"
                    (onValueChanged)="onDateChanged()"
                  >
                  </wapro-date-box>
                </div>

                <div>
                  <label>{{
                    "form-financial-document.payer" | translate
                    }}</label>
                  <dx-text-box
                    width="276"
                    [readOnly]="true"
                    [value]="payerName"
                  >
                  </dx-text-box>
                </div>
              </div>
            </div>

            <div class="box-body" style="border-right: none">
              <div class="form-group-inline-right">

                <div>
                  <div>

                    <label>{{
                      "form-payment.paymentMethod" | translate
                      }}</label>
                    <app-forms-payment
                    [onlyActive]="true"
                      [dropDownBoxMode]="true"
                      style="width: 276px"
                      [readOnly]="readOnly"
                      (onChoosed)="onChoosedFormPayment($event)"
                      [selectedId]="form.controls.PaymentType.value"
                    >
                    </app-forms-payment>
                  </div>

                  <div>
                    <label>{{
                      "sadDocuments.headerDate.paymentTerm" | translate
                      }}</label>
                    <dx-number-box
                      [min]="0"
                      [showSpinButtons]="true"
                      width="276"
                      [useLargeSpinButtons]="true"
                      [(value)]="daysForDue"
                      (onValueChanged)="setPaymentDate()"
                      #paymentTerm
                    >
                    </dx-number-box>
                  </div>

                  <div>
                    <label>{{
                      "form-commercial-operation.dateOfDaymentts" | translate
                      }}</label>
                    <wapro-date-box
                      type="date"
                      width="276"
                      [displayFormat]="event.dateFormat"
                      (onValueChanged)="DateOfPaymentChanged($event)"
                      formControlName="PaymentDate"
                    >
                    </wapro-date-box>

                  </div>
                </div>
              </div>
            </div>

            <div class="box-body" style="border-right: none">
              <div class="form-group-inline-right">
                <div>
                  <label class="p-remarks">{{ "worker" | translate }}</label>
                  <app-workers
                    style="width: 276px"
                    [readOnly]="readOnly"
                    [dropDownBoxMode]="true"
                    (onChoosed)="onChoosedWorker($event)"
                    [selectedId]="form.get('EmployeeId').value"
                  >
                  </app-workers>

                </div>

                <div>
                  <label>{{
                    "constractors.interestTable" | translate
                    }}</label>
                  <app-interest
                    [dropDownBoxMode]="true"
                    [readOnly]="readOnly"
                    (onChoosed)="onChoosedInterest($event)"
                    [selectedId]="form.get('InterestTableId').value"
                  >
                  </app-interest>

                </div>
              </div>
            </div>

          </div>

          <div class="settlementHeader" [hidden]="!showHeader">
            <label>{{ "settlementData" | translate }}</label>
            <p class="p-header-document">
              {{ "articles.filter.nr" | translate }}:
              <strong>{{ settlementNumer }}</strong> |
              {{ "settlementAmount" | translate }}:
              <strong>{{ settlementAmount | amountFormatter:null }}</strong> |
              {{"form-commercial-operation.dateOfPaymentt" | translate }}:
              <strong>{{ dayOfPaymentSettlement }}</strong>

            </p>
          </div>
        </div>

        <div>
          <dx-data-grid
            [height]="emptyStateHeight - heightGrid"
            [showBorders]="true"
            [wordWrapEnabled]="true"
            [hoverStateEnabled]="true"
            [dataSource]="dataSource"
            #itemGrid
            [id]="'grid' + unicalGuid"
            shortcut
            (onKeyDown)="onKeyDown()"
            [focusedRowEnabled]="true"
            [(selectedRowKeys)]="selectedInterestNoteRowKeys"
            [allowColumnResizing]="true"
            [columnAutoWidth]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [columnResizingMode]="'widget'"
            style=" width: 100%;
                          margin-bottom: 10px;
                        "
            (onRowDblClick)="onRowDblClick()"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              [mode]="'virtual'"

            ></dxo-scrolling>            <dxo-paging [pageSize]="100"></dxo-paging>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              [width]="200"
              [caption]="'interestNote.documentTitle'| translate"
              dataField="NumberDocument"
              [allowSorting]="false"
            ></dxi-column>

            <dxi-column

              [format]="{ type: event.dateFormat }"
              [caption]="'interestNote.issueDate' | translate"
              dataField="PaymentDate"
              [allowSorting]="false"
              dataType="date"
            ></dxi-column>

            <dxi-column

              [caption]="
                            'interestNote.value' | translate
                          "
              dataField="Amount"
              alignment="left"
              [allowSorting]="false"
              cellTemplate="Amount"
            >
              <dxo-format type="fixedPoint" [precision]="2">
              </dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'Amount'">
              {{ data.data.Amount | amountFormatter:null }}
            </div>

            <dxi-column

              [format]="{ type: event.dateFormat }"
              [caption]="'interestNote.startDate' | translate"
              dataField="FromDate"
              [allowSorting]="false"
              dataType="date"
            ></dxi-column>

            <dxi-column

              [caption]="
                            'interestNote.interestRate'
                              | translate
                          "
              dataField="Rate"
              [allowSorting]="false"
            >
              <dxo-format type="fixedPoint" [precision]="2">
              </dxo-format>
            </dxi-column>

            <dxi-column

              [caption]="
                            'interestNote.duration'
                              | translate
                          "
              dataField="Period"
              [allowSorting]="false"
            ></dxi-column>

            <dxi-column

              [caption]="
                            'interestNote.daysCount'
                              | translate
                          "
              dataField="DaysAmount"
              [allowSorting]="false"
            ></dxi-column>

            <dxi-column

              [caption]="
                            'interestNote.interestAmount'
                              | translate
                          "
              dataField="AmountRate"
              [allowSorting]="false"
            >
              <dxo-format type="fixedPoint" [precision]="2">
              </dxo-format>
            </dxi-column>


          </dx-data-grid>

          <div class="count-container" style="height: 56px">
            <div class="row">
              <div class="col-5 text-left" style="padding-top: 6px">
                <p class="total">
                  {{
                  "form-financial-document.count"
                    | translate
                  }}
                </p>
              </div>
              <div class="col-7 text-right">
                <div class="contener-pay" style="padding-right: 5px">
                  <p class="p-toPay">
                    {{ noteSummary | amountFormatter:null }}
                  </p>
                  <p class="des-toPay">
                    {{
                    "interestNote.totalNote"
                      | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>

    <div *ngIf="event.deviceType == 'mobile'">
      <ng-container *ngTemplateOutlet="mobileBottomTemplate"></ng-container>
    </div>
  </div>
</dx-popup>

<div *ngIf="loadChildComponent"></div>


<ng-template #topButtons>
  <!--  <dx-button-->
  <!--    text="{{ 'interestNote.calculateNote' | translate }}"-->
  <!--    style="margin-right: 6px"-->
  <!--    (onClick)="calculateNote()"-->
  <!--    [id]="'calculateNote' + unicalGuid"-->
  <!--    *ngIf="!readOnly"-->
  <!--    (mouseenter)="tooltipAdd = true"-->
  <!--  >-->
  <!--  </dx-button>-->

  <!--  <div class="seperator" style="display: inline-block; margin-top: 10px" *ngIf="!isMobileBtnHeader"></div>-->
  <app-print-settings
          componentName="interestNote"
          [dropDownMode]="true"
          [isVisible]="true"
          (onComponentLoaded)="getPrintConfig($event)"
          [splitButton]="false"
          style="margin-left: 6px"
          [isChild]="true"
  >
  </app-print-settings>
    <i
    class="icon"
    [ngClass]="{
      'absui-icon--fullscreen2': !localStorageData.windowSize.isFullscreen,
      'absui-icon--fullscreen-off': localStorageData.windowSize.isFullscreen
    }"
    (click)="
      localStorageData.windowSize.isFullscreen =
        !localStorageData.windowSize.isFullscreen
    "
    (mouseenter)="tooltipAdd = true"
    style="position: relative; top: 5px; left: 6px"
    [id]="'fitToWidth' + unicalGuid"
    *ngIf="!isMobileBtnHeader"
  ></i>

  <div class="d-inline-block">
    <dx-button
      text="{{ 'buttons.save' | translate }}"
      type="success"
      style="margin-right: 6px"
      (onClick)="onSaveNote()"
      [id]="'add' + unicalGuid"
      *ngIf="!readOnly"
      (mouseenter)="tooltipAdd = true"
    >
    </dx-button>
    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onCancelImportInvoice()"
      [id]="'cancel' + unicalGuid"
      (mouseenter)="tooltipCancel = true"
    >
    </dx-button>
  </div>

  <dx-tooltip
    [target]="'#calculateNote' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "interestNote.calculateNote" | translate }} (Ctrl + P)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#fitToWidth' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "servicesDevices.fitToWidth" | translate }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#add' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.save" | translate }} (F10)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>
</ng-template>

<!--<app-confirm-dialog-->
<!--  [isVisible]="isCancelImportInvoice"-->
<!--  (onRemoving)="onSaveImportInvoice()"-->
<!--  kindDialog="question"-->
<!--  btnConfig="yesnocancel"-->
<!--  (onCanceling)="closeConfirmDialogImportInvoice()"-->
<!--  confirmText="intrastate-documents.new-intrastate.confirm-dialog"-->
<!--  (onClosing)="cancel()"-->
<!--&gt;-->
<!--</app-confirm-dialog>-->

<!-- mobile template -->

<ng-template #mobileHeaderTemplate></ng-template>

<ng-template #mobileBottomTemplate></ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="550"
  [minHeight]="200"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        data-cy="save-clasification-customer-btn"
        [id]="'add' + unicalGuid"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          Esc {{ "buttons.close" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          F10 {{ "buttons.save" | translate }}
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <form [formGroup]="form">
        <div class="form-columns">
          <div class="form-column form-column__full-width">
            <div class="form-column__item">
              <label style="min-width: 30px">{{
                "selectedArticlesList.titleContractors" | translate
              }}</label>
              <div class="form-control" style="width: 100%">
                <app-selected-list
                  [dropDownBoxMode]="true"
                  type="customers"
                  (onChoosed)="onSelectedListChoosed($event)"
                  [selectedId]="form.value.TemplateDictionaryId"
                  [className]="
                    !form.controls.TemplateDictionaryId.valid && submitted()
                      ? true
                      : false
                  "
                ></app-selected-list>
              </div>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

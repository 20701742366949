import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { ICompanyConfigurationParameter } from 'src/app/administrator/company-configuration/ICompanyConfiguration';
import { AppServices } from 'src/app/app-services.service';
import { BaseGridDataResponse } from 'src/app/event.model';
import { EventService } from 'src/app/event.service';

@Component({
    selector: 'app-change-cash-box',
    templateUrl: './change-cash-box.component.html',
    styleUrls: ['./change-cash-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChangeCashBoxComponent implements OnInit {
  selectedSymbol: WritableSignal<string> = signal(null);
  readonly FieldCode = 'DomKasa';
  constructor(
    private appService: AppServices,
    private event: EventService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getCurrentCashBox();
  }

  getCurrentCashBox() {
    this.appService
      .getAuth(`configurations/users/currentUser/config`, {
        FieldCode: this.FieldCode,
        ShowWeb: true,
      })
      .pipe(
        map((response: BaseGridDataResponse<ICompanyConfigurationParameter>) =>
          response?.data?.length ? response?.data[0] : { Value: null }
        ),
        map((obj) => obj.Value)
      )
      .subscribe({
        next: (data) => {
          if (data) {
            this.selectedSymbol.set(`${data}`);
          }
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  onChoosed(e) {
    if (e) {
      this.selectedSymbol.set(`${e.CashId}`);

      this.event.footerInfo.AppCashId = e.CashId;
      this.event.footerInfo.AppCashName = e.Name;
      this.event.footerInfo.AppCashSymbol = e.Symbol;
      this.saveCashBox();
    }
  }

  saveCashBox() {
    const obj = {
      FieldCode: this.FieldCode,
      Value: `${this.selectedSymbol()}`,
    };
    this.appService
      .postAuth(`configurations/users/currentUser/config`, [obj])
      .subscribe({
        next: () => {
          this.event.showNotification(
            'success',
            this.translate.instant('updated-record')
          );

          this.saveToLocalStorage(obj);
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  saveToLocalStorage(parameter: { FieldCode: string; Value: string }) {
    const paramsStr = localStorage.getItem('userConfiguration');
    if (!paramsStr) {
      return;
    }

    const localParameters: ICompanyConfigurationParameter[] =
      this.event.decryptString(paramsStr);
    const foundParameterIndex: number = localParameters.findIndex(
      (el) => el.Field.FieldCode === parameter.FieldCode
    );
    if (foundParameterIndex !== -1) {
      localParameters[foundParameterIndex].Value = parameter.Value;
    }

    this.event.userParameters = localParameters;

    localStorage.setItem(
      'userConfiguration',
      this.event.encryptString(localParameters)
    );
  }
}

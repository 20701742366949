import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { IBasicSelectBox } from 'src/app/core/Icore';
import { EventService } from 'src/app/event.service';

@Component({
    selector: 'app-sum-comercial-operation',
    templateUrl: './sum-comercial-operation.component.html',
    styleUrls: ['./sum-comercial-operation.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'forFiltered',
        'filters',
    ],
    standalone: false
})
export class SumComercialOperationComponent {
  @Output() onClosing = new EventEmitter();

  isVisible: boolean;
  title;
  widthWindow = '90%';
  heightWindow = '90%';
  // filters;
  shortcuts: ShortcutInput[] = [];
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  dataSourceSum: any[] = [];

  isOnlySellPrice: boolean = false;
  isOnlySellPriceButtons: IBasicSelectBox[] = [
    { value: true, label: 'skrócone (tylko w cenach sprzedaży)' },
    { value: false, label: 'pełne (w cenach zakupu i sprzedaży)' },
  ];

  forFiltered: boolean = false;
  isOnlyFromFiltringPriceButtons: IBasicSelectBox[] = [
    { value: true, label: 'tylko filtrowane' },
    { value: false, label: 'tylko zaznaczone' },
  ];

  visibleShortcut;
  selectedId;
  readOnly;
  filters: '';

  // dataSourceSum = [];
  // dataSourceCurrency = [];
  // dataSourceSumaAccount = [];

  fullMode = false;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.getTranslations();
  }

  getTranslations() {
    this.translate
      .get('sumCommercialOperations.summaryType.short')
      .subscribe(
        (text: string) => (this.isOnlySellPriceButtons[0].label = text)
      );
    this.translate
      .get('sumCommercialOperations.summaryType.full')
      .subscribe(
        (text: string) => (this.isOnlySellPriceButtons[1].label = text)
      );
    this.translate
      .get('sumCommercialOperations.displayedDocuments.false')
      .subscribe(
        (text: string) => (this.isOnlyFromFiltringPriceButtons[0].label = text)
      );
    this.translate
      .get('sumCommercialOperations.displayedDocuments.true')
      .subscribe(
        (text: string) => (this.isOnlyFromFiltringPriceButtons[1].label = text)
      );
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  // transformFiltersToString(filters: any = {}) {
  //   let params: string = '';
  //   for (let param in filters) {
  //     params += `${param}=${filters[param]}&`;
  //   }

  //   return params.substring(0, params.length - 1);
  // }

  onSummaryTypeValueChanged = () => {
    this.getData();
  };

  onDisplayedDocumentsValueChanged = () => {
    this.getData();
  };

  getParamsFromFilters = () => {
    const params: Array<string> = [
      'InvoiceDocumentId',
      'InvoiceDocumentNumber',
      'InvoiceDocumentNumber',
      'DateOfInvoiceDocumentStart',
      'DateOfInvoiceDocumentStop',
      'CustomerName',
      'PayerName',
      'TotalGrossAmount',
      'DistinguishCode',
      'EAN',
      'DateOfReceived',
      'EmployeeName',
      'IsSuspended',
      'InvoiceTypeTreeId',
      'LabelId',
      'IsSelected',
      'UseForSelection',
      'Order',
      'OrderBy',
      'CustomerId',
    ];
    let paramsString: string = '';
    let isFirst: boolean = true;

    params.forEach((param: string) => {
      if (this.filters[param]) {
        paramsString += `${isFirst ? '' : '&'}${param}=${this.filters[param]}`;
        isFirst = false;
      }
    });

    return paramsString;
  };

  getData = () => {
    let filterParams = this.getParamsFromFilters();

    this.appService
      .getAuth(
        `invoices/documents/totalSums?IsOnlyFromFiltring=${this.forFiltered}&IsOnlySellPrice=${this.isOnlySellPrice}&${filterParams}`
      )
      .subscribe((res) => {
        this.dataSourceSum = [];

        this.dataSourceSum.push({
          name: this.translate.instant('sumCommercialOperations.sellTotal'),
          outcomeNet: res['SellTotalNetAmount'],
          outcomeGross: res['SellTotalGrossAmount'],
          incomeNet: '',
          incomeGross: '',
        });

        this.dataSourceSum.push({
          name: this.translate.instant('sumCommercialOperations.buyTotal'),
          outcomeNet: res['SellTotalBuyNetAmount'],
          outcomeGross: res['SellTotalBuyGrossAmount'],
          incomeNet: res['BuyTotalNetAmount'],
          incomeGross: res['BuyTotalGrossAmount'],
        });

        this.dataSourceSum.push({
          name: this.translate.instant('sumCommercialOperations.margin'),
          outcomeNet: res['TotalMarginAmount'],
          outcomeGross: '',
          incomeNet: '',
          incomeGross: '',
        });

        this.dataSourceSum.push({
          name: this.translate.instant('sumCommercialOperations.markup'),
          outcomeNet: res['TotalMarkupAmount'],
          outcomeGross: '',
          incomeNet: '',
          incomeGross: '',
        });

        this.dataSourceSum.push({
          name: this.translate.instant('sumCommercialOperations.profit'),
          outcomeNet: res['TotalProfitAmount'],
          outcomeGross: '',
          incomeNet: '',
          incomeGross: '',
        });
        this.cd.detectChanges();
      });
  };

  ngOnChanges() {
    if (this.selectedId) {
      if (this.selectedId.length != 0 && this.isVisible) {
        this.getData();
      }
    }
    // if (changes?.forFiltered?.currentValue) {
    //   this.cd.detectChanges();
    // }
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'esc',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.onClosing.emit(false);
      },
      preventDefault: true,
    });
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };
}

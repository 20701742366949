import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
  signal,
  ChangeDetectionStrategy,
  computed,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription, of, take, switchMap } from 'rxjs';
import { GlobalDate } from '../../core/date-range/GlobalDate';
import { PathToNestMap } from '../../nest-resolver.service';
import { NestService } from '../../nest-service.service';
import { NestOperation } from './../../nest/nest.model';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { environment } from '../../../environments/environment';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { AdditionalOperationComponent } from 'src/app/core/additional-operation/additional-operation.component';
import { CustomChipsButtonComponent } from 'src/app/core/custom-chips-button/custom-chips-button.component';
import { CustomDropdownBoxComponent } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import { ICustomSearchItem } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import { DynamicFilterService, ViewUserDefinition } from 'src/app/core/dynamic-filter.service';
import { UserDefinition } from 'src/app/core/filter-panel/filterpanel';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { TableService } from 'src/app/core/table-user-extension/table-service.service';
import {
  DoubleClickResult,
  RefreshCardResult,
  SingleRecordMode,
} from 'src/app/event.model';
import { HelpService } from 'src/app/help-service.service';
import { NestFinishedEvent } from 'src/app/nest/nest.model';
import { ExportFakirService } from 'src/app/other/export-fakir.service';
import { PrintService } from 'src/app/print.service';
import { Socket } from 'src/app/socket.model';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';
import { FinancialDocument } from './financial-operation.model';
import { LoadOptions } from 'devextreme/data';
import { FINANCE_TYPE_DOCUMENT } from 'src/app/consts';
import { RecordCardMode } from 'src/app/libraries/record-card/record-card.interface';
import { OnInsertedData } from '../new-financial-operation/new-financial-operation.component';
import { PrintSettingsService } from 'src/app/core/print-settings/print-settings.service';

@Component({
  selector: 'app-financial-operation',
  templateUrl: './financial-operation.component.html',
  styleUrls: ['./financial-operation.component.scss'],
  inputs: ['dropDownBoxMode'],
  changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FinancialOperationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('additionalOperation')
  additionalOperation: AdditionalOperationComponent;
  exportFakirService = inject(ExportFakirService);

  helpService = inject(HelpService);
  @ViewChild('reports') reports: ReportsComponent;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  //mobile
  modeSelectionMobile: any = 'none';
  isContextMenuMobile: boolean = false;
  isDetails: boolean = false;
  isShowMoreDetails: boolean = false;
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @ViewChild('payerChips') payerChips: CustomChipsButtonComponent;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  @ViewChild('treeViewAll') treeViewAll;
  @ViewChild('gridClass') gridClass;
  currentDate: GlobalDate = new GlobalDate();
  currentDateSub: Subscription;
  dataSource;
  doubleClickResult: DoubleClickResult = 'Edycja';
  dropDownBoxMode;
  dynamicFilterUserDefinitions: any[] = [];
  filterMobileVisible: boolean = false;
  mode: SingleRecordMode = 'show';
  newRowPopupVisible: boolean = false;
  paramsObj = {};
  readonly DynamicFilterDictionaryCode = 'FINANCE_DOCUMENT';
  readonly USER_EXTENSIONS_GROUP_CODE = 'Dokument_finansowy';
  readonly: boolean = false;
  shortcuts: ShortcutInput[] = [];
  valueCriteria2 = '';
  viewUserDefinitionSub: Subscription = null;
  userExtensionColumns = {
    Pole1: {
      IsActive: false,
      Name: 'pole1',
    },
    Pole2: {
      IsActive: false,
      Name: 'pole2',
    },
    Pole3: {
      IsActive: false,
      Name: 'pole3',
    },
    Pole4: {
      IsActive: false,
      Name: 'pole4',
    },
    Pole5: {
      IsActive: false,
      Name: 'pole5',
    },
    Pole6: {
      IsActive: false,
      Name: 'pole6',
    },
    Pole7: {
      IsActive: false,
      Name: 'pole7',
    },
    Pole8: {
      IsActive: false,
      Name: 'pole8',
    },
    Pole9: {
      IsActive: false,
      Name: 'pole9',
    },
    Pole10: {
      IsActive: false,
      Name: 'pole10',
    },
  };
  selectedAmount = signal(0);
  localStorageData: any = {
    mainPanelWidth: 'calc(100% - 300px)',
    bottomPanel: {
      isVisible: true,
      height: 210,
      selectedIndex: 0,
    },
    filter: {
      orderBy: 'numberIntuition',
      order: 'ASC',
      value: '',
    },
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 300,
    },
    valueCriteria2: '',
    classificationIdFilter: null,
    filtrLeftPanelClasyfication: '',
    groupIdFilter: null,
    filterLeftPanelGroup: '',
    order: 'ASC',
    columns: [
      { caption: 'R', visibleIndex: 0, visible: true, width: '40' },
      { caption: 'Numer', visibleIndex: 1, visible: true, width: '100' },
      { caption: 'Typ', visibleIndex: 2, visible: true, width: '40' },
      { caption: 'Data', visibleIndex: 3, visible: true, width: '110' },
      {
        caption: 'Nazwa płatnika',
        visibleIndex: 4,
        visible: true,
        width: '300',
      },
      { caption: 'Wpływ', visibleIndex: 5, visible: true, width: '100' },
      { caption: 'Wypływ', visibleIndex: 6, visible: true, width: '100' },
      { caption: 'Waluta', visibleIndex: 7, visible: true, width: '100' },
      { caption: 'Wpływ wal.', visibleIndex: 8, visible: true, width: '100' },
      { caption: 'Wypływ wal.', visibleIndex: 9, visible: true, width: '100' },
      {
        caption: 'Numer wyciągu',
        visibleIndex: 10,
        visible: true,
        width: '200',
      },
      { caption: 'Tytułem', visibleIndex: 11, visible: true, width: '200' },
      { caption: 'Kod', visibleIndex: 12, visible: true, width: '40' },
      { caption: 'PP', visibleIndex: 13, visible: true, width: '100' },
      { caption: 'PP NIP', visibleIndex: 13, visible: true, width: '100' },
      {
        caption: 'PP Numer dokumentu',
        visibleIndex: 13,
        visible: true,
        width: '100',
      },
      {
        caption: 'PP Kwota VAT',
        visibleIndex: 13,
        visible: true,
        width: '100',
      },
      { caption: 'Pracownik', visibleIndex: 13, visible: true, width: '300' },
      { caption: 'pole1', visibleIndex: 13, visible: false, width: '100' },
      { caption: 'pole2', visibleIndex: 13, visible: false, width: '100' },
      { caption: 'pole3', visibleIndex: 13, visible: false, width: '100' },
      { caption: 'pole4', visibleIndex: 13, visible: false, width: '100' },
      { caption: 'pole5', visibleIndex: 13, visible: false, width: '100' },
      { caption: 'pole6', visibleIndex: 13, visible: false, width: '100' },
      { caption: 'pole7', visibleIndex: 13, visible: false, width: '100' },
      { caption: 'pole8', visibleIndex: 13, visible: false, width: '100' },
      { caption: 'pole9', visibleIndex: 13, visible: false, width: '100' },
      { caption: 'pole10', visibleIndex: 13, visible: false, width: '100' },
    ],
    advancedFilter: null,
  };

  unicalGuid;
  selectedRow = [];
  heightGrid;
  width = 300;

  openCustomDropDown: boolean = false;
  // filterValue = '';
  filterCriteria: ICustomSearchItem[] = [
    // {
    //   value: 'number',
    //   label: '',
    //   translationSrc: 'form-financial-document.number',
    // },
    {
      value: 'numberIntuition',
      label: '',
      translationSrc: 'form-financial-document.numberIntuition',
    },
    {
      value: 'financeDocumentDate',
      label: '',
      type: 'data',
      translationSrc: 'form-financial-document.date',
    },
    {
      value: 'amount',
      label: '',
      type: 'number',
      translationSrc: 'form-financial-document.grid.amount',
    },
    // {
    //   value: 'payerName',
    //   label: '',
    //   translationSrc: 'form-financial-document.payer',
    // },
    {
      value: 'payerNameIntuition',
      label: '',
      translationSrc: 'form-financial-document.payerIntuition',
    },
    {
      value: 'statementNumber',
      label: '',
      translationSrc: 'form-financial-document.statement-number',
    },
    {
      value: 'titleIntuition',
      label: '',
      translationSrc: 'form-financial-document.the-titel-intuition',
    },
  ];

  mobileOperationsVisible: boolean = false;
  contextMenuOperationsMobile = [
    { name: 'Zaznacz wiele', icon: 'icon checkAll', itemIndex: 99 },
  ];

  typeDocumentNewDoc = [];

  perABD = {
    addBtnK: false,
    editBtnK: false,
    deleteBtnK: false,
    showBtnK: false,
    addBtnB: false,
    editBtnB: false,
    deleteBtnB: false,
    showBtnB: false,
    addBtnI: false,
    editBtnI: false,
    deleteBtnI: false,
    showBtnI: false,
  };
  rkwbPermission = false;
  editRow: boolean = false;
  showRow: boolean = false;
  isDeleteRow: boolean = false;

  permissionToChooseColums: Boolean = false;
  readOnly = false;
  focusedSelected: FinancialDocument[] = [];

  isGridBoxOpened: boolean = false;
  columnsChooserVisible: boolean = false;
  // orderby: string = 'number';
  // order: string = 'ASC'; // asc/desc
  pageSize: number = 100;
  refreshCardResult: RefreshCardResult = 'Tak';
  focusOnLastRow: RefreshCardResult = 'Nie';
  FinanceDocumentId = null;
  // NEST
  onInitErr: boolean = false;
  activatedRouteSub: Subscription;
  componentNests: Socket;
  nest: Socket;
  nestsSub: Subscription;
  isNestRunning = false;
  nestOperation: NestOperation;
  closeNest: Socket;
  globalNestSub: Subscription;
  componentVisible = false;
  pinnedViewsSub: Subscription = null;
  isFinancialRaports: boolean = false;
  isFilterPanelComponent = false;
  visibleAdvanceFiltersWithoutPanel = false;
  deleteFilter = false;

  userId: number = null;
  filterValue: any;
  selectedDocumentsData: FinancialDocument[];
  perEdocuments: boolean;
  eDocumentsVisible: boolean;

  additionalOperationList = signal([]);
  contextMenuSignal = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: this.event.readOnly,
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        disabled: this.event.readOnly,
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        disabled: this.event.readOnly,
      },
      {
        text: this.translate.instant('repairFunctions.selectAll'),
        icon: 'icon absui-icon--checkbox-all',
        itemIndex: 3,
      },
      {
        text: this.translate.instant('articles.setUnselectedAll'),
        icon: 'icon absui-icon--deselct-all',
        itemIndex: 5,
        disabled: this.selectedAmount() === 0,
      },
      {
        text: this.translate.instant('showSelected'),
        icon: '',
        itemIndex: 51,
        items: [],
        disabled: this.selectedAmount() === 0,
      },
      {
        text: this.translate.instant('labels.labels'),
        itemIndex: 6,
        items: [
          {
            text: this.translate.instant('labels.assign'),
            itemIndex: 7,
            disabled: !this.DEtykietyDokFin,
          },
          {
            text: this.translate.instant('labels.delete'),
            itemIndex: 8,
            disabled: !this.UEtykietyDokFin,
          },
        ],
      },
      {
        text: this.translate.instant(
          'form-commercial-operation.additionalOperations'
        ),
        items: this.additionalOperationList(),
        visible: this.additionalOperationList().length > 0
      },
      {
        text: this.translate.instant('menu.eDocuments'),
        itemIndex: 30,
        disabled: !this.perEdocuments,
        visible: this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'
      },
    ];
  });
  contextMenuMobileSignal = computed(() => {
    return [
      {
        text: this.mobileSelectionText(),
        icon: this.mobileSelectionIcon(),
        itemIndex: 99,
      },
      {
        text: this.translate.instant('tasks.details'),
        icon: 'icon absui-icon--file-edit',
        itemIndex: 98,
      },
      {
        text: this.translate.instant('print'),
        icon: 'icon absui-icon--print',
        itemIndex: 96,
        disabled: this.isPrintDisabled(),
        visible: this.event.deviceType === 'mobile',
      },
      ...this.contextMenuSignal(),
    ];
  });
  mobileSelectionIcon = signal('icon checkAll');
  mobileSelectionText = signal('');
  isPrintDisabled = signal<boolean>(false);
  DEtykietyDokFin: boolean = false;
  UEtykietyDokFin: boolean = false;
  isRecordCardVisible: boolean;
  recordCardObjectId: number;
  recordCardMode: RecordCardMode;
  isGroupzmoper = signal<boolean>(false);
  heightBottomGrid: any;
  bottomPanelTabs = ['Notatki'];
  isMobileReportsPopupVisible: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dynamicFilterService: DynamicFilterService,
    private router: Router,
    private tableService: TableService,
    public appServices: AppServices,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public nestService: NestService,
    public print: PrintService,
    public translate: TranslateService,
    private printSettings: PrintSettingsService
  ) {
    this.DEtykietyDokFin = this.event.checkPermissions('DEtykietyDokFin');
    this.UEtykietyDokFin = this.event.checkPermissions('UEtykietyDokFin');
    this.print.onRefreshRequested.pipe(takeUntilDestroyed()).subscribe(() => {
      this.dataSource.reload();
    });
    this.event.isInventory.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.readOnly = data;
      this.getData();
    });
    this.userId = this.event.returnUserId();
    this.translate
      .get('selectMultiple')
      .subscribe((text) => (this.contextMenuOperationsMobile[0].name = text));

    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((res) => {
      this.readOnly = res;
    });
    //super(nestService)

    this.event.translateSearchboxLabels(this.filterCriteria);

    this.perEdocuments = !this.event.isDisabledEDocuments('Financial');

    this.heightGrid = window.innerHeight - 170;
    this.gridClass?.instance?.updateDimensions();
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests || {};

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });

    this.viewUserDefinitionSub = this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          this.dynamicFilterUserDefinitions = data?.userDefinitions || [];
        },
      });
  }

  onDateRangeChoosed() {
    if (this.refreshCardResult === 'Tak') {
      this.onlySelected = false;
      this.getData();
    }
  }

  ngOnDestroy(): void {
    this.exportFakirService.isSelectMode.set(false);
    this.additionalOperationShortcutSub?.unsubscribe();
    this.localStorageData.columns = this.event.setWidthColumnGrid(
      this.gridClass
    );
    this.viewUserDefinitionSub?.unsubscribe();
    this.tableService.removeTableContext('Dokument_finansowy');
    localStorage.setItem(
      'finance-operation',
      JSON.stringify(this.localStorageData)
    );
    if (this.nestsSub) {
      this.nestsSub.unsubscribe();
    }

    this.pinnedViewsSub?.unsubscribe();
    this.currentDateSub?.unsubscribe();
    this.activatedRouteSub.unsubscribe();
    this.globalNestSub.unsubscribe();
    this.nestsSub?.unsubscribe();
  }

  ngOnInit(): void {
    if (localStorage.getItem('finance-operation')) {
      this.localStorageData = JSON.parse(localStorage.getItem('finance-operation'));
      this.filterValue = this.localStorageData.filter.value;
      this.updateColumns();
    }

    if (
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO' &&
      this.perEdocuments
    ) {
      this.bottomPanelTabs.push(this.translate.instant('menu.eDocuments'));
    }

    this.printSettings.currentComponentName.set('financialOperation');
    this.readOnly = this.event.readOnly;
    this.getUserParameters();
    this.getTypeDocument();
    this.deselectAllRecords();

    //sprawdzam uprawnienia do dodawania, edycji i usuwania
    this.perABD.addBtnB = this.event.checkPermissions('DDokFinBan');
    this.perABD.editBtnB = this.event.checkPermissions('EDokFinBan');
    this.perABD.deleteBtnB = this.event.checkPermissions('UDokFinBan');
    this.perABD.showBtnB = this.event.checkPermissions('ODokFinBan');

    this.perABD.addBtnK = this.event.checkPermissions('DDokFinKas');
    this.perABD.editBtnK = this.event.checkPermissions('EDokFinKas');
    this.perABD.deleteBtnK = this.event.checkPermissions('UDokFinKas');
    this.perABD.showBtnK = this.event.checkPermissions('ODokFinKas');

    this.perABD.addBtnI = this.event.checkPermissions('DDokFinPoz');
    this.perABD.editBtnI = this.event.checkPermissions('EDokFinPoz');
    this.perABD.deleteBtnI = this.event.checkPermissions('UDokFinPoz');
    this.perABD.showBtnI = this.event.checkPermissions('ODokFinPoz');
    this.rkwbPermission = this.event.checkPermissions('RKWBDokFin');
    //sprawdzam uprawnienia do konfiguracji kolumn grida
    this.permissionToChooseColums =
      this.event.checkPermissions('KonfKolDokFin');

    this.activatedRoute.queryParams.subscribe((params) => {
      this.FinanceDocumentId = params.id;
      this.getData();
    });

    setTimeout(() => {
      if (!this.dropDownBoxMode) {
        this.closeNest = this.nestService.findNest(
          this.componentNests?.Sockets,
          'XGFLA'
        );
        this.findSocketAndRun('XGFLO1');
      } else {
        this.componentVisible = true;
        this.cd.detectChanges();
      }
    });

    this.pinnedViewsSub = this.dynamicFilterService
      .getPinnedViewsFilters(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          if (!data) {
            return;
          }
          if (this.localStorageData?.advancedFilter) {
            this.localStorageData.advancedFilter.PinnedFilters =
              data.pinnedFilters;
          } else {
            this.localStorageData.advancedFilter = {
              PinnedFilters: data.pinnedFilters,
            };
          }
        },
      });

    this.additionalOperationShortcutSub =
      this.event.additinalOperationShortcut$.subscribe((code: string) => {
        if (this.dropDownBoxMode) {
          return;
        }
        if (
          this.newRowPopupVisible ||
          this.isExternalTableVisible ||
          this.isShortcutTableOpened
        ) {
          return;
        }

        this.contextMenuClick({
          itemData: {
            itemIndex: code,
          },
        });
      });
  }

  widthLeftPanel;
  additionalOperationShortcutSub: Subscription;
  typeDocument;

  onItemAllClick = (e) => {
    switch (e.itemData.TypeId) {
      case '-1':
        this.valueCriteria2 = '';
        break;
      case 'k':
        this.translate
          .get('form-financial-document.cashRegister')
          .subscribe((text) => (this.valueCriteria2 = text));
        break;
      case 'b':
        this.translate
          .get('form-financial-document.banking')
          .subscribe((text) => (this.valueCriteria2 = text));
        break;
      case 'c':
        this.translate
          .get('form-financial-document.compensation')
          .subscribe((text) => (this.valueCriteria2 = text));
        break;
      case 'i':
        this.translate
          .get('categories.other')
          .subscribe((text) => (this.valueCriteria2 = text));
        break;
      default:
        this.valueCriteria2 = e.itemData.Signature;
        break;
    }

    this.localStorageData.valueCriteria2 = e.itemData.Signature;

    if (e.itemData.TypeId == 'k') {
      this.localStorageData.valueCriteria2 = 'KP';
    }
    if (e.itemData.TypeId == 'b') {
      this.localStorageData.valueCriteria2 = 'BP';
    }
    if (e.itemData.TypeId == 'i') {
      this.localStorageData.valueCriteria2 = 'IW';
    }

    localStorage.setItem(
      'finance-operation',
      JSON.stringify(this.localStorageData)
    );
    this.getData();
  };

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  }

  // resizing(event) {
  //   let width;
  //   width = this.widthLeftPanel - event.edges.left;
  //   if (width < 280) width = 280;
  //   if (width > 620) width = 620;
  //   this.leftPanel.nativeElement.style.width = `${width}px`;
  //   this.width = width;

  //   let rightWidth = width;
  //   this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
  //   this.localStorageData.sidePanel.width = `${width}px`;
  //   this.localStorageData.rightPanelWidth = `calc(100% - ${rightWidth}px)`;
  //   localStorage.setItem(
  //     'finance-operation',
  //     JSON.stringify(this.localStorageData)
  //   );
  // }

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 280) {
      width = 280;
    }
    if (width > 620) {
      width = 620;
    }
    // this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    this.localStorageData.sidePanel.width = `${width}px`;
    this.localStorageData.sidePanel.isVisible = true;
    this.localStorageData.mainPanelWidth = `calc(100% - ${rightWidth}px)`;
    localStorage.setItem(
      'finance-operation',
      JSON.stringify(this.localStorageData)
    );
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  hideRightPanel() {
    // this.leftPanel.nativeElement.style.width = '10px';
    this.width = 21;
    this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
    this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
    this.localStorageData.sidePanel.width = `21px`;
    this.localStorageData.sidePanel.isVisible = false;
  }

  showRightPanel() {
    // this.leftPanel.nativeElement.style.width = '280px';
    this.width = 280;
    this.rightPanel.nativeElement.style.width = `calc(100% - 280px)`;
    this.localStorageData.mainPanelWidth = `calc(100% - 280px)`;
    this.localStorageData.sidePanel.width = `280px`;
    this.localStorageData.sidePanel.isVisible = true;
  }

  resizeWindowBtn() {
    if (this.width > 21) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
    }
  }

  // comercial-operation
  getCustomConfiguration = () => {
    if (localStorage.getItem('finance-operation')) {
      this.localStorageData = Object.assign(
        this.localStorageData,
        JSON.parse(localStorage.getItem('finance-operation'))
      );
      if (this.localStorageData.mainPanelWidth != null) {
        this.rightPanel.nativeElement.style.width =
          this.localStorageData.mainPanelWidth;
      }

      if(!this.localStorageData?.advancedFilter || !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId){
        this.getDefaultFilter();
      }
      else if (
        this.localStorageData?.advancedFilter
          ?.FilterUsersDefinitionId ||
        this.localStorageData?.advancedFilter?.IsPinned
      ) {
        this.getSavedFilterDefinitions();
      }
      if (this.localStorageData?.advancedFilter?.PinnedFilters) {
        this.dynamicFilterService.addPinnedViewsFilters(
          this.DynamicFilterDictionaryCode,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      }

      this.filterValue = this.localStorageData.filter.value;
    }

    this.cd.detectChanges();
    if (this.localStorageData.valueCriteria2) {
      setTimeout(() => {
        try {
          this.typeDocument.forEach((element) => {
            element.items.forEach((elementSub) => {
              if (
                elementSub.Signature == this.localStorageData.valueCriteria2
              ) {
                this.treeViewAll?.instance.selectItem(elementSub.TypeId);
              }
              elementSub.items.forEach((field) => {
                if (field.Signature == this.localStorageData.valueCriteria2) {
                  this.treeViewAll?.instance.selectItem(field.TypeId);
                }
              });
            });
          });
        } catch {}
      }, 0);
    }
    this.valueCriteria2 = this.localStorageData.valueCriteria2
      ? this.localStorageData.valueCriteria2
      : '';
    this.updateColumns();
  };

  contextMenuPreparing(e: any): void {
    if (e.target == 'header') e.items = [];
  }

  updateColumns() {
    if (this.localStorageData.columns) {
      setTimeout(() => {
        const instance = this.gridClass.instance;

        this.localStorageData.columns.forEach((col, i) => {
          instance.columnOption(col.caption, 'visible', col.visible);
          instance.columnOption(col.caption, 'visibleIndex', i);
          instance.columnOption(col.caption, 'width', col.width);
          instance.columnOption(col.caption, 'fixed', col.fixed);
        });
        this.cd.detectChanges();
      }, 250);
    }
  }

  ngAfterViewInit(): void {    
    setTimeout(() => {
      this.event
        .getUserExtension('Dokument_finansowy', this.userExtensionColumns)
        .then((res) => {
          try {
            this.localStorageData.columns = this.event.getAdditionalColumnsCaptions(
              res,
              this.localStorageData.columns
            );
          } catch {}
        });
      this.getCustomConfiguration();
      this.getData();
    }, 0);

    this.additionalTables =
      this.event.getTablesByGroupCode('Dokument_finansowy');

    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.getData();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            return;
          }
          this.helpService.openHelp('sprzedaz-i-magazyn/dokumenty/finansowe/');
        },
        preventDefault: true,
      },
      {
        key: 'enter',
        allowIn: [AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRow.length &&
            this.exportFakirService.isSelectMode()
          ) {
            this.selectChoosed();
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRow.length &&
            this.exportFakirService.isSelectMode()
          ) {
            this.cancelChoosed();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + o',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.shiftKey) {
            this.additionalOperation.openList();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + s',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.sumOpen();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'f7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            this.setCustomerFromFocusedRow();
          } else {
            this.openPayer();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.localStorageData?.sidePanel?.selectedIndex === 0) {
            this.localStorageData.sidePanel.selectedIndex = 1;
            this.cd.detectChanges();
          }
          if (
            this.localStorageData.sidePanel.selectedIndex === 1 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
              this.cd.detectChanges();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.openAdvanceFiltersWithoutPanel();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + r',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.rkwbPermission) {
            return;
          }

          this.isFinancialRaports = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        preventDefault: true,
        command: async () => {
          this.openNewRow();
        },
      },
      {
        key: 'f2',
        preventDefault: true,
        command: async (e) => {
          if (
            !this.readOnly &&
            !e.event.shiftKey &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            if (this.focusedSelected.length > 0) {
              this.showEditContr();
            }
          }
        },
      },
      {
        key: 'del',
        preventDefault: true,
        command: async () => {
          if (!this.editRow && !this.showRow && !this.newRowPopupVisible) {
            this.isDelete();
          }
        },
      },
      {
        key: 'shift + f2',
        preventDefault: true,
        command: async () => {
          if (
            !this.readOnly &&
            this.focusedSelected.length &&
            (this.perABD.showBtnB ||
              this.perABD.showBtnK ||
              this.perABD.showBtnI)
          ) {
            this.showRow = true;
            this.cd.detectChanges();
          }
        },
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            if (this.onlySelected) {
              this.revertSelectOnly();
            } else {
              this.onOnlySelected();
            }
          } else {
            this.openCustomDropDown = !this.openCustomDropDown;
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.clearValues();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + y',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.additionalTables.length) {
            return;
          }

          if (this.focusedSelected.length == 1) {
            this.isExternalTableVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      }
    );

    for (let table of this.additionalTables) {
      if (table.Shortcut) {
        this.shortcuts.push({
          key: table.Shortcut.toLowerCase(),
          allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
          command: () => {
            if (this.focusedSelected.length) {
              this.shortcutTable = [table];
              this.isShortcutTableOpened = true;
              this.cd.detectChanges();
            }
          },
          preventDefault: true,
        });
      }
    }

    if (this.router.url == '/trade-operations/finances-documents/add') {
      this.openNewRow();
    }

    let idDocument;
    let mode;
    this.activatedRoute.params.subscribe(
      (params) => (idDocument = parseInt(params['FinanceDocumentId']))
    );
    this.activatedRoute.params.subscribe((params) => (mode = params['mode']));
    if (idDocument) {
      this.appServices
        .getAuth(`finances/documents?ObjectId=${idDocument}`)
        .subscribe((res) => {
          this.focusedSelected = [res.data[0]];
          if (mode == 'edit') {
            this.showEditContr();
          }
          if (mode == 'show') {
            this.showRow = true;
            this.cd.detectChanges();
          }
        });
    }
  }

  closeNewComercialOperation = () => {
    this.printSettings.currentComponentName.set('financialOperation');
    this.isReport = false;
    this.newRowPopupVisible = false;
    this.editRow = false;
    this.showRow = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 500);
  };

  async openNewRow() {
    if (this.perABD.addBtnB || this.perABD.addBtnI || this.perABD.addBtnK) {
      this.findSocketAndRun('XGFLYD1');
    }
  }

  onRowClick = (e) => {
    try {
      this.focusedSelected = [e.data];
    } catch {}
  };

  firstLoad = true;
  indexToFocus: number;
  setFocusOnLoad = false;
  getData = (fromSearch: boolean = false) => {
    return new Promise<void>((resolve) => {
      this.dataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'FinanceDocumentId',
          onBeforeSend: this.event.onBeforeSendDataSource,
          loadUrl: `${environment.domain}finances/documents`,
          loadParams: this.getLoadParams(),
          onAjaxError: this.event.onAjaxDataSourceError,
          onLoading(loadOptions: LoadOptions) {
            loadOptions.requireTotalCount = true;
          },
          onLoaded: (data) => {
            this.focusedRowIndex =
              data.length > this.event.initFocusedRowIndex('financesDocuments')
                ? this.event.initFocusedRowIndex('financesDocuments')
                : 0;
            if (!data.length) {
              this.focusedSelected = [];
              resolve();
              return;
            }

            const toAdd = data.filter(
              (el) =>
                el.IsSelected &&
                this.selectedRow.indexOf(el.FinanceDocumentId) === -1
            );
            if (toAdd.length) {
              this.skipSendSelectionEvent = true;
              this.cd.detectChanges();
            }
            for (let item of toAdd) {
              this.selectedRow.push(item.FinanceDocumentId);
            }
            const toDelete = [];
            for (let id of this.selectedRow) {
              const item = data.find((el) => el.FinanceDocumentId === id);
              if (item && !item.IsSelected) {
                toDelete.push(id);
              }
            }
            for (let id of toDelete) {
              this.selectedRow.splice(
                this.selectedRow.findIndex((el) => el === id),
                1
              );
              this.skipSendSelectionEvent = true;
              this.cd.detectChanges();
            }

            if (this.firstLoad) {
              this.firstLoad = false;
              setTimeout(() => {
                const filters: any = this.getLoadParams();
                const totalCount: number = this.dataSource.totalCount();
                if (!totalCount) {
                  resolve();
                  return;
                }

                if (
                  (filters?.order === 'ASC' && this.focusOnLastRow === 'Tak') ||
                  (filters?.order === 'DESC' && this.focusOnLastRow === 'Nie')
                ) {
                  this.indexToFocus = totalCount - 1;
                  if (this.indexToFocus < 0) {
                    this.indexToFocus =
                      this.event.initFocusedRowIndex('financesDocuments') === 0
                        ? totalCount - 1
                        : this.event.initFocusedRowIndex('financesDocuments');
                  }
                  const page = Math.floor(totalCount / this.pageSize);
                  if (page > 0) {
                    this.setFocusOnLoad = true;
                    this.cd.detectChanges();
                    this.dataSource.pageIndex(page);
                    this.dataSource.load();
                  } else {
                    this.focusedRowIndex = this.indexToFocus;
                    this.onFocusedRowChanged({
                      row: { data: data[this.focusedRowIndex] },
                    });
                    this.cd.detectChanges();
                  }
                } else {
                  this.focusedRowIndex =
                    data.length >
                    this.event.initFocusedRowIndex('financesDocuments')
                      ? this.event.initFocusedRowIndex('financesDocuments')
                      : 0;
                  // this.onFocusedRowChanged({
                  //   row: { data: data[this.focusedRowIndex] },
                  // });
                  this.cd.detectChanges();
                }

                this.searchControl?.focusInput();
              }, 200);
            } else {
              if (fromSearch) {
                this.searchControl?.focusInput();
              } else {
                this.gridClass.instance.focus();
              }
            }
            resolve();

            if (
              data.length >= this.focusedRowIndex &&
              this.focusedRowIndex > -1
            ) {
              this.focusedSelected = [data[this.focusedRowIndex]];
            }
            this.cd.detectChanges();
          },
          deleteUrl: `${environment.domain}finances/documents`,
        }),
        reshapeOnPush: true,
        requireTotalCount: true,
        paginate: true,
      });
    });
  };

  setFocusAfterLoad(data) {
    this.focusedRowIndex = this.indexToFocus;
    this.setFocusOnLoad = false;
    this.onFocusedRowChanged({ row: { data: data[this.focusedRowIndex] } });
    this.cd.detectChanges();
  }

  getTypeDocument = () => {
    this.typeDocumentNewDoc = [];
    const localCache = this.event.getSessionStorageData(FINANCE_TYPE_DOCUMENT);
    if (localCache) {
      this.mapTypes(localCache);
      return;
    }
    this.appServices
      .getAuth(`document/sales/types/finance`)
      .subscribe((res) => {
        this.mapTypes(res?.data || []);
        this.event.saveSessionStorageData(
          FINANCE_TYPE_DOCUMENT,
          res?.data || []
        );
      });
  };

  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        localStorage.setItem(
          'finance-operation',
          JSON.stringify(this.localStorageData)
        );
        this.getData();
      }
    })
  }

  mapTypes(types: any[]) {
    this.typeDocument = [
      {
        expanded: true,
        items: [
          { expanded: true, TypeId: 'k', Name: 'Kasowe', items: [] },
          { expanded: true, TypeId: 'b', Name: 'Bankowe', items: [] },
          { expanded: true, TypeId: 'c', Name: 'Kompensaty', items: [] },
          { expanded: true, TypeId: 'i', Name: 'Inne', items: [] },
        ],
        TypeId: '-1',
        Name: 'Wszystkie',
      },
    ];
    types.forEach((element) => {
      if (
        element.OperationType == 'k' ||
        element.OperationType == 'b' ||
        element.OperationType == 'i'
      ) {
        this.typeDocumentNewDoc.push(element);
      }
      if (element.OperationType == 'k') {
        this.typeDocument[0].items[0].items.push(element);
      }
      if (element.OperationType == 'b') {
        this.typeDocument[0].items[1].items.push(element);
      }
      if (element.OperationType == 'i') {
        this.typeDocument[0].items[3].items.push(element);
      }
    });
    this.translate
      .get('all')
      .subscribe((text) => (this.typeDocument[0].Name = text));
    this.translate
      .get('form-financial-document.cashRegister')
      .subscribe((text) => (this.typeDocument[0].items[0].Name = text));
    this.translate
      .get('form-financial-document.banking')
      .subscribe((text) => (this.typeDocument[0].items[1].Name = text));
    this.translate
      .get('form-financial-document.compensation')
      .subscribe((text) => (this.typeDocument[0].items[2].Name = text));
    this.translate
      .get('menu.other')
      .subscribe((text) => (this.typeDocument[0].items[3].Name = text));
  }

  getLoadParams() {
    if (this.onlySelected) {
      this.paramsObj = {
        onlySelected: true,
      };
      return this.paramsObj;
    }
    let obj: any = {};
    (obj.orderBy = this.localStorageData.filter.orderBy), //this.valueCriteria[0].toLowerCase() + this.valueCriteria.substr(1),//
      (obj.order = this.localStorageData.filter.order);
    if (this.valueCriteria2 != '') {
      obj.signature = this.valueCriteria2;
    }

    obj.DateFrom =
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '';
    obj.DateTo =
      this.event.dataFormatted({ value: this.currentDate.dateTo }) || '';

    if (this.useForSelection) {
      obj.UseForSelection = true;
      this.cd.detectChanges();
    }
    if (this.FinanceDocumentId) {
      obj.ObjectId = this.FinanceDocumentId;
    }
    switch (this.localStorageData.filter.orderBy) {
      case 'number':
        obj.number = this.filterValue;
        break;
      case 'financeDocumentDate':
        obj.DateFrom =
          this.event.setCorrectDate(this.filterValue) ||
          this.event.dataFormatted({ value: this.currentDate.dateFrom }) ||
          '';
        obj.DateTo =
          this.event.setCorrectDate(this.filterValue) ||
          this.event.dataFormatted({ value: this.currentDate.dateTo }) ||
          '';
        //obj.financeDocumentDate = this.event.setCorrectDate(this.filterValue);
        break;
      case 'amount':
        obj.amount = this.filterValue;
        break;
      case 'payerName':
        obj.payerName = this.filterValue;
        break;
      case 'payerNameIntuition':
        obj.payerNameIntuition = this.filterValue;
        break;
      case 'statementNumber':
        obj.statementNumber = this.filterValue;
        break;
      case 'titleIntuition':
        obj.titleIntuition = this.filterValue;
        break;
      case 'numberIntuition':
        obj.numberIntuition = this.filterValue;
        break;
      default:
        break;
    }

    if (this.customerFilter.PayerType !== 'all') {
      obj.PayerType = this.customerFilter.PayerType;
    }
    if (this.customerFilter.CustomerId) {
      obj.PayerId = this.customerFilter.CustomerId;
    } else if (this.customerFilter.OfficeId) {
      obj.PayerId = this.customerFilter.OfficeId;
    } else if (this.customerFilter.WorkerId) {
      obj.PayerId = this.customerFilter.WorkerId;
    }

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    this.paramsObj = obj;
    return obj;
  }

  resetFiltr = (filtr) => {
    switch (filtr) {
      case 'valueCriteria2':
        this.valueCriteria2 = '';
        this.restoreTreeConfig();
        this.treeViewAll?.instance.unselectAll();
        break;
      case 'advancedFilter':
        this.localStorageData.advancedFilter =
          this.dynamicFilterService.createDefaultAdvancedFilter(
            this.localStorageData.advancedFilter.IsPinned
          );
        break;
      case 'onlySelected':
        this.onlySelected = false;
        break;
    }

    this.localStorageData.valueCriteria2 = '';
    localStorage.setItem(
      'finance-operation',
      JSON.stringify(this.localStorageData)
    );
    this.getData();
  };

  getSortIcon() {
    return this.localStorageData.filter.order === 'ASC'
      ? 'icon absui-icon--sort-ascending'
      : 'icon absui-icon--sort-descending';
  }

  switchOrder() {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      localStorage.setItem(
        'finance-operation',
        JSON.stringify(this.localStorageData)
      );
      return;
    }
    this.localStorageData.filter.order = 'ASC';
    localStorage.setItem(
      'finance-operation',
      JSON.stringify(this.localStorageData)
    );
  }

  onFilterDataChanged(e) {
    this.localStorageData.filter.orderBy = e.selectedItem.value;
    this.localStorageData.filter.value = e.filterValue;
    this.filterValue = e.filterValue;
    localStorage.setItem(
      'finance-operation',
      JSON.stringify(this.localStorageData)
    );

    if (e.selectedItem) {
      this.localStorageData.filter.orderBy = e.selectedItem.value;
      //this.displayValue = e.selectedItem.label
    }
    this.filterValue = e.filterValue;
    this.getData(true);
  }

  sendRequestTimer;
  useForSelection: boolean = false;
  onlySelected = false;
  skipSendSelectionEvent = false;
  isAllSelected: boolean = false;
  onSelectionChanged = (e: SelectionChangedEvent) => {
    this.selectedDocumentsData = e.selectedRowsData;
    if (this.skipSendSelectionEvent) {
      this.toSelect = [];
      this.toDeselect = [];
      this.skipSendSelectionEvent = false;
      return;
    }

    if (!this.onlySelected) {
      if (
        e.currentDeselectedRowKeys.length &&
        e.currentDeselectedRowKeys.length === this.dataSource._totalCount
      ) {
        this.deselectAllRecords();
        return;
      }
    }

    this.toSelect = this.toSelect.concat(e.selectedRowKeys);
    this.selectedAmount.set(
      this.selectedAmount() + e.currentSelectedRowKeys.length
    );
    if (this.selectedAmount() < 0) {
      this.selectedAmount.set(0);
    }
    this.toDeselect = this.toDeselect.concat(e.currentDeselectedRowKeys);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }
    if (
      this.selectedRow.length === 0 ||
      (this.selectedRow.length &&
        this.selectedRow.length !== this.dataSource._items.length)
    ) {
      this.isAllSelected = false;
    }

    if (this.dataSource._items.length) {
      this.setSelectionTimeout();
    }
  };

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        let toSelect = [];

        this.selectedRow.forEach((field) => {
          if (typeof field == 'object') {
            toSelect.push(field.SadDocumentId);
          } else {
            toSelect.push(field);
          }
        });

        if (toSelect.length) {
          this.event.selectRecords(
            `finances/selection/documents/select`,
            toSelect
          );
        }
        this.toSelect = [];
      }
      const items = this.dataSource.items();
      const toRemove = [];
      for (let toDes of this.toDeselect) {
        const item = items.find((el) => el.FinanceDocumentId === toDes);
        if (!item) {
          toRemove.push(this.toDeselect.indexOf(toDes));
        }
      }
      for (let i = toRemove.length - 1; i >= 0; i--) {
        this.toDeselect.splice(toRemove[i], 1);
      }
      if (this.toDeselect.length) {
        let toDeselect = [];
        this.toDeselect.forEach((field) => {
          if (typeof field == 'object') {
            toDeselect.push(field.SadDocumentId);
          } else {
            toDeselect.push(field);
          }
        });
        this.selectedAmount.set(this.selectedAmount() - toDeselect.length);
        if (this.selectedAmount() < 0) {
          this.selectedAmount.set(0);
        }
        if (toDeselect.length) {
          this.event.selectRecords(
            `finances/selection/documents/unselect`,
            toDeselect
          );
        }
        toDeselect = [];
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);

    // this.sendRequestTimer = setTimeout(() => {
    //   if (this.toSelect.length) {
    //     this.toSelect = [];
    //     this.event.selectRecords(
    //       `finances/selection/documents/select`,
    //       this.selectedRow
    //     );
    //   }
    //   if (this.toDeselect.length) {
    //     this.event.selectRecords(
    //       `finances/selection/documents/unselect`,
    //       this.toDeselect
    //     );
    //     this.toDeselect = [];
    //   }
    //   this.sendRequestTimer = null;
    // }, 500);
  }

  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  focusedRowIndex;
  keyDownTimeout = null;

  handleSearchFromGrid(key: string) {
    const orderByItem = this.filterCriteria.find(
      (el) => el.value === this.localStorageData.filter.orderBy
    );

    this.filterValue = this.event.setFilterValueBySearchKey(
      this.filterValue,
      key,
      orderByItem.type === 'data'
    );

    if (this.keyDownTimeout) {
      clearTimeout(this.keyDownTimeout);
    }
    this.keyDownTimeout = setTimeout(() => {
      if (orderByItem.type === 'data') {
        if (
          this.event.setCorrectDate(this.filterValue) !==
          this.paramsObj[this.localStorageData.filter.orderBy]
        ) {
          this.getData();
        }
        return;
      }
      this.getData();
    }, 300);
  }

  onKeyDown(e) {
    if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.key === 'O' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.additionalOperation.openList();
    } else if (e.event.key === 'S' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.sumOpen();
    } else if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.key === 'F9' && !e.event.ctrlKey && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.dateRange.openDateRange();
    } else if (e.event.key === 'F7') {
      e.event.preventDefault();
      e.event.stopPropagation();
      if (e.event.shiftKey) {
        this.setCustomerFromFocusedRow();
      } else {
        this.openPayer();
      }
      this.cd.detectChanges();
    } else if (e.event.key === 'F6' && e.event.shiftKey) {
      e.event.stopPropagation();
      e.event.preventDefault();
      if (this.onlySelected) {
        this.revertSelectOnly();
      } else {
        this.onOnlySelected();
      }
    } else if (
      this.event.isSearchGridKey(
        e.event.keyCode,
        e.event.altKey,
        e.event.ctrlKey
      )
    ) {
      this.handleSearchFromGrid(e.event.key);
    } else if (e.event.keyCode === 107) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.cd.detectChanges();
      this.gridClass.instance.selectAll();
      this.selectAll();
    } else if (e.event.keyCode === 109) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.cd.detectChanges();
      this.selectedRow = [];
      this.deselectAllRecords();
    } else if (e.event.key === 'Enter') {
      if (this.selectedRow.length && this.exportFakirService.isSelectMode()) {
        this.selectChoosed();
      }
    } else if (e.event.key === 'Escape') {
      if (this.exportFakirService.isSelectMode()) {
        this.cancelChoosed();
      }
    }
    if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    }
    if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onGridContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }
    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  onRowDblClick = () => {
    if (
      !this.readOnly &&
      !this.dropDownBoxMode &&
      (this.perABD.editBtnB || this.perABD.editBtnI || this.perABD.editBtnK)
    ) {
      if (this.doubleClickResult === 'Edycja') {
        this.showEditContr();
      } else {
        this.showRow = true;
        this.cd.detectChanges();
      }
    }
  };

  onOptionChanged = (e) => {
    this.event.onOptionChanged(e);
    if (e.name === 'columns' && e.fullName.indexOf('width') > -1) {
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        this.localStorageData.columns[colIndex].width = e.value;
      } catch (e) {}
    }

    // setTimeout(() => {
    //   this.localStorageData.columns.forEach((field, index) => {
    //     field.visibleIndex = index;
    //   });
    // }, 0);
  };

  myTimer
  onFocusedRowChanged = (e) => {
    if (!e.row) {
      return;
    }
    this.focusedSelected = [e.row.data];
    this.event.setFocusedRowIndex('financesDocuments', this.focusedRowIndex);
    this.tableService.addTableContext({
      id: e.row.data?.FinanceDocumentId,
      idParentObject: e.row.data,
      tableName: 'Dokument_finansowy',
    });
    this.menuName = e.row.data?.MenuName || null;

    this.myTimer = setTimeout(() => {
      this.myTimer = null;
      try {
        this.getDetails(this.focusedSelected[0].FinanceDocumentId);
      } catch (e) {}
    }, 300);
  };

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  };

  onInserted = (e: OnInsertedData, mode: SingleRecordMode) => {
    this.isReport = false;
    this.editRow = false;
    this.newRowPopupVisible = false;
    this.focusedSelected = [e.document];
    this.getData().then(() => {
      const data = this.dataSource.items();
      let index = data.findIndex(
        (x: FinancialDocument) =>
          x.FinanceDocumentId === e.document.FinanceDocumentId
      );

      if (index != -1) {
        this.focusedRowIndex = index;
        this.focusedSelected = [e.document];
        this.cd.detectChanges();
      }
      if (mode === 'add') {
        this.indexToFocus = index;
        this.setFocusAfterLoad(data);
        this.gridClass.instance.focus(index);
      }
    });
    if (mode === 'add') {
      this.findSocketAndRun('XGFLYD2');
    } else if (mode === 'edit') {
      this.findSocketAndRun('XGFLYP2');
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 500);
  }

  selectAll = () => {
    this.isAllSelected = true;
    this.cd.detectChanges();
    this.appServices
      .postAuth(`finances/selection/documents`, this.paramsObj)
      .subscribe(({ RowNumber }) => {
        this.selectedAmount.set(RowNumber);
      });
    this.modeSelectionMobile = 'multiple';
  };

  isLabel: boolean = false;
  onlyShow: boolean = false;

  onClosedBankList = () => {
    this.isShowBankList = false;
    this.cd.detectChanges();
  };

  onBankAccountChoosed = (e) => {
    this.isShowBankList = false;
    this.cd.detectChanges();
    this.appServices
      .postAuth(
        `finances/additionalOperations/changeBankAccount/${e.BankAccountId}`,
        null
      )
      .subscribe(
        () => {
          this.dataSource.reload();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  isShowBankList: boolean = false;
  selectedOwnOperation: any = null;
  runOwnAdditionalOperation(operation: any) {
    this.selectedOwnOperation = operation;
    this.findSocketAndRun(
      `XGFLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E1`
    );
  }

  runInnerOwnOperation() {
    const data = this.event.prepareAdditionalOperationData(
      this.selectedOwnOperation,
      this.currentDate,
      this.selectedRow.length == 0
        ? this.focusedSelected[0].FinanceDocumentId
        : null
    );
    this.event.runOwnAdditionalOperation(data).subscribe({
      next: () => {
        if (this.selectedOwnOperation?.RefreshList) {
          this.getData();
        }
        this.findSocketAndRun(
          `XGFLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E2`
        );
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      },
    });
  }

  contextMenuClick = (e) => {
    let DEtykietyDokFin = this.event.checkPermissions('DEtykietyDokFin');
    let UEtykietyDokFin = this.event.checkPermissions('UEtykietyDokFin');
    let BlokDokFin = this.event.checkPermissions('BlokDokFin');

    if (e.itemData.IsOuter) {
      this.runOwnAdditionalOperation(e.itemData);
      return;
    }

    switch (e.itemData.itemIndex) {
      case '1111_000_GRUPZMOPER':
        if (this.selectedAmount() === 0) {
          this.event.showNotification(
            'info',
            this.translate.instant('buffor.selectLeastPosition')
          );
        } else {
          this.isGroupzmoper.set(true);
        }

        break;
      case 0:
        if (!this.readOnly) {
          this.openNewRow();
        }
        break;
      case 1:
        if (!this.readOnly) {
          this.showEditContr();
        }
        break;
      case 2:
        if (!this.readOnly) {
          this.isDelete();
        }
        break;
      case 3:
        this.skipSendSelectionEvent = true;
        this.cd.detectChanges();
        this.gridClass.instance.selectAll();
        this.selectAll();
        break;
      case 4:
        if (!this.readOnly) {
          this.gridClass.instance.deselectRows(
            this.focusedSelected[0].FinanceDocumentId
          );
        }
        break;
      case 5:
        this.skipSendSelectionEvent = true;
        this.cd.detectChanges();
        this.selectedRow = [];
        this.deselectAllRecords();
        break;
      case 51:
        if (this.onlySelected) {
          this.revertSelectOnly();
        } else {
          this.onOnlySelected();
        }
        break;
      case 7:
        if (!this.readOnly && DEtykietyDokFin) {
          if (this.selectedAmount() === 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.isLabel = true;
            this.cd.detectChanges();
          }
        }
        break;
      case 8:
        if (!this.readOnly && UEtykietyDokFin) {
          if (this.selectedAmount() === 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.event.deleteLabel('finances/selection/label');
            setTimeout(() => {
              this.selectedRow = [];
              this.dataSource.reload();
            }, 100);
          }
        }
        break;
      case 30:
        this.eDocumentsVisible = true;
        this.cd.detectChanges();
        break;
      case '1111_000_DO_KSIEGOW':
        if (!this.readOnly) {
          if (this.selectedAmount() === 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGFLEXDOKFIN_BRWDO_KSIEGOW_E1');
          }
        }
        break;
      case '1111_000_OD_KSIEGOW':
        if (!this.readOnly) {
          if (this.selectedAmount() === 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGFLEXDOKFIN_BRWOD_KSIEGOW_E1');
          }
        }
        break;
      case '1111_000_BLOKDOK':
        if (!this.readOnly && BlokDokFin) {
          this.textBlock =
            'form-commercial-operation.doYouPerformDocumentBlock';
          this.findSocketAndRun('XGFLEXDOKFIN_BRWBLOKDOK_E1');
        }
        break;
      case '1111_000_ODBLOKDOK':
        if (!this.readOnly && BlokDokFin) {
          this.textBlock =
            'form-commercial-operation.doYouPerformDocumentUnblock';
          this.findSocketAndRun('XGFLEXDOKFIN_BRWODBLOKDOK_E1');
        }
        break;
      case '0011_000_POLADODGRUP':
        this.onlyShow = false;
        this.additionalFieldsList = {
          Field1: '',
          Field2: '',
          Field3: '',
          Field4: '',
          Field5: '',
          Field6: '',
          Field7: '',
          Field8: '',
          Field9: '',
          Field10: '',
        };

        if (!this.readOnly) {
          if (this.selectedAmount() === 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGFLEXDOKFIN_BRWPOLADODGRUP_E1');
          }
        }
        break;
      case '0011_000_POLADOD':
        this.onlyShow = true;
        this.cd.detectChanges();
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.additionalFieldsList = this.focusedSelected[0];
          this.visibleUserExtensions = true;
          this.cd.detectChanges();
        }
        break;
      case '1111_000_GRUPZMRACH':
        if (!this.readOnly) {
          if (this.selectedAmount() === 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastDocument')
            );
          } else {
            this.isShowBankList = true;
            this.cd.detectChanges();
          }
        }
        break;
      case '1111_000_KARTA_KONTR':
        this.showRecordCard('contractor');
        break;
      case 99:
        this.modeSelectionMobile = 'multiple';
        let row = this.gridClass.instance.getDataSource().items()[
          this.focusedRowIndex
        ];
        const id = row.FinanceDocumentId;
        if (!this.selectedRow.includes(id)) {
          this.gridClass.instance.selectRows(id, true);
        } else {
          this.gridClass.instance.deselectRows(id);
        }
        this.isContextMenuMobile = false;
        break;
      case 98:
        this.isContextMenuMobile = false;
        this.isDetails = true;
        this.cd.detectChanges();
        break;
      case 96:
        this.isMobileReportsPopupVisible = true;
        this.cd.detectChanges();
        break;
    }
  };

  onChoosedLabel = () => {
    this.selectedRow = [];
    this.isLabel = false;
    this.dataSource.reload();
  };

  isDelete = () => {
    if (
      this.focusedSelected.length > 0 &&
      !this.readOnly &&
      ((this.perABD.deleteBtnB &&
        this.focusedSelected[0].OperationType[0] == 'b') ||
        (this.perABD.deleteBtnK &&
          this.focusedSelected[0].OperationType[0] == 'k') ||
        (this.perABD.deleteBtnI &&
          this.focusedSelected[0].OperationType[0] == 'i'))
    ) {
      this.findSocketAndRun('XGFLYU1');
    }
  };

  delete = () => {
    this.isDeleteRow = false;
    this.gridClass.instance.deleteRow(
      this.gridClass.instance.getRowIndexByKey(
        this.focusedSelected[0].FinanceDocumentId
      )
    );
    this.gridClass.instance.focus();
    setTimeout(() => {
      if (this.gridClass) {
        this.focusedSelected = [];
        this.gridClass.focusedRowIndex = 0;
        this.gridClass.instance.focus();
        this.getData();
      }
    }, 500);
    this.findSocketAndRun('XGFLYU2');
  };

  isReport = false;
  showEditContr = (i?) => {
    if (this.focusedSelected.length) {
      if (
        this.focusedSelected[0].ReportId != 0 &&
        this.focusedSelected[0].ReportId != null
      ) {
        this.isReport = true;
      }

      if (this.focusedSelected[0].IsSetForAccounting === true) {
        this.event.showNotification(
          'warning',
          this.translate.instant(
            'unmodifiableDocumentMarkedForTransferToFKSystem'
          )
        );
        return;
      }
    }

    if (i) {
      this.focusedSelected = [i];
    }
    if (
      this.focusedSelected.length > 0 &&
      !this.readOnly &&
      ((this.perABD.editBtnB && this.focusedSelected[0].PayerType == 'b') ||
        (this.perABD.editBtnK && this.focusedSelected[0].PayerType == 'k') ||
        (this.perABD.editBtnI && this.focusedSelected[0].PayerType == 'i') ||
        (this.perABD.editBtnI && this.focusedSelected[0].PayerType == 'p'))
    ) {
      this.findSocketAndRun('XGFLYP1');
    }
  };

  editContrApi() {
    this.appServices
      .putAuth(
        `finances/documents/${this.focusedSelected[0].FinanceDocumentId}`,
        {
          FinanceDocumentId: this.focusedSelected[0].FinanceDocumentId,
          CurrencyCode:
            this.focusedSelected[0].CurrencyCode === '' ||
            this.focusedSelected[0].CurrencyCode === null
              ? null
              : this.focusedSelected[0].CurrencyCode,
        }
      )
      .subscribe(() => {
        this.editRow = true;
        this.cd.detectChanges();
      });
  }

  additionalTables = [];
  isExternalTableVisible: boolean = false;
  shortcutTable;
  isShortcutTableOpened: boolean = false;

  getUserParameters() {
    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }

    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }

    const focusOnLastRow = this.event.getConfigurationParameter('NaOstatZapis');
    if (focusOnLastRow) {
      this.focusOnLastRow = focusOnLastRow.Value as RefreshCardResult;
    }
  }

  clearMobileFilters = () => {
    this.resetFiltr('valueCriteria2');
  };

  setForAccounting(set: boolean = false) {
    const url: string = `finances/additionalOperations/${
      set ? 'set' : 'unset'
    }ForAccounting`;

    this.appServices.putAuth(url, null).subscribe({
      next: () => {
        const text = this.translate.instant(
          `form-financial-document.${set ? 'set' : 'unset'}ForAccounting`
        );
        this.event.showNotification('success', text);

        this.selectedRow = [];
        this.dataSource.reload();
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
      complete: () => {
        if (set) {
          this.findSocketAndRun('XGFLEXDOKFIN_BRWDO_KSIEGOW_E2');
        } else {
          this.findSocketAndRun('XGFLEXDOKFIN_BRWOD_KSIEGOW_E2');
        }
      },
    });
  }

  visibleUserExtensions = false;
  additionalFieldsList = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };
  isBlockRow = false;
  textBlock = '';

  blockUnblockDocument() {
    this.isBlockRow = false;
    const isBlock =
      this.textBlock == 'form-commercial-operation.doYouPerformDocumentBlock'
        ? true
        : false;
    const url =
      this.selectedRow.length > 0
        ? ''
        : `?financeDocumentId=${this.focusedSelected[0].FinanceDocumentId}`;

    this.appServices
      .putAuth(
        `finances/additionalOperations/${isBlock ? 'block' : 'unblock'}${url}`,
        null
      )
      .subscribe(
        () => {
          const text = this.translate.instant(
            isBlock
              ? 'form-commercial-operation.theDocumentHasBeenBlocked'
              : 'form-commercial-operation.theDocumentHasBeenUnblocked'
          );
          this.event.showNotification('info', text);

          this.selectedRow = [];
          this.dataSource.reload();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          if (isBlock) {
            this.findSocketAndRun('XGFLEXDOKFIN_BRWBLOKDOK_E2');
          } else {
            this.findSocketAndRun('XGFLEXDOKFIN_BRWODBLOKDOK_E2');
          }
        }
      );
  }

  onClosingUserExtensions() {
    this.visibleUserExtensions = false;
  }

  setAdditionalFields(e) {
    this.appServices
      .putAuth(`finances/additionalOperations/document/setAdditionalFields`, e)
      .subscribe(
        () => {
          // this.visibleUserExtensions = false;
          this.additionalFieldsList = {
            Field1: '',
            Field2: '',
            Field3: '',
            Field4: '',
            Field5: '',
            Field6: '',
            Field7: '',
            Field8: '',
            Field9: '',
            Field10: '',
          };

          this.selectedRow = [];
          this.dataSource.reload();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGFLEXDOKFIN_BRWPOLADODGRUP_E2');
        }
      );
  }

  visibleAdvanceFilters = false;

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'finance-operation',
      this.event.encryptString(this.localStorageData)
    );

    this.getData();
  }

  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;

    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false; // znalazlo
    } else {
      this.onNestFinished({ SocketCode });
      return true; // nieznalazlo
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGFLA1':
        this.findSocketAndRun('XGFLA2');
        break;
      case 'XGFLO1':
        this.componentVisible = true;
        this.cd.detectChanges();
        this.findSocketAndRun('XGFLO2');
        break;
      case 'XGFLO2':
        this.searchControl?.focusInput();
        break;
      case 'XGFLYD1':
        this.findSocketAndRun('XGFLYA1', 'add');
        break;
      case 'XGFLYA1':
        if (this.nestOperation === 'add') {
          this.newRowPopupVisible = true;
          this.cd.detectChanges();
        } else if (this.nestOperation === 'edit') {
          this.editContrApi();
        } else if (this.nestOperation === 'delete') {
          this.isDeleteRow = true;
          this.cd.detectChanges();
        }
        break;
      case 'XGFLYP1':
        this.findSocketAndRun('XGFLYA1', 'edit');
        break;
      case 'XGFLYU1':
        this.findSocketAndRun('XGFLYA1', 'delete');
        break;
      case 'XGFLYU2':
        this.findSocketAndRun('XGFLYA2');
        break;
      case 'XGFLYD2':
        this.findSocketAndRun('XGFLYA2');
        break;
      case 'XGFLYP2':
        this.findSocketAndRun('XGFLYA2');
        break;
      // operacje dod
      case 'XGFLEXDOKFIN_BRWBLOKDOK_E1':
        this.isBlockRow = true;
        this.cd.detectChanges();
        break;
      case 'XGFLEXDOKFIN_BRWODBLOKDOK_E1':
        this.isBlockRow = true;
        this.cd.detectChanges();
        break;
      case 'XGFLEXDOKFIN_BRWOD_KSIEGOW_E1':
        this.setForAccounting(false);
        break;
      case 'XGFLEXDOKFIN_BRWDO_KSIEGOW_E1':
        this.setForAccounting(true);
        break;
      case 'XGFLEXDOKFIN_BRWPOLADODGRUP_E1':
        this.visibleUserExtensions = true;
        this.cd.detectChanges();
        break;
      case `XGFLEX${this.selectedOwnOperation?.GroupCode}_${this?.selectedOwnOperation.Name}_E1`:
        this.runInnerOwnOperation();
        break;
    }
  }

  menuName: string = null;

  restoreTreeConfig = () => {
    setTimeout(() => {
      if (this.valueCriteria2 == '') {
        this.treeViewAll?.instance.selectItem(-1);
      }
    }, 0);
  };

  lastTreeSelection;

  onTreeSelectionChanged = (e) => {
    const selectedNodes = e.component
      .getSelectedNodes()
      .map((node) => node.itemData);
    if (selectedNodes.length > 0) {
      this.lastTreeSelection = selectedNodes;
    }
    e.component.selectItem(this.lastTreeSelection);
  };

  openRightPanel = () => {
    if (this.width <= 21) {
      this.resizeWindowBtn();
    }
  };

  advancedFilterTemplateData = null;

  clearValues(onlySelected: boolean = false) {
    this.onlySelected = onlySelected;
    if (onlySelected) {
      this.event.setCurrentDateForOnlySelected();
    } else {
      this.event.setCurrentDate(new GlobalDate(), false, true);
    }
    this.deleteFilter = true;
    this.cd.detectChanges();
    if (
      !this.advancedFilterTemplateData &&
      this.localStorageData.advancedFilter
    ) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter?.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.cd.detectChanges();
    }
    this.resetFiltr('valueCriteria2');
    this.customerFilter.PayerType = null;
    this.cd.detectChanges();
    this.clearCustomerFilterValues();
    this.getData();
    this.deleteFilter = false;
  }

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getData();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  onPinnedValueChanged(e) {
    const modifiedIndex =
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.findIndex(
        (el) => el.FilterDefinitionId === e.FilterDefinitionId
      );
    if (modifiedIndex > -1) {
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.splice(
        modifiedIndex,
        1
      );
    }
    this.localStorageData.advancedFilter.UserFilterDefinitionValues = [
      ...this.localStorageData.advancedFilter.UserFilterDefinitionValues,
      e,
    ];
    this.cd.detectChanges();
  }

  getSavedFilterDefinitions() {
    const windowId = this.event.getFilterWindowIdFromDictionaryCode(
      this.DynamicFilterDictionaryCode
    );
    this.dynamicFilterService.getViewUserDefinition(this.DynamicFilterDictionaryCode).pipe(
      take(1),
      switchMap((data: ViewUserDefinition) => {
        if(data){
          return of(null)
        }
        else{
          return this.dynamicFilterService.getUsersDefinitionsForWindowId(windowId)
        }
      })
    ).subscribe({
        next: (data: UserDefinition[]) => {
          if(!data) return;

          this.dynamicFilterService.addViewUserDefinitions(
            this.DynamicFilterDictionaryCode,
            data || []
          );
        },
        error: () => {},
      });
  }

  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 1,
      isPanelVisible: this.localStorageData.sidePanel.isVisible,
    };

    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
        config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex ==
        config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
  };

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.getData();
    this.cd.detectChanges();
  };

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGF');
    }
    this.gridClass.instance.focus();
  }

  payerTypes = [
    {
      label: 'wszystko',
      value: 'all',
    },
    {
      label: 'kontrahent',
      value: 'k',
    },
    {
      label: 'pracownik',
      value: 'p',
    },
    {
      label: 'urząd',
      value: 'u',
    },
  ];
  isWorkersVisible = false;
  isOfficeVisible = false;
  isCustomerVisible = false;

  customerFilter = {
    CustomerId: null,
    CustomerName: '',
    WorkerId: null,
    WorkerName: '',
    OfficeId: null,
    OfficeName: '',
    PayerType: 'all',
  };

  onChoosedCustomer = (e) => {
    if (e) {
      this.customerFilter.CustomerId = e.CustomerId;
      this.customerFilter.CustomerName = e.Name;
      this.customerFilter.PayerType = 'k';
      this.isCustomerVisible = false;
      this.getData();
    }
  };

  setCustomerFromFocusedRow() {
    if (
      this.focusedSelected[0] &&
      this.customerFilter.CustomerId !== this.focusedSelected[0].PayerId
    ) {
      this.clearCustomerFilterValues();
      this.customerFilter.PayerType = this.focusedSelected[0].PayerType;
      if (this.focusedSelected[0].PayerType === 'k') {
        this.customerFilter.CustomerId = this.focusedSelected[0].PayerId;
        this.customerFilter.CustomerName = this.focusedSelected[0].PayerName;
      } else if (this.focusedSelected[0].PayerType === 'u') {
        this.customerFilter.OfficeId = this.focusedSelected[0].PayerId;
        this.customerFilter.OfficeName = this.focusedSelected[0].PayerName;
      } else if (this.focusedSelected[0].PayerType === 'p') {
        this.customerFilter.WorkerId = this.focusedSelected[0].PayerId;
        this.customerFilter.WorkerName = this.focusedSelected[0].PayerName;
      }
      this.getData();
    }
  }

  onPayerTypeChanged(e: string) {
    this.customerFilter.PayerType = e;
    this.resetPayerFilter();
  }

  onChoosedWorker(e) {
    this.customerFilter.WorkerId = e?.EmployeeId;
    this.customerFilter.WorkerName = e?.FirstName + ' ' + e?.LastName;
    this.getData();
  }

  onChoosedOffice(e) {
    this.customerFilter.OfficeId = e?.TaxOfficeId;
    this.customerFilter.OfficeName = e?.Name;
    this.getData();
  }

  openPayer() {
    if (this.customerFilter.PayerType === 'all') {
      (this.payerChips as any)?.eRef?.nativeElement?.click();
      this.payerChips.isChipsList.set(true);
      this.cd.detectChanges();
      setTimeout(() => {
        this.payerChips.listRef.instance.focus();
      }, 100);
    } else if (this.customerFilter.PayerType === 'k') {
      this.isCustomerVisible = true;
      this.cd.detectChanges();
    } else if (this.customerFilter.PayerType === 'p') {
      this.isWorkersVisible = true;
      this.cd.detectChanges();
    } else if (this.customerFilter.PayerType === 'u') {
      this.isOfficeVisible = true;
      this.cd.detectChanges();
    }
  }

  clearCustomerFilterValues() {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
    this.customerFilter.WorkerId = null;
    this.customerFilter.WorkerName = '';
    this.customerFilter.OfficeId = null;
    this.customerFilter.OfficeName = '';
  }

  resetPayerFilter() {
    this.clearCustomerFilterValues();
    this.getData();
  }

  onScanerInputChanged(e) {
    this.setSearchCriteria('numberIntuition');
    this.filterValue = e.toString();
    this.getData();
  }

  onOnlySelected() {
    this.clearValues(true);
  }

  revertSelectOnly() {
    this.onlySelected = false;
    this.getData();
  }

  onDeselectAll() {
    this.onlySelected = false;
    this.skipSendSelectionEvent = true;
    this.cd.detectChanges();
    this.deselectAllRecords();
    this.getData();
  }

  deselectAllRecords() {
    this.selectedRow = [];
    this.isAllSelected = false;
    this.selectedAmount.set(0);
    return this.event.deselectAllRecords('finances/selection/document');
  }

  onEditorPreparing(e) {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  onReportsPopupClosed() {
    this.isMobileReportsPopupVisible = false;
    this.cd.detectChanges();
  }

  isSumVisible = false;

  sumOpen() {
    this.isSumVisible = true;
    this.cd.detectChanges();
  }

  onSumClosing() {
    this.isSumVisible = false;
  }

  selectChoosed() {
    const value = this.exportFakirService.selections.getValue();
    if (this.isAllSelected) {
      this.exportFakirService.selections.next({
        ...value,
        Financial: this.dataSource._totalCount,
      });
    } else if (this.selectedRow) {
      this.exportFakirService.selections.next({
        ...value,
        Financial: this.selectedRow.length,
      });
    }
    this.event.isExportFKVisible.set(true);
  }

  cancelChoosed() {
    this.deselectAllRecords();
    const value = this.exportFakirService.selections.getValue();
    this.exportFakirService.selections.next({
      ...value,
      Financial: 0,
    });
    this.event.isExportFKVisible.set(true);
  }

  refreshDocuments() {
    this.getData();
  }
  showContextMenuMobile(rowData: FinancialDocument) {
    if (this.selectedRow.includes(rowData.FinanceDocumentId)) {
      this.mobileSelectionIcon.set('icon absui-icon--deselct-all');
      this.mobileSelectionText.set(this.translate.instant('unmark'));
    } else {
      this.mobileSelectionIcon.set('icon checkAll');
      this.mobileSelectionText.set(this.translate.instant('mark'));
    }
    this.isContextMenuMobile = true;
  }

  touchTimeout: any;

  onTouchStart(e) {
    e.stopPropagation();
    this.touchTimeout = setTimeout(() => {
      this.modeSelectionMobile = 'multiple';
      let row = this.gridClass.instance.getDataSource().items()[
        this.focusedRowIndex
      ];
      const id = row.FinanceDocumentId;
      if (!this.selectedRow.includes(id)) {
        this.gridClass.instance.selectRows(id, true);
      } else {
        this.gridClass.instance.deselectRows(id);
      }
      this.cd.detectChanges();
    }, 1500);
  }

  onTouchEnd() {
    clearTimeout(this.touchTimeout);
  }

  onSetAdditionalOperation(e) {
    this.additionalOperationList.set(e);
  }

  onRecordCardClosing(): void {
    this.isRecordCardVisible = false;
    this.cd.detectChanges();
  }

  showRecordCard(mode: RecordCardMode): void {
    this.recordCardMode = mode;
    switch (mode) {
      case 'contractor':
        this.recordCardObjectId = this.focusedSelected[0].PayerId;
        break;
    }
    if (!this.recordCardObjectId) return;
    this.isRecordCardVisible = true;
    this.cd.detectChanges();
  }

  onResizingBottom(e) {
    this.localStorageData.bottomPanel.height = e.height;
    this.heightGrid = e.heightGrid;
    this.heightBottomGrid = e.heightBottomGrid;
    this.localStorageData.bottomPanel.isVisible = e.isVisible;
  }

  onItemClickTab = () => {
    if (!this.localStorageData.bottomPanel.isVisible) {
      this.localStorageData.bottomPanel.isVisible;
      this.localStorageData.bottomPanel.height = 230;
      this.heightBottomGrid = 205;
      this.heightGrid = window.innerHeight - this.heightBottomGrid - 225;

      this.cd.detectChanges();
    }

    this.gridClass?.instance?.updateDimensions();
  };

  onSelectionTabChanged = () => {
    this.localStorageData.bottomPanel.selectedIndex =
      this.localStorageData.bottomPanel.selectedIndex;
    try {
      if (this.focusedSelected[0].FinanceDocumentId) {
        this.getDetails(this.focusedSelected[0].FinanceDocumentId);
      }
    } catch {}
  };

  @ViewChild('eDocumentss') eDocumentss;
  getDetails = (_id: number) => {
    if (this.localStorageData.bottomPanel.isVisible) {
      // switch (this.selectedtabIndex) {
      switch (
        this.bottomPanelTabs[this.localStorageData.bottomPanel.selectedIndex]
      ) {
        case this.translate.instant('notes.notes'):
          break;
        case this.translate.instant('menu.eDocuments'):
          this.eDocumentss.getDocuments();
          this.cd.detectChanges();
          break;
      }
    }
  };
}
